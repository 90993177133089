/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadProductBuildingLineSheetResponse
 */
export interface UploadProductBuildingLineSheetResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadProductBuildingLineSheetResponse
     */
    product_building_line_sheet_url?: string;
}

/**
 * Check if a given object implements the UploadProductBuildingLineSheetResponse interface.
 */
export function instanceOfUploadProductBuildingLineSheetResponse(value: object): value is UploadProductBuildingLineSheetResponse {
    return true;
}

export function UploadProductBuildingLineSheetResponseFromJSON(json: any): UploadProductBuildingLineSheetResponse {
    return UploadProductBuildingLineSheetResponseFromJSONTyped(json, false);
}

export function UploadProductBuildingLineSheetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadProductBuildingLineSheetResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'product_building_line_sheet_url': json['product_building_line_sheet_url'] == null ? undefined : json['product_building_line_sheet_url'],
    };
}

export function UploadProductBuildingLineSheetResponseToJSON(json: any): UploadProductBuildingLineSheetResponse {
    return UploadProductBuildingLineSheetResponseToJSONTyped(json, false);
}

export function UploadProductBuildingLineSheetResponseToJSONTyped(value?: UploadProductBuildingLineSheetResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_building_line_sheet_url': value['product_building_line_sheet_url'],
    };
}

