/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BoardProductBrandResponse } from './BoardProductBrandResponse';
import {
    BoardProductBrandResponseFromJSON,
    BoardProductBrandResponseFromJSONTyped,
    BoardProductBrandResponseToJSON,
    BoardProductBrandResponseToJSONTyped,
} from './BoardProductBrandResponse';

/**
 * 
 * @export
 * @interface BoardProductResponse
 */
export interface BoardProductResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardProductResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardProductResponse
     */
    name?: string;
    /**
     * 
     * @type {BoardProductBrandResponse}
     * @memberof BoardProductResponse
     */
    brand?: BoardProductBrandResponse;
    /**
     * 
     * @type {number}
     * @memberof BoardProductResponse
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardProductResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardProductResponse
     */
    image?: string;
    /**
     * 
     * @type {Date}
     * @memberof BoardProductResponse
     */
    added_at?: Date;
}

/**
 * Check if a given object implements the BoardProductResponse interface.
 */
export function instanceOfBoardProductResponse(value: object): value is BoardProductResponse {
    return true;
}

export function BoardProductResponseFromJSON(json: any): BoardProductResponse {
    return BoardProductResponseFromJSONTyped(json, false);
}

export function BoardProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoardProductResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'brand': json['brand'] == null ? undefined : BoardProductBrandResponseFromJSON(json['brand']),
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'image': json['image'] == null ? undefined : json['image'],
        'added_at': json['added_at'] == null ? undefined : (new Date(json['added_at'])),
    };
}

export function BoardProductResponseToJSON(json: any): BoardProductResponse {
    return BoardProductResponseToJSONTyped(json, false);
}

export function BoardProductResponseToJSONTyped(value?: BoardProductResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'brand': BoardProductBrandResponseToJSON(value['brand']),
        'wholesale_price': value['wholesale_price'],
        'pos_price': value['pos_price'],
        'image': value['image'],
        'added_at': value['added_at'] == null ? undefined : ((value['added_at']).toISOString()),
    };
}

