/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SplitShipmentPackingSlipRequest } from './SplitShipmentPackingSlipRequest';
import {
    SplitShipmentPackingSlipRequestFromJSON,
    SplitShipmentPackingSlipRequestFromJSONTyped,
    SplitShipmentPackingSlipRequestToJSON,
    SplitShipmentPackingSlipRequestToJSONTyped,
} from './SplitShipmentPackingSlipRequest';

/**
 * Split ship object
 * @export
 * @interface BatchSplitShipmentRequest
 */
export interface BatchSplitShipmentRequest {
    /**
     * 
     * @type {Array<SplitShipmentPackingSlipRequest>}
     * @memberof BatchSplitShipmentRequest
     */
    packing_slips?: Array<SplitShipmentPackingSlipRequest>;
}

/**
 * Check if a given object implements the BatchSplitShipmentRequest interface.
 */
export function instanceOfBatchSplitShipmentRequest(value: object): value is BatchSplitShipmentRequest {
    return true;
}

export function BatchSplitShipmentRequestFromJSON(json: any): BatchSplitShipmentRequest {
    return BatchSplitShipmentRequestFromJSONTyped(json, false);
}

export function BatchSplitShipmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchSplitShipmentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'packing_slips': json['packing_slips'] == null ? undefined : ((json['packing_slips'] as Array<any>).map(SplitShipmentPackingSlipRequestFromJSON)),
    };
}

export function BatchSplitShipmentRequestToJSON(json: any): BatchSplitShipmentRequest {
    return BatchSplitShipmentRequestToJSONTyped(json, false);
}

export function BatchSplitShipmentRequestToJSONTyped(value?: BatchSplitShipmentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'packing_slips': value['packing_slips'] == null ? undefined : ((value['packing_slips'] as Array<any>).map(SplitShipmentPackingSlipRequestToJSON)),
    };
}

