/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductOrdinalDto
 */
export interface ProductOrdinalDto {
    /**
     * 
     * @type {string}
     * @memberof ProductOrdinalDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOrdinalDto
     */
    ordinal?: number;
}

/**
 * Check if a given object implements the ProductOrdinalDto interface.
 */
export function instanceOfProductOrdinalDto(value: object): value is ProductOrdinalDto {
    return true;
}

export function ProductOrdinalDtoFromJSON(json: any): ProductOrdinalDto {
    return ProductOrdinalDtoFromJSONTyped(json, false);
}

export function ProductOrdinalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductOrdinalDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
    };
}

export function ProductOrdinalDtoToJSON(json: any): ProductOrdinalDto {
    return ProductOrdinalDtoToJSONTyped(json, false);
}

export function ProductOrdinalDtoToJSONTyped(value?: ProductOrdinalDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'ordinal': value['ordinal'],
    };
}

