<template>
  <div :class="isRouteNYNOW ? 'min-height-60' : 'nav-nynow-container bb'">
    <div v-if="showLinks" class="m-40">
      <h4>NY NOW</h4>
      <div class="brand-menu my-20">
        <div class="menu-item">
          <router-link v-if="showLinks" :to="'/nynow-buyer'" class="active-hover" data-cy="nynow-link">
            Buyer Directory
          </router-link>
          <router-link :to="exhibitorRoute" class="active-hover exhibitor-link ml-100" data-cy="nynow-link">
            Exhibitor Directory
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="showLinks && isRouteNYNOW" class="bt" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrandApi from './../../services/api/brandApi.js'
import { TradeShowApi } from '@/api'

const BrandApiService = new BrandApi()

export default {
  name: 'NavNYNOW',
  data() {
    return {
      showLinks: false,
      isNYN: false,
      exhibitorRoute: '/nynow-exhibitor?sortBy=brands_made_active_desc&page=1'
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    }),
    isRouteNYNOW() {
      return this.$route.path === '/nynow'
    }
  },
  async beforeMount() {
    if (this.user) {
      if (this.user.role === 'BRAND') {
        const { data } = await BrandApiService.getBrandMe()
        const { features } = await TradeShowApi.getTradeShowEnabledFeatures()
        const eventId = features?.buyer_directory?.event_ids?.[0]
        this.showLinks = data?.trade_shows?.[eventId]?.booth_number
        if (!this.showLinks) {
          return this.goToExhibitorDirectory()
        }
      }
      if (this.user.role === 'RETAILER') {
        return this.goToExhibitorDirectory()
      }
      if (this.user.role === 'ADMIN') {
        return (this.showLinks = true)
      }
    } else {
      return this.goToExhibitorDirectory()
    }
  },
  methods: {
    goToExhibitorDirectory() {
      this.$router.push(`/nynow-exhibitor`)
    }
  }
}
</script>

<style lang="scss" scoped src="../../assets/sass/searchPage/searchPage.scss"></style>
<style lang="scss" scoped>
@import '@/assets/sass/base/_mixins.scss';

.nav-nynow-container {
  height: 110px;
}

@include respond-to('small') {
  .menu-item {
    .exhibitor-link {
      margin-left: 40px !important;
    }
  }
}
</style>
