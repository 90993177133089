import utils from '@/services/utils'

export const cartHelper = {
  methods: {
    urlFormatter: utils.urlSafeString,
    findProductCategoryIdByName: function (product, categoryFromParam, parentCategoryFromParam) {
      const { categories } = product

      if (!categories || !categories.length) {
        return null
      }

      const found = categories.find((item) => {
        const { name, parent_category_name, parent_category } = item
        const parentCategory = parent_category || parent_category_name
        const urlFormattedName = this.urlFormatter(name)
        const urlFormattedParentCategoryName = this.urlFormatter(parentCategory)

        if (categoryFromParam) {
          if (urlFormattedName === `${parentCategoryFromParam}-${categoryFromParam}`) {
            return true
          }
          if (urlFormattedName === categoryFromParam) {
            return true
          }
        }

        if (parentCategoryFromParam) {
          return urlFormattedParentCategoryName === parentCategoryFromParam
        }

        return false
      })

      return found ? found.id : categories[0].id
    }
  }
}
