/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalPart
 */
export interface LocalPart {
    /**
     * 
     * @type {boolean}
     * @memberof LocalPart
     */
    is_suspected_role_address: boolean;
}

/**
 * Check if a given object implements the LocalPart interface.
 */
export function instanceOfLocalPart(value: object): value is LocalPart {
    if (!('is_suspected_role_address' in value) || value['is_suspected_role_address'] === undefined) return false;
    return true;
}

export function LocalPartFromJSON(json: any): LocalPart {
    return LocalPartFromJSONTyped(json, false);
}

export function LocalPartFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalPart {
    if (json == null) {
        return json;
    }
    return {
        
        'is_suspected_role_address': json['is_suspected_role_address'],
    };
}

export function LocalPartToJSON(json: any): LocalPart {
    return LocalPartToJSONTyped(json, false);
}

export function LocalPartToJSONTyped(value?: LocalPart | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'is_suspected_role_address': value['is_suspected_role_address'],
    };
}

