/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create video request
 * @export
 * @interface CreateVideoRequest
 */
export interface CreateVideoRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVideoRequest
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoRequest
     */
    entity_type: CreateVideoRequestEntityTypeEnum;
}


/**
 * @export
 */
export const CreateVideoRequestEntityTypeEnum = {
    Product: 'PRODUCT'
} as const;
export type CreateVideoRequestEntityTypeEnum = typeof CreateVideoRequestEntityTypeEnum[keyof typeof CreateVideoRequestEntityTypeEnum];


/**
 * Check if a given object implements the CreateVideoRequest interface.
 */
export function instanceOfCreateVideoRequest(value: object): value is CreateVideoRequest {
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('entity_type' in value) || value['entity_type'] === undefined) return false;
    return true;
}

export function CreateVideoRequestFromJSON(json: any): CreateVideoRequest {
    return CreateVideoRequestFromJSONTyped(json, false);
}

export function CreateVideoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVideoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'entity_type': json['entity_type'],
    };
}

export function CreateVideoRequestToJSON(json: any): CreateVideoRequest {
    return CreateVideoRequestToJSONTyped(json, false);
}

export function CreateVideoRequestToJSONTyped(value?: CreateVideoRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
        'entity_type': value['entity_type'],
    };
}

