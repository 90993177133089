/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandFromBrandBoardResponse
 */
export interface BrandFromBrandBoardResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandFromBrandBoardResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandFromBrandBoardResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandFromBrandBoardResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandFromBrandBoardResponse
     */
    buy_on_bulletin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandFromBrandBoardResponse
     */
    bulletin_pick?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandFromBrandBoardResponse
     */
    vanity_url_name?: string;
}

/**
 * Check if a given object implements the BrandFromBrandBoardResponse interface.
 */
export function instanceOfBrandFromBrandBoardResponse(value: object): value is BrandFromBrandBoardResponse {
    return true;
}

export function BrandFromBrandBoardResponseFromJSON(json: any): BrandFromBrandBoardResponse {
    return BrandFromBrandBoardResponseFromJSONTyped(json, false);
}

export function BrandFromBrandBoardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandFromBrandBoardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
        'bulletin_pick': json['bulletin_pick'] == null ? undefined : json['bulletin_pick'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
    };
}

export function BrandFromBrandBoardResponseToJSON(json: any): BrandFromBrandBoardResponse {
    return BrandFromBrandBoardResponseToJSONTyped(json, false);
}

export function BrandFromBrandBoardResponseToJSONTyped(value?: BrandFromBrandBoardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'buy_on_bulletin': value['buy_on_bulletin'],
        'bulletin_pick': value['bulletin_pick'],
        'vanity_url_name': value['vanity_url_name'],
    };
}

