/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralsPagination
 */
export interface ReferralsPagination {
    /**
     * 
     * @type {number}
     * @memberof ReferralsPagination
     */
    items_per_page?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsPagination
     */
    total_items?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsPagination
     */
    current_page?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralsPagination
     */
    total_pages?: number;
}

/**
 * Check if a given object implements the ReferralsPagination interface.
 */
export function instanceOfReferralsPagination(value: object): value is ReferralsPagination {
    return true;
}

export function ReferralsPaginationFromJSON(json: any): ReferralsPagination {
    return ReferralsPaginationFromJSONTyped(json, false);
}

export function ReferralsPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsPagination {
    if (json == null) {
        return json;
    }
    return {
        
        'items_per_page': json['items_per_page'] == null ? undefined : json['items_per_page'],
        'total_items': json['total_items'] == null ? undefined : json['total_items'],
        'current_page': json['current_page'] == null ? undefined : json['current_page'],
        'total_pages': json['total_pages'] == null ? undefined : json['total_pages'],
    };
}

export function ReferralsPaginationToJSON(json: any): ReferralsPagination {
    return ReferralsPaginationToJSONTyped(json, false);
}

export function ReferralsPaginationToJSONTyped(value?: ReferralsPagination | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items_per_page': value['items_per_page'],
        'total_items': value['total_items'],
        'current_page': value['current_page'],
        'total_pages': value['total_pages'],
    };
}

