<template>
  <div :class="{ show: show }" class="mobile-filter-popup">
    <Icon icon-key="close" hover-type="OPACITY" class="close-container" :on-click="hideFilters" />
    <h2 class="popup-title">Filters</h2>
    <div class="mobile-filter-scroll-section">
      <filter-badges class="mobile-filter-badges" />
      <filter-panel
        :total-count="totalCount"
        is-mobile
        class="mobile-filter-panel"
        :filter-panel-config="filterPanelConfig"
        :is-buyer="isBuyer"
      />
    </div>
    <div class="view-results">
      <bulletin-button variant="primary" full-width @click="hideFilters()"> View Results </bulletin-button>
    </div>
  </div>
</template>

<script>
import { bodyScrollHelper } from '../../mixin/bodyScrollHelper'

export default {
  mixins: [bodyScrollHelper],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: Number,
      default: 0
    },
    hideFilters: {
      type: Function,
      default: () => {}
    },
    filterPanelConfig: {
      type: Object,
      default: () => {}
    },
    isBuyer: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.mobile-filter-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: none;
  background: $color-white;
  z-index: 23;
  &.show {
    display: flex;
    flex-direction: column;
  }

  .mobile-filter-badges {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mobile-filter-panel {
    padding: 0 16px;
    margin-top: 8px;
  }

  .mobile-filter-scroll-section {
    flex-grow: 1;
    overflow: auto;
  }

  .view-results {
    padding: 16px;
    border-top: 1px solid $color-black;
    background: $color-white;
    z-index: 2;
  }

  .popup-title {
    padding-top: 48px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-size: 32px;
  }

  .close-container {
    position: fixed;
    top: 16px;
    right: 16px;
  }
}
</style>
