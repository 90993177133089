import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper
  },
  props: {
    inline: false,
    imageUrl: '',
    className: ''
  },
  data() {
    return {
      invalidCount: 0,
      imageMinimumHeight: 300,
      aspectRatio: {
        x: 176,
        y: 220
      },
      imageEditorSource: null,
      showModal: false,
      isModalVisible: false,
      dataUrl: '',
      zoomValue: 1,
      filesArray: [],
      imagesArray: [],
      minZoom: 0,
      cropParams: this.getDefaultCropParams(),
      ready: false,
      imageType: null,
      imageIndex: null,
      original_file: null,
      imageData: null,
      originalImage: {}
    }
  },
  methods: {
    addDroppedFile,
    addSelectedFile,
    handleFiles,
    displayEditorWhenLoaded,
    handleImageUploadClick,
    handleCancelClick,
    handleConfirmClick,
    handleCloseClick,
    handleRotateLeft,
    handleRotateRight,
    handleFlipHorizontal,
    handleFlipVertical,
    iterateFiles,
    pushBulkImagesToParent,
    displayEditorWithExistingImage,
    cropperReadyCallback,
    getDefaultCropParams,
    removeInlineImage,
    reopenInlineImage,
    resetOriginalImage
  },
  watch: {
    zoomValue(value) {
      this.$refs.cropper.zoomTo(value)
    }
  },
  computed: {
    isRotatedSideways() {
      if (this.cropParams.rotate == null) {
        return false
      }

      return this.cropParams.rotate % 90 === 0 && this.cropParams.rotate % 180 !== 0
    }
  },
  beforeUnmount() {
    this.resetOriginalImage()
  }
}

function addDroppedFile(e) {
  const dt = e.dataTransfer,
    files = dt.files
  this.handleFiles(files)
}

function addSelectedFile(e) {
  const files = Array.from(e.target.files),
    validFiles = files.filter(
      (file) => file.size < this.$utils.getByteSizeFromMegabyteSize(this.$utils.constants.IMAGE_UPLOAD_SIZE_LIMIT_MB)
    )

  if (files.length !== validFiles.length) {
    this.$handleError(this, this.$utils.constants.IMAGE_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE)
  }
  if (validFiles.length) {
    this.handleFiles(validFiles)
  }
  //reset input form
  this.$refs.productCustomImageInput.value = ''
}

function handleFiles(files) {
  this.filesArray = [...files]
  this.$emit('on-image-drop', this.filesArray[0])
  if (this.filesArray.length === 1) {
    this.displayEditorWhenLoaded(this.filesArray[0])
    this.filesArray = []
  } else {
    this.iterateFiles(0)
  }
}

function resetOriginalImage() {
  this.originalImage = {}
}

function displayEditorWhenLoaded(file) {
  if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
    this.$handleError(this, 'Please upload a .jpeg or .png image.')
  }

  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onloadend = () => {
    const i = new Image()
    i.onload = () => {
      if (i.naturalHeight > 300) {
        this.imageEditorSource = reader.result
        this.imageType = /data:(.*);/.exec(reader.result)[1]
        this.showModal = true
      } else {
        this.$handleError(this, 'Please upload a higher resolution image')
      }
    }
    i.src = reader.result
  }
  this.cropParams = this.getDefaultCropParams()
  this.imageIndex = null
  this.imageType = null
  this.original_file = file
}

function displayEditorWithExistingImage(image, index) {
  this.originalImage = image
  this.imageIndex = index
  this.imageType = 'image/jpeg'
  this.imageEditorSource = image.originalSrc || this.src
  this.cropParams = {
    ...this.getDefaultCropParams(),
    ...image.cropParams
  }
  this.showModal = true
  if (image.original_file) {
    this.original_file = image.original_file
  }
}

function cropperReadyCallback() {
  const params = this.cropParams,
    cropper = this.$refs.cropper,
    landscape = cropper.getImageData().width > cropper.getImageData().height

  this.minZoom = landscape
    ? cropper.getContainerData().width / cropper.getImageData().naturalWidth
    : cropper.getContainerData().height / cropper.getImageData().naturalHeight
  this.ready = true

  this.zoomValue = params.zoom || this.minZoom
  this.$nextTick(() => {
    const ratio = cropper.getImageData().width / cropper.getImageData().naturalWidth

    cropper.cropper.renderCropBox()

    cropper.setData({
      rotate: Number(params.rotate) || 0,
      scaleX: Number(params.flipX) === 1 ? -1 : 1,
      scaleY: Number(params.flipY) === 1 ? -1 : 1
    })

    if (params.cropX && params.cropY) {
      cropper.setCanvasData({
        ...cropper.getCanvasData(),
        left: (cropper.getCropBoxData().left / ratio - params.cropX) * ratio,
        top: (cropper.getCropBoxData().top / ratio - params.cropY) * ratio
      })
    }
  })
}

function handleImageUploadClick() {
  this.$refs.productCustomImageInput.click()
}

function handleCancelClick() {
  this.ready = false
  this.showModal = false
  this.isModalVisible = false
  this.zoomValue = 5
  this.imageEditorSource = null
  this.imageIndex = null
  this.imageType = null
  this.cropParams = this.getDefaultCropParams()
  this.originalImage = {}
}

function handleConfirmClick() {
  this.dataUrl = this.$refs.cropper.getCroppedCanvas().toDataURL(this.imageType, 0.75)
  this.cropParams = {
    ...this.cropParams,
    cropX: this.$refs.cropper.getData().x,
    cropY: this.$refs.cropper.getData().y,
    zoom: this.zoomValue
  }
  this.$refs.cropper.getCroppedCanvas().toBlob(
    (blob) => {
      //create file from blob
      const file = new File([blob], null, {
        lastModified: Date.now(),
        type: this.imageType
      })
      //send file to parent product.images array
      this.imageData = {
        file,
        original_file: this.original_file,
        src: this.dataUrl,
        originalSrc: this.imageEditorSource,
        cropParams: this.cropParams,
        index: this.imageIndex
      }
      if (this.originalImage.id) {
        this.imageData.id = this.originalImage.id
      }

      this.resetOriginalImage()
      this.cropParams = this.getDefaultCropParams()
      if (this.$refs.cropper) {
        this.$refs.cropper.reset()
      }
      this.ready = false
      this.showModal = false
      this.isModalVisible = false
      this.zoomValue = 5

      this.$emit('create-image-data', { ...this.imageData })
    },
    this.imageType,
    0.75
  )
}

function handleCloseClick() {
  this.resetOriginalImage()
  this.$refs.cropper.reset()
  this.zoomValue = 5
}

function handleRotateLeft() {
  this.cropParams.rotate = (this.cropParams.rotate || 0) - 90
  this.$refs.cropper.rotate(-90)
}

function handleRotateRight() {
  this.cropParams.rotate = (this.cropParams.rotate || 0) + 90
  this.$refs.cropper.rotate(90)
}

function handleFlipHorizontal() {
  // toggle scaleX
  const isFlipped = this.$refs.cropper.getData().scaleX < 0

  this.cropParams.flipX = isFlipped ? 0 : 1
  this.$refs.cropper.scale(isFlipped ? 1 : -1, 1)
}

function handleFlipVertical() {
  // toggle scaleY
  const isFlipped = this.$refs.cropper.getData().scaleY < 0

  this.cropParams.flipY = isFlipped ? 0 : 1
  this.$refs.cropper.scale(1, isFlipped ? 1 : -1)
}

function iterateFiles(i, _invalidImagesCounter) {
  const _self = this

  if (i < _self.filesArray.length) {
    const reader = new FileReader()

    reader.readAsDataURL(_self.filesArray[i])
    reader.onloadend = () => {
      const image = new Image(),
        canvas = document.createElement('canvas'),
        context = canvas.getContext('2d')

      image.src = reader.result
      image.onload = () => {
        if (image.naturalHeight < _self.imageMinimumHeight) {
          _self.invalidCount += 1
          _self.iterateFiles(i + 1)
          return
        }

        // determine which property is being changed, width or height
        if (_self.aspectRatio.y * image.naturalWidth - _self.aspectRatio.x * image.naturalHeight > 0) {
          // changing image width
          canvas.height = image.naturalHeight
          canvas.width = (_self.aspectRatio.x * image.naturalHeight) / _self.aspectRatio.y
        } else {
          // changing image height
          canvas.width = image.naturalWidth
          canvas.height = (_self.aspectRatio.y * image.naturalWidth) / _self.aspectRatio.x
        }

        // draw image with canvas centered
        context.drawImage(image, canvas.width / 2 - image.width / 2, canvas.height / 2 - image.height / 2)

        // cast to file to blod (current implementation for cropping is working this way)
        canvas.toBlob(
          function (blob) {
            const file = new File([blob], null, {
              lastModified: Date.now(),
              type: _self.filesArray[i].type
            })

            _self.imagesArray.push({
              file,
              original_file: _self.filesArray[i],
              src: canvas.toDataURL(_self.filesArray[i].type),
              originalSrc: reader.result,
              cropParams: {}
            })
            _self.iterateFiles(i + 1)
          },
          _self.filesArray[i].type,
          0.75
        )
      }
    }
  } else {
    if (_self.invalidCount > 0) {
      const message =
        _self.invalidCount > 1
          ? `Images (${_self.invalidCount}) with height below ${_self.imageMinimumHeight}px detected. These images will not be added.`
          : `Image with height below ${_self.imageMinimumHeight}px detected. This image will not be added.`
      _self.$handleError(_self, message)
    }

    _self.pushBulkImagesToParent()
  }
}

function pushBulkImagesToParent() {
  this.$emit('create-image-data', this.imagesArray)
  this.filesArray = []
  this.imagesArray = []
}

function getDefaultCropParams() {
  return {
    cropX: null,
    cropY: null,
    rotate: null,
    flipY: null,
    flipX: null,
    zoom: null,
    scaleX: null,
    scaleY: null
  }
}

function removeInlineImage() {
  this.imageData = null
  this.$emit('remove-image')
}

function reopenInlineImage() {
  const existingImage = this.imageData || {
    originalSrc: this.imageUrl
  }
  this.displayEditorWithExistingImage(existingImage, 0)
}
