<template>
  <dropdown
    ref="collectionDropdown"
    data-test-id="collection-dropdown"
    :class="{ 'opacity-05': !collections.length }"
    :dropdown-items="collections"
    :searchable="true"
    :is-required="required"
    :disabled="disabled || !collections.length"
    :dropdown-index="dropdownIndex"
    :selected-name="selectedName"
    :placeholder="loading ? 'Loading Collections...' : placeholder"
    :name="name"
    :on-select="handleSelectChange"
    :input-class="inputClass"
  />
</template>

<script>
import { algoliaCollectionHelper } from '@/components/mixin/search/algoliaCollectionHelper'

export default {
  mixins: [algoliaCollectionHelper],
  props: {
    algoliaCollections: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select Collection'
    },
    inputClass: {
      type: String,
      default: ''
    },
    selectedName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dropdownIndex: {
      type: Number,
      default: undefined
    },
    showResetAsOption: {
      type: Boolean,
      default: false
    },
    onSelect: {
      type: Function,
      default: () => {}
    },
    onReset: {
      type: Function,
      default: () => {}
    },
    onCollectionsFetched: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      collections: [],
      loading: false
    }
  },
  async beforeMount() {
    if (this.algoliaCollections) {
      this.collections = this.mapAlgoliaResults(this.algoliaCollections)
    } else {
      this.getCollections()
    }
  },
  methods: {
    handleSelectChange,
    getCollections,
    mapAlgoliaResults
  }
}

async function getCollections() {
  this.loading = true

  this.collections = this.mapAlgoliaResults(await this.getAllCollections())

  if (this.showResetAsOption) {
    this.brands?.unshift({
      name: 'Reset'
    })
  }
  this.onCollectionsFetched()
  this.loading = false
}

function handleSelectChange(item) {
  if (item.name === 'Reset') {
    this.$refs.collectionDropdown.selected.name = ''
    this.onReset(item)
  } else {
    this.onSelect(item)
  }
}

function mapAlgoliaResults({ hits }) {
  return hits?.map((item) => {
    return {
      name: item.title,
      homepage_title: '',
      id: item.id,
      entity_id: item.id,
      image_url: item.image_url
    }
  })
}
</script>
