export const searchPageMobileStateHelper = {
  data() {
    return {
      isMobile: false,
      showMobileFilter: false,
      windowWidth: window.innerWidth
    }
  },
  beforeMount() {
    this.setMobileState()
    window.addEventListener('resize', this.setMobileState)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setMobileState)
  },

  methods: {
    setMobileState,
    setMobileFilterVisibility,
    setPage
  }
}

function setMobileFilterVisibility(value) {
  this.showMobileFilter = value
}

function setMobileState() {
  this.isMobile = window.innerWidth < 770
  this.windowWidth = window.innerWidth
}

function setPage(page) {
  this.pagination.page = parseInt(page)
  this.updateURLParams('page', page)
}
