<template>
  <div class="show hide-md mx-15" @mouseover="mouseOver()">
    <Icon data-cy="avatar-button" :icon-key="iconKey" hover-type="ICON" :on-click="handleClick" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { navigationHelper } from '../mixin/navigationHelper'

export default {
  mixins: [navigationHelper],
  props: {
    mouseOver: {
      type: Function,
      default: () => null
    },
    mouseLeave: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    }),
    messageCount() {
      return this.$store.getters.notificationState
    },
    targetRoute() {
      const roleTargetMap = {
        PRODUCT_BUILDER: '/dashboard/products',
        BRAND: '/dashboard/brands/my-brand-page',
        RETAILER: '/dashboard/retailer/orders',
        ADMIN: '/dashboard/orders'
      }
      return roleTargetMap[this.user?.role]
    },
    iconKey() {
      if (this.messageCount && this.isOnADashboardPage) return 'profile-alert-active'
      if (this.messageCount) return 'profile-alert'
      if (this.isOnADashboardPage) return 'profile-active'
      return 'profile'
    }
  },
  methods: {
    handleClick
  }
}

function handleClick() {
  this.$router.push(this.targetRoute)
  this.$store.dispatch('navigation/setDropdownType', 'closed')
  this.mouseOver()
  this.trackNavigationEvent('dashboard', 'dashboard')
}
</script>