import vClickOutside from 'v-click-outside'

export default {
  inject: {
    parentValidator: {
      default: function () {
        return this.$validator
      }
    }
  },
  props: {
    searchable: { type: Boolean, required: false },
    scope: { type: String },
    dropdownItems: { type: Array },
    dropdownParentIndex: { type: Number },
    dropdownIndex: { type: Number },
    placeholder: { type: String },
    onSelect: { type: Function },
    optionalyAvailable: { type: Boolean },
    selectedName: { type: String },
    isRequired: { type: Boolean },
    name: { type: String },
    itemDataTestId: { type: String },
    inputDataTestId: { type: String },
    modelName: { type: String },
    transformItem: { type: Function },
    inputClass: { type: String },
    isTableView: { type: Boolean }
  },

  data() {
    return {
      query: '',
      selected: {
        name: ''
      },
      isOpen: false,
      items: []
    }
  },
  created() {
    this.$validator = this.parentValidator
  },
  watch: {
    dropdownItems: function (val) {
      this.items = val
    },

    selectedName: function (val) {
      this.selected.name = val
    }
  },

  methods: {
    toggleDropdown,
    closeDropdown,
    pick
  },

  mounted() {
    this.items = this.dropdownItems === undefined ? [] : this.dropdownItems
    this.selected.name = this.selectedName ? this.selectedName : ''
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  computed: {
    filteredData
  }
}

function filteredData() {
  return this.items.filter((item) => {
    if (item.name !== null) {
      return item.name.toLowerCase().match(this.query.toLowerCase())
    }
  })
}

function pick(item) {
  this.selected = { ...item }

  this.onSelect(item, this.dropdownIndex, this.dropdownParentIndex, this.modelName)

  if (this.$validator && this.name) {
    this.$validator?.errors?.remove(this.name)
  }

  this.closeDropdown()
}

function toggleDropdown() {
  if (this.items.length) {
    this.isOpen = !this.isOpen
  }
}

function closeDropdown() {
  this.isOpen = false
}
