/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RetailerBoardDetailsResponse } from './RetailerBoardDetailsResponse';
import {
    RetailerBoardDetailsResponseFromJSON,
    RetailerBoardDetailsResponseFromJSONTyped,
    RetailerBoardDetailsResponseToJSON,
    RetailerBoardDetailsResponseToJSONTyped,
} from './RetailerBoardDetailsResponse';

/**
 * 
 * @export
 * @interface GetAllRetailerBoardsDetailsResponse
 */
export interface GetAllRetailerBoardsDetailsResponse {
    /**
     * 
     * @type {Array<RetailerBoardDetailsResponse>}
     * @memberof GetAllRetailerBoardsDetailsResponse
     */
    boards?: Array<RetailerBoardDetailsResponse>;
}

/**
 * Check if a given object implements the GetAllRetailerBoardsDetailsResponse interface.
 */
export function instanceOfGetAllRetailerBoardsDetailsResponse(value: object): value is GetAllRetailerBoardsDetailsResponse {
    return true;
}

export function GetAllRetailerBoardsDetailsResponseFromJSON(json: any): GetAllRetailerBoardsDetailsResponse {
    return GetAllRetailerBoardsDetailsResponseFromJSONTyped(json, false);
}

export function GetAllRetailerBoardsDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllRetailerBoardsDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'boards': json['boards'] == null ? undefined : ((json['boards'] as Array<any>).map(RetailerBoardDetailsResponseFromJSON)),
    };
}

export function GetAllRetailerBoardsDetailsResponseToJSON(json: any): GetAllRetailerBoardsDetailsResponse {
    return GetAllRetailerBoardsDetailsResponseToJSONTyped(json, false);
}

export function GetAllRetailerBoardsDetailsResponseToJSONTyped(value?: GetAllRetailerBoardsDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'boards': value['boards'] == null ? undefined : ((value['boards'] as Array<any>).map(RetailerBoardDetailsResponseToJSON)),
    };
}

