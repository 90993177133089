/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    url?: string;
}

/**
 * Check if a given object implements the UploadFileResponse interface.
 */
export function instanceOfUploadFileResponse(value: object): value is UploadFileResponse {
    return true;
}

export function UploadFileResponseFromJSON(json: any): UploadFileResponse {
    return UploadFileResponseFromJSONTyped(json, false);
}

export function UploadFileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadFileResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function UploadFileResponseToJSON(json: any): UploadFileResponse {
    return UploadFileResponseToJSONTyped(json, false);
}

export function UploadFileResponseToJSONTyped(value?: UploadFileResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
    };
}

