<template>
  <div class="filter-badges">
    <div class="active-badges-wrapper" :class="{ mobile: isMobile && hasFilters() }">
      <div v-if="hasMultipleFilters() && !isMobile" class="clear-button" @click="clearAll()">Clear All Filters</div>
      <div
        class="swipable-filters"
        :style="{ width: `${getCarouselContainerWidth()}px` }"
        :class="{ mobile: isMobile }"
      >
        <template v-if="hasMultipleFilters() && showCarouselControls && !isMobile">
          <div class="carousel-control left" :class="{ 'fade-out': swiperReachStart }">
            <Icon icon-key="chevron-left" hover-type="OPACITY" :size="32" :on-click="slideToPrevious" />
          </div>
          <div class="carousel-control right" :class="{ 'fade-out': swiperReachEnd }">
            <Icon icon-key="chevron-right" hover-type="OPACITY" :size="32" class="ml-auto" :on-click="slideToNext" />
          </div>
        </template>

        <swiper
          ref="carousel"
          :slides-per-view="5"
          :options="carouselOptions"
          :class="{ 'mobile-filters': isMobile }"
          @reachEnd="reachEnd"
          @slideChange="slideChange"
        >
          <swiper-slide
            v-for="filter in filterBadgeList"
            :key="filter.value"
            :style="{ width: `${filter.width + chipSpacingPlusIcon}px` }"
          >
            <div class="filter-badge">
              <span> {{ filter.label }}</span>
              <Icon
                icon-key="close"
                hover-type="OPACITY"
                :size="24"
                class="ml-8"
                :on-click="() => removeFilter(filter.groupKey, filter)"
              />
            </div>
          </swiper-slide>

          <swiper-slide v-if="isMobile" :style="{ width: '16px' }" />
        </swiper>
      </div>
    </div>
    <div v-if="hasMultipleFilters() && isMobile" class="clear-button mt-15" @click="clearAll()">Clear All Filters</div>
  </div>
</template>

<script>
import { paramsHelper } from '../../mixin/paramsHelper'
import { mapGetters } from 'vuex'
import { searchPageMobileStateHelper } from '../../mixin/search/searchPageMobileStateHelper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

const CHIP_SPACING_PLUS_ICON = 72

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  mixins: [paramsHelper, searchPageMobileStateHelper],
  data: function () {
    return {
      swiperReachEnd: false,
      swiperReachStart: true,
      showCarousel: false,
      showCarouselControls: false,
      chipSpacingPlusIcon: CHIP_SPACING_PLUS_ICON
    }
  },

  computed: {
    ...mapGetters({
      filters: 'filters/filters'
    }),
    filterBadgeList() {
      return Object.keys(this.filters).reduce((list, key) => {
        return [
          ...list,
          ...this.filters[key].map((item) => {
            const textWidth = this.$utils.getTextWidth(item.label, '16px NHaasGroteskDSPro-55Rg')
            return { ...item, groupKey: key, width: textWidth }
          })
        ]
      }, [])
    },
    carousel() {
      return this.$refs?.carousel?.$swiper
    },
    carouselOptions() {
      const halfFactor = 2
      return {
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: true,
        initialSlide: this.filterBadgeList?.length / halfFactor,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  watch: {
    filterBadgeList: function (value) {
      const chipsWidth = value.reduce((fullWidth, item) => {
        return fullWidth + item.width + this.chipSpacingPlusIcon
      }, 0)

      this.showCarouselControls = chipsWidth > this.getCarouselContainerWidth()

      if (this.showCarouselControls) {
        this.slideChange({ translate: this.carousel.translate })
      }
    }
  },
  methods: {
    hasMultipleFilters,
    clearAll,
    removeFilter,
    hasFilters,
    getCarouselContainerWidth,
    reachEnd,
    slideChange,
    slideToNext,
    slideToPrevious
  }
}

function removeFilter(groupKey, filter) {
  // NOTE:: Remove all states related to country
  if (groupKey === 'country') {
    const updatedLocations = this.filters['location']
      .filter((item) => !filter.states.some((element) => element.code === item.value))
      .map((item) => item.value)
    this.updateURLParams('location', updatedLocations)
  }
  const updatedFilters = this.filters[groupKey].filter((item) => item.value !== filter.value).map((item) => item.value)
  this.updateURLParams(groupKey, updatedFilters?.length ? updatedFilters : null)
}

function clearAll() {
  Object.keys(this.filters).forEach((key) => {
    this.updateURLParams(key, null)
  })
}

function hasMultipleFilters() {
  return this.filterBadgeList?.length > 1
}

function hasFilters() {
  return this.filterBadgeList?.length
}

function slideToNext() {
  this.carousel.slideNext()
  this.carousel.slideNext()
}
function slideToPrevious() {
  this.carousel.slidePrev()
  this.carousel.slidePrev()
}

function slideChange(event) {
  setTimeout(() => {
    const reachStartDiff = Math.abs(event.translate)
    const reachEndDiff = this.carousel.virtualSize - this.carousel.width - Math.abs(event.translate)

    this.swiperReachStart = reachStartDiff < 100 && reachStartDiff < reachEndDiff
    this.swiperReachEnd = reachEndDiff < 100 && reachEndDiff < reachStartDiff

    // Fix glitch on reaching bounds
    if (this.swiperReachStart) {
      this.carousel.slidePrev()
    }
    if (this.swiperReachEnd) {
      this.carousel.slideNext()
    }
  })
}
function reachEnd() {
  this.swiperReachEnd = true
}

function getCarouselContainerWidth() {
  const clearButtonWidth = this.isMobile ? 0 : 120
  const spacing = !this.isMobile ? 88 : 0
  const swiperContainerWidth = !this.isMobile ? this.windowWidth * 0.75 : this.windowWidth
  return swiperContainerWidth - spacing - clearButtonWidth
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';

$transitionTime: 250ms;
$transitionType: ease-in-out;

.active-badges-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  &.mobile {
    min-height: 32px;
  }
}

.swipable-filters {
  position: relative;

  &.mobile {
    position: absolute;
    top: 0;
    left: -16px;
    right: 0;
  }

  .carousel-control {
    position: absolute;
    z-index: 2;
    height: 32px;
    width: 50px;
    top: 0;
    background: $color-white;
    @include transition(opacity, $transitionTime, $transitionType);
    opacity: 1;

    &.fade-out {
      opacity: 0;
      pointer-events: none;
    }

    &.left {
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 60%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &.right {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 60%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.selected-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.filter-badge {
  background: $color-green;
  font-size: 16px;
  font-family: 'NHaasGroteskDSPro-55Rg';
  border-radius: 16px;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  margin-right: 8px;

  .span {
    user-select: none;
  }
}

.clear-button {
  font-family: 'NHaasGroteskDSPro-55Rg';
  font-size: 16px;
  text-decoration: underline;
  width: 120px;
  &:hover {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.swiper-container {
  &.mobile-filters {
    .swiper-wrapper {
      padding-left: 16px !important;
    }
  }
}
</style>
