/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddSuborderShippingInfoRequest,
  BatchSplitShipmentRequest,
  ChangeSuborderCreditCardRequest,
  ConfirmPackingSlipRequest,
  CreateBalanceOrderRequest,
  CreateOrderRequest,
  CreateOrderResponse,
  CreateOrderReturnRequest,
  CreatePackingSlipRequest,
  DeclineSuborderRequest,
  DeletePackingSlipsRequest,
  DropdownItem,
  DuplicateOrderResponse,
  EstimateShippingRequest,
  EstimateShippingResponse,
  GetDeletedVariantsRequest,
  GetDeletedVariantsResponse,
  GetEnumOptionsResponseOrderStatus,
  GetOrderResponse,
  GetPayInvoiceInformationResponse,
  GetPaymentResponse,
  GetReorderResponse,
  IdWrapper,
  InvoiceResponse,
  MinimumOrderValueValidationRequest,
  MinimumOrderValuesResponse,
  OrderCountByBrandResponse,
  OrderPreviewResponse,
  OrderResponse,
  OrderReturnReasonResponse,
  OrderReturnResponse,
  PackingSlipResponse,
  PaginatedOrdersForSubordersResponse,
  PaginatedOrdersResponse,
  PayInvoiceResponse,
  ShippingCarrierResponse,
  SplitShipRequest,
  SplitShipResponse,
  SuborderResponse,
  UpdateExternalServiceRequest,
  UpdateOrderReturnItemsRequest,
  UpdateOrderReturnRequest,
  UpdatePackingSlipRequest,
  UpdateSuborderCreditCardRequest,
  UpdateSuborderRequest,
  UpdateSuborderResponse,
} from '../models/index';
import {
    AddSuborderShippingInfoRequestFromJSON,
    AddSuborderShippingInfoRequestToJSON,
    BatchSplitShipmentRequestFromJSON,
    BatchSplitShipmentRequestToJSON,
    ChangeSuborderCreditCardRequestFromJSON,
    ChangeSuborderCreditCardRequestToJSON,
    ConfirmPackingSlipRequestFromJSON,
    ConfirmPackingSlipRequestToJSON,
    CreateBalanceOrderRequestFromJSON,
    CreateBalanceOrderRequestToJSON,
    CreateOrderRequestFromJSON,
    CreateOrderRequestToJSON,
    CreateOrderResponseFromJSON,
    CreateOrderResponseToJSON,
    CreateOrderReturnRequestFromJSON,
    CreateOrderReturnRequestToJSON,
    CreatePackingSlipRequestFromJSON,
    CreatePackingSlipRequestToJSON,
    DeclineSuborderRequestFromJSON,
    DeclineSuborderRequestToJSON,
    DeletePackingSlipsRequestFromJSON,
    DeletePackingSlipsRequestToJSON,
    DropdownItemFromJSON,
    DropdownItemToJSON,
    DuplicateOrderResponseFromJSON,
    DuplicateOrderResponseToJSON,
    EstimateShippingRequestFromJSON,
    EstimateShippingRequestToJSON,
    EstimateShippingResponseFromJSON,
    EstimateShippingResponseToJSON,
    GetDeletedVariantsRequestFromJSON,
    GetDeletedVariantsRequestToJSON,
    GetDeletedVariantsResponseFromJSON,
    GetDeletedVariantsResponseToJSON,
    GetEnumOptionsResponseOrderStatusFromJSON,
    GetEnumOptionsResponseOrderStatusToJSON,
    GetOrderResponseFromJSON,
    GetOrderResponseToJSON,
    GetPayInvoiceInformationResponseFromJSON,
    GetPayInvoiceInformationResponseToJSON,
    GetPaymentResponseFromJSON,
    GetPaymentResponseToJSON,
    GetReorderResponseFromJSON,
    GetReorderResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    InvoiceResponseFromJSON,
    InvoiceResponseToJSON,
    MinimumOrderValueValidationRequestFromJSON,
    MinimumOrderValueValidationRequestToJSON,
    MinimumOrderValuesResponseFromJSON,
    MinimumOrderValuesResponseToJSON,
    OrderCountByBrandResponseFromJSON,
    OrderCountByBrandResponseToJSON,
    OrderPreviewResponseFromJSON,
    OrderPreviewResponseToJSON,
    OrderResponseFromJSON,
    OrderResponseToJSON,
    OrderReturnReasonResponseFromJSON,
    OrderReturnReasonResponseToJSON,
    OrderReturnResponseFromJSON,
    OrderReturnResponseToJSON,
    PackingSlipResponseFromJSON,
    PackingSlipResponseToJSON,
    PaginatedOrdersForSubordersResponseFromJSON,
    PaginatedOrdersForSubordersResponseToJSON,
    PaginatedOrdersResponseFromJSON,
    PaginatedOrdersResponseToJSON,
    PayInvoiceResponseFromJSON,
    PayInvoiceResponseToJSON,
    ShippingCarrierResponseFromJSON,
    ShippingCarrierResponseToJSON,
    SplitShipRequestFromJSON,
    SplitShipRequestToJSON,
    SplitShipResponseFromJSON,
    SplitShipResponseToJSON,
    SuborderResponseFromJSON,
    SuborderResponseToJSON,
    UpdateExternalServiceRequestFromJSON,
    UpdateExternalServiceRequestToJSON,
    UpdateOrderReturnItemsRequestFromJSON,
    UpdateOrderReturnItemsRequestToJSON,
    UpdateOrderReturnRequestFromJSON,
    UpdateOrderReturnRequestToJSON,
    UpdatePackingSlipRequestFromJSON,
    UpdatePackingSlipRequestToJSON,
    UpdateSuborderCreditCardRequestFromJSON,
    UpdateSuborderCreditCardRequestToJSON,
    UpdateSuborderRequestFromJSON,
    UpdateSuborderRequestToJSON,
    UpdateSuborderResponseFromJSON,
    UpdateSuborderResponseToJSON,
} from '../models/index';

export interface AcceptSuborderRequest {
    id: string;
}

export interface AddSuborderShippingInfoOperationRequest {
    id: string;
    addSuborderShippingInfoRequest: AddSuborderShippingInfoRequest;
}

export interface BalanceFailedPaymentCaptureRequest {
    id: string;
    body: string;
}

export interface BatchSplitShipRequest {
    suborderId: string;
    batchSplitShipmentRequest: BatchSplitShipmentRequest;
}

export interface BrandOrderReminderRequest {
    id: string;
    body: string;
}

export interface CaptureFailPaymentRequest {
    id: string;
    body: string;
}

export interface ChangeSuborderBillingAddressRequest {
    suborderId: string;
    addressId: string;
}

export interface ChangeSuborderCardRequest {
    id: string;
    changeSuborderCreditCardRequest: ChangeSuborderCreditCardRequest;
}

export interface ChargeSuborderRequest {
    id: string;
    body: string;
}

export interface ConfirmPackingSlipOperationRequest {
    id: string;
    confirmPackingSlipRequest: ConfirmPackingSlipRequest;
}

export interface CreateBalanceOrderOperationRequest {
    createBalanceOrderRequest: CreateBalanceOrderRequest;
}

export interface CreateOrderOperationRequest {
    createOrderRequest: CreateOrderRequest;
}

export interface CreateOrderReturnOperationRequest {
    id: string;
    createOrderReturnRequest: CreateOrderReturnRequest;
}

export interface CreatePackingSlipOperationRequest {
    suborderId: string;
    createPackingSlipRequest: CreatePackingSlipRequest;
}

export interface DeclineSuborderOperationRequest {
    id: string;
    declineSuborderRequest: DeclineSuborderRequest;
}

export interface DelcinePackingSlipRequest {
    id: string;
}

export interface DeleteOrderRequest {
    id: string;
}

export interface DeletePackingSlipsOperationRequest {
    deletePackingSlipsRequest: DeletePackingSlipsRequest;
}

export interface DraftPackingSlipRequest {
    id: string;
}

export interface EstimateShippingOperationRequest {
    estimateShippingRequest: EstimateShippingRequest;
}

export interface ExpireSuborderRequest {
    id: string;
    body: string;
}

export interface FindDuplicateOrderRequest {
    start: Date;
    end: Date;
    delta: number;
}

export interface GenerateOrderReturnSlipRequest {
    id: string;
}

export interface GeneratePackingSlipRequest {
    id: string;
}

export interface GetDeletedVariantsOperationRequest {
    getDeletedVariantsRequest: GetDeletedVariantsRequest;
}

export interface GetInvoiceBySuborderIdRequest {
    id: string;
}

export interface GetMyOrderCountByBrandRequest {
    brandId: string;
}

export interface GetOrderDetailsRequest {
    id: string;
}

export interface GetOrderDetailsForSuborderRequest {
    id: string;
}

export interface GetOrderReturnRequest {
    id: string;
}

export interface GetOrdersByBrandRequest {
    brandId: string;
    retailerId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
    status?: string;
}

export interface GetOrdersByRetailerRequest {
    retailerId: string;
    page?: number;
    size?: number;
    searchBy?: string;
    status?: string;
    sortName?: string;
    sortValue?: string;
}

export interface GetOrdersFilteredRequest {
    retailerId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
    status?: string;
}

export interface GetPackingSlipRequest {
    suborderId: string;
    id: string;
}

export interface GetPackingSlipsRequest {
    suborderId: string;
}

export interface GetPackingSlipsByOrderRequest {
    id: string;
}

export interface GetPayInvoiceInfoRequest {
    id: string;
}

export interface GetPaymentBySuborderIdRequest {
    id: string;
}

export interface GetReordersRequest {
    page: number;
    size: number;
    query?: string;
    sort?: string;
}

export interface GetSuborderRequest {
    id: string;
}

export interface PayInvoiceRequest {
    suborderId: string;
}

export interface PaySuborderRequest {
    id: string;
}

export interface RetailerGetOrderRequest {
    id: string;
}

export interface SendChargeDateApproachingEmailRequest {
    id: string;
    body: string;
}

export interface SetSuborderAsPartiallyShippedRequest {
    id: string;
}

export interface SplitShipOperationRequest {
    suborderId: string;
    splitShipRequest: SplitShipRequest;
}

export interface UpdateAndAcceptSuborderRequest {
    id: string;
    updateSuborderRequest: UpdateSuborderRequest;
}

export interface UpdateExternalServiceOperationRequest {
    updateExternalServiceRequest: UpdateExternalServiceRequest;
}

export interface UpdateOrderReturnOperationRequest {
    id: string;
    updateOrderReturnRequest: UpdateOrderReturnRequest;
}

export interface UpdateOrderReturnItemsOperationRequest {
    id: string;
    updateOrderReturnItemsRequest: UpdateOrderReturnItemsRequest;
}

export interface UpdatePackingSlipOperationRequest {
    id: string;
    updatePackingSlipRequest: UpdatePackingSlipRequest;
}

export interface UpdateSuborderOperationRequest {
    id: string;
    updateSuborderRequest: UpdateSuborderRequest;
}

export interface UpdateSuborderCardRequest {
    suborderId: string;
    updateSuborderCreditCardRequest: UpdateSuborderCreditCardRequest;
}

export interface ValidateMinimumOrderValueRequest {
    minimumOrderValueValidationRequest: MinimumOrderValueValidationRequest;
}

/**
 * 
 */
export class OrderControllerApi extends runtime.BaseAPI {

    /**
     * Accept suborder
     */
    async acceptSuborderRaw(requestParameters: AcceptSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling acceptSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept suborder
     */
    async acceptSuborder(requestParameters: AcceptSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptSuborderRaw(requestParameters, initOverrides);
    }

    /**
     * Add suborder shipping info
     */
    async addSuborderShippingInfoRaw(requestParameters: AddSuborderShippingInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addSuborderShippingInfo().'
            );
        }

        if (requestParameters['addSuborderShippingInfoRequest'] == null) {
            throw new runtime.RequiredError(
                'addSuborderShippingInfoRequest',
                'Required parameter "addSuborderShippingInfoRequest" was null or undefined when calling addSuborderShippingInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/add-shipping-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddSuborderShippingInfoRequestToJSON(requestParameters['addSuborderShippingInfoRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add suborder shipping info
     */
    async addSuborderShippingInfo(requestParameters: AddSuborderShippingInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addSuborderShippingInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Hook for charging an suborder. After suborder is shipped, payment is scheduled for specific date on Posthook. Once it is time for payment, this endpoint is hit by the Posthook.
     */
    async balanceFailedPaymentCaptureRaw(requestParameters: BalanceFailedPaymentCaptureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling balanceFailedPaymentCapture().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling balanceFailedPaymentCapture().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/balance-fail-payment-capture`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Hook for charging an suborder. After suborder is shipped, payment is scheduled for specific date on Posthook. Once it is time for payment, this endpoint is hit by the Posthook.
     */
    async balanceFailedPaymentCapture(requestParameters: BalanceFailedPaymentCaptureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.balanceFailedPaymentCaptureRaw(requestParameters, initOverrides);
    }

    /**
     * Split packing slip in batch
     */
    async batchSplitShipRaw(requestParameters: BatchSplitShipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling batchSplitShip().'
            );
        }

        if (requestParameters['batchSplitShipmentRequest'] == null) {
            throw new runtime.RequiredError(
                'batchSplitShipmentRequest',
                'Required parameter "batchSplitShipmentRequest" was null or undefined when calling batchSplitShip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/batch-split-ship`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchSplitShipmentRequestToJSON(requestParameters['batchSplitShipmentRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Split packing slip in batch
     */
    async batchSplitShip(requestParameters: BatchSplitShipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.batchSplitShipRaw(requestParameters, initOverrides);
    }

    /**
     * Send brand order reminder emails
     */
    async brandOrderReminderRaw(requestParameters: BrandOrderReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling brandOrderReminder().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling brandOrderReminder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/send-reminder`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send brand order reminder emails
     */
    async brandOrderReminder(requestParameters: BrandOrderReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.brandOrderReminderRaw(requestParameters, initOverrides);
    }

    /**
     * Hook for charging an suborder. After suborder is shipped, payment is scheduled for specific date on Posthook. Once it is time for payment, this endpoint is hit by the Posthook.
     */
    async captureFailPaymentRaw(requestParameters: CaptureFailPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling captureFailPayment().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling captureFailPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/fail-payment-capture`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Hook for charging an suborder. After suborder is shipped, payment is scheduled for specific date on Posthook. Once it is time for payment, this endpoint is hit by the Posthook.
     */
    async captureFailPayment(requestParameters: CaptureFailPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.captureFailPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Change billing address of suborder
     */
    async changeSuborderBillingAddressRaw(requestParameters: ChangeSuborderBillingAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling changeSuborderBillingAddress().'
            );
        }

        if (requestParameters['addressId'] == null) {
            throw new runtime.RequiredError(
                'addressId',
                'Required parameter "addressId" was null or undefined when calling changeSuborderBillingAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/billing-address/{addressId}`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))).replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters['addressId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change billing address of suborder
     */
    async changeSuborderBillingAddress(requestParameters: ChangeSuborderBillingAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeSuborderBillingAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Change suborder\'s credit card
     */
    async changeSuborderCardRaw(requestParameters: ChangeSuborderCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling changeSuborderCard().'
            );
        }

        if (requestParameters['changeSuborderCreditCardRequest'] == null) {
            throw new runtime.RequiredError(
                'changeSuborderCreditCardRequest',
                'Required parameter "changeSuborderCreditCardRequest" was null or undefined when calling changeSuborderCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/change-card`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeSuborderCreditCardRequestToJSON(requestParameters['changeSuborderCreditCardRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change suborder\'s credit card
     */
    async changeSuborderCard(requestParameters: ChangeSuborderCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeSuborderCardRaw(requestParameters, initOverrides);
    }

    /**
     * Hook for charging an suborder. After suborder is shipped, payment is scheduled for specific date on Posthook. Once it is time for payment, this endpoint is hit by the Posthook.
     */
    async chargeSuborderRaw(requestParameters: ChargeSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling chargeSuborder().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling chargeSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/charge-v2`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Hook for charging an suborder. After suborder is shipped, payment is scheduled for specific date on Posthook. Once it is time for payment, this endpoint is hit by the Posthook.
     */
    async chargeSuborder(requestParameters: ChargeSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chargeSuborderRaw(requestParameters, initOverrides);
    }

    /**
     * Confirm packing slip
     */
    async confirmPackingSlipRaw(requestParameters: ConfirmPackingSlipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling confirmPackingSlip().'
            );
        }

        if (requestParameters['confirmPackingSlipRequest'] == null) {
            throw new runtime.RequiredError(
                'confirmPackingSlipRequest',
                'Required parameter "confirmPackingSlipRequest" was null or undefined when calling confirmPackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/packing-slips/{id}/confirm`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmPackingSlipRequestToJSON(requestParameters['confirmPackingSlipRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm packing slip
     */
    async confirmPackingSlip(requestParameters: ConfirmPackingSlipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmPackingSlipRaw(requestParameters, initOverrides);
    }

    /**
     * Create balance order
     */
    async createBalanceOrderRaw(requestParameters: CreateBalanceOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrderResponse>> {
        if (requestParameters['createBalanceOrderRequest'] == null) {
            throw new runtime.RequiredError(
                'createBalanceOrderRequest',
                'Required parameter "createBalanceOrderRequest" was null or undefined when calling createBalanceOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/balance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBalanceOrderRequestToJSON(requestParameters['createBalanceOrderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrderResponseFromJSON(jsonValue));
    }

    /**
     * Create balance order
     */
    async createBalanceOrder(requestParameters: CreateBalanceOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrderResponse> {
        const response = await this.createBalanceOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create order
     */
    async createOrderRaw(requestParameters: CreateOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrderResponse>> {
        if (requestParameters['createOrderRequest'] == null) {
            throw new runtime.RequiredError(
                'createOrderRequest',
                'Required parameter "createOrderRequest" was null or undefined when calling createOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderRequestToJSON(requestParameters['createOrderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrderResponseFromJSON(jsonValue));
    }

    /**
     * Create order
     */
    async createOrder(requestParameters: CreateOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrderResponse> {
        const response = await this.createOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create order return
     */
    async createOrderReturnRaw(requestParameters: CreateOrderReturnOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createOrderReturn().'
            );
        }

        if (requestParameters['createOrderReturnRequest'] == null) {
            throw new runtime.RequiredError(
                'createOrderReturnRequest',
                'Required parameter "createOrderReturnRequest" was null or undefined when calling createOrderReturn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/order-returns`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderReturnRequestToJSON(requestParameters['createOrderReturnRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create order return
     */
    async createOrderReturn(requestParameters: CreateOrderReturnOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createOrderReturnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create packing slip
     */
    async createPackingSlipRaw(requestParameters: CreatePackingSlipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling createPackingSlip().'
            );
        }

        if (requestParameters['createPackingSlipRequest'] == null) {
            throw new runtime.RequiredError(
                'createPackingSlipRequest',
                'Required parameter "createPackingSlipRequest" was null or undefined when calling createPackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/packing-slips`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePackingSlipRequestToJSON(requestParameters['createPackingSlipRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create packing slip
     */
    async createPackingSlip(requestParameters: CreatePackingSlipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createPackingSlipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Decline suborder
     */
    async declineSuborderRaw(requestParameters: DeclineSuborderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling declineSuborder().'
            );
        }

        if (requestParameters['declineSuborderRequest'] == null) {
            throw new runtime.RequiredError(
                'declineSuborderRequest',
                'Required parameter "declineSuborderRequest" was null or undefined when calling declineSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeclineSuborderRequestToJSON(requestParameters['declineSuborderRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decline suborder
     */
    async declineSuborder(requestParameters: DeclineSuborderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.declineSuborderRaw(requestParameters, initOverrides);
    }

    /**
     * Decline packing slip
     */
    async delcinePackingSlipRaw(requestParameters: DelcinePackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling delcinePackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/packing-slips/{id}/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decline packing slip
     */
    async delcinePackingSlip(requestParameters: DelcinePackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.delcinePackingSlipRaw(requestParameters, initOverrides);
    }

    /**
     * Delete order
     */
    async deleteOrderRaw(requestParameters: DeleteOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete order
     */
    async deleteOrder(requestParameters: DeleteOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Delete packing slips
     */
    async deletePackingSlipsRaw(requestParameters: DeletePackingSlipsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['deletePackingSlipsRequest'] == null) {
            throw new runtime.RequiredError(
                'deletePackingSlipsRequest',
                'Required parameter "deletePackingSlipsRequest" was null or undefined when calling deletePackingSlips().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/packing-slips`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeletePackingSlipsRequestToJSON(requestParameters['deletePackingSlipsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete packing slips
     */
    async deletePackingSlips(requestParameters: DeletePackingSlipsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePackingSlipsRaw(requestParameters, initOverrides);
    }

    /**
     * Draft packing slip
     */
    async draftPackingSlipRaw(requestParameters: DraftPackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling draftPackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/packing-slips/{id}/draft`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Draft packing slip
     */
    async draftPackingSlip(requestParameters: DraftPackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.draftPackingSlipRaw(requestParameters, initOverrides);
    }

    /**
     * Estimate shipping cost using ShipEngine API
     */
    async estimateShippingRaw(requestParameters: EstimateShippingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EstimateShippingResponse>> {
        if (requestParameters['estimateShippingRequest'] == null) {
            throw new runtime.RequiredError(
                'estimateShippingRequest',
                'Required parameter "estimateShippingRequest" was null or undefined when calling estimateShipping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/estimate-shipping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EstimateShippingRequestToJSON(requestParameters['estimateShippingRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EstimateShippingResponseFromJSON(jsonValue));
    }

    /**
     * Estimate shipping cost using ShipEngine API
     */
    async estimateShipping(requestParameters: EstimateShippingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EstimateShippingResponse> {
        const response = await this.estimateShippingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Changes suborder status on expired
     */
    async expireSuborderRaw(requestParameters: ExpireSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling expireSuborder().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling expireSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/expire`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Changes suborder status on expired
     */
    async expireSuborder(requestParameters: ExpireSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.expireSuborderRaw(requestParameters, initOverrides);
    }

    /**
     * Export brand orders to csv file
     */
    async exportBrandOrdersToCsvRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/brands/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export brand orders to csv file
     */
    async exportBrandOrdersToCsv(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportBrandOrdersToCsvRaw(initOverrides);
        return await response.value();
    }

    /**
     * Export orders to csv file
     */
    async exportOrdersToCsvRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export orders to csv file
     */
    async exportOrdersToCsv(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportOrdersToCsvRaw(initOverrides);
        return await response.value();
    }

    /**
     * Export retailer orders to csv file
     */
    async exportRetailerOrdersToCsvRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/retailers/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export retailer orders to csv file
     */
    async exportRetailerOrdersToCsv(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportRetailerOrdersToCsvRaw(initOverrides);
        return await response.value();
    }

    /**
     * find all the duplicate order between provided date with delta if provided.
     */
    async findDuplicateOrderRaw(requestParameters: FindDuplicateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateOrderResponse>> {
        if (requestParameters['start'] == null) {
            throw new runtime.RequiredError(
                'start',
                'Required parameter "start" was null or undefined when calling findDuplicateOrder().'
            );
        }

        if (requestParameters['end'] == null) {
            throw new runtime.RequiredError(
                'end',
                'Required parameter "end" was null or undefined when calling findDuplicateOrder().'
            );
        }

        if (requestParameters['delta'] == null) {
            throw new runtime.RequiredError(
                'delta',
                'Required parameter "delta" was null or undefined when calling findDuplicateOrder().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['start'] != null) {
            queryParameters['start'] = (requestParameters['start'] as any).toISOString();
        }

        if (requestParameters['end'] != null) {
            queryParameters['end'] = (requestParameters['end'] as any).toISOString();
        }

        if (requestParameters['delta'] != null) {
            queryParameters['delta'] = requestParameters['delta'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/duplicate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DuplicateOrderResponseFromJSON(jsonValue));
    }

    /**
     * find all the duplicate order between provided date with delta if provided.
     */
    async findDuplicateOrder(requestParameters: FindDuplicateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateOrderResponse> {
        const response = await this.findDuplicateOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate order return slip
     */
    async generateOrderReturnSlipRaw(requestParameters: GenerateOrderReturnSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling generateOrderReturnSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-returns/{id}/print`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate order return slip
     */
    async generateOrderReturnSlip(requestParameters: GenerateOrderReturnSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.generateOrderReturnSlipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate packing slip
     */
    async generatePackingSlipRaw(requestParameters: GeneratePackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling generatePackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/packing-slips/{id}/print`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate packing slip
     */
    async generatePackingSlip(requestParameters: GeneratePackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.generatePackingSlipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all decline order reasons
     */
    async getDeclineReasonRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DropdownItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/decline-reasons/dropdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DropdownItemFromJSON));
    }

    /**
     * Returns all decline order reasons
     */
    async getDeclineReason(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DropdownItem>> {
        const response = await this.getDeclineReasonRaw(initOverrides);
        return await response.value();
    }

    /**
     * Cart validation, receives array of ProductVariant UUIDs. Returns deleted variants with details.
     */
    async getDeletedVariantsRaw(requestParameters: GetDeletedVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDeletedVariantsResponse>> {
        if (requestParameters['getDeletedVariantsRequest'] == null) {
            throw new runtime.RequiredError(
                'getDeletedVariantsRequest',
                'Required parameter "getDeletedVariantsRequest" was null or undefined when calling getDeletedVariants().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/check-deleted-products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetDeletedVariantsRequestToJSON(requestParameters['getDeletedVariantsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeletedVariantsResponseFromJSON(jsonValue));
    }

    /**
     * Cart validation, receives array of ProductVariant UUIDs. Returns deleted variants with details.
     */
    async getDeletedVariants(requestParameters: GetDeletedVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDeletedVariantsResponse> {
        const response = await this.getDeletedVariantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Invoice by Suborder Id
     */
    async getInvoiceBySuborderIdRaw(requestParameters: GetInvoiceBySuborderIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getInvoiceBySuborderId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/invoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceResponseFromJSON(jsonValue));
    }

    /**
     * Get Invoice by Suborder Id
     */
    async getInvoiceBySuborderId(requestParameters: GetInvoiceBySuborderIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceResponse> {
        const response = await this.getInvoiceBySuborderIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all minimum order values
     */
    async getMinimumOrderValuesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MinimumOrderValuesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/minimum-order-values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MinimumOrderValuesResponseFromJSON(jsonValue));
    }

    /**
     * Returns all minimum order values
     */
    async getMinimumOrderValues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MinimumOrderValuesResponse> {
        const response = await this.getMinimumOrderValuesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns number of orders created by retailer for given brand
     */
    async getMyOrderCountByBrandRaw(requestParameters: GetMyOrderCountByBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderCountByBrandResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getMyOrderCountByBrand().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['brandId'] != null) {
            queryParameters['brand_id'] = requestParameters['brandId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderCountByBrandResponseFromJSON(jsonValue));
    }

    /**
     * Returns number of orders created by retailer for given brand
     */
    async getMyOrderCountByBrand(requestParameters: GetMyOrderCountByBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderCountByBrandResponse> {
        const response = await this.getMyOrderCountByBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get order details by Id
     */
    async getOrderDetailsRaw(requestParameters: GetOrderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderResponseFromJSON(jsonValue));
    }

    /**
     * Get order details by Id
     */
    async getOrderDetails(requestParameters: GetOrderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderResponse> {
        const response = await this.getOrderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get order preview
     */
    async getOrderDetailsForSuborderRaw(requestParameters: GetOrderDetailsForSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderPreviewResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderDetailsForSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/preview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderPreviewResponseFromJSON(jsonValue));
    }

    /**
     * Get order preview
     */
    async getOrderDetailsForSuborder(requestParameters: GetOrderDetailsForSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderPreviewResponse> {
        const response = await this.getOrderDetailsForSuborderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get order return by Id
     */
    async getOrderReturnRaw(requestParameters: GetOrderReturnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderReturnResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderReturn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-returns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderReturnResponseFromJSON(jsonValue));
    }

    /**
     * Get order return by Id
     */
    async getOrderReturn(requestParameters: GetOrderReturnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderReturnResponse> {
        const response = await this.getOrderReturnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all order return reasons
     */
    async getOrderReturnReasonsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderReturnReasonResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-returns/order-return-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderReturnReasonResponseFromJSON));
    }

    /**
     * Get all order return reasons
     */
    async getOrderReturnReasons(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderReturnReasonResponse>> {
        const response = await this.getOrderReturnReasonsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all order returns
     */
    async getOrderReturnsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderReturnResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-returns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderReturnResponseFromJSON));
    }

    /**
     * Get all order returns
     */
    async getOrderReturns(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderReturnResponse>> {
        const response = await this.getOrderReturnsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get order statuses for dropdown
     */
    async getOrderStatusesDropdownRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEnumOptionsResponseOrderStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnumOptionsResponseOrderStatusFromJSON(jsonValue));
    }

    /**
     * Get order statuses for dropdown
     */
    async getOrderStatusesDropdown(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEnumOptionsResponseOrderStatus> {
        const response = await this.getOrderStatusesDropdownRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all orders
     */
    async getOrdersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderResponseFromJSON));
    }

    /**
     * Get all orders
     */
    async getOrders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderResponse>> {
        const response = await this.getOrdersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all orders for provided brand
     */
    async getOrdersByBrandRaw(requestParameters: GetOrdersByBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOrdersForSubordersResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getOrdersByBrand().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/brand`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOrdersForSubordersResponseFromJSON(jsonValue));
    }

    /**
     * Find all orders for provided brand
     */
    async getOrdersByBrand(requestParameters: GetOrdersByBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOrdersForSubordersResponse> {
        const response = await this.getOrdersByBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all orders for provided retailer
     */
    async getOrdersByRetailerRaw(requestParameters: GetOrdersByRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOrdersForSubordersResponse>> {
        if (requestParameters['retailerId'] == null) {
            throw new runtime.RequiredError(
                'retailerId',
                'Required parameter "retailerId" was null or undefined when calling getOrdersByRetailer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['sortName'] != null) {
            queryParameters['sortName'] = requestParameters['sortName'];
        }

        if (requestParameters['sortValue'] != null) {
            queryParameters['sortValue'] = requestParameters['sortValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/retailer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOrdersForSubordersResponseFromJSON(jsonValue));
    }

    /**
     * Find all orders for provided retailer
     */
    async getOrdersByRetailer(requestParameters: GetOrdersByRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOrdersForSubordersResponse> {
        const response = await this.getOrdersByRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all orders per multiple/custom criteria
     */
    async getOrdersFilteredRaw(requestParameters: GetOrdersFilteredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOrdersResponse>> {
        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOrdersResponseFromJSON(jsonValue));
    }

    /**
     * Find all orders per multiple/custom criteria
     */
    async getOrdersFiltered(requestParameters: GetOrdersFilteredRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOrdersResponse> {
        const response = await this.getOrdersFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get packing slip by Id
     */
    async getPackingSlipRaw(requestParameters: GetPackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PackingSlipResponse>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling getPackingSlip().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/packing-slips/{id}`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PackingSlipResponseFromJSON(jsonValue));
    }

    /**
     * Get packing slip by Id
     */
    async getPackingSlip(requestParameters: GetPackingSlipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PackingSlipResponse> {
        const response = await this.getPackingSlipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get packing slips by suborder Id
     */
    async getPackingSlipsRaw(requestParameters: GetPackingSlipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PackingSlipResponse>>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling getPackingSlips().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/packing slips`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackingSlipResponseFromJSON));
    }

    /**
     * Get packing slips by suborder Id
     */
    async getPackingSlips(requestParameters: GetPackingSlipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PackingSlipResponse>> {
        const response = await this.getPackingSlipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get packing slip by order Id
     */
    async getPackingSlipsByOrderRaw(requestParameters: GetPackingSlipsByOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PackingSlipResponse>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPackingSlipsByOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/packing-slips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackingSlipResponseFromJSON));
    }

    /**
     * Get packing slip by order Id
     */
    async getPackingSlipsByOrder(requestParameters: GetPackingSlipsByOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PackingSlipResponse>> {
        const response = await this.getPackingSlipsByOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get information for billing address and card
     */
    async getPayInvoiceInfoRaw(requestParameters: GetPayInvoiceInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPayInvoiceInformationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPayInvoiceInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/pay-invoice-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPayInvoiceInformationResponseFromJSON(jsonValue));
    }

    /**
     * Get information for billing address and card
     */
    async getPayInvoiceInfo(requestParameters: GetPayInvoiceInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPayInvoiceInformationResponse> {
        const response = await this.getPayInvoiceInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Payment by Suborder Id
     */
    async getPaymentBySuborderIdRaw(requestParameters: GetPaymentBySuborderIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPaymentBySuborderId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/payment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentResponseFromJSON(jsonValue));
    }

    /**
     * Get Payment by Suborder Id
     */
    async getPaymentBySuborderId(requestParameters: GetPaymentBySuborderIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentResponse> {
        const response = await this.getPaymentBySuborderIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all retailer reorders
     */
    async getReordersRaw(requestParameters: GetReordersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReorderResponse>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getReorders().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getReorders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/reorders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReorderResponseFromJSON(jsonValue));
    }

    /**
     * Returns all retailer reorders
     */
    async getReorders(requestParameters: GetReordersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReorderResponse> {
        const response = await this.getReordersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all shipping carriers
     */
    async getShippingCarriersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShippingCarrierResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/shipping-carriers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShippingCarrierResponseFromJSON));
    }

    /**
     * Get all shipping carriers
     */
    async getShippingCarriers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShippingCarrierResponse>> {
        const response = await this.getShippingCarriersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get suborder by Id
     */
    async getSuborderRaw(requestParameters: GetSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuborderResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuborderResponseFromJSON(jsonValue));
    }

    /**
     * Get suborder by Id
     */
    async getSuborder(requestParameters: GetSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuborderResponse> {
        const response = await this.getSuborderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pay on invoice
     */
    async payInvoiceRaw(requestParameters: PayInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayInvoiceResponse>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling payInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/pay-invoice`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayInvoiceResponseFromJSON(jsonValue));
    }

    /**
     * Pay on invoice
     */
    async payInvoice(requestParameters: PayInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayInvoiceResponse> {
        const response = await this.payInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pay suborder
     */
    async paySuborderRaw(requestParameters: PaySuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling paySuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/paid`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pay suborder
     */
    async paySuborder(requestParameters: PaySuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.paySuborderRaw(requestParameters, initOverrides);
    }

    /**
     * Get order confirmation data by Id
     */
    async retailerGetOrderRaw(requestParameters: RetailerGetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling retailerGetOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/{id}/confirmation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderResponseFromJSON(jsonValue));
    }

    /**
     * Get order confirmation data by Id
     */
    async retailerGetOrder(requestParameters: RetailerGetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderResponse> {
        const response = await this.retailerGetOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Hook for sending charge date approaching email 7 days before charging suborder
     */
    async sendChargeDateApproachingEmailRaw(requestParameters: SendChargeDateApproachingEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendChargeDateApproachingEmail().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling sendChargeDateApproachingEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/charge-date-approaching-email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Hook for sending charge date approaching email 7 days before charging suborder
     */
    async sendChargeDateApproachingEmail(requestParameters: SendChargeDateApproachingEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendChargeDateApproachingEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Set suborder status as partially shipped
     */
    async setSuborderAsPartiallyShippedRaw(requestParameters: SetSuborderAsPartiallyShippedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling setSuborderAsPartiallyShipped().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/partially-shipped`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set suborder status as partially shipped
     */
    async setSuborderAsPartiallyShipped(requestParameters: SetSuborderAsPartiallyShippedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setSuborderAsPartiallyShippedRaw(requestParameters, initOverrides);
    }

    /**
     * Create multiple packing slips using split shipment
     */
    async splitShipRaw(requestParameters: SplitShipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SplitShipResponse>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling splitShip().'
            );
        }

        if (requestParameters['splitShipRequest'] == null) {
            throw new runtime.RequiredError(
                'splitShipRequest',
                'Required parameter "splitShipRequest" was null or undefined when calling splitShip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{suborderId}/split-ship`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SplitShipRequestToJSON(requestParameters['splitShipRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SplitShipResponseFromJSON(jsonValue));
    }

    /**
     * Create multiple packing slips using split shipment
     */
    async splitShip(requestParameters: SplitShipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SplitShipResponse> {
        const response = await this.splitShipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update and accept suborder
     */
    async updateAndAcceptSuborderRaw(requestParameters: UpdateAndAcceptSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateSuborderResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAndAcceptSuborder().'
            );
        }

        if (requestParameters['updateSuborderRequest'] == null) {
            throw new runtime.RequiredError(
                'updateSuborderRequest',
                'Required parameter "updateSuborderRequest" was null or undefined when calling updateAndAcceptSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}/update-accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSuborderRequestToJSON(requestParameters['updateSuborderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSuborderResponseFromJSON(jsonValue));
    }

    /**
     * Update and accept suborder
     */
    async updateAndAcceptSuborder(requestParameters: UpdateAndAcceptSuborderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateSuborderResponse> {
        const response = await this.updateAndAcceptSuborderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update algolia and salesforce by suborder number
     */
    async updateExternalServiceRaw(requestParameters: UpdateExternalServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateExternalServiceRequest'] == null) {
            throw new runtime.RequiredError(
                'updateExternalServiceRequest',
                'Required parameter "updateExternalServiceRequest" was null or undefined when calling updateExternalService().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/update-external-service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateExternalServiceRequestToJSON(requestParameters['updateExternalServiceRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update algolia and salesforce by suborder number
     */
    async updateExternalService(requestParameters: UpdateExternalServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateExternalServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Update order return
     */
    async updateOrderReturnRaw(requestParameters: UpdateOrderReturnOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOrderReturn().'
            );
        }

        if (requestParameters['updateOrderReturnRequest'] == null) {
            throw new runtime.RequiredError(
                'updateOrderReturnRequest',
                'Required parameter "updateOrderReturnRequest" was null or undefined when calling updateOrderReturn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-returns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrderReturnRequestToJSON(requestParameters['updateOrderReturnRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update order return
     */
    async updateOrderReturn(requestParameters: UpdateOrderReturnOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderReturnRaw(requestParameters, initOverrides);
    }

    /**
     * Update order return items
     */
    async updateOrderReturnItemsRaw(requestParameters: UpdateOrderReturnItemsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOrderReturnItems().'
            );
        }

        if (requestParameters['updateOrderReturnItemsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateOrderReturnItemsRequest',
                'Required parameter "updateOrderReturnItemsRequest" was null or undefined when calling updateOrderReturnItems().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/order-returns/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrderReturnItemsRequestToJSON(requestParameters['updateOrderReturnItemsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update order return items
     */
    async updateOrderReturnItems(requestParameters: UpdateOrderReturnItemsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderReturnItemsRaw(requestParameters, initOverrides);
    }

    /**
     * Update packing slip
     */
    async updatePackingSlipRaw(requestParameters: UpdatePackingSlipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePackingSlip().'
            );
        }

        if (requestParameters['updatePackingSlipRequest'] == null) {
            throw new runtime.RequiredError(
                'updatePackingSlipRequest',
                'Required parameter "updatePackingSlipRequest" was null or undefined when calling updatePackingSlip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/packing-slips/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePackingSlipRequestToJSON(requestParameters['updatePackingSlipRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update packing slip
     */
    async updatePackingSlip(requestParameters: UpdatePackingSlipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePackingSlipRaw(requestParameters, initOverrides);
    }

    /**
     * Update suborder
     */
    async updateSuborderRaw(requestParameters: UpdateSuborderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateSuborderResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSuborder().'
            );
        }

        if (requestParameters['updateSuborderRequest'] == null) {
            throw new runtime.RequiredError(
                'updateSuborderRequest',
                'Required parameter "updateSuborderRequest" was null or undefined when calling updateSuborder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/suborders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSuborderRequestToJSON(requestParameters['updateSuborderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSuborderResponseFromJSON(jsonValue));
    }

    /**
     * Update suborder
     */
    async updateSuborder(requestParameters: UpdateSuborderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateSuborderResponse> {
        const response = await this.updateSuborderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Attach card to suborder from invoice
     */
    async updateSuborderCardRaw(requestParameters: UpdateSuborderCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['suborderId'] == null) {
            throw new runtime.RequiredError(
                'suborderId',
                'Required parameter "suborderId" was null or undefined when calling updateSuborderCard().'
            );
        }

        if (requestParameters['updateSuborderCreditCardRequest'] == null) {
            throw new runtime.RequiredError(
                'updateSuborderCreditCardRequest',
                'Required parameter "updateSuborderCreditCardRequest" was null or undefined when calling updateSuborderCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/invoices/{suborderId}/update-card`.replace(`{${"suborderId"}}`, encodeURIComponent(String(requestParameters['suborderId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSuborderCreditCardRequestToJSON(requestParameters['updateSuborderCreditCardRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Attach card to suborder from invoice
     */
    async updateSuborderCard(requestParameters: UpdateSuborderCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSuborderCardRaw(requestParameters, initOverrides);
    }

    /**
     * Validate minimum order value. Checks if total order value is higher or lower than minimum order value.
     */
    async validateMinimumOrderValueRaw(requestParameters: ValidateMinimumOrderValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['minimumOrderValueValidationRequest'] == null) {
            throw new runtime.RequiredError(
                'minimumOrderValueValidationRequest',
                'Required parameter "minimumOrderValueValidationRequest" was null or undefined when calling validateMinimumOrderValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orders/mov-validation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MinimumOrderValueValidationRequestToJSON(requestParameters['minimumOrderValueValidationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate minimum order value. Checks if total order value is higher or lower than minimum order value.
     */
    async validateMinimumOrderValue(requestParameters: ValidateMinimumOrderValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateMinimumOrderValueRaw(requestParameters, initOverrides);
    }

}
