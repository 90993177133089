/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadComponentImageRequest
 */
export interface UploadComponentImageRequest {
    /**
     * Image for upload
     * @type {Blob}
     * @memberof UploadComponentImageRequest
     */
    image: Blob;
}

/**
 * Check if a given object implements the UploadComponentImageRequest interface.
 */
export function instanceOfUploadComponentImageRequest(value: object): value is UploadComponentImageRequest {
    if (!('image' in value) || value['image'] === undefined) return false;
    return true;
}

export function UploadComponentImageRequestFromJSON(json: any): UploadComponentImageRequest {
    return UploadComponentImageRequestFromJSONTyped(json, false);
}

export function UploadComponentImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadComponentImageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'],
    };
}

export function UploadComponentImageRequestToJSON(json: any): UploadComponentImageRequest {
    return UploadComponentImageRequestToJSONTyped(json, false);
}

export function UploadComponentImageRequestToJSONTyped(value?: UploadComponentImageRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image': value['image'],
    };
}

