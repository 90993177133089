<template>
  <transition name="fade">
    <modal :show="true" class="modal--medium" :dark-bg="true" :padded="true" @close="close">
      <div class="modal-body modal-body__white">
        <div class="modal-content">
          <Icon icon-key="close" hover-type="OPACITY" :size="24" class="close-container" :on-click="close" />
          <h2 class="font-size-32">Add New Address</h2>

          <div class="clearfix">
            <text-field
              v-model="addressData.first_name"
              v-validate="{ required: true, min: 1, max: 100 }"
              :maxlength="100"
              placeholder="First Name"
              label="First Name"
              name="first name"
              class="no-margin-bottom"
            />

            <text-field
              v-model="addressData.last_name"
              v-validate="{ required: true, min: 1, max: 100 }"
              :maxlength="100"
              placeholder="Last Name"
              column="2"
              label="Last Name"
              name="last name"
              class="no-margin-bottom"
            />

            <div class="fleft width-100">
              <address-form
                ref="addressForm"
                with-labels
                state-dropdown
                country-dropdown
                :address-changed="onAddressChanged"
                :initial-data="addressData"
              />
            </div>

            <text-field
              v-model="addressData.company"
              :maxlength="100"
              placeholder="Company"
              label="Company"
              show-optional
              name="company"
            />
            <text-field
              v-model="addressData.phone"
              v-validate="{ required: true, max: 64 }"
              :maxlength="64"
              name="phone number"
              label="Phone"
              column="2"
              placeholder="Phone Number"
            />
          </div>

          <div class="clearfix mb-20 mt-20">
            <checkbox-new
              v-model="addressData.billing ? addressData.default_billing : addressData.default_shipping"
              label="Make default"
              @click="toggleDefaultCheckbox"
            />
          </div>

          <div class="action-buttons">
            <bulletin-button variant="tertiary" @click="clearForm"> Clear </bulletin-button>
            <bulletin-button variant="primary" @click="submitAddress"> Save </bulletin-button>
          </div>
        </div>
      </div>
    </modal>
  </transition>
</template>

<script>
import AddressApi from '../../../services/api/addressApi'

const AddressApiService = new AddressApi()
const initialData = {
  first_name: '',
  last_name: '',
  company: '',
  street: '',
  apartment: '',
  city: '',
  zip_code: '',
  phone: '',
  country: '',
  state: '',
  shipping: false,
  billing: false,
  default_shipping: false,
  default_billing: false
}

export default {
  provide() {
    return { parentValidator: this.$validator }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: () => null
    },
    addressType: {
      type: String,
      default: 'shipping'
    }
  },
  data() {
    return {
      addressData: {
        ...initialData,
        ...{ shipping: this.addressType === 'shipping' },
        ...{ billing: this.addressType === 'billing' }
      }
    }
  },
  methods: {
    onAddressChanged,
    clearForm,
    submitAddress,
    toggleDefaultCheckbox
  }
}

async function clearForm() {
  this.addressData = { ...initialData }
  this.$refs.addressForm.clearForm()

  await this.$nextTick()

  const fields = [
    'first name',
    'last name',
    'company',
    'phone number',
    'street',
    'apartment',
    'city',
    'state',
    'zip_code',
    'country'
  ]
  fields.forEach((field) => this.$validator?.errors?.remove(field))
}

function onAddressChanged(addressObject) {
  this.addressData = { ...this.addressData, ...addressObject }
}

async function submitAddress() {
  const valid = await this.$validator.validate()
  if (!valid) return
  try {
    const { data } = await AddressApiService.create(this.addressData)
    if (data?.id) {
      this.close(data?.id)
    }
  } catch (error) {
    this.$handleError(this, error)
  }
}

function toggleDefaultCheckbox() {
  if (this.addressData.shipping) {
    this.addressData.default_shipping = !this.addressData.default_shipping
  } else {
    this.addressData.default_billing = !this.addressData.default_billing
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
.modal-content {
  padding: 80px 40px 40px 40px !important;

  @include respond-to('small') {
    padding: 60px 16px 16px 16px !important;
  }
}

.close-container {
  position: absolute;
  right: 40px !important;
  top: 40px !important;
  @include respond-to('small') {
    right: 16px !important;
    top: 24px !important;
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  .primary-button {
    margin-left: 10px;
  }

  @include respond-to('small') {
    flex-direction: column-reverse;
    button {
      width: 100%;
    }

    .primary-button {
      margin-left: 0;
    }
    .tertiary-button {
      margin-top: 10px;
    }
  }
}
</style>
