/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProductImageCropInfoRequest
 */
export interface UpdateProductImageCropInfoRequest {
    /**
     * Image
     * @type {Blob}
     * @memberof UpdateProductImageCropInfoRequest
     */
    image: Blob;
    /**
     * Crop data
     * @type {object}
     * @memberof UpdateProductImageCropInfoRequest
     */
    crop_params: object;
}

/**
 * Check if a given object implements the UpdateProductImageCropInfoRequest interface.
 */
export function instanceOfUpdateProductImageCropInfoRequest(value: object): value is UpdateProductImageCropInfoRequest {
    if (!('image' in value) || value['image'] === undefined) return false;
    if (!('crop_params' in value) || value['crop_params'] === undefined) return false;
    return true;
}

export function UpdateProductImageCropInfoRequestFromJSON(json: any): UpdateProductImageCropInfoRequest {
    return UpdateProductImageCropInfoRequestFromJSONTyped(json, false);
}

export function UpdateProductImageCropInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProductImageCropInfoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'],
        'crop_params': json['crop_params'],
    };
}

export function UpdateProductImageCropInfoRequestToJSON(json: any): UpdateProductImageCropInfoRequest {
    return UpdateProductImageCropInfoRequestToJSONTyped(json, false);
}

export function UpdateProductImageCropInfoRequestToJSONTyped(value?: UpdateProductImageCropInfoRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image': value['image'],
        'crop_params': value['crop_params'],
    };
}

