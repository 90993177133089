<template>
  <div v-if="hasErrors" class="span-10 row-2 mb-10">
    <div class="uppercase flex">
      <Icon icon-key="alert-warning" :size="24" class="flex-shrink-0" />
      <div class="subheading-3 pl-5">not Available for orders</div>
    </div>
    <div v-for="(error, index) in errorBag" :key="index" class="legal ml-25 mb-5">
      {{ error }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomErrors',
  props: ['errorBag'],
  computed: {
    hasErrors() {
      return this.errorBag.length !== 0
    }
  }
}
</script>
