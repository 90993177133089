import FooterNavigation from './../../../footer/footer.vue'
import { trackEvent } from '@/services/tracking'

export default {
  components: {
    FooterNavigation
  },
  data() {
    return {
      utm_tags_if_on_nynow_directory: '',
      show: false,
      product: null,
      totalPrice: 0,
      unwatch: null,
      applymentType: '',
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        requestAssortment: false,
        applymentType: '',
        storeBrandName: ''
      },
      isSignInWithParams: false
    }
  },

  computed: {
    isApplying() {
      return !!this.applymentType && !this.product
    }
  },

  methods: {
    close: close,
    showQuickShopModalUnregisteredCallback: showQuickShopModalUnregisteredCallback,
    setApplymentType: setApplymentType,
    apply: apply,
    signup: signup,
    signInRedirection() {
      let url = '/sign-in'
      if (this.isSignInWithParams) {
        const { pathname, search } = window.location
        const afterLoginRedirectToEncoded = encodeURIComponent(`${pathname}${search}`)
        url += `?redirectUrl=${afterLoginRedirectToEncoded}`
      }
      this.$router.push(url)
    }
  },

  mounted() {
    this.utm_tags_if_on_nynow_directory =
      this.$route.name === 'nynow' && '?utm_source=nynonline&utm_medium=directory&utm_campaign=nyn251'
    this.$eventBus.$on('apply-modal-unregistered', this.showQuickShopModalUnregisteredCallback)
  },

  beforeDestroy() {
    this.$eventBus.$off('apply-modal-unregistered', this.showQuickShopModalUnregisteredCallback)
  }
}

function apply() {
  this.$router.push({ path: `/wholesale-retail/brand${this.utm_tags_if_on_nynow_directory}` })
  this.close()
}
function signup() {
  this.$router.push({ path: `/wholesale-retail/buyer${this.utm_tags_if_on_nynow_directory}` })
  trackEvent('Retailer Button Click')
  this.close()
}

function showQuickShopModalUnregisteredCallback(product, applymentType = null, options = {}) {
  this.product = product
  this.show = true

  this.isSignInWithParams = !!options.isSignInWithParams

  if (applymentType) {
    this.applymentType = applymentType
  } else if (this.$route.params.view) {
    this.applymentType = this.$route.params.view
  } else {
    this.applymentType = 'retailer'
  }

  this.setApplymentType(this.applymentType)
}

function close() {
  this.show = false
}

function setApplymentType(applymentType) {
  applymentType = typeof applymentType == 'object' ? applymentType.name : applymentType

  if (applymentType == 'brand') {
    this.form.applymentType = 'brand'
    this.form.requestAssortment = false
  } else if (applymentType == 'assortment') {
    this.form.applymentType = 'retailer'
    this.form.requestAssortment = true
  } else {
    this.form.applymentType = 'retailer'
    this.form.requestAssortment = false
  }
}
