<template>
  <div class="product-details--header">
    <div class="mb-40">
      <h4 class="flex justify-between" @click="$emit('close')">
        <router-link
          class="opacity-04-hover no-margin"
          :to="'/brands/' + brandUrl"
          data-test-id="product-description_link_brand"
        >
          {{ brandName }}
        </router-link>
        <span class="block color-gray">${{ brandMinimumOrderValue }} minimum</span>
      </h4>
    </div>

    <div class="product-ny-now-badge">
      <div v-if="canShowNynowBadge" class="badge-product-overlay">
        <h4><a href="/nynow-exhibitor">NY NOW</a></h4>
      </div>
    </div>

    <h1 class="font-size-36 no-transform break-word">
      {{ productName }}
    </h1>

    <div class="flex items-center">
      <h4 v-if="userLoggedIn" class="font-size-12-sm">
        <span v-if="userLoggedIn">
          <span>$</span>
          <span v-if="!variant">
            {{ productWholeSalePrice && productWholeSalePrice.toFixed(2) }}
          </span>
          <span v-else>
            {{ variant.price && variant.price.toFixed(2) }}
          </span>
          <span>Wholesale</span>
        </span>
      </h4>

      <span v-if="userLoggedIn" class="font-weight-bold mx-5">/</span>

      <h4 class="font-size-12-sm">
        <span>
          <span v-if="!variant">${{ productPosPrice && productPosPrice.toFixed(2) }} Retail </span>
          <span v-else-if="variant.pos_price">${{ variant.pos_price.toFixed(2) }} Retail </span>
          <span v-else>&nbsp;</span>
        </span>
      </h4>
    </div>

    <div v-if="displayNotSelling" class="panel-buy-direct p-25">
      <h4>GET IN TOUCH</h4>
      <p class="a2">
        Currently this brand does not sell on Bulletin. Message them to learn more about how to purchase their products.
      </p>
      <chat-button
        v-if="user?.role !== 'ADMIN'"
        btn-class="width-100 mt-25"
        btn-variant="primary"
        :me="chatMe"
        :other="chatOther"
        :hide-name="true"
        :hide-icon="true"
        :tracking-info="trackingInfo"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MiscApi from '@/services/api/misc.js'
import BrandApi from '@/services/api/brandApi.js'

const MiscApiService = new MiscApi(),
  BrandApiService = new BrandApi()

export default {
  props: [
    'brandId',
    'brandName',
    'brandUrl',
    'brandMinimumOrderValue',
    'productName',
    'variant',
    'productWholeSalePrice',
    'userLoggedIn',
    'productPosPrice',
    'displayNotSelling',
    'canShowNynowBadge'
  ],

  data() {
    return {
      chatMe: null,
      chatOther: null,
      trackingInfo: {
        action: 'Message from Buy Direct PDP',
        properties: {
          'Brand Name': this.brandName,
          'Brand Id': this.brandId
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  mounted() {
    if (this.user && this.user.role !== 'ADMIN') {
      this.getUserInfoForChat()
    }
  },
  methods: {
    getUserInfoForChat
  }
}

async function getUserInfoForChat() {
  const { data } = this.user.brand_id ? await BrandApiService.getBrandMe() : await MiscApiService.getMeRetailer()
  this.chatMe = {
    fullName: `${data.first_name} from ${data.store_name ? data.store_name : data.brand_name}`,
    role: this.user.role,
    userId: this.user.brand_id ? this.user.brand_id : this.user.retailer_id,
    email: this.user.email
  }

  this.chatOther = {
    storeName: this.brandName,
    firstName: this.brandName,
    email: '',
    userId: this.brandId,
    role: 'BRAND'
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';

.product-details--header {
  .product--name {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.1px;
    color: $color-black;
  }
}

.panel-buy-direct {
  background-color: $color-green !important;
  margin-top: 30px !important;
}
</style>
