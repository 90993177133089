import algoliaSearch from 'algoliasearch/lite'
import { paramsHelper } from '../paramsHelper'

export const algoliaBaseHelper = {
  mixins: [paramsHelper],
  data() {
    return {
      searchIndex: null,
      algoliaClient: this.setupAlgolia(),
      loading: true,
      pagination: {
        page: 1,
        limit: 36,
        totalCount: null
      },
      exhibitorListWithoutPagination: []
    }
  },
  methods: {
    setupAlgolia: function () {
      return algoliaSearch(this.$env.algolia().appId, this.$env.algolia().token, {
        timeouts: {
          connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
          read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
        }
      })
    },
    setPage(page) {
      this.pagination.page = parseInt(page)
      this.updateURLParams('page', page)
    }
  },
  beforeDestroy() {
    this.$store.commit('filters/clearFilters')
  }
}
