<template>
  <h4 class="text-center mt-20 font-size-12">
    Est. shipping {{ leadTimeFrom }}
    <span v-if="leadTimeTo && leadTimeTo !== leadTimeFrom" class="text-dark no-margin-left">to {{ leadTimeTo }}</span>
    <span>{{ !leadTimeTo && leadTimeFrom == 1 ? ' day' : ' days' }}</span>
  </h4>
</template>

<script>
export default {
  props: {
    leadTimeFrom: Number,
    leadTimeTo: Number
  }
}
</script>
