/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualCollectionResponse
 */
export interface ManualCollectionResponse {
    /**
     * 
     * @type {string}
     * @memberof ManualCollectionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualCollectionResponse
     */
    title?: string;
}

/**
 * Check if a given object implements the ManualCollectionResponse interface.
 */
export function instanceOfManualCollectionResponse(value: object): value is ManualCollectionResponse {
    return true;
}

export function ManualCollectionResponseFromJSON(json: any): ManualCollectionResponse {
    return ManualCollectionResponseFromJSONTyped(json, false);
}

export function ManualCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualCollectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function ManualCollectionResponseToJSON(json: any): ManualCollectionResponse {
    return ManualCollectionResponseToJSONTyped(json, false);
}

export function ManualCollectionResponseToJSONTyped(value?: ManualCollectionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
    };
}

