export default {
  props: {
    src: {
      type: String
    },
    type: {
      type: String,
      validator: (val) => ['retailer', 'brand', 'round-sm', 'round-none'].includes(val)
    },
    size: {
      type: String,
      default: () => 'md',
      validator: (val) => ['md', 'lg', 'auto', 'height-fixed--240'].includes(val)
    }
  }
}
