/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadVideoUrlResponse
 */
export interface UploadVideoUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadVideoUrlResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadVideoUrlResponse
     */
    upload_id?: string;
}

/**
 * Check if a given object implements the UploadVideoUrlResponse interface.
 */
export function instanceOfUploadVideoUrlResponse(value: object): value is UploadVideoUrlResponse {
    return true;
}

export function UploadVideoUrlResponseFromJSON(json: any): UploadVideoUrlResponse {
    return UploadVideoUrlResponseFromJSONTyped(json, false);
}

export function UploadVideoUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadVideoUrlResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
        'upload_id': json['upload_id'] == null ? undefined : json['upload_id'],
    };
}

export function UploadVideoUrlResponseToJSON(json: any): UploadVideoUrlResponse {
    return UploadVideoUrlResponseToJSONTyped(json, false);
}

export function UploadVideoUrlResponseToJSONTyped(value?: UploadVideoUrlResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
        'upload_id': value['upload_id'],
    };
}

