/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReferralsPagination } from './ReferralsPagination';
import {
    ReferralsPaginationFromJSON,
    ReferralsPaginationFromJSONTyped,
    ReferralsPaginationToJSON,
    ReferralsPaginationToJSONTyped,
} from './ReferralsPagination';
import type { ReferralResponse } from './ReferralResponse';
import {
    ReferralResponseFromJSON,
    ReferralResponseFromJSONTyped,
    ReferralResponseToJSON,
    ReferralResponseToJSONTyped,
} from './ReferralResponse';

/**
 * 
 * @export
 * @interface PaginatedRefferalsResponse
 */
export interface PaginatedRefferalsResponse {
    /**
     * 
     * @type {Array<ReferralResponse>}
     * @memberof PaginatedRefferalsResponse
     */
    referrals?: Array<ReferralResponse>;
    /**
     * 
     * @type {ReferralsPagination}
     * @memberof PaginatedRefferalsResponse
     */
    pagination?: ReferralsPagination;
}

/**
 * Check if a given object implements the PaginatedRefferalsResponse interface.
 */
export function instanceOfPaginatedRefferalsResponse(value: object): value is PaginatedRefferalsResponse {
    return true;
}

export function PaginatedRefferalsResponseFromJSON(json: any): PaginatedRefferalsResponse {
    return PaginatedRefferalsResponseFromJSONTyped(json, false);
}

export function PaginatedRefferalsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedRefferalsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'referrals': json['referrals'] == null ? undefined : ((json['referrals'] as Array<any>).map(ReferralResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : ReferralsPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedRefferalsResponseToJSON(json: any): PaginatedRefferalsResponse {
    return PaginatedRefferalsResponseToJSONTyped(json, false);
}

export function PaginatedRefferalsResponseToJSONTyped(value?: PaginatedRefferalsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'referrals': value['referrals'] == null ? undefined : ((value['referrals'] as Array<any>).map(ReferralResponseToJSON)),
        'pagination': ReferralsPaginationToJSON(value['pagination']),
    };
}

