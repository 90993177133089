/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create order object
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    validToken?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    token_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    card_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    brand_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    saved_shipping_address_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    saved_billing_address_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    use_shipping_as_billing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    save_card: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    make_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    use_saved_card: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    pay_on_shipment?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderRequest
     */
    promo_codes?: Array<string>;
}

/**
 * Check if a given object implements the CreateOrderRequest interface.
 */
export function instanceOfCreateOrderRequest(value: object): value is CreateOrderRequest {
    if (!('saved_shipping_address_id' in value) || value['saved_shipping_address_id'] === undefined) return false;
    if (!('save_card' in value) || value['save_card'] === undefined) return false;
    if (!('make_default' in value) || value['make_default'] === undefined) return false;
    if (!('use_saved_card' in value) || value['use_saved_card'] === undefined) return false;
    return true;
}

export function CreateOrderRequestFromJSON(json: any): CreateOrderRequest {
    return CreateOrderRequestFromJSONTyped(json, false);
}

export function CreateOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'validToken': json['validToken'] == null ? undefined : json['validToken'],
        'token_id': json['token_id'] == null ? undefined : json['token_id'],
        'card_id': json['card_id'] == null ? undefined : json['card_id'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'saved_shipping_address_id': json['saved_shipping_address_id'],
        'saved_billing_address_id': json['saved_billing_address_id'] == null ? undefined : json['saved_billing_address_id'],
        'use_shipping_as_billing': json['use_shipping_as_billing'] == null ? undefined : json['use_shipping_as_billing'],
        'save_card': json['save_card'],
        'make_default': json['make_default'],
        'use_saved_card': json['use_saved_card'],
        'pay_on_shipment': json['pay_on_shipment'] == null ? undefined : json['pay_on_shipment'],
        'promo_codes': json['promo_codes'] == null ? undefined : json['promo_codes'],
    };
}

export function CreateOrderRequestToJSON(json: any): CreateOrderRequest {
    return CreateOrderRequestToJSONTyped(json, false);
}

export function CreateOrderRequestToJSONTyped(value?: CreateOrderRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'validToken': value['validToken'],
        'token_id': value['token_id'],
        'card_id': value['card_id'],
        'brand_id': value['brand_id'],
        'saved_shipping_address_id': value['saved_shipping_address_id'],
        'saved_billing_address_id': value['saved_billing_address_id'],
        'use_shipping_as_billing': value['use_shipping_as_billing'],
        'save_card': value['save_card'],
        'make_default': value['make_default'],
        'use_saved_card': value['use_saved_card'],
        'pay_on_shipment': value['pay_on_shipment'],
        'promo_codes': value['promo_codes'],
    };
}

