<template>
  <div data-test-id="address-view">
    <h1 v-if="highlightName" class="font-size-40">
      {{ address.first_name }}
      {{ address.last_name }}
    </h1>
    <template v-if="!highlightName && !hideName">
      {{ address.first_name }}
      {{ address.last_name }}
    </template>
    <br v-if="!hideName" />
    {{ address.street }}
    <template v-if="address.apartment">
      {{ address.apartment }}
    </template>
    <template v-if="address.company"> , {{ address.company }} </template>
    <br />
    {{ address.city }},
    {{ address.state }}
    {{ address.zip_code }}
    <br />
    {{ address.country }}
  </div>
</template>

<script>
export default {
  props: {
    highlightName: Boolean,
    hideName: Boolean,
    address: {
      type: Object,
      required: true
    }
  }
}
</script>
