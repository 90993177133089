<template>
  <div>
    <label class="h4" :class="[!!error && 'invalid']">
      {{ label }}
      <error-message>{{ error }}</error-message>
    </label>
    <ais-instant-search :search-client="algoliaClient" :index-name="searchIndex">
      <ais-search-box>
        <template #default="{ currentRefinement, refine }">
          <form autocomplete="off" @submit.prevent="!error && handleAdd(currentRefinement.trim(), inputKey, refine)">
            <input
              ref="aisInput"
              :placeholder="placeholder"
              maxlength="30"
              type="search"
              class="input-main input-font-regular"
              :class="[!!error && 'invalid']"
              :data-test-id="`product_input_${inputKey}`"
              :name="inputKey"
              @input="refine($event.currentTarget.value)"
            />

            <ais-state-results>
              <!-- TAGS -->
              <template v-if="inputKey === 'tags'" #default="{ query }">
                <ais-hits v-if="query.length > 0">
                  <template #item="{ item }">
                    <div class="font-sans font-size-16" @click="handleAdd(item.name, inputKey, refine)">
                      {{ item.name }}
                    </div>
                  </template>
                </ais-hits>
                <div v-else />
              </template>
              <!-- COLLECTION -->
              <template v-else-if="inputKey === 'collections'" #default="{ query }">
                <ais-hits v-if="query.length > 0" class="collections-dropdown--items">
                  <template #item="{ item }">
                    <div
                      v-if="item.collection_type === 'MANUAL'"
                      class="font-sans font-size-16"
                      data-test-id="product_collection_option"
                      @click="handleAdd(item, inputKey, refine)"
                    >
                      {{ item.title }}
                    </div>
                    <div v-else style="height: 0; padding: 0; border: 0" />
                  </template>
                </ais-hits>
                <div v-else />
              </template>
            </ais-state-results>
          </form>
        </template>
      </ais-search-box>
    </ais-instant-search>
    <ul v-if="items.length" class="small-box--wrapper mt-10">
      <chip v-for="(item, index) in items" :key="index" @remove="handleRemove(inputKey, index)">
        <span v-if="inputKey === 'tags'">
          {{ item }}
        </span>
        <span v-else-if="inputKey === 'collections'">
          {{ item.title }}
        </span>
      </chip>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AisInput',
  props: [
    'algoliaClient',
    'searchIndex',
    'label',
    'placeholder',
    'items',
    'inputKey',
    'handleAdd',
    'handleRemove',
    'error'
  ]
}
</script>
