/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteBrandCountResponse
 */
export interface FavoriteBrandCountResponse {
    /**
     * 
     * @type {number}
     * @memberof FavoriteBrandCountResponse
     */
    favorite_brands_count?: number;
}

/**
 * Check if a given object implements the FavoriteBrandCountResponse interface.
 */
export function instanceOfFavoriteBrandCountResponse(value: object): value is FavoriteBrandCountResponse {
    return true;
}

export function FavoriteBrandCountResponseFromJSON(json: any): FavoriteBrandCountResponse {
    return FavoriteBrandCountResponseFromJSONTyped(json, false);
}

export function FavoriteBrandCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteBrandCountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'favorite_brands_count': json['favorite_brands_count'] == null ? undefined : json['favorite_brands_count'],
    };
}

export function FavoriteBrandCountResponseToJSON(json: any): FavoriteBrandCountResponse {
    return FavoriteBrandCountResponseToJSONTyped(json, false);
}

export function FavoriteBrandCountResponseToJSONTyped(value?: FavoriteBrandCountResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'favorite_brands_count': value['favorite_brands_count'],
    };
}

