export default {
  data() {
    return {
      show: false
    }
  },

  methods: {
    showModal: showModal,
    onUserLoggedIn,
    close: close
  },

  mounted() {
    // this.$eventBus.$on('userLoggedIn', this.onUserLoggedIn)
  },

  beforeDestroy() {
    // this.$eventBus.$off('userLoggedIn', this.onUserLoggedIn);
  }
}

function onUserLoggedIn() {
  let isRetailer = this.user.role === 'RETAILER'
  let madalSeen = localStorage.getItem('PROMO_MODAL_SHOWN')
  if (isRetailer && !madalSeen) {
    this.showModal()
    localStorage.setItem('PROMO_MODAL_SHOWN', 'true')
  }
}

function showModal() {
  this.show = true
}

function close() {
  this.show = false
}
