<template>
  <div class="mobile-filter-header">
    <div class="filter-indicator-button" @click="showFilters">
      <Icon icon-key="filter" />
      Filters
      <div v-if="totalFiltersApplied" class="selection-counter">{{ totalFiltersApplied }}</div>
    </div>
    <sorting-options :options="sortingOptions" class="sorting-options" is-mobile />
  </div>
</template>

<script>
export default {
  props: {
    sortChanged: {
      type: Function,
      default: () => {}
    },
    showFilters: {
      type: Function,
      default: () => {}
    },
    sortingOptions: {
      type: Array,
      default: () => {
        return undefined
      }
    },
    totalFiltersApplied: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.mobile-filter-header {
  display: flex;
  border-bottom: 1px solid $color-black;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 72px;
  left: 0;
  right: 0;
  border-top: 1px solid $color-black;
  margin-top: 50px;
  background: $color-white;
  z-index: 15;

  .sorting-options {
    flex: 1;
    padding: 8px;
    border-left: 1px solid $color-black;
  }

  .filter-indicator-button {
    padding: 8px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity $defaultTransitionTime $defaultTransitionType;
    font-size: 16px;
    font-family: 'NHaasGroteskDSPro-55Rg';
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
</style>
