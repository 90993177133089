/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TradeShowAttendeeProvince } from './TradeShowAttendeeProvince';
import {
    TradeShowAttendeeProvinceFromJSON,
    TradeShowAttendeeProvinceFromJSONTyped,
    TradeShowAttendeeProvinceToJSON,
    TradeShowAttendeeProvinceToJSONTyped,
} from './TradeShowAttendeeProvince';

/**
 * 
 * @export
 * @interface TradeShowCountryResponse
 */
export interface TradeShowCountryResponse {
    /**
     * 
     * @type {string}
     * @memberof TradeShowCountryResponse
     */
    country?: string;
    /**
     * 
     * @type {Array<TradeShowAttendeeProvince>}
     * @memberof TradeShowCountryResponse
     */
    provinces?: Array<TradeShowAttendeeProvince>;
}

/**
 * Check if a given object implements the TradeShowCountryResponse interface.
 */
export function instanceOfTradeShowCountryResponse(value: object): value is TradeShowCountryResponse {
    return true;
}

export function TradeShowCountryResponseFromJSON(json: any): TradeShowCountryResponse {
    return TradeShowCountryResponseFromJSONTyped(json, false);
}

export function TradeShowCountryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowCountryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'] == null ? undefined : json['country'],
        'provinces': json['provinces'] == null ? undefined : ((json['provinces'] as Array<any>).map(TradeShowAttendeeProvinceFromJSON)),
    };
}

export function TradeShowCountryResponseToJSON(json: any): TradeShowCountryResponse {
    return TradeShowCountryResponseToJSONTyped(json, false);
}

export function TradeShowCountryResponseToJSONTyped(value?: TradeShowCountryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'country': value['country'],
        'provinces': value['provinces'] == null ? undefined : ((value['provinces'] as Array<any>).map(TradeShowAttendeeProvinceToJSON)),
    };
}

