/*
    TODO:
    create multiple .js files, each representing a separated store,
    that will be imported into main store.js file and included into store object as module.
    https://vuex.vuejs.org/guide/modules.html
*/

import Vue from 'vue'
import Vuex from 'vuex'
import SharedMutations from 'vuex-shared-mutations'
import VuexPersist from 'vuex-persist'
import CartStore from './cart'
import FiltersStore from './filters'
import NavigationStore from './navigation'
import BrandShopifySyncStore from './brandShopifySync'
import NavigationApi from '@/services/api/NavigationApi'
import { ProductApi } from '@/api'

const NavigationApiService = new NavigationApi()

Vue.use(Vuex)

const getInitialPromoCodeState = () => ({
  promoCodeInvalid: false,
  promoCodeErrorMessage: null
})

const state = {
  checkoutState: {
    cart: null,
    shipping: null,
    isPromoCodeLoading: false
  },
  estimatedShippingCost: 0,

  reorderState: null,
  packingSlipState: null,
  userState: null,
  keysState: {},
  notificationCount: 0,
  notificationSessionLoaded: false,
  promoCodeState: getInitialPromoCodeState(),
  favoriteState: [],
  draftUploadedStatus: {
    started: false,
    ended: false,
    error: false
  },
  nav: [],
  draftCount: 0,
  isRetailerApproved: undefined,
  userStatus: undefined,
  dropdown: 'none',
  favoriteProducts: [],
  canSendMessage: false,
  disablingMessageReason: '',
  userContext: null,
  retailerState: null,
  isCSVProductImport: false
}

const getters = {
  checkoutState: checkoutStateGetter,
  packingSlipState: packingSlipStateGetter,
  reorderState: reorderStateGetter,
  userState: userStateGetter,
  retailerState: retailerStateGetter,
  notificationState: notificationCountGetter,
  notificationSessionLoaded: notificationSessionLoadedGetter,
  promoCodeState: promoCodeStateGetter,
  favoritesState: favoritesStateGetter,
  draftStatus: draftStatus,
  draftCount: draftCountGetter,
  isRetailerApproved,
  getUserStatus,
  userContext: getUserContext,
  getNav,
  favoriteProductsState: getFavoriteProducts,
  canSendMessage: getCanSendMessage,
  disablingMessageReason: getDisablingMessageReason,
  getEstimatedShippingCost(state) {
    return state.estimatedShippingCost
  },
  isCSVProductImport: isCSVProductImportGetter
}

const mutations = {
  setReorderObject,
  setCart,
  setFavoriteState,
  setCheckoutAddressInfo,
  removeCheckoutState,
  removeReorderState,
  setDataForPackingSlip,
  removeCheckoutShipping,
  setUser,
  setRetailer,
  setKeysState,
  addKeysToKeysState,
  setPromoCodeLoadingState,
  setNotificationSessionLoaded,
  setNotificationCount,
  setPromoCodeInvalid,
  setPromoCodeErrorMessage,
  clearPromoCodeState,
  draftUploadStart,
  draftUploadEnded,
  draftUploadError,
  resetDraftError,
  resetDraftLoader,
  setRetailerApproved,
  setCanSendMessage,
  setDisablingMessageReason,
  setUserStatus,
  setUserContext,
  setDraftCount,
  setDropdownType,
  setNav,
  setFavoriteProducts,
  setEstimatedShippingCost(state, payload) {
    state.estimatedShippingCost = payload
  },
  setIsCSVProductImport
}

const actions = {
  async getDraftCount(store) {
    const { commit, getters } = store
    const role = getters.userState ? getters.userState.role : null

    if (role === 'BRAND') {
      const { count } = await ProductApi.getDraftProductsCount()
      commit('setDraftCount', count)
    }
  },
  async getNav(store) {
    const { commit } = store
    const { data } = await NavigationApiService.getNavigation()
    commit('setNav', data[0])
  }
}

function setDropdownType(state, type) {
  state.dropdown = type
}

function draftUploadStart(state) {
  state.draftUploadedStatus.started = true
}
function draftUploadEnded(state) {
  state.draftUploadedStatus.ended = true
}
function draftUploadError(state, message) {
  state.draftUploadedStatus.error = message
}
function resetDraftError(state) {
  state.draftUploadedStatus.error = false
}
function resetDraftLoader(state) {
  state.draftUploadedStatus = {
    started: false,
    ended: false,
    error: false
  }
}

function draftStatus(state) {
  return state.draftUploadedStatus
}

function draftCountGetter(state) {
  return state.draftCount
}

function setDraftCount(state, count) {
  state.draftCount = count
}
function setNav(state, data) {
  state.nav = data
}

function isRetailerApproved(state) {
  return state.isRetailerApproved
}

function getUserStatus(state) {
  return state.userStatus
}
function getUserContext(state) {
  return state.userContext
}
function getNav(state) {
  return state.nav
}

function setNotificationSessionLoaded(state, payload) {
  state.notificationSessionLoaded = payload
}

function setNotificationCount(state, payload) {
  state.notificationCount = payload
}

function setReorderObject(state, payload) {
  state.reorderState = payload
}

function setDataForPackingSlip(state, payload) {
  state.packingSlipState = payload
}

function setCart(state, payload) {
  state.checkoutState.cart = payload
}

function setCheckoutAddressInfo(state, payload) {
  state.checkoutState.shipping = payload
}

function setPromoCodeLoadingState(state, payload) {
  state.checkoutState.isPromoCodeLoading = payload
}

function setUser(state, payload) {
  state.userState = payload
}

function setRetailer(state, payload) {
  state.retailerState = payload
}

function setRetailerApproved(state, payload) {
  state.isRetailerApproved = payload
}

function setCanSendMessage(state, payload) {
  state.canSendMessage = payload
}

function setDisablingMessageReason(state, payload) {
  state.disablingMessageReason = payload
}

function setUserStatus(state, payload) {
  state.userStatus = payload
}
function setUserContext(state, payload) {
  state.userContext = payload
}

function setFavoriteState(state, payload) {
  state.favoriteState = payload
}

function setKeysState(state, payload) {
  state.keysState = payload
}

function addKeysToKeysState(state, payload) {
  state.keysState = {
    ...state.keysState,
    ...payload
  }
}

function setPromoCodeInvalid(state, payload) {
  state.promoCodeState.promoCodeInvalid = payload
}

function setPromoCodeErrorMessage(state, payload) {
  state.promoCodeState.promoCodeErrorMessage = payload
}

function clearPromoCodeState(state) {
  state.promoCodeState = getInitialPromoCodeState()
}

function checkoutStateGetter(state) {
  return state.checkoutState
}

function packingSlipStateGetter(state) {
  return state.packingSlipState
}

function reorderStateGetter(state) {
  return state.reorderState
}

function userStateGetter(state) {
  return state.userState
}

function retailerStateGetter(state) {
  return state.retailerState
}

function getCanSendMessage(state) {
  return state.canSendMessage
}

function getDisablingMessageReason(state) {
  return state.disablingMessageReason
}

function notificationCountGetter(state) {
  return state.notificationCount
}

function notificationSessionLoadedGetter(state) {
  return state.notificationSessionLoaded
}

function promoCodeStateGetter(state) {
  return state.promoCodeState
}

function favoritesStateGetter(state) {
  return state.favoriteState
}

function isCSVProductImportGetter(state) {
  return state.isCSVProductImport
}

function removeCheckoutState(state) {
  state.checkoutState = {
    cart: null,
    shipping: null
  }
}

function removeCheckoutShipping(state) {
  state.checkoutState.shipping = null
}

function removeReorderState(state) {
  state.reorderState = null
}

function getFavoriteProducts(state) {
  return state.favoriteProducts
}

function setFavoriteProducts(state, payload) {
  state.favoriteProducts = payload
}

function setIsCSVProductImport(state, payload) {
  state.isCSVProductImport = payload
}

const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage,
  modules: ['checkoutState']
})

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    cart: CartStore,
    filters: FiltersStore,
    navigation: NavigationStore,
    brandShopifySync: BrandShopifySyncStore
  },
  plugins: [
    vuexLocalStorage.plugin,
    SharedMutations({ predicate: ['setCart', 'checkoutStateGetter', 'setCheckoutAddressInfo'] })
  ]
})
