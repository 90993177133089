export const bodyScrollHelper = {
  watch: {
    show: function (value) {
      const body = document.getElementsByTagName('body')[0]
      const classList = body.classList
      if (value && !classList?.contains('overflow-hidden')) {
        classList.add('overflow-hidden')
      } else {
        this.removeOverflowHiddenFromBody()
      }
    }
  },
  beforeDestroy: function () {
    this.removeOverflowHiddenFromBody()
  },
  methods: {
    removeOverflowHiddenFromBody: function () {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
    }
  }
}
