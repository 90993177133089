export default {
  props: ['disabled', 'iconClasses', 'defaultExpanded'],

  data() {
    return {
      toggleIcon: false,
      isExpanded: false
    }
  },

  methods: {
    toggleExpanded
  },

  mounted() {
    if (this.defaultExpanded) {
      this.toggleExpanded()
    }
  }
}

function toggleExpanded() {
  if (this.disabled) return

  this.isExpanded = !this.isExpanded
}
