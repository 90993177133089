/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminCollectionResponse
 */
export interface AdminCollectionResponse {
    /**
     * 
     * @type {string}
     * @memberof AdminCollectionResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCollectionResponse
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCollectionResponse
     */
    link?: string;
}

/**
 * Check if a given object implements the AdminCollectionResponse interface.
 */
export function instanceOfAdminCollectionResponse(value: object): value is AdminCollectionResponse {
    return true;
}

export function AdminCollectionResponseFromJSON(json: any): AdminCollectionResponse {
    return AdminCollectionResponseFromJSONTyped(json, false);
}

export function AdminCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCollectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'image_url': json['image_url'] == null ? undefined : json['image_url'],
        'link': json['link'] == null ? undefined : json['link'],
    };
}

export function AdminCollectionResponseToJSON(json: any): AdminCollectionResponse {
    return AdminCollectionResponseToJSONTyped(json, false);
}

export function AdminCollectionResponseToJSONTyped(value?: AdminCollectionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'image_url': value['image_url'],
        'link': value['link'],
    };
}

