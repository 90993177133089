import session from '../session'
import restApi from '../../http'

class RecommendationsApi {
  baseUrl = '/recommendations'

  get authHeader() {
    return {
      Authorization: 'Bearer ' + session.getAccessToken()
    }
  }

  saveRecommendedBrands(brand_ids, retailer_id) {
    return restApi({
      method: 'POST',
      url: `${this.baseUrl}/recommend_brands`,
      headers: this.authHeader,
      data: {
        brand_ids,
        retailer_id
      }
    })
  }

  fetchRecommendedBrands(retailerId) {
    return restApi({
      method: 'GET',
      url: `${this.baseUrl}/retailer/${retailerId}/brands`,
      headers: this.authHeader
    })
  }
}

export const recommendationsApi = new RecommendationsApi()
