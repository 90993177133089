/* eslint-disable max-lines */
import OrderApi from '../services/api/orderApi'
import ProductApi from '../services/api/productApi'
import RetailerApi from '../services/api/retailerApi'
import AuthApi from '@/services/api/authApi'
import OldBrandApi from '@/services/api/brandApi'
import MiscApi from '../services/api/misc'
import clipboard from 'clipboard-polyfill/dist/clipboard-polyfill.promise'
import createTalkSession from '../talkSession.js'
import { trackEvent } from './tracking'
import { parse } from 'query-string'
import PusherConfig from '@/pusherConfig'
import _startCase from 'lodash/startCase'
import _toLower from 'lodash/toLower'
import { datadogRum } from '@datadog/browser-rum'
import FavoriteApi from '@/services/api/favoritesApi'
import sanitizeHtml from 'sanitize-html'
import { TradeShowApi } from '@/api'

const OrderApiService = new OrderApi(),
  ProductApiService = new ProductApi(),
  FavoriteApiService = new FavoriteApi(),
  AuthApiService = new AuthApi(),
  MiscApiService = new MiscApi(),
  BrandApiService = new OldBrandApi(),
  RetailerApiService = new RetailerApi(),
  CSV_UPLOAD_SIZE_LIMIT_MB = 35,
  CSV_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE = `Unfortunately you cannot upload a file bigger than ${CSV_UPLOAD_SIZE_LIMIT_MB}MB. Please try again by uploading a smaller file.`,
  IMAGE_UPLOAD_SIZE_LIMIT_MB = 35,
  IMAGE_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE = `Unfortunately you cannot upload a file bigger than ${IMAGE_UPLOAD_SIZE_LIMIT_MB}MB. Please try again by uploading a smaller file.`

const enumValue = (name) => Object.freeze({ toString: () => name })
export const FilterKeys = Object.freeze({
  CATEGORIES: enumValue('categories'),
  BRANDS: enumValue('brands'),
  BULLETIN_PICKS: enumValue('bulletin_picks'),
  BUY_ON_BULLETIN: enumValue('buy_on_bulletin'),
  BRAND_VALUES: enumValue('brand_values'),
  SHIP_BY: enumValue('max_lead_time'),
  LOCATION: enumValue('location'),
  COUNTRY: enumValue('country'),
  PRICE_FROM: enumValue('price_from'),
  PRICE_TO: enumValue('price_to'),
  MOV: enumValue('mov'),
  KEYWORD: enumValue('keyword'),
  SECTIONS: enumValue('sections'),
  SPECIAL_SERVICES: enumValue('special_services'),
  DEALS_PROMOTIONS: enumValue('deals_promotions')
})

export const RouterPath = {
  DEFAULT_SEARCH_PRODUCT_PAGE: 'search',
  CATEGORY_PRODUCTS_PAGE: 'products',
  COLLECTION_PRODUCTS_PAGE: 'collection'
}

export const AlgoliaIndexKeys = {
  CREATED_AT_ASC: 'created_at_asc',
  TOTAL_ORDERS_DESC: 'total_orders_desc',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc'
}

export default {
  imageSize,
  deepClone,
  isMobile,
  isValidUrl,
  urlSafeString,
  stripHTML,
  getCart,
  updateCart,
  removeProductFromCart,
  addToCart,
  addMultipleVariantsToCart,
  formatFollowers,
  initMixpanelBeforeTrack,
  findVariantByOptions,
  convertEnumToString,
  isEquivalent,
  applyPromoCode,
  getOrderCount,
  formatErrorMessage,
  getProductCategories,
  getCDNURL,
  scrollToSection,
  scrollTo,
  scrollToCoordinate,
  handleError,
  checkCartChanges,
  showCartChangesMessageDialog,
  copyToClipboard,
  mapAddressToDropdownItem,
  getByteSizeFromMegabyteSize,
  print,
  getAllBrands,
  getAllRetailers,
  handleLogin,
  handlePostLogin,
  validateQuantityNumber,
  isValidNumericValue,
  poll,
  balanceChangeHandler,
  setRetailerStores,
  viewTracking,
  checkUtmParamsAndRedirect,
  mapCategoriesToProductTypeList,
  capitalize,
  displayCharacteristicOption,
  removeSlashFromString,
  stripHtml,
  arrayify,
  flatteningList,
  getFavoriteProducts,
  getTextWidth,
  getRetailerMessageCapability,
  getRetailerMe,
  storeRetailerData,
  toDollars,
  pluralize,
  tradeShowEnabledFeatures,
  constants: {
    RouterPath: RouterPath,
    FilterKeys: FilterKeys,
    instagramUsernameRegex: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
    urlRegex:
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    phoneNumberRegex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
    phoneNumberRegexForUS: /^(?:1[1-9]{1}[0-9]{9}|[1-9]{1}[0-9]{9})$/,
    phoneNumberOptional: /^(?:[\d\s\-\(\)\+\.]*)?$/,
    passwordRegex: { regex: /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@\$=!:.#%\-]+$)/ },
    websiteRegex: /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/,
    CHECKOUT_TOTAL_TEXT: 'Subject to change pending shipping cost and product availability.',
    CHECKOUT_ACCEPT_TERM_TEXT:
      'By clicking place order, I confirm that I am a valid retailer eligible to purchase and re-sell the items in my cart. I accept Bulletin\'s <a href="https://bulletin.co/terms" class=\'underline\' target="_blank">Terms of Service</a> and acknowledge that Bulletin has the right to review my account status at any time if I am found to be in violation.',
    MINIMUM_ORDER_VALUE_ERROR_MESSAGE: 'Minimum order value for some brands not reached. Please check your bag.',
    TESTER_SUCCESS_MESSAGE:
      "Congratulations! You have reached the minimum order quantity that is required to purchase a highly discounted tester for this product. We have automatically added it to your cart. If you decide you don't want this tester, you can simply remove it before you check out.",
    DELETED_VARIANTS_MESSAGE:
      'It seems that some of the products in your cart have been modified or deleted from our system. You can browse and add new products to the cart or proceed to checkout without these products',
    INVALID_PROMO_CODE_MESSAGE: 'The promo code used with this order no longer applies and has been removed.',
    OUT_OF_STOCK_MESSAGE:
      'Items in your cart have changed. Some items in your cart are no longer in stock and have been removed.',
    QUANTITY_CHANGED_MESSAGE:
      'Availability for items in your cart have changed. Some items in your cart are running low on inventory and you may not be able to purchase your originally desired amount.',
    LIMIT_INCREASED_MESSAGE: (newLimit) => {
      return `Great news! We've just extended your net-60 credit limit to $${newLimit}! This means you can order $${newLimit} worth of inventory without paying the wholesale invoice upfront. Happy hunting!`
    },
    LIMIT_DECREASED_MESSAGE: (newLimit) => {
      return `Your updated net-60 credit limit with Bulletin Wholesale is $${newLimit}. This means you can order $${newLimit} worth of inventory without paying the wholesale invoice upfront. If you have questions or concerns about your adjusted credit limit, please email <a href="mailto:support@bulletin.com">support@bulletin.com </a>`
    },
    GET_TESTER_PRODUCTS_LIMIT: 2000,
    ALGOLIA_CONNECT_TIMEOUT: 1000,
    ALGOLIA_READ_TIMEOUT: 2000,
    PRIMARY_ADDRESS: {
      name: 'Bulletin',
      store: 'UPS Store',
      street: '217 Centre Street',
      apt: 'Ste 280',
      city_state: 'New York, NY 10013'
    },
    ORDER_RETURNABLE_VALUE: 2000,
    DAYS_FOR_RETURN: 60,
    CHECKOUT_MINIMUM: 200,
    BREAKPOINT_MEDIUM: 992,
    IS_MOBILE: isMobile(),
    BRAND_PAGE_PREFIX: getBrandPrefix(),
    CSV_UPLOAD_SIZE_LIMIT_MB,
    CSV_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE,
    IMAGE_UPLOAD_SIZE_LIMIT_MB,
    IMAGE_UPLOAD_SIZE_LIMIT_ERROR_MESSAGE,
    ZENDESK_ID: '360002745032',
    ALGOLIA_DEFAULT_PRODUCT_SEARCH_FACET_FILTER: [
      ['brand.is_published:true'],
      ['is_test_product:false'],
      ['active:true'],
      ['product_status: APPROVED']
    ],
    ALGOLIA_DEFAULT_PRODUCT_SEARCH_NUMERIC_FILTER: ['pos_price >= 0', 'pos_price <= 10000'],
    IMPORT_SPREADSHEET_LINK: '/bulletin_product_template.xlsx',
    TALKJS_APP_ID: 'cs69IIU0',
    PRICE_FORMAT_DECIMAL_PLACES: 2,
    HTTP_STATUS: {
      UNAUTHORIZED: 401,
      FORBIDDEN: 403,
      NOT_FOUND: 404,
      CONFLICT: 409
    },
    SIMILAR_PRODUCTS_LIMIT: 4,
    ZENDESK_URL: 'https://bulletin.zendesk.com/',
    routeDict: {
      productsFromNavigation: 'Category',
      collection: 'Collection',
      home: 'Homepage',
      brands: 'Brands',
      'brand-bio': 'Brand Bio',
      search: 'Search results',
      productDetails: 'Product details',
      dashboardFavoritesbyBrand: 'Dashboard favorited brands',
      dashboardFavoritesbyProduct: 'Dashboard favorited products'
    },
    ACCEPTED_CSV_MIME_TYPES: [
      'text/csv',
      'application/vnd.ms-excel',
      'text/plain',
      'text/x-csv',
      'application/csv',
      'application/x-csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
      'text/tab-separated-values'
    ],
    NEW_RETAILER_PROMO_CODE_OFFER_TEXT: 'Free shipping <br /> on your <br /> first order <br> with code: <br>4FREESHIP',
    NEW_RETAILER_PROMO_CODE_OFFER_REMINDER:
      'Don’t forget! We’re currently covering shipping costs up to $100 on your first order with code 4FREESHIP',
    ALGOLIA_INDEX_KEYS: AlgoliaIndexKeys,
    DEFAULT_PRODUCT_SORTING_OPTIONS: [
      {
        name: 'The New Edit',
        value: AlgoliaIndexKeys.CREATED_AT_ASC,
        distinct: true
      },
      {
        name: 'All New Arrivals',
        value: AlgoliaIndexKeys.CREATED_AT_ASC
      },
      {
        name: 'High Sell Through',
        value: AlgoliaIndexKeys.TOTAL_ORDERS_DESC
      },
      {
        name: 'Price Low to High',
        value: AlgoliaIndexKeys.PRICE_ASC
      },
      {
        name: 'Price High to Low',
        value: AlgoliaIndexKeys.PRICE_DESC
      }
    ],
    DATE_SORTING_OPTIONS: [
      {
        name: 'Newest to Oldest',
        value: 'DESC'
      },
      {
        name: 'Oldest to Newest',
        value: 'ASC'
      }
    ],
    CUSTOM_TOOLBAR: {
      REGULAR: [['bold', 'italic', 'underline'], ['clean']],
      EXTENDED: [
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean']
      ]
    },
    SHOPIFY_INSTALL_URL: 'https://apps.shopify.com/bulletin-sales-channel'
  }
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent)
}
function getBrandPrefix() {
  return `${window.location.origin}/brands/`
}

function isValidUrl(testString) {
  // https://stackoverflow.com/a/42619368
  const isValidUrl = RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/).test(testString)
  return testString ? isValidUrl : false
}

function isValidNumericValue(testString) {
  return /^[0-9]*$/.test(testString)
}

function convertEnumToString(string) {
  if (string && string.length > 1) {
    return string
      .split('_')
      .map((substring) => substring[0].toUpperCase() + substring.substr(1).toLowerCase())
      .join(' ')
      .replace('_', ' ')
  }
}

// eslint-disable-next-line complexity
function imageSize(image) {
  switch (image) {
    case 'productImage':
      return {
        height: 1875,
        width: 1500
      }

    case 'productCustomAssortmentImage':
      return {
        height: 2000,
        width: 2000
      }
    case 'brandThumbnailImage':
      return {
        height: 1875,
        width: 1500
      }
    case 'collectionImage':
    case 'brandImage':
      return {
        height: 800,
        width: 2392
      }
  }
}

function stripHTML(html) {
  if (!html) {
    return '<p></p>'
  }
  return sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {}
  })
}

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function urlSafeString(string) {
  return string
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '-')
}

function formatFollowers(num) {
  const million = 1000000,
    thousand = 1000

  if (num >= million) {
    return (num / million).toFixed(1).replace(/\.0$/, '') + 'm'
  }
  if (num >= thousand) {
    return (num / thousand).toFixed(1).replace(/\.0$/, '') + 'k'
  }
  return num
}

function initMixpanelBeforeTrack(instance) {
  const user = instance.$session.getUserInfo()

  window.rudderanalytics.ready(() => {
    window.rudderanalytics.identify(
      user.id,
      {
        Email: user.email,
        Role: user.role
      }
      // , null , () => {
      //   instance.$trackEvent({action : 'callback test'});
      // }
    )
  })

  instance.$ma.setSuperProperties({
    Email: user.email,
    UserId: user.id,
    NumberOfLogins: user.number_of_logins,
    FirstLogin: user.first_login,
    Role: user.role
  })

  instance.$ma.identify({
    userId: user.id,
    options: {
      newUser: true
    }
  })

  instance.$ma.setUserProperties({
    userId: user.id,
    $email: user.email,
    Role: user.role
  })
}

function findVariantByOptions(variants, properties) {
  let foundVariant = null

  for (let i = 0; i < variants.length; i++) {
    if (this.isEquivalent(deepClone(variants[i].option), deepClone(properties))) {
      foundVariant = variants[i]

      break
    }
  }

  return foundVariant
}

function isEquivalent(objectA, objectB) {
  // create arrays of property names
  const aProps = Object.getOwnPropertyNames(objectA),
    bProps = Object.getOwnPropertyNames(objectB)

  /*
   *if number of properties is different,
   *objects are not equivalent
   */
  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i]

    /*
     * if values of same property are not equal,
     * objects are not equivalent
     */
    if (objectA[propName] !== objectB[propName]) {
      return false
    }
  }

  // if we reached this return, objects are considered equivalent
  return true
}

function getAllBrands(published) {
  return MiscApiService.getAllBrands(published)
}

function getAllRetailers() {
  return MiscApiService.getAllRetailers()
}

function getCart(filter) {
  return OrderApiService.getCart(filter)
}

function updateCart(request) {
  return OrderApiService.updateCart(request)
}

function addToCart(request) {
  return OrderApiService.addToCart(request)
}

function addMultipleVariantsToCart(requestData) {
  return OrderApiService.addMultipleVariantsToCart(requestData)
}

function removeProductFromCart(id) {
  return OrderApiService.removeProductFromCart(id)
}

function applyPromoCode(code) {
  return OrderApiService.applyPromoCode(code)
}

function getOrderCount(brandId) {
  return OrderApiService.getOrderCount(brandId)
}

function formatErrorMessage(string) {
  return string ? `Could not load ${string}` : 'Could not load data'
}

async function getProductCategories(active) {
  const response = await ProductApiService.getCategoriesFromNavigation(active),
    categories = response.data.children_containers

  return categories.reduce((total, category) => {
    if (category.container_direction === 'GRID') {
      return [
        ...total,
        ...category.children_containers.map((container) => {
          return {
            ...container,
            name: `${category.name} (${container.name})`
          }
        })
      ]
    }

    return [...total, category]
  }, [])
}

// eslint-disable-next-line complexity
function getCDNURL(data) {
  // eslint-disable-next-line id-length
  const { src, h: height, w: width, q: quality, fit } = data

  let configString = ''
  if (height) {
    configString += `h=${height},`
  }
  if (width) {
    configString += `w=${width},`
  }
  if (quality) {
    configString += `q=${quality},`
  }

  if (fit) {
    configString += `fit=${fit},`
  }

  return `//bulletin.co/cdn-cgi/image/${configString}format=auto/${src}`
}

function scrollToSection(id) {
  document.getElementById(id).scrollIntoView({
    behavior: 'smooth'
  })
}
// due to our fixed header all scrolls are offset by the header's height
function scrollTo(target) {
  const headerOffsetPX = document.getElementsByTagName('header')[0].offsetHeight
  window.scrollTo({
    top: target.offsetTop - headerOffsetPX,
    left: 0,
    behavior: 'smooth'
  })
}

function scrollToCoordinate(top) {
  window.scrollTo({
    top,
    behavior: 'smooth'
  })
}

function handleError(instance, error) {
  let message
  // Old API Errors (to be removed)
  if (error?.response?.data?.error_message) {
    message = error.response.data.error_message
    // New API Errors (openapi)
  } else if (error?.error_message) {
    message = error.error_message
  } else if (typeof error === 'string') {
    message = error
  }

  instance.toast({
    type: 'error',
    title: 'Error',
    message: message ?? 'Something went wrong!'
  })

  if (!message) {
    instance.$logger.notify(new Error(error))
  }
}

function checkCartChanges(instance, cart) {
  this.showCartChangesMessageDialog(instance, cart)

  if (cart.has_deleted_items) {
    const error = 'Deleted Variant in Cart',
      email = instance.$session.getUserInfo().email

    instance.$logger.notify(error, {
      user: email,
      data: cart
    })
  }
}

function showCartChangesMessageDialog(instance, cart) {
  const messageQue = []

  let { has_out_of_stock_items, has_changed_items, has_invalid_promo_code, has_deleted_items, has_new_testers } = cart

  if (has_out_of_stock_items) {
    messageQue.push(this.constants.OUT_OF_STOCK_MESSAGE)
  }

  if (has_changed_items) {
    messageQue.push(this.constants.QUANTITY_CHANGED_MESSAGE)
  }

  if (has_invalid_promo_code) {
    messageQue.push(this.constants.INVALID_PROMO_CODE_MESSAGE)
  }

  if (has_deleted_items && !has_out_of_stock_items) {
    //   messageQue.push(this.constants.DELETED_VARIANTS_MESSAGE);
  }

  if (has_new_testers) {
    messageQue.push(this.constants.TESTER_SUCCESS_MESSAGE)
  }

  if (has_out_of_stock_items || has_changed_items) {
    instance.$store.dispatch('cart/getCartProductCount')
    instance.$eventBus.$emit('refresh-product-cart-summary')
  }

  const showDialogs = () => {
    if (messageQue?.length) {
      instance.$swal('Change in cart', messageQue[0]).then(() => {
        messageQue.shift()
        showDialogs()
      })
    }
  }
  showDialogs()
}

function copyToClipboard(str) {
  if (str) {
    clipboard.writeText(str)
  }
}

function mapAddressToDropdownItem(address) {
  return {
    id: address.id,
    name:
      address.first_name +
      ' ' +
      address.last_name +
      ' ' +
      address.street +
      ' ' +
      address.zip_code +
      ' ' +
      address.state +
      ' ' +
      address.country,
    address
  }
}

function getByteSizeFromMegabyteSize(size) {
  const binaryBase = 1024

  return size * binaryBase * binaryBase
}

function print() {
  window.print()
}

async function handleLogin(request) {
  return AuthApiService.signIn(request)
}

// eslint-disable-next-line complexity
async function handlePostLogin(response) {
  response = response.data
  const firstLogin = new Date(response.first_login_date),
    user = {
      email: response.email,
      id: response.id,
      name: response.name,
      role: response.role,
      first_login: firstLogin.toString(),
      number_of_logins: response.number_of_logins
    }
  this.$session.setAccessToken(response.access_token)
  this.$session.setUserInfo(user)
  this.$store.commit('setUser', user)

  if (this.isZendeskSingleSignOn) {
    // TODO find out when this is called and why "request" object undefined
    // eslint-disable-next-line no-undef
    AuthApiService.getZendDeskJWTToken(request)
      .then((response) =>
        window.location.replace(
          `https://bulletin.zendesk.com/access/jwt?jwt=${response.data.zendesk_jwt}&return_to=${this.$route.query}`
        )
      )
      .catch((e) => {
        this.$logger.notify(new Error('Zendesk login failed: ' + e))
        this.$handleError(this, 'Comunity login Error. Please email support@bulletin.co')
      })
    return
  }
  if (this.$route.query.redirectUrl) {
    this.$router.push(this.$route.query.redirectUrl)
  } else if (this.$route.query.redirectToPage) {
    this.$router.push('/' + this.$route.query.redirectToPage)
  } else if (this.$route.name === 'signIn') {
    const { shop } = this.$route.query
    if (shop) {
      this.$router.push(`/dashboard/connect-shopify?shop=${shop}`)
    } else {
      this.$router.push('/home')
    }
  } else if (['buyer', 'Brand'].includes(this.$route.name)) {
    this.$router.push('/home')
  }

  datadogRum.setUser({
    id: response.id,
    email: response.email,
    role: response.role
  })
  //config Google analytics with a user_id
  if (localStorage.USER_INFO) {
    window.dataLayer.push('config', 'G-' + this.$env.googleAnalyticsV4(), {
      user_id: JSON.parse(localStorage.USER_INFO)['id'],
      role: JSON.parse(localStorage.USER_INFO)['role']
    })
  } else {
    window.dataLayer.push('config', 'G-' + this.$env.googleAnalyticsV4())
  }

  this.$eventBus.$emit('subscribe-all')

  switch (user.role) {
    case 'BRAND':
      AuthApiService.getLoggedBrandInfo()
        .then(async (response) => {
          user.brand_id = response.data.id
          user.brand_vanity_url_name = response.data.vanity_url_name

          createTalkSession(this, user)

          this.$session.setUserInfo(user)
          this.$store.commit('setUser', {
            ...user
          })
          this.$eventBus.$emit('user-logged-in')
          this.$utils.initMixpanelBeforeTrack(this)
          this.$trackEvent({
            action: 'Log In',
            properties: {
              Success: true
            }
          })
          PusherConfig(this)
        })
        .catch((error) => {
          this.$trackEvent({
            action: 'Log In',
            properties: {
              Success: false,
              Error: error.response.data.error_message
            }
          })
          this.$handleError(this, error)
        })
      break
    case 'RETAILER':
      try {
        const { data } = await RetailerApiService.getMyRetailerInfo()

        this.$utils.storeRetailerData.call(this, data)
        this.$utils.setRetailerStores(this)

        user.retailer_id = data.id
        user.retailer = data
        this.$session.setUserInfo(user)
        this.$utils.balanceChangeHandler(this.$session, this)

        createTalkSession(this, user)
        this.$store.dispatch('cart/getCartProductCount')

        this.$store.commit('setUser', JSON.parse(JSON.stringify(user)))
        this.$eventBus.$emit('user-logged-in')
        this.$utils.initMixpanelBeforeTrack(this)
        this.$trackEvent({
          action: 'Log In',
          properties: {
            Success: true
          }
        })
        PusherConfig(this)
      } catch (error) {
        this.$trackEvent({
          action: 'Log In',
          properties: {
            Success: false,
            Error: error.response.data.error_message
          }
        })
        this.$handleError(this, error)
      }

      this.$utils.getRetailerMessageCapability.call(this)
      break
    case 'PRODUCT_BUILDER':
    case 'ADMIN':
      this.$utils.initMixpanelBeforeTrack(this)
      this.$eventBus.$emit('user-logged-in')
      this.$store.dispatch('cart/getCartProductCount')
      this.$trackEvent({
        action: 'Log In',
        properties: {
          Success: true
        }
      })
      PusherConfig(this)
      break
  }

  if (user.role === 'BRAND') {
    const { data } = await BrandApiService.getBrandMe()
    if (data && !data.onboarding_complete && data.status !== 'REGISTERED') {
      localStorage.setItem('ONBOARD_DATA', JSON.stringify({ isOnboardIncomplete: true }))
      this.$router.push('/dashboard/brand-page-setup')
      return
    }
  }

  if (this.$route.query.redirectUrl) {
    this.$router.push(this.$route.query.redirectUrl)
  } else if (this.$route.query.redirectToPage) {
    this.$router.push('/' + this.$route.query.redirectToPage)
  } else if (this.$route.name === 'signIn') {
    const { shop } = this.$route.query
    if (shop) {
      this.$router.push(`/dashboard/connect-shopify?shop=${shop}`)
    } else {
      this.$router.push('/home')
    }
  } else if (['buyer', 'Brand'].includes(this.$route.name)) {
    this.$router.push('/home')
  } else if (this.$route.name === 'forgotPassword') {
    this.$router.push('/home')
  }
}

function validateQuantityNumber(value, minimum, increment) {
  const nInputValue = Number(value)

  if (nInputValue < minimum) {
    return minimum
  } else if ((nInputValue - minimum) % increment !== 0) {
    return Math.floor((nInputValue - minimum) / increment) * increment + minimum
  }

  return nInputValue
}

async function poll({ fn, validate, interval, maxAttempts }) {
  // console.log("Start poll...");
  let attempts = 0
  const executePoll = async (resolve, reject) => {
    const result = await fn()
    attempts++
    if (validate(result)) {
      return resolve(result)
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    }
    setTimeout(executePoll, interval, resolve, reject)
  }

  return new Promise(executePoll)
}

async function balanceChangeHandler(session, instance) {
  const { balance_level } = instance.$store.getters.retailerState
  const user = session.getUserInfo(),
    oldBalanceValue = Number(getBalanceValueFromLevel(user.balance_level)),
    newBalanceValue = Number(getBalanceValueFromLevel(balance_level))

  if (user.balance_level && newBalanceValue !== oldBalanceValue) {
    instance.$handleError(
      instance,
      newBalanceValue > oldBalanceValue
        ? this.constants.LIMIT_INCREASED_MESSAGE(newBalanceValue)
        : this.constants.LIMIT_DECREASED_MESSAGE(newBalanceValue)
    )
    updateSavedBalanceLevel(session, user, balance_level)
  } else {
    updateSavedBalanceLevel(session, user, balance_level)
  }
}

function updateSavedBalanceLevel(session, user, balanceLevel) {
  session.setUserInfo({
    ...user,
    ...{
      balance_level: balanceLevel
    }
  })
}

async function setRetailerStores(vm) {
  try {
    const { data } = await MiscApiService.getMeRetailer()
    vm.$store.commit('setRetailerApproved', data.is_approved && !data.is_declined)
    vm.$store.commit('setUserStatus', data.status)
    vm.$store.commit('setUserContext', data)
  } catch (error) {
    this.$logger.notify(new Error(error))
  }
}

function getBalanceValueFromLevel(balanceLevel) {
  if (!balanceLevel) {
    return
  }
  const splitBalanceLevel = balanceLevel.split('LEVEL_')
  if (!splitBalanceLevel.length) {
    return
  }
  return splitBalanceLevel[1]
}

function viewTracking(path) {
  try {
    // Track hubspot page view
    if (window._hsq === undefined) {
      window._hsq = []
    }

    window._hsq.push(['setPath', path])
    window._hsq.push(['trackPageView'])

    if (window.fbq !== undefined) {
      trackEvent('PageView')
    }

    window.rudderanalytics.page()
  } catch (exc) {
    // eslint-disable-next-line no-console
    console.log(exc)
  }
}
/* eslint-enable no-underscore-dangle */

function mapCategoriesToProductTypeList(categories, productTypes) {
  const productTypeSubCategories = []

  if (!categories || !productTypes) {
    return []
  }

  categories.forEach((category) => {
    productTypes.forEach((parent) => {
      const child = parent.view_components.find((child) => child.entity_id === category.id)
      if (child) {
        productTypeSubCategories.push(child)
      }
    })
  })
  return productTypeSubCategories
}

function checkUtmParamsAndRedirect(router) {
  const params = parse(window.location.search)

  if (!params) {
    return
  }

  const hasUtmParams = Object.keys(params).find((key) => key.toLowerCase().includes('utm'))

  if (!hasUtmParams) {
    return
  }

  const { redirect } = params

  // retain all query params except the redirect part
  delete params.redirect

  const utm_params = []
  Object.keys(params).map((utm_key) => utm_params.push(utm_key + '=' + params[utm_key]))
  const query_params = utm_params.join('&')

  if (redirect === 'retailer') {
    router.push('/wholesale-retail/buyer/?' + query_params)
  } else if (redirect === 'brand') {
    router.push('/wholesale-retail/brand/?' + query_params)
  }
}

function removeSlashFromString(value) {
  return value.replace(/\\|\//g, '')
}

function capitalize(str) {
  return _startCase(_toLower(str))
}

function displayCharacteristicOption(group, option) {
  return group?.toLowerCase() === 'size' ? option : capitalize(option)
}

function stripHtml(html) {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

function arrayify(value) {
  if (Array.isArray(value)) {
    return value
  }

  if (value) {
    return [value]
  }

  return []
}

function flatteningList(list, childKey) {
  return list?.reduce((result, category) => {
    return [...result, ...category[childKey]]
  }, [])
}

async function getFavoriteProducts() {
  try {
    const { data } = await FavoriteApiService.getFavoriteProducts()
    const favoriteProducts = data?.product_ids || []
    this.$store.commit('setFavoriteProducts', favoriteProducts)
    return favoriteProducts
  } catch (error) {
    this.$handleError(this, error)
  }
}

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

async function getRetailerMessageCapability() {
  const userRole = this.$session.getUserInfo().role
  if (userRole === 'RETAILER') {
    try {
      const { data } = await RetailerApiService.getRetailerMessagingCapability()
      this.$logger.addAction('canSendMessage', {
        data
      })
      this.$store.commit('setCanSendMessage', data?.can_send_message)
      this.$store.commit('setDisablingMessageReason', data?.error_message)
    } catch (e) {
      this.$store.commit('setCanSendMessage', true)
      this.$store.commit('setDisablingMessageReason', '')
    }
  } else {
    this.$store.commit('setCanSendMessage', true)
    this.$store.commit('setDisablingMessageReason', '')
  }
}

async function getRetailerMe() {
  try {
    const { data } = await MiscApiService.getMeRetailer()
    this.$utils.storeRetailerData.call(this, data)
  } catch (error) {
    this.$store.commit('setRetailer', null)
  }
}

function storeRetailerData(data) {
  this.$store.commit('setRetailer', data)
  this.$store.commit('setRetailerApproved', data.is_approved && !data.is_declined)
  this.$store.commit('setUserStatus', data.status)
}

function toDollars(cents) {
  const formatConfig = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    currencyDisplay: 'symbol'
  }
  const USDFormatter = new Intl.NumberFormat('en-US', formatConfig)
  return USDFormatter.format(cents / 100)
}

export function getTotalFilterCount(filters) {
  return Object.keys(filters)
    .map((key) => filters[key].length)
    .reduce((acc, curr) => {
      return acc + curr
    }, 0)
}

function pluralize(word, count) {
  return `${word}${count === 1 ? '' : 's'}`
}

async function tradeShowEnabledFeatures(prop) {
  const { features } = await TradeShowApi.getTradeShowEnabledFeatures()
  if (prop) {
    return features?.[prop]?.event_ids[0]
  }
  return features
}
