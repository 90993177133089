/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomAssortmentItemResponse } from './CustomAssortmentItemResponse';
import {
    CustomAssortmentItemResponseFromJSON,
    CustomAssortmentItemResponseFromJSONTyped,
    CustomAssortmentItemResponseToJSON,
    CustomAssortmentItemResponseToJSONTyped,
} from './CustomAssortmentItemResponse';

/**
 * 
 * @export
 * @interface CustomAssortmentResponse
 */
export interface CustomAssortmentResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {Array<CustomAssortmentItemResponse>}
     * @memberof CustomAssortmentResponse
     */
    items?: Array<CustomAssortmentItemResponse>;
}

/**
 * Check if a given object implements the CustomAssortmentResponse interface.
 */
export function instanceOfCustomAssortmentResponse(value: object): value is CustomAssortmentResponse {
    return true;
}

export function CustomAssortmentResponseFromJSON(json: any): CustomAssortmentResponse {
    return CustomAssortmentResponseFromJSONTyped(json, false);
}

export function CustomAssortmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAssortmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(CustomAssortmentItemResponseFromJSON)),
    };
}

export function CustomAssortmentResponseToJSON(json: any): CustomAssortmentResponse {
    return CustomAssortmentResponseToJSONTyped(json, false);
}

export function CustomAssortmentResponseToJSONTyped(value?: CustomAssortmentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'retailer_id': value['retailer_id'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(CustomAssortmentItemResponseToJSON)),
    };
}

