/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateSKUResponse
 */
export interface ValidateSKUResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateSKUResponse
     */
    valid?: boolean;
}

/**
 * Check if a given object implements the ValidateSKUResponse interface.
 */
export function instanceOfValidateSKUResponse(value: object): value is ValidateSKUResponse {
    return true;
}

export function ValidateSKUResponseFromJSON(json: any): ValidateSKUResponse {
    return ValidateSKUResponseFromJSONTyped(json, false);
}

export function ValidateSKUResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateSKUResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'valid': json['valid'] == null ? undefined : json['valid'],
    };
}

export function ValidateSKUResponseToJSON(json: any): ValidateSKUResponse {
    return ValidateSKUResponseToJSONTyped(json, false);
}

export function ValidateSKUResponseToJSONTyped(value?: ValidateSKUResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'valid': value['valid'],
    };
}

