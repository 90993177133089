/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailerBoardDetailsResponse
 */
export interface RetailerBoardDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof RetailerBoardDetailsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerBoardDetailsResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailerBoardDetailsResponse
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RetailerBoardDetailsResponse
     */
    products_count?: number;
}

/**
 * Check if a given object implements the RetailerBoardDetailsResponse interface.
 */
export function instanceOfRetailerBoardDetailsResponse(value: object): value is RetailerBoardDetailsResponse {
    return true;
}

export function RetailerBoardDetailsResponseFromJSON(json: any): RetailerBoardDetailsResponse {
    return RetailerBoardDetailsResponseFromJSONTyped(json, false);
}

export function RetailerBoardDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerBoardDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'images': json['images'] == null ? undefined : json['images'],
        'products_count': json['products_count'] == null ? undefined : json['products_count'],
    };
}

export function RetailerBoardDetailsResponseToJSON(json: any): RetailerBoardDetailsResponse {
    return RetailerBoardDetailsResponseToJSONTyped(json, false);
}

export function RetailerBoardDetailsResponseToJSONTyped(value?: RetailerBoardDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'images': value['images'],
        'products_count': value['products_count'],
    };
}

