/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update Retailer Account object
 * @export
 * @interface UpdateRetailerAccountRequest
 */
export interface UpdateRetailerAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    store_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    store_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    year_founded?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    facebook?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    status?: UpdateRetailerAccountRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    store_location_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    balance_level?: UpdateRetailerAccountRequestBalanceLevelEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRetailerAccountRequest
     */
    values?: Array<UpdateRetailerAccountRequestValuesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRetailerAccountRequest
     */
    store_types?: Array<UpdateRetailerAccountRequestStoreTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRetailerAccountRequest
     */
    store_gallery?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRetailerAccountRequest
     */
    is_public: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    score?: UpdateRetailerAccountRequestScoreEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerAccountRequest
     */
    phone_number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRetailerAccountRequest
     */
    review_required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRetailerAccountRequest
     */
    payment_plan_enrolled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRetailerAccountRequest
     */
    use_balance: boolean;
}


/**
 * @export
 */
export const UpdateRetailerAccountRequestStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Banned: 'BANNED'
} as const;
export type UpdateRetailerAccountRequestStatusEnum = typeof UpdateRetailerAccountRequestStatusEnum[keyof typeof UpdateRetailerAccountRequestStatusEnum];

/**
 * @export
 */
export const UpdateRetailerAccountRequestBalanceLevelEnum = {
    _500: '500',
    _2000: '2000',
    _2500: '2500',
    _5000: '5000',
    _7500: '7500',
    _10000: '10000',
    _15000: '15000',
    _20000: '20000',
    _25000: '25000',
    _100000: '100000'
} as const;
export type UpdateRetailerAccountRequestBalanceLevelEnum = typeof UpdateRetailerAccountRequestBalanceLevelEnum[keyof typeof UpdateRetailerAccountRequestBalanceLevelEnum];

/**
 * @export
 */
export const UpdateRetailerAccountRequestValuesEnum = {
    FemaleFounded: 'FEMALE_FOUNDED',
    EthicalProduction: 'ETHICAL_PRODUCTION',
    CleanIngredients: 'CLEAN_INGREDIENTS',
    Sustainable: 'SUSTAINABLE',
    Handmade: 'HANDMADE',
    MadeInTheUs: 'MADE_IN_THE_US',
    CharitableBrandGiveBack: 'CHARITABLE_BRAND_GIVE_BACK',
    Organic: 'ORGANIC',
    ZeroWaste: 'ZERO_WASTE',
    BlackOwned: 'BLACK_OWNED',
    LatinxOwned: 'LATINX_OWNED',
    AapiOwned: 'AAPI_OWNED',
    IndigenousOwned: 'INDIGENOUS_OWNED',
    LgbtqOwned: 'LGBTQ_OWNED',
    MinorityOwnedBusiness: 'MINORITY_OWNED_BUSINESS',
    DisabilityOwned: 'DISABILITY_OWNED'
} as const;
export type UpdateRetailerAccountRequestValuesEnum = typeof UpdateRetailerAccountRequestValuesEnum[keyof typeof UpdateRetailerAccountRequestValuesEnum];

/**
 * @export
 */
export const UpdateRetailerAccountRequestStoreTypesEnum = {
    BrickAndMortar: 'BRICK_AND_MORTAR',
    Online: 'ONLINE',
    PopUp: 'POP_UP'
} as const;
export type UpdateRetailerAccountRequestStoreTypesEnum = typeof UpdateRetailerAccountRequestStoreTypesEnum[keyof typeof UpdateRetailerAccountRequestStoreTypesEnum];

/**
 * @export
 */
export const UpdateRetailerAccountRequestScoreEnum = {
    NoScore: 'NO_SCORE',
    Good: 'GOOD',
    Better: 'BETTER',
    Best: 'BEST'
} as const;
export type UpdateRetailerAccountRequestScoreEnum = typeof UpdateRetailerAccountRequestScoreEnum[keyof typeof UpdateRetailerAccountRequestScoreEnum];


/**
 * Check if a given object implements the UpdateRetailerAccountRequest interface.
 */
export function instanceOfUpdateRetailerAccountRequest(value: object): value is UpdateRetailerAccountRequest {
    if (!('is_public' in value) || value['is_public'] === undefined) return false;
    if (!('review_required' in value) || value['review_required'] === undefined) return false;
    if (!('payment_plan_enrolled' in value) || value['payment_plan_enrolled'] === undefined) return false;
    if (!('use_balance' in value) || value['use_balance'] === undefined) return false;
    return true;
}

export function UpdateRetailerAccountRequestFromJSON(json: any): UpdateRetailerAccountRequest {
    return UpdateRetailerAccountRequestFromJSONTyped(json, false);
}

export function UpdateRetailerAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRetailerAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'store_name': json['store_name'] == null ? undefined : json['store_name'],
        'store_url': json['store_url'] == null ? undefined : json['store_url'],
        'year_founded': json['year_founded'] == null ? undefined : json['year_founded'],
        'instagram': json['instagram'] == null ? undefined : json['instagram'],
        'facebook': json['facebook'] == null ? undefined : json['facebook'],
        'twitter': json['twitter'] == null ? undefined : json['twitter'],
        'bio': json['bio'] == null ? undefined : json['bio'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'status': json['status'] == null ? undefined : json['status'],
        'store_location_id': json['store_location_id'] == null ? undefined : json['store_location_id'],
        'balance_level': json['balance_level'] == null ? undefined : json['balance_level'],
        'values': json['values'] == null ? undefined : json['values'],
        'store_types': json['store_types'] == null ? undefined : json['store_types'],
        'store_gallery': json['store_gallery'] == null ? undefined : json['store_gallery'],
        'is_public': json['is_public'],
        'city': json['city'] == null ? undefined : json['city'],
        'score': json['score'] == null ? undefined : json['score'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'review_required': json['review_required'],
        'payment_plan_enrolled': json['payment_plan_enrolled'],
        'use_balance': json['use_balance'],
    };
}

export function UpdateRetailerAccountRequestToJSON(json: any): UpdateRetailerAccountRequest {
    return UpdateRetailerAccountRequestToJSONTyped(json, false);
}

export function UpdateRetailerAccountRequestToJSONTyped(value?: UpdateRetailerAccountRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'store_name': value['store_name'],
        'store_url': value['store_url'],
        'year_founded': value['year_founded'],
        'instagram': value['instagram'],
        'facebook': value['facebook'],
        'twitter': value['twitter'],
        'bio': value['bio'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'status': value['status'],
        'store_location_id': value['store_location_id'],
        'balance_level': value['balance_level'],
        'values': value['values'],
        'store_types': value['store_types'],
        'store_gallery': value['store_gallery'],
        'is_public': value['is_public'],
        'city': value['city'],
        'score': value['score'],
        'phone_number': value['phone_number'],
        'review_required': value['review_required'],
        'payment_plan_enrolled': value['payment_plan_enrolled'],
        'use_balance': value['use_balance'],
    };
}

