/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateVideoRequest
 */
export interface UpdateVideoRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoRequest
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVideoRequest
     */
    entity_type: UpdateVideoRequestEntityTypeEnum;
}


/**
 * @export
 */
export const UpdateVideoRequestEntityTypeEnum = {
    Product: 'PRODUCT'
} as const;
export type UpdateVideoRequestEntityTypeEnum = typeof UpdateVideoRequestEntityTypeEnum[keyof typeof UpdateVideoRequestEntityTypeEnum];


/**
 * Check if a given object implements the UpdateVideoRequest interface.
 */
export function instanceOfUpdateVideoRequest(value: object): value is UpdateVideoRequest {
    if (!('entity_id' in value) || value['entity_id'] === undefined) return false;
    if (!('entity_type' in value) || value['entity_type'] === undefined) return false;
    return true;
}

export function UpdateVideoRequestFromJSON(json: any): UpdateVideoRequest {
    return UpdateVideoRequestFromJSONTyped(json, false);
}

export function UpdateVideoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateVideoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'url': json['url'] == null ? undefined : json['url'],
        'entity_id': json['entity_id'],
        'entity_type': json['entity_type'],
    };
}

export function UpdateVideoRequestToJSON(json: any): UpdateVideoRequest {
    return UpdateVideoRequestToJSONTyped(json, false);
}

export function UpdateVideoRequestToJSONTyped(value?: UpdateVideoRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'url': value['url'],
        'entity_id': value['entity_id'],
        'entity_type': value['entity_type'],
    };
}

