/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    has_valid_address_syntax: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    has_mx_or_a_record: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    is_suspected_disposable_address: boolean;
}

/**
 * Check if a given object implements the Domain interface.
 */
export function instanceOfDomain(value: object): value is Domain {
    if (!('has_valid_address_syntax' in value) || value['has_valid_address_syntax'] === undefined) return false;
    if (!('has_mx_or_a_record' in value) || value['has_mx_or_a_record'] === undefined) return false;
    if (!('is_suspected_disposable_address' in value) || value['is_suspected_disposable_address'] === undefined) return false;
    return true;
}

export function DomainFromJSON(json: any): Domain {
    return DomainFromJSONTyped(json, false);
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
    if (json == null) {
        return json;
    }
    return {
        
        'has_valid_address_syntax': json['has_valid_address_syntax'],
        'has_mx_or_a_record': json['has_mx_or_a_record'],
        'is_suspected_disposable_address': json['is_suspected_disposable_address'],
    };
}

export function DomainToJSON(json: any): Domain {
    return DomainToJSONTyped(json, false);
}

export function DomainToJSONTyped(value?: Domain | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'has_valid_address_syntax': value['has_valid_address_syntax'],
        'has_mx_or_a_record': value['has_mx_or_a_record'],
        'is_suspected_disposable_address': value['is_suspected_disposable_address'],
    };
}

