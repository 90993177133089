/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkBypassOnboardingRequest,
  DraftBrandResponse,
  MarketingCategoriesResponse,
  UnlinkBrandsFromTradeShowRequest,
  UpdateDraftBrandRequest,
  UploadFileRequest,
  UploadProductBuildingLineSheetResponse,
} from '../models/index';
import {
    BulkBypassOnboardingRequestFromJSON,
    BulkBypassOnboardingRequestToJSON,
    DraftBrandResponseFromJSON,
    DraftBrandResponseToJSON,
    MarketingCategoriesResponseFromJSON,
    MarketingCategoriesResponseToJSON,
    UnlinkBrandsFromTradeShowRequestFromJSON,
    UnlinkBrandsFromTradeShowRequestToJSON,
    UpdateDraftBrandRequestFromJSON,
    UpdateDraftBrandRequestToJSON,
    UploadFileRequestFromJSON,
    UploadFileRequestToJSON,
    UploadProductBuildingLineSheetResponseFromJSON,
    UploadProductBuildingLineSheetResponseToJSON,
} from '../models/index';

export interface ApproveOutreachBrandRequest {
    id: string;
}

export interface BulkBypassOnboardingOperationRequest {
    bulkBypassOnboardingRequest: BulkBypassOnboardingRequest;
}

export interface BypassProductRequiredInOnboardingRequest {
    id: string;
}

export interface CompleteOnboardingFormRequest {
    id: string;
}

export interface DenyBrandRequest {
    id: string;
}

export interface GetDraftBrandByBrandIdRequest {
    id: string;
}

export interface MoveDraftBrandDataToBrandRequest {
    id: string;
}

export interface UnlinkBrandsFromTradeShowOperationRequest {
    eventId: string;
    unlinkBrandsFromTradeShowRequest: UnlinkBrandsFromTradeShowRequest;
}

export interface UpdateDraftBrandOperationRequest {
    id: string;
    updateDraftBrandRequest: UpdateDraftBrandRequest;
}

export interface UploadBrandProductBuildingLineSheetRequest {
    id: string;
    uploadFileRequest: UploadFileRequest;
}

/**
 * 
 */
export class OnboardingControllerApi extends runtime.BaseAPI {

    /**
     * Approve an outreach brand that is in NEEDS_REVIEW status by admin.
     */
    async approveOutreachBrandRaw(requestParameters: ApproveOutreachBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approveOutreachBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve an outreach brand that is in NEEDS_REVIEW status by admin.
     */
    async approveOutreachBrand(requestParameters: ApproveOutreachBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveOutreachBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Move draft brand data to appropriate brand entity and mark onboarding as complete, ignoring required product count
     */
    async bulkBypassOnboardingRaw(requestParameters: BulkBypassOnboardingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkBypassOnboardingRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkBypassOnboardingRequest',
                'Required parameter "bulkBypassOnboardingRequest" was null or undefined when calling bulkBypassOnboarding().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/bulk-bypass-onboarding`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkBypassOnboardingRequestToJSON(requestParameters['bulkBypassOnboardingRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Move draft brand data to appropriate brand entity and mark onboarding as complete, ignoring required product count
     */
    async bulkBypassOnboarding(requestParameters: BulkBypassOnboardingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkBypassOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Move draft brand data to appropriate brand entity and mark onboarding as complete, ignoring required product count
     */
    async bypassProductRequiredInOnboardingRaw(requestParameters: BypassProductRequiredInOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling bypassProductRequiredInOnboarding().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}/bypass-onboarding`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Move draft brand data to appropriate brand entity and mark onboarding as complete, ignoring required product count
     */
    async bypassProductRequiredInOnboarding(requestParameters: BypassProductRequiredInOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bypassProductRequiredInOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Complete brand onboarding form
     */
    async completeOnboardingFormRaw(requestParameters: CompleteOnboardingFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling completeOnboardingForm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Complete brand onboarding form
     */
    async completeOnboardingForm(requestParameters: CompleteOnboardingFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.completeOnboardingFormRaw(requestParameters, initOverrides);
    }

    /**
     * Deny outreach brand which completed onboarding form and needs review
     */
    async denyBrandRaw(requestParameters: DenyBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling denyBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}/deny`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deny outreach brand which completed onboarding form and needs review
     */
    async denyBrand(requestParameters: DenyBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.denyBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Get draft brand by brand ID
     */
    async getDraftBrandByBrandIdRaw(requestParameters: GetDraftBrandByBrandIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DraftBrandResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getDraftBrandByBrandId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DraftBrandResponseFromJSON(jsonValue));
    }

    /**
     * Get draft brand by brand ID
     */
    async getDraftBrandByBrandId(requestParameters: GetDraftBrandByBrandIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DraftBrandResponse> {
        const response = await this.getDraftBrandByBrandIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get draft brand by principal ID
     */
    async getDraftBrandByPrincipalIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DraftBrandResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DraftBrandResponseFromJSON(jsonValue));
    }

    /**
     * Get draft brand by principal ID
     */
    async getDraftBrandByPrincipalId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DraftBrandResponse> {
        const response = await this.getDraftBrandByPrincipalIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get marketing category values.
     */
    async getMarketingCategoryValuesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingCategoriesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/marketing-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingCategoriesResponseFromJSON(jsonValue));
    }

    /**
     * Get marketing category values.
     */
    async getMarketingCategoryValues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingCategoriesResponse> {
        const response = await this.getMarketingCategoryValuesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Move draft brand data to appropriate brand entity
     */
    async moveDraftBrandDataToBrandRaw(requestParameters: MoveDraftBrandDataToBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling moveDraftBrandDataToBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}/move-data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Move draft brand data to appropriate brand entity
     */
    async moveDraftBrandDataToBrand(requestParameters: MoveDraftBrandDataToBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveDraftBrandDataToBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Publish the brand after reviewing the profile that was built by the Bulletin team.
     */
    async publishBrandRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/me/publish`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish the brand after reviewing the profile that was built by the Bulletin team.
     */
    async publishBrand(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publishBrandRaw(initOverrides);
    }

    /**
     * Unlink A2Z brands from trade show
     */
    async unlinkBrandsFromTradeShowRaw(requestParameters: UnlinkBrandsFromTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling unlinkBrandsFromTradeShow().'
            );
        }

        if (requestParameters['unlinkBrandsFromTradeShowRequest'] == null) {
            throw new runtime.RequiredError(
                'unlinkBrandsFromTradeShowRequest',
                'Required parameter "unlinkBrandsFromTradeShowRequest" was null or undefined when calling unlinkBrandsFromTradeShow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brands/trade-show/{eventId}/unlink`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnlinkBrandsFromTradeShowRequestToJSON(requestParameters['unlinkBrandsFromTradeShowRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlink A2Z brands from trade show
     */
    async unlinkBrandsFromTradeShow(requestParameters: UnlinkBrandsFromTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkBrandsFromTradeShowRaw(requestParameters, initOverrides);
    }

    /**
     * Update draft brand by brand ID
     */
    async updateDraftBrandRaw(requestParameters: UpdateDraftBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateDraftBrand().'
            );
        }

        if (requestParameters['updateDraftBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'updateDraftBrandRequest',
                'Required parameter "updateDraftBrandRequest" was null or undefined when calling updateDraftBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDraftBrandRequestToJSON(requestParameters['updateDraftBrandRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update draft brand by brand ID
     */
    async updateDraftBrand(requestParameters: UpdateDraftBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDraftBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Upload brand\'s product building line sheet
     */
    async uploadBrandProductBuildingLineSheetRaw(requestParameters: UploadBrandProductBuildingLineSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadProductBuildingLineSheetResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadBrandProductBuildingLineSheet().'
            );
        }

        if (requestParameters['uploadFileRequest'] == null) {
            throw new runtime.RequiredError(
                'uploadFileRequest',
                'Required parameter "uploadFileRequest" was null or undefined when calling uploadBrandProductBuildingLineSheet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding/brand/{id}/line-sheet`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UploadFileRequestToJSON(requestParameters['uploadFileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadProductBuildingLineSheetResponseFromJSON(jsonValue));
    }

    /**
     * Upload brand\'s product building line sheet
     */
    async uploadBrandProductBuildingLineSheet(requestParameters: UploadBrandProductBuildingLineSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadProductBuildingLineSheetResponse> {
        const response = await this.uploadBrandProductBuildingLineSheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
