/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PackingSlipItemResponse } from './PackingSlipItemResponse';
import {
    PackingSlipItemResponseFromJSON,
    PackingSlipItemResponseFromJSONTyped,
    PackingSlipItemResponseToJSON,
    PackingSlipItemResponseToJSONTyped,
} from './PackingSlipItemResponse';

/**
 * 
 * @export
 * @interface PackingSlipResponse
 */
export interface PackingSlipResponse {
    /**
     * 
     * @type {string}
     * @memberof PackingSlipResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipResponse
     */
    suborder_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipResponse
     */
    tracking_number?: string;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipResponse
     */
    shipping_cost?: number;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipResponse
     */
    shipping_carrier?: PackingSlipResponseShippingCarrierEnum;
    /**
     * 
     * @type {Date}
     * @memberof PackingSlipResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackingSlipResponse
     */
    updated_at?: Date;
    /**
     * 
     * @type {Array<PackingSlipItemResponse>}
     * @memberof PackingSlipResponse
     */
    items?: Array<PackingSlipItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipResponse
     */
    status?: PackingSlipResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipResponse
     */
    ordinal?: number;
}


/**
 * @export
 */
export const PackingSlipResponseShippingCarrierEnum = {
    Apc: 'APC',
    CanadaPost: 'CANADA_POST',
    Canpar: 'CANPAR',
    Dhl: 'DHL',
    FedEx: 'FedEx',
    Gls: 'GLS',
    NewGistics: 'NEW_GISTICS',
    Purolator: 'PUROLATOR',
    Sendle: 'SENDLE',
    SfExpress: 'SF_EXPRESS',
    Ups: 'UPS',
    Usps: 'USPS',
    Other: 'OTHER'
} as const;
export type PackingSlipResponseShippingCarrierEnum = typeof PackingSlipResponseShippingCarrierEnum[keyof typeof PackingSlipResponseShippingCarrierEnum];

/**
 * @export
 */
export const PackingSlipResponseStatusEnum = {
    Draft: 'DRAFT',
    Confirmed: 'CONFIRMED',
    Shipped: 'SHIPPED'
} as const;
export type PackingSlipResponseStatusEnum = typeof PackingSlipResponseStatusEnum[keyof typeof PackingSlipResponseStatusEnum];


/**
 * Check if a given object implements the PackingSlipResponse interface.
 */
export function instanceOfPackingSlipResponse(value: object): value is PackingSlipResponse {
    return true;
}

export function PackingSlipResponseFromJSON(json: any): PackingSlipResponse {
    return PackingSlipResponseFromJSONTyped(json, false);
}

export function PackingSlipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackingSlipResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'suborder_id': json['suborder_id'] == null ? undefined : json['suborder_id'],
        'tracking_number': json['tracking_number'] == null ? undefined : json['tracking_number'],
        'shipping_cost': json['shipping_cost'] == null ? undefined : json['shipping_cost'],
        'shipping_carrier': json['shipping_carrier'] == null ? undefined : json['shipping_carrier'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updated_at': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(PackingSlipItemResponseFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
    };
}

export function PackingSlipResponseToJSON(json: any): PackingSlipResponse {
    return PackingSlipResponseToJSONTyped(json, false);
}

export function PackingSlipResponseToJSONTyped(value?: PackingSlipResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'suborder_id': value['suborder_id'],
        'tracking_number': value['tracking_number'],
        'shipping_cost': value['shipping_cost'],
        'shipping_carrier': value['shipping_carrier'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'updated_at': value['updated_at'] == null ? undefined : ((value['updated_at']).toISOString()),
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(PackingSlipItemResponseToJSON)),
        'status': value['status'],
        'ordinal': value['ordinal'],
    };
}

