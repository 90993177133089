import _debounce from 'lodash/debounce'

const breakpoints = {
  xs: 460,
  sm: 770,
  md: 992,
  xl: 1190,
  xxl: 1440
}

export const breakPointsHelper = {
  data() {
    return {
      windowWidth: window.innerWidth,
      breakpoints: breakpoints
    }
  },
  mounted() {
    window.addEventListener('resize', _debounce(this.setDimensions, 250))
  },
  destroy() {
    window.removeEventListener('resize', this.setDimensions)
  },
  methods: {
    setDimensions: function () {
      this.windowWidth = window.innerWidth
    },
    underBreakpoint: function (breakpoint) {
      return this.windowWidth < breakpoints[breakpoint]
    },
    aboveBreakpoint: function (breakpoint) {
      return this.windowWidth > breakpoints[breakpoint]
    }
  }
}
