/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddProductToBrandBoardsRequest,
  CreateBrandBoardRequest,
  GetBrandBoardDetailsResponse,
  GetBrandBoardSharesResponse,
  GetBrandBoardsResponse,
  IdWrapper,
  ShareBrandBoardRequest,
  UpdateBrandBoardRequest,
} from '../models/index';
import {
    AddProductToBrandBoardsRequestFromJSON,
    AddProductToBrandBoardsRequestToJSON,
    CreateBrandBoardRequestFromJSON,
    CreateBrandBoardRequestToJSON,
    GetBrandBoardDetailsResponseFromJSON,
    GetBrandBoardDetailsResponseToJSON,
    GetBrandBoardSharesResponseFromJSON,
    GetBrandBoardSharesResponseToJSON,
    GetBrandBoardsResponseFromJSON,
    GetBrandBoardsResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ShareBrandBoardRequestFromJSON,
    ShareBrandBoardRequestToJSON,
    UpdateBrandBoardRequestFromJSON,
    UpdateBrandBoardRequestToJSON,
} from '../models/index';

export interface AddProductToBrandBoardRequest {
    boardId: string;
    id: string;
}

export interface AddProductToBrandBoardsOperationRequest {
    id: string;
    addProductToBrandBoardsRequest: AddProductToBrandBoardsRequest;
}

export interface CreateBrandBoardOperationRequest {
    brandId: string;
    createBrandBoardRequest: CreateBrandBoardRequest;
}

export interface DeleteBrandBoardRequest {
    id: string;
}

export interface DeleteBrandBoard1Request {
    id: string;
    productId: string;
}

export interface GetBrandBoardByIdRequest {
    id: string;
    order: string;
    search?: string;
}

export interface GetBrandBoardSharesByBrandIdRequest {
    brandId: string;
    boardId: string;
}

export interface GetBrandBoardsRequest {
    brandId: string;
    page: number;
    size: number;
    sortBy: string;
    sortOrder: string;
    search?: string;
    productId?: string;
}

export interface ShareBrandBoardOperationRequest {
    id: string;
    shareBrandBoardRequest: ShareBrandBoardRequest;
}

export interface UpdateBrandBoardOperationRequest {
    id: string;
    updateBrandBoardRequest: UpdateBrandBoardRequest;
}

/**
 * 
 */
export class BrandBoardControllerApi extends runtime.BaseAPI {

    /**
     * Add product to Brand Board
     */
    async addProductToBrandBoardRaw(requestParameters: AddProductToBrandBoardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['boardId'] == null) {
            throw new runtime.RequiredError(
                'boardId',
                'Required parameter "boardId" was null or undefined when calling addProductToBrandBoard().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addProductToBrandBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/boards/{boardId}/products/{id}/add`.replace(`{${"boardId"}}`, encodeURIComponent(String(requestParameters['boardId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add product to Brand Board
     */
    async addProductToBrandBoard(requestParameters: AddProductToBrandBoardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProductToBrandBoardRaw(requestParameters, initOverrides);
    }

    /**
     * Add products to Brand Boards
     */
    async addProductToBrandBoardsRaw(requestParameters: AddProductToBrandBoardsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addProductToBrandBoards().'
            );
        }

        if (requestParameters['addProductToBrandBoardsRequest'] == null) {
            throw new runtime.RequiredError(
                'addProductToBrandBoardsRequest',
                'Required parameter "addProductToBrandBoardsRequest" was null or undefined when calling addProductToBrandBoards().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/products/{id}/add`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProductToBrandBoardsRequestToJSON(requestParameters['addProductToBrandBoardsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add products to Brand Boards
     */
    async addProductToBrandBoards(requestParameters: AddProductToBrandBoardsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProductToBrandBoardsRaw(requestParameters, initOverrides);
    }

    /**
     * Create Brand Board
     */
    async createBrandBoardRaw(requestParameters: CreateBrandBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling createBrandBoard().'
            );
        }

        if (requestParameters['createBrandBoardRequest'] == null) {
            throw new runtime.RequiredError(
                'createBrandBoardRequest',
                'Required parameter "createBrandBoardRequest" was null or undefined when calling createBrandBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/brands/{brandId}/boards`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBrandBoardRequestToJSON(requestParameters['createBrandBoardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create Brand Board
     */
    async createBrandBoard(requestParameters: CreateBrandBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createBrandBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Brand Board
     */
    async deleteBrandBoardRaw(requestParameters: DeleteBrandBoardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteBrandBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Brand Board
     */
    async deleteBrandBoard(requestParameters: DeleteBrandBoardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBrandBoardRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Product from Brand Board
     */
    async deleteBrandBoard1Raw(requestParameters: DeleteBrandBoard1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteBrandBoard1().'
            );
        }

        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling deleteBrandBoard1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/{id}/product/{productId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Product from Brand Board
     */
    async deleteBrandBoard1(requestParameters: DeleteBrandBoard1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBrandBoard1Raw(requestParameters, initOverrides);
    }

    /**
     * Get Brand Board details
     */
    async getBrandBoardByIdRaw(requestParameters: GetBrandBoardByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandBoardDetailsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getBrandBoardById().'
            );
        }

        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling getBrandBoardById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandBoardDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Get Brand Board details
     */
    async getBrandBoardById(requestParameters: GetBrandBoardByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandBoardDetailsResponse> {
        const response = await this.getBrandBoardByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Brand Board Shares by Brand Id
     */
    async getBrandBoardSharesByBrandIdRaw(requestParameters: GetBrandBoardSharesByBrandIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandBoardSharesResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getBrandBoardSharesByBrandId().'
            );
        }

        if (requestParameters['boardId'] == null) {
            throw new runtime.RequiredError(
                'boardId',
                'Required parameter "boardId" was null or undefined when calling getBrandBoardSharesByBrandId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/brands/{brandId}/boards/{boardId}/shared-with`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))).replace(`{${"boardId"}}`, encodeURIComponent(String(requestParameters['boardId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandBoardSharesResponseFromJSON(jsonValue));
    }

    /**
     * Get Brand Board Shares by Brand Id
     */
    async getBrandBoardSharesByBrandId(requestParameters: GetBrandBoardSharesByBrandIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandBoardSharesResponse> {
        const response = await this.getBrandBoardSharesByBrandIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Brand Boards details
     */
    async getBrandBoardsRaw(requestParameters: GetBrandBoardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandBoardsResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getBrandBoards().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getBrandBoards().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getBrandBoards().'
            );
        }

        if (requestParameters['sortBy'] == null) {
            throw new runtime.RequiredError(
                'sortBy',
                'Required parameter "sortBy" was null or undefined when calling getBrandBoards().'
            );
        }

        if (requestParameters['sortOrder'] == null) {
            throw new runtime.RequiredError(
                'sortOrder',
                'Required parameter "sortOrder" was null or undefined when calling getBrandBoards().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortOrder'] != null) {
            queryParameters['sortOrder'] = requestParameters['sortOrder'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/brands/{brandId}/boards`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandBoardsResponseFromJSON(jsonValue));
    }

    /**
     * Get Brand Boards details
     */
    async getBrandBoards(requestParameters: GetBrandBoardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandBoardsResponse> {
        const response = await this.getBrandBoardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Share Brand Board
     */
    async shareBrandBoardRaw(requestParameters: ShareBrandBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling shareBrandBoard().'
            );
        }

        if (requestParameters['shareBrandBoardRequest'] == null) {
            throw new runtime.RequiredError(
                'shareBrandBoardRequest',
                'Required parameter "shareBrandBoardRequest" was null or undefined when calling shareBrandBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/{id}/share`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareBrandBoardRequestToJSON(requestParameters['shareBrandBoardRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Share Brand Board
     */
    async shareBrandBoard(requestParameters: ShareBrandBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shareBrandBoardRaw(requestParameters, initOverrides);
    }

    /**
     * Update Brand Board
     */
    async updateBrandBoardRaw(requestParameters: UpdateBrandBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateBrandBoard().'
            );
        }

        if (requestParameters['updateBrandBoardRequest'] == null) {
            throw new runtime.RequiredError(
                'updateBrandBoardRequest',
                'Required parameter "updateBrandBoardRequest" was null or undefined when calling updateBrandBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-boards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandBoardRequestToJSON(requestParameters['updateBrandBoardRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Brand Board
     */
    async updateBrandBoard(requestParameters: UpdateBrandBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBrandBoardRaw(requestParameters, initOverrides);
    }

}
