/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarketingCategoryResponse
 */
export interface MarketingCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof MarketingCategoryResponse
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingCategoryResponse
     */
    value?: MarketingCategoryResponseValueEnum;
}


/**
 * @export
 */
export const MarketingCategoryResponseValueEnum = {
    GiftAndLifestyle: 'GIFT_AND_LIFESTYLE',
    Home: 'HOME',
    JewelryAndAccessories: 'JEWELRY_AND_ACCESSORIES'
} as const;
export type MarketingCategoryResponseValueEnum = typeof MarketingCategoryResponseValueEnum[keyof typeof MarketingCategoryResponseValueEnum];


/**
 * Check if a given object implements the MarketingCategoryResponse interface.
 */
export function instanceOfMarketingCategoryResponse(value: object): value is MarketingCategoryResponse {
    return true;
}

export function MarketingCategoryResponseFromJSON(json: any): MarketingCategoryResponse {
    return MarketingCategoryResponseFromJSONTyped(json, false);
}

export function MarketingCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingCategoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function MarketingCategoryResponseToJSON(json: any): MarketingCategoryResponse {
    return MarketingCategoryResponseToJSONTyped(json, false);
}

export function MarketingCategoryResponseToJSONTyped(value?: MarketingCategoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'value': value['value'],
    };
}

