/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulletinAddress
 */
export interface BulletinAddress {
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    apartment: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinAddress
     */
    firstName: string;
}

/**
 * Check if a given object implements the BulletinAddress interface.
 */
export function instanceOfBulletinAddress(value: object): value is BulletinAddress {
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('zipCode' in value) || value['zipCode'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('apartment' in value) || value['apartment'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    return true;
}

export function BulletinAddressFromJSON(json: any): BulletinAddress {
    return BulletinAddressFromJSONTyped(json, false);
}

export function BulletinAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulletinAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'street': json['street'],
        'zipCode': json['zipCode'],
        'phone': json['phone'],
        'apartment': json['apartment'],
        'city': json['city'],
        'company': json['company'],
        'country': json['country'],
        'state': json['state'],
        'firstName': json['firstName'],
    };
}

export function BulletinAddressToJSON(json: any): BulletinAddress {
    return BulletinAddressToJSONTyped(json, false);
}

export function BulletinAddressToJSONTyped(value?: BulletinAddress | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'street': value['street'],
        'zipCode': value['zipCode'],
        'phone': value['phone'],
        'apartment': value['apartment'],
        'city': value['city'],
        'company': value['company'],
        'country': value['country'],
        'state': value['state'],
        'firstName': value['firstName'],
    };
}

