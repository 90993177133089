/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandBoardResponse
 */
export interface BrandBoardResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandBoardResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandBoardResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandBoardResponse
     */
    visibility?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandBoardResponse
     */
    show_on_profile?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrandBoardResponse
     */
    thumbnail_image_urls?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BrandBoardResponse
     */
    contains_product?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BrandBoardResponse
     */
    product_count?: number;
}

/**
 * Check if a given object implements the BrandBoardResponse interface.
 */
export function instanceOfBrandBoardResponse(value: object): value is BrandBoardResponse {
    return true;
}

export function BrandBoardResponseFromJSON(json: any): BrandBoardResponse {
    return BrandBoardResponseFromJSONTyped(json, false);
}

export function BrandBoardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandBoardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'visibility': json['visibility'] == null ? undefined : json['visibility'],
        'show_on_profile': json['show_on_profile'] == null ? undefined : json['show_on_profile'],
        'thumbnail_image_urls': json['thumbnail_image_urls'] == null ? undefined : json['thumbnail_image_urls'],
        'contains_product': json['contains_product'] == null ? undefined : json['contains_product'],
        'product_count': json['product_count'] == null ? undefined : json['product_count'],
    };
}

export function BrandBoardResponseToJSON(json: any): BrandBoardResponse {
    return BrandBoardResponseToJSONTyped(json, false);
}

export function BrandBoardResponseToJSONTyped(value?: BrandBoardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'visibility': value['visibility'],
        'show_on_profile': value['show_on_profile'],
        'thumbnail_image_urls': value['thumbnail_image_urls'],
        'contains_product': value['contains_product'],
        'product_count': value['product_count'],
    };
}

