/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SplitShipResponse
 */
export interface SplitShipResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof SplitShipResponse
     */
    packing_slip_ids?: Array<string>;
}

/**
 * Check if a given object implements the SplitShipResponse interface.
 */
export function instanceOfSplitShipResponse(value: object): value is SplitShipResponse {
    return true;
}

export function SplitShipResponseFromJSON(json: any): SplitShipResponse {
    return SplitShipResponseFromJSONTyped(json, false);
}

export function SplitShipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitShipResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'packing_slip_ids': json['packing_slip_ids'] == null ? undefined : json['packing_slip_ids'],
    };
}

export function SplitShipResponseToJSON(json: any): SplitShipResponse {
    return SplitShipResponseToJSONTyped(json, false);
}

export function SplitShipResponseToJSONTyped(value?: SplitShipResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'packing_slip_ids': value['packing_slip_ids'],
    };
}

