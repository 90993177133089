/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Change card request
 * @export
 * @interface ChangeSuborderCreditCardRequest
 */
export interface ChangeSuborderCreditCardRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeSuborderCreditCardRequest
     */
    card_id: string;
}

/**
 * Check if a given object implements the ChangeSuborderCreditCardRequest interface.
 */
export function instanceOfChangeSuborderCreditCardRequest(value: object): value is ChangeSuborderCreditCardRequest {
    if (!('card_id' in value) || value['card_id'] === undefined) return false;
    return true;
}

export function ChangeSuborderCreditCardRequestFromJSON(json: any): ChangeSuborderCreditCardRequest {
    return ChangeSuborderCreditCardRequestFromJSONTyped(json, false);
}

export function ChangeSuborderCreditCardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeSuborderCreditCardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'card_id': json['card_id'],
    };
}

export function ChangeSuborderCreditCardRequestToJSON(json: any): ChangeSuborderCreditCardRequest {
    return ChangeSuborderCreditCardRequestToJSONTyped(json, false);
}

export function ChangeSuborderCreditCardRequestToJSONTyped(value?: ChangeSuborderCreditCardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'card_id': value['card_id'],
    };
}

