/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayInvoiceResponse
 */
export interface PayInvoiceResponse {
    /**
     * 
     * @type {string}
     * @memberof PayInvoiceResponse
     */
    status?: PayInvoiceResponseStatusEnum;
}


/**
 * @export
 */
export const PayInvoiceResponseStatusEnum = {
    Paid: 'PAID',
    NotPaid: 'NOT_PAID',
    PaymentFailed: 'PAYMENT_FAILED',
    UnprocessableCharge: 'UNPROCESSABLE_CHARGE'
} as const;
export type PayInvoiceResponseStatusEnum = typeof PayInvoiceResponseStatusEnum[keyof typeof PayInvoiceResponseStatusEnum];


/**
 * Check if a given object implements the PayInvoiceResponse interface.
 */
export function instanceOfPayInvoiceResponse(value: object): value is PayInvoiceResponse {
    return true;
}

export function PayInvoiceResponseFromJSON(json: any): PayInvoiceResponse {
    return PayInvoiceResponseFromJSONTyped(json, false);
}

export function PayInvoiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayInvoiceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function PayInvoiceResponseToJSON(json: any): PayInvoiceResponse {
    return PayInvoiceResponseToJSONTyped(json, false);
}

export function PayInvoiceResponseToJSONTyped(value?: PayInvoiceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': value['status'],
    };
}

