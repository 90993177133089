<template>
  <div class="filter-checkbox-wrapper" :class="{ disabled, 'my-0': noMargin }">
    <div
      class="filter-checkbox-indicator"
      :class="{ checked: value }"
      @click="
        $emit('click', $event)
        $emit('input', !value)
      "
    >
      <Icon v-if="value" icon-key="checkmark-white" :size="14" />
    </div>
    <div
      class="checkbox-label font-sans"
      @click="
        $emit('click', $event)
        $emit('input', !value)
      "
    >
      {{ label }}
    </div>
    <Icon v-if="tooltip" v-tooltip="tooltip" icon-key="info" hover-type="ICON" />
  </div>
</template>

<script>
export default {
  props: ['value', 'label', 'disabled', 'tooltip', 'noMargin']
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.filter-checkbox-wrapper {
  margin: 0 5px 15px 5px;
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.filter-checkbox-indicator {
  border: 2px solid $color-grey-4;
  height: 24px;
  width: 24px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all $defaultTransitionTime $defaultTransitionType;

  &:hover {
    cursor: pointer;
    border-color: $color-black;
  }

  &.checked {
    background: $color-black;
    border-color: $color-black;
  }
}
.checkbox-label {
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
}
</style>
