/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface GetOrdersDashboardForSubordersResponse
 */
export interface GetOrdersDashboardForSubordersResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    order_number?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    brand_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    order_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    payout_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    original_charge_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    scheduled_charge_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    payout?: number;
    /**
     * 
     * @type {AddressResponse}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    balance_invoice_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    retailer_email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardForSubordersResponse
     */
    brand_email?: string;
}

/**
 * Check if a given object implements the GetOrdersDashboardForSubordersResponse interface.
 */
export function instanceOfGetOrdersDashboardForSubordersResponse(value: object): value is GetOrdersDashboardForSubordersResponse {
    return true;
}

export function GetOrdersDashboardForSubordersResponseFromJSON(json: any): GetOrdersDashboardForSubordersResponse {
    return GetOrdersDashboardForSubordersResponseFromJSONTyped(json, false);
}

export function GetOrdersDashboardForSubordersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrdersDashboardForSubordersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'order_number': json['order_number'] == null ? undefined : json['order_number'],
        'number': json['number'] == null ? undefined : json['number'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'order_id': json['order_id'] == null ? undefined : json['order_id'],
        'status': json['status'] == null ? undefined : json['status'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'payout_date': json['payout_date'] == null ? undefined : (new Date(json['payout_date'])),
        'original_charge_date': json['original_charge_date'] == null ? undefined : (new Date(json['original_charge_date'])),
        'scheduled_charge_date': json['scheduled_charge_date'] == null ? undefined : (new Date(json['scheduled_charge_date'])),
        'payout': json['payout'] == null ? undefined : json['payout'],
        'address': json['address'] == null ? undefined : AddressResponseFromJSON(json['address']),
        'balance_invoice_url': json['balance_invoice_url'] == null ? undefined : json['balance_invoice_url'],
        'retailer_email': json['retailer_email'] == null ? undefined : json['retailer_email'],
        'brand_email': json['brand_email'] == null ? undefined : json['brand_email'],
    };
}

export function GetOrdersDashboardForSubordersResponseToJSON(json: any): GetOrdersDashboardForSubordersResponse {
    return GetOrdersDashboardForSubordersResponseToJSONTyped(json, false);
}

export function GetOrdersDashboardForSubordersResponseToJSONTyped(value?: GetOrdersDashboardForSubordersResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'order_number': value['order_number'],
        'number': value['number'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'brand_id': value['brand_id'],
        'brand_name': value['brand_name'],
        'order_id': value['order_id'],
        'status': value['status'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'payout_date': value['payout_date'] == null ? undefined : ((value['payout_date']).toISOString()),
        'original_charge_date': value['original_charge_date'] == null ? undefined : ((value['original_charge_date']).toISOString()),
        'scheduled_charge_date': value['scheduled_charge_date'] == null ? undefined : ((value['scheduled_charge_date']).toISOString()),
        'payout': value['payout'],
        'address': AddressResponseToJSON(value['address']),
        'balance_invoice_url': value['balance_invoice_url'],
        'retailer_email': value['retailer_email'],
        'brand_email': value['brand_email'],
    };
}

