/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackingSlipItemResponse
 */
export interface PackingSlipItemResponse {
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipItemResponse
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemResponse
     */
    packing_slip_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemResponse
     */
    suborder_id?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PackingSlipItemResponse
     */
    characteristic_option_map?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PackingSlipItemResponse
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemResponse
     */
    product_image?: string;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemResponse
     */
    product_name?: string;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipItemResponse
     */
    shipped_quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemResponse
     */
    sku?: string;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipItemResponse
     */
    maximum_shipping_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipItemResponse
     */
    ordinal?: number;
}

/**
 * Check if a given object implements the PackingSlipItemResponse interface.
 */
export function instanceOfPackingSlipItemResponse(value: object): value is PackingSlipItemResponse {
    return true;
}

export function PackingSlipItemResponseFromJSON(json: any): PackingSlipItemResponse {
    return PackingSlipItemResponseFromJSONTyped(json, false);
}

export function PackingSlipItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackingSlipItemResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'packing_slip_id': json['packing_slip_id'] == null ? undefined : json['packing_slip_id'],
        'suborder_id': json['suborder_id'] == null ? undefined : json['suborder_id'],
        'characteristic_option_map': json['characteristic_option_map'] == null ? undefined : json['characteristic_option_map'],
        'price': json['price'] == null ? undefined : json['price'],
        'product_image': json['product_image'] == null ? undefined : json['product_image'],
        'product_name': json['product_name'] == null ? undefined : json['product_name'],
        'shipped_quantity': json['shipped_quantity'] == null ? undefined : json['shipped_quantity'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'maximum_shipping_quantity': json['maximum_shipping_quantity'] == null ? undefined : json['maximum_shipping_quantity'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
    };
}

export function PackingSlipItemResponseToJSON(json: any): PackingSlipItemResponse {
    return PackingSlipItemResponseToJSONTyped(json, false);
}

export function PackingSlipItemResponseToJSONTyped(value?: PackingSlipItemResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'quantity': value['quantity'],
        'packing_slip_id': value['packing_slip_id'],
        'suborder_id': value['suborder_id'],
        'characteristic_option_map': value['characteristic_option_map'],
        'price': value['price'],
        'product_image': value['product_image'],
        'product_name': value['product_name'],
        'shipped_quantity': value['shipped_quantity'],
        'sku': value['sku'],
        'maximum_shipping_quantity': value['maximum_shipping_quantity'],
        'ordinal': value['ordinal'],
    };
}

