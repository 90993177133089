/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandsApplicationsResponse
 */
export interface BrandsApplicationsResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    instagram_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    based_in?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandsApplicationsResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandsApplicationsResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrandsApplicationsResponse
     */
    is_published?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    created_at?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandsApplicationsResponse
     */
    minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandsApplicationsResponse
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    onboarding_complete?: string;
    /**
     * 
     * @type {object}
     * @memberof BrandsApplicationsResponse
     */
    address?: object;
    /**
     * 
     * @type {string}
     * @memberof BrandsApplicationsResponse
     */
    source?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandsApplicationsResponse
     */
    bulletin_pick?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandsApplicationsResponse
     */
    buy_on_bulletin?: boolean;
}

/**
 * Check if a given object implements the BrandsApplicationsResponse interface.
 */
export function instanceOfBrandsApplicationsResponse(value: object): value is BrandsApplicationsResponse {
    return true;
}

export function BrandsApplicationsResponseFromJSON(json: any): BrandsApplicationsResponse {
    return BrandsApplicationsResponseFromJSONTyped(json, false);
}

export function BrandsApplicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandsApplicationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'instagram_url': json['instagram_url'] == null ? undefined : json['instagram_url'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'status': json['status'] == null ? undefined : json['status'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'is_published': json['is_published'] == null ? undefined : json['is_published'],
        'created_at': json['created_at'] == null ? undefined : json['created_at'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'website_url': json['website_url'] == null ? undefined : json['website_url'],
        'onboarding_complete': json['onboarding_complete'] == null ? undefined : json['onboarding_complete'],
        'address': json['address'] == null ? undefined : json['address'],
        'source': json['source'] == null ? undefined : json['source'],
        'bulletin_pick': json['bulletin_pick'] == null ? undefined : json['bulletin_pick'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
    };
}

export function BrandsApplicationsResponseToJSON(json: any): BrandsApplicationsResponse {
    return BrandsApplicationsResponseToJSONTyped(json, false);
}

export function BrandsApplicationsResponseToJSONTyped(value?: BrandsApplicationsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'brand_name': value['brand_name'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'instagram_url': value['instagram_url'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'vanity_url_name': value['vanity_url_name'],
        'status': value['status'],
        'based_in': value['based_in'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'is_published': value['is_published'],
        'created_at': value['created_at'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'website_url': value['website_url'],
        'onboarding_complete': value['onboarding_complete'],
        'address': value['address'],
        'source': value['source'],
        'bulletin_pick': value['bulletin_pick'],
        'buy_on_bulletin': value['buy_on_bulletin'],
    };
}

