/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopifyOrderAlgolia
 */
export interface ShopifyOrderAlgolia {
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderAlgolia
     */
    objectID?: string;
    /**
     * 
     * @type {number}
     * @memberof ShopifyOrderAlgolia
     */
    order_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderAlgolia
     */
    order_gid?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyOrderAlgolia
     */
    order_url?: string;
}

/**
 * Check if a given object implements the ShopifyOrderAlgolia interface.
 */
export function instanceOfShopifyOrderAlgolia(value: object): value is ShopifyOrderAlgolia {
    return true;
}

export function ShopifyOrderAlgoliaFromJSON(json: any): ShopifyOrderAlgolia {
    return ShopifyOrderAlgoliaFromJSONTyped(json, false);
}

export function ShopifyOrderAlgoliaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopifyOrderAlgolia {
    if (json == null) {
        return json;
    }
    return {
        
        'objectID': json['objectID'] == null ? undefined : json['objectID'],
        'order_id': json['order_id'] == null ? undefined : json['order_id'],
        'order_gid': json['order_gid'] == null ? undefined : json['order_gid'],
        'order_url': json['order_url'] == null ? undefined : json['order_url'],
    };
}

export function ShopifyOrderAlgoliaToJSON(json: any): ShopifyOrderAlgolia {
    return ShopifyOrderAlgoliaToJSONTyped(json, false);
}

export function ShopifyOrderAlgoliaToJSONTyped(value?: ShopifyOrderAlgolia | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'objectID': value['objectID'],
        'order_id': value['order_id'],
        'order_gid': value['order_gid'],
        'order_url': value['order_url'],
    };
}

