<template>
  <div>
    <div class="favorite--overlay" @click="onClose"></div>
    <div class="favorite-wrapper flex flex-direction-column z-22">
      <div class="favorite--header">
        <div class="flex items-center justify-between mx-15 mt-15">
          <Icon
            data-test-id="board_unfavorite_back_icon"
            icon-key="chevron-left"
            hover-type="OPACITY"
            :on-click="toggleDrawer"
          />
          <Icon icon-key="close" hover-type="OPACITY" data-test-id="board_unfavorite_close_icon" :on-click="onClose" />
        </div>
      </div>
      <div class="favorite--body-container">
        <heading4 class="mb-10">Remove from Favorites</heading4>
        Are you sure you want to remove this product from your favorites?
        {{ boardCount ? `It will also be removed from ${boardCount} board${boardCount === 1 ? '' : 's'}.` : '' }}
      </div>
      <div class="favorite--footer">
        <bulletin-button data-test-id="board_unfavorite_confirm" full-width variant="primary" @click.prevent="onRemove">
          <span>Confirm</span>
        </bulletin-button>
        <span data-test-id="board_unfavorite_cancel" class="mt-15 body-copy-1 underline pointer" @click="toggleDrawer">
          Cancel
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    boardCount: {
      type: Number,
      default: 0
    },
    toggleDrawer: {
      type: Function
    },
    onClose: {
      type: Function
    },
    onRemove: {
      type: Function
    }
  }
}
</script>