<template>
  <div>
    <portal-target name="board-drawer" />
    <portal-target name="brand-board-drawer" />
    <div v-if="$route.path.split('/')[1] === 'brand'">
      <router-view :key="$route.path" />
    </div>
    <div v-else class="content-wrapper" :style="{ 'padding-top': contentPaddingTop }">
      <div class="side-wide-banner-wrapper" :style="{ top: bannerTop }">
        <site-wide-banner />
      </div>
      <the-navigation-bar />
      <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
import TheNavigationBar from '@/components/navigation/TheNavigationBar'
import { mapGetters } from 'vuex'

const PRIMARY_HEADER_HEIGHT = 72

export default {
  components: {
    TheNavigationBar
  },
  data() {
    return {
      scrollPosition: window.scrollY
    }
  },
  computed: {
    ...mapGetters({
      siteWideBannerHeight: 'navigation/getSiteWideBannerHeight',
      isSiteWideBannerVisible: 'navigation/getSiteWideBannerVisible'
    }),
    bannerTop() {
      return `-${this.scrollPosition}px`
    },
    contentPaddingTop() {
      if (!this.isSiteWideBannerVisible) {
        return `${PRIMARY_HEADER_HEIGHT}px`
      }

      let topWithScrollCorrection = this.siteWideBannerHeight - this.scrollPosition
      if (topWithScrollCorrection < 0) {
        topWithScrollCorrection = 0
      }
      return `${topWithScrollCorrection + PRIMARY_HEADER_HEIGHT}px`
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
.content-wrapper {
  .side-wide-banner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
</style>
