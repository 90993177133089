/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchCriteriaResponse } from './SearchCriteriaResponse';
import {
    SearchCriteriaResponseFromJSON,
    SearchCriteriaResponseFromJSONTyped,
    SearchCriteriaResponseToJSON,
    SearchCriteriaResponseToJSONTyped,
} from './SearchCriteriaResponse';

/**
 * 
 * @export
 * @interface CollectionResponse
 */
export interface CollectionResponse {
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    internal_title?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    collection_type?: CollectionResponseCollectionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionResponse
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionResponse
     */
    featured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    featured_category_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollectionResponse
     */
    include_all_conditions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollectionResponse
     */
    search_criteria_string?: string;
    /**
     * 
     * @type {Array<SearchCriteriaResponse>}
     * @memberof CollectionResponse
     */
    search_criteria_list?: Array<SearchCriteriaResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionResponse
     */
    product_ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionResponse
     */
    removed_product_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CollectionResponse
     */
    total?: number;
}


/**
 * @export
 */
export const CollectionResponseCollectionTypeEnum = {
    Manual: 'MANUAL',
    Automated: 'AUTOMATED',
    Parent: 'PARENT'
} as const;
export type CollectionResponseCollectionTypeEnum = typeof CollectionResponseCollectionTypeEnum[keyof typeof CollectionResponseCollectionTypeEnum];


/**
 * Check if a given object implements the CollectionResponse interface.
 */
export function instanceOfCollectionResponse(value: object): value is CollectionResponse {
    return true;
}

export function CollectionResponseFromJSON(json: any): CollectionResponse {
    return CollectionResponseFromJSONTyped(json, false);
}

export function CollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'internal_title': json['internal_title'] == null ? undefined : json['internal_title'],
        'description': json['description'] == null ? undefined : json['description'],
        'image_url': json['image_url'] == null ? undefined : json['image_url'],
        'collection_type': json['collection_type'] == null ? undefined : json['collection_type'],
        'active': json['active'] == null ? undefined : json['active'],
        'featured': json['featured'] == null ? undefined : json['featured'],
        'featured_category_id': json['featured_category_id'] == null ? undefined : json['featured_category_id'],
        'include_all_conditions': json['include_all_conditions'] == null ? undefined : json['include_all_conditions'],
        'search_criteria_string': json['search_criteria_string'] == null ? undefined : json['search_criteria_string'],
        'search_criteria_list': json['search_criteria_list'] == null ? undefined : ((json['search_criteria_list'] as Array<any>).map(SearchCriteriaResponseFromJSON)),
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
        'removed_product_ids': json['removed_product_ids'] == null ? undefined : json['removed_product_ids'],
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function CollectionResponseToJSON(json: any): CollectionResponse {
    return CollectionResponseToJSONTyped(json, false);
}

export function CollectionResponseToJSONTyped(value?: CollectionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
        'internal_title': value['internal_title'],
        'description': value['description'],
        'image_url': value['image_url'],
        'collection_type': value['collection_type'],
        'active': value['active'],
        'featured': value['featured'],
        'featured_category_id': value['featured_category_id'],
        'include_all_conditions': value['include_all_conditions'],
        'search_criteria_string': value['search_criteria_string'],
        'search_criteria_list': value['search_criteria_list'] == null ? undefined : ((value['search_criteria_list'] as Array<any>).map(SearchCriteriaResponseToJSON)),
        'product_ids': value['product_ids'],
        'removed_product_ids': value['removed_product_ids'],
        'total': value['total'],
    };
}

