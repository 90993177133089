/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendBrandWelcomeEmailRequest
 */
export interface ResendBrandWelcomeEmailRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ResendBrandWelcomeEmailRequest
     */
    override?: boolean;
}

/**
 * Check if a given object implements the ResendBrandWelcomeEmailRequest interface.
 */
export function instanceOfResendBrandWelcomeEmailRequest(value: object): value is ResendBrandWelcomeEmailRequest {
    return true;
}

export function ResendBrandWelcomeEmailRequestFromJSON(json: any): ResendBrandWelcomeEmailRequest {
    return ResendBrandWelcomeEmailRequestFromJSONTyped(json, false);
}

export function ResendBrandWelcomeEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendBrandWelcomeEmailRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'override': json['override'] == null ? undefined : json['override'],
    };
}

export function ResendBrandWelcomeEmailRequestToJSON(json: any): ResendBrandWelcomeEmailRequest {
    return ResendBrandWelcomeEmailRequestToJSONTyped(json, false);
}

export function ResendBrandWelcomeEmailRequestToJSONTyped(value?: ResendBrandWelcomeEmailRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'override': value['override'],
    };
}

