/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplaceCreditCardRequest
 */
export interface ReplaceCreditCardRequest {
    /**
     * 
     * @type {string}
     * @memberof ReplaceCreditCardRequest
     */
    stripe_card_token: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceCreditCardRequest
     */
    old_card_token: string;
}

/**
 * Check if a given object implements the ReplaceCreditCardRequest interface.
 */
export function instanceOfReplaceCreditCardRequest(value: object): value is ReplaceCreditCardRequest {
    if (!('stripe_card_token' in value) || value['stripe_card_token'] === undefined) return false;
    if (!('old_card_token' in value) || value['old_card_token'] === undefined) return false;
    return true;
}

export function ReplaceCreditCardRequestFromJSON(json: any): ReplaceCreditCardRequest {
    return ReplaceCreditCardRequestFromJSONTyped(json, false);
}

export function ReplaceCreditCardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplaceCreditCardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'stripe_card_token': json['stripe_card_token'],
        'old_card_token': json['old_card_token'],
    };
}

export function ReplaceCreditCardRequestToJSON(json: any): ReplaceCreditCardRequest {
    return ReplaceCreditCardRequestToJSONTyped(json, false);
}

export function ReplaceCreditCardRequestToJSONTyped(value?: ReplaceCreditCardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'stripe_card_token': value['stripe_card_token'],
        'old_card_token': value['old_card_token'],
    };
}

