/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandPublishedPromoCodeResponse } from './BrandPublishedPromoCodeResponse';
import {
    BrandPublishedPromoCodeResponseFromJSON,
    BrandPublishedPromoCodeResponseFromJSONTyped,
    BrandPublishedPromoCodeResponseToJSON,
    BrandPublishedPromoCodeResponseToJSONTyped,
} from './BrandPublishedPromoCodeResponse';
import type { BrandTradeShowResponse } from './BrandTradeShowResponse';
import {
    BrandTradeShowResponseFromJSON,
    BrandTradeShowResponseFromJSONTyped,
    BrandTradeShowResponseToJSON,
    BrandTradeShowResponseToJSONTyped,
} from './BrandTradeShowResponse';
import type { GetBrandBoardResponse } from './GetBrandBoardResponse';
import {
    GetBrandBoardResponseFromJSON,
    GetBrandBoardResponseFromJSONTyped,
    GetBrandBoardResponseToJSON,
    GetBrandBoardResponseToJSONTyped,
} from './GetBrandBoardResponse';
import type { BrandLocationResponse } from './BrandLocationResponse';
import {
    BrandLocationResponseFromJSON,
    BrandLocationResponseFromJSONTyped,
    BrandLocationResponseToJSON,
    BrandLocationResponseToJSONTyped,
} from './BrandLocationResponse';
import type { DropdownItem } from './DropdownItem';
import {
    DropdownItemFromJSON,
    DropdownItemFromJSONTyped,
    DropdownItemToJSON,
    DropdownItemToJSONTyped,
} from './DropdownItem';

/**
 * 
 * @export
 * @interface PublicBrandResponse
 */
export interface PublicBrandResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    brand_description?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    status?: PublicBrandResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    brand_bio_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    instagram_url?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    instagram_followers?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    based_in?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicBrandResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicBrandResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicBrandResponse
     */
    minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicBrandResponse
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof PublicBrandResponse
     */
    values?: Array<DropdownItem>;
    /**
     * 
     * @type {Array<BrandLocationResponse>}
     * @memberof PublicBrandResponse
     */
    brand_locations?: Array<BrandLocationResponse>;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof PublicBrandResponse
     */
    special_services?: Array<DropdownItem>;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof PublicBrandResponse
     */
    deals_and_promotions?: Array<DropdownItem>;
    /**
     * 
     * @type {Array<BrandPublishedPromoCodeResponse>}
     * @memberof PublicBrandResponse
     */
    published_promo_codes?: Array<BrandPublishedPromoCodeResponse>;
    /**
     * 
     * @type {{ [key: string]: BrandTradeShowResponse; }}
     * @memberof PublicBrandResponse
     */
    trade_shows?: { [key: string]: BrandTradeShowResponse; };
    /**
     * 
     * @type {string}
     * @memberof PublicBrandResponse
     */
    source?: PublicBrandResponseSourceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBrandResponse
     */
    ships_from_usa_or_canada?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBrandResponse
     */
    bulletin_pick?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBrandResponse
     */
    buy_on_bulletin?: boolean;
    /**
     * 
     * @type {Array<GetBrandBoardResponse>}
     * @memberof PublicBrandResponse
     */
    boards?: Array<GetBrandBoardResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBrandResponse
     */
    onboard_complete?: boolean;
}


/**
 * @export
 */
export const PublicBrandResponseStatusEnum = {
    Pending: 'PENDING',
    NeedsReview: 'NEEDS_REVIEW',
    Approved: 'APPROVED',
    Denied: 'DENIED',
    Registered: 'REGISTERED'
} as const;
export type PublicBrandResponseStatusEnum = typeof PublicBrandResponseStatusEnum[keyof typeof PublicBrandResponseStatusEnum];

/**
 * @export
 */
export const PublicBrandResponseSourceEnum = {
    Application: 'APPLICATION',
    Exhibitor: 'EXHIBITOR',
    Outreach: 'OUTREACH'
} as const;
export type PublicBrandResponseSourceEnum = typeof PublicBrandResponseSourceEnum[keyof typeof PublicBrandResponseSourceEnum];


/**
 * Check if a given object implements the PublicBrandResponse interface.
 */
export function instanceOfPublicBrandResponse(value: object): value is PublicBrandResponse {
    return true;
}

export function PublicBrandResponseFromJSON(json: any): PublicBrandResponse {
    return PublicBrandResponseFromJSONTyped(json, false);
}

export function PublicBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'brand_description': json['brand_description'] == null ? undefined : json['brand_description'],
        'status': json['status'] == null ? undefined : json['status'],
        'logo_url': json['logo_url'] == null ? undefined : json['logo_url'],
        'brand_bio_image_url': json['brand_bio_image_url'] == null ? undefined : json['brand_bio_image_url'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'instagram_url': json['instagram_url'] == null ? undefined : json['instagram_url'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'instagram_followers': json['instagram_followers'] == null ? undefined : json['instagram_followers'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'values': json['values'] == null ? undefined : ((json['values'] as Array<any>).map(DropdownItemFromJSON)),
        'brand_locations': json['brand_locations'] == null ? undefined : ((json['brand_locations'] as Array<any>).map(BrandLocationResponseFromJSON)),
        'special_services': json['special_services'] == null ? undefined : ((json['special_services'] as Array<any>).map(DropdownItemFromJSON)),
        'deals_and_promotions': json['deals_and_promotions'] == null ? undefined : ((json['deals_and_promotions'] as Array<any>).map(DropdownItemFromJSON)),
        'published_promo_codes': json['published_promo_codes'] == null ? undefined : ((json['published_promo_codes'] as Array<any>).map(BrandPublishedPromoCodeResponseFromJSON)),
        'trade_shows': json['trade_shows'] == null ? undefined : (mapValues(json['trade_shows'], BrandTradeShowResponseFromJSON)),
        'source': json['source'] == null ? undefined : json['source'],
        'ships_from_usa_or_canada': json['ships_from_usa_or_canada'] == null ? undefined : json['ships_from_usa_or_canada'],
        'bulletin_pick': json['bulletin_pick'] == null ? undefined : json['bulletin_pick'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
        'boards': json['boards'] == null ? undefined : ((json['boards'] as Array<any>).map(GetBrandBoardResponseFromJSON)),
        'onboard_complete': json['onboard_complete'] == null ? undefined : json['onboard_complete'],
    };
}

export function PublicBrandResponseToJSON(json: any): PublicBrandResponse {
    return PublicBrandResponseToJSONTyped(json, false);
}

export function PublicBrandResponseToJSONTyped(value?: PublicBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'brand_name': value['brand_name'],
        'brand_description': value['brand_description'],
        'status': value['status'],
        'logo_url': value['logo_url'],
        'brand_bio_image_url': value['brand_bio_image_url'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'instagram_url': value['instagram_url'],
        'vanity_url_name': value['vanity_url_name'],
        'instagram_followers': value['instagram_followers'],
        'based_in': value['based_in'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'values': value['values'] == null ? undefined : ((value['values'] as Array<any>).map(DropdownItemToJSON)),
        'brand_locations': value['brand_locations'] == null ? undefined : ((value['brand_locations'] as Array<any>).map(BrandLocationResponseToJSON)),
        'special_services': value['special_services'] == null ? undefined : ((value['special_services'] as Array<any>).map(DropdownItemToJSON)),
        'deals_and_promotions': value['deals_and_promotions'] == null ? undefined : ((value['deals_and_promotions'] as Array<any>).map(DropdownItemToJSON)),
        'published_promo_codes': value['published_promo_codes'] == null ? undefined : ((value['published_promo_codes'] as Array<any>).map(BrandPublishedPromoCodeResponseToJSON)),
        'trade_shows': value['trade_shows'] == null ? undefined : (mapValues(value['trade_shows'], BrandTradeShowResponseToJSON)),
        'source': value['source'],
        'ships_from_usa_or_canada': value['ships_from_usa_or_canada'],
        'bulletin_pick': value['bulletin_pick'],
        'buy_on_bulletin': value['buy_on_bulletin'],
        'boards': value['boards'] == null ? undefined : ((value['boards'] as Array<any>).map(GetBrandBoardResponseToJSON)),
        'onboard_complete': value['onboard_complete'],
    };
}

