/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteBrandResponse
 */
export interface FavoriteBrandResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof FavoriteBrandResponse
     */
    brand_ids?: Array<string>;
}

/**
 * Check if a given object implements the FavoriteBrandResponse interface.
 */
export function instanceOfFavoriteBrandResponse(value: object): value is FavoriteBrandResponse {
    return true;
}

export function FavoriteBrandResponseFromJSON(json: any): FavoriteBrandResponse {
    return FavoriteBrandResponseFromJSONTyped(json, false);
}

export function FavoriteBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_ids': json['brand_ids'] == null ? undefined : json['brand_ids'],
    };
}

export function FavoriteBrandResponseToJSON(json: any): FavoriteBrandResponse {
    return FavoriteBrandResponseToJSONTyped(json, false);
}

export function FavoriteBrandResponseToJSONTyped(value?: FavoriteBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_ids': value['brand_ids'],
    };
}

