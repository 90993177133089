import ReferalApi from '../../../services/api/referralApi.js'

const ReferalApiService = new ReferalApi()

export default {
  data() {
    return {
      link: null,
      copied: false
    }
  },

  methods: {
    generateLink,
    copy
  },

  beforeMount() {
    this.generateLink()
  }
}

function copy() {
  this.$refs.refCodeInput.select()
  this.$refs.refCodeInput.setSelectionRange(0, this.$refs.refCodeInput.value.length, 'backward')
  document.execCommand('copy')
  this.copied = true
}

function generateLink() {
  ReferalApiService.generateReferLink().then(({ data }) => {
    this.link = data.link
  })
}
