<template>
  <div v-if="displayFavoriteToggle">
    <Icon
      v-if="isProduct"
      :icon-key="isFavorite ? 'heart-active' : 'heart'"
      hover-type="ICON"
      class="heart-icon-container"
      :on-click="handleFavoriteClick"
    />
    <bulletin-button
      v-else
      :class="[buttonClass, { 'white favorited': isFavorite }]"
      class="button button-black hover-green small-btn"
      variant="primary"
      @click="handleFavoriteClick"
    >
      <span>{{ `Follow${isFavorite ? 'ing' : ''}` }}</span>
    </bulletin-button>
    <portal v-if="displayFavoriteDrawer" to="board-drawer">
      <favorite-drawer
        :id="id"
        :on-close="handleCloseFavoriteDrawer"
        :on-remove="handleRemoveProductFromFavorites"
        :is-unfavorite="isFav"
        :product-image="productImage"
        :tracking-info="trackingInfo"
        :on-save-to-board="onSaveToBoard"
      />
    </portal>
  </div>
</template>

<script>
import FavoriteApi from '../../../services/api/favoritesApi'
import FavoriteDrawer from '../favoriteDrawer/FavoriteDrawer'
import { mapGetters } from 'vuex'

const FavoriteApiService = new FavoriteApi()
export default {
  components: { FavoriteDrawer },
  props: [
    'isFav',
    'id',
    'isProduct',
    'buttonClass',
    'trackingInfo',
    'productImage',
    'onSaveToBoard',
    'shouldRefetchOnFavorite'
  ],
  data() {
    return {
      isFavorite: false,
      displayFavoriteDrawer: false
    }
  },
  computed: {
    testIdPrefix() {
      return this.isProduct ? 'product' : 'brand'
    },
    displayFavoriteToggle() {
      return this.user?.role === 'RETAILER'
    },
    toggleClickEnabled() {
      return this.user && this.user.role === 'RETAILER'
    },
    ...mapGetters({
      user: 'userState'
    })
  },
  mounted() {
    this.isFavorite = this.isFav
  },
  methods: {
    handleFavoriteClick,
    handleCloseFavoriteDrawer,
    handleRemoveProductFromFavorites
  }
}

async function handleFavoriteClick(event) {
  if (event) {
    event.stopPropagation()
  }
  if (this.toggleClickEnabled) {
    if (this.isProduct) {
      this.displayFavoriteDrawer = true
      if (!this.isFavorite) {
        this.isFavorite = true
        await FavoriteApiService.favoriteProductToggle(this.id)
      } else {
        this.isFavorite = !this.isFavorite
      }
    } else {
      this.isFavorite = !this.isFavorite
      await FavoriteApiService.favoriteBrandToggle(this.id)
      if (this.isFavorite !== this.isFav && this.shouldRefetchOnFavorite) {
        this.$eventBus.$emit('refetch-data-on-favorite')
      }
    }

    let properties = {
      ...this.trackingInfo,
      'Page name': this.$utils.constants.routeDict[this.$route.name],
      'Page type': this.$utils.constants.routeDict[this.$route.name]
    }
    let action = this.isProduct ? 'Add to favorites' : 'Followed'

    if (this.isProduct && this.$route.name === 'BrandBoardsDetails') {
      properties['Board Creator'] = 'BRAND'
    }

    if (!this.isProduct && this.$route.name === 'favoriteBoards') {
      action = 'Bulletin Board Brand Follow'
      properties['Board Creator'] = 'RETAILER'
    }
    if (this.isFavorite) {
      this.$trackEvent({
        action,
        properties
      })
    }
  } else {
    this.$eventBus.$emit('apply-modal-unregistered', null)
  }
}

async function handleRemoveProductFromFavorites() {
  await FavoriteApiService.favoriteProductToggle(this.id)
  this.isFavorite = false
  this.handleCloseFavoriteDrawer()
}

async function handleCloseFavoriteDrawer() {
  if (this.isFavorite !== this.isFav && this.shouldRefetchOnFavorite) {
    this.$eventBus.$emit('refetch-data-on-favorite')
  }
  this.displayFavoriteDrawer = false
  await this.$utils.getFavoriteProducts.call(this)
}
</script>

<style scoped lang="scss">
.heart-icon-container {
  height: 40px;
  width: 40px;
  z-index: 5;
  border-radius: 50%;
  background-color: white;
}

.small-btn {
  min-width: unset !important;
  padding: 4px 16px !important;
}
</style>
