/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkRetailersWithTradeShowRequest
 */
export interface LinkRetailersWithTradeShowRequest {
    /**
     * Csv file as form data object
     * @type {Blob}
     * @memberof LinkRetailersWithTradeShowRequest
     */
    csvFile: Blob;
}

/**
 * Check if a given object implements the LinkRetailersWithTradeShowRequest interface.
 */
export function instanceOfLinkRetailersWithTradeShowRequest(value: object): value is LinkRetailersWithTradeShowRequest {
    if (!('csvFile' in value) || value['csvFile'] === undefined) return false;
    return true;
}

export function LinkRetailersWithTradeShowRequestFromJSON(json: any): LinkRetailersWithTradeShowRequest {
    return LinkRetailersWithTradeShowRequestFromJSONTyped(json, false);
}

export function LinkRetailersWithTradeShowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkRetailersWithTradeShowRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'csvFile': json['csvFile'],
    };
}

export function LinkRetailersWithTradeShowRequestToJSON(json: any): LinkRetailersWithTradeShowRequest {
    return LinkRetailersWithTradeShowRequestToJSONTyped(json, false);
}

export function LinkRetailersWithTradeShowRequestToJSONTyped(value?: LinkRetailersWithTradeShowRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'csvFile': value['csvFile'],
    };
}

