/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Email verification code
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequest
     */
    code: string;
}

/**
 * Check if a given object implements the VerifyEmailRequest interface.
 */
export function instanceOfVerifyEmailRequest(value: object): value is VerifyEmailRequest {
    if (!('code' in value) || value['code'] === undefined) return false;
    return true;
}

export function VerifyEmailRequestFromJSON(json: any): VerifyEmailRequest {
    return VerifyEmailRequestFromJSONTyped(json, false);
}

export function VerifyEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyEmailRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function VerifyEmailRequestToJSON(json: any): VerifyEmailRequest {
    return VerifyEmailRequestToJSONTyped(json, false);
}

export function VerifyEmailRequestToJSONTyped(value?: VerifyEmailRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': value['code'],
    };
}

