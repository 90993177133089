/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductsPagination
 */
export interface ProductsPagination {
    /**
     * 
     * @type {number}
     * @memberof ProductsPagination
     */
    items_per_page?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsPagination
     */
    total_items?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsPagination
     */
    current_page?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsPagination
     */
    total_pages?: number;
}

/**
 * Check if a given object implements the ProductsPagination interface.
 */
export function instanceOfProductsPagination(value: object): value is ProductsPagination {
    return true;
}

export function ProductsPaginationFromJSON(json: any): ProductsPagination {
    return ProductsPaginationFromJSONTyped(json, false);
}

export function ProductsPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsPagination {
    if (json == null) {
        return json;
    }
    return {
        
        'items_per_page': json['items_per_page'] == null ? undefined : json['items_per_page'],
        'total_items': json['total_items'] == null ? undefined : json['total_items'],
        'current_page': json['current_page'] == null ? undefined : json['current_page'],
        'total_pages': json['total_pages'] == null ? undefined : json['total_pages'],
    };
}

export function ProductsPaginationToJSON(json: any): ProductsPagination {
    return ProductsPaginationToJSONTyped(json, false);
}

export function ProductsPaginationToJSONTyped(value?: ProductsPagination | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items_per_page': value['items_per_page'],
        'total_items': value['total_items'],
        'current_page': value['current_page'],
        'total_pages': value['total_pages'],
    };
}

