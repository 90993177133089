<template>
  <div>
    <div v-if="isNYNOW && showOptionsNYNOW">
      <NavNYNOW />
    </div>
    <div v-else>
      <div
        v-if="hasMultipleCategoryContainer() && !isNYNOW"
        class="category-containers"
        :class="{ mobile: mobile, 'left-side-menu': leftSideMenu }"
      >
        <template v-if="!activeCategoryContainer">
          <h2 v-if="leftSideMenu" class="font-size-32">
            {{ activeCategory.name }}
          </h2>
          <h5 v-else class="uppercase mb-16" :class="{ 'font-size-16': mobile }">
            {{ activeCategory.name }}
          </h5>
        </template>

        <div class="flex justify-between" :class="{ 'flex-direction-column': mobile || leftSideMenu }">
          <div :class="{ 'flex flex-grow-1': !leftSideMenu }">
            <div v-if="showCategoryContainerList()" class="category-container-list">
              <div
                v-for="container in getCategoryContainers()"
                :key="container.id"
                class="category-container-list-item"
                :class="{ active: activeCategoryContainer && container.id === activeCategoryContainer.id }"
                @click="setActiveCategoryContainer(container)"
              >
                <div class="container-name">{{ container.name }}</div>
                <Icon icon-key="chevron-right" hover-type="OPACITY" :size="mobile || leftSideMenu ? 40 : 30" />
              </div>
            </div>
            <div v-if="activeCategoryContainer" class="selected-category-container">
              <template v-if="showActiveCategoryContainerName()">
                <h2 v-if="leftSideMenu" class="font-size-32 mb-16">
                  {{ activeCategoryContainer.name }}
                </h2>
                <h5 v-else class="uppercase mb-16" :class="{ 'font-size-16': mobile }">
                  {{ activeCategoryContainer.name }}
                </h5>
              </template>

              <div :class="{ 'grid-12 grid-column-gap-24 grid-row-gap-8': isFeatured() }">
                <div
                  v-for="item in getActiveCategoryViewComponents()"
                  :key="item.id"
                  class="category-item span-12-sm span-6-md"
                  :class="{
                    'mb-16': mobile || leftSideMenu,
                    'mb-8': !mobile && !leftSideMenu && !isFeatured(),
                    'mx-4': isFeatured()
                  }"
                  @click="
                    handleChildClick({
                      entity_id: item.entity_id,
                      id: item.id,
                      name: item.info.text,
                      parentName: item.info.parent_name || activeCategory.name,
                      type: item.component_type
                    })
                  "
                >
                  {{ item.info.text }}
                </div>
              </div>

              <bulletin-button
                v-if="!isFeatured()"
                class="all-sub-cat-btn mt-20"
                @click="handleCollectionClick(activeCategoryContainer.name, activeCategoryContainer.id)"
              >
                All {{ activeCategoryContainer.name }}
              </bulletin-button>
            </div>
          </div>

          <div v-if="isFeatured()" class="featured-collections-with-images" :class="{ mobile: mobile || leftSideMenu }">
            <div class="grid-12 grid-column-gap-24 grid-row-gap-8">
              <div
                v-for="(item, index) in getActiveCategoryViewComponents(true)"
                :key="index"
                class="collection-image-item"
                :class="{ 'span-4 span-6-sm': !leftSideMenu, 'span-6': leftSideMenu }"
                @click="handleFeaturedCollectionWithImageClick(item)"
              >
                <img
                  v-if="item.info.layout === 'IMAGE'"
                  :src="$utils.getCDNURL({ src: item.info.image_url, w: 300, h: 350, q: 100, fit: 'crop' })"
                  class="mb-15 img-entity overflow-hidden collection-image"
                  :class="[`img-entity-${['none', 'retailer', 'round-sm'][index]}`]"
                />
                {{ item.info.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!isNYNOW" class="category-menu" :class="{ mobile: mobile, 'left-side-menu': leftSideMenu }">
          <div class="grid-12 grid-column-gap-24">
            <div class="span-12" :class="{ 'span-6-md': getCollections() && !leftSideMenu }">
              <template>
                <h2 v-if="leftSideMenu" class="font-size-32 mb-16">
                  {{ activeCategory.name }}
                </h2>
                <h5 v-else class="uppercase mb-16" :class="{ 'font-size-16': mobile }">
                  {{ activeCategory.name }}
                </h5>
              </template>

              <div :class="{ 'grid-12 grid-column-gap-24 category-row-gap': hasMultipleCategoryContainer() }">
                <div
                  v-for="(container, index) in getCategoryContainers()"
                  :key="index"
                  :class="{
                    'grid-12 grid-column-gap-24 category-row-gap': container.container_direction === 'ROW',
                    'span-12-sm span-6-md span-3-md span-2-lg': container.container_direction === 'COLUMN',
                    'left-side-menu-mode': leftSideMenu
                  }"
                >
                  <div
                    v-for="item in container.view_components"
                    :key="item.id"
                    class="category-item"
                    :class="{ 'span-12-sm span-6-md span-4-lg': !leftSideMenu, 'span-6': leftSideMenu }"
                    @click="
                      handleChildClick({
                        entity_id: item.entity_id,
                        id: item.id,
                        name: item.info.text,
                        parentName: item.info.parent_name || activeCategory.name,
                        type: item.component_type
                      })
                    "
                  >
                    <span v-if="container.container_direction === 'ROW'">{{ item.info.text }}</span>
                  </div>
                  <div v-if="container.container_direction === 'COLUMN'" class="span-12-sm span-6-md span-4-lg">
                    <h5 class="uppercase mb-8" :class="{ 'font-size-16': mobile }">
                      {{ container.name }}
                    </h5>
                    <div
                      v-for="item in container.view_components"
                      :key="item.id"
                      class="category-item mb-8"
                      @click="
                        handleChildClick({
                          entity_id: item.entity_id,
                          id: item.id,
                          name: item.info.text,
                          parentName: container.name,
                          type: item.component_type
                        })
                      "
                    >
                      {{ item.info.text }}
                    </div>
                  </div>
                </div>
              </div>
              <bulletin-button class="all-sub-cat-btn" @click="handleParentCategoryClick()">
                All {{ activeCategory.name }}
              </bulletin-button>
            </div>

            <div
              v-if="getCollections()"
              class="span-12-sm"
              :class="{ 'span-6-md': !leftSideMenu, 'span-12': leftSideMenu }"
            >
              <h5 class="uppercase mb-16" :class="{ 'mt-25': leftSideMenu }">Collections</h5>
              <div class="grid-12 grid-column-gap-24 grid-row-gap-8">
                <div
                  v-for="(item, index) in getCollections()"
                  :key="index"
                  class="collection-image-item"
                  :class="{ 'span-4 span-6-sm': !leftSideMenu, 'span-6': leftSideMenu }"
                  @click="handleCollectionClick(item.info.text, item.id, activeCategory.name)"
                >
                  <img
                    v-if="item.info.layout === 'IMAGE'"
                    :src="$utils.getCDNURL({ src: item.info.image_url, w: 300, h: 350, q: 100, fit: 'crop' })"
                    class="mb-15 img-entity overflow-hidden collection-image"
                    :class="[`img-entity-${['none', 'retailer', 'round-sm'][index]}`]"
                  />
                  {{ item.info.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { navigationHelper } from '../mixin/navigationHelper'
import NavNYNOW from '../../views/TheNYNOWDirectoryPage/navNYNOW.vue'

export default {
  components: {
    NavNYNOW
  },
  mixins: [navigationHelper],
  props: {
    categories: {
      type: [Array, Object],
      required: true
    },
    mobile: {
      type: Boolean,
      default: false
    },
    leftSideMenu: {
      type: Boolean,
      default: false
    },
    selectCategoryContainer: {
      type: Function,
      default: () => null
    },
    closeLeftSideMenu: {
      type: Function,
      default: () => null
    },
    showOptionsNYNOW: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      activeCategoryContainer: null
    }
  },
  computed: {
    ...mapGetters({
      activeCategoryId: 'navigation/activeCategory'
    }),
    activeCategory() {
      if (
        this.categories &&
        this.categories.children_containers &&
        this.categories.children_containers[1].id !== 'nynow'
      ) {
        // eslint-disable-next-line vue/no-mutating-props, vue/no-side-effects-in-computed-properties
        this.categories.children_containers.splice(1, 0, {
          name: 'NY NOW',
          id: 'nynow',
          active: true,
          children_containers: [],
          container_direction: 'COLUMN',
          container_type: 'NAVIGATION',
          view_components: []
        })
      }
      return this.categories?.children_containers?.find((item) => item.id.toString() === this.activeCategoryId)
    },
    isNYNOW() {
      return this.activeCategory?.id === 'nynow'
    }
  },
  watch: {
    activeCategory: function () {
      if (this.hasMultipleCategoryContainer() && this.getCategoryContainers() && !this.mobile && !this.leftSideMenu) {
        this.activeCategoryContainer = this.getCategoryContainers()[0]
      }
    }
  },
  mounted() {
    this.$eventBus.$on('clear-selected-category-container', this.clearSelectedCategoryContainer)
    if (this.hasMultipleCategoryContainer() && this.getCategoryContainers() && !this.mobile && !this.leftSideMenu) {
      this.activeCategoryContainer = this.getCategoryContainers()[0]
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('clear-selected-category-container', this.clearSelectedCategoryContainer)
  },
  methods: {
    getCategoryContainers,
    getCollections,
    handleParentCategoryClick,
    handleChildClick,
    handleCollectionClick,
    hasMultipleCategoryContainer,
    setActiveCategoryContainer,
    clearSelectedCategoryContainer,
    showCategoryContainerList,
    isFeatured,
    getFeaturedCategoryContainerCollections,
    getActiveCategoryViewComponents,
    handleFeaturedCollectionWithImageClick,
    showActiveCategoryContainerName
  }
}

function handleFeaturedCollectionWithImageClick(item) {
  const link = item.info?.link
  const collectionName = item.info?.text

  if (link) {
    const allowedHosts = ['bulletin.co']
    let isInnerRoute = false
    try {
      const url = new URL(link)
      isInnerRoute = allowedHosts.includes(url.host)
    } catch (e) {
      console.error('Invalid URL:', e)
    }
    if (isInnerRoute) {
      this.$router.push(link.split('https://bulletin.co')[1])
    } else {
      window.location.replace(link)
    }

    this.$store.dispatch('navigation/setDropdownType', 'closed')
    this.trackNavigationEvent(collectionName, 'collection')
    this.$store.dispatch('navigation/hideSideBar')
    if (this.leftSideMenu) {
      this.closeLeftSideMenu()
    }
  } else {
    this.handleCollectionClick(item.info.text, item.id, this.activeCategory.name)
  }
}

function getCategoryContainers() {
  if (this.hasMultipleCategoryContainer()) {
    if (this.isFeatured()) {
      return this.activeCategory?.children_containers
    }

    return this.activeCategory?.children_containers.filter((container) =>
      container.view_components.find((item) => item.component_type !== 'COLLECTION')
    )
  }

  return [this.activeCategory?.children_containers[0]]
}

function getCollections() {
  const collectionContainer = this.activeCategory?.children_containers[1]
  const isCollection =
    collectionContainer.container_direction === 'ROW' &&
    collectionContainer.view_components.every((item) => item.info.layout === 'IMAGE')

  return isCollection ? this.activeCategory?.children_containers[1].view_components : null
}

function setActiveCategoryContainer(container) {
  this.activeCategoryContainer = container
  this.selectCategoryContainer(container)
}

// Kids & Baby and Featured
function hasMultipleCategoryContainer() {
  return this.activeCategory?.children_containers.length > 2 || this.isFeatured()
}

function isFeatured() {
  return this.activeCategory?.name === 'Collections'
}

function handleParentCategoryClick() {
  this.navigateToParentCategory(this.activeCategory)
  if (this.leftSideMenu) {
    this.closeLeftSideMenu()
  }
}

function handleChildClick(data) {
  const { type, parentName, name, id, entity_id } = data

  if (type === 'COLLECTION') {
    this.$router.push({
      name: 'CollectionWithCategory',
      params: {
        categoryName: parentName,
        collectionName: name,
        collectionId: id
      },
      query: { page: 1 }
    })
  } else if (type === 'CATEGORY') {
    this.$router.push({
      path: `/${this.$utils.urlSafeString(parentName)}/${this.$utils.urlSafeString(name)}/${entity_id}/products`
    })
  }

  this.trackNavigationEvent(name, type)
  this.$store.dispatch('navigation/setDropdownType', 'closed')
  this.$store.dispatch('navigation/hideSideBar')
  if (this.leftSideMenu) {
    this.closeLeftSideMenu()
  }
}

function handleCollectionClick(collectionName, collectionId, categoryName) {
  const params = {
    collectionName,
    collectionId
  }

  if (categoryName) {
    params.categoryName = categoryName
  }

  this.$router.push({
    name: categoryName ? 'CollectionWithCategory' : 'ParentCollection',
    params,
    query: { page: 1 }
  })

  this.$store.dispatch('navigation/setDropdownType', 'closed')

  this.trackNavigationEvent(collectionName, 'collection')

  this.$store.dispatch('navigation/hideSideBar')

  if (this.leftSideMenu) {
    this.closeLeftSideMenu()
  }
}

function clearSelectedCategoryContainer() {
  this.activeCategoryContainer = null
  this.selectCategoryContainer(null)
}

function showCategoryContainerList() {
  if (!this.mobile && !this.leftSideMenu) {
    return true
  }

  return !this.activeCategoryContainer
}

function getActiveCategoryViewComponents(getImageCollection) {
  if (this.isFeatured()) {
    return this.getFeaturedCategoryContainerCollections(getImageCollection)?.view_components
  }

  return this.activeCategoryContainer.view_components
}

function getFeaturedCategoryContainerCollections(getImageCollection) {
  if (!this.activeCategoryContainer?.children_containers?.length) {
    return []
  }
  if (getImageCollection) {
    return this.activeCategoryContainer?.children_containers[1]
  } else {
    return this.activeCategoryContainer?.children_containers[0]
  }
}

function showActiveCategoryContainerName() {
  if (this.isFeatured()) {
    return this.leftSideMenu || this.mobile
  }

  return true
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';

.category-containers {
  background: $color-white;
  border-top: 1px solid $color-grey-3;
  margin-top: -1px;
  border-bottom: 1px solid $color-black;

  &.mobile,
  &.left-side-menu {
    border-bottom: none;
    border-top: none;
    flex-direction: column;
    .category-container-list {
      border-right: none;
      width: 100%;
      padding: 0;
      .category-container-list-item {
        height: 50px;
        padding: 0;
      }
    }

    .selected-category-container {
      padding: 0;
    }
  }

  .category-container-list {
    border-right: 1px solid $color-grey-3;
    min-width: 250px;
    padding-block: 24px;

    .category-container-list-item {
      padding-block: 5px;
      padding-left: 40px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: NHaasGroteskDSPro-55Rg;
      font-size: 16px;

      &.active {
        background: $color-green;
      }

      &:hover {
        cursor: pointer;
      }

      .container-name {
        max-width: calc(100% - 30px);
      }
    }
  }

  .selected-category-container {
    padding: 40px;
    width: 100%;
  }
}

.category-menu {
  background: $color-white;

  &:not(.mobile):not(.left-side-menu) {
    border-bottom: 1px solid $color-black;
    padding: 40px;
    margin-top: -1px;
    border-top: 1px solid $color-grey-3;
  }

  .category-row-gap {
    grid-row-gap: 8px;

    &.left-side-menu-mode {
      grid-row-gap: 16px;
    }

    @media only screen and (max-width: 992px) {
      grid-row-gap: 16px;
    }
  }

  .all-sub-cat-btn {
    margin-top: 24px;
    @include respond-to('medium') {
      margin-bottom: 32px;
    }
  }
}

.collection-image-item {
  @include transition(opacity, 100ms, ease-in-out);
  &:hover {
    opacity: 0.3;
    cursor: pointer;
  }
}

.category-item {
  @include transition(color, 100ms, ease-in-out);

  &:hover {
    cursor: pointer;
    color: mix($color-green, $color-black, 88%) !important;
  }
}

.collection-image {
  width: 100%;
  display: block;
  max-height: 355px;
  object-fit: cover;
}

.featured-collections-with-images {
  padding: 40px;
  width: 50%;

  &.mobile {
    padding: 0;
    width: 100%;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 992px) {
  .span-12-sm {
    grid-column-end: span 12;
  }
}

@media only screen and (max-width: 992px) {
  .span-6-sm {
    grid-column-end: span 6 !important;
  }
}

@media only screen and (min-width: 992px) {
  .span-6-md {
    grid-column-end: span 6 !important;
  }

  .span-3-md {
    grid-column-end: span 3 !important;
  }
}

@media only screen and (min-width: 1200px) {
  .span-4-lg {
    grid-column-end: span 4 !important;
  }

  .span-2-lg {
    grid-column-end: span 2 !important;
  }
}
</style>
