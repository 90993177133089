/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBrandDetailsResponse
 */
export interface GetBrandDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailsResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailsResponse
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailsResponse
     */
    phone_number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandDetailsResponse
     */
    ships_from_usa_or_canada?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailsResponse
     */
    years_in_business?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailsResponse
     */
    section?: string;
}

/**
 * Check if a given object implements the GetBrandDetailsResponse interface.
 */
export function instanceOfGetBrandDetailsResponse(value: object): value is GetBrandDetailsResponse {
    return true;
}

export function GetBrandDetailsResponseFromJSON(json: any): GetBrandDetailsResponse {
    return GetBrandDetailsResponseFromJSONTyped(json, false);
}

export function GetBrandDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'] == null ? undefined : json['country'],
        'instagram': json['instagram'] == null ? undefined : json['instagram'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'ships_from_usa_or_canada': json['ships_from_usa_or_canada'] == null ? undefined : json['ships_from_usa_or_canada'],
        'years_in_business': json['years_in_business'] == null ? undefined : json['years_in_business'],
        'section': json['section'] == null ? undefined : json['section'],
    };
}

export function GetBrandDetailsResponseToJSON(json: any): GetBrandDetailsResponse {
    return GetBrandDetailsResponseToJSONTyped(json, false);
}

export function GetBrandDetailsResponseToJSONTyped(value?: GetBrandDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'country': value['country'],
        'instagram': value['instagram'],
        'phone_number': value['phone_number'],
        'ships_from_usa_or_canada': value['ships_from_usa_or_canada'],
        'years_in_business': value['years_in_business'],
        'section': value['section'],
    };
}

