/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackingSlipItemRequest
 */
export interface PackingSlipItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItemRequest
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipItemRequest
     */
    quantity?: number;
}

/**
 * Check if a given object implements the PackingSlipItemRequest interface.
 */
export function instanceOfPackingSlipItemRequest(value: object): value is PackingSlipItemRequest {
    return true;
}

export function PackingSlipItemRequestFromJSON(json: any): PackingSlipItemRequest {
    return PackingSlipItemRequestFromJSONTyped(json, false);
}

export function PackingSlipItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackingSlipItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function PackingSlipItemRequestToJSON(json: any): PackingSlipItemRequest {
    return PackingSlipItemRequestToJSONTyped(json, false);
}

export function PackingSlipItemRequestToJSONTyped(value?: PackingSlipItemRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'quantity': value['quantity'],
    };
}

