/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Decline order object
 * @export
 * @interface DeclineSuborderRequest
 */
export interface DeclineSuborderRequest {
    /**
     * 
     * @type {string}
     * @memberof DeclineSuborderRequest
     */
    decline_order_reason: DeclineSuborderRequestDeclineOrderReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DeclineSuborderRequest
     */
    additional_information?: string;
}


/**
 * @export
 */
export const DeclineSuborderRequestDeclineOrderReasonEnum = {
    OutOfStock: 'OUT_OF_STOCK',
    LocationRestrictions: 'LOCATION_RESTRICTIONS',
    RetailerBrandFit: 'RETAILER_BRAND_FIT',
    ProductionDelays: 'PRODUCTION_DELAYS',
    RetailerCancelledOrder: 'RETAILER_CANCELLED_ORDER',
    InternationalRestriction: 'INTERNATIONAL_RESTRICTION',
    BulletinDidntVerifyRetailer: 'BULLETIN_DIDNT_VERIFY_RETAILER',
    Other: 'OTHER'
} as const;
export type DeclineSuborderRequestDeclineOrderReasonEnum = typeof DeclineSuborderRequestDeclineOrderReasonEnum[keyof typeof DeclineSuborderRequestDeclineOrderReasonEnum];


/**
 * Check if a given object implements the DeclineSuborderRequest interface.
 */
export function instanceOfDeclineSuborderRequest(value: object): value is DeclineSuborderRequest {
    if (!('decline_order_reason' in value) || value['decline_order_reason'] === undefined) return false;
    return true;
}

export function DeclineSuborderRequestFromJSON(json: any): DeclineSuborderRequest {
    return DeclineSuborderRequestFromJSONTyped(json, false);
}

export function DeclineSuborderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeclineSuborderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'decline_order_reason': json['decline_order_reason'],
        'additional_information': json['additional_information'] == null ? undefined : json['additional_information'],
    };
}

export function DeclineSuborderRequestToJSON(json: any): DeclineSuborderRequest {
    return DeclineSuborderRequestToJSONTyped(json, false);
}

export function DeclineSuborderRequestToJSONTyped(value?: DeclineSuborderRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'decline_order_reason': value['decline_order_reason'],
        'additional_information': value['additional_information'],
    };
}

