/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SuborderItemResponse } from './SuborderItemResponse';
import {
    SuborderItemResponseFromJSON,
    SuborderItemResponseFromJSONTyped,
    SuborderItemResponseToJSON,
    SuborderItemResponseToJSONTyped,
} from './SuborderItemResponse';
import type { ShopifySuborderResponse } from './ShopifySuborderResponse';
import {
    ShopifySuborderResponseFromJSON,
    ShopifySuborderResponseFromJSONTyped,
    ShopifySuborderResponseToJSON,
    ShopifySuborderResponseToJSONTyped,
} from './ShopifySuborderResponse';
import type { SuborderRetailerResponse } from './SuborderRetailerResponse';
import {
    SuborderRetailerResponseFromJSON,
    SuborderRetailerResponseFromJSONTyped,
    SuborderRetailerResponseToJSON,
    SuborderRetailerResponseToJSONTyped,
} from './SuborderRetailerResponse';
import type { SuborderBrandResponse } from './SuborderBrandResponse';
import {
    SuborderBrandResponseFromJSON,
    SuborderBrandResponseFromJSONTyped,
    SuborderBrandResponseToJSON,
    SuborderBrandResponseToJSONTyped,
} from './SuborderBrandResponse';
import type { PackingSlipResponse } from './PackingSlipResponse';
import {
    PackingSlipResponseFromJSON,
    PackingSlipResponseFromJSONTyped,
    PackingSlipResponseToJSON,
    PackingSlipResponseToJSONTyped,
} from './PackingSlipResponse';
import type { ShopifyOrderAlgolia } from './ShopifyOrderAlgolia';
import {
    ShopifyOrderAlgoliaFromJSON,
    ShopifyOrderAlgoliaFromJSONTyped,
    ShopifyOrderAlgoliaToJSON,
    ShopifyOrderAlgoliaToJSONTyped,
} from './ShopifyOrderAlgolia';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface SuborderResponse
 */
export interface SuborderResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    order_number?: number;
    /**
     * 
     * @type {SuborderBrandResponse}
     * @memberof SuborderResponse
     */
    brand?: SuborderBrandResponse;
    /**
     * 
     * @type {SuborderRetailerResponse}
     * @memberof SuborderResponse
     */
    retailer?: SuborderRetailerResponse;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    status?: SuborderResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderResponse
     */
    adjusted_status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    shipping_info?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof SuborderResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderResponse
     */
    is_custom_assortment?: boolean;
    /**
     * 
     * @type {Array<SuborderItemResponse>}
     * @memberof SuborderResponse
     */
    items?: Array<SuborderItemResponse>;
    /**
     * 
     * @type {Date}
     * @memberof SuborderResponse
     */
    payout_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SuborderResponse
     */
    original_charge_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SuborderResponse
     */
    scheduled_charge_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SuborderResponse
     */
    updated_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    payout?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    commission?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    commission_percent?: number;
    /**
     * 
     * @type {Array<PackingSlipResponse>}
     * @memberof SuborderResponse
     */
    packing_slips?: Array<PackingSlipResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderResponse
     */
    manually_charged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderResponse
     */
    charge_date_adjusted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    decline_order_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    additional_information?: string;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    total_suborder_price?: number;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    promo_code?: string;
    /**
     * 
     * @type {number}
     * @memberof SuborderResponse
     */
    promo_code_amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderResponse
     */
    admin_promo_code?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    promo_code_type?: SuborderResponsePromoCodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SuborderResponse
     */
    invoiceUrl?: string;
    /**
     * 
     * @type {ShopifyOrderAlgolia}
     * @memberof SuborderResponse
     */
    shopify?: ShopifyOrderAlgolia;
    /**
     * 
     * @type {ShopifySuborderResponse}
     * @memberof SuborderResponse
     */
    shopify_suborder?: ShopifySuborderResponse;
}


/**
 * @export
 */
export const SuborderResponseStatusEnum = {
    Hold: 'HOLD',
    Void: 'VOID',
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    Expired: 'EXPIRED',
    PartiallyFulfilled: 'PARTIALLY_FULFILLED',
    FulfillmentInProgress: 'FULFILLMENT_IN_PROGRESS',
    Shipped: 'SHIPPED',
    Paid: 'PAID',
    Failed: 'FAILED',
    NotCollectable: 'NOT_COLLECTABLE',
    Cancelled: 'CANCELLED'
} as const;
export type SuborderResponseStatusEnum = typeof SuborderResponseStatusEnum[keyof typeof SuborderResponseStatusEnum];

/**
 * @export
 */
export const SuborderResponsePromoCodeTypeEnum = {
    Percent: 'PERCENT',
    Amount: 'AMOUNT',
    FreeShipping: 'FREE_SHIPPING'
} as const;
export type SuborderResponsePromoCodeTypeEnum = typeof SuborderResponsePromoCodeTypeEnum[keyof typeof SuborderResponsePromoCodeTypeEnum];


/**
 * Check if a given object implements the SuborderResponse interface.
 */
export function instanceOfSuborderResponse(value: object): value is SuborderResponse {
    return true;
}

export function SuborderResponseFromJSON(json: any): SuborderResponse {
    return SuborderResponseFromJSONTyped(json, false);
}

export function SuborderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'order_number': json['order_number'] == null ? undefined : json['order_number'],
        'brand': json['brand'] == null ? undefined : SuborderBrandResponseFromJSON(json['brand']),
        'retailer': json['retailer'] == null ? undefined : SuborderRetailerResponseFromJSON(json['retailer']),
        'status': json['status'] == null ? undefined : json['status'],
        'adjusted_status': json['adjusted_status'] == null ? undefined : json['adjusted_status'],
        'shipping_info': json['shipping_info'] == null ? undefined : json['shipping_info'],
        'address': json['address'] == null ? undefined : AddressResponseFromJSON(json['address']),
        'is_custom_assortment': json['is_custom_assortment'] == null ? undefined : json['is_custom_assortment'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(SuborderItemResponseFromJSON)),
        'payout_date': json['payout_date'] == null ? undefined : (new Date(json['payout_date'])),
        'original_charge_date': json['original_charge_date'] == null ? undefined : (new Date(json['original_charge_date'])),
        'scheduled_charge_date': json['scheduled_charge_date'] == null ? undefined : (new Date(json['scheduled_charge_date'])),
        'updated_at': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'payout': json['payout'] == null ? undefined : json['payout'],
        'commission': json['commission'] == null ? undefined : json['commission'],
        'commission_percent': json['commission_percent'] == null ? undefined : json['commission_percent'],
        'packing_slips': json['packing_slips'] == null ? undefined : ((json['packing_slips'] as Array<any>).map(PackingSlipResponseFromJSON)),
        'manually_charged': json['manually_charged'] == null ? undefined : json['manually_charged'],
        'charge_date_adjusted': json['charge_date_adjusted'] == null ? undefined : json['charge_date_adjusted'],
        'decline_order_reason': json['decline_order_reason'] == null ? undefined : json['decline_order_reason'],
        'additional_information': json['additional_information'] == null ? undefined : json['additional_information'],
        'total_suborder_price': json['total_suborder_price'] == null ? undefined : json['total_suborder_price'],
        'promo_code': json['promo_code'] == null ? undefined : json['promo_code'],
        'promo_code_amount': json['promo_code_amount'] == null ? undefined : json['promo_code_amount'],
        'admin_promo_code': json['admin_promo_code'] == null ? undefined : json['admin_promo_code'],
        'promo_code_type': json['promo_code_type'] == null ? undefined : json['promo_code_type'],
        'invoiceUrl': json['invoiceUrl'] == null ? undefined : json['invoiceUrl'],
        'shopify': json['shopify'] == null ? undefined : ShopifyOrderAlgoliaFromJSON(json['shopify']),
        'shopify_suborder': json['shopify_suborder'] == null ? undefined : ShopifySuborderResponseFromJSON(json['shopify_suborder']),
    };
}

export function SuborderResponseToJSON(json: any): SuborderResponse {
    return SuborderResponseToJSONTyped(json, false);
}

export function SuborderResponseToJSONTyped(value?: SuborderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'order_number': value['order_number'],
        'brand': SuborderBrandResponseToJSON(value['brand']),
        'retailer': SuborderRetailerResponseToJSON(value['retailer']),
        'status': value['status'],
        'adjusted_status': value['adjusted_status'],
        'shipping_info': value['shipping_info'],
        'address': AddressResponseToJSON(value['address']),
        'is_custom_assortment': value['is_custom_assortment'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(SuborderItemResponseToJSON)),
        'payout_date': value['payout_date'] == null ? undefined : ((value['payout_date']).toISOString().substring(0,10)),
        'original_charge_date': value['original_charge_date'] == null ? undefined : ((value['original_charge_date']).toISOString().substring(0,10)),
        'scheduled_charge_date': value['scheduled_charge_date'] == null ? undefined : ((value['scheduled_charge_date']).toISOString().substring(0,10)),
        'updated_at': value['updated_at'] == null ? undefined : ((value['updated_at']).toISOString().substring(0,10)),
        'payout': value['payout'],
        'commission': value['commission'],
        'commission_percent': value['commission_percent'],
        'packing_slips': value['packing_slips'] == null ? undefined : ((value['packing_slips'] as Array<any>).map(PackingSlipResponseToJSON)),
        'manually_charged': value['manually_charged'],
        'charge_date_adjusted': value['charge_date_adjusted'],
        'decline_order_reason': value['decline_order_reason'],
        'additional_information': value['additional_information'],
        'total_suborder_price': value['total_suborder_price'],
        'promo_code': value['promo_code'],
        'promo_code_amount': value['promo_code_amount'],
        'admin_promo_code': value['admin_promo_code'],
        'promo_code_type': value['promo_code_type'],
        'invoiceUrl': value['invoiceUrl'],
        'shopify': ShopifyOrderAlgoliaToJSON(value['shopify']),
        'shopify_suborder': ShopifySuborderResponseToJSON(value['shopify_suborder']),
    };
}

