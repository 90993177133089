<template>
  <div>
    <div v-for="brandValue in getFilteredItems(brandValues, 'label')" :key="brandValue.value" class="mb-5">
      <checkbox-new v-model="brandValue.selected" :label="brandValue.label" @click="handleClick(brandValue)" />
    </div>
  </div>
</template>

<script>
import TradeShowApi from '@/services/api/tradeShowApi'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import { FilterKeys } from '@/services/utils'

const TradeShowApiService = new TradeShowApi()

export default {
  mixins: [paramsHelper, filterHelper],
  data() {
    return {
      brandValues: [],
      selected: []
    }
  },
  watch: {
    '$route.query.sections': function (query) {
      this.parseSelection(query)
      this.emitFilterSelection({
        filterKey: FilterKeys.SECTIONS,
        items: this.brandValues
      })
    }
  },
  beforeMount() {
    this.getBrandValues()
  },
  methods: {
    async getBrandValues() {
      const { data: event } = await TradeShowApiService.getActiveTradeShow()
      const { a2z_event_id: eventID } = event[0]
      let { data } = await TradeShowApiService.getSectionsForActiveTradeShow(eventID)
      data = data
        .filter((item) => item !== '') // remove blank
        .map((item) => {
          return {
            active: true,
            label: item,
            value: item
          }
        })
      this.brandValues = data
      this.parseSelection(this.$route.query.sections)
      this.emitFilterSelection({
        filterKey: FilterKeys.SECTIONS,
        items: this.brandValues
      })
    },
    handleClick(data) {
      data.selected = !data.selected
      const selectedBrandValues = this.brandValues.filter((obj) => obj.selected).map((obj) => obj.value)
      this.trackFilterUsedEvent('sections')
      this.updateFilterURLParams(FilterKeys.SECTIONS, selectedBrandValues)
      this.emitFilterSelection({
        filterKey: FilterKeys.SECTIONS.toString(),
        items: this.brandValues
      })
    },
    parseSelection(brandValuesQuery) {
      this.brandValues = this.brandValues.map((brandValue) => {
        const isSelected = brandValuesQuery === undefined ? false : brandValuesQuery?.includes(brandValue.value)
        return {
          ...brandValue,
          ...{ selected: isSelected }
        }
      })
    }
  }
}
</script>
