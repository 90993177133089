/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Bulk update draft products request
 * @export
 * @interface BulkUpdateDraftProductsRequest
 */
export interface BulkUpdateDraftProductsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateDraftProductsRequest
     */
    product_ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateDraftProductsRequest
     */
    category_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateDraftProductsRequest
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateDraftProductsRequest
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateDraftProductsRequest
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateDraftProductsRequest
     */
    pos_price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateDraftProductsRequest
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateDraftProductsRequest
     */
    approve_products?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateDraftProductsRequest
     */
    weight?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateDraftProductsRequest
     */
    track_inventory?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateDraftProductsRequest
     */
    min_order_quantity?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateDraftProductsRequest
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the BulkUpdateDraftProductsRequest interface.
 */
export function instanceOfBulkUpdateDraftProductsRequest(value: object): value is BulkUpdateDraftProductsRequest {
    return true;
}

export function BulkUpdateDraftProductsRequestFromJSON(json: any): BulkUpdateDraftProductsRequest {
    return BulkUpdateDraftProductsRequestFromJSONTyped(json, false);
}

export function BulkUpdateDraftProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateDraftProductsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
        'category_ids': json['category_ids'] == null ? undefined : json['category_ids'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'active': json['active'] == null ? undefined : json['active'],
        'approve_products': json['approve_products'] == null ? undefined : json['approve_products'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'track_inventory': json['track_inventory'] == null ? undefined : json['track_inventory'],
        'min_order_quantity': json['min_order_quantity'] == null ? undefined : json['min_order_quantity'],
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function BulkUpdateDraftProductsRequestToJSON(json: any): BulkUpdateDraftProductsRequest {
    return BulkUpdateDraftProductsRequestToJSONTyped(json, false);
}

export function BulkUpdateDraftProductsRequestToJSONTyped(value?: BulkUpdateDraftProductsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_ids': value['product_ids'],
        'category_ids': value['category_ids'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'wholesale_price': value['wholesale_price'],
        'pos_price': value['pos_price'],
        'active': value['active'],
        'approve_products': value['approve_products'],
        'weight': value['weight'],
        'track_inventory': value['track_inventory'],
        'min_order_quantity': value['min_order_quantity'],
        'tags': value['tags'],
    };
}

