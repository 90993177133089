/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RetailersDirectoryResponse } from './RetailersDirectoryResponse';
import {
    RetailersDirectoryResponseFromJSON,
    RetailersDirectoryResponseFromJSONTyped,
    RetailersDirectoryResponseToJSON,
    RetailersDirectoryResponseToJSONTyped,
} from './RetailersDirectoryResponse';
import type { RetailersDirectoryPagination } from './RetailersDirectoryPagination';
import {
    RetailersDirectoryPaginationFromJSON,
    RetailersDirectoryPaginationFromJSONTyped,
    RetailersDirectoryPaginationToJSON,
    RetailersDirectoryPaginationToJSONTyped,
} from './RetailersDirectoryPagination';

/**
 * 
 * @export
 * @interface PaginatedRetailersDirectoryResponse
 */
export interface PaginatedRetailersDirectoryResponse {
    /**
     * 
     * @type {Array<RetailersDirectoryResponse>}
     * @memberof PaginatedRetailersDirectoryResponse
     */
    hits?: Array<RetailersDirectoryResponse>;
    /**
     * 
     * @type {RetailersDirectoryPagination}
     * @memberof PaginatedRetailersDirectoryResponse
     */
    pagination?: RetailersDirectoryPagination;
}

/**
 * Check if a given object implements the PaginatedRetailersDirectoryResponse interface.
 */
export function instanceOfPaginatedRetailersDirectoryResponse(value: object): value is PaginatedRetailersDirectoryResponse {
    return true;
}

export function PaginatedRetailersDirectoryResponseFromJSON(json: any): PaginatedRetailersDirectoryResponse {
    return PaginatedRetailersDirectoryResponseFromJSONTyped(json, false);
}

export function PaginatedRetailersDirectoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedRetailersDirectoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(RetailersDirectoryResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : RetailersDirectoryPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedRetailersDirectoryResponseToJSON(json: any): PaginatedRetailersDirectoryResponse {
    return PaginatedRetailersDirectoryResponseToJSONTyped(json, false);
}

export function PaginatedRetailersDirectoryResponseToJSONTyped(value?: PaginatedRetailersDirectoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(RetailersDirectoryResponseToJSON)),
        'pagination': RetailersDirectoryPaginationToJSON(value['pagination']),
    };
}

