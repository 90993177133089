export const scrollBehavior = (to, from) => {
  if (isPathUnChanged(to, from, 'brand-bio') && isKeywordChanged(to, from)) {
    return { selector: '#brandBioSearchInputField', offset: { y: 200 } }
  }

  if (isPathUnChanged(to, from, 'favorites-recommendations') && isKeywordChanged(to, from)) {
    return { selector: '#recommendedBrandsSearchInputField', offset: { y: 200 } }
  }

  return from.meta.keepScrollPosition ? false : { y: 0 }
}

const isPathUnChanged = (to, from, path) => {
  return to.name === path && from.name === path
}

const isKeywordChanged = (to, from) => {
  return from.query?.keyword !== to.query?.keyword && (from.query?.keyword || to.query?.keyword)
}
