<script>
import colorPicker from '@caohenghu/vue-colorpicker'

export default {
  components: { colorPicker },
  props: {
    color: {
      type: String,
      default: ''
    },
    changeColor: {
      type: Function,
      default: () => undefined
    }
  },
  data() {
    return {
      showColorPicker: false,
      colorPickerConfig: {
        suckerCanvas: null,
        suckerArea: [],
        isSucking: true
      },
      selectedColor: this.color
    }
  },
  watch: {
    selectedColor(value) {
      this.changeColor(value)
    }
  },
  methods: {
    backgroundColorFieldFocusChange,
    changeBackgroundColor
  }
}

function changeBackgroundColor(color) {
  this.selectedColor = color.hex
}
function backgroundColorFieldFocusChange(show) {
  this.showColorPicker = show
}
</script>

<template>
  <div class="color-picker-container">
    <text-field
      v-model="selectedColor"
      :validate="'required'"
      name="Background color"
      label="Background color"
      no-spacing
      width="full"
      :focus="() => backgroundColorFieldFocusChange(true)"
    />

    <Icon
      v-if="showColorPicker"
      icon-key="close"
      hover-type="OPACITY"
      :size="30"
      class="close-container"
      :on-click="() => backgroundColorFieldFocusChange(false)"
    />
    <div
      v-else
      class="color-circle"
      :style="{ background: selectedColor }"
      @click="backgroundColorFieldFocusChange(true)"
    />
    <color-picker
      v-if="showColorPicker"
      class="color-picker"
      theme="light"
      :color="selectedColor"
      :colors-default="['#e1ffa1', '#fcffeb', '#ffb6e6', '#fe8e6b', '#8befdd', '#12aaec', '#bd6464', '#D50000']"
      :sucker-hide="true"
      :sucker-canvas="colorPickerConfig.suckerCanvas"
      :sucker-area="colorPickerConfig.suckerArea"
      @changeColor="changeBackgroundColor"
    />
  </div>
</template>

<style scoped lang="scss">
.color-picker-container {
  .color-picker {
    position: absolute;
    top: 75px;
    width: 220px !important;
  }

  .close-container {
    position: absolute;
    right: 0;
    top: 35px;
  }

  .color-circle {
    position: absolute;
    right: 0;
    top: 35px;
    width: 25px;
    height: 25px;
    border-radius: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
