<template>
  <v-date-picker
    class="filter-date"
    :value="new Date(selectedDate)"
    :tint-color="'transparent'"
    :theme-styles="themeStyles"
    :popover="{ visibility: 'click' }"
    is-inline
    :min-date="minDate"
    :select-attribute="selectAttribute"
    @input="selectDate($event)"
  />
</template>

<script>
import Vue from 'vue'
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import { FilterKeys } from '../../../../services/utils'

Vue.use(VCalendar, {
  datePickerShowDayPopover: false
})

export default {
  mixins: [paramsHelper, filterHelper],
  data() {
    return {
      dirty: false,
      selectedDate: null,
      selectedValueInDays: null,
      selectAttribute: {
        highlight: {
          backgroundColor: '#e1ffa1',
          borderColor: '#e1ffa1'
        },
        contentStyle: {
          color: 'black',
          backgroundColor: '#e1ffa1'
        }
      },
      themeStyles: {
        dayCellNotInMonth: {
          opacity: 0.5,
          color: '#000000'
        }
      },
      minDate: this.$moment(new Date()).add(1, 'days').toDate()
    }
  },
  watch: {
    '$route.query.max_lead_time': function (leadTimeTo) {
      const days = !leadTimeTo || Array.isArray(leadTimeTo) ? null : leadTimeTo + 1

      if (!days) {
        this.selectedDate = null
      }

      this.parseSelectedValue(days)
      this.transformValuesEmitFilterSelection()
    }
  },
  beforeMount() {
    this.parseSelectedValue(this.$route.query.max_lead_time)
    this.transformValuesEmitFilterSelection()
  },

  methods: {
    selectDate,
    resetDatepicker,
    transformValuesEmitFilterSelection,
    parseSelectedValue
  }
}

function resetDatepicker() {
  this.dirty = false
  this.selectedDate = null
}

function selectDate(date) {
  this.selectedDate = date

  const start = this.$moment(new Date(), 'YYYY-MM-DD').startOf()
  const end = this.$moment(this.selectedDate, 'YYYY-MM-DD').endOf()
  const days = Math.ceil(Math.abs(this.$moment.duration(end.diff(start)).asDays()))

  this.trackFilterUsedEvent('date')

  this.selectedValueInDays = days
  this.updateFilterURLParams(FilterKeys.SHIP_BY, days)
  this.transformValuesEmitFilterSelection()
}

function parseSelectedValue(selectedValue) {
  if (this.selectedDate) {
    return
  }

  if (selectedValue) {
    this.selectedDate = this.$moment(this.$moment(new Date()).add(selectedValue, 'd')).toDate()
  } else {
    this.selectedDate = null
    this.selectedValueInDays = null
  }

  this.$forceUpdate()
}

function transformValuesEmitFilterSelection() {
  this.emitFilterSelection({
    filterKey: FilterKeys.SHIP_BY,
    items: this.selectedDate
      ? [
          {
            value: this.selectedValueInDays,
            label: `Ship by ${this.$moment(this.selectedDate).format('ll')}`,
            selected: true
          }
        ]
      : []
  })
}
</script>

<style lang="scss">
@import '@/assets/sass/base/vars';
@import '@/assets/sass/base/mixins';

.filter-date {
  &.c-pane-container {
    background-color: transparent !important;
    border: none !important;
    width: 100%;
  }
  .c-title {
    font-size: 14px !important;
    color: $color-black;
    font-family: NHaasGroteskDSPro-65Md;
    font-weight: normal;
    letter-spacing: calc(16px * 0.16) !important;
  }

  .c-arrow-layout {
    border: 1px solid $color-black;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    .c-arrow {
      height: 14px;
    }
  }

  .c-day-content {
    font-family: NHaasGroteskDSPro-55Rg;
    font-weight: normal !important;
  }

  .c-weekday {
    font-family: NHaasGroteskDSPro-65Md;
    color: $color-black;
    font-size: 12px;
  }

  .c-week {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .c-arrow-layout {
    border: none !important;
  }

  .c-arrow {
    height: 28px !important;
    width: 28px !important;
    padding: 5px !important;
    border: 1px solid $color-black;
    border-radius: 50%;
  }
}

// responsive fixes
.filter-date.c-pane-container {
  position: static;
}
.c-pane {
  position: static;
}
.c-weeks-rows {
  position: static;
}
.c-weekday {
  position: static;
}
.c-week {
  position: static;
  > .c-day-popover {
    display: block;
  }
}
</style>
