import restApi from '../../http.js'
import session from '@/services/session'

const defaultPageLimit = 36

export default class NavigationApi {
  getNavigation() {
    return restApi({
      method: 'GET',
      url: '/views/navigation'
    })
  }

  getProductsForComponent(id) {
    return restApi({
      method: 'GET',
      url: `/views/components/${id}/products`
    })
  }
  getProductsForContainer(id) {
    return restApi({
      method: 'GET',
      url: `/views/containers/${id}/products`
    })
  }

  getProductsFromParentCategory(id, page = 0, limit = defaultPageLimit) {
    return restApi({
      method: 'GET',
      url: `/views/containers/${id}/products`,
      params: {
        limit: limit,
        page: page
      }
    })
  }
  getProductsFromChildCategory(id, page = 0, limit = defaultPageLimit) {
    return restApi({
      method: 'GET',
      url: `/views/components/${id}/products`,
      params: {
        limit: limit,
        page: page
      }
    })
  }

  updateContainer(data) {
    return restApi({
      method: 'PATCH',
      url: `/views/containers/${data.id}`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  createContainer(data) {
    return restApi({
      method: 'POST',
      url: `/views/containers/${data.parent_container_id}/children-containers`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  deleteContainer(containerId) {
    return restApi({
      method: 'DELETE',
      url: `/views/containers/${containerId}/children-containers`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
