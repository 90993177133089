/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollectionProductBrandResponse
 */
export interface CollectionProductBrandResponse {
    /**
     * 
     * @type {string}
     * @memberof CollectionProductBrandResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionProductBrandResponse
     */
    name?: string;
}

/**
 * Check if a given object implements the CollectionProductBrandResponse interface.
 */
export function instanceOfCollectionProductBrandResponse(value: object): value is CollectionProductBrandResponse {
    return true;
}

export function CollectionProductBrandResponseFromJSON(json: any): CollectionProductBrandResponse {
    return CollectionProductBrandResponseFromJSONTyped(json, false);
}

export function CollectionProductBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionProductBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function CollectionProductBrandResponseToJSON(json: any): CollectionProductBrandResponse {
    return CollectionProductBrandResponseToJSONTyped(json, false);
}

export function CollectionProductBrandResponseToJSONTyped(value?: CollectionProductBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

