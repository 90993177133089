/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomAssortmentsResponse } from './CustomAssortmentsResponse';
import {
    CustomAssortmentsResponseFromJSON,
    CustomAssortmentsResponseFromJSONTyped,
    CustomAssortmentsResponseToJSON,
    CustomAssortmentsResponseToJSONTyped,
} from './CustomAssortmentsResponse';
import type { CustomAssortmentsPagination } from './CustomAssortmentsPagination';
import {
    CustomAssortmentsPaginationFromJSON,
    CustomAssortmentsPaginationFromJSONTyped,
    CustomAssortmentsPaginationToJSON,
    CustomAssortmentsPaginationToJSONTyped,
} from './CustomAssortmentsPagination';

/**
 * 
 * @export
 * @interface PaginatedCustomAssortmentsResponse
 */
export interface PaginatedCustomAssortmentsResponse {
    /**
     * 
     * @type {Array<CustomAssortmentsResponse>}
     * @memberof PaginatedCustomAssortmentsResponse
     */
    hits?: Array<CustomAssortmentsResponse>;
    /**
     * 
     * @type {CustomAssortmentsPagination}
     * @memberof PaginatedCustomAssortmentsResponse
     */
    pagination?: CustomAssortmentsPagination;
}

/**
 * Check if a given object implements the PaginatedCustomAssortmentsResponse interface.
 */
export function instanceOfPaginatedCustomAssortmentsResponse(value: object): value is PaginatedCustomAssortmentsResponse {
    return true;
}

export function PaginatedCustomAssortmentsResponseFromJSON(json: any): PaginatedCustomAssortmentsResponse {
    return PaginatedCustomAssortmentsResponseFromJSONTyped(json, false);
}

export function PaginatedCustomAssortmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedCustomAssortmentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(CustomAssortmentsResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : CustomAssortmentsPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedCustomAssortmentsResponseToJSON(json: any): PaginatedCustomAssortmentsResponse {
    return PaginatedCustomAssortmentsResponseToJSONTyped(json, false);
}

export function PaginatedCustomAssortmentsResponseToJSONTyped(value?: PaginatedCustomAssortmentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(CustomAssortmentsResponseToJSON)),
        'pagination': CustomAssortmentsPaginationToJSON(value['pagination']),
    };
}

