/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateSuborderResponse } from './CreateSuborderResponse';
import {
    CreateSuborderResponseFromJSON,
    CreateSuborderResponseFromJSONTyped,
    CreateSuborderResponseToJSON,
    CreateSuborderResponseToJSONTyped,
} from './CreateSuborderResponse';

/**
 * 
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {Array<CreateSuborderResponse>}
     * @memberof CreateOrderResponse
     */
    suborders?: Array<CreateSuborderResponse>;
}

/**
 * Check if a given object implements the CreateOrderResponse interface.
 */
export function instanceOfCreateOrderResponse(value: object): value is CreateOrderResponse {
    return true;
}

export function CreateOrderResponseFromJSON(json: any): CreateOrderResponse {
    return CreateOrderResponseFromJSONTyped(json, false);
}

export function CreateOrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'suborders': json['suborders'] == null ? undefined : ((json['suborders'] as Array<any>).map(CreateSuborderResponseFromJSON)),
    };
}

export function CreateOrderResponseToJSON(json: any): CreateOrderResponse {
    return CreateOrderResponseToJSONTyped(json, false);
}

export function CreateOrderResponseToJSONTyped(value?: CreateOrderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'suborders': value['suborders'] == null ? undefined : ((value['suborders'] as Array<any>).map(CreateSuborderResponseToJSON)),
    };
}

