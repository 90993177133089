/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BrandPublishPromoCodeRequest,
  CreatePromoCodeRequest,
  GetBrandsWithPromoCodesResponse,
  GetEnumOptionsResponsePromoCodeStatus,
  PromoCodeResponse,
  PromoCodesWithPaginationResponse,
  UpdatePromoCodeRequest,
} from '../models/index';
import {
    BrandPublishPromoCodeRequestFromJSON,
    BrandPublishPromoCodeRequestToJSON,
    CreatePromoCodeRequestFromJSON,
    CreatePromoCodeRequestToJSON,
    GetBrandsWithPromoCodesResponseFromJSON,
    GetBrandsWithPromoCodesResponseToJSON,
    GetEnumOptionsResponsePromoCodeStatusFromJSON,
    GetEnumOptionsResponsePromoCodeStatusToJSON,
    PromoCodeResponseFromJSON,
    PromoCodeResponseToJSON,
    PromoCodesWithPaginationResponseFromJSON,
    PromoCodesWithPaginationResponseToJSON,
    UpdatePromoCodeRequestFromJSON,
    UpdatePromoCodeRequestToJSON,
} from '../models/index';

export interface CreatePromoCodeOperationRequest {
    createPromoCodeRequest: CreatePromoCodeRequest;
}

export interface DeletePromoCodeRequest {
    code: string;
}

export interface GetPromoCodeRequest {
    id: string;
}

export interface GetPromoCodesRequest {
    keyword: string;
    page: number;
    size: number;
    status: string;
    brandId: string;
}

export interface PublishPromoCodeRequest {
    id: string;
    brandPublishPromoCodeRequest: BrandPublishPromoCodeRequest;
}

export interface UpdatePromoCodeOperationRequest {
    id: string;
    updatePromoCodeRequest: UpdatePromoCodeRequest;
}

/**
 * 
 */
export class PromoCodeControllerApi extends runtime.BaseAPI {

    /**
     * Create Promo code
     */
    async createPromoCodeRaw(requestParameters: CreatePromoCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PromoCodeResponse>> {
        if (requestParameters['createPromoCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'createPromoCodeRequest',
                'Required parameter "createPromoCodeRequest" was null or undefined when calling createPromoCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePromoCodeRequestToJSON(requestParameters['createPromoCodeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PromoCodeResponseFromJSON(jsonValue));
    }

    /**
     * Create Promo code
     */
    async createPromoCode(requestParameters: CreatePromoCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PromoCodeResponse> {
        const response = await this.createPromoCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Promo code
     */
    async deletePromoCodeRaw(requestParameters: DeletePromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling deletePromoCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Promo code
     */
    async deletePromoCode(requestParameters: DeletePromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePromoCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Get all brands which created promo codes
     */
    async getBrandsWithPromoCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBrandsWithPromoCodesResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes/brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBrandsWithPromoCodesResponseFromJSON));
    }

    /**
     * Get all brands which created promo codes
     */
    async getBrandsWithPromoCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBrandsWithPromoCodesResponse>> {
        const response = await this.getBrandsWithPromoCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find Promo Code by Id
     */
    async getPromoCodeRaw(requestParameters: GetPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PromoCodeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPromoCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PromoCodeResponseFromJSON(jsonValue));
    }

    /**
     * Find Promo Code by Id
     */
    async getPromoCode(requestParameters: GetPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PromoCodeResponse> {
        const response = await this.getPromoCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all promo code statuses
     */
    async getPromoCodeStatusesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEnumOptionsResponsePromoCodeStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnumOptionsResponsePromoCodeStatusFromJSON(jsonValue));
    }

    /**
     * Get all promo code statuses
     */
    async getPromoCodeStatuses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEnumOptionsResponsePromoCodeStatus> {
        const response = await this.getPromoCodeStatusesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Search all promo codes by keyword with pagination
     */
    async getPromoCodesRaw(requestParameters: GetPromoCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PromoCodesWithPaginationResponse>> {
        if (requestParameters['keyword'] == null) {
            throw new runtime.RequiredError(
                'keyword',
                'Required parameter "keyword" was null or undefined when calling getPromoCodes().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPromoCodes().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPromoCodes().'
            );
        }

        if (requestParameters['status'] == null) {
            throw new runtime.RequiredError(
                'status',
                'Required parameter "status" was null or undefined when calling getPromoCodes().'
            );
        }

        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getPromoCodes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['keyword'] != null) {
            queryParameters['keyword'] = requestParameters['keyword'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brand_id'] = requestParameters['brandId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PromoCodesWithPaginationResponseFromJSON(jsonValue));
    }

    /**
     * Search all promo codes by keyword with pagination
     */
    async getPromoCodes(requestParameters: GetPromoCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PromoCodesWithPaginationResponse> {
        const response = await this.getPromoCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish promo code
     */
    async publishPromoCodeRaw(requestParameters: PublishPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling publishPromoCode().'
            );
        }

        if (requestParameters['brandPublishPromoCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'brandPublishPromoCodeRequest',
                'Required parameter "brandPublishPromoCodeRequest" was null or undefined when calling publishPromoCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BrandPublishPromoCodeRequestToJSON(requestParameters['brandPublishPromoCodeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish promo code
     */
    async publishPromoCode(requestParameters: PublishPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publishPromoCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Update promo code
     */
    async updatePromoCodeRaw(requestParameters: UpdatePromoCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePromoCode().'
            );
        }

        if (requestParameters['updatePromoCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'updatePromoCodeRequest',
                'Required parameter "updatePromoCodeRequest" was null or undefined when calling updatePromoCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/promo-codes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromoCodeRequestToJSON(requestParameters['updatePromoCodeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update promo code
     */
    async updatePromoCode(requestParameters: UpdatePromoCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePromoCodeRaw(requestParameters, initOverrides);
    }

}
