<template>
  <i
    :class="[getIconClass, { 'opacity-04 no-events': disabled }]"
    :style="{ width: `${size}px`, height: `${size}px` }"
    @click="handleClick"
  />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    iconKey: {
      type: String
    },
    size: {
      type: Number,
      default: 40
    },
    hoverType: {
      type: String,
      // BACKGROUND | ICON | OPACITY | NONE
      default: 'NONE'
    },
    disabled: {
      type: Boolean
    },
    onClick: {
      type: Function,
      required: false
    },
    stopPropagation: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    getIconClass() {
      let hoverClass = `icon icon-${this.iconKey}`
      if (this.hoverType !== 'NONE') {
        hoverClass += ' pointer'
      }
      if (this.hoverType === 'ICON') {
        hoverClass += ` icon-${this.iconKey}-hover`
      }
      if (this.hoverType === 'OPACITY') {
        hoverClass += ` opacity-hover`
      }
      if (this.hoverType === 'BACKGROUND') {
        hoverClass += ` background-hover border-radius`
      }
      return hoverClass
    }
  },
  methods: {
    handleClick
  }
}

function handleClick(event) {
  if (this.stopPropagation) {
    event.stopPropagation()
  }
  if (this.onClick) {
    this.onClick()
  }
}
</script>
