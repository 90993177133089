import restApi from '../../http.js'
import session from '../session'

export default class ReferralApi {
  generateReferLink() {
    return restApi({
      method: 'GET',
      url: '/referrals/link',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  create(request) {
    return restApi({
      method: 'POST',
      url: '/referrals',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  importReferrals(request) {
    return restApi({
      method: 'POST',
      url: '/referrals/import-from-csv',
      data: request,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  changeReferalPaidStatus(id) {
    return restApi({
      method: 'PATCH',
      url: `/referrals/${id}/toggle-complete`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  downloadCSVTemplate() {
    return restApi({
      method: 'POST',
      url: '/referrals/download-csv',
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
