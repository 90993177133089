/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CollectionProductResponse } from './CollectionProductResponse';
import {
    CollectionProductResponseFromJSON,
    CollectionProductResponseFromJSONTyped,
    CollectionProductResponseToJSON,
    CollectionProductResponseToJSONTyped,
} from './CollectionProductResponse';

/**
 * 
 * @export
 * @interface GetCollectionProductsToExploreResponse
 */
export interface GetCollectionProductsToExploreResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCollectionProductsToExploreResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCollectionProductsToExploreResponse
     */
    title?: string;
    /**
     * 
     * @type {Array<CollectionProductResponse>}
     * @memberof GetCollectionProductsToExploreResponse
     */
    products?: Array<CollectionProductResponse>;
}

/**
 * Check if a given object implements the GetCollectionProductsToExploreResponse interface.
 */
export function instanceOfGetCollectionProductsToExploreResponse(value: object): value is GetCollectionProductsToExploreResponse {
    return true;
}

export function GetCollectionProductsToExploreResponseFromJSON(json: any): GetCollectionProductsToExploreResponse {
    return GetCollectionProductsToExploreResponseFromJSONTyped(json, false);
}

export function GetCollectionProductsToExploreResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCollectionProductsToExploreResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(CollectionProductResponseFromJSON)),
    };
}

export function GetCollectionProductsToExploreResponseToJSON(json: any): GetCollectionProductsToExploreResponse {
    return GetCollectionProductsToExploreResponseToJSONTyped(json, false);
}

export function GetCollectionProductsToExploreResponseToJSONTyped(value?: GetCollectionProductsToExploreResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(CollectionProductResponseToJSON)),
    };
}

