/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminCollectionResponse } from './AdminCollectionResponse';
import {
    AdminCollectionResponseFromJSON,
    AdminCollectionResponseFromJSONTyped,
    AdminCollectionResponseToJSON,
    AdminCollectionResponseToJSONTyped,
} from './AdminCollectionResponse';
import type { TradeShowFeaturesResponse } from './TradeShowFeaturesResponse';
import {
    TradeShowFeaturesResponseFromJSON,
    TradeShowFeaturesResponseFromJSONTyped,
    TradeShowFeaturesResponseToJSON,
    TradeShowFeaturesResponseToJSONTyped,
} from './TradeShowFeaturesResponse';

/**
 * 
 * @export
 * @interface TradeShowResponse
 */
export interface TradeShowResponse {
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    id?: string;
    /**
     * 
     * @type {TradeShowFeaturesResponse}
     * @memberof TradeShowResponse
     */
    features?: TradeShowFeaturesResponse;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    a2z_event_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowResponse
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    registration_url?: string;
    /**
     * 
     * @type {Array<AdminCollectionResponse>}
     * @memberof TradeShowResponse
     */
    admin_collections?: Array<AdminCollectionResponse>;
    /**
     * 
     * @type {string}
     * @memberof TradeShowResponse
     */
    booth_map_url?: string;
}

/**
 * Check if a given object implements the TradeShowResponse interface.
 */
export function instanceOfTradeShowResponse(value: object): value is TradeShowResponse {
    return true;
}

export function TradeShowResponseFromJSON(json: any): TradeShowResponse {
    return TradeShowResponseFromJSONTyped(json, false);
}

export function TradeShowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'features': json['features'] == null ? undefined : TradeShowFeaturesResponseFromJSON(json['features']),
        'a2z_event_id': json['a2z_event_id'] == null ? undefined : json['a2z_event_id'],
        'active': json['active'] == null ? undefined : json['active'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'name': json['name'] == null ? undefined : json['name'],
        'location': json['location'] == null ? undefined : json['location'],
        'start_date': json['start_date'] == null ? undefined : json['start_date'],
        'end_date': json['end_date'] == null ? undefined : json['end_date'],
        'registration_url': json['registration_url'] == null ? undefined : json['registration_url'],
        'admin_collections': json['admin_collections'] == null ? undefined : ((json['admin_collections'] as Array<any>).map(AdminCollectionResponseFromJSON)),
        'booth_map_url': json['booth_map_url'] == null ? undefined : json['booth_map_url'],
    };
}

export function TradeShowResponseToJSON(json: any): TradeShowResponse {
    return TradeShowResponseToJSONTyped(json, false);
}

export function TradeShowResponseToJSONTyped(value?: TradeShowResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'features': TradeShowFeaturesResponseToJSON(value['features']),
        'a2z_event_id': value['a2z_event_id'],
        'active': value['active'],
        'brand': value['brand'],
        'name': value['name'],
        'location': value['location'],
        'start_date': value['start_date'],
        'end_date': value['end_date'],
        'registration_url': value['registration_url'],
        'admin_collections': value['admin_collections'] == null ? undefined : ((value['admin_collections'] as Array<any>).map(AdminCollectionResponseToJSON)),
        'booth_map_url': value['booth_map_url'],
    };
}

