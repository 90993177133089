import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'
import CartButton from '@/components/shared/cart/CartButton'
import aa from 'search-insights'

export default {
  components: { CartButton },
  props: {
    forceRetailerView: {
      type: Boolean,
      default: false
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      new_product_added: null,
      cart_disabled: false,
      cart: {
        sub_carts: [],
        total: null,
        valid_mov: false
      },
      product_counter: 0,
      isCartVisible: false,
      minimum: null,
      quantityChangeDisabled: false,
      animate: 'IN'
    }
  },

  methods: {
    getCart,
    toggleCart,
    openCart,
    closeCart,
    addToCart,
    userLoggedInCallback,
    updateQuantity,
    removeProduct,
    goToCheckout,
    cartUpdatedCallback,
    validate,
    destroyCart,
    addMultipleVariantsToCart,
    trackAddToCart,
    handleSuccessAddToCart,
    addToCartFromReorder
  },

  mounted() {
    this.minimum = this.$utils.constants.CHECKOUT_MINIMUM

    this.$eventBus.$on('add-to-cart', this.addToCart)
    this.$eventBus.$on('add-multiple-variants', this.addMultipleVariantsToCart)
    this.$eventBus.$on('remove-from-cart', this.removeFromCart)
    this.$eventBus.$on('user-logged-in', this.userLoggedInCallback)
    this.$eventBus.$on('destroy-cart', this.destroyCart)
    this.$eventBus.$on('add-to-cart-from-reorder', this.addToCartFromReorder)
  },

  beforeDestroy() {
    this.$eventBus.$off('add-to-cart', this.addToCart)
    this.$eventBus.$on('add-multiple-variants', this.addMultipleVariantsToCart)
    this.$eventBus.$off('remove-from-cart', this.removeFromCart)
    this.$eventBus.$off('user-logged-in', this.userLoggedInCallback)
    this.$eventBus.$off('destroy-cart', this.destroyCart)
    this.$eventBus.$off('add-to-cart-from-reorder', this.addToCartFromReorder)
  },

  computed: {
    ...mapGetters({
      user: 'userState'
    })
  }
}

function destroyCart() {
  this.cart = {
    sub_carts: [],
    total: null,
    valid_mov: false
  }
}

function userLoggedInCallback() {
  this.getCart()
}

async function getCart() {
  if (this.user && (this.user.role === 'RETAILER' || this.user.role === 'ADMIN')) {
    try {
      this.cart_disabled = true
      this.cart = (await this.$utils.getCart()).data
      this.$utils.checkCartChanges(this, this.cart)
      this.cart_disabled = false
    } catch (error) {
      this.$handleError(this, error)
    }
  }
}

function cartUpdatedCallback() {
  this.getCart()
}

function toggleCart() {
  this.animate = 'IN'
  this.isCartVisible = !this.isCartVisible

  if (this.isCartVisible) {
    this.getCart()
  }
}

function openCart() {
  this.animate = 'IN'
  setTimeout(() => {
    this.isCartVisible = true
  }, 400)
}

function closeCart() {
  this.animate = 'OUT'
  setTimeout(() => {
    this.isCartVisible = false
  }, 400)
}

async function updateQuantity(quantity, sub_cart, product) {
  this.cart_disabled = true
  if (quantity <= 0) {
    this.removeProduct(product, sub_cart)
  } else {
    try {
      this.cart = (
        await this.$utils.updateCart({
          variant_id: this.cart.sub_carts[sub_cart].cart_items[product].variant_id,
          total_quantity: quantity
        })
      ).data
      this.$utils.checkCartChanges(this, this.cart)
      this.$forceUpdate()
    } catch (error) {
      this.$handleError(this, error)
      this.getCart()
    }
  }
  this.cart_disabled = false
}

async function removeProduct(product, sub_cart) {
  this.cart_disabled = true
  try {
    this.cart = (
      await this.$utils.removeProductFromCart(this.cart.sub_carts[sub_cart].cart_items[product].variant_id)
    ).data
    this.$utils.checkCartChanges(this, this.cart)
    this.$store.dispatch('cart/getCartProductCount')
    this.$forceUpdate()
    this.$eventBus.$emit('refresh-product-cart-summary')
  } catch (error) {
    this.$handleError(this, error)
    this.getCart()
  }
  this.cart_disabled = false
}

function validate() {
  if (this.cart && this.cart.sub_carts.length) {
    this.goToCheckout()
  }
}

function goToCheckout() {
  this.isCartVisible = false
  this.$router.push('/checkout')
}

async function addToCart(cart_obj) {
  let request = {
    variant_id: cart_obj.variant.id,
    quantity: cart_obj.quantity
  }

  if (cart_obj.category_id) {
    request.category_id = cart_obj.category_id
  }

  try {
    this.cart = (await this.$utils.addToCart(request)).data
    this.$utils.checkCartChanges(this, this.cart)
    this.$store.dispatch('cart/getCartProductCount')

    this.trackAddToCart(
      cart_obj.product,
      cart_obj.variant,
      cart_obj.quantity,
      cart_obj.entryPoint,
      cart_obj.category_id
    )
  } catch (error) {
    this.$handleError(this, error)
    return
  }

  this.handleSuccessAddToCart(cart_obj.product)
}

function handleSuccessAddToCart(product, keepAdding) {
  if (!this.$route.name.includes('checkout') && this.$route.name !== 'customAssortments' && !keepAdding) {
    this.openCart()
  }

  if (this.$route.name.includes('checkout')) {
    this.$eventBus.$emit('cart-updated')
  }

  this.new_product_added = product.name

  if (!keepAdding) {
    this.$eventBus.$emit('close-quick-shop-modal')
  }

  const _self = this
  setTimeout(function () {
    _self.new_product_added = null
  }, 2000)
}

async function addToCartFromReorder(variantId, quantity) {
  try {
    const { data } = await this.$utils.addMultipleVariantsToCart({
      variants: [{ id: variantId, quantity }]
    })
    this.cart = data
    this.$utils.checkCartChanges(this, this.cart)
    this.$store.dispatch('cart/getCartProductCount')
    this.$eventBus.$emit('refresh-product-cart-summary')
    this.isCartVisible = true
  } catch (error) {
    this.$handleError(this, error)
  }
}

async function addMultipleVariantsToCart(data) {
  const { product, variants, entryPoint, category_id, keepAdding } = data

  const requestBody = {
    category_id,
    variants: variants?.map(({ quantity, variant }) => {
      return {
        quantity,
        id: variant.id,
        ...(this.$trackingStore.state.queryID && {
          query_id: this.$trackingStore.state.queryID
        })
      }
    })
  }

  try {
    const { data } = await this.$utils.addMultipleVariantsToCart(requestBody)
    this.cart = data
    this.$utils.checkCartChanges(this, this.cart)
    this.$store.dispatch('cart/getCartProductCount')

    variants?.forEach((item) => {
      this.trackAddToCart(product, item.variant, item.quantity, entryPoint, category_id)
    })

    this.$eventBus.$emit('refresh-product-cart-summary')
  } catch (error) {
    this.$handleError(this, error)
    return
  }

  this.handleSuccessAddToCart(product, keepAdding)
}

function trackAddToCart(product, variant, quantity, entryPoint, categoryId) {
  const category = product.categories?.find((item) => item.id === categoryId)
  let properties = {
    Product: product.name,
    'Product Id': product.id,
    Variant: variant.name,
    Brand: product.brand_name,
    'Item Quantity': quantity,
    Cost: product.wholesale_price,
    Category: category?.name,
    'Entry Point Type': entryPoint,
    type: category?.name,
    brand: product.brand?.company_name || product?.brand_name
  }

  this.$trackEvent({ action: 'Add to Cart', properties: properties })

  const isFromSearchResultPage = this.$trackingStore.state.queryID

  aa(isFromSearchResultPage ? 'addedToCartObjectIDsAfterSearch' : 'addedToCartObjectIDs', {
    ...(isFromSearchResultPage && {
      queryID: this.$trackingStore.state.queryID
    }),
    authenticatedUserToken: this.user.id,
    userToken: this.user.id,
    eventName: 'Product Added to Cart',
    index: `${this.$env.getOrigin()}_products`,
    objectIDs: [product.id],
    objectData: [
      {
        price: product.wholesale_price,
        quantity: parseInt(quantity, 10)
      }
    ],
    value: product.wholesale_price * quantity,
    currency: 'USD'
  })
}
