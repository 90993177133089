/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Bulk update products request
 * @export
 * @interface BulkUpdateProductsRequest
 */
export interface BulkUpdateProductsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateProductsRequest
     */
    draft_products: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateProductsRequest
     */
    active_products: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateProductsRequest
     */
    brand_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateProductsRequest
     */
    product_ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateProductsRequest
     */
    category_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateProductsRequest
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateProductsRequest
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateProductsRequest
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateProductsRequest
     */
    pos_price?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateProductsRequest
     */
    wholesale_minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateProductsRequest
     */
    wholesale_increment?: number;
}

/**
 * Check if a given object implements the BulkUpdateProductsRequest interface.
 */
export function instanceOfBulkUpdateProductsRequest(value: object): value is BulkUpdateProductsRequest {
    if (!('draft_products' in value) || value['draft_products'] === undefined) return false;
    if (!('active_products' in value) || value['active_products'] === undefined) return false;
    return true;
}

export function BulkUpdateProductsRequestFromJSON(json: any): BulkUpdateProductsRequest {
    return BulkUpdateProductsRequestFromJSONTyped(json, false);
}

export function BulkUpdateProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateProductsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'draft_products': json['draft_products'],
        'active_products': json['active_products'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
        'category_ids': json['category_ids'] == null ? undefined : json['category_ids'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'wholesale_minimum': json['wholesale_minimum'] == null ? undefined : json['wholesale_minimum'],
        'wholesale_increment': json['wholesale_increment'] == null ? undefined : json['wholesale_increment'],
    };
}

export function BulkUpdateProductsRequestToJSON(json: any): BulkUpdateProductsRequest {
    return BulkUpdateProductsRequestToJSONTyped(json, false);
}

export function BulkUpdateProductsRequestToJSONTyped(value?: BulkUpdateProductsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'draft_products': value['draft_products'],
        'active_products': value['active_products'],
        'brand_id': value['brand_id'],
        'product_ids': value['product_ids'],
        'category_ids': value['category_ids'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'wholesale_price': value['wholesale_price'],
        'pos_price': value['pos_price'],
        'wholesale_minimum': value['wholesale_minimum'],
        'wholesale_increment': value['wholesale_increment'],
    };
}

