import ProductApi from '@/services/api/productApi'
import BrandApi from '@/services/api/brandApi'
import CustomErrors from '@/components/shared/productEditor/productVariantBox/CustomErrors'

const ProductApiService = new ProductApi(),
  BrandApiService = new BrandApi()

export default {
  components: { CustomErrors },
  inject: ['parentValidator'],
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    sku: {
      type: String
    },
    inventoryQuantity: {
      type: Number
    },
    productVariant: {
      type: Object
    },
    product: {
      type: Object
    },
    formErrors: {
      type: Object
    },
    user: {
      type: Object
    },
    testerProducts: {
      type: Array
    },
    selectedBrand: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      warningMessages: [],
      moq: null,
      initialSku: '',
      initialInventory: 0,
      tooltipTextWeight: 'Weights imported from Shopify have been converted to lbs.'
    }
  },
  methods: {
    openVariantImageModal,
    updateVariantValue,
    removeVariantFromList,
    addTester,
    removeTester,
    selectTestVariant,
    selectTestProduct,
    changeStockState,
    requiredError(error) {
      if (error) {
        return error.includes('field is required.')
      } else {
        return false
      }
    },
    createWarnings,
    getErrorMessage,
    validateSku,
    validateInventory
  },
  watch: {
    'productVariant.inventory_quantity': function (newValue) {
      this.createWarnings(newValue)
    },
    sku: function (value) {
      this.validateSku(value)
    },

    inventoryQuantity: function (value) {
      this.validateInventory(value)
    },

    // when track inventory toggle changes we re-run the sku and inventory validation
    'product.track_inventory': function () {
      this.validateSku(this.productVariant.sku)
      this.validateInventory(this.productVariant.inventory_quantity)
    }
  },
  computed: {
    weightValidationRules() {
      return {
        nullable: true, // Optional field, but if filled must be greater than 0
        numeric: true,
        min_value: 0,
        decimal: 2
      }
    }
  },

  async created() {
    this.$validator = this.parentValidator
    const { data } =
      this.$session.getUserInfo().role === 'BRAND'
        ? await BrandApiService.getBrandMe()
        : await BrandApiService.getBrand(this.selectedBrand.id)
    this.moq = data.minimum_quantity
    if (this.productVariant.inventory_quantity) {
      this.createWarnings(this.productVariant.inventory_quantity)
    }
    //weight may come back with more than 2 decimals, fix it to 2 decimals
    this.productVariant.weight = this.productVariant.weight?.toFixed(2)
  },
  mounted: function () {
    const { tester_product_id, tester_product_name } = this.productVariant
    if (tester_product_id && tester_product_name) {
      this.selectTestProduct({ id: tester_product_id, name: tester_product_name })
    }
    this.initialSku = this.productVariant.sku
    this.initialInventory = this.productVariant.inventory_quantity
    this.$nextTick(() => this.validateSku(this.initialSku))
    this.$nextTick(() => this.validateInventory(this.initialInventory))
  }
}

function openVariantImageModal() {
  if (!this.disabled) {
    this.$emit('on-variant-image-modal-open')
  }
}

function updateVariantValue(key) {
  this.$emit('on-update-variant-value', key, this.productVariant[key])
  this.$forceUpdate()
}

function removeVariantFromList() {
  if (!this.disabled) {
    this.$emit('on-remove-variant')
    this.$forceUpdate()
  }
}

function addTester() {
  if (!this.disabled) {
    this.$emit('on-add-tester')
    this.$forceUpdate()
  }
}

function removeTester() {
  if (!this.disabled) {
    this.$emit('on-remove-tester')
    this.$forceUpdate()
  }
}

function selectTestVariant(item) {
  if (!this.disabled) {
    this.$emit('on-select-test-variant', item)
    this.$forceUpdate()
  }
}

async function selectTestProduct(product, clearVariant) {
  try {
    const response = await ProductApiService.getProduct(product.id)
    this.productVariant.tester_product_id = product.id
    this.productVariant.tester_product_name = product.name

    if (clearVariant) {
      this.productVariant.tester_variant_id = null
      this.productVariant.tester_variant_name = ''
    }

    this.productVariant.testerVariants =
      response.data.variants.map(function (variant) {
        return {
          id: variant.id,
          name: Object.values(variant.characteristic_option).join(' ')
        }
      }) || []

    this.$forceUpdate()
  } catch (error) {
    this.$handleError(this, error)
  }
}

function changeStockState() {
  this.$emit('on-update-variant-value', 'in_stock', !this.productVariant.in_stock)
  this.$forceUpdate()
}

function createWarnings(newValue) {
  let warnings = []
  if (parseInt(newValue) < this.product.wholesale_increment) {
    warnings.push('Inventory is lower than the wholesale increment.')
  }
  // let totalQuantity = this.$parent.$data.product.variants
  //   .filter((variant) => variant.inventory_quantity)
  //   .map((variant) => parseInt(variant.inventory_quantity));
  //
  // if (totalQuantity.length > 0) {
  //   totalQuantity = totalQuantity.reduce((accumulator, inventory) => accumulator + inventory);
  // }
  // if (totalQuantity < this.moq) {
  //   warnings.push("Total inventory across the style is lower than the minimum order quantity.");
  // }
  this.warningMessages = warnings
}

function getErrorMessage(fieldName) {
  return this.$validator.errors.items.find((error) => error.field === fieldName)?.msg
}

async function validateInventory(value) {
  if (!value) {
    if (this.product.track_inventory) {
      this.$validator.errors.add({ field: `${this.index}_product_variant_inventory`, msg: '*Required' })
    }
    return
  } else {
    this.createWarnings(value)
    if (value.toString().length > 7) {
      this.$validator.errors.add({ field: `${this.index}_product_variant_inventory`, msg: 'Value cannot be greater' })
      return
    }
  }
  this.$validator.errors.remove(`${this.index}_product_variant_inventory`)
}

async function validateSku(value) {
  if (!value) {
    this.$validator.errors.remove(`${this.index}_product_variant_sku`)
    if (this.product.track_inventory) {
      this.$validator.errors.add({ field: `${this.index}_product_variant_sku`, msg: '*Required' })
    }
    return
  }

  const findLocalDuplication = this.product?.variants.find(
    (variant) => variant.sku === value && variant.id !== this.productVariant.id
  )

  if (findLocalDuplication) {
    this.$validator.errors.add({ field: `${this.index}_product_variant_sku`, msg: 'Duplicated SKU.' })
  } else {
    this.$validator.errors.remove(`${this.index}_product_variant_sku`)
  }

  if (!findLocalDuplication && value !== this.initialSku) {
    try {
      const { data } = await ProductApiService.validateSku(
        value,
        this.user?.role === 'ADMIN' ? this.selectedBrand.id : null
      )
      if (!data?.valid) {
        this.$validator.errors.add({ field: `${this.index}_product_variant_sku`, msg: 'Duplicated SKU.' })
      }
    } catch (error) {
      this.$logger.notify(new Error(error))
    }
  }
}
