import utils from '../../../services/utils'
import { mapGetters } from 'vuex'
import BrandFavoriteButton from '../brandFavoriteButton/BrandFavoriteButton'
import aa from 'search-insights'

export default {
  props: [
    'data',
    'quickShop',
    'filterOrSortUsed',
    'listOfFilterTypes',
    'queryId',
    'searchIndex',
    'pdpPage',
    'fitHeight',
    'onSaveToBoard',
    'shouldRefetchOnFavorite',
    'i',
    'actionType',
    'eventId'
  ],
  components: {
    BrandFavoriteButton
  },
  data() {
    return {
      showBrandName: true,
      trackingInfo: {
        'Product name': this.data.name,
        'Brand name': this.data.brand.company_name,
        Category: this.data?.categories?.[0]?.name,
        BuyonBulletin: this.data.brand.buy_on_bulletin,
        BuyDirect: !this.data.brand.buy_on_bulletin
      }
    }
  },

  methods: {
    openQuickShopModal: openQuickShopModal,
    urlFormatter: utils.urlSafeString,
    handleQuickShopButtonClick,
    trackClick,
    getRouterLink,
    truncatedProductName(str) {
      const num = 255
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }
  },

  beforeMount() {
    this.showBrandName = this.$route.name !== 'brand-bio' && this.$route.name !== 'BrandBoardsDetails'
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    }),
    canShowThumbnailBadge() {
      return !!this.data?.brand?.event_ids?.includes(this.eventId)
    }
  }
}

function handleQuickShopButtonClick() {
  this.openQuickShopModal(this.data)
}

function openQuickShopModal(product) {
  this.$eventBus.$emit('show-quick-shop-modal', product)
}

function trackClick() {
  const isOnSearchResultPage = this.$route.name === 'search',
    isLoggedInUser = this.user

  const objectIDs = this.data.objectID || 'N/A'
  aa(isOnSearchResultPage ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs', {
    ...(isLoggedInUser && {
      authenticatedUserToken: this.user.id,
      userToken: this.user.id,
      ...(this.$trackingStore.state.queryID && {
        queryID: this.$trackingStore.state.queryID,
        positions: [this.i + 1]
      })
    }),
    eventName: 'Product Clicked',
    index: `${this.$env.getOrigin()}_products`,
    objectIDs: [objectIDs]
  })

  if (this.searchIndex && this.queryId) {
    this.$trackingStore.commit('setIndexName', this.searchIndex)
    this.$trackingStore.commit('setQueryId', this.queryId)
    this.$trackingStore.commit('setSource', 'search')
  }
  this.$trackEvent({
    action: 'Pdp Click',
    properties: {
      'Filter or Sort Used': this.filterOrSortUsed,
      'Filter Type(s)': this.listOfFilterTypes
    }
  })
}

function getRouterLink() {
  const { navItemParentName, navItemName, navItemId, categoryName, collectionName, collectionId } = this.$route.params,
    routeConfig = {
      name: 'productDetails',
      params: {
        productName: this.urlFormatter(this.data.name),
        productId: this.data.id
      },
      query: {}
    }
  if (navItemParentName && navItemName) {
    routeConfig.params.parentCategory = navItemParentName
    routeConfig.params.category = navItemName
    routeConfig.query.navItemId = navItemId
  }
  if (navItemName && navItemParentName === undefined) {
    routeConfig.params.parentCategory = navItemName
    routeConfig.query.navItemId = navItemId
  }
  if (collectionName && collectionId && categoryName === undefined) {
    routeConfig.params.parentCategory = collectionName
    routeConfig.query.collectionId = collectionId
  }
  if (categoryName && collectionName && collectionId) {
    routeConfig.params.parentCategory = categoryName
    routeConfig.params.category = collectionName
    routeConfig.query.collectionId = collectionId
  }
  return routeConfig
}
