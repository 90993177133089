/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenameViewComponentRequest
 */
export interface RenameViewComponentRequest {
    /**
     * 
     * @type {string}
     * @memberof RenameViewComponentRequest
     */
    view_component_id: string;
    /**
     * 
     * @type {string}
     * @memberof RenameViewComponentRequest
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof RenameViewComponentRequest
     */
    rename_entity: boolean;
}

/**
 * Check if a given object implements the RenameViewComponentRequest interface.
 */
export function instanceOfRenameViewComponentRequest(value: object): value is RenameViewComponentRequest {
    if (!('view_component_id' in value) || value['view_component_id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('rename_entity' in value) || value['rename_entity'] === undefined) return false;
    return true;
}

export function RenameViewComponentRequestFromJSON(json: any): RenameViewComponentRequest {
    return RenameViewComponentRequestFromJSONTyped(json, false);
}

export function RenameViewComponentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenameViewComponentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'view_component_id': json['view_component_id'],
        'name': json['name'],
        'rename_entity': json['rename_entity'],
    };
}

export function RenameViewComponentRequestToJSON(json: any): RenameViewComponentRequest {
    return RenameViewComponentRequestToJSONTyped(json, false);
}

export function RenameViewComponentRequestToJSONTyped(value?: RenameViewComponentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'view_component_id': value['view_component_id'],
        'name': value['name'],
        'rename_entity': value['rename_entity'],
    };
}

