<template>
  <div class="flex">
    <Icon
      v-tooltip="action === 'REMOVE' ? 'Remove from Board' : 'Add to Board'"
      :icon-key="action === 'REMOVE' ? 'minus-circled' : 'plus-circled'"
      hover-type="ICON"
      :stop-propagation="true"
      :on-click="handleFavoriteClick"
    />
    <portal v-if="displayDrawer" to="brand-board-drawer">
      <brand-board-drawer :product-id="productId" :on-close="handleCloseDrawer" :on-save-to-board="onSaveToBoard" />
    </portal>
  </div>
</template>

<script>
import BrandBoardDrawer from '../brandBoardDrawer/BrandBoardDrawer'
export default {
  name: 'BrandFavoriteButton',
  components: {
    BrandBoardDrawer
  },
  props: ['productId', 'action', 'onSaveToBoard'],
  data() {
    return {
      displayDrawer: false
    }
  },
  methods: {
    handleFavoriteClick,
    handleCloseDrawer
  }
}

function handleFavoriteClick() {
  this.displayDrawer = true
}

function handleCloseDrawer() {
  this.displayDrawer = false
}
</script>
