/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetBrandCategoriesForDropdownResponse } from './GetBrandCategoriesForDropdownResponse';
import {
    GetBrandCategoriesForDropdownResponseFromJSON,
    GetBrandCategoriesForDropdownResponseFromJSONTyped,
    GetBrandCategoriesForDropdownResponseToJSON,
    GetBrandCategoriesForDropdownResponseToJSONTyped,
} from './GetBrandCategoriesForDropdownResponse';

/**
 * 
 * @export
 * @interface BrandCategoriesForDropdownResponse
 */
export interface BrandCategoriesForDropdownResponse {
    /**
     * 
     * @type {Array<GetBrandCategoriesForDropdownResponse>}
     * @memberof BrandCategoriesForDropdownResponse
     */
    brand_categories?: Array<GetBrandCategoriesForDropdownResponse>;
}

/**
 * Check if a given object implements the BrandCategoriesForDropdownResponse interface.
 */
export function instanceOfBrandCategoriesForDropdownResponse(value: object): value is BrandCategoriesForDropdownResponse {
    return true;
}

export function BrandCategoriesForDropdownResponseFromJSON(json: any): BrandCategoriesForDropdownResponse {
    return BrandCategoriesForDropdownResponseFromJSONTyped(json, false);
}

export function BrandCategoriesForDropdownResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandCategoriesForDropdownResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_categories': json['brand_categories'] == null ? undefined : ((json['brand_categories'] as Array<any>).map(GetBrandCategoriesForDropdownResponseFromJSON)),
    };
}

export function BrandCategoriesForDropdownResponseToJSON(json: any): BrandCategoriesForDropdownResponse {
    return BrandCategoriesForDropdownResponseToJSONTyped(json, false);
}

export function BrandCategoriesForDropdownResponseToJSONTyped(value?: BrandCategoriesForDropdownResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_categories': value['brand_categories'] == null ? undefined : ((value['brand_categories'] as Array<any>).map(GetBrandCategoriesForDropdownResponseToJSON)),
    };
}

