/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopifyBrandOrders
 */
export interface ShopifyBrandOrders {
    /**
     * 
     * @type {string}
     * @memberof ShopifyBrandOrders
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopifyBrandOrders
     */
    orders_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifyBrandOrders
     */
    wholesale_prices?: boolean;
}

/**
 * Check if a given object implements the ShopifyBrandOrders interface.
 */
export function instanceOfShopifyBrandOrders(value: object): value is ShopifyBrandOrders {
    return true;
}

export function ShopifyBrandOrdersFromJSON(json: any): ShopifyBrandOrders {
    return ShopifyBrandOrdersFromJSONTyped(json, false);
}

export function ShopifyBrandOrdersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopifyBrandOrders {
    if (json == null) {
        return json;
    }
    return {
        
        'domain': json['domain'] == null ? undefined : json['domain'],
        'orders_url': json['orders_url'] == null ? undefined : json['orders_url'],
        'wholesale_prices': json['wholesale_prices'] == null ? undefined : json['wholesale_prices'],
    };
}

export function ShopifyBrandOrdersToJSON(json: any): ShopifyBrandOrders {
    return ShopifyBrandOrdersToJSONTyped(json, false);
}

export function ShopifyBrandOrdersToJSONTyped(value?: ShopifyBrandOrders | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'domain': value['domain'],
        'orders_url': value['orders_url'],
        'wholesale_prices': value['wholesale_prices'],
    };
}

