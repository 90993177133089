/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PackingSlipRequest } from './PackingSlipRequest';
import {
    PackingSlipRequestFromJSON,
    PackingSlipRequestFromJSONTyped,
    PackingSlipRequestToJSON,
    PackingSlipRequestToJSONTyped,
} from './PackingSlipRequest';

/**
 * Split ship object
 * @export
 * @interface SplitShipRequest
 */
export interface SplitShipRequest {
    /**
     * 
     * @type {Array<PackingSlipRequest>}
     * @memberof SplitShipRequest
     */
    packing_slips?: Array<PackingSlipRequest>;
}

/**
 * Check if a given object implements the SplitShipRequest interface.
 */
export function instanceOfSplitShipRequest(value: object): value is SplitShipRequest {
    return true;
}

export function SplitShipRequestFromJSON(json: any): SplitShipRequest {
    return SplitShipRequestFromJSONTyped(json, false);
}

export function SplitShipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitShipRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'packing_slips': json['packing_slips'] == null ? undefined : ((json['packing_slips'] as Array<any>).map(PackingSlipRequestFromJSON)),
    };
}

export function SplitShipRequestToJSON(json: any): SplitShipRequest {
    return SplitShipRequestToJSONTyped(json, false);
}

export function SplitShipRequestToJSONTyped(value?: SplitShipRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'packing_slips': value['packing_slips'] == null ? undefined : ((value['packing_slips'] as Array<any>).map(PackingSlipRequestToJSON)),
    };
}

