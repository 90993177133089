// eslint-disable-next-line max-lines
import restApi from '../../http.js'
import session from '../session.js'

export default class OrderApi {
  applyPromoCode(request) {
    return restApi({
      method: 'POST',
      url: `/carts/promo-code?code=${request.promo_code}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  validateOrder(request) {
    return restApi({
      method: 'POST',
      url: '/orders/mov-validation',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  createOrder(request) {
    return restApi({
      method: 'POST',
      url: '/orders',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  createCustomAssortment(request) {
    return restApi({
      method: 'POST',
      url: '/custom-assortments',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  finishOrderCreation(id, request) {
    return restApi({
      method: 'POST',
      url: `/orders/${id}/finish`,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getOrder(id) {
    return restApi({
      method: 'GET',
      url: '/orders/' + id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getOrderConfirmation(id) {
    return restApi({
      method: 'GET',
      url: `/orders/${id}/confirmation`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getSuborder(id) {
    return restApi({
      method: 'GET',
      url: '/orders/suborders/' + id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getInvoice(id) {
    return restApi({
      method: 'GET',
      url: `/orders/suborders/${id}/invoice`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getPayment(id) {
    return restApi({
      method: 'GET',
      url: `/orders/suborders/${id}/payment`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getOrderMoreInfo(id) {
    return restApi({
      method: 'GET',
      url: `/orders/${id}/preview`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateShippingInfo(request) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + request.id + '/add-shipping-info',
      data: request.data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  shipOrder(id) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + id + '/shipped',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  triggerPartiallyUpdate(id) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + id + '/partially-shipped',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateSuborder(request) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + request.id,
      data: request.data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateAcceptSuborder(request) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + request.id + '/update-accept',
      data: request.data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  completeOrder(request) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + request.id + '/shipped',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  declineSuborder(id, data) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/' + id + '/decline',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  payOrder(request) {
    return restApi({
      method: 'POST',
      url: '/payments/create-session',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  generatePDF(id) {
    return restApi({
      method: 'POST',
      url: '/orders/suborders/packing-slips/' + id + '/print',
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  createPackingSlip(request) {
    return restApi({
      method: 'POST',
      url: '/orders/suborders/' + request.id + '/packing-slips',
      data: request.data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getPackingSlip(suborder_id, packing_slip_id) {
    return restApi({
      method: 'GET',
      url: '/orders/suborders/' + suborder_id + '/packing-slips/' + packing_slip_id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updatePackingSlip(request) {
    return restApi({
      method: 'PATCH',
      url: '/orders/suborders/packing-slips/' + request.id,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getAddress(type) {
    return restApi({
      method: 'GET',
      url: '/retailers/addresses',
      params: {
        [type]: true
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getShippingCarriers() {
    return restApi({
      method: 'GET',
      url: '/orders/shipping-carriers',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  adminExportToCSV() {
    return restApi({
      method: 'POST',
      url: '/orders/export',
      headers: {
        Accept: 'text/csv',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  retailerExportToCSV() {
    return restApi({
      method: 'POST',
      url: 'orders/retailers/export',
      headers: {
        Accept: 'text/csv',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  brandExportToCSV() {
    return restApi({
      method: 'POST',
      url: 'orders/brands/export',
      headers: {
        Accept: 'text/csv',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  changePaidStatus(id) {
    return restApi({
      method: 'PATCH',
      url: `/orders/suborders/${id}/paid`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getReturn(id) {
    return restApi({
      method: 'GET',
      url: `/orders/order-returns/${id}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getReorders(sort, page, size, query) {
    const params = { sort, page, size }
    if (query) {
      params.query = query
    }
    params.size = 5
    params.page = page - 1
    return restApi({
      method: 'GET',
      url: '/orders/reorders',
      params,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getOrderCount(id) {
    return restApi({
      method: 'get',
      url: '/orders/count',
      params: {
        brand_id: id
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getDeclineReasons() {
    return restApi({
      method: 'get',
      url: '/orders/decline-reasons/dropdown',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getCart(selected_for_checkout) {
    return restApi({
      method: 'get',
      url: '/carts',
      params: {
        selected_for_checkout: Boolean(selected_for_checkout)
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getCartQuantity() {
    return restApi({
      method: 'get',
      url: 'carts/products-amount',

      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  addToCart(request) {
    return restApi({
      method: 'post',
      url: '/carts',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  addMultipleVariantsToCart(requestData) {
    return restApi({
      method: 'post',
      url: '/carts/add-items',
      data: requestData,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  addToCartFromCustomAssortment(id) {
    return restApi({
      method: 'post',
      url: `/carts/custom-assortments/${id}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateCart(request) {
    return restApi({
      method: 'post',
      url: '/carts/update',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removeProductFromCart(id) {
    return restApi({
      method: 'delete',
      url: `/carts/${id}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  checkoutBrand(id) {
    return restApi({
      method: 'post',
      url: '/carts/brand-checkout',
      data: {
        brand_id: id
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  adjustManuallyCharged(id) {
    return restApi({
      method: 'patch',
      url: `/order-adjustments/suborders/${id}/manually-charge`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  addItemToOrder(id, items) {
    return restApi({
      method: 'patch',
      url: `/order-adjustments/suborders/${id}/add-items`,
      data: {
        items
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateSlipShippingQuantity(id, items) {
    return restApi({
      method: 'patch',
      url: `/order-adjustments/packing-slips/${id}/packing-slip-items/shipped-quantities`,
      data: {
        items
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  adjustChargeDate(id, charge_date) {
    return restApi({
      method: 'patch',
      url: `/order-adjustments/suborders/${id}/charge-date`,
      data: {
        charge_date: new Date(charge_date).toISOString()
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  adjustSuborder(id, adjustment) {
    return restApi({
      method: 'PATCH',
      url: `/order-adjustments/suborders/${id}/status`,
      data: adjustment,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removePromoCode(code) {
    return restApi({
      method: 'DELETE',
      url: `/carts/promo-code/${code}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getOrderStatuses() {
    return restApi({
      method: 'GET',
      url: '/orders/statuses',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getInfoForInvoicePayment(id) {
    return restApi({
      method: 'GET',
      url: `/orders/suborders/${id}/pay-invoice-info`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateInvoiceCard({ suborderId, token_id, first_name, last_name, make_default, save_card }) {
    return restApi({
      method: 'PUT',
      url: `/orders/invoices/${suborderId}/update-card`,
      data: {
        token_id,
        first_name,
        last_name,
        make_default,
        save_card
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateInvoiceCardId(card_id, suborderId) {
    return restApi({
      method: 'PUT',
      url: `/orders/suborders/${suborderId}/change-card`,
      data: {
        card_id
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateInvoiceAddress(request) {
    return restApi({
      method: 'PUT',
      url: `/orders/suborders/${request.suborderId}/billing-address/${request.addressId}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  payInvoice(id) {
    return restApi({
      method: 'POST',
      url: `/orders/suborders/${id}/pay-invoice`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  splitShip(suborderId, data) {
    return restApi({
      method: 'POST',
      url: `/orders/suborders/${suborderId}/split-ship`,
      data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateSplitShip(suborderId, data) {
    return restApi({
      method: 'POST',
      url: `/orders/suborders/${suborderId}/batch-split-ship`,
      data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  estimateShipping(shipping_address_id) {
    return restApi({
      method: 'POST',
      url: `/orders/estimate-shipping`,
      data: {
        shipping_address_id: shipping_address_id
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  confirmPackingSlip(packingSlipId, data) {
    return restApi({
      method: 'PATCH',
      url: `/orders/suborders/packing-slips/${packingSlipId}/confirm`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      },
      data: data
    })
  }

  draftPackingSlip(packingSlipId) {
    return restApi({
      method: 'PATCH',
      url: `/orders/suborders/packing-slips/${packingSlipId}/draft`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  declinePackingSlip(packingSlipId) {
    return restApi({
      method: 'PATCH',
      url: `/orders/suborders/packing-slips/${packingSlipId}/decline`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  // orders/suborders/packing-slips/{id}/decline
}

export const OrderApiService = new OrderApi()
