/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductCategoryResponse } from './ProductCategoryResponse';
import {
    ProductCategoryResponseFromJSON,
    ProductCategoryResponseFromJSONTyped,
    ProductCategoryResponseToJSON,
    ProductCategoryResponseToJSONTyped,
} from './ProductCategoryResponse';
import type { GetProductVariantResponse } from './GetProductVariantResponse';
import {
    GetProductVariantResponseFromJSON,
    GetProductVariantResponseFromJSONTyped,
    GetProductVariantResponseToJSON,
    GetProductVariantResponseToJSONTyped,
} from './GetProductVariantResponse';

/**
 * 
 * @export
 * @interface GetProductFilteredResponse
 */
export interface GetProductFilteredResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductFilteredResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductFilteredResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductFilteredResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductFilteredResponse
     */
    brand_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductFilteredResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    brand_minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    brand_minimum_reorder_value?: number;
    /**
     * 
     * @type {Array<ProductCategoryResponse>}
     * @memberof GetProductFilteredResponse
     */
    categories?: Array<ProductCategoryResponse>;
    /**
     * 
     * @type {string}
     * @memberof GetProductFilteredResponse
     */
    status?: GetProductFilteredResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductFilteredResponse
     */
    sell_pos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductFilteredResponse
     */
    sell_wholesale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductFilteredResponse
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    compare_at_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    wholesale_minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    wholesale_increment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductFilteredResponse
     */
    opted_into_consignment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    commission_split?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductFilteredResponse
     */
    visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductFilteredResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductFilteredResponse
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductFilteredResponse
     */
    characteristics?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GetProductFilteredResponse
     */
    characteristic_options?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<GetProductVariantResponse>}
     * @memberof GetProductFilteredResponse
     */
    variants?: Array<GetProductVariantResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductFilteredResponse
     */
    images?: Array<string>;
}


/**
 * @export
 */
export const GetProductFilteredResponseStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Any: 'ANY',
    Draft: 'DRAFT'
} as const;
export type GetProductFilteredResponseStatusEnum = typeof GetProductFilteredResponseStatusEnum[keyof typeof GetProductFilteredResponseStatusEnum];


/**
 * Check if a given object implements the GetProductFilteredResponse interface.
 */
export function instanceOfGetProductFilteredResponse(value: object): value is GetProductFilteredResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('brand_id' in value) || value['brand_id'] === undefined) return false;
    return true;
}

export function GetProductFilteredResponseFromJSON(json: any): GetProductFilteredResponse {
    return GetProductFilteredResponseFromJSONTyped(json, false);
}

export function GetProductFilteredResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductFilteredResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'brand_id': json['brand_id'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'brand_minimum_order_value': json['brand_minimum_order_value'] == null ? undefined : json['brand_minimum_order_value'],
        'brand_minimum_reorder_value': json['brand_minimum_reorder_value'] == null ? undefined : json['brand_minimum_reorder_value'],
        'categories': json['categories'] == null ? undefined : ((json['categories'] as Array<any>).map(ProductCategoryResponseFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
        'sell_pos': json['sell_pos'] == null ? undefined : json['sell_pos'],
        'sell_wholesale': json['sell_wholesale'] == null ? undefined : json['sell_wholesale'],
        'active': json['active'] == null ? undefined : json['active'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'compare_at_price': json['compare_at_price'] == null ? undefined : json['compare_at_price'],
        'wholesale_minimum': json['wholesale_minimum'] == null ? undefined : json['wholesale_minimum'],
        'wholesale_increment': json['wholesale_increment'] == null ? undefined : json['wholesale_increment'],
        'opted_into_consignment': json['opted_into_consignment'] == null ? undefined : json['opted_into_consignment'],
        'commission_split': json['commission_split'] == null ? undefined : json['commission_split'],
        'visible': json['visible'] == null ? undefined : json['visible'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'characteristics': json['characteristics'] == null ? undefined : json['characteristics'],
        'characteristic_options': json['characteristic_options'] == null ? undefined : json['characteristic_options'],
        'variants': json['variants'] == null ? undefined : ((json['variants'] as Array<any>).map(GetProductVariantResponseFromJSON)),
        'images': json['images'] == null ? undefined : json['images'],
    };
}

export function GetProductFilteredResponseToJSON(json: any): GetProductFilteredResponse {
    return GetProductFilteredResponseToJSONTyped(json, false);
}

export function GetProductFilteredResponseToJSONTyped(value?: GetProductFilteredResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'brand_id': value['brand_id'],
        'brand_name': value['brand_name'],
        'brand_minimum_order_value': value['brand_minimum_order_value'],
        'brand_minimum_reorder_value': value['brand_minimum_reorder_value'],
        'categories': value['categories'] == null ? undefined : ((value['categories'] as Array<any>).map(ProductCategoryResponseToJSON)),
        'status': value['status'],
        'sell_pos': value['sell_pos'],
        'sell_wholesale': value['sell_wholesale'],
        'active': value['active'],
        'pos_price': value['pos_price'],
        'wholesale_price': value['wholesale_price'],
        'compare_at_price': value['compare_at_price'],
        'wholesale_minimum': value['wholesale_minimum'],
        'wholesale_increment': value['wholesale_increment'],
        'opted_into_consignment': value['opted_into_consignment'],
        'commission_split': value['commission_split'],
        'visible': value['visible'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'tags': value['tags'],
        'characteristics': value['characteristics'],
        'characteristic_options': value['characteristic_options'],
        'variants': value['variants'] == null ? undefined : ((value['variants'] as Array<any>).map(GetProductVariantResponseToJSON)),
        'images': value['images'],
    };
}

