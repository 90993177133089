/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumOptionRetailerStoreType } from './EnumOptionRetailerStoreType';
import {
    EnumOptionRetailerStoreTypeFromJSON,
    EnumOptionRetailerStoreTypeFromJSONTyped,
    EnumOptionRetailerStoreTypeToJSON,
    EnumOptionRetailerStoreTypeToJSONTyped,
} from './EnumOptionRetailerStoreType';

/**
 * 
 * @export
 * @interface GetEnumOptionsResponseRetailerStoreType
 */
export interface GetEnumOptionsResponseRetailerStoreType {
    /**
     * 
     * @type {Array<EnumOptionRetailerStoreType>}
     * @memberof GetEnumOptionsResponseRetailerStoreType
     */
    options?: Array<EnumOptionRetailerStoreType>;
}

/**
 * Check if a given object implements the GetEnumOptionsResponseRetailerStoreType interface.
 */
export function instanceOfGetEnumOptionsResponseRetailerStoreType(value: object): value is GetEnumOptionsResponseRetailerStoreType {
    return true;
}

export function GetEnumOptionsResponseRetailerStoreTypeFromJSON(json: any): GetEnumOptionsResponseRetailerStoreType {
    return GetEnumOptionsResponseRetailerStoreTypeFromJSONTyped(json, false);
}

export function GetEnumOptionsResponseRetailerStoreTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEnumOptionsResponseRetailerStoreType {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'] == null ? undefined : ((json['options'] as Array<any>).map(EnumOptionRetailerStoreTypeFromJSON)),
    };
}

export function GetEnumOptionsResponseRetailerStoreTypeToJSON(json: any): GetEnumOptionsResponseRetailerStoreType {
    return GetEnumOptionsResponseRetailerStoreTypeToJSONTyped(json, false);
}

export function GetEnumOptionsResponseRetailerStoreTypeToJSONTyped(value?: GetEnumOptionsResponseRetailerStoreType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': value['options'] == null ? undefined : ((value['options'] as Array<any>).map(EnumOptionRetailerStoreTypeToJSON)),
    };
}

