/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Duplicate products request
 * @export
 * @interface BulkDuplicateProductsRequest
 */
export interface BulkDuplicateProductsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkDuplicateProductsRequest
     */
    product_ids?: Array<string>;
}

/**
 * Check if a given object implements the BulkDuplicateProductsRequest interface.
 */
export function instanceOfBulkDuplicateProductsRequest(value: object): value is BulkDuplicateProductsRequest {
    return true;
}

export function BulkDuplicateProductsRequestFromJSON(json: any): BulkDuplicateProductsRequest {
    return BulkDuplicateProductsRequestFromJSONTyped(json, false);
}

export function BulkDuplicateProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkDuplicateProductsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
    };
}

export function BulkDuplicateProductsRequestToJSON(json: any): BulkDuplicateProductsRequest {
    return BulkDuplicateProductsRequestToJSONTyped(json, false);
}

export function BulkDuplicateProductsRequestToJSONTyped(value?: BulkDuplicateProductsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_ids': value['product_ids'],
    };
}

