<template>
  <div class="relative">
    <input
      v-model="localValue"
      class="search-input"
      :class="[inputClass, { active: localValue }]"
      :placeholder="placeholder"
      autocomplete="off"
    />

    <Icon
      v-if="localValue"
      icon-key="close"
      hover-type="OPACITY"
      class="close-container"
      :on-click="() => $emit('input', '')"
    />

    <Icon icon-key="search" class="search-icon-container" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    change: {
      type: Function,
      default: () => null
    },
    id: {
      type: String,
      default: undefined
    },
    inputClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    localValue() {
      this.change()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
.search-input {
  background: $color-grey-2;
  height: 40px;
  border-radius: 4px !important;
  padding: 0 16px 0 56px;
  border: none;
  width: 100%;
  font-family: NHaasGroteskDSPro-55Rg;
  font-size: 16px;

  &:hover {
    border: 1px solid $color-grey-4;
  }

  &.active,
  &:focus {
    border: 1px solid $color-black;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-black;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-black;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-black;
  }
}

.search-icon-container {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.close-container {
  position: absolute;
  top: 0;
  right: 8px;
}
</style>
