/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateViewComponentsRequest
 */
export interface UpdateViewComponentsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentsRequest
     */
    view_component_id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentsRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateViewComponentsRequest
     */
    rename_entity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentsRequest
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentsRequest
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentsRequest
     */
    component_type?: UpdateViewComponentsRequestComponentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewComponentsRequest
     */
    view_container_id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateViewComponentsRequest
     */
    ordinal?: number;
}


/**
 * @export
 */
export const UpdateViewComponentsRequestComponentTypeEnum = {
    Category: 'CATEGORY',
    Collection: 'COLLECTION',
    Brand: 'BRAND',
    Hero: 'HERO',
    PromoBanner: 'PROMO_BANNER',
    About: 'ABOUT'
} as const;
export type UpdateViewComponentsRequestComponentTypeEnum = typeof UpdateViewComponentsRequestComponentTypeEnum[keyof typeof UpdateViewComponentsRequestComponentTypeEnum];


/**
 * Check if a given object implements the UpdateViewComponentsRequest interface.
 */
export function instanceOfUpdateViewComponentsRequest(value: object): value is UpdateViewComponentsRequest {
    if (!('view_component_id' in value) || value['view_component_id'] === undefined) return false;
    return true;
}

export function UpdateViewComponentsRequestFromJSON(json: any): UpdateViewComponentsRequest {
    return UpdateViewComponentsRequestFromJSONTyped(json, false);
}

export function UpdateViewComponentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateViewComponentsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'view_component_id': json['view_component_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'rename_entity': json['rename_entity'] == null ? undefined : json['rename_entity'],
        'image_url': json['image_url'] == null ? undefined : json['image_url'],
        'entity_id': json['entity_id'] == null ? undefined : json['entity_id'],
        'component_type': json['component_type'] == null ? undefined : json['component_type'],
        'view_container_id': json['view_container_id'] == null ? undefined : json['view_container_id'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
    };
}

export function UpdateViewComponentsRequestToJSON(json: any): UpdateViewComponentsRequest {
    return UpdateViewComponentsRequestToJSONTyped(json, false);
}

export function UpdateViewComponentsRequestToJSONTyped(value?: UpdateViewComponentsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'view_component_id': value['view_component_id'],
        'name': value['name'],
        'rename_entity': value['rename_entity'],
        'image_url': value['image_url'],
        'entity_id': value['entity_id'],
        'component_type': value['component_type'],
        'view_container_id': value['view_container_id'],
        'ordinal': value['ordinal'],
    };
}

