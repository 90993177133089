/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddProductsToManualCollectionRequest,
  AutomatedCollectionConditionsResponse,
  CollectionInfo,
  CollectionResponse,
  CreateCollectionRequest,
  GetAllManualCollectionsResponse,
  GetCollectionProductsToExploreResponse,
  IdWrapper,
  SetHomeOrdinalRequest,
  UpdateCollectionRequest,
  UploadCollectionImageRequest,
} from '../models/index';
import {
    AddProductsToManualCollectionRequestFromJSON,
    AddProductsToManualCollectionRequestToJSON,
    AutomatedCollectionConditionsResponseFromJSON,
    AutomatedCollectionConditionsResponseToJSON,
    CollectionInfoFromJSON,
    CollectionInfoToJSON,
    CollectionResponseFromJSON,
    CollectionResponseToJSON,
    CreateCollectionRequestFromJSON,
    CreateCollectionRequestToJSON,
    GetAllManualCollectionsResponseFromJSON,
    GetAllManualCollectionsResponseToJSON,
    GetCollectionProductsToExploreResponseFromJSON,
    GetCollectionProductsToExploreResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    SetHomeOrdinalRequestFromJSON,
    SetHomeOrdinalRequestToJSON,
    UpdateCollectionRequestFromJSON,
    UpdateCollectionRequestToJSON,
    UploadCollectionImageRequestFromJSON,
    UploadCollectionImageRequestToJSON,
} from '../models/index';

export interface AddProductsToManualCollectionsRequest {
    addProductsToManualCollectionRequest: AddProductsToManualCollectionRequest;
}

export interface CreateCollectionOperationRequest {
    createCollectionRequest: CreateCollectionRequest;
}

export interface DeleteCollectionRequest {
    id: string;
}

export interface DeleteImageFromCollectionRequest {
    id: string;
}

export interface GetCollectionRequest {
    id: string;
}

export interface GetCollectionProductToExploreRequest {
    id: string;
    productsNumber?: number;
}

export interface GetManualCollectionsRequest {
    search?: string;
}

export interface SetHomeOrdinalOperationRequest {
    id: string;
    setHomeOrdinalRequest: SetHomeOrdinalRequest;
}

export interface UpdateCollectionOperationRequest {
    id: string;
    updateCollectionRequest: UpdateCollectionRequest;
}

export interface UploadCollectionImageOperationRequest {
    id: string;
    uploadCollectionImageRequest?: UploadCollectionImageRequest;
}

/**
 * 
 */
export class CollectionControllerApi extends runtime.BaseAPI {

    /**
     * Add new products to the manual collections
     */
    async addProductsToManualCollectionsRaw(requestParameters: AddProductsToManualCollectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addProductsToManualCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'addProductsToManualCollectionRequest',
                'Required parameter "addProductsToManualCollectionRequest" was null or undefined when calling addProductsToManualCollections().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/manual/add-products`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddProductsToManualCollectionRequestToJSON(requestParameters['addProductsToManualCollectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add new products to the manual collections
     */
    async addProductsToManualCollections(requestParameters: AddProductsToManualCollectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProductsToManualCollectionsRaw(requestParameters, initOverrides);
    }

    /**
     * Create collection
     */
    async createCollectionRaw(requestParameters: CreateCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'createCollectionRequest',
                'Required parameter "createCollectionRequest" was null or undefined when calling createCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCollectionRequestToJSON(requestParameters['createCollectionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create collection
     */
    async createCollection(requestParameters: CreateCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete collection
     */
    async deleteCollectionRaw(requestParameters: DeleteCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete collection
     */
    async deleteCollection(requestParameters: DeleteCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCollectionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete collection
     */
    async deleteImageFromCollectionRaw(requestParameters: DeleteImageFromCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteImageFromCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete collection
     */
    async deleteImageFromCollection(requestParameters: DeleteImageFromCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteImageFromCollectionRaw(requestParameters, initOverrides);
    }

    /**
     * Get all conditions for automated collections
     */
    async getAutomatedCollectionConditionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedCollectionConditionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/automated/conditions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutomatedCollectionConditionsResponseFromJSON(jsonValue));
    }

    /**
     * Get all conditions for automated collections
     */
    async getAutomatedCollectionConditions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedCollectionConditionsResponse> {
        const response = await this.getAutomatedCollectionConditionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get collection
     */
    async getCollectionRaw(requestParameters: GetCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionResponseFromJSON(jsonValue));
    }

    /**
     * Get collection
     */
    async getCollection(requestParameters: GetCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionResponse> {
        const response = await this.getCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get collection products
     */
    async getCollectionProductToExploreRaw(requestParameters: GetCollectionProductToExploreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCollectionProductsToExploreResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCollectionProductToExplore().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productsNumber'] != null) {
            queryParameters['products_number'] = requestParameters['productsNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}/products-to-explore`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCollectionProductsToExploreResponseFromJSON(jsonValue));
    }

    /**
     * Get collection products
     */
    async getCollectionProductToExplore(requestParameters: GetCollectionProductToExploreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCollectionProductsToExploreResponse> {
        const response = await this.getCollectionProductToExploreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all collections for home page
     */
    async getCollectionsForHomeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CollectionInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/home`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollectionInfoFromJSON));
    }

    /**
     * Get all collections for home page
     */
    async getCollectionsForHome(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CollectionInfo>> {
        const response = await this.getCollectionsForHomeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find featured collections
     */
    async getFeaturedCollectionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CollectionResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/featured/landing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollectionResponseFromJSON));
    }

    /**
     * Find featured collections
     */
    async getFeaturedCollections(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CollectionResponse>> {
        const response = await this.getFeaturedCollectionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get featured collections for home page
     */
    async getFeaturedOnHomeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/featured/home`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionResponseFromJSON(jsonValue));
    }

    /**
     * Get featured collections for home page
     */
    async getFeaturedOnHome(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionResponse> {
        const response = await this.getFeaturedOnHomeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all manual collections with search by title
     */
    async getManualCollectionsRaw(requestParameters: GetManualCollectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllManualCollectionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/manual`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllManualCollectionsResponseFromJSON(jsonValue));
    }

    /**
     * Get all manual collections with search by title
     */
    async getManualCollections(requestParameters: GetManualCollectionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllManualCollectionsResponse> {
        const response = await this.getManualCollectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add ordinal for collection on home page
     */
    async setHomeOrdinalRaw(requestParameters: SetHomeOrdinalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling setHomeOrdinal().'
            );
        }

        if (requestParameters['setHomeOrdinalRequest'] == null) {
            throw new runtime.RequiredError(
                'setHomeOrdinalRequest',
                'Required parameter "setHomeOrdinalRequest" was null or undefined when calling setHomeOrdinal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}/home-ordinal`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SetHomeOrdinalRequestToJSON(requestParameters['setHomeOrdinalRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add ordinal for collection on home page
     */
    async setHomeOrdinal(requestParameters: SetHomeOrdinalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setHomeOrdinalRaw(requestParameters, initOverrides);
    }

    /**
     * Update collection
     */
    async updateCollectionRaw(requestParameters: UpdateCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCollection().'
            );
        }

        if (requestParameters['updateCollectionRequest'] == null) {
            throw new runtime.RequiredError(
                'updateCollectionRequest',
                'Required parameter "updateCollectionRequest" was null or undefined when calling updateCollection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCollectionRequestToJSON(requestParameters['updateCollectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update collection
     */
    async updateCollection(requestParameters: UpdateCollectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCollectionRaw(requestParameters, initOverrides);
    }

    /**
     * Update products inside collections
     */
    async updateProductsInCollectionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/update-collections-products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update products inside collections
     */
    async updateProductsInCollections(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProductsInCollectionsRaw(initOverrides);
    }

    /**
     * Upload collection image
     */
    async uploadCollectionImageRaw(requestParameters: UploadCollectionImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadCollectionImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collections/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadCollectionImageRequestToJSON(requestParameters['uploadCollectionImageRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload collection image
     */
    async uploadCollectionImage(requestParameters: UploadCollectionImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadCollectionImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
