/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DraftProductRequest
 */
export interface DraftProductRequest {
    /**
     * 
     * @type {string}
     * @memberof DraftProductRequest
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof DraftProductRequest
     */
    title: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DraftProductRequest
     */
    category_ids: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DraftProductRequest
     */
    min_lead_time: number;
    /**
     * 
     * @type {number}
     * @memberof DraftProductRequest
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftProductRequest
     */
    unit_price: number;
    /**
     * 
     * @type {number}
     * @memberof DraftProductRequest
     */
    wholesale_price: number;
}

/**
 * Check if a given object implements the DraftProductRequest interface.
 */
export function instanceOfDraftProductRequest(value: object): value is DraftProductRequest {
    if (!('product_id' in value) || value['product_id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('category_ids' in value) || value['category_ids'] === undefined) return false;
    if (!('min_lead_time' in value) || value['min_lead_time'] === undefined) return false;
    if (!('unit_price' in value) || value['unit_price'] === undefined) return false;
    if (!('wholesale_price' in value) || value['wholesale_price'] === undefined) return false;
    return true;
}

export function DraftProductRequestFromJSON(json: any): DraftProductRequest {
    return DraftProductRequestFromJSONTyped(json, false);
}

export function DraftProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftProductRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'product_id': json['product_id'],
        'title': json['title'],
        'category_ids': json['category_ids'],
        'min_lead_time': json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'unit_price': json['unit_price'],
        'wholesale_price': json['wholesale_price'],
    };
}

export function DraftProductRequestToJSON(json: any): DraftProductRequest {
    return DraftProductRequestToJSONTyped(json, false);
}

export function DraftProductRequestToJSONTyped(value?: DraftProductRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_id': value['product_id'],
        'title': value['title'],
        'category_ids': value['category_ids'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'unit_price': value['unit_price'],
        'wholesale_price': value['wholesale_price'],
    };
}

