/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductVariantInventoryLevelRequest
 */
export interface ProductVariantInventoryLevelRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductVariantInventoryLevelRequest
     */
    variant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantInventoryLevelRequest
     */
    sku?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantInventoryLevelRequest
     */
    inventory_quantity?: number;
}

/**
 * Check if a given object implements the ProductVariantInventoryLevelRequest interface.
 */
export function instanceOfProductVariantInventoryLevelRequest(value: object): value is ProductVariantInventoryLevelRequest {
    return true;
}

export function ProductVariantInventoryLevelRequestFromJSON(json: any): ProductVariantInventoryLevelRequest {
    return ProductVariantInventoryLevelRequestFromJSONTyped(json, false);
}

export function ProductVariantInventoryLevelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductVariantInventoryLevelRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'inventory_quantity': json['inventory_quantity'] == null ? undefined : json['inventory_quantity'],
    };
}

export function ProductVariantInventoryLevelRequestToJSON(json: any): ProductVariantInventoryLevelRequest {
    return ProductVariantInventoryLevelRequestToJSONTyped(json, false);
}

export function ProductVariantInventoryLevelRequestToJSONTyped(value?: ProductVariantInventoryLevelRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'variant_id': value['variant_id'],
        'sku': value['sku'],
        'inventory_quantity': value['inventory_quantity'],
    };
}

