<template>
  <div class="chat-button" :class="{ 'opacity-05 disabled': disabled }">
    <Icon
      icon-key="chat"
      hover-type="ICON"
      :size="16"
      class="chat-icon-container"
      :on-click="(event) => $emit('click', event)"
    />
  </div>
</template>

<script lang="ts">
export default {
  props: ['disabled']
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.chat-button {
  background: $color-black;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
  .chat-icon-container {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
</style>
