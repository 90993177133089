import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

Vue.prototype.$trackingStore = new Vuex.Store({
  state: {
    count: 0,
    indexName: null,
    queryID: null,
    source: null
  },
  mutations: {
    increment(state) {
      state.count++
    },
    setIndexName(state, value) {
      state.indexName = value
    },
    setQueryId(state, value) {
      state.queryID = value
    },
    setSource(state, value) {
      state.source = value
    }
  }
})
