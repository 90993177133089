/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandInfoResponse
 */
export interface BrandInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandInfoResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandInfoResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandInfoResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandInfoResponse
     */
    based_in?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandInfoResponse
     */
    is_published?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BrandInfoResponse
     */
    minimum_order_value?: number;
}

/**
 * Check if a given object implements the BrandInfoResponse interface.
 */
export function instanceOfBrandInfoResponse(value: object): value is BrandInfoResponse {
    return true;
}

export function BrandInfoResponseFromJSON(json: any): BrandInfoResponse {
    return BrandInfoResponseFromJSONTyped(json, false);
}

export function BrandInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'is_published': json['is_published'] == null ? undefined : json['is_published'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
    };
}

export function BrandInfoResponseToJSON(json: any): BrandInfoResponse {
    return BrandInfoResponseToJSONTyped(json, false);
}

export function BrandInfoResponseToJSONTyped(value?: BrandInfoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'vanity_url_name': value['vanity_url_name'],
        'brand_name': value['brand_name'],
        'based_in': value['based_in'],
        'is_published': value['is_published'],
        'minimum_order_value': value['minimum_order_value'],
    };
}

