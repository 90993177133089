/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomAssortmentsResponse
 */
export interface CustomAssortmentsResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentsResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentsResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentsResponse
     */
    created_at?: string;
}

/**
 * Check if a given object implements the CustomAssortmentsResponse interface.
 */
export function instanceOfCustomAssortmentsResponse(value: object): value is CustomAssortmentsResponse {
    return true;
}

export function CustomAssortmentsResponseFromJSON(json: any): CustomAssortmentsResponse {
    return CustomAssortmentsResponseFromJSONTyped(json, false);
}

export function CustomAssortmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAssortmentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'created_at': json['created_at'] == null ? undefined : json['created_at'],
    };
}

export function CustomAssortmentsResponseToJSON(json: any): CustomAssortmentsResponse {
    return CustomAssortmentsResponseToJSONTyped(json, false);
}

export function CustomAssortmentsResponseToJSONTyped(value?: CustomAssortmentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'created_at': value['created_at'],
    };
}

