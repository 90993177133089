/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuborderBrandResponse
 */
export interface SuborderBrandResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderBrandResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderBrandResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderBrandResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderBrandResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderBrandResponse
     */
    last_name?: string;
}

/**
 * Check if a given object implements the SuborderBrandResponse interface.
 */
export function instanceOfSuborderBrandResponse(value: object): value is SuborderBrandResponse {
    return true;
}

export function SuborderBrandResponseFromJSON(json: any): SuborderBrandResponse {
    return SuborderBrandResponseFromJSONTyped(json, false);
}

export function SuborderBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'name': json['name'] == null ? undefined : json['name'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
    };
}

export function SuborderBrandResponseToJSON(json: any): SuborderBrandResponse {
    return SuborderBrandResponseToJSONTyped(json, false);
}

export function SuborderBrandResponseToJSONTyped(value?: SuborderBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'name': value['name'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
    };
}

