/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Share Brand Board object
 * @export
 * @interface ShareBrandBoardRequest
 */
export interface ShareBrandBoardRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareBrandBoardRequest
     */
    emails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShareBrandBoardRequest
     */
    role?: ShareBrandBoardRequestRoleEnum;
}


/**
 * @export
 */
export const ShareBrandBoardRequestRoleEnum = {
    Creator: 'CREATOR',
    Viewer: 'VIEWER'
} as const;
export type ShareBrandBoardRequestRoleEnum = typeof ShareBrandBoardRequestRoleEnum[keyof typeof ShareBrandBoardRequestRoleEnum];


/**
 * Check if a given object implements the ShareBrandBoardRequest interface.
 */
export function instanceOfShareBrandBoardRequest(value: object): value is ShareBrandBoardRequest {
    if (!('emails' in value) || value['emails'] === undefined) return false;
    return true;
}

export function ShareBrandBoardRequestFromJSON(json: any): ShareBrandBoardRequest {
    return ShareBrandBoardRequestFromJSONTyped(json, false);
}

export function ShareBrandBoardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareBrandBoardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'emails': json['emails'],
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function ShareBrandBoardRequestToJSON(json: any): ShareBrandBoardRequest {
    return ShareBrandBoardRequestToJSONTyped(json, false);
}

export function ShareBrandBoardRequestToJSONTyped(value?: ShareBrandBoardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'emails': value['emails'],
        'role': value['role'],
    };
}

