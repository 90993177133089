/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DropdownItem
 */
export interface DropdownItem {
    /**
     * 
     * @type {string}
     * @memberof DropdownItem
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof DropdownItem
     */
    label?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DropdownItem
     */
    active?: boolean;
}

/**
 * Check if a given object implements the DropdownItem interface.
 */
export function instanceOfDropdownItem(value: object): value is DropdownItem {
    return true;
}

export function DropdownItemFromJSON(json: any): DropdownItem {
    return DropdownItemFromJSONTyped(json, false);
}

export function DropdownItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): DropdownItem {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'label': json['label'] == null ? undefined : json['label'],
        'active': json['active'] == null ? undefined : json['active'],
    };
}

export function DropdownItemToJSON(json: any): DropdownItem {
    return DropdownItemToJSONTyped(json, false);
}

export function DropdownItemToJSONTyped(value?: DropdownItem | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'label': value['label'],
        'active': value['active'],
    };
}

