/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDraftBrandRequest
 */
export interface UpdateDraftBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    draft_brand_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    user_id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDraftBrandRequest
     */
    onboarding_step?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    admin_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    admin_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    account_email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    company_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    owner_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    owner_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    instagram_account_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    instagram_follower_count?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDraftBrandRequest
     */
    values?: Array<UpdateDraftBrandRequestValuesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDraftBrandRequest
     */
    product_categories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    years_in_business?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    profile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    additional_photography_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateDraftBrandRequest
     */
    address?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateDraftBrandRequest
     */
    fulfillment_address?: object;
    /**
     * 
     * @type {number}
     * @memberof UpdateDraftBrandRequest
     */
    minimum_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDraftBrandRequest
     */
    maximum_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDraftBrandRequest
     */
    minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDraftBrandRequest
     */
    reorder_minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDraftBrandRequest
     */
    minimum_order_quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    product_building_line_sheet_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDraftBrandRequest
     */
    product_building_notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDraftBrandRequest
     */
    buy_on_bulletin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDraftBrandRequest
     */
    connect_with_shopify?: boolean;
}


/**
 * @export
 */
export const UpdateDraftBrandRequestValuesEnum = {
    FemaleFounded: 'FEMALE_FOUNDED',
    EthicalProduction: 'ETHICAL_PRODUCTION',
    CleanIngredients: 'CLEAN_INGREDIENTS',
    Sustainable: 'SUSTAINABLE',
    Handmade: 'HANDMADE',
    MadeInTheUs: 'MADE_IN_THE_US',
    CharitableBrandGiveBack: 'CHARITABLE_BRAND_GIVE_BACK',
    Organic: 'ORGANIC',
    ZeroWaste: 'ZERO_WASTE',
    BlackOwned: 'BLACK_OWNED',
    LatinxOwned: 'LATINX_OWNED',
    AapiOwned: 'AAPI_OWNED',
    IndigenousOwned: 'INDIGENOUS_OWNED',
    LgbtqOwned: 'LGBTQ_OWNED',
    MinorityOwnedBusiness: 'MINORITY_OWNED_BUSINESS',
    DisabilityOwned: 'DISABILITY_OWNED'
} as const;
export type UpdateDraftBrandRequestValuesEnum = typeof UpdateDraftBrandRequestValuesEnum[keyof typeof UpdateDraftBrandRequestValuesEnum];


/**
 * Check if a given object implements the UpdateDraftBrandRequest interface.
 */
export function instanceOfUpdateDraftBrandRequest(value: object): value is UpdateDraftBrandRequest {
    return true;
}

export function UpdateDraftBrandRequestFromJSON(json: any): UpdateDraftBrandRequest {
    return UpdateDraftBrandRequestFromJSONTyped(json, false);
}

export function UpdateDraftBrandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDraftBrandRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'draft_brand_id': json['draft_brand_id'] == null ? undefined : json['draft_brand_id'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
        'onboarding_step': json['onboarding_step'] == null ? undefined : json['onboarding_step'],
        'admin_first_name': json['admin_first_name'] == null ? undefined : json['admin_first_name'],
        'admin_last_name': json['admin_last_name'] == null ? undefined : json['admin_last_name'],
        'account_email': json['account_email'] == null ? undefined : json['account_email'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'company_name': json['company_name'] == null ? undefined : json['company_name'],
        'website_url': json['website_url'] == null ? undefined : json['website_url'],
        'owner_first_name': json['owner_first_name'] == null ? undefined : json['owner_first_name'],
        'owner_last_name': json['owner_last_name'] == null ? undefined : json['owner_last_name'],
        'instagram_account_name': json['instagram_account_name'] == null ? undefined : json['instagram_account_name'],
        'instagram_follower_count': json['instagram_follower_count'] == null ? undefined : json['instagram_follower_count'],
        'values': json['values'] == null ? undefined : json['values'],
        'product_categories': json['product_categories'] == null ? undefined : json['product_categories'],
        'years_in_business': json['years_in_business'] == null ? undefined : json['years_in_business'],
        'profile_image_url': json['profile_image_url'] == null ? undefined : json['profile_image_url'],
        'additional_photography_url': json['additional_photography_url'] == null ? undefined : json['additional_photography_url'],
        'description': json['description'] == null ? undefined : json['description'],
        'address': json['address'] == null ? undefined : json['address'],
        'fulfillment_address': json['fulfillment_address'] == null ? undefined : json['fulfillment_address'],
        'minimum_lead_time': json['minimum_lead_time'] == null ? undefined : json['minimum_lead_time'],
        'maximum_lead_time': json['maximum_lead_time'] == null ? undefined : json['maximum_lead_time'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
        'reorder_minimum_order_value': json['reorder_minimum_order_value'] == null ? undefined : json['reorder_minimum_order_value'],
        'minimum_order_quantity': json['minimum_order_quantity'] == null ? undefined : json['minimum_order_quantity'],
        'product_building_line_sheet_url': json['product_building_line_sheet_url'] == null ? undefined : json['product_building_line_sheet_url'],
        'product_building_notes': json['product_building_notes'] == null ? undefined : json['product_building_notes'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
        'connect_with_shopify': json['connect_with_shopify'] == null ? undefined : json['connect_with_shopify'],
    };
}

export function UpdateDraftBrandRequestToJSON(json: any): UpdateDraftBrandRequest {
    return UpdateDraftBrandRequestToJSONTyped(json, false);
}

export function UpdateDraftBrandRequestToJSONTyped(value?: UpdateDraftBrandRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'draft_brand_id': value['draft_brand_id'],
        'user_id': value['user_id'],
        'onboarding_step': value['onboarding_step'],
        'admin_first_name': value['admin_first_name'],
        'admin_last_name': value['admin_last_name'],
        'account_email': value['account_email'],
        'phone_number': value['phone_number'],
        'company_name': value['company_name'],
        'website_url': value['website_url'],
        'owner_first_name': value['owner_first_name'],
        'owner_last_name': value['owner_last_name'],
        'instagram_account_name': value['instagram_account_name'],
        'instagram_follower_count': value['instagram_follower_count'],
        'values': value['values'],
        'product_categories': value['product_categories'],
        'years_in_business': value['years_in_business'],
        'profile_image_url': value['profile_image_url'],
        'additional_photography_url': value['additional_photography_url'],
        'description': value['description'],
        'address': value['address'],
        'fulfillment_address': value['fulfillment_address'],
        'minimum_lead_time': value['minimum_lead_time'],
        'maximum_lead_time': value['maximum_lead_time'],
        'minimum_order_value': value['minimum_order_value'],
        'reorder_minimum_order_value': value['reorder_minimum_order_value'],
        'minimum_order_quantity': value['minimum_order_quantity'],
        'product_building_line_sheet_url': value['product_building_line_sheet_url'],
        'product_building_notes': value['product_building_notes'],
        'buy_on_bulletin': value['buy_on_bulletin'],
        'connect_with_shopify': value['connect_with_shopify'],
    };
}

