/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandReorderResponse } from './BrandReorderResponse';
import {
    BrandReorderResponseFromJSON,
    BrandReorderResponseFromJSONTyped,
    BrandReorderResponseToJSON,
    BrandReorderResponseToJSONTyped,
} from './BrandReorderResponse';
import type { SuborderFromReorderResponse } from './SuborderFromReorderResponse';
import {
    SuborderFromReorderResponseFromJSON,
    SuborderFromReorderResponseFromJSONTyped,
    SuborderFromReorderResponseToJSON,
    SuborderFromReorderResponseToJSONTyped,
} from './SuborderFromReorderResponse';

/**
 * 
 * @export
 * @interface ReorderResponse
 */
export interface ReorderResponse {
    /**
     * 
     * @type {BrandReorderResponse}
     * @memberof ReorderResponse
     */
    brand?: BrandReorderResponse;
    /**
     * 
     * @type {SuborderFromReorderResponse}
     * @memberof ReorderResponse
     */
    suborder?: SuborderFromReorderResponse;
}

/**
 * Check if a given object implements the ReorderResponse interface.
 */
export function instanceOfReorderResponse(value: object): value is ReorderResponse {
    return true;
}

export function ReorderResponseFromJSON(json: any): ReorderResponse {
    return ReorderResponseFromJSONTyped(json, false);
}

export function ReorderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReorderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'brand': json['brand'] == null ? undefined : BrandReorderResponseFromJSON(json['brand']),
        'suborder': json['suborder'] == null ? undefined : SuborderFromReorderResponseFromJSON(json['suborder']),
    };
}

export function ReorderResponseToJSON(json: any): ReorderResponse {
    return ReorderResponseToJSONTyped(json, false);
}

export function ReorderResponseToJSONTyped(value?: ReorderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand': BrandReorderResponseToJSON(value['brand']),
        'suborder': SuborderFromReorderResponseToJSON(value['suborder']),
    };
}

