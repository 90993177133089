/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressPartialResponse
 */
export interface AddressPartialResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    address_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    zip_code?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressPartialResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressPartialResponse
     */
    default_address?: boolean;
}

/**
 * Check if a given object implements the AddressPartialResponse interface.
 */
export function instanceOfAddressPartialResponse(value: object): value is AddressPartialResponse {
    return true;
}

export function AddressPartialResponseFromJSON(json: any): AddressPartialResponse {
    return AddressPartialResponseFromJSONTyped(json, false);
}

export function AddressPartialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressPartialResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'address_id': json['address_id'] == null ? undefined : json['address_id'],
        'zip_code': json['zip_code'] == null ? undefined : json['zip_code'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'street': json['street'] == null ? undefined : json['street'],
        'company': json['company'] == null ? undefined : json['company'],
        'city': json['city'] == null ? undefined : json['city'],
        'apartment': json['apartment'] == null ? undefined : json['apartment'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': json['country'] == null ? undefined : json['country'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'default_address': json['default_address'] == null ? undefined : json['default_address'],
    };
}

export function AddressPartialResponseToJSON(json: any): AddressPartialResponse {
    return AddressPartialResponseToJSONTyped(json, false);
}

export function AddressPartialResponseToJSONTyped(value?: AddressPartialResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'address_id': value['address_id'],
        'zip_code': value['zip_code'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'street': value['street'],
        'company': value['company'],
        'city': value['city'],
        'apartment': value['apartment'],
        'phone': value['phone'],
        'state': value['state'],
        'country': value['country'],
        'retailer_id': value['retailer_id'],
        'default_address': value['default_address'],
    };
}

