import restApi from '../../http.js'
import session from '../session.js'

export default class Api {
  getSectionsForActiveTradeShow(eventId) {
    return restApi({
      method: 'GET',
      url: `/trade-shows/${eventId}/sections`
    })
  }

  getActiveTradeShow() {
    return restApi({
      method: 'GET',
      url: `/trade-shows/active`
    })
  }

  getTradeShowExhibitorsExport(eventID) {
    return restApi({
      method: 'POST',
      data: { exhibitor_ids: [] },
      url: `/trade-shows/${eventID}/exhibitors-export`,
      headers: {
        Accept: 'text/csv'
      }
    })
  }

  getTradeShowExhibitorsExportWithFilters(eventID, exhibitorsIdListObj) {
    return restApi({
      method: 'POST',
      data: exhibitorsIdListObj,
      url: `/trade-shows/${eventID}/exhibitors-export`,
      headers: {
        Accept: 'text/csv'
      }
    })
  }

  getCountriesForBuyerDirectory(eventId) {
    return restApi({
      method: 'GET',
      url: `/trade-shows/${eventId}/countries`
    })
  }

  getBuyers(eventId, { page, size, sort_order, country, location, search, sections }) {
    const query = () => {
      const qParamPage = page - 1
      let qParams = `?page=${qParamPage}&size=${size}&sort_order=${sort_order}`
      qParams += `&country=${country && country.length > 0 ? country : null}`
      qParams += `&state=${location && location.length > 0 ? location : null}`
      if (sections && sections.length > 0) {
        const encodedSections = sections.map((section) => encodeURIComponent(section)).join(',')
        qParams += `&sections=${encodedSections}`
      } else {
        qParams += `&sections=null`
      }
      if (search) {
        qParams += `&search=${search}`
      }
      return qParams
    }
    const qParams = query()
    return restApi({
      method: 'GET',
      url: `/trade-shows/${eventId}/buyer-directory${qParams}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getRecommendedBrands(eventId, brandId) {
    return restApi({
      method: 'GET',
      url: `/trade-shows/${eventId}/brand/${brandId}/recommended-brands`
    })
  }
}
