/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Apply to sell object
 * @export
 * @interface ApplyToSellRequest
 */
export interface ApplyToSellRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    brand_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    website_url: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    years_in_business: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    phone_number: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    instagram?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplyToSellRequest
     */
    ships_from_usa_or_canada?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    country_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplyToSellRequest
     */
    marketing_opt_in?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    utm_medium?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    utm_source?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    utm_campaign?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    utm_content?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellRequest
     */
    utm_term?: string;
}

/**
 * Check if a given object implements the ApplyToSellRequest interface.
 */
export function instanceOfApplyToSellRequest(value: object): value is ApplyToSellRequest {
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('brand_name' in value) || value['brand_name'] === undefined) return false;
    if (!('website_url' in value) || value['website_url'] === undefined) return false;
    if (!('years_in_business' in value) || value['years_in_business'] === undefined) return false;
    if (!('phone_number' in value) || value['phone_number'] === undefined) return false;
    return true;
}

export function ApplyToSellRequestFromJSON(json: any): ApplyToSellRequest {
    return ApplyToSellRequestFromJSONTyped(json, false);
}

export function ApplyToSellRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyToSellRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': json['email'],
        'brand_name': json['brand_name'],
        'website_url': json['website_url'],
        'years_in_business': json['years_in_business'],
        'phone_number': json['phone_number'],
        'instagram': json['instagram'] == null ? undefined : json['instagram'],
        'ships_from_usa_or_canada': json['ships_from_usa_or_canada'] == null ? undefined : json['ships_from_usa_or_canada'],
        'country_id': json['country_id'] == null ? undefined : json['country_id'],
        'marketing_opt_in': json['marketing_opt_in'] == null ? undefined : json['marketing_opt_in'],
        'utm_medium': json['utm_medium'] == null ? undefined : json['utm_medium'],
        'utm_source': json['utm_source'] == null ? undefined : json['utm_source'],
        'utm_campaign': json['utm_campaign'] == null ? undefined : json['utm_campaign'],
        'utm_content': json['utm_content'] == null ? undefined : json['utm_content'],
        'utm_term': json['utm_term'] == null ? undefined : json['utm_term'],
    };
}

export function ApplyToSellRequestToJSON(json: any): ApplyToSellRequest {
    return ApplyToSellRequestToJSONTyped(json, false);
}

export function ApplyToSellRequestToJSONTyped(value?: ApplyToSellRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'brand_name': value['brand_name'],
        'website_url': value['website_url'],
        'years_in_business': value['years_in_business'],
        'phone_number': value['phone_number'],
        'instagram': value['instagram'],
        'ships_from_usa_or_canada': value['ships_from_usa_or_canada'],
        'country_id': value['country_id'],
        'marketing_opt_in': value['marketing_opt_in'],
        'utm_medium': value['utm_medium'],
        'utm_source': value['utm_source'],
        'utm_campaign': value['utm_campaign'],
        'utm_content': value['utm_content'],
        'utm_term': value['utm_term'],
    };
}

