import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'userState',
      canSendMessage: 'canSendMessage'
    }),
    isRetailerApproved() {
      return this.$store.getters.isRetailerApproved
    }
  }
}
