/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PromoCodeResponse } from './PromoCodeResponse';
import {
    PromoCodeResponseFromJSON,
    PromoCodeResponseFromJSONTyped,
    PromoCodeResponseToJSON,
    PromoCodeResponseToJSONTyped,
} from './PromoCodeResponse';

/**
 * 
 * @export
 * @interface BrandActivePromoCodesResponse
 */
export interface BrandActivePromoCodesResponse {
    /**
     * 
     * @type {Array<PromoCodeResponse>}
     * @memberof BrandActivePromoCodesResponse
     */
    promo_codes?: Array<PromoCodeResponse>;
}

/**
 * Check if a given object implements the BrandActivePromoCodesResponse interface.
 */
export function instanceOfBrandActivePromoCodesResponse(value: object): value is BrandActivePromoCodesResponse {
    return true;
}

export function BrandActivePromoCodesResponseFromJSON(json: any): BrandActivePromoCodesResponse {
    return BrandActivePromoCodesResponseFromJSONTyped(json, false);
}

export function BrandActivePromoCodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandActivePromoCodesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'promo_codes': json['promo_codes'] == null ? undefined : ((json['promo_codes'] as Array<any>).map(PromoCodeResponseFromJSON)),
    };
}

export function BrandActivePromoCodesResponseToJSON(json: any): BrandActivePromoCodesResponse {
    return BrandActivePromoCodesResponseToJSONTyped(json, false);
}

export function BrandActivePromoCodesResponseToJSONTyped(value?: BrandActivePromoCodesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'promo_codes': value['promo_codes'] == null ? undefined : ((value['promo_codes'] as Array<any>).map(PromoCodeResponseToJSON)),
    };
}

