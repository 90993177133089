<template>
  <span>
    <p v-for="(value, name) in characteristicOptionMap" :key="`${value}${name}`" class="font-sans">
      <span class="capitalize">{{ name }}: </span>{{ $utils.displayCharacteristicOption(name, value) }}
    </p>
  </span>
</template>

<script>
export default {
  props: {
    characteristicOptionMap: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>
