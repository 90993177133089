/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAllDraftProductStatusResponse,
  AddAllDraftProductsRequest,
  AddDraftProductRequest,
  BulkDeleteProductsRequest,
  BulkDuplicateProductsRequest,
  BulkDuplicateProductsResponse,
  BulkSaveDraftProductRequest,
  BulkUpdateDraftProductsRequest,
  BulkUpdateProductsRequest,
  CollectionResponse,
  CountBrandProductsResponse,
  CountDraftProductsResponse,
  CreateDuplicateProductRequest,
  CreateProductRequest,
  CreateVideoRequest,
  DeleteBulkProductRequest,
  FavoriteProductsCountResponse,
  FilterProductRequest,
  FilteredProductResponse,
  GetInventoryLevelsResponse,
  GetProductFilteredResponse,
  GetProductResponse,
  GetProductsByCategoryResponse,
  IdWrapper,
  ImportProductsFromCsvRequest,
  ImportProductsNavigationRequest,
  LinkRetailersWithTradeShowRequest,
  PaginatedProductsResponse,
  ProductBuilderCreateProductRequest,
  ProductBuilderUpdateProductRequest,
  ProductWithoutImageResponse,
  UpdateInventoryLevelResponse,
  UpdateInventoryTrackingResponse,
  UpdateProductImageCropInfoRequest,
  UpdateProductInventoryLevelRequest,
  UpdateProductRequest,
  UpdateProductsInventoryLevelsRequest,
  UploadImageResponse,
  UploadProductImageRequest,
  UploadVideoUrlResponse,
  ValidateSKURequest,
  ValidateSKUResponse,
} from '../models/index';
import {
    AddAllDraftProductStatusResponseFromJSON,
    AddAllDraftProductStatusResponseToJSON,
    AddAllDraftProductsRequestFromJSON,
    AddAllDraftProductsRequestToJSON,
    AddDraftProductRequestFromJSON,
    AddDraftProductRequestToJSON,
    BulkDeleteProductsRequestFromJSON,
    BulkDeleteProductsRequestToJSON,
    BulkDuplicateProductsRequestFromJSON,
    BulkDuplicateProductsRequestToJSON,
    BulkDuplicateProductsResponseFromJSON,
    BulkDuplicateProductsResponseToJSON,
    BulkSaveDraftProductRequestFromJSON,
    BulkSaveDraftProductRequestToJSON,
    BulkUpdateDraftProductsRequestFromJSON,
    BulkUpdateDraftProductsRequestToJSON,
    BulkUpdateProductsRequestFromJSON,
    BulkUpdateProductsRequestToJSON,
    CollectionResponseFromJSON,
    CollectionResponseToJSON,
    CountBrandProductsResponseFromJSON,
    CountBrandProductsResponseToJSON,
    CountDraftProductsResponseFromJSON,
    CountDraftProductsResponseToJSON,
    CreateDuplicateProductRequestFromJSON,
    CreateDuplicateProductRequestToJSON,
    CreateProductRequestFromJSON,
    CreateProductRequestToJSON,
    CreateVideoRequestFromJSON,
    CreateVideoRequestToJSON,
    DeleteBulkProductRequestFromJSON,
    DeleteBulkProductRequestToJSON,
    FavoriteProductsCountResponseFromJSON,
    FavoriteProductsCountResponseToJSON,
    FilterProductRequestFromJSON,
    FilterProductRequestToJSON,
    FilteredProductResponseFromJSON,
    FilteredProductResponseToJSON,
    GetInventoryLevelsResponseFromJSON,
    GetInventoryLevelsResponseToJSON,
    GetProductFilteredResponseFromJSON,
    GetProductFilteredResponseToJSON,
    GetProductResponseFromJSON,
    GetProductResponseToJSON,
    GetProductsByCategoryResponseFromJSON,
    GetProductsByCategoryResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ImportProductsFromCsvRequestFromJSON,
    ImportProductsFromCsvRequestToJSON,
    ImportProductsNavigationRequestFromJSON,
    ImportProductsNavigationRequestToJSON,
    LinkRetailersWithTradeShowRequestFromJSON,
    LinkRetailersWithTradeShowRequestToJSON,
    PaginatedProductsResponseFromJSON,
    PaginatedProductsResponseToJSON,
    ProductBuilderCreateProductRequestFromJSON,
    ProductBuilderCreateProductRequestToJSON,
    ProductBuilderUpdateProductRequestFromJSON,
    ProductBuilderUpdateProductRequestToJSON,
    ProductWithoutImageResponseFromJSON,
    ProductWithoutImageResponseToJSON,
    UpdateInventoryLevelResponseFromJSON,
    UpdateInventoryLevelResponseToJSON,
    UpdateInventoryTrackingResponseFromJSON,
    UpdateInventoryTrackingResponseToJSON,
    UpdateProductImageCropInfoRequestFromJSON,
    UpdateProductImageCropInfoRequestToJSON,
    UpdateProductInventoryLevelRequestFromJSON,
    UpdateProductInventoryLevelRequestToJSON,
    UpdateProductRequestFromJSON,
    UpdateProductRequestToJSON,
    UpdateProductsInventoryLevelsRequestFromJSON,
    UpdateProductsInventoryLevelsRequestToJSON,
    UploadImageResponseFromJSON,
    UploadImageResponseToJSON,
    UploadProductImageRequestFromJSON,
    UploadProductImageRequestToJSON,
    UploadVideoUrlResponseFromJSON,
    UploadVideoUrlResponseToJSON,
    ValidateSKURequestFromJSON,
    ValidateSKURequestToJSON,
    ValidateSKUResponseFromJSON,
    ValidateSKUResponseToJSON,
} from '../models/index';

export interface ActivateDraftProductRequest {
    id: string;
    addDraftProductRequest: AddDraftProductRequest;
}

export interface AddAllDraftProductsOperationRequest {
    addAllDraftProductsRequest: AddAllDraftProductsRequest;
}

export interface ApproveProductRequest {
    id: string;
}

export interface AssignImageToVariantRequest {
    variantId: string;
    imageId: string;
}

export interface BrandImportProductsFromCsvRequest {
    linkRetailersWithTradeShowRequest?: LinkRetailersWithTradeShowRequest;
}

export interface BulkDeleteProductRequest {
    bulkDeleteProductsRequest: BulkDeleteProductsRequest;
}

export interface BulkSaveDraftProductOperationRequest {
    bulkSaveDraftProductRequest: BulkSaveDraftProductRequest;
}

export interface BulkUpdateDraftProductsOperationRequest {
    bulkUpdateDraftProductsRequest: BulkUpdateDraftProductsRequest;
}

export interface BulkUpdateProductsOperationRequest {
    bulkUpdateProductsRequest: BulkUpdateProductsRequest;
}

export interface ChangePriorityRequest {
    id: string;
}

export interface ChangeVisibilityRequest {
    id: string;
    active: boolean;
}

export interface CreateDuplicateProductOperationRequest {
    id: string;
    createDuplicateProductRequest: CreateDuplicateProductRequest;
}

export interface CreateProductOperationRequest {
    createProductRequest: CreateProductRequest;
}

export interface CreateProductVideoRequest {
    id: string;
    createVideoRequest: CreateVideoRequest;
}

export interface CreateProductVideoUrlRequest {
    id: string;
}

export interface DeleteBulkProductOperationRequest {
    deleteBulkProductRequest: DeleteBulkProductRequest;
}

export interface DeleteDraftProductRequest {
    id: string;
}

export interface DeleteImageFromVariantRequest {
    variantId: string;
}

export interface DeleteProductRequest {
    id: string;
}

export interface DeleteProductImageRequest {
    imageId: string;
}

export interface DuplicateBulkProductsRequest {
    bulkDuplicateProductsRequest: BulkDuplicateProductsRequest;
}

export interface FavoritizeProductRequest {
    id: string;
}

export interface FilterProductsBySearchCriteriaRequest {
    filterProductRequest: FilterProductRequest;
}

export interface GetAllDraftProductsForBrandRequest {
    brandId: string;
    page: number;
    size: number;
}

export interface GetInventoryLevelsRequest {
    page: number;
    size: number;
    order: string;
    keyword?: string;
    inStock?: boolean;
}

export interface GetProductRequest {
    id: string;
    checkAccess?: boolean;
}

export interface GetProductsRequest {
    brandId?: string;
    page?: number;
    size?: number;
    order?: string;
    status?: string;
    categoryId?: string;
    createdByAdmin?: boolean;
    active?: boolean;
    name?: string;
}

export interface GetProductsByCategoryRequest {
    categoryId: string;
}

export interface GetProductsByCollectionRequest {
    collectionId: string;
    page?: number;
    limit?: number;
}

export interface GetProductsFilteredRequest {
    productId: string;
    brandId: string;
    productNumber: number;
}

export interface ImportProductsFromCsvOperationRequest {
    importProductsFromCsvRequest?: ImportProductsFromCsvRequest;
}

export interface ImportProductsFromCsvV2Request {
    csvType: string;
    brandId: string;
    createDraft?: boolean;
    linkRetailersWithTradeShowRequest?: LinkRetailersWithTradeShowRequest;
}

export interface ImportProductsNavigationOperationRequest {
    importProductsNavigationRequest: Array<ImportProductsNavigationRequest>;
}

export interface MarkProductAsActiveRequest {
    id: string;
}

export interface MarkProductAsVisibleRequest {
    id: string;
}

export interface ProductBuilderCreateProductOperationRequest {
    productBuilderCreateProductRequest: ProductBuilderCreateProductRequest;
}

export interface ProductBuilderUpdateProductOperationRequest {
    id: string;
    productBuilderUpdateProductRequest: ProductBuilderUpdateProductRequest;
}

export interface RejectProductRequest {
    id: string;
}

export interface SendDraftProductReminderRequest {
    brandId: string;
    body: string;
}

export interface UpdateInventoryTrackingFromCsvRequest {
    linkRetailersWithTradeShowRequest?: LinkRetailersWithTradeShowRequest;
}

export interface UpdateProductOperationRequest {
    id: string;
    updateProductRequest: UpdateProductRequest;
}

export interface UpdateProductCategoriesRequest {
    linkRetailersWithTradeShowRequest?: LinkRetailersWithTradeShowRequest;
}

export interface UpdateProductImageCropInfoOperationRequest {
    productId: string;
    imageId: string;
    updateProductImageCropInfoRequest: UpdateProductImageCropInfoRequest;
}

export interface UpdateProductInventoryLevelOperationRequest {
    id: string;
    updateProductInventoryLevelRequest: UpdateProductInventoryLevelRequest;
}

export interface UpdateProductsInventoryLevelsOperationRequest {
    updateProductsInventoryLevelsRequest: UpdateProductsInventoryLevelsRequest;
}

export interface UploadProductImageOperationRequest {
    id: string;
    ordinal: number;
    isCustomAssortment: boolean;
    uploadProductImageRequest: UploadProductImageRequest;
}

export interface ValidateSKUOperationRequest {
    validateSKURequest: ValidateSKURequest;
}

/**
 * 
 */
export class ProductControllerApi extends runtime.BaseAPI {

    /**
     */
    async activateDraftProductRaw(requestParameters: ActivateDraftProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateDraftProduct().'
            );
        }

        if (requestParameters['addDraftProductRequest'] == null) {
            throw new runtime.RequiredError(
                'addDraftProductRequest',
                'Required parameter "addDraftProductRequest" was null or undefined when calling activateDraftProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddDraftProductRequestToJSON(requestParameters['addDraftProductRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateDraftProduct(requestParameters: ActivateDraftProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateDraftProductRaw(requestParameters, initOverrides);
    }

    /**
     * Add all draft products
     */
    async addAllDraftProductsRaw(requestParameters: AddAllDraftProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddAllDraftProductStatusResponse>> {
        if (requestParameters['addAllDraftProductsRequest'] == null) {
            throw new runtime.RequiredError(
                'addAllDraftProductsRequest',
                'Required parameter "addAllDraftProductsRequest" was null or undefined when calling addAllDraftProducts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/add-all-draft`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddAllDraftProductsRequestToJSON(requestParameters['addAllDraftProductsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddAllDraftProductStatusResponseFromJSON(jsonValue));
    }

    /**
     * Add all draft products
     */
    async addAllDraftProducts(requestParameters: AddAllDraftProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddAllDraftProductStatusResponse> {
        const response = await this.addAllDraftProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve product
     */
    async approveProductRaw(requestParameters: ApproveProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approveProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve product
     */
    async approveProduct(requestParameters: ApproveProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveProductRaw(requestParameters, initOverrides);
    }

    /**
     * Assign product image to variant
     */
    async assignImageToVariantRaw(requestParameters: AssignImageToVariantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['variantId'] == null) {
            throw new runtime.RequiredError(
                'variantId',
                'Required parameter "variantId" was null or undefined when calling assignImageToVariant().'
            );
        }

        if (requestParameters['imageId'] == null) {
            throw new runtime.RequiredError(
                'imageId',
                'Required parameter "imageId" was null or undefined when calling assignImageToVariant().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/variants/{variantId}/image/{imageId}`.replace(`{${"variantId"}}`, encodeURIComponent(String(requestParameters['variantId']))).replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters['imageId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign product image to variant
     */
    async assignImageToVariant(requestParameters: AssignImageToVariantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignImageToVariantRaw(requestParameters, initOverrides);
    }

    /**
     * Import products from CSV file as Brand
     */
    async brandImportProductsFromCsvRaw(requestParameters: BrandImportProductsFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/brand-csv-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRetailersWithTradeShowRequestToJSON(requestParameters['linkRetailersWithTradeShowRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import products from CSV file as Brand
     */
    async brandImportProductsFromCsv(requestParameters: BrandImportProductsFromCsvRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.brandImportProductsFromCsvRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk delete product
     */
    async bulkDeleteProductRaw(requestParameters: BulkDeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkDeleteProductsRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkDeleteProductsRequest',
                'Required parameter "bulkDeleteProductsRequest" was null or undefined when calling bulkDeleteProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/products`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: BulkDeleteProductsRequestToJSON(requestParameters['bulkDeleteProductsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk delete product
     */
    async bulkDeleteProduct(requestParameters: BulkDeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkDeleteProductRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk save draft product.
     */
    async bulkSaveDraftProductRaw(requestParameters: BulkSaveDraftProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkSaveDraftProductRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkSaveDraftProductRequest',
                'Required parameter "bulkSaveDraftProductRequest" was null or undefined when calling bulkSaveDraftProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/bulk-save`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkSaveDraftProductRequestToJSON(requestParameters['bulkSaveDraftProductRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk save draft product.
     */
    async bulkSaveDraftProduct(requestParameters: BulkSaveDraftProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkSaveDraftProductRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk update draft products
     */
    async bulkUpdateDraftProductsRaw(requestParameters: BulkUpdateDraftProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkUpdateDraftProductsRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkUpdateDraftProductsRequest',
                'Required parameter "bulkUpdateDraftProductsRequest" was null or undefined when calling bulkUpdateDraftProducts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/bulk-update`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateDraftProductsRequestToJSON(requestParameters['bulkUpdateDraftProductsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk update draft products
     */
    async bulkUpdateDraftProducts(requestParameters: BulkUpdateDraftProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateDraftProductsRaw(requestParameters, initOverrides);
    }

    /**
     * Admin API to update products in bulk
     */
    async bulkUpdateProductsRaw(requestParameters: BulkUpdateProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bulkUpdateProductsRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkUpdateProductsRequest',
                'Required parameter "bulkUpdateProductsRequest" was null or undefined when calling bulkUpdateProducts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/update`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateProductsRequestToJSON(requestParameters['bulkUpdateProductsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin API to update products in bulk
     */
    async bulkUpdateProducts(requestParameters: BulkUpdateProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateProductsRaw(requestParameters, initOverrides);
    }

    /**
     * Change product priority
     */
    async changePriorityRaw(requestParameters: ChangePriorityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling changePriority().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/priority`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change product priority
     */
    async changePriority(requestParameters: ChangePriorityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePriorityRaw(requestParameters, initOverrides);
    }

    /**
     * Change product visibility
     */
    async changeVisibilityRaw(requestParameters: ChangeVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling changeVisibility().'
            );
        }

        if (requestParameters['active'] == null) {
            throw new runtime.RequiredError(
                'active',
                'Required parameter "active" was null or undefined when calling changeVisibility().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/visibility`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change product visibility
     */
    async changeVisibility(requestParameters: ChangeVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeVisibilityRaw(requestParameters, initOverrides);
    }

    /**
     * Duplicate product. Receives name of copy(duplicate product). Returns UUID of new created product.
     */
    async createDuplicateProductRaw(requestParameters: CreateDuplicateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createDuplicateProduct().'
            );
        }

        if (requestParameters['createDuplicateProductRequest'] == null) {
            throw new runtime.RequiredError(
                'createDuplicateProductRequest',
                'Required parameter "createDuplicateProductRequest" was null or undefined when calling createDuplicateProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/duplicate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDuplicateProductRequestToJSON(requestParameters['createDuplicateProductRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Duplicate product. Receives name of copy(duplicate product). Returns UUID of new created product.
     */
    async createDuplicateProduct(requestParameters: CreateDuplicateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createDuplicateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Product
     */
    async createProductRaw(requestParameters: CreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createProductRequest'] == null) {
            throw new runtime.RequiredError(
                'createProductRequest',
                'Required parameter "createProductRequest" was null or undefined when calling createProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductRequestToJSON(requestParameters['createProductRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create Product
     */
    async createProduct(requestParameters: CreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save information for new product video
     */
    async createProductVideoRaw(requestParameters: CreateProductVideoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createProductVideo().'
            );
        }

        if (requestParameters['createVideoRequest'] == null) {
            throw new runtime.RequiredError(
                'createVideoRequest',
                'Required parameter "createVideoRequest" was null or undefined when calling createProductVideo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/video`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVideoRequestToJSON(requestParameters['createVideoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Save information for new product video
     */
    async createProductVideo(requestParameters: CreateProductVideoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createProductVideoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create video url
     */
    async createProductVideoUrlRaw(requestParameters: CreateProductVideoUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadVideoUrlResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createProductVideoUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/video-url`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadVideoUrlResponseFromJSON(jsonValue));
    }

    /**
     * Create video url
     */
    async createProductVideoUrl(requestParameters: CreateProductVideoUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadVideoUrlResponse> {
        const response = await this.createProductVideoUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete products in bulk. Supply brand_id to delete all products for that brand, or supply product_ids to delete specific products.
     */
    async deleteBulkProductRaw(requestParameters: DeleteBulkProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['deleteBulkProductRequest'] == null) {
            throw new runtime.RequiredError(
                'deleteBulkProductRequest',
                'Required parameter "deleteBulkProductRequest" was null or undefined when calling deleteBulkProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteBulkProductRequestToJSON(requestParameters['deleteBulkProductRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete products in bulk. Supply brand_id to delete all products for that brand, or supply product_ids to delete specific products.
     */
    async deleteBulkProduct(requestParameters: DeleteBulkProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBulkProductRaw(requestParameters, initOverrides);
    }

    /**
     * Delete draft product
     */
    async deleteDraftProductRaw(requestParameters: DeleteDraftProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDraftProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/draft-product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete draft product
     */
    async deleteDraftProduct(requestParameters: DeleteDraftProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDraftProductRaw(requestParameters, initOverrides);
    }

    /**
     * Delete product variant image
     */
    async deleteImageFromVariantRaw(requestParameters: DeleteImageFromVariantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['variantId'] == null) {
            throw new runtime.RequiredError(
                'variantId',
                'Required parameter "variantId" was null or undefined when calling deleteImageFromVariant().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/variants/{variantId}/image`.replace(`{${"variantId"}}`, encodeURIComponent(String(requestParameters['variantId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete product variant image
     */
    async deleteImageFromVariant(requestParameters: DeleteImageFromVariantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteImageFromVariantRaw(requestParameters, initOverrides);
    }

    /**
     * Delete product
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete product
     */
    async deleteProduct(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductRaw(requestParameters, initOverrides);
    }

    /**
     * Delete product image
     */
    async deleteProductImageRaw(requestParameters: DeleteProductImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['imageId'] == null) {
            throw new runtime.RequiredError(
                'imageId',
                'Required parameter "imageId" was null or undefined when calling deleteProductImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/images/{imageId}`.replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters['imageId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete product image
     */
    async deleteProductImage(requestParameters: DeleteProductImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductImageRaw(requestParameters, initOverrides);
    }

    /**
     * Download csv template which should be filled with inventory information
     */
    async downloadInventoryCsvFileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/download-inventory-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download csv template which should be filled with inventory information
     */
    async downloadInventoryCsvFile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadInventoryCsvFileRaw(initOverrides);
        return await response.value();
    }

    /**
     * Bulk duplicate products
     */
    async duplicateBulkProductsRaw(requestParameters: DuplicateBulkProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkDuplicateProductsResponse>> {
        if (requestParameters['bulkDuplicateProductsRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkDuplicateProductsRequest',
                'Required parameter "bulkDuplicateProductsRequest" was null or undefined when calling duplicateBulkProducts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/bulk-duplicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkDuplicateProductsRequestToJSON(requestParameters['bulkDuplicateProductsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkDuplicateProductsResponseFromJSON(jsonValue));
    }

    /**
     * Bulk duplicate products
     */
    async duplicateBulkProducts(requestParameters: DuplicateBulkProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkDuplicateProductsResponse> {
        const response = await this.duplicateBulkProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export products to csv file
     */
    async exportProductsToCsvRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export products to csv file
     */
    async exportProductsToCsv(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportProductsToCsvRaw(initOverrides);
        return await response.value();
    }

    /**
     * Favoritize product
     */
    async favoritizeProductRaw(requestParameters: FavoritizeProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling favoritizeProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/favoritize`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Favoritize product
     */
    async favoritizeProduct(requestParameters: FavoritizeProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.favoritizeProductRaw(requestParameters, initOverrides);
    }

    /**
     * Find products by search criteria
     */
    async filterProductsBySearchCriteriaRaw(requestParameters: FilterProductsBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredProductResponse>> {
        if (requestParameters['filterProductRequest'] == null) {
            throw new runtime.RequiredError(
                'filterProductRequest',
                'Required parameter "filterProductRequest" was null or undefined when calling filterProductsBySearchCriteria().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/search-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterProductRequestToJSON(requestParameters['filterProductRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilteredProductResponseFromJSON(jsonValue));
    }

    /**
     * Find products by search criteria
     */
    async filterProductsBySearchCriteria(requestParameters: FilterProductsBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredProductResponse> {
        const response = await this.filterProductsBySearchCriteriaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all draft products per brand
     */
    async getAllDraftProductsForBrandRaw(requestParameters: GetAllDraftProductsForBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedProductsResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getAllDraftProductsForBrand().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getAllDraftProductsForBrand().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getAllDraftProductsForBrand().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/brands/{brandId}/draft-products`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProductsResponseFromJSON(jsonValue));
    }

    /**
     * Find all draft products per brand
     */
    async getAllDraftProductsForBrand(requestParameters: GetAllDraftProductsForBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedProductsResponse> {
        const response = await this.getAllDraftProductsForBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brand products count
     */
    async getBrandProductsCountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountBrandProductsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountBrandProductsResponseFromJSON(jsonValue));
    }

    /**
     * Get brand products count
     */
    async getBrandProductsCount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountBrandProductsResponse> {
        const response = await this.getBrandProductsCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get draft products count
     */
    async getDraftProductsCountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountDraftProductsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/draft-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDraftProductsResponseFromJSON(jsonValue));
    }

    /**
     * Get draft products count
     */
    async getDraftProductsCount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountDraftProductsResponse> {
        const response = await this.getDraftProductsCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get user favorite products
     */
    async getFavoriteProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredProductResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/favorite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilteredProductResponseFromJSON(jsonValue));
    }

    /**
     * Get user favorite products
     */
    async getFavoriteProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredProductResponse> {
        const response = await this.getFavoriteProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get user filtered favorite products
     */
    async getFilteredFavoriteProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredProductResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/favorite-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilteredProductResponseFromJSON(jsonValue));
    }

    /**
     * Get user filtered favorite products
     */
    async getFilteredFavoriteProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredProductResponse> {
        const response = await this.getFilteredFavoriteProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Search all inventory levels by keyword with pagination
     */
    async getInventoryLevelsRaw(requestParameters: GetInventoryLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInventoryLevelsResponse>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getInventoryLevels().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getInventoryLevels().'
            );
        }

        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling getInventoryLevels().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['keyword'] != null) {
            queryParameters['keyword'] = requestParameters['keyword'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        if (requestParameters['inStock'] != null) {
            queryParameters['in_stock'] = requestParameters['inStock'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/inventory-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInventoryLevelsResponseFromJSON(jsonValue));
    }

    /**
     * Search all inventory levels by keyword with pagination
     */
    async getInventoryLevels(requestParameters: GetInventoryLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInventoryLevelsResponse> {
        const response = await this.getInventoryLevelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get number of user favorite products
     */
    async getNumberOfFavoriteProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavoriteProductsCountResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/number-of-favorite-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteProductsCountResponseFromJSON(jsonValue));
    }

    /**
     * Get number of user favorite products
     */
    async getNumberOfFavoriteProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavoriteProductsCountResponse> {
        const response = await this.getNumberOfFavoriteProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get product by Id
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getProduct().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['checkAccess'] != null) {
            queryParameters['check_access'] = requestParameters['checkAccess'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductResponseFromJSON(jsonValue));
    }

    /**
     * Get product by Id
     */
    async getProduct(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductResponse> {
        const response = await this.getProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all products per multiple/custom criteria
     */
    async getProductsRaw(requestParameters: GetProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedProductsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['categoryId'] != null) {
            queryParameters['categoryId'] = requestParameters['categoryId'];
        }

        if (requestParameters['createdByAdmin'] != null) {
            queryParameters['createdByAdmin'] = requestParameters['createdByAdmin'];
        }

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProductsResponseFromJSON(jsonValue));
    }

    /**
     * Find all products per multiple/custom criteria
     */
    async getProducts(requestParameters: GetProductsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedProductsResponse> {
        const response = await this.getProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find products by category Id
     */
    async getProductsByCategoryRaw(requestParameters: GetProductsByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductsByCategoryResponse>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getProductsByCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductsByCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Find products by category Id
     */
    async getProductsByCategory(requestParameters: GetProductsByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductsByCategoryResponse> {
        const response = await this.getProductsByCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find products by collection Id
     */
    async getProductsByCollectionRaw(requestParameters: GetProductsByCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionResponse>> {
        if (requestParameters['collectionId'] == null) {
            throw new runtime.RequiredError(
                'collectionId',
                'Required parameter "collectionId" was null or undefined when calling getProductsByCollection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/collections/{collectionId}`.replace(`{${"collectionId"}}`, encodeURIComponent(String(requestParameters['collectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionResponseFromJSON(jsonValue));
    }

    /**
     * Find products by collection Id
     */
    async getProductsByCollection(requestParameters: GetProductsByCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionResponse> {
        const response = await this.getProductsByCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get filtered products
     */
    async getProductsFilteredRaw(requestParameters: GetProductsFilteredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProductFilteredResponse>>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getProductsFiltered().'
            );
        }

        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getProductsFiltered().'
            );
        }

        if (requestParameters['productNumber'] == null) {
            throw new runtime.RequiredError(
                'productNumber',
                'Required parameter "productNumber" was null or undefined when calling getProductsFiltered().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['product_id'] = requestParameters['productId'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brand_id'] = requestParameters['brandId'];
        }

        if (requestParameters['productNumber'] != null) {
            queryParameters['product_number'] = requestParameters['productNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductFilteredResponseFromJSON));
    }

    /**
     * Get filtered products
     */
    async getProductsFiltered(requestParameters: GetProductsFilteredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProductFilteredResponse>> {
        const response = await this.getProductsFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all tags
     */
    async getTagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Find all tags
     */
    async getTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getTagsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Import products from CSV file
     */
    async importProductsFromCsvRaw(requestParameters: ImportProductsFromCsvOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/csv-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportProductsFromCsvRequestToJSON(requestParameters['importProductsFromCsvRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import products from CSV file
     */
    async importProductsFromCsv(requestParameters: ImportProductsFromCsvOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importProductsFromCsvRaw(requestParameters, initOverrides);
    }

    /**
     * Import products from CSV file for Brands and Admins
     */
    async importProductsFromCsvV2Raw(requestParameters: ImportProductsFromCsvV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['csvType'] == null) {
            throw new runtime.RequiredError(
                'csvType',
                'Required parameter "csvType" was null or undefined when calling importProductsFromCsvV2().'
            );
        }

        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling importProductsFromCsvV2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['createDraft'] != null) {
            queryParameters['createDraft'] = requestParameters['createDraft'];
        }

        if (requestParameters['csvType'] != null) {
            queryParameters['csvType'] = requestParameters['csvType'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/csv-import/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRetailersWithTradeShowRequestToJSON(requestParameters['linkRetailersWithTradeShowRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import products from CSV file for Brands and Admins
     */
    async importProductsFromCsvV2(requestParameters: ImportProductsFromCsvV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importProductsFromCsvV2Raw(requestParameters, initOverrides);
    }

    /**
     * Import products name, category and tags based on new navigation settings
     */
    async importProductsNavigationRaw(requestParameters: ImportProductsNavigationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['importProductsNavigationRequest'] == null) {
            throw new runtime.RequiredError(
                'importProductsNavigationRequest',
                'Required parameter "importProductsNavigationRequest" was null or undefined when calling importProductsNavigation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/import-navigation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['importProductsNavigationRequest']!.map(ImportProductsNavigationRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Import products name, category and tags based on new navigation settings
     */
    async importProductsNavigation(requestParameters: ImportProductsNavigationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.importProductsNavigationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set product active flag.
     */
    async markProductAsActiveRaw(requestParameters: MarkProductAsActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling markProductAsActive().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/active`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set product active flag.
     */
    async markProductAsActive(requestParameters: MarkProductAsActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markProductAsActiveRaw(requestParameters, initOverrides);
    }

    /**
     * Set product visible flag.
     */
    async markProductAsVisibleRaw(requestParameters: MarkProductAsVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling markProductAsVisible().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/visible`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set product visible flag.
     */
    async markProductAsVisible(requestParameters: MarkProductAsVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markProductAsVisibleRaw(requestParameters, initOverrides);
    }

    /**
     * Create Product as ProductBuilder
     */
    async productBuilderCreateProductRaw(requestParameters: ProductBuilderCreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['productBuilderCreateProductRequest'] == null) {
            throw new runtime.RequiredError(
                'productBuilderCreateProductRequest',
                'Required parameter "productBuilderCreateProductRequest" was null or undefined when calling productBuilderCreateProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/product-builders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductBuilderCreateProductRequestToJSON(requestParameters['productBuilderCreateProductRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create Product as ProductBuilder
     */
    async productBuilderCreateProduct(requestParameters: ProductBuilderCreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.productBuilderCreateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update product as ProductBuilder
     */
    async productBuilderUpdateProductRaw(requestParameters: ProductBuilderUpdateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling productBuilderUpdateProduct().'
            );
        }

        if (requestParameters['productBuilderUpdateProductRequest'] == null) {
            throw new runtime.RequiredError(
                'productBuilderUpdateProductRequest',
                'Required parameter "productBuilderUpdateProductRequest" was null or undefined when calling productBuilderUpdateProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/product-builders`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductBuilderUpdateProductRequestToJSON(requestParameters['productBuilderUpdateProductRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update product as ProductBuilder
     */
    async productBuilderUpdateProduct(requestParameters: ProductBuilderUpdateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productBuilderUpdateProductRaw(requestParameters, initOverrides);
    }

    /**
     * Pull products from Algolia without an image
     */
    async pullProductsWithoutImageFromAlgoliaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductWithoutImageResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/pull`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductWithoutImageResponseFromJSON));
    }

    /**
     * Pull products from Algolia without an image
     */
    async pullProductsWithoutImageFromAlgolia(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductWithoutImageResponse>> {
        const response = await this.pullProductsWithoutImageFromAlgoliaRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reject product
     */
    async rejectProductRaw(requestParameters: RejectProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rejectProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject product
     */
    async rejectProduct(requestParameters: RejectProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectProductRaw(requestParameters, initOverrides);
    }

    /**
     * send draft product reminder email
     */
    async sendDraftProductReminderRaw(requestParameters: SendDraftProductReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling sendDraftProductReminder().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling sendDraftProductReminder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/brands/{brandId}/draft-product-reminder`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * send draft product reminder email
     */
    async sendDraftProductReminder(requestParameters: SendDraftProductReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendDraftProductReminderRaw(requestParameters, initOverrides);
    }

    /**
     * Update inventory tracking data from CSV file
     */
    async updateInventoryTrackingFromCsvRaw(requestParameters: UpdateInventoryTrackingFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateInventoryTrackingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/import-inventory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRetailersWithTradeShowRequestToJSON(requestParameters['linkRetailersWithTradeShowRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateInventoryTrackingResponseFromJSON(jsonValue));
    }

    /**
     * Update inventory tracking data from CSV file
     */
    async updateInventoryTrackingFromCsv(requestParameters: UpdateInventoryTrackingFromCsvRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateInventoryTrackingResponse> {
        const response = await this.updateInventoryTrackingFromCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update product
     */
    async updateProductRaw(requestParameters: UpdateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateProduct().'
            );
        }

        if (requestParameters['updateProductRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProductRequest',
                'Required parameter "updateProductRequest" was null or undefined when calling updateProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductRequestToJSON(requestParameters['updateProductRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update product
     */
    async updateProduct(requestParameters: UpdateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProductRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateProductCategoriesRaw(requestParameters: UpdateProductCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/update-categories`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRetailersWithTradeShowRequestToJSON(requestParameters['linkRetailersWithTradeShowRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateProductCategories(requestParameters: UpdateProductCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProductCategoriesRaw(requestParameters, initOverrides);
    }

    /**
     * Update product image crop info
     * @deprecated
     */
    async updateProductImageCropInfoRaw(requestParameters: UpdateProductImageCropInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadImageResponse>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateProductImageCropInfo().'
            );
        }

        if (requestParameters['imageId'] == null) {
            throw new runtime.RequiredError(
                'imageId',
                'Required parameter "imageId" was null or undefined when calling updateProductImageCropInfo().'
            );
        }

        if (requestParameters['updateProductImageCropInfoRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProductImageCropInfoRequest',
                'Required parameter "updateProductImageCropInfoRequest" was null or undefined when calling updateProductImageCropInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{productId}/images/{imageId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))).replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters['imageId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductImageCropInfoRequestToJSON(requestParameters['updateProductImageCropInfoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadImageResponseFromJSON(jsonValue));
    }

    /**
     * Update product image crop info
     * @deprecated
     */
    async updateProductImageCropInfo(requestParameters: UpdateProductImageCropInfoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadImageResponse> {
        const response = await this.updateProductImageCropInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update product inventory level
     */
    async updateProductInventoryLevelRaw(requestParameters: UpdateProductInventoryLevelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateInventoryLevelResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateProductInventoryLevel().'
            );
        }

        if (requestParameters['updateProductInventoryLevelRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProductInventoryLevelRequest',
                'Required parameter "updateProductInventoryLevelRequest" was null or undefined when calling updateProductInventoryLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/change-inventory`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductInventoryLevelRequestToJSON(requestParameters['updateProductInventoryLevelRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateInventoryLevelResponseFromJSON(jsonValue));
    }

    /**
     * Update product inventory level
     */
    async updateProductInventoryLevel(requestParameters: UpdateProductInventoryLevelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateInventoryLevelResponse> {
        const response = await this.updateProductInventoryLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update products inventory levels
     */
    async updateProductsInventoryLevelsRaw(requestParameters: UpdateProductsInventoryLevelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateInventoryLevelResponse>> {
        if (requestParameters['updateProductsInventoryLevelsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProductsInventoryLevelsRequest',
                'Required parameter "updateProductsInventoryLevelsRequest" was null or undefined when calling updateProductsInventoryLevels().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/change-inventories`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductsInventoryLevelsRequestToJSON(requestParameters['updateProductsInventoryLevelsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateInventoryLevelResponseFromJSON(jsonValue));
    }

    /**
     * Update products inventory levels
     */
    async updateProductsInventoryLevels(requestParameters: UpdateProductsInventoryLevelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateInventoryLevelResponse> {
        const response = await this.updateProductsInventoryLevelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload product image
     * @deprecated
     */
    async uploadProductImageRaw(requestParameters: UploadProductImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadImageResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadProductImage().'
            );
        }

        if (requestParameters['ordinal'] == null) {
            throw new runtime.RequiredError(
                'ordinal',
                'Required parameter "ordinal" was null or undefined when calling uploadProductImage().'
            );
        }

        if (requestParameters['isCustomAssortment'] == null) {
            throw new runtime.RequiredError(
                'isCustomAssortment',
                'Required parameter "isCustomAssortment" was null or undefined when calling uploadProductImage().'
            );
        }

        if (requestParameters['uploadProductImageRequest'] == null) {
            throw new runtime.RequiredError(
                'uploadProductImageRequest',
                'Required parameter "uploadProductImageRequest" was null or undefined when calling uploadProductImage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordinal'] != null) {
            queryParameters['ordinal'] = requestParameters['ordinal'];
        }

        if (requestParameters['isCustomAssortment'] != null) {
            queryParameters['is_custom_assortment'] = requestParameters['isCustomAssortment'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/images`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadProductImageRequestToJSON(requestParameters['uploadProductImageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadImageResponseFromJSON(jsonValue));
    }

    /**
     * Upload product image
     * @deprecated
     */
    async uploadProductImage(requestParameters: UploadProductImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadImageResponse> {
        const response = await this.uploadProductImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate variant\'s SKU
     */
    async validateSKURaw(requestParameters: ValidateSKUOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateSKUResponse>> {
        if (requestParameters['validateSKURequest'] == null) {
            throw new runtime.RequiredError(
                'validateSKURequest',
                'Required parameter "validateSKURequest" was null or undefined when calling validateSKU().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/validate-sku`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateSKURequestToJSON(requestParameters['validateSKURequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateSKUResponseFromJSON(jsonValue));
    }

    /**
     * Validate variant\'s SKU
     */
    async validateSKU(requestParameters: ValidateSKUOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateSKUResponse> {
        const response = await this.validateSKURaw(requestParameters, initOverrides);
        return await response.value();
    }

}
