/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulletinAddress } from './BulletinAddress';
import {
    BulletinAddressFromJSON,
    BulletinAddressFromJSONTyped,
    BulletinAddressToJSON,
    BulletinAddressToJSONTyped,
} from './BulletinAddress';
import type { SuborderReturnResponse } from './SuborderReturnResponse';
import {
    SuborderReturnResponseFromJSON,
    SuborderReturnResponseFromJSONTyped,
    SuborderReturnResponseToJSON,
    SuborderReturnResponseToJSONTyped,
} from './SuborderReturnResponse';

/**
 * 
 * @export
 * @interface OrderReturnResponse
 */
export interface OrderReturnResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    order_id?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderReturnResponse
     */
    order_number?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderReturnResponse
     */
    shipping_cost?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    tracking_number?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    shipping_carrier?: OrderReturnResponseShippingCarrierEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    comment?: string;
    /**
     * 
     * @type {BulletinAddress}
     * @memberof OrderReturnResponse
     */
    bulletin_address?: BulletinAddress;
    /**
     * 
     * @type {Array<SuborderReturnResponse>}
     * @memberof OrderReturnResponse
     */
    suborder_returns?: Array<SuborderReturnResponse>;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    status?: OrderReturnResponseStatusEnum;
}


/**
 * @export
 */
export const OrderReturnResponseShippingCarrierEnum = {
    Apc: 'APC',
    CanadaPost: 'CANADA_POST',
    Canpar: 'CANPAR',
    Dhl: 'DHL',
    FedEx: 'FedEx',
    Gls: 'GLS',
    NewGistics: 'NEW_GISTICS',
    Purolator: 'PUROLATOR',
    Sendle: 'SENDLE',
    SfExpress: 'SF_EXPRESS',
    Ups: 'UPS',
    Usps: 'USPS',
    Other: 'OTHER'
} as const;
export type OrderReturnResponseShippingCarrierEnum = typeof OrderReturnResponseShippingCarrierEnum[keyof typeof OrderReturnResponseShippingCarrierEnum];

/**
 * @export
 */
export const OrderReturnResponseStatusEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    PartiallyAccepted: 'PARTIALLY_ACCEPTED'
} as const;
export type OrderReturnResponseStatusEnum = typeof OrderReturnResponseStatusEnum[keyof typeof OrderReturnResponseStatusEnum];


/**
 * Check if a given object implements the OrderReturnResponse interface.
 */
export function instanceOfOrderReturnResponse(value: object): value is OrderReturnResponse {
    return true;
}

export function OrderReturnResponseFromJSON(json: any): OrderReturnResponse {
    return OrderReturnResponseFromJSONTyped(json, false);
}

export function OrderReturnResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderReturnResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'order_id': json['order_id'] == null ? undefined : json['order_id'],
        'order_number': json['order_number'] == null ? undefined : json['order_number'],
        'shipping_cost': json['shipping_cost'] == null ? undefined : json['shipping_cost'],
        'tracking_number': json['tracking_number'] == null ? undefined : json['tracking_number'],
        'shipping_carrier': json['shipping_carrier'] == null ? undefined : json['shipping_carrier'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'bulletin_address': json['bulletin_address'] == null ? undefined : BulletinAddressFromJSON(json['bulletin_address']),
        'suborder_returns': json['suborder_returns'] == null ? undefined : ((json['suborder_returns'] as Array<any>).map(SuborderReturnResponseFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function OrderReturnResponseToJSON(json: any): OrderReturnResponse {
    return OrderReturnResponseToJSONTyped(json, false);
}

export function OrderReturnResponseToJSONTyped(value?: OrderReturnResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'order_id': value['order_id'],
        'order_number': value['order_number'],
        'shipping_cost': value['shipping_cost'],
        'tracking_number': value['tracking_number'],
        'shipping_carrier': value['shipping_carrier'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'comment': value['comment'],
        'bulletin_address': BulletinAddressToJSON(value['bulletin_address']),
        'suborder_returns': value['suborder_returns'] == null ? undefined : ((value['suborder_returns'] as Array<any>).map(SuborderReturnResponseToJSON)),
        'status': value['status'],
    };
}

