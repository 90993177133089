import BrandApi from '@/services/api/brandApi'

const brandApi = new BrandApi()

export default {
  namespaced: true,
  state: {
    productOwnerBrand: null
  },
  getters: {
    isShopifyConnected: (state) => state.productOwnerBrand?.shopify != null,
    shopifyProductNotActive: (_state, _getters, _rootState) => (product) => {
      return (
        product?.shopify &&
        (product.shopify.status === 'archived' ||
          product.shopify.status === 'deleted' ||
          product.shopify.status === 'draft')
      )
    },
    disableFieldForShopify: (_state, getters) => (product) => {
      if (!getters.isShopifyConnected) return false
      return !getters.shopifyProductNotActive(product)
    }
  },
  mutations: {
    SET_PRODUCT_OWNER_BRAND(state, brand) {
      state.productOwnerBrand = brand
    },
    CLEAR_PRODUCT_OWNER_BRAND(state) {
      state.productOwnerBrand = null
    }
  },
  actions: {
    async fetchProductOwnerBrand({ commit, rootGetters }, productBrandId) {
      if (!productBrandId) {
        console.warn('No brand ID provided to fetchProductOwnerBrand')
        commit('CLEAR_PRODUCT_OWNER_BRAND')
        return
      }
      try {
        const response = await (rootGetters.userState?.role === 'ADMIN'
          ? brandApi.getBrand(productBrandId)
          : brandApi.getMe())
        commit('SET_PRODUCT_OWNER_BRAND', response.data)
      } catch (error) {
        console.error('Failed to fetch brand details:', error)
        commit('CLEAR_PRODUCT_OWNER_BRAND')
      }
    },
    clearProductOwnerBrand({ commit }) {
      commit('CLEAR_PRODUCT_OWNER_BRAND')
    }
  }
}
