/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandTradeShowResponse
 */
export interface BrandTradeShowResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    booth_number?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    registration_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandTradeShowResponse
     */
    booth_map_url?: string;
}

/**
 * Check if a given object implements the BrandTradeShowResponse interface.
 */
export function instanceOfBrandTradeShowResponse(value: object): value is BrandTradeShowResponse {
    return true;
}

export function BrandTradeShowResponseFromJSON(json: any): BrandTradeShowResponse {
    return BrandTradeShowResponseFromJSONTyped(json, false);
}

export function BrandTradeShowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandTradeShowResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'booth_number': json['booth_number'] == null ? undefined : json['booth_number'],
        'name': json['name'] == null ? undefined : json['name'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'section': json['section'] == null ? undefined : json['section'],
        'registration_url': json['registration_url'] == null ? undefined : json['registration_url'],
        'booth_map_url': json['booth_map_url'] == null ? undefined : json['booth_map_url'],
    };
}

export function BrandTradeShowResponseToJSON(json: any): BrandTradeShowResponse {
    return BrandTradeShowResponseToJSONTyped(json, false);
}

export function BrandTradeShowResponseToJSONTyped(value?: BrandTradeShowResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'booth_number': value['booth_number'],
        'name': value['name'],
        'slug': value['slug'],
        'brand': value['brand'],
        'section': value['section'],
        'registration_url': value['registration_url'],
        'booth_map_url': value['booth_map_url'],
    };
}

