/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductsDashboardResponse
 */
export interface ProductsDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductsDashboardResponse
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDashboardResponse
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDashboardResponse
     */
    created_by_admin?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProductsDashboardResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductsDashboardResponse
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDashboardResponse
     */
    is_test_product?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductsDashboardResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductsDashboardResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductsDashboardResponse
     */
    status?: ProductsDashboardResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDashboardResponse
     */
    visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductsDashboardResponse
     */
    wholesale_increment?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDashboardResponse
     */
    wholesale_minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDashboardResponse
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDashboardResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDashboardResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProductsDashboardResponse
     */
    extern_product_id?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsDashboardResponse
     */
    characteristic_options?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ProductsDashboardResponse
     */
    brand?: object;
    /**
     * 
     * @type {object}
     * @memberof ProductsDashboardResponse
     */
    categories?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsDashboardResponse
     */
    tags?: Array<string>;
}


/**
 * @export
 */
export const ProductsDashboardResponseStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Any: 'ANY',
    Draft: 'DRAFT'
} as const;
export type ProductsDashboardResponseStatusEnum = typeof ProductsDashboardResponseStatusEnum[keyof typeof ProductsDashboardResponseStatusEnum];


/**
 * Check if a given object implements the ProductsDashboardResponse interface.
 */
export function instanceOfProductsDashboardResponse(value: object): value is ProductsDashboardResponse {
    return true;
}

export function ProductsDashboardResponseFromJSON(json: any): ProductsDashboardResponse {
    return ProductsDashboardResponseFromJSONTyped(json, false);
}

export function ProductsDashboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsDashboardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'active': json['active'] == null ? undefined : json['active'],
        'created_by_admin': json['created_by_admin'] == null ? undefined : json['created_by_admin'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'image': json['image'] == null ? undefined : json['image'],
        'is_test_product': json['is_test_product'] == null ? undefined : json['is_test_product'],
        'name': json['name'] == null ? undefined : json['name'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'status': json['status'] == null ? undefined : json['status'],
        'visible': json['visible'] == null ? undefined : json['visible'],
        'wholesale_increment': json['wholesale_increment'] == null ? undefined : json['wholesale_increment'],
        'wholesale_minimum': json['wholesale_minimum'] == null ? undefined : json['wholesale_minimum'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'extern_product_id': json['extern_product_id'] == null ? undefined : json['extern_product_id'],
        'characteristic_options': json['characteristic_options'] == null ? undefined : json['characteristic_options'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'categories': json['categories'] == null ? undefined : json['categories'],
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function ProductsDashboardResponseToJSON(json: any): ProductsDashboardResponse {
    return ProductsDashboardResponseToJSONTyped(json, false);
}

export function ProductsDashboardResponseToJSONTyped(value?: ProductsDashboardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'active': value['active'],
        'created_by_admin': value['created_by_admin'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'image': value['image'],
        'is_test_product': value['is_test_product'],
        'name': value['name'],
        'pos_price': value['pos_price'],
        'status': value['status'],
        'visible': value['visible'],
        'wholesale_increment': value['wholesale_increment'],
        'wholesale_minimum': value['wholesale_minimum'],
        'wholesale_price': value['wholesale_price'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'extern_product_id': value['extern_product_id'],
        'characteristic_options': value['characteristic_options'],
        'brand': value['brand'],
        'categories': value['categories'],
        'tags': value['tags'],
    };
}

