import OrderApi from '@/services/api/orderApi'

const OrderApiService = new OrderApi()

export default {
  namespaced: true,
  state: () => ({
    count: 0
  }),
  mutations: {
    SET_COUNT(state, count) {
      state.count = count
    },
    decrement(state) {
      state.count--
    }
  },
  getters: {
    count(state) {
      return state.count
    }
  },
  actions: {
    getCartProductCount({ commit }) {
      const user = JSON.parse(localStorage.getItem('USER_INFO'))
      if (user?.role === 'PRODUCT_BUILDER') {
        return
      }

      OrderApiService.getCartQuantity().then(({ data }) => {
        commit('SET_COUNT', setTotalProductCount(data))
      })
    }
  }
}

function setTotalProductCount(data) {
  return data.products_amount
}
