import vClickOutside from 'v-click-outside'

export default {
  props: {
    name: { type: String },
    dropdownItems: { type: Array },
    placeholder: { type: String },
    onSelect: { type: Function },
    optionalyAvailable: { type: Boolean },
    canReset: { type: Boolean },
    showChips: { type: Boolean }
  },
  data() {
    return {
      isOpen: false,
      items: []
    }
  },
  computed: {
    hasSelectedValues() {
      const totalSelectedValues = this.items.reduce((total, item) => {
        if (item.selected) {
          return total + 1
        }
        return total
      }, 0)
      return totalSelectedValues > 0
    }
  },
  watch: {
    dropdownItems: function (val) {
      this.items = val
    }
  },
  methods: {
    toggleDropdown,
    closeDropdown,
    pick,
    reset
  },
  mounted() {
    this.items = this.dropdownItems === undefined ? [] : this.dropdownItems
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
}

function pick(item, index) {
  this.dropdownItems[index].selected = !this.dropdownItems[index].selected
  this.onSelect(item, index)
}

function toggleDropdown() {
  if (this.items.length) {
    this.isOpen = !this.isOpen
  }
}

function closeDropdown() {
  this.isOpen = false
}

function reset() {
  this.dropdownItems.forEach((item, index) => {
    this.dropdownItems[index].selected = false
  })
}
