/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumOptionRetailerStatus } from './EnumOptionRetailerStatus';
import {
    EnumOptionRetailerStatusFromJSON,
    EnumOptionRetailerStatusFromJSONTyped,
    EnumOptionRetailerStatusToJSON,
    EnumOptionRetailerStatusToJSONTyped,
} from './EnumOptionRetailerStatus';

/**
 * 
 * @export
 * @interface GetEnumOptionsResponseRetailerStatus
 */
export interface GetEnumOptionsResponseRetailerStatus {
    /**
     * 
     * @type {Array<EnumOptionRetailerStatus>}
     * @memberof GetEnumOptionsResponseRetailerStatus
     */
    options?: Array<EnumOptionRetailerStatus>;
}

/**
 * Check if a given object implements the GetEnumOptionsResponseRetailerStatus interface.
 */
export function instanceOfGetEnumOptionsResponseRetailerStatus(value: object): value is GetEnumOptionsResponseRetailerStatus {
    return true;
}

export function GetEnumOptionsResponseRetailerStatusFromJSON(json: any): GetEnumOptionsResponseRetailerStatus {
    return GetEnumOptionsResponseRetailerStatusFromJSONTyped(json, false);
}

export function GetEnumOptionsResponseRetailerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEnumOptionsResponseRetailerStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'] == null ? undefined : ((json['options'] as Array<any>).map(EnumOptionRetailerStatusFromJSON)),
    };
}

export function GetEnumOptionsResponseRetailerStatusToJSON(json: any): GetEnumOptionsResponseRetailerStatus {
    return GetEnumOptionsResponseRetailerStatusToJSONTyped(json, false);
}

export function GetEnumOptionsResponseRetailerStatusToJSONTyped(value?: GetEnumOptionsResponseRetailerStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': value['options'] == null ? undefined : ((value['options'] as Array<any>).map(EnumOptionRetailerStatusToJSON)),
    };
}

