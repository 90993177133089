/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderCountByBrandResponse
 */
export interface OrderCountByBrandResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderCountByBrandResponse
     */
    order_count?: number;
}

/**
 * Check if a given object implements the OrderCountByBrandResponse interface.
 */
export function instanceOfOrderCountByBrandResponse(value: object): value is OrderCountByBrandResponse {
    return true;
}

export function OrderCountByBrandResponseFromJSON(json: any): OrderCountByBrandResponse {
    return OrderCountByBrandResponseFromJSONTyped(json, false);
}

export function OrderCountByBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCountByBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'order_count': json['order_count'] == null ? undefined : json['order_count'],
    };
}

export function OrderCountByBrandResponseToJSON(json: any): OrderCountByBrandResponse {
    return OrderCountByBrandResponseToJSONTyped(json, false);
}

export function OrderCountByBrandResponseToJSONTyped(value?: OrderCountByBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'order_count': value['order_count'],
    };
}

