/**
 * Register global components here and use them as a plugin in your main Vue instance
 */

import Headline from './components/shared/headline.vue'
import ProductTile from './components/shared/product/productTile.vue'
import QuickShopModal from './components/shared/product/quickShopModal/quickShopModal.vue'
import QuickShopModalUnregistered from './components/shared/product/quickShopModalUnregistered/quickShopModalUnregistered.vue'
import ApplyModal from './components/shared/modal/applyModal/applyModal.vue'
import Modal from './components/shared/modal/modal.vue'
import Dropdown from './components/shared/input/dropdown/dropdown.vue'
import DropdownCategories from './components/shared/input/dropdownCategories/dropdownCategories.vue'
import MultiSelect from './components/shared/input/multiSelect/multiSelect.vue'
import Quantity from './components/shared/input/quantityInput/quantityInput.vue'
import Pagination from './components/shared/pagination/pagination.vue'
import PromoModal from './components/shared/promoModal/promoModal.vue'
import AddressModal from './components/shared/addressModal/addressModal.vue'
import Loader from './components/shared/loader/loader.vue'
import LoaderStatic from './components/shared/loader/loaderStatic.vue'
import Accordion from './components/shared/accordion/accordion.vue'
import Tabs from './components/shared/tabs/tabs.vue'
import CategoryGrid from './components/shared/categoryGrid/categoryGrid.vue'
import AlgoliaAutocomplete from './components/shared/algoliaAutocomplete/algoliaAutocomplete.vue'
import ChatButton from './components/shared/chatButton/chatButton.vue'
import Button from './components/shared/button/button.vue'
import OpenNewTabButton from './components/shared/button/open-new-tab-button/openNewTabButton.vue'
import BrandValueIcon from './components/shared/brandValueIcon/brandValueIcon.vue'
import Checkbox from './components/shared/checkbox/checkbox.vue'
import PrivacyAndTermsCheckbox from './components/shared/privacyAndTermsCheckbox/PrivacyAndTermsCheckbox.vue'
import MarketingOptCheckbox from './components/shared/marketingOptCheckbox/MarketingOptCheckbox.vue'
import EntityImage from './components/shared/entityImage/entityImage.vue'
import Chip from './components/shared/chip/chip.vue'
import ErrorMessage from './components/shared/errorMessage/errorMessage.vue'
import Badge from './components/shared/badge/badge.vue'
import VideoPlayer from './components/shared/video/video.vue'
import ProductSearchContainer from './components/shared/productSearchContainer/productSearchContainer.vue'
import Toggle from './components/shared/toggle/toggle.vue'
import ToggleWithDescription from './components/shared/toggle/ToggleWithDescription.vue'
import AddImage from './components/shared/addImage/addImage.vue'
import BrandSelector from './components/shared/input/brandSelector/brandSelector.vue'
import ProductVariantBox from './components/shared/productEditor/productVariantBox/productVariantBox.vue'
import RetailerSelector from './components/shared/input/retailerSelector/retailerSelector.vue'
import CreditCardSelector from './components/shared/input/creditCardSelector/creditCardSelector.vue'
import BulletinButton from './components/shared/button/bulletin-button.vue'
import VariantPicker from './components/shared/variantPicker/variantPicker.vue'
import SingleVariantPicker from './components/shared/variantPicker/singleVariantPicker.vue'
import AddressView from './components/shared/addressView/addressView.vue'
import ProductCartSummary from './components/shared/productCartSummary/productCartSummary.vue'
import BrandValues from './components/shared/product/brandValues/brandValues.vue'
import TextField from './components/shared/input/textField/textField'
import AddressAutocomplete from './components/shared/input/addressAutocomplete/addressAutocomplete'
import AddressForm from './components/shared/addressForm/addressForm'
import Heading4 from '@/components/shared/typography/heading4'
import VariantCharacteristicList from './components/shared/variantCharacteristicList/variantCharacteristicList'
import FilterAccordion from './components/shared/filters/filterAccordion'
import FilterPanel from './components/shared/filters/filterPanel'
import CheckboxNew from './components/shared/checkboxNew/checkboxNew'
import FilterRadioButton from './components/shared/filters/filterRadioButton'
import SearchInputField from './components/shared/searchInputField/searchInputField'
import FilterBrandValuesNew from './components/shared/filters/filterTypes/filterBrandValues'
import FilterBrand from './components/shared/filters/filterTypes/filterBrand'
import FilterCategoriesNew from './components/shared/filters/filterTypes/filterCategories'
import FilterSections from './components/shared/filters/filterTypes/filterSections'
import FilterSpecialServices from './components/shared/filters/filterTypes/filterSpecialServices'
import FilterDealsPromotions from './components/shared/filters/filterTypes/filterDealsPromotions'
import FilterDateNew from './components/shared/filters/filterTypes/filterDate'
import FilterPriceNew from './components/shared/filters/filterTypes/filterPrice'
import FilterMinimumOrderValueNew from './components/shared/filters/filterTypes/filterMinimumOrderValue'
import FilterLocations from './components/shared/filters/filterTypes/filterLocations'
import FilterBadges from './components/shared/filters/filterBadges'
import SortingOptions from './components/shared/filters/sortingOptions'
import MobileFilterHeader from './components/shared/filters/mobileFilterHeader'
import MobileFilterPanel from './components/shared/filters/mobileFilterPanel'
import ProductTiles from './components/shared/productTiles/productTiles'
import FavoriteButton from './components/shared/favorite-button/favoriteButton'
import NetTerms from './components/shared/NetTerms.vue'
import CollectionSelector from '@/components/shared/input/collectionSelector/collectionSelector'
import ColorPickerInput from '@/components/shared/input/ColorPickerInput'
import SiteWideBanner from '@/components/navigation/SiteWideBanner'
import CreditAvailable from './components/shared/CreditAvailable.vue'
import Toast from './components/shared/Toast.vue'
import FloatingLabel from './components/shared/FloatingLabel.vue'
import TableSortIndicator from './components/shared/table-sort-indicator/TableSortIndicator.vue'
import AisInput from './components/shared/tagsInput/AISInput.vue'
import Icon from './components/shared/icon/Icon.vue'

const SharedComponents = {
  install(Vue) {
    Vue.component('Accordion', Accordion)
    Vue.component('Headline', Headline)
    Vue.component('ProductTile', ProductTile)
    Vue.component('QuickShopModal', QuickShopModal)
    Vue.component('QuickShopModalUnregistered', ApplyModal)
    Vue.component('ApplyModal', QuickShopModalUnregistered)
    Vue.component('Modal', Modal)
    Vue.component('Dropdown', Dropdown)
    Vue.component('MultiSelect', MultiSelect)
    Vue.component('DropdownCategories', DropdownCategories)
    Vue.component('Quantity', Quantity)
    Vue.component('Pagination', Pagination)
    Vue.component('PromoModal', PromoModal)
    Vue.component('AddressModal', AddressModal)
    Vue.component('Loader', Loader)
    Vue.component('LoaderStatic', LoaderStatic)
    Vue.component('Tabs', Tabs)
    Vue.component('CategoryGrid', CategoryGrid)
    Vue.component('AlgoliaAutocomplete', AlgoliaAutocomplete)
    Vue.component('ChatButton', ChatButton)
    Vue.component('Btn', Button)
    Vue.component('OpenNewTabButton', OpenNewTabButton)
    Vue.component('BrandValueIcon', BrandValueIcon)
    Vue.component('Checkbox', Checkbox)
    Vue.component('PrivacyAndTermsCheckbox', PrivacyAndTermsCheckbox)
    Vue.component('MarketingOptCheckbox', MarketingOptCheckbox)
    Vue.component('EntityImage', EntityImage)
    Vue.component('Chip', Chip)
    Vue.component('ErrorMessage', ErrorMessage)
    Vue.component('Badge', Badge)
    Vue.component('VideoPlayer', VideoPlayer)
    Vue.component('Toggle', Toggle)
    Vue.component('ToggleWithDescription', ToggleWithDescription)
    Vue.component('ProductSearchContainer', ProductSearchContainer)
    Vue.component('AddImage', AddImage)
    Vue.component('BrandSelector', BrandSelector)
    Vue.component('ProductVariantBox', ProductVariantBox)
    Vue.component('RetailerSelector', RetailerSelector)
    Vue.component('BulletinButton', BulletinButton)
    Vue.component('CreditCardSelector', CreditCardSelector)
    Vue.component('VariantPicker', VariantPicker)
    Vue.component('SingleVariantPicker', SingleVariantPicker)
    Vue.component('AddressView', AddressView)
    Vue.component('ProductCartSummary', ProductCartSummary)
    Vue.component('BrandValues', BrandValues)
    Vue.component('TextField', TextField)
    Vue.component('AddressAutocomplete', AddressAutocomplete)
    Vue.component('AddressForm', AddressForm)
    Vue.component('Heading4', Heading4)
    Vue.component('VariantCharacteristicList', VariantCharacteristicList)
    Vue.component('FilterAccordion', FilterAccordion)
    Vue.component('FilterPanel', FilterPanel)
    Vue.component('CheckboxNew', CheckboxNew)
    Vue.component('FilterRadioButton', FilterRadioButton)
    Vue.component('SearchInputField', SearchInputField)
    Vue.component('FilterBrandValues', FilterBrandValuesNew)
    Vue.component('FilterBrand', FilterBrand)
    Vue.component('FilterCategoriesNew', FilterCategoriesNew)
    Vue.component('FilterDateNew', FilterDateNew)
    Vue.component('FilterPriceNew', FilterPriceNew)
    Vue.component('FilterMinimumOrderValueNew', FilterMinimumOrderValueNew)
    Vue.component('FilterLocations', FilterLocations)
    Vue.component('FilterSections', FilterSections)
    Vue.component('FilterSpecialServices', FilterSpecialServices)
    Vue.component('FilterDealsPromotions', FilterDealsPromotions)
    Vue.component('FilterBadges', FilterBadges)
    Vue.component('SortingOptions', SortingOptions)
    Vue.component('MobileFilterHeader', MobileFilterHeader)
    Vue.component('MobileFilterPanel', MobileFilterPanel)
    Vue.component('ProductTiles', ProductTiles)
    Vue.component('FavoriteButton', FavoriteButton)
    Vue.component('CollectionSelector', CollectionSelector)
    Vue.component('ColorPickerInput', ColorPickerInput)
    Vue.component('SiteWideBanner', SiteWideBanner)
    Vue.component('NetTerms', NetTerms)
    Vue.component('CreditAvailable', CreditAvailable)
    Vue.component('Toast', Toast)
    Vue.component('FloatingLabel', FloatingLabel)
    Vue.component('TableSortIndicator', TableSortIndicator)
    Vue.component('AisInput', AisInput)
    Vue.component('Icon', Icon)
  }
}

export default SharedComponents
