/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductVariantInventoryLevelRequest } from './ProductVariantInventoryLevelRequest';
import {
    ProductVariantInventoryLevelRequestFromJSON,
    ProductVariantInventoryLevelRequestFromJSONTyped,
    ProductVariantInventoryLevelRequestToJSON,
    ProductVariantInventoryLevelRequestToJSONTyped,
} from './ProductVariantInventoryLevelRequest';

/**
 * Update Product Inventory level object
 * @export
 * @interface UpdateProductInventoryLevelRequest
 */
export interface UpdateProductInventoryLevelRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductInventoryLevelRequest
     */
    product_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductInventoryLevelRequest
     */
    track_inventory?: boolean;
    /**
     * 
     * @type {Array<ProductVariantInventoryLevelRequest>}
     * @memberof UpdateProductInventoryLevelRequest
     */
    variants?: Array<ProductVariantInventoryLevelRequest>;
}

/**
 * Check if a given object implements the UpdateProductInventoryLevelRequest interface.
 */
export function instanceOfUpdateProductInventoryLevelRequest(value: object): value is UpdateProductInventoryLevelRequest {
    return true;
}

export function UpdateProductInventoryLevelRequestFromJSON(json: any): UpdateProductInventoryLevelRequest {
    return UpdateProductInventoryLevelRequestFromJSONTyped(json, false);
}

export function UpdateProductInventoryLevelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProductInventoryLevelRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'product_id': json['product_id'] == null ? undefined : json['product_id'],
        'track_inventory': json['track_inventory'] == null ? undefined : json['track_inventory'],
        'variants': json['variants'] == null ? undefined : ((json['variants'] as Array<any>).map(ProductVariantInventoryLevelRequestFromJSON)),
    };
}

export function UpdateProductInventoryLevelRequestToJSON(json: any): UpdateProductInventoryLevelRequest {
    return UpdateProductInventoryLevelRequestToJSONTyped(json, false);
}

export function UpdateProductInventoryLevelRequestToJSONTyped(value?: UpdateProductInventoryLevelRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_id': value['product_id'],
        'track_inventory': value['track_inventory'],
        'variants': value['variants'] == null ? undefined : ((value['variants'] as Array<any>).map(ProductVariantInventoryLevelRequestToJSON)),
    };
}

