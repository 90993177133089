/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSuborderItemRequest
 */
export interface UpdateSuborderItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSuborderItemRequest
     */
    suborder_item_id: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSuborderItemRequest
     */
    fulfilled_quantity: number;
}

/**
 * Check if a given object implements the UpdateSuborderItemRequest interface.
 */
export function instanceOfUpdateSuborderItemRequest(value: object): value is UpdateSuborderItemRequest {
    if (!('suborder_item_id' in value) || value['suborder_item_id'] === undefined) return false;
    if (!('fulfilled_quantity' in value) || value['fulfilled_quantity'] === undefined) return false;
    return true;
}

export function UpdateSuborderItemRequestFromJSON(json: any): UpdateSuborderItemRequest {
    return UpdateSuborderItemRequestFromJSONTyped(json, false);
}

export function UpdateSuborderItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSuborderItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'suborder_item_id': json['suborder_item_id'],
        'fulfilled_quantity': json['fulfilled_quantity'],
    };
}

export function UpdateSuborderItemRequestToJSON(json: any): UpdateSuborderItemRequest {
    return UpdateSuborderItemRequestToJSONTyped(json, false);
}

export function UpdateSuborderItemRequestToJSONTyped(value?: UpdateSuborderItemRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suborder_item_id': value['suborder_item_id'],
        'fulfilled_quantity': value['fulfilled_quantity'],
    };
}

