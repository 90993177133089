<template>
  <div>
    <div>
      <div v-for="category in getFilteredList()" :key="category.id" class="mb-10">
        <h5 v-if="isCategoryPageParentFilterActive" class="font-size-12 uppercase mb-10">
          {{ category.name }}
        </h5>

        <checkbox-new
          v-else
          v-model="category.selected"
          :label="category.name"
          @click="handleParentCategoryClick(category.id)"
        />

        <div
          v-for="subcategory in category.view_components"
          :key="subcategory.id"
          class="child-items"
          :class="{ 'small-margin': isCategoryPageParentFilterActive }"
        >
          <checkbox-new
            v-model="subcategory.selected"
            :label="subcategory.info.text"
            @click="handleCategoryClick(category.id, subcategory.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filterHelper } from './filterHelper'
import { paramsHelper } from '../../../mixin/paramsHelper'
import { FilterKeys } from '../../../../services/utils'

export default {
  mixins: [paramsHelper, filterHelper],
  props: {
    preLoadedCategories: {
      type: Array,
      default: () => []
    },
    parentCategoryFilter: { type: String, default: '' },
    categoriesFilter: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      searchInputString: '',
      categories: [],
      filter: [],
      isCategoryPageParentFilterActive: false
    }
  },

  watch: {
    '$route.query.categories': function (query) {
      this.parseSelectedValues(query)
      this.transformListEmitFilterSelection()
    }
  },

  async beforeMount() {
    await this.getCategories()
    this.parseSelectedValues(this.$route.query.categories)
    this.transformListEmitFilterSelection()
  },

  methods: {
    parseSelectedValues,
    getCategories,
    handleParentCategoryClick,
    handleCategoryClick,
    createFilterFromSelectedCategories,
    transformListEmitFilterSelection,
    getFilteredList
  }
}

function getFilteredList() {
  const searchValue = this.getParentSearchValue()?.toLowerCase()
  if (!searchValue.length) {
    return this.categories
  }

  const parentNameMatch = this.categories.find((parent) =>
    parent.name.toLowerCase().includes(searchValue.toLowerCase())
  )
  if (parentNameMatch) {
    return [parentNameMatch]
  }

  return this.categories
    .filter((parent) => {
      return parent.view_components.filter((subitem) => subitem.info.text.toLowerCase().match(searchValue)).length
    })
    .map((parent) => {
      const subArray = parent.view_components.filter((subitem) => subitem.info.text.toLowerCase().match(searchValue))
      return {
        ...parent,
        view_components: subArray
      }
    })
}

function handleParentCategoryClick(categoryId) {
  const parent = this.categories.find((item) => item.id === categoryId)
  parent.selected = !parent.selected
  parent.view_components = parent?.view_components.map((child) => {
    return { ...child, selected: parent.selected }
  })
  this.createFilterFromSelectedCategories()
  this.trackFilterUsedEvent('category')
}

function handleCategoryClick(categoryId, subcategoryId) {
  const item = this.categories
    .find((item) => item.id === categoryId)
    ?.view_components.find((item) => item.id === subcategoryId)
  item.selected = !item.selected
  this.createFilterFromSelectedCategories()
  this.trackFilterUsedEvent('category')
}

function createFilterFromSelectedCategories() {
  this.updateFilterURLParams(
    'categories',
    this.$utils
      .flatteningList(this.categories, 'view_components')
      .filter((item) => item.selected)
      .map((item) => item.entity_id)
  )

  this.transformListEmitFilterSelection()
}

async function getCategories() {
  let categories = this.preLoadedCategories?.length
    ? this.preLoadedCategories
    : await this.$utils.getProductCategories(true)

  if (this.parentCategoryFilter) {
    this.categories = categories.filter((category) => category.id === this.parentCategoryFilter)
  } else if (this.categoriesFilter?.length) {
    this.categories = categories
      .filter((parentCategory) =>
        parentCategory.view_components.find((category) => this.categoriesFilter.includes(category.entity_id))
      )
      .map((parentCategory) => {
        const filteredCategories = parentCategory.view_components.filter((category) =>
          this.categoriesFilter.includes(category.entity_id)
        )
        return {
          ...parentCategory,
          selected: false,
          view_components: filteredCategories
        }
      })
  } else {
    this.categories = categories
  }

  this.isCategoryPageParentFilterActive = this.categories?.length === 1
}

function parseSelectedValues(selectedValuesQuery) {
  this.clearSelection(this.categories, 'view_components')
  selectedValuesQuery = this.$utils.arrayify(selectedValuesQuery)
  this.categories?.forEach((category) => {
    category.view_components.forEach((view_component) => {
      selectedValuesQuery.forEach((value) => {
        if (view_component.entity_id === value) {
          view_component.selected = true
        }
      })
    })

    if (category.view_components.every((item) => item.selected)) {
      category.selected = true
    }
  })
  this.$forceUpdate()
}

function transformListEmitFilterSelection() {
  this.emitFilterSelection({
    filterKey: FilterKeys.CATEGORIES,
    items: this.$utils.flatteningList(this.categories, 'view_components').map((item) => {
      return { label: item.info.text, value: item.entity_id, selected: item.selected }
    })
  })
}
</script>

<style scoped lang="scss">
.child-items {
  margin-left: 15px;
  &.small-margin {
    margin-left: 5px;
  }
}
</style>
