<template>
  <Icon :icon-key="iconKey" hover-type="ICON" :on-click="handleClick" />
</template>

<script>
import { navigationHelper } from '../mixin/navigationHelper'
import { breakPointsHelper } from '../mixin/breakPointsHelpers'

export default {
  mixins: [navigationHelper, breakPointsHelper],
  computed: {
    isMobile() {
      return this.underBreakpoint('md')
    },
    iconKey() {
      if (this.$route.name === 'favorites') return 'heart-active'
      return 'heart'
    }
  },
  methods: {
    handleClick
  }
}

function handleClick() {
  this.trackNavigationEvent('favorites', 'favorites')
}
</script>
