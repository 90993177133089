<template>
  <div>
    <board-create-edit
      v-if="isCreateNew"
      :on-back="toggleCreateNew"
      :on-close="closeFavorite"
      :on-success-callback="getAllBoards"
    />
    <board-unfavorite
      v-else-if="aysRemove"
      :toggle-drawer="handleCloseAysRemove"
      :on-close="delayClose"
      :board-count="boardIdsByContainedProduct.length"
      :on-remove="handleRemove"
    />
    <div v-else>
      <div
        class="favorite--overlay"
        :class="{
          'overlay-in-animation': animate === 'IN',
          'overlay-out-animation': animate === 'OUT'
        }"
        @click="delayClose"
      ></div>
      <div
        class="favorite-wrapper flex flex-direction-column z-22"
        :class="{
          'favorite-in-animation': animate === 'IN',
          'favorite-out-animation': animate === 'OUT'
        }"
      >
        <div class="favorite--header border-b-gray">
          <div class="flex items-center justify-end mx-15 mt-15">
            <Icon
              data-test-id="favorite_product_close_icon"
              icon-key="close"
              hover-type="OPACITY"
              :on-click="delayClose"
            />
          </div>
          <div class="flex justify-between items-center mx-15 mb-15">
            <div v-if="!isUnfavorite" class="flex items-center">
              <Icon icon-key="success-circled" />
              <span class="body-copy-1">Added to Favorites</span>
            </div>
            <div v-else class="flex items-center">
              <img height="114" width="91" :src="productImage" />
              <span class="b1 ml-20">Saved to Favorites</span>
            </div>
            <span data-test-id="favorite_product_remove" class="b1 underline" @click="handleOpenAysRemove">Remove</span>
          </div>
        </div>
        <div v-if="!isLoading" class="favorite--body-container">
          <div v-if="allBoards.length > 0" class="flex flex-direction-column">
            <div>
              <div class="flex justify-between items-center my-10">
                <heading4>Add to Boards</heading4>
                <div class="flex items-center">
                  <Icon icon-key="plus" :size="16" class="mr-3" />
                  <span
                    data-test-id="favorite_product_create"
                    class="body-copy-1 text-decoration-underline-grey pointer"
                    @click="toggleCreateNew"
                  >
                    Create New
                  </span>
                </div>
              </div>
              <board-item-card
                v-for="(board, index) in allBoards"
                :id="board.id"
                :key="index"
                :data-test-id="`favorite_product_card_${index}`"
                :images="board.images"
                :count="board.products_count"
                :name="board.name"
                :on-click="() => handleCheckBoard(board.id)"
                :checked="board.contains_product"
              />
            </div>
          </div>
          <!-- SCENARIO:: NO BOARDS -->
          <div v-else class="flex flex-direction-column">
            <heading4 class="mb-10">Add to Boards</heading4>
            <span class="body-copy-1 mb-10">
              Get started with your first board! Save and organize your products to plan your assortments in one place.
            </span>
            <bulletin-button
              data-test-id="favorite_product_no_board_create"
              class="half-width"
              variant="primary"
              @click="toggleCreateNew"
            >
              <span>Create Board</span>
            </bulletin-button>
          </div>
        </div>
        <div class="favorite--footer">
          <bulletin-button
            data-test-id="favorite_product_save"
            full-width
            variant="primary"
            name="checkout"
            :disabled="disableSaveButton"
            @click="handleSaveToBoard"
          >
            <span>Save</span>
          </bulletin-button>
          <span
            v-if="!isUnfavorite"
            data-test-id="favorite_product_skip"
            class="mt-15 body-copy-1 underline pointer"
            @click="handleSkipForNow"
          >
            Skip for now
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoardCreateEdit from './boardCreateEdit/BoardCreateEdit'
import BoardItemCard from './boardItemCard/boardItemCard'
import BoardUnfavorite from './boardUnfavorite/boardUnfavorite'
import { BoardApi } from '@/api'

export default {
  name: 'FavoriteDrawer',
  components: {
    BoardCreateEdit,
    BoardItemCard,
    BoardUnfavorite
  },
  props: ['id', 'onClose', 'isUnfavorite', 'onRemove', 'productImage', 'onSaveToBoard', 'trackingInfo'],
  data() {
    return {
      isCreateNew: false,
      allBoards: [],
      isLoading: false,
      originalySaved: [],
      aysRemove: false,
      animate: 'IN'
    }
  },
  computed: {
    boardIdsByContainedProduct() {
      return this.allBoards.filter((board) => board.contains_product).map((item) => item.id)
    },
    totalBoardsToUpdate() {
      return this.boardIdsByContainedProduct.length
    },
    disableSaveButton() {
      return (
        JSON.stringify([...this.originalySaved].sort()) === JSON.stringify([...this.boardIdsByContainedProduct].sort())
      )
    }
  },
  mounted() {
    this.getAllBoards()
  },
  methods: {
    closeFavorite() {
      this.onClose()
      this.isCreateNew = false
    },
    toggleCreateNew() {
      this.animate = ''
      this.isCreateNew = !this.isCreateNew
    },
    handleCheckBoard(boardId) {
      const selectedBoard = this.allBoards.find((board) => board.id === boardId)
      selectedBoard.contains_product = !selectedBoard.contains_product
    },
    handleOpenAysRemove() {
      this.aysRemove = true
    },
    handleCloseAysRemove() {
      this.animate = ''
      this.aysRemove = false
    },
    handleSkipForNow() {
      this.delayClose()
      this.toast({
        type: 'success',
        title: 'Success',
        message: `Saved to Favorites`
      })
    },
    handleSaveToBoard,
    getAllBoards,
    handleRemove,
    getTrackingInfoPerPage,
    delayClose
  }
}

async function getAllBoards(newlyCreatedId) {
  try {
    this.isLoading = true
    const { boards } = await BoardApi.getRetailerBoards1({ productId: this.id })
    this.allBoards = boards.filter((board) => board.name !== 'All Favorites')
    this.originalySaved = this.boardIdsByContainedProduct
    // NOTE: We check new board here after creation
    if (newlyCreatedId) {
      this.allBoards.find((board) => board.id === newlyCreatedId).contains_product = true
    }
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    this.isLoading = false
  }
}

async function handleSaveToBoard() {
  try {
    await BoardApi.associateProductWithBoards({
      associateProductWithBoardsRequest: {
        board_ids: this.boardIdsByContainedProduct,
        product_id: this.id
      }
    })
    if (this.onSaveToBoard) {
      await this.onSaveToBoard()
    } else {
      this.toast({
        type: 'success',
        title: 'Success',
        message:
          this.totalBoardsToUpdate > 0
            ? `Saved to ${this.totalBoardsToUpdate} board${this.totalBoardsToUpdate > 1 ? 's' : ''}`
            : 'Removed from all boards',
        actionName: 'Go to Boards',
        action: () => this.$router.push('/favorites')
      })
    }
    if (this.totalBoardsToUpdate > 0) {
      const additionalTrackingInfo = this.getTrackingInfoPerPage()
      this.$trackEvent({
        action: 'Add product to Bulletin Board',
        properties: { ...this.trackingInfo, ...additionalTrackingInfo }
      })
    }

    this.onClose()
  } catch (error) {
    this.$handleError(this, error)
  }
}

async function handleRemove() {
  await this.onRemove()
  if (this.onSaveToBoard) {
    await this.onSaveToBoard()
  }
  this.toast({
    type: 'success',
    title: 'Success',
    message: `Removed from Favorites${
      this.totalBoardsToUpdate
        ? ` and ${this.totalBoardsToUpdate} board${this.totalBoardsToUpdate === 1 ? '' : 's'}`
        : ''
    }.`
  })
}

function getTrackingInfoPerPage() {
  const routeName = this.$route.name
  switch (routeName) {
    case 'productsFromNavigation':
      return {
        Category: this.$route.params?.navItemName,
        'From Page': 'Category'
      }
    case 'CollectionWithCategory':
      return {
        Collection: this.$route.params?.collectionName,
        'From Page': 'Collection'
      }
    case 'search': {
      return {
        'Search Keyword': this.$route.query?.keyword,
        'From Page': 'Search'
      }
    }
    case 'BrandBoardsDetails': {
      return {
        'Board Creator': 'Brand',
        'From Page': 'Brand Board Details'
      }
    }
    case 'brand-bio': {
      return {
        'From Page': 'Brand Bio'
      }
    }
    case 'reorder': {
      return {
        'From Page': 'Reorder'
      }
    }
    default:
      return null
  }
}

function delayClose() {
  this.animate = 'OUT'
  setTimeout(() => {
    this.closeFavorite()
  }, 400)
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/vars.scss';
.border-b-gray {
  border-bottom: 1px solid $color-grey-3 !important;
}

.plus-icon {
  width: 24px;
  height: 24px;
  padding: 4px;
}
</style>
