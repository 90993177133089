/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Checks } from './Checks';
import {
    ChecksFromJSON,
    ChecksFromJSONTyped,
    ChecksToJSON,
    ChecksToJSONTyped,
} from './Checks';

/**
 * 
 * @export
 * @interface Validation
 */
export interface Validation {
    /**
     * 
     * @type {Checks}
     * @memberof Validation
     */
    checks: Checks;
}

/**
 * Check if a given object implements the Validation interface.
 */
export function instanceOfValidation(value: object): value is Validation {
    if (!('checks' in value) || value['checks'] === undefined) return false;
    return true;
}

export function ValidationFromJSON(json: any): Validation {
    return ValidationFromJSONTyped(json, false);
}

export function ValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Validation {
    if (json == null) {
        return json;
    }
    return {
        
        'checks': ChecksFromJSON(json['checks']),
    };
}

export function ValidationToJSON(json: any): Validation {
    return ValidationToJSONTyped(json, false);
}

export function ValidationToJSONTyped(value?: Validation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'checks': ChecksToJSON(value['checks']),
    };
}

