<template>
  <Icon :icon-key="`brand-value-${getValueIconByName()}`" :size="20" />
</template>

<script>
export default {
  props: {
    name: {
      type: String
    }
  },
  methods: {
    getValueIconByName() {
      const iconNameMap = {
        ZERO_WASTE: '1',
        SUSTAINABLE: '1',
        CLEAN_INGREDIENTS: '1',
        ORGANIC: '1',
        ETHICAL_PRODUCTION: '1',
        CERTIFIED_ORGANIC: '2',
        CERTIFIED_FAIR_TRADE: '2',
        MINORITY_OWNED_BUSINESS: '3',
        BLACK_OWNED: '3',
        LATINX_OWNED: '3',
        AAPI_OWNED: '3',
        INDIGENOUS_OWNED: '3',
        LGBTQ_OWNED: '3',
        DISABILITY_OWNED: '3',
        FEMALE_FOUNDED: '4',
        CHARITABLE_BRAND_GIVE_BACK: '5',
        HANDMADE: '6',
        MADE_IN_THE_US: '7'
      }

      return iconNameMap[this.name]
    }
  }
}
</script>
