/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailersDirectoryResponse
 */
export interface RetailersDirectoryResponse {
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    store_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    assortment_requested?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    status?: RetailersDirectoryResponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof RetailersDirectoryResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    banned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RetailersDirectoryResponse
     */
    balance_level?: RetailersDirectoryResponseBalanceLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    is_public?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    is_approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    is_declined?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RetailersDirectoryResponse
     */
    orders_places?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    payment_plan_enrolled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailersDirectoryResponse
     */
    review_required?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RetailersDirectoryResponse
     */
    credit_limit?: number;
}


/**
 * @export
 */
export const RetailersDirectoryResponseStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Banned: 'BANNED'
} as const;
export type RetailersDirectoryResponseStatusEnum = typeof RetailersDirectoryResponseStatusEnum[keyof typeof RetailersDirectoryResponseStatusEnum];

/**
 * @export
 */
export const RetailersDirectoryResponseBalanceLevelEnum = {
    _500: '500',
    _2000: '2000',
    _2500: '2500',
    _5000: '5000',
    _7500: '7500',
    _10000: '10000',
    _15000: '15000',
    _20000: '20000',
    _25000: '25000',
    _100000: '100000'
} as const;
export type RetailersDirectoryResponseBalanceLevelEnum = typeof RetailersDirectoryResponseBalanceLevelEnum[keyof typeof RetailersDirectoryResponseBalanceLevelEnum];


/**
 * Check if a given object implements the RetailersDirectoryResponse interface.
 */
export function instanceOfRetailersDirectoryResponse(value: object): value is RetailersDirectoryResponse {
    return true;
}

export function RetailersDirectoryResponseFromJSON(json: any): RetailersDirectoryResponse {
    return RetailersDirectoryResponseFromJSONTyped(json, false);
}

export function RetailersDirectoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailersDirectoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'name': json['name'] == null ? undefined : json['name'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'city': json['city'] == null ? undefined : json['city'],
        'store_url': json['store_url'] == null ? undefined : json['store_url'],
        'assortment_requested': json['assortment_requested'] == null ? undefined : json['assortment_requested'],
        'status': json['status'] == null ? undefined : json['status'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'banned': json['banned'] == null ? undefined : json['banned'],
        'balance_level': json['balance_level'] == null ? undefined : json['balance_level'],
        'is_public': json['is_public'] == null ? undefined : json['is_public'],
        'is_approved': json['is_approved'] == null ? undefined : json['is_approved'],
        'is_declined': json['is_declined'] == null ? undefined : json['is_declined'],
        'orders_places': json['orders_places'] == null ? undefined : json['orders_places'],
        'payment_plan_enrolled': json['payment_plan_enrolled'] == null ? undefined : json['payment_plan_enrolled'],
        'review_required': json['review_required'] == null ? undefined : json['review_required'],
        'credit_limit': json['credit_limit'] == null ? undefined : json['credit_limit'],
    };
}

export function RetailersDirectoryResponseToJSON(json: any): RetailersDirectoryResponse {
    return RetailersDirectoryResponseToJSONTyped(json, false);
}

export function RetailersDirectoryResponseToJSONTyped(value?: RetailersDirectoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'name': value['name'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'city': value['city'],
        'store_url': value['store_url'],
        'assortment_requested': value['assortment_requested'],
        'status': value['status'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'banned': value['banned'],
        'balance_level': value['balance_level'],
        'is_public': value['is_public'],
        'is_approved': value['is_approved'],
        'is_declined': value['is_declined'],
        'orders_places': value['orders_places'],
        'payment_plan_enrolled': value['payment_plan_enrolled'],
        'review_required': value['review_required'],
        'credit_limit': value['credit_limit'],
    };
}

