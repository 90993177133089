/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnumOptionRetailerInformedFrom
 */
export interface EnumOptionRetailerInformedFrom {
    /**
     * 
     * @type {string}
     * @memberof EnumOptionRetailerInformedFrom
     */
    value?: EnumOptionRetailerInformedFromValueEnum;
    /**
     * 
     * @type {string}
     * @memberof EnumOptionRetailerInformedFrom
     */
    label?: string;
}


/**
 * @export
 */
export const EnumOptionRetailerInformedFromValueEnum = {
    BulletinEmailOutreach: 'Bulletin Email Outreach',
    BulletinInstagram: 'Bulletin Instagram',
    PressOrPublication: 'Press or Publication',
    WordOfMouth: 'Word of mouth',
    ReferredByABrand: 'Referred by a Brand',
    ReferredByARetailer: 'Referred by a Retailer',
    ReferredByAFriend: 'Referred by a Friend',
    FamiliarWithBulletinStores: 'Familiar with Bulletin Stores',
    ExternalFacebookGroup: 'External Facebook Group',
    NyNowDiscoveryShow: 'NY Now Discovery Show',
    ReassembledTradeShow: 'Reassembled Trade Show',
    SupportTheStoresCampaign: 'Support the Stores Campaign',
    Other: 'Other'
} as const;
export type EnumOptionRetailerInformedFromValueEnum = typeof EnumOptionRetailerInformedFromValueEnum[keyof typeof EnumOptionRetailerInformedFromValueEnum];


/**
 * Check if a given object implements the EnumOptionRetailerInformedFrom interface.
 */
export function instanceOfEnumOptionRetailerInformedFrom(value: object): value is EnumOptionRetailerInformedFrom {
    return true;
}

export function EnumOptionRetailerInformedFromFromJSON(json: any): EnumOptionRetailerInformedFrom {
    return EnumOptionRetailerInformedFromFromJSONTyped(json, false);
}

export function EnumOptionRetailerInformedFromFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumOptionRetailerInformedFrom {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function EnumOptionRetailerInformedFromToJSON(json: any): EnumOptionRetailerInformedFrom {
    return EnumOptionRetailerInformedFromToJSONTyped(json, false);
}

export function EnumOptionRetailerInformedFromToJSONTyped(value?: EnumOptionRetailerInformedFrom | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'label': value['label'],
    };
}

