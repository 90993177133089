/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderReturnReasonResponse
 */
export interface OrderReturnReasonResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    reason_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    reason_value?: string;
}

/**
 * Check if a given object implements the OrderReturnReasonResponse interface.
 */
export function instanceOfOrderReturnReasonResponse(value: object): value is OrderReturnReasonResponse {
    return true;
}

export function OrderReturnReasonResponseFromJSON(json: any): OrderReturnReasonResponse {
    return OrderReturnReasonResponseFromJSONTyped(json, false);
}

export function OrderReturnReasonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderReturnReasonResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'reason_name': json['reason_name'] == null ? undefined : json['reason_name'],
        'reason_value': json['reason_value'] == null ? undefined : json['reason_value'],
    };
}

export function OrderReturnReasonResponseToJSON(json: any): OrderReturnReasonResponse {
    return OrderReturnReasonResponseToJSONTyped(json, false);
}

export function OrderReturnReasonResponseToJSONTyped(value?: OrderReturnReasonResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reason_name': value['reason_name'],
        'reason_value': value['reason_value'],
    };
}

