<template>
  <div class="mobile-menu">
    <div v-if="activeSubSection" class="back-container" @click="setActiveSubSection()">
      <Icon icon-key="chevron-left" hover-type="OPACITY" />
      <div class="back-label">
        {{ backLabel }}
      </div>
    </div>
    <Icon icon-key="close" hover-type="OPACITY" class="close-container" :on-click="closeMenu" />

    <div class="menu-content">
      <div class="scroll-area">
        <template v-if="!activeSubSection">
          <!-- PROFILE -->
          <div v-if="user" class="menu-section small-margin-top">
            <h3 class="section-heading">Profile</h3>
            <div class="menu-section-list">
              <div
                class="menu-section-list-item flex justify-between align-center pointer"
                @click="setActiveSubSection('DASHBOARD')"
              >
                <div class="flex align-center">
                  <Icon icon-key="profile" class="mr-5" />
                  <span>Dashboard</span>
                </div>
                <Icon icon-key="chevron-right" hover-type="OPACITY" />
              </div>

              <bulletin-button
                v-if="user.role === 'RETAILER'"
                variant="primary"
                to="/reorder"
                class="mt-10"
                full-width
                @click.native="closeMenu()"
              >
                Reorder
              </bulletin-button>
            </div>
          </div>

          <!--        EXPLORER-->
          <div class="menu-section" :class="{ 'small-margin-top': !user }">
            <h3 class="section-heading">Explore</h3>

            <div class="menu-section-list category-list">
              <div
                v-for="(parentCategory, index) in parentCategories"
                :key="parentCategory.id"
                :class="[
                  'menu-section-list-item',
                  'flex',
                  'justify-between',
                  'align-center',
                  { 'border-bottom': index === 1 }
                ]"
                @click="handleCategoryClick(parentCategory)"
              >
                {{ parentCategory.name }}
                <Icon v-if="index !== 1" icon-key="chevron-right" hover-type="OPACITY" />
              </div>

              <router-link
                class="menu-section-list-item flex justify-between align-center"
                to="/brands"
                data-test-id="header_link_brands"
                data-cy="browse-brands-link"
                @click.native="
                  trackNavigationEvent('brand directory', 'brand directory')
                  closeMenu()
                "
              >
                Browse by Brands
              </router-link>
            </div>
          </div>
        </template>

        <!-- CREDIT AVAILABLE -->
        <credit-available class="mt-20" />

        <!--DASHBOARD-->
        <dashboard-mobile-menu v-if="activeSubSection === 'DASHBOARD'" />

        <!-- SUB CATEGORY-->
        <sub-category-menu
          v-if="activeSubSection === 'CATEGORY'"
          class="menu-section sub-category-menu small-margin-top"
          :categories="categories"
          :mobile="true"
          :select-category-container="setSelectedCategoryContainer"
        />

        <div v-if="!activeSubSection" class="bottom-buttons">
          <template v-if="!user">
            <bulletin-button to="/wholesale-retail/buyer" full-width variant="primary" @click.native="closeMenu()">
              Join Us
            </bulletin-button>
            <bulletin-button to="/sign-in" class="mt-20" variant="tertiary" full-width @click.native="closeMenu()">
              Log in
            </bulletin-button>
          </template>
          <bulletin-button v-if="user && user.role" full-width @click="handleLogout()"> Log out </bulletin-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bodyScrollHelper } from '@/components/mixin/bodyScrollHelper'
import { mapGetters } from 'vuex'
import SubCategoryMenu from '@/components/navigation/SubCategoryMenu.vue'
import { sessionHelper } from '../../mixin/sessionHelper'
import { navigationHelper } from '../../mixin/navigationHelper'
import { dialogHelper } from '../../mixin/dialogHelper'
import DashboardMobileMenu from '@/components/navigation/TheMainMobileMenu/DashboardMobileMenu'

export default {
  components: {
    DashboardMobileMenu,
    SubCategoryMenu
  },
  mixins: [bodyScrollHelper, sessionHelper, navigationHelper, dialogHelper],
  props: {
    categories: {
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {
      show: false,
      activeSubSection: null,
      selectedCategoryContainer: null
    }
  },

  computed: {
    ...mapGetters({
      user: 'userState',
      activeCategoryId: 'navigation/activeCategory',
      canSendMessage: 'canSendMessage'
    }),
    messageCount() {
      return this.$store.getters.notificationState
    },
    parentCategories() {
      if (!this.categories?.children_containers.some((container) => container.name === 'NY NOW')) {
        // eslint-disable-next-line vue/no-mutating-props, vue/no-side-effects-in-computed-properties
        this.categories?.children_containers.splice(1, 0, { name: 'NY NOW', id: 'nynow' })
      }
      return this.categories?.children_containers
    },
    backLabel() {
      if (!this.activeSubSection) {
        return
      }

      if (!this.selectedCategoryContainer) {
        return 'MAIN MENU'
      }

      return this.activeCategory ? `ALL ${this.activeCategory?.name}` : null
    },
    activeCategory() {
      return this.categories?.children_containers?.find((item) => item.id.toString() === this.activeCategoryId)
    }
  },
  mounted() {
    this.show = true

    if (this.isOnADashboardPage) {
      this.activeSubSection = 'DASHBOARD'
    }
  },
  methods: {
    closeMenu,
    setActiveSubSection,
    handleLogout,
    setActiveCategory,
    handleCategoryClick,
    setSelectedCategoryContainer
  }
}

function handleCategoryClick(parentCategory) {
  this.setActiveCategory(parentCategory)
  this.setActiveSubSection('CATEGORY')
}

function setActiveCategory(parentCategory) {
  if (parentCategory.id === 'nynow') {
    const nynowLink = {
      name: 'nynow',
      query: {
        sortBy: 'brands_made_active_desc',
        page: 1
      }
    }
    this.$router.push(nynowLink)
    this.closeMenu()
  } else this.$store.dispatch('navigation/setActiveCategory', parentCategory.id)
}

function closeMenu() {
  this.$store.dispatch('navigation/hideSideBar')
}

function handleLogout() {
  this.logout()
  this.closeMenu()
}
function setActiveSubSection(sectionKey) {
  if (this.selectedCategoryContainer) {
    this.$eventBus.$emit('clear-selected-category-container')
    return
  }

  this.activeSubSection = sectionKey
}

function setSelectedCategoryContainer(container) {
  this.selectedCategoryContainer = container
}
</script>

<style lang="scss" scoped src="./TheMainMobileMenu.scss" />
