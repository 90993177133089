/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ViewComponentResponse } from './ViewComponentResponse';
import {
    ViewComponentResponseFromJSON,
    ViewComponentResponseFromJSONTyped,
    ViewComponentResponseToJSON,
    ViewComponentResponseToJSONTyped,
} from './ViewComponentResponse';

/**
 * 
 * @export
 * @interface ChildrenContainerResponse
 */
export interface ChildrenContainerResponse {
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerResponse
     */
    parent_container_id?: string;
    /**
     * 
     * @type {Array<ViewComponentResponse>}
     * @memberof ChildrenContainerResponse
     */
    view_components?: Array<ViewComponentResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ChildrenContainerResponse
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChildrenContainerResponse
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerResponse
     */
    container_direction?: ChildrenContainerResponseContainerDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerResponse
     */
    container_type?: ChildrenContainerResponseContainerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChildrenContainerResponse
     */
    name?: string;
}


/**
 * @export
 */
export const ChildrenContainerResponseContainerDirectionEnum = {
    Row: 'ROW',
    Column: 'COLUMN',
    Grid: 'GRID'
} as const;
export type ChildrenContainerResponseContainerDirectionEnum = typeof ChildrenContainerResponseContainerDirectionEnum[keyof typeof ChildrenContainerResponseContainerDirectionEnum];

/**
 * @export
 */
export const ChildrenContainerResponseContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type ChildrenContainerResponseContainerTypeEnum = typeof ChildrenContainerResponseContainerTypeEnum[keyof typeof ChildrenContainerResponseContainerTypeEnum];


/**
 * Check if a given object implements the ChildrenContainerResponse interface.
 */
export function instanceOfChildrenContainerResponse(value: object): value is ChildrenContainerResponse {
    return true;
}

export function ChildrenContainerResponseFromJSON(json: any): ChildrenContainerResponse {
    return ChildrenContainerResponseFromJSONTyped(json, false);
}

export function ChildrenContainerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildrenContainerResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'parent_container_id': json['parent_container_id'] == null ? undefined : json['parent_container_id'],
        'view_components': json['view_components'] == null ? undefined : ((json['view_components'] as Array<any>).map(ViewComponentResponseFromJSON)),
        'active': json['active'] == null ? undefined : json['active'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'container_direction': json['container_direction'] == null ? undefined : json['container_direction'],
        'container_type': json['container_type'] == null ? undefined : json['container_type'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ChildrenContainerResponseToJSON(json: any): ChildrenContainerResponse {
    return ChildrenContainerResponseToJSONTyped(json, false);
}

export function ChildrenContainerResponseToJSONTyped(value?: ChildrenContainerResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'parent_container_id': value['parent_container_id'],
        'view_components': value['view_components'] == null ? undefined : ((value['view_components'] as Array<any>).map(ViewComponentResponseToJSON)),
        'active': value['active'],
        'ordinal': value['ordinal'],
        'container_direction': value['container_direction'],
        'container_type': value['container_type'],
        'name': value['name'],
    };
}

