import { paramsHelper } from '../../../mixin/paramsHelper'

export const filterHelper = {
  mixins: [paramsHelper],
  methods: {
    updateFilterURLParams: function (queryKey, value) {
      this.updateURLParams(queryKey, value)
      this.updateURLParams('page', 1)
    },
    emitFilterSelection({ filterKey, items }) {
      const selectedItems = items.filter((item) => item.selected)
      let totalCount = selectedItems.length
      // NOTE:: Include states in total count of countries
      if (filterKey.toString() === 'country') {
        const stateCount = items.reduce((result, item) => {
          return result + item.states.filter((state) => state.selected).length
        }, 0)
        totalCount += stateCount
      }
      this.$parent.setSelectionCount(totalCount)

      this.$store.commit('filters/setFilters', { filterKey, activeFilters: selectedItems })
    },
    getFilteredItems: function (items, searchKey, singleSelectedItem) {
      if (!this.searchValue || !searchKey) return items
      if (singleSelectedItem) {
        return items.filter((item) => item.id === singleSelectedItem.id)
      }
      return items.filter((item) => item[searchKey].toLowerCase().includes(this.searchValue?.toLowerCase()))
    },
    clearSelection: function (list, childListKey) {
      list?.forEach((item) => {
        item.selected = false
        if (childListKey) {
          item[childListKey]?.forEach((item) => (item.selected = false))
        }
      })
    },
    setParentSearchValue: function (value) {
      this.$parent?.setSearchValue(value)
    },
    getParentSearchValue: function () {
      return this.$parent.searchValue
    },
    updateParentSelectionCount: function (items) {
      const selectedItems = items.filter((item) => item.selected)
      this.$parent.setSelectionCount(selectedItems.length)
    },
    setParentSelectionCountValue: function (value) {
      this.$parent.setSelectionCount(value)
    },
    clearSelectionOnSingleSelect: function (items) {
      items.forEach((item) => (item.selected = false))
    },
    trackFilterUsedEvent: function (type) {
      this.$trackEvent({
        action: 'Filter Used',
        properties: {
          'filter type': type
        }
      })
    }
  },
  computed: {
    searchValue: function () {
      return this.$parent.searchValue || ''
    }
  }
}
