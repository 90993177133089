/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface RetailerResponse
 */
export interface RetailerResponse {
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    store_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    store_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailerResponse
     */
    store_types?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    year_founded?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    facebook?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    balance_level?: RetailerResponseBalanceLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof RetailerResponse
     */
    credit_used?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailerResponse
     */
    credit_left?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailerResponse
     */
    credit_level?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailerResponse
     */
    balance?: number;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    status?: RetailerResponseStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailerResponse
     */
    values?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailerResponse
     */
    store_gallery?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    city?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerResponse
     */
    is_public?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerResponse
     */
    is_approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerResponse
     */
    is_declined?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RetailerResponse
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    phone_number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerResponse
     */
    payment_plan_enrolled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerResponse
     */
    review_required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerResponse
     */
    use_balance?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    balance_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    qualification_status?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerResponse
     */
    user_id?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof RetailerResponse
     */
    billing_address?: AddressResponse;
}


/**
 * @export
 */
export const RetailerResponseBalanceLevelEnum = {
    _500: '500',
    _2000: '2000',
    _2500: '2500',
    _5000: '5000',
    _7500: '7500',
    _10000: '10000',
    _15000: '15000',
    _20000: '20000',
    _25000: '25000',
    _100000: '100000'
} as const;
export type RetailerResponseBalanceLevelEnum = typeof RetailerResponseBalanceLevelEnum[keyof typeof RetailerResponseBalanceLevelEnum];

/**
 * @export
 */
export const RetailerResponseStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Banned: 'BANNED'
} as const;
export type RetailerResponseStatusEnum = typeof RetailerResponseStatusEnum[keyof typeof RetailerResponseStatusEnum];


/**
 * Check if a given object implements the RetailerResponse interface.
 */
export function instanceOfRetailerResponse(value: object): value is RetailerResponse {
    return true;
}

export function RetailerResponseFromJSON(json: any): RetailerResponse {
    return RetailerResponseFromJSONTyped(json, false);
}

export function RetailerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'store_name': json['store_name'] == null ? undefined : json['store_name'],
        'store_url': json['store_url'] == null ? undefined : json['store_url'],
        'store_types': json['store_types'] == null ? undefined : json['store_types'],
        'location': json['location'] == null ? undefined : json['location'],
        'year_founded': json['year_founded'] == null ? undefined : json['year_founded'],
        'instagram': json['instagram'] == null ? undefined : json['instagram'],
        'facebook': json['facebook'] == null ? undefined : json['facebook'],
        'twitter': json['twitter'] == null ? undefined : json['twitter'],
        'bio': json['bio'] == null ? undefined : json['bio'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'balance_level': json['balance_level'] == null ? undefined : json['balance_level'],
        'credit_used': json['credit_used'] == null ? undefined : json['credit_used'],
        'credit_left': json['credit_left'] == null ? undefined : json['credit_left'],
        'credit_level': json['credit_level'] == null ? undefined : json['credit_level'],
        'balance': json['balance'] == null ? undefined : json['balance'],
        'status': json['status'] == null ? undefined : json['status'],
        'values': json['values'] == null ? undefined : json['values'],
        'store_gallery': json['store_gallery'] == null ? undefined : json['store_gallery'],
        'city': json['city'] == null ? undefined : json['city'],
        'is_public': json['is_public'] == null ? undefined : json['is_public'],
        'is_approved': json['is_approved'] == null ? undefined : json['is_approved'],
        'is_declined': json['is_declined'] == null ? undefined : json['is_declined'],
        'score': json['score'] == null ? undefined : json['score'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'payment_plan_enrolled': json['payment_plan_enrolled'] == null ? undefined : json['payment_plan_enrolled'],
        'review_required': json['review_required'] == null ? undefined : json['review_required'],
        'use_balance': json['use_balance'] == null ? undefined : json['use_balance'],
        'balance_id': json['balance_id'] == null ? undefined : json['balance_id'],
        'qualification_status': json['qualification_status'] == null ? undefined : json['qualification_status'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
        'billing_address': json['billing_address'] == null ? undefined : AddressResponseFromJSON(json['billing_address']),
    };
}

export function RetailerResponseToJSON(json: any): RetailerResponse {
    return RetailerResponseToJSONTyped(json, false);
}

export function RetailerResponseToJSONTyped(value?: RetailerResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'store_name': value['store_name'],
        'store_url': value['store_url'],
        'store_types': value['store_types'],
        'location': value['location'],
        'year_founded': value['year_founded'],
        'instagram': value['instagram'],
        'facebook': value['facebook'],
        'twitter': value['twitter'],
        'bio': value['bio'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'balance_level': value['balance_level'],
        'credit_used': value['credit_used'],
        'credit_left': value['credit_left'],
        'credit_level': value['credit_level'],
        'balance': value['balance'],
        'status': value['status'],
        'values': value['values'],
        'store_gallery': value['store_gallery'],
        'city': value['city'],
        'is_public': value['is_public'],
        'is_approved': value['is_approved'],
        'is_declined': value['is_declined'],
        'score': value['score'],
        'phone_number': value['phone_number'],
        'payment_plan_enrolled': value['payment_plan_enrolled'],
        'review_required': value['review_required'],
        'use_balance': value['use_balance'],
        'balance_id': value['balance_id'],
        'qualification_status': value['qualification_status'],
        'user_id': value['user_id'],
        'billing_address': AddressResponseToJSON(value['billing_address']),
    };
}

