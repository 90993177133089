/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumOptionPromoCodeStatus } from './EnumOptionPromoCodeStatus';
import {
    EnumOptionPromoCodeStatusFromJSON,
    EnumOptionPromoCodeStatusFromJSONTyped,
    EnumOptionPromoCodeStatusToJSON,
    EnumOptionPromoCodeStatusToJSONTyped,
} from './EnumOptionPromoCodeStatus';

/**
 * 
 * @export
 * @interface GetEnumOptionsResponsePromoCodeStatus
 */
export interface GetEnumOptionsResponsePromoCodeStatus {
    /**
     * 
     * @type {Array<EnumOptionPromoCodeStatus>}
     * @memberof GetEnumOptionsResponsePromoCodeStatus
     */
    options?: Array<EnumOptionPromoCodeStatus>;
}

/**
 * Check if a given object implements the GetEnumOptionsResponsePromoCodeStatus interface.
 */
export function instanceOfGetEnumOptionsResponsePromoCodeStatus(value: object): value is GetEnumOptionsResponsePromoCodeStatus {
    return true;
}

export function GetEnumOptionsResponsePromoCodeStatusFromJSON(json: any): GetEnumOptionsResponsePromoCodeStatus {
    return GetEnumOptionsResponsePromoCodeStatusFromJSONTyped(json, false);
}

export function GetEnumOptionsResponsePromoCodeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEnumOptionsResponsePromoCodeStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'] == null ? undefined : ((json['options'] as Array<any>).map(EnumOptionPromoCodeStatusFromJSON)),
    };
}

export function GetEnumOptionsResponsePromoCodeStatusToJSON(json: any): GetEnumOptionsResponsePromoCodeStatus {
    return GetEnumOptionsResponsePromoCodeStatusToJSONTyped(json, false);
}

export function GetEnumOptionsResponsePromoCodeStatusToJSONTyped(value?: GetEnumOptionsResponsePromoCodeStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': value['options'] == null ? undefined : ((value['options'] as Array<any>).map(EnumOptionPromoCodeStatusToJSON)),
    };
}

