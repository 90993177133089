let origin = window.location.origin

export default {
  getOrigin() {
    switch (origin) {
      case 'http://localhost:8080':
      case 'https://localdevshop.bulletin.co':
      case 'https://devshop.bulletin.co':
        return 'develop'

      case 'https://stageshop.bulletin.co':
        return 'stage'

      case 'https://local.bulletin.co':
      case 'https://bulletin.co':
        return 'prod'
    }
  },

  algolia() {
    switch (origin) {
      case 'http://localhost:8080':
      case 'https://localdevshop.bulletin.co':
      case 'https://devshop.bulletin.co':
      case 'https://stageshop.bulletin.co':
        return {
          appId: 'C27IKO5C3J',
          token: '311f647be7e38c1e500ee05962c2787c'
        }

      case 'https://local.bulletin.co':
      case 'https://bulletin.co':
        return {
          appId: '3ILKBK4XB6',
          token: '58f07d980dd16d0423322ca96aa03915'
        }
    }
  },

  mixpanelToken() {
    switch (origin) {
      case 'http://localhost:8080':
      case 'https://localdevshop.bulletin.co':
      case 'https://devshop.bulletin.co':
        return '3b56342926b1a80a7b8e0c0a481b5825'

      case 'https://stageshop.bulletin.co':
        return '3b56342926b1a80a7b8e0c0a481b5825'

      case 'https://local.bulletin.co':
      case 'https://bulletin.co':
        return '5533ac71c0107b1f22000893874db7ac'
    }
  },

  apiUrl() {
    switch (origin) {
      case 'http://localhost:8080':
        return 'https://api-develop.bulletin.co'

      case 'https://localdevshop.bulletin.co':
        return 'https://api-develop.bulletin.co'

      case 'https://devshop.bulletin.co':
        return 'https://api-develop.bulletin.co'

      case 'https://stageshop.bulletin.co':
        return 'https://api-stage.bulletin.co'

      case 'https://local.bulletin.co':
      case 'https://bulletin.co':
        return 'https://api.bulletin.co'
    }
  },

  feedApiUrl() {
    switch (origin) {
      case 'https://localdevshop.bulletin.co':
        return 'http://127.0.0.1:5000'

      case 'http://localhost:8080':
        return 'https://feed-develop.bulletin.co'

      case 'https://devshop.bulletin.co':
        return 'https://feed-develop.bulletin.co'

      case 'https://stageshop.bulletin.co':
        return 'https://feed-stage.bulletin.co'
      case 'https://local.bulletin.co':
      case 'https://bulletin.co':
        return 'https://feed.bulletin.co'
    }
  },

  googleAnalytics() {
    var origin = this.getOrigin()

    switch (origin) {
      case 'localdev':
      case 'develop':
        return 'UA-88243972-4'

      case 'stage':
        return 'UA-88243972-3'

      case 'prod':
        return 'UA-88243972-2'
    }
  },

  googleAnalyticsV4() {
    switch (origin) {
      case 'http://localhost:8080':
      case 'https://devshop.bulletin.co':
        return '344136847'
      case 'https://stageshop.bulletin.co':
        return '344136847'
      case 'https://bulletin.co':
        return '343360398'
    }
  },

  shouldAuthenticateFeed() {
    const origin = this.getOrigin()

    switch (origin) {
      case 'prod':
        return true
      default:
        return false
    }
  },

  pusherOptions() {
    const origin = this.getOrigin()

    switch (origin) {
      case 'stage':
        return {
          key: '07013de949570d954dc5',
          options: {
            cluster: 'mt1'
          }
        }

      case 'prod':
        return {
          key: '63a9264c79a0a7bc38b9',
          options: {
            cluster: 'mt1'
          }
        }

      case 'develop':
      default:
        return {
          key: '0d7f16500f7265124e74',
          options: {
            cluster: 'mt1'
          }
        }
    }
  },

  rudderStackWriteKey() {
    var origin = this.getOrigin()

    switch (origin) {
      case 'localdev':
      case 'develop':
        return '2N9b3ciqbPI7KEveGcFAHuPBJlk'

      case 'stage':
        return '2N9bFjdmXIjEbfehtolQOMwukks'

      case 'prod':
        return '2N9bM4MDs0YR5e2IlANHahrsk4o'
    }
  }
}
