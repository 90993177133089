/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Ordinal
 * @export
 * @interface SetHomeOrdinalRequest
 */
export interface SetHomeOrdinalRequest {
    /**
     * 
     * @type {number}
     * @memberof SetHomeOrdinalRequest
     */
    home_ordinal?: number;
}

/**
 * Check if a given object implements the SetHomeOrdinalRequest interface.
 */
export function instanceOfSetHomeOrdinalRequest(value: object): value is SetHomeOrdinalRequest {
    return true;
}

export function SetHomeOrdinalRequestFromJSON(json: any): SetHomeOrdinalRequest {
    return SetHomeOrdinalRequestFromJSONTyped(json, false);
}

export function SetHomeOrdinalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetHomeOrdinalRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'home_ordinal': json['home_ordinal'] == null ? undefined : json['home_ordinal'],
    };
}

export function SetHomeOrdinalRequestToJSON(json: any): SetHomeOrdinalRequest {
    return SetHomeOrdinalRequestToJSONTyped(json, false);
}

export function SetHomeOrdinalRequestToJSONTyped(value?: SetHomeOrdinalRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'home_ordinal': value['home_ordinal'],
    };
}

