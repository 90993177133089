/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateProductInventoryLevelRequest } from './UpdateProductInventoryLevelRequest';
import {
    UpdateProductInventoryLevelRequestFromJSON,
    UpdateProductInventoryLevelRequestFromJSONTyped,
    UpdateProductInventoryLevelRequestToJSON,
    UpdateProductInventoryLevelRequestToJSONTyped,
} from './UpdateProductInventoryLevelRequest';

/**
 * Update Products Inventory levels object
 * @export
 * @interface UpdateProductsInventoryLevelsRequest
 */
export interface UpdateProductsInventoryLevelsRequest {
    /**
     * 
     * @type {Array<UpdateProductInventoryLevelRequest>}
     * @memberof UpdateProductsInventoryLevelsRequest
     */
    products?: Array<UpdateProductInventoryLevelRequest>;
}

/**
 * Check if a given object implements the UpdateProductsInventoryLevelsRequest interface.
 */
export function instanceOfUpdateProductsInventoryLevelsRequest(value: object): value is UpdateProductsInventoryLevelsRequest {
    return true;
}

export function UpdateProductsInventoryLevelsRequestFromJSON(json: any): UpdateProductsInventoryLevelsRequest {
    return UpdateProductsInventoryLevelsRequestFromJSONTyped(json, false);
}

export function UpdateProductsInventoryLevelsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProductsInventoryLevelsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(UpdateProductInventoryLevelRequestFromJSON)),
    };
}

export function UpdateProductsInventoryLevelsRequestToJSON(json: any): UpdateProductsInventoryLevelsRequest {
    return UpdateProductsInventoryLevelsRequestToJSONTyped(json, false);
}

export function UpdateProductsInventoryLevelsRequestToJSONTyped(value?: UpdateProductsInventoryLevelsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(UpdateProductInventoryLevelRequestToJSON)),
    };
}

