/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderPreviewAddressResponse
 */
export interface OrderPreviewAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewAddressResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewAddressResponse
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewAddressResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewAddressResponse
     */
    zip_code?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewAddressResponse
     */
    street?: string;
}

/**
 * Check if a given object implements the OrderPreviewAddressResponse interface.
 */
export function instanceOfOrderPreviewAddressResponse(value: object): value is OrderPreviewAddressResponse {
    return true;
}

export function OrderPreviewAddressResponseFromJSON(json: any): OrderPreviewAddressResponse {
    return OrderPreviewAddressResponseFromJSONTyped(json, false);
}

export function OrderPreviewAddressResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPreviewAddressResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': json['country'] == null ? undefined : json['country'],
        'zip_code': json['zip_code'] == null ? undefined : json['zip_code'],
        'street': json['street'] == null ? undefined : json['street'],
    };
}

export function OrderPreviewAddressResponseToJSON(json: any): OrderPreviewAddressResponse {
    return OrderPreviewAddressResponseToJSONTyped(json, false);
}

export function OrderPreviewAddressResponseToJSONTyped(value?: OrderPreviewAddressResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'city': value['city'],
        'state': value['state'],
        'country': value['country'],
        'zip_code': value['zip_code'],
        'street': value['street'],
    };
}

