/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnumOptionRetailerStoreType
 */
export interface EnumOptionRetailerStoreType {
    /**
     * 
     * @type {string}
     * @memberof EnumOptionRetailerStoreType
     */
    value?: EnumOptionRetailerStoreTypeValueEnum;
    /**
     * 
     * @type {string}
     * @memberof EnumOptionRetailerStoreType
     */
    label?: string;
}


/**
 * @export
 */
export const EnumOptionRetailerStoreTypeValueEnum = {
    BrickAndMortar: 'BRICK_AND_MORTAR',
    Online: 'ONLINE',
    PopUp: 'POP_UP'
} as const;
export type EnumOptionRetailerStoreTypeValueEnum = typeof EnumOptionRetailerStoreTypeValueEnum[keyof typeof EnumOptionRetailerStoreTypeValueEnum];


/**
 * Check if a given object implements the EnumOptionRetailerStoreType interface.
 */
export function instanceOfEnumOptionRetailerStoreType(value: object): value is EnumOptionRetailerStoreType {
    return true;
}

export function EnumOptionRetailerStoreTypeFromJSON(json: any): EnumOptionRetailerStoreType {
    return EnumOptionRetailerStoreTypeFromJSONTyped(json, false);
}

export function EnumOptionRetailerStoreTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumOptionRetailerStoreType {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function EnumOptionRetailerStoreTypeToJSON(json: any): EnumOptionRetailerStoreType {
    return EnumOptionRetailerStoreTypeToJSONTyped(json, false);
}

export function EnumOptionRetailerStoreTypeToJSONTyped(value?: EnumOptionRetailerStoreType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'label': value['label'],
    };
}

