/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketingCategoryResponse } from './MarketingCategoryResponse';
import {
    MarketingCategoryResponseFromJSON,
    MarketingCategoryResponseFromJSONTyped,
    MarketingCategoryResponseToJSON,
    MarketingCategoryResponseToJSONTyped,
} from './MarketingCategoryResponse';

/**
 * 
 * @export
 * @interface MarketingCategoriesResponse
 */
export interface MarketingCategoriesResponse {
    /**
     * 
     * @type {Array<MarketingCategoryResponse>}
     * @memberof MarketingCategoriesResponse
     */
    marketing_categories?: Array<MarketingCategoryResponse>;
}

/**
 * Check if a given object implements the MarketingCategoriesResponse interface.
 */
export function instanceOfMarketingCategoriesResponse(value: object): value is MarketingCategoriesResponse {
    return true;
}

export function MarketingCategoriesResponseFromJSON(json: any): MarketingCategoriesResponse {
    return MarketingCategoriesResponseFromJSONTyped(json, false);
}

export function MarketingCategoriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingCategoriesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'marketing_categories': json['marketing_categories'] == null ? undefined : ((json['marketing_categories'] as Array<any>).map(MarketingCategoryResponseFromJSON)),
    };
}

export function MarketingCategoriesResponseToJSON(json: any): MarketingCategoriesResponse {
    return MarketingCategoriesResponseToJSONTyped(json, false);
}

export function MarketingCategoriesResponseToJSONTyped(value?: MarketingCategoriesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'marketing_categories': value['marketing_categories'] == null ? undefined : ((value['marketing_categories'] as Array<any>).map(MarketingCategoryResponseToJSON)),
    };
}

