/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductsPagination } from './ProductsPagination';
import {
    ProductsPaginationFromJSON,
    ProductsPaginationFromJSONTyped,
    ProductsPaginationToJSON,
    ProductsPaginationToJSONTyped,
} from './ProductsPagination';
import type { ProductsDashboardResponse } from './ProductsDashboardResponse';
import {
    ProductsDashboardResponseFromJSON,
    ProductsDashboardResponseFromJSONTyped,
    ProductsDashboardResponseToJSON,
    ProductsDashboardResponseToJSONTyped,
} from './ProductsDashboardResponse';

/**
 * 
 * @export
 * @interface PaginatedProductsResponse
 */
export interface PaginatedProductsResponse {
    /**
     * 
     * @type {Array<ProductsDashboardResponse>}
     * @memberof PaginatedProductsResponse
     */
    products?: Array<ProductsDashboardResponse>;
    /**
     * 
     * @type {ProductsPagination}
     * @memberof PaginatedProductsResponse
     */
    pagination?: ProductsPagination;
}

/**
 * Check if a given object implements the PaginatedProductsResponse interface.
 */
export function instanceOfPaginatedProductsResponse(value: object): value is PaginatedProductsResponse {
    return true;
}

export function PaginatedProductsResponseFromJSON(json: any): PaginatedProductsResponse {
    return PaginatedProductsResponseFromJSONTyped(json, false);
}

export function PaginatedProductsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedProductsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductsDashboardResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : ProductsPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedProductsResponseToJSON(json: any): PaginatedProductsResponse {
    return PaginatedProductsResponseToJSONTyped(json, false);
}

export function PaginatedProductsResponseToJSONTyped(value?: PaginatedProductsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductsDashboardResponseToJSON)),
        'pagination': ProductsPaginationToJSON(value['pagination']),
    };
}

