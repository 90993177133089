/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AbstractViewComponentResponse } from './AbstractViewComponentResponse';
import {
    AbstractViewComponentResponseFromJSON,
    AbstractViewComponentResponseFromJSONTyped,
    AbstractViewComponentResponseToJSON,
    AbstractViewComponentResponseToJSONTyped,
} from './AbstractViewComponentResponse';

/**
 * 
 * @export
 * @interface GetAbstractViewComponentResponse
 */
export interface GetAbstractViewComponentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAbstractViewComponentResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<AbstractViewComponentResponse>}
     * @memberof GetAbstractViewComponentResponse
     */
    view_components?: Array<AbstractViewComponentResponse>;
}

/**
 * Check if a given object implements the GetAbstractViewComponentResponse interface.
 */
export function instanceOfGetAbstractViewComponentResponse(value: object): value is GetAbstractViewComponentResponse {
    return true;
}

export function GetAbstractViewComponentResponseFromJSON(json: any): GetAbstractViewComponentResponse {
    return GetAbstractViewComponentResponseFromJSONTyped(json, false);
}

export function GetAbstractViewComponentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAbstractViewComponentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'view_components': json['view_components'] == null ? undefined : ((json['view_components'] as Array<any>).map(AbstractViewComponentResponseFromJSON)),
    };
}

export function GetAbstractViewComponentResponseToJSON(json: any): GetAbstractViewComponentResponse {
    return GetAbstractViewComponentResponseToJSONTyped(json, false);
}

export function GetAbstractViewComponentResponseToJSONTyped(value?: GetAbstractViewComponentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'view_components': value['view_components'] == null ? undefined : ((value['view_components'] as Array<any>).map(AbstractViewComponentResponseToJSON)),
    };
}

