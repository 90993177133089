<template>
  <transition name="fade">
    <div
      v-if="hasApplied && !closed"
      class="p-15 mb-25 relative"
      :class="fullPage ? 'bg-green' : 'bg-gray-200'"
      data-test-id="net-terms-container"
    >
      <Icon
        v-if="fullPage"
        icon-key="close"
        hover-type="OPACITY"
        class="close-container"
        :size="24"
        :on-click="close"
      />
      <div class="subheading-3">Net terms</div>
      <p>
        <span class="underline" data-test-id="net-terms-60-link" @click="handleClick">Apply for net-60 terms</span>
        – make profits on sales before paying a single invoice!
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'NetTerms',
  props: ['fullPage', 'trackingLocation'],
  data() {
    return {
      closed: false
    }
  },
  computed: {
    hasApplied() {
      return this.$store.getters.userContext?.qualification_status === 'pending'
    }
  },
  methods: {
    handleClick() {
      this.$trackEvent({
        action: 'Balance Application',
        properties: {
          application_entry_point: this.trackingLocation
        }
      })
      this.$emit('close')
      this.$router.push('/dashboard/credit')
    },
    close() {
      this.closed = true
    }
  }
}
</script>

<style lang="scss" scoped>
.close-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
