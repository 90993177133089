import restApi from '../../http.js'
import session from '../session'

export default class BrandApi {
  favoriteProductToggle(id) {
    return restApi({
      method: 'PATCH',
      url: `/products/${id}/favoritize`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getFavoriteProducts() {
    return restApi({
      method: 'GET',
      url: '/products/favorite',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getFavoriteProductsCount() {
    return restApi({
      method: 'GET',
      url: '/products/number-of-favorite-products',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getFavoriteProductIds() {
    return restApi({
      method: 'GET',
      url: '/products/favorite-products',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  favoriteBrandToggle(id) {
    return restApi({
      method: 'PATCH',
      url: `/brands/${id}/favoritize`,

      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getFavoriteBrands() {
    return restApi({
      method: 'GET',
      url: '/brands/favorite',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getFavoriteBrandIds() {
    return restApi({
      method: 'GET',
      url: '/brands/favorite-brands',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getFavoriteBrandsCount() {
    return restApi({
      method: 'GET',
      url: '/brands/number-of-favorite-brands',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  // NOTE:: Temp solution till generated api for csv response is fixed
  boardExportToCSV(boardId) {
    return restApi({
      method: 'POST',
      url: `boards/${boardId}/export?order=ASC`,
      headers: {
        Accept: 'text/csv',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
