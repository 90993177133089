<template>
  <transition name="fade">
    <div v-if="hasApproved && creditLevel && !closed" class="p-15 mb-25 relative bg-gray-200">
      <div class="subheading-3">Credit Available</div>
      <p>{{ creditLeft }} of {{ creditLevel }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CreditAvailable',
  props: ['fullPage'],
  data() {
    return {
      closed: false
    }
  },
  computed: {
    hasApproved() {
      return this.$store.getters.userContext?.qualification_status === 'APPROVED'
    },
    creditLeft() {
      return this.$utils.toDollars(this.$store.getters.userContext?.credit_left * 100)
    },
    creditLevel() {
      return this.$utils.toDollars(this.$store.getters.userContext?.credit_level * 100)
    }
  },
  methods: {
    close() {
      this.closed = true
    }
  }
}
</script>

<style scoped></style>
