<template>
  <div @click="openNewTab">
    <div class="open-new-tab-button" :class="{ disabled: disabled }">
      <span><slot /></span>
      <Icon icon-key="external-link" :size="24" class="ml-5" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    route: {
      type: String,
      required: true
    }
  },
  methods: {
    openNewTab: function () {
      if (this.disabled) {
        return
      }
      window.open(`${window.location.origin}${this.route}`, '_blank').focus()
    }
  }
}
</script>

<style lang="scss">
.open-new-tab-button {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
  }

  span {
    text-decoration: underline;
  }

  &:hover:not(.disabled) {
    cursor: pointer;
    color: #30a4ff;
    .icon-external-link {
      background: url('../../../../assets/icons/external-link_hover.svg') no-repeat center;
      background-size: contain;
    }
  }
}
</style>
