import Vue from 'vue'
import Toast from '@/components/shared/Toast'

const ComponentClass = Vue.extend(Toast)

export const toastHelper = {
  computed: {},
  methods: {
    toast({ type, title, message, actionName, action }) {
      const toast = new ComponentClass({
        propsData: { type, title, message, actionName, action }
      })
      toast.$mount()
      this.$toasted.show(toast.$el)
    }
  }
}
