<template>
  <div class="bt bb mt-40 py-80 text-center">
    <bulletin-button :to="'/wholesale-retail/buyer'" class="width-100" variant="primary">
      Sign Up as a Retailer
    </bulletin-button>
    <a
      class="b1 block align-center pointer underline mt-30"
      @click="redirectToSignin ? $router.push('/sign-in') : $store.dispatch('navigation/setDropdownType', 'sign-in')"
      >Login with an existing account</a
    >
  </div>
</template>

<script>
export default {
  props: {
    redirectToSignin: {
      default: false
    }
  }
}
</script>
