/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadProductImageRequest
 */
export interface UploadProductImageRequest {
    /**
     * Image
     * @type {Blob}
     * @memberof UploadProductImageRequest
     */
    image: Blob;
    /**
     * Original Image
     * @type {Blob}
     * @memberof UploadProductImageRequest
     */
    original_image: Blob;
    /**
     * Crop data
     * @type {object}
     * @memberof UploadProductImageRequest
     */
    crop_params: object;
}

/**
 * Check if a given object implements the UploadProductImageRequest interface.
 */
export function instanceOfUploadProductImageRequest(value: object): value is UploadProductImageRequest {
    if (!('image' in value) || value['image'] === undefined) return false;
    if (!('original_image' in value) || value['original_image'] === undefined) return false;
    if (!('crop_params' in value) || value['crop_params'] === undefined) return false;
    return true;
}

export function UploadProductImageRequestFromJSON(json: any): UploadProductImageRequest {
    return UploadProductImageRequestFromJSONTyped(json, false);
}

export function UploadProductImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadProductImageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'],
        'original_image': json['original_image'],
        'crop_params': json['crop_params'],
    };
}

export function UploadProductImageRequestToJSON(json: any): UploadProductImageRequest {
    return UploadProductImageRequestToJSONTyped(json, false);
}

export function UploadProductImageRequestToJSONTyped(value?: UploadProductImageRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image': value['image'],
        'original_image': value['original_image'],
        'crop_params': value['crop_params'],
    };
}

