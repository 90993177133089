/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryLevelVariantResponse
 */
export interface InventoryLevelVariantResponse {
    /**
     * 
     * @type {string}
     * @memberof InventoryLevelVariantResponse
     */
    variant_id?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InventoryLevelVariantResponse
     */
    characteristic_options?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof InventoryLevelVariantResponse
     */
    sku?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLevelVariantResponse
     */
    in_stock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryLevelVariantResponse
     */
    inventory_quantity?: number;
}

/**
 * Check if a given object implements the InventoryLevelVariantResponse interface.
 */
export function instanceOfInventoryLevelVariantResponse(value: object): value is InventoryLevelVariantResponse {
    return true;
}

export function InventoryLevelVariantResponseFromJSON(json: any): InventoryLevelVariantResponse {
    return InventoryLevelVariantResponseFromJSONTyped(json, false);
}

export function InventoryLevelVariantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryLevelVariantResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
        'characteristic_options': json['characteristic_options'] == null ? undefined : json['characteristic_options'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'in_stock': json['in_stock'] == null ? undefined : json['in_stock'],
        'inventory_quantity': json['inventory_quantity'] == null ? undefined : json['inventory_quantity'],
    };
}

export function InventoryLevelVariantResponseToJSON(json: any): InventoryLevelVariantResponse {
    return InventoryLevelVariantResponseToJSONTyped(json, false);
}

export function InventoryLevelVariantResponseToJSONTyped(value?: InventoryLevelVariantResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'variant_id': value['variant_id'],
        'characteristic_options': value['characteristic_options'],
        'sku': value['sku'],
        'in_stock': value['in_stock'],
        'inventory_quantity': value['inventory_quantity'],
    };
}

