/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedPaymentsResponse,
  ReplaceCreditCardRequest,
  UpdateCreditCardRequest,
} from '../models/index';
import {
    PaginatedPaymentsResponseFromJSON,
    PaginatedPaymentsResponseToJSON,
    ReplaceCreditCardRequestFromJSON,
    ReplaceCreditCardRequestToJSON,
    UpdateCreditCardRequestFromJSON,
    UpdateCreditCardRequestToJSON,
} from '../models/index';

export interface DeleteCreditCard1Request {
    cardId: string;
}

export interface GetPaymentsRequest {
    retailerId?: string;
    brandId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
    sortName?: string;
    sortValue?: string;
}

export interface GetPaymentsByBrandRequest {
    brandId: string;
    retailerId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
    sortName?: string;
    sortValue?: string;
}

export interface ReplaceCreditCardOperationRequest {
    replaceCreditCardRequest: ReplaceCreditCardRequest;
}

export interface UpdateCreditCardOperationRequest {
    updateCreditCardRequest: UpdateCreditCardRequest;
}

/**
 * 
 */
export class PaymentControllerApi extends runtime.BaseAPI {

    /**
     * Delete retailer credit card
     */
    async deleteCreditCardRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/credit-card`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete retailer credit card
     */
    async deleteCreditCard(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCreditCardRaw(initOverrides);
    }

    /**
     * Delete retailer credit card by Id
     */
    async deleteCreditCard1Raw(requestParameters: DeleteCreditCard1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['cardId'] == null) {
            throw new runtime.RequiredError(
                'cardId',
                'Required parameter "cardId" was null or undefined when calling deleteCreditCard1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/{cardId}/credit-card`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters['cardId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete retailer credit card by Id
     */
    async deleteCreditCard1(requestParameters: DeleteCreditCard1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCreditCard1Raw(requestParameters, initOverrides);
    }

    /**
     * Find all payments per multiple/custom criteria
     */
    async getPaymentsRaw(requestParameters: GetPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPaymentsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['sortName'] != null) {
            queryParameters['sortName'] = requestParameters['sortName'];
        }

        if (requestParameters['sortValue'] != null) {
            queryParameters['sortValue'] = requestParameters['sortValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPaymentsResponseFromJSON(jsonValue));
    }

    /**
     * Find all payments per multiple/custom criteria
     */
    async getPayments(requestParameters: GetPaymentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPaymentsResponse> {
        const response = await this.getPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all payments for provided brand
     */
    async getPaymentsByBrandRaw(requestParameters: GetPaymentsByBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPaymentsResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getPaymentsByBrand().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['sortName'] != null) {
            queryParameters['sortName'] = requestParameters['sortName'];
        }

        if (requestParameters['sortValue'] != null) {
            queryParameters['sortValue'] = requestParameters['sortValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/brand`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPaymentsResponseFromJSON(jsonValue));
    }

    /**
     * Find all payments for provided brand
     */
    async getPaymentsByBrand(requestParameters: GetPaymentsByBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPaymentsResponse> {
        const response = await this.getPaymentsByBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Replace retailer credit card
     */
    async replaceCreditCardRaw(requestParameters: ReplaceCreditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['replaceCreditCardRequest'] == null) {
            throw new runtime.RequiredError(
                'replaceCreditCardRequest',
                'Required parameter "replaceCreditCardRequest" was null or undefined when calling replaceCreditCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/credit-card-replace`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplaceCreditCardRequestToJSON(requestParameters['replaceCreditCardRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Replace retailer credit card
     */
    async replaceCreditCard(requestParameters: ReplaceCreditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.replaceCreditCardRaw(requestParameters, initOverrides);
    }

    /**
     * Update retailer credit card
     */
    async updateCreditCardRaw(requestParameters: UpdateCreditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateCreditCardRequest'] == null) {
            throw new runtime.RequiredError(
                'updateCreditCardRequest',
                'Required parameter "updateCreditCardRequest" was null or undefined when calling updateCreditCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/credit-card`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCreditCardRequestToJSON(requestParameters['updateCreditCardRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update retailer credit card
     */
    async updateCreditCard(requestParameters: UpdateCreditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCreditCardRaw(requestParameters, initOverrides);
    }

}
