<template>
  <div class="secondary-row show hide-md">
    <div
      v-if="!showNynowTopbanner"
      id="menu"
      class="secondary-menu"
      :class="{ centered: user && user.role === 'BRAND' && !forceRetailerView }"
    >
      <template v-if="user === null || (user && user.role !== 'BRAND') || forceRetailerView">
        <div ref="parentCategoryList" class="left-side" :class="{ 'logged-in-state': user }">
          <div
            ref="allCategoriesMenu"
            class="menu-item subheading-1 all-categories-menu default-hover"
            @click="showAllCategories"
            @mouseover="$store.dispatch('navigation/setDropdownType', 'closed')"
          >
            <Icon icon-key="hamburger" hover-type="OPACITY" class="all-categories-icon" />
          </div>

          <template v-if="parentCategories && parentCategories.length">
            <div
              v-for="(parentCategory, index) in getVisibleCategories()"
              :key="parentCategory.id"
              class="menu-item subheading-1"
              :class="[
                { active: parentCategory.id === activeCategoryId && index === !1 },
                { 'apparel-margin-right': index === 2 },
                { nynow: index === 1 }
              ]"
              @click="
                index === 1 && user && user.role === 'ADMIN' ? '' : navigateToParentCategory(parentCategory, index)
              "
              @mouseover="handleCategoryMouseOver(parentCategory, index)"
            >
              {{ parentCategory.name }}
              <span v-if="index === 1" class="vertical-line" />
            </div>
          </template>
        </div>

        <div class="right-side">
          <div class="menu-item router-link subheading-1">
            <router-link
              data-test-id="header_link_brands"
              data-cy="browse-brands-link"
              to="/brands"
              @mouseover="$store.dispatch('navigation/setDropdownType', 'closed')"
              @click.native="trackNavigationEvent('brand directory', 'brand directory')"
            >
              Browse by Brands
            </router-link>
          </div>
        </div>
      </template>

      <template v-if="user && user.role === 'BRAND' && !forceRetailerView">
        <div class="brand-menu">
          <div id="secondaryNavDashboardLink" class="menu-item router-link subheading-1">
            <router-link :to="'/dashboard/products'" class="active-hover" data-cy="dashboard-link">
              Dashboard
            </router-link>
          </div>
          <div class="menu-item router-link subheading-1">
            <router-link :to="'/dashboard/orders'" class="active-hover" data-cy="orders-link">
              Orders & Payouts
            </router-link>
          </div>
          <div class="menu-item router-link subheading-1">
            <router-link
              class="active-hover"
              data-test-id="header_link_messages"
              :to="{ name: 'dashboardMessages' }"
              @click.native="trackNavigationEvent('messages', 'messages')"
            >
              Messages
            </router-link>
          </div>

          <div class="menu-item router-link subheading-1">
            <a
              :class="{ 'router-link-active': $route.name === 'refer' }"
              data-test-id="header_menu_refer"
              data-cy="refer-retailer-link"
              @mouseover="$store.dispatch('navigation/setDropdownType', 'refer')"
              >Refer a Retailer</a
            >
          </div>
          <div class="menu-item router-link subheading-1">
            <router-link v-if="!isBrandNYN" :to="'/nynow'" class="active-hover" data-cy="nynow-link">
              NY NOW
            </router-link>
            <a
              v-if="isBrandNYN"
              :class="{ 'router-link-active': $route.name === 'nynow' }"
              data-test-id="header_menu_nynow"
              data-cy="nynow-link"
              @mouseover="$store.dispatch('navigation/setDropdownType', 'nynow')"
              >NY NOW</a
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BrandApi from '@/services/api/brandApi.js'
import { navigationHelper } from '../mixin/navigationHelper'
import _debounce from 'lodash/debounce'

const BrandApiService = new BrandApi()

export default {
  mixins: [navigationHelper],
  props: {
    categories: {
      type: [Array, Object],
      required: true
    },
    forceRetailerView: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      hiddenCategories: [],
      isBrandNYN: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState',
      dropdownState: 'navigation/dropdownState',
      activeCategoryId: 'navigation/activeCategory'
    }),
    parentCategories() {
      return this.categories?.children_containers
    },
    visibleParentCategories() {
      const categories = this.parentCategories ? [...this.parentCategories] : []
      if (!categories.some((container) => container.name === 'NY NOW')) {
        categories.splice(1, 0, { name: 'NY NOW', id: 'nynow' })
      }
      return categories
    },
    showNynowTopbanner() {
      return (
        !['ADMIN', 'BRAND'].includes(this.user?.role) && ['/nynow-exhibitor', '/nynow-buyer'].includes(this.$route.path)
      )
    }
  },
  watch: {
    user: function () {
      this.checkBrandNYNOW()
      setTimeout(() => {
        this.setCategoryVisibility()
      })
    },
    parentCategories: function () {
      this.setCategoryVisibility()
    }
  },
  mounted() {
    window.addEventListener('resize', _debounce(this.setCategoryVisibility, 250))
  },
  destroy() {
    window.removeEventListener('resize', this.setCategoryVisibility)
  },
  beforeMount() {
    this.checkBrandNYNOW()
  },
  methods: {
    handleCategoryMouseOver,
    showAllCategories,
    setCategoryVisibility,
    getVisibleCategories,
    isCategoryHidden,
    async checkBrandNYNOW() {
      if (this.user && this.user.role === 'BRAND') {
        const { data } = await BrandApiService.getBrandMe()
        this.isBrandNYN = data && data.active_trade_shows?.length && data.active_trade_shows[0].booth_number
      }
    }
  }
}

function showAllCategories() {
  this.$store.dispatch('navigation/setLeftSideCategoryMenuOpen', true)
}

function handleCategoryMouseOver(parentCategory, _index) {
  if (this.dropdownState === 'sign-in' || this.dropdownState === 'sign-up') {
    return
  }

  this.$store.dispatch('navigation/setDropdownType', 'category')

  if (parentCategory.id) {
    this.$store.dispatch('navigation/setActiveCategory', parentCategory.id)
  }
}

function setCategoryVisibility() {
  const IsParentCategoryListVisible = this.$refs.parentCategoryList === undefined
  if (IsParentCategoryListVisible) {
    return
  }
  this.hiddenCategories = []

  const spaceForCategories = this.$refs.parentCategoryList.getBoundingClientRect()?.width - 40

  let categoriesWidth = 150
  const categoryPadding = 16
  this.parentCategories?.forEach((item) => {
    const categoryNameWidth = this.$utils.getTextWidth(item.name, '16px NHaasGroteskDSPro-55Rg') + categoryPadding
    categoriesWidth = categoriesWidth + categoryNameWidth
    if (spaceForCategories < categoriesWidth) {
      this.hiddenCategories.push(item.id)
    }
  })

  this.$forceUpdate()
}

function isCategoryHidden(categoryId) {
  return this.hiddenCategories.find((id) => {
    return id === categoryId
  })
}

function getVisibleCategories() {
  return this.visibleParentCategories.filter((item) => {
    return !this.isCategoryHidden(item.id)
  })
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/base/_mixins.scss';
@import '@/assets/sass/base/_vars.scss';
.secondary-row {
  padding: 8px 24px 0 24px;
}
.secondary-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.centered {
    justify-content: center;
  }

  .left-side,
  .right-side {
    display: flex;
    flex-direction: row;
  }

  .left-side {
    max-width: calc(100vw - 203px);
    width: calc(100vw - 203px);
    &.logged-in-state {
      max-width: calc(100vw - 347px);
      width: calc(100vw - 347px);
    }
  }

  .menu-item {
    padding: 0 8px 20px 8px;
    margin-bottom: -1px;
    z-index: 10;
    display: inline-block;
    border-bottom: 1px solid transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    // overflow: hidden;
    @include transition(border-color, 150ms, ease-in-out);

    a {
      &.router-link-active {
        background: linear-gradient(0deg, $color-green 40%, transparent 50%);
      }

      &:hover {
        cursor: pointer;
        color: $color-grey-4;
      }
    }

    &.default-hover {
      &:hover {
        cursor: pointer;
        color: $color-grey-4;
      }
    }

    &:not(.router-link) {
      &:hover {
        cursor: pointer;
        border-color: black !important;
      }
    }

    &.active {
      cursor: pointer;
      border-color: black !important;
    }
  }

  .brand-menu {
    display: flex;
  }

  .all-categories-menu {
    overflow: visible;
    position: relative;
    margin-right: 60px;
    padding: 0 0 20px 0 !important;
    .all-categories-icon {
      position: absolute;
      top: -10px;
      left: 10px;
    }

    span {
      margin-left: 50px;
    }
  }
}

.subheading-1 {
  letter-spacing: normal !important;
}

.vertical-line {
  margin-left: 26px;
  position: absolute;
  left: 239px;
  top: 0;
  height: 70%;
  width: 1px;
  background-color: $color-grey-3;
}

.apparel-margin-right {
  margin-left: 30px;
}

.nynow {
  border-bottom: none !important;

  &:hover {
    cursor: pointer;
    color: $color-grey-4;
  }
}
</style>
