var loader

export default {
  data() {
    return {
      isLoading: false
    }
  },

  beforeMount() {
    loader = this
  },

  created() {
    this.$loader.$on('trigger', (val) => {
      loader.isLoading = val
      loader.$forceUpdate()
    })
  }
}
