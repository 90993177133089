/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SuborderItemFromReorderResponse } from './SuborderItemFromReorderResponse';
import {
    SuborderItemFromReorderResponseFromJSON,
    SuborderItemFromReorderResponseFromJSONTyped,
    SuborderItemFromReorderResponseToJSON,
    SuborderItemFromReorderResponseToJSONTyped,
} from './SuborderItemFromReorderResponse';

/**
 * 
 * @export
 * @interface SuborderFromReorderResponse
 */
export interface SuborderFromReorderResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderFromReorderResponse
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SuborderFromReorderResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof SuborderFromReorderResponse
     */
    order_number?: number;
    /**
     * 
     * @type {string}
     * @memberof SuborderFromReorderResponse
     */
    order_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SuborderFromReorderResponse
     */
    order_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof SuborderFromReorderResponse
     */
    suborder_number?: number;
    /**
     * 
     * @type {Array<SuborderItemFromReorderResponse>}
     * @memberof SuborderFromReorderResponse
     */
    items?: Array<SuborderItemFromReorderResponse>;
}

/**
 * Check if a given object implements the SuborderFromReorderResponse interface.
 */
export function instanceOfSuborderFromReorderResponse(value: object): value is SuborderFromReorderResponse {
    return true;
}

export function SuborderFromReorderResponseFromJSON(json: any): SuborderFromReorderResponse {
    return SuborderFromReorderResponseFromJSONTyped(json, false);
}

export function SuborderFromReorderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderFromReorderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'order_number': json['order_number'] == null ? undefined : json['order_number'],
        'order_id': json['order_id'] == null ? undefined : json['order_id'],
        'order_date': json['order_date'] == null ? undefined : (new Date(json['order_date'])),
        'suborder_number': json['suborder_number'] == null ? undefined : json['suborder_number'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(SuborderItemFromReorderResponseFromJSON)),
    };
}

export function SuborderFromReorderResponseToJSON(json: any): SuborderFromReorderResponse {
    return SuborderFromReorderResponseToJSONTyped(json, false);
}

export function SuborderFromReorderResponseToJSONTyped(value?: SuborderFromReorderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'order_number': value['order_number'],
        'order_id': value['order_id'],
        'order_date': value['order_date'] == null ? undefined : ((value['order_date']).toISOString()),
        'suborder_number': value['suborder_number'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(SuborderItemFromReorderResponseToJSON)),
    };
}

