import restApi from '../../http.js'
import session from '../session'

export default class KeysApi {
  getStoredCreditCardList() {
    return restApi({
      method: 'GET',
      url: '/retailers/credit-cards',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getRetailerCards(retailerId) {
    return restApi({
      method: 'GET',
      url: `/retailers/${retailerId}/credit-cards`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removeCard(id) {
    return restApi({
      method: 'DELETE',
      url: `payments/${id}/credit-card`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removeCardById(id) {
    return restApi({
      method: 'DELETE',
      url: `payments/${id}/credit-card`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateCard(request) {
    return restApi({
      method: 'PUT',
      url: 'payments/credit-card',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  replaceCard(data) {
    return restApi({
      method: 'PUT',
      url: '/payments/credit-card-replace',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
