/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update Promo code object
 * @export
 * @interface UpdatePromoCodeRequest
 */
export interface UpdatePromoCodeRequest {
    /**
     * 
     * @type {Date}
     * @memberof UpdatePromoCodeRequest
     */
    end_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromoCodeRequest
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromoCodeRequest
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromoCodeRequest
     */
    minimum_cart_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePromoCodeRequest
     */
    ongoing_indefinite?: boolean;
}

/**
 * Check if a given object implements the UpdatePromoCodeRequest interface.
 */
export function instanceOfUpdatePromoCodeRequest(value: object): value is UpdatePromoCodeRequest {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function UpdatePromoCodeRequestFromJSON(json: any): UpdatePromoCodeRequest {
    return UpdatePromoCodeRequestFromJSONTyped(json, false);
}

export function UpdatePromoCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePromoCodeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'code': json['code'],
        'amount': json['amount'],
        'minimum_cart_value': json['minimum_cart_value'] == null ? undefined : json['minimum_cart_value'],
        'ongoing_indefinite': json['ongoing_indefinite'] == null ? undefined : json['ongoing_indefinite'],
    };
}

export function UpdatePromoCodeRequestToJSON(json: any): UpdatePromoCodeRequest {
    return UpdatePromoCodeRequestToJSONTyped(json, false);
}

export function UpdatePromoCodeRequestToJSONTyped(value?: UpdatePromoCodeRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString().substring(0,10)),
        'code': value['code'],
        'amount': value['amount'],
        'minimum_cart_value': value['minimum_cart_value'],
        'ongoing_indefinite': value['ongoing_indefinite'],
    };
}

