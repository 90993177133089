import Pusher from 'pusher-js'
import Env from '@/env'

export default (vm) => {
  const user = vm.$session.getUserInfo(),
    pusherOptions = Env.pusherOptions(),
    pusher = new Pusher(pusherOptions.key, pusherOptions.options)
  pusher.connection.bind('error', function (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  })
  if (user && user.role !== 'RETAILER') {
    pusher.subscribe(`users_${user.id}`).bind('product-save', (productId) => {
      if (vm.$route.name === 'dashboardDraftEdit') {
        const { brandId, brandName } = vm.$route.query || {}
        let query = brandName && brandId ? vm.$route.query : undefined
        vm.$router.push({ path: '/dashboard/drafts', query })
      } else if (vm.$route.name === 'dashboardProducts') {
        vm.toast({
          type: 'success',
          title: 'Success',
          message: 'Product successfully saved!',
          actionName: 'Go to Product',
          action: () => vm.$router.push(`/dashboard/products/${productId}/edit`)
        })
        vm.$eventBus.$emit('clear-saving-product')
      }
    })
    pusher.subscribe(`users_${user.id}`).bind('product-image', (productId) => {
      if (vm.$route.name === 'dashboardDraftEdit') {
        vm.$router.push('/dashboard/drafts')
      } else {
        vm.toast({
          type: 'success',
          title: 'Success',
          message: 'Product successfully saved!',
          actionName: 'Go to Product',
          action: () => vm.$router.push(`/dashboard/products/${productId}/edit`)
        })
        vm.$eventBus.$emit('clear-saving-product')
      }
    })

    pusher.subscribe(`users_${user.id}`).bind('collection-updated', () => {
      vm.toast({
        type: 'success',
        title: 'Collection successfully saved!',
        message: 'Updates will be visible shortly!'
      })
    })
  }
  if (user) {
    if (user.brand_id) {
      pusher.subscribe(`users_${user.brand_id}`).bind('vanity-url', (payload) => {
        user.brand_vanity_url_name = payload
        vm.$session.setUserInfo(user)
        vm.$store.commit('setUser', user)
      })
    }

    pusher
      .subscribe(`users_${user.id}`)
      .bind('completed', () => {
        vm.$store.dispatch('getDraftCount')
        vm.$store.commit('draftUploadEnded')
      })
      .bind('import-csv-error', (payload) => {
        vm.$store.dispatch('getDraftCount')
        vm.$store.commit('draftUploadError', payload)
      })
    pusher.subscribe(`users_${user.id}`).bind('product-import', () => {
      if (vm.$route.name !== 'dashboardBrandPageSetup') {
        vm.$store.commit('setIsCSVProductImport', false)
        vm.toast({
          type: 'success',
          title: 'Success',
          message: 'All products from the CSV have been imported!',
          actionName: 'Go to Drafts',
          action: () => vm.$router.push('/dashboard/drafts')
        })
      }
    })
  }

  //TODO: I have to check if this can be deleted
  pusher.subscribe(`users_${user.id}`).bind('balance', () => {
    vm.$utils.balanceChangeHandler(vm.$session, vm)
  })

  pusher.subscribe(`users_${user.id}`).bind('recommendation', () => {
    vm.toast({
      type: 'info',
      title: 'Information',
      message: 'Our Merch Team dropped you some new recommendations!',
      actionName: 'Go to Boards',
      action: () => vm.$router.push('/favorites')
    })
  })
}
