/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplyToSellResponse
 */
export interface ApplyToSellResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToSellResponse
     */
    user_id?: string;
}

/**
 * Check if a given object implements the ApplyToSellResponse interface.
 */
export function instanceOfApplyToSellResponse(value: object): value is ApplyToSellResponse {
    return true;
}

export function ApplyToSellResponseFromJSON(json: any): ApplyToSellResponse {
    return ApplyToSellResponseFromJSONTyped(json, false);
}

export function ApplyToSellResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyToSellResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
    };
}

export function ApplyToSellResponseToJSON(json: any): ApplyToSellResponse {
    return ApplyToSellResponseToJSONTyped(json, false);
}

export function ApplyToSellResponseToJSONTyped(value?: ApplyToSellResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'user_id': value['user_id'],
    };
}

