/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountDraftProductsResponse
 */
export interface CountDraftProductsResponse {
    /**
     * 
     * @type {number}
     * @memberof CountDraftProductsResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the CountDraftProductsResponse interface.
 */
export function instanceOfCountDraftProductsResponse(value: object): value is CountDraftProductsResponse {
    return true;
}

export function CountDraftProductsResponseFromJSON(json: any): CountDraftProductsResponse {
    return CountDraftProductsResponseFromJSONTyped(json, false);
}

export function CountDraftProductsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountDraftProductsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
    };
}

export function CountDraftProductsResponseToJSON(json: any): CountDraftProductsResponse {
    return CountDraftProductsResponseToJSONTyped(json, false);
}

export function CountDraftProductsResponseToJSONTyped(value?: CountDraftProductsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'count': value['count'],
    };
}

