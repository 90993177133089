/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportProductsFromCsvRequest
 */
export interface ImportProductsFromCsvRequest {
    /**
     * Csv file as form data object
     * @type {Blob}
     * @memberof ImportProductsFromCsvRequest
     */
    csvFile: Blob;
    /**
     * Email address as form data object
     * @type {Blob}
     * @memberof ImportProductsFromCsvRequest
     */
    email: Blob;
}

/**
 * Check if a given object implements the ImportProductsFromCsvRequest interface.
 */
export function instanceOfImportProductsFromCsvRequest(value: object): value is ImportProductsFromCsvRequest {
    if (!('csvFile' in value) || value['csvFile'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function ImportProductsFromCsvRequestFromJSON(json: any): ImportProductsFromCsvRequest {
    return ImportProductsFromCsvRequestFromJSONTyped(json, false);
}

export function ImportProductsFromCsvRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportProductsFromCsvRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'csvFile': json['csvFile'],
        'email': json['email'],
    };
}

export function ImportProductsFromCsvRequestToJSON(json: any): ImportProductsFromCsvRequest {
    return ImportProductsFromCsvRequestToJSONTyped(json, false);
}

export function ImportProductsFromCsvRequestToJSONTyped(value?: ImportProductsFromCsvRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'csvFile': value['csvFile'],
        'email': value['email'],
    };
}

