<template>
  <div>
    <div
      v-if="$route.name !== 'brand-bio'"
      class="toggle-with-description items-start"
      :class="{ 'border-top-mobile': isMobile }"
    >
      <Icon icon-key="star" class="mr-10" />
      <div class="toggle-title-and-description">
        <div class="toggle-title">Bulletin Picks</div>
        <div class="toggle-description mt-5">
          <p>A selection of brands verified for quality and design.</p>
        </div>
      </div>
      <div class="toggle-wrapper">
        <toggle id="bulletinPicksCheckbox" v-model="bulletin_picks" @click="toggleBulletinPicks" />
      </div>
    </div>
    <div
      v-if="$route.name !== 'brand-bio'"
      class="toggle-with-description items-start"
      :class="{ 'border-top-mobile': isMobile }"
    >
      <Icon icon-key="bag" class="mr-10" />
      <div class="toggle-title-and-description">
        <div class="toggle-title">Buy on Bulletin</div>
        <div class="toggle-description mt-5">
          <p>Browse products available for purchase on our marketplace.</p>
        </div>
      </div>
      <div class="toggle-wrapper">
        <toggle id="buyOnbulletinCheckbox" v-model="buy_on_bulletin" @click="toggleBuyOnBulletinPicks" />
      </div>
    </div>
  </div>
</template>
<script>
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from '@/components/shared/filters/filterTypes/filterHelper'

export default {
  name: 'FilterBulletinPicks',
  mixins: [paramsHelper, filterHelper],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bulletin_picks: false,
      buy_on_bulletin: false
    }
  },
  watch: {
    $route(to) {
      this.bulletin_picks = to.query.bulletin_picks === true || localStorage.getItem('BULLETIN_PICKS')
      this.updateURLParams('bulletin_picks', this.bulletin_picks ?? null)
      this.buy_on_bulletin = to.query.buy_on_bulletin === true || localStorage.getItem('BUY_ON_BULLETIN')
      this.updateURLParams('buy_on_bulletin', this.buy_on_bulletin ?? null)
    }
  },
  mounted() {
    this.bulletin_picks = this.$route.query.bulletin_picks === 'true' || localStorage.getItem('BULLETIN_PICKS')
    this.updateURLParams('bulletin_picks', this.bulletin_picks ?? null)
    this.buy_on_bulletin = this.$route.query.buy_on_bulletin === 'true' || localStorage.getItem('BUY_ON_BULLETIN')
    this.updateURLParams('buy_on_bulletin', this.buy_on_bulletin ?? null)
  },

  methods: {
    toggleBulletinPicks() {
      this.bulletin_picks = !this.bulletin_picks
      if (!this.bulletin_picks) {
        localStorage.removeItem('BULLETIN_PICKS')
      } else {
        localStorage.setItem('BULLETIN_PICKS', this.bulletin_picks)
      }
      this.updateURLParams('bulletin_picks', this.bulletin_picks ?? null)
      // this.emitFilterSelection({
      //   filterKey: FilterKeys.BULLETIN_PICKS,
      //   items: [{ value: this.bulletin_picks, label: "Bulletin Picks", selected: true }],
      // });
    },
    toggleBuyOnBulletinPicks() {
      this.buy_on_bulletin = !this.buy_on_bulletin
      if (!this.buy_on_bulletin) {
        localStorage.removeItem('BUY_ON_BULLETIN')
      } else {
        localStorage.setItem('BUY_ON_BULLETIN', this.buy_on_bulletin)
      }
      this.updateURLParams('buy_on_bulletin', this.buy_on_bulletin ?? null)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/base/_vars.scss';
.toggle-with-description {
  border-top: 0;
  border-bottom: 1px solid $color-grey-3;
}
.border-top-mobile {
  border-top: 1px solid $color-grey-3;
}
</style>
