import AuthApi from '../../../../services/api/authApi.js'
import FooterNavigation from './../../../footer/footer.vue'

export default {
  components: {
    FooterNavigation
  },
  data() {
    return {
      show: false,
      product: null,
      totalPrice: 0,
      unwatch: null,
      applymentType: '',
      applymentDropdownItems: [{ name: 'retailer' }, { name: 'brand' }],
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        requestAssortment: false,
        applymentType: '',
        storeBrandName: ''
      },
      requestStarted: false
    }
  },

  computed: {
    isApplying() {
      return !!this.applymentType && !this.product
    }
  },

  methods: {
    close: close,
    showQuickShopModalUnregisteredCallback: showQuickShopModalUnregisteredCallback,
    setApplymentType: setApplymentType,
    handleFormSubmit: handleFormSubmit,
    clearForm: clearForm
  },

  mounted() {
    this.$eventBus.$on('show-quick-shop-modal-unregistered', this.showQuickShopModalUnregisteredCallback)
  },

  beforeDestroy() {
    this.$eventBus.$off('show-quick-shop-modal-unregistered', this.showQuickShopModalUnregisteredCallback)
  }
}

function showQuickShopModalUnregisteredCallback(product, applymentType = null) {
  this.product = product
  this.show = true

  if (applymentType) {
    this.applymentType = applymentType
    // todo: delete $route.params.view,
    // instead emit an showQuickShopModalUnregistered, with a second value 'retailer' | 'brand' | 'assortment'
  } else if (this.$route.params.view) {
    this.applymentType = this.$route.params.view
  } else {
    this.applymentType = 'retailer'
  }

  this.setApplymentType(this.applymentType)
}

function handleFormSubmit() {
  let _self = this

  if (_self.requestStarted) return

  _self.$validator.validate().then((valid) => {
    if (!valid) return false

    const AuthApiService = new AuthApi(),
      registerRequest =
        _self.form.applymentType == 'brand' ? AuthApiService.signUpAsBrand : AuthApiService.signUpAsRetailer

    let request = {
      first_name: _self.form.firstName,
      last_name: _self.form.lastName,
      email: _self.form.email,
      password: _self.form.password
    }

    if (_self.form.applymentType === 'retailer') {
      request.assortment_requested = _self.form.requestAssortment
      request.store_name = _self.form.storeOrBrandName
    } else {
      request.company_name = _self.form.storeOrBrandName
    }

    _self.requestStarted = true

    registerRequest(request)
      .then((_response) => {
        _self.requestStarted = false

        let currentDate = new Date(),
          properties = {
            'first name': _self.form.firstName,
            'last name': _self.form.lastName,
            email: _self.form.email,
            role: _self.form.applymentType,
            date: currentDate.toISOString()
          }

        if (_self.form.applymentType === 'retailer') {
          properties['requesting assortment'] = _self.form.requestAssortment
          properties['store'] = _self.form.storeOrBrandName
        } else {
          properties['brand name'] = _self.form.storeOrBrandName
        }

        if (_self.$ma) {
          _self.$trackEvent({ action: 'Sign Up', properties: properties })
        }

        _self.clearForm()
        _self.close()
        _self.toast({
          type: 'success',
          title: 'Thanks for applying!',
          message: "We're reviewing applications on a rolling basis and will be in touch soon."
        })
      })
      .catch((error) => {
        _self.requestStarted = false
        _self.$handleError(
          _self,
          error.response.status === 409
            ? 'Email you entered already exists'
            : 'Something went wrong. Please try again later.'
        )
      })
  })
}

function close() {
  this.show = false
  this.product = null
  this.$validator.reset()
}

function setApplymentType(applymentType) {
  applymentType = typeof applymentType == 'object' ? applymentType.name : applymentType

  if (applymentType == 'brand') {
    this.form.applymentType = 'brand'
    this.form.requestAssortment = false
  } else if (applymentType == 'assortment') {
    this.form.applymentType = 'retailer'
    this.form.requestAssortment = true
  } else {
    this.form.applymentType = 'retailer'
    this.form.requestAssortment = false
  }
}

function clearForm() {
  this.form = {
    ...this.form,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    storeOrBrandName: ''
  }
}
