/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DuplicateOrderEntry } from './DuplicateOrderEntry';
import {
    DuplicateOrderEntryFromJSON,
    DuplicateOrderEntryFromJSONTyped,
    DuplicateOrderEntryToJSON,
    DuplicateOrderEntryToJSONTyped,
} from './DuplicateOrderEntry';

/**
 * 
 * @export
 * @interface DuplicateOrderResponse
 */
export interface DuplicateOrderResponse {
    /**
     * 
     * @type {{ [key: string]: Array<DuplicateOrderEntry>; }}
     * @memberof DuplicateOrderResponse
     */
    duplicate_by_retailer?: { [key: string]: Array<DuplicateOrderEntry>; };
}

/**
 * Check if a given object implements the DuplicateOrderResponse interface.
 */
export function instanceOfDuplicateOrderResponse(value: object): value is DuplicateOrderResponse {
    return true;
}

export function DuplicateOrderResponseFromJSON(json: any): DuplicateOrderResponse {
    return DuplicateOrderResponseFromJSONTyped(json, false);
}

export function DuplicateOrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateOrderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'duplicate_by_retailer': json['duplicate_by_retailer'] == null ? undefined : json['duplicate_by_retailer'],
    };
}

export function DuplicateOrderResponseToJSON(json: any): DuplicateOrderResponse {
    return DuplicateOrderResponseToJSONTyped(json, false);
}

export function DuplicateOrderResponseToJSONTyped(value?: DuplicateOrderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'duplicate_by_retailer': value['duplicate_by_retailer'],
    };
}

