/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignInResponse
 */
export interface SignInResponse {
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    access_token?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    role?: SignInResponseRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof SignInResponse
     */
    number_of_logins?: number;
    /**
     * 
     * @type {Date}
     * @memberof SignInResponse
     */
    first_login_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SignInResponse
     */
    last_login_date?: Date;
}


/**
 * @export
 */
export const SignInResponseRoleEnum = {
    Brand: 'BRAND',
    Retailer: 'RETAILER',
    Admin: 'ADMIN',
    ProductBuilder: 'PRODUCT_BUILDER'
} as const;
export type SignInResponseRoleEnum = typeof SignInResponseRoleEnum[keyof typeof SignInResponseRoleEnum];


/**
 * Check if a given object implements the SignInResponse interface.
 */
export function instanceOfSignInResponse(value: object): value is SignInResponse {
    return true;
}

export function SignInResponseFromJSON(json: any): SignInResponse {
    return SignInResponseFromJSONTyped(json, false);
}

export function SignInResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'access_token': json['access_token'] == null ? undefined : json['access_token'],
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'role': json['role'] == null ? undefined : json['role'],
        'number_of_logins': json['number_of_logins'] == null ? undefined : json['number_of_logins'],
        'first_login_date': json['first_login_date'] == null ? undefined : (new Date(json['first_login_date'])),
        'last_login_date': json['last_login_date'] == null ? undefined : (new Date(json['last_login_date'])),
    };
}

export function SignInResponseToJSON(json: any): SignInResponse {
    return SignInResponseToJSONTyped(json, false);
}

export function SignInResponseToJSONTyped(value?: SignInResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'access_token': value['access_token'],
        'id': value['id'],
        'email': value['email'],
        'role': value['role'],
        'number_of_logins': value['number_of_logins'],
        'first_login_date': value['first_login_date'] == null ? undefined : ((value['first_login_date']).toISOString()),
        'last_login_date': value['last_login_date'] == null ? undefined : ((value['last_login_date']).toISOString()),
    };
}

