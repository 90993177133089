import restApi from '../../http.js'
import session from '../session.js'

export default class Api {
  getAll() {
    return restApi({
      method: 'GET',
      url: '/addresses',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  get(id) {
    return restApi({
      method: 'GET',
      url: `/addresses/${id}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  create(request) {
    return restApi({
      method: 'POST',
      url: '/addresses',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  update(request) {
    return restApi({
      method: 'PUT',
      url: `/addresses/${request.id}`,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  remove(id) {
    return restApi({
      method: 'DELETE',
      url: `/addresses/${id}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  setDefault(id, data) {
    return restApi({
      method: 'PATCH',
      url: `/addresses/${id}/default`,
      data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
