/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductWithoutImageResponse
 */
export interface ProductWithoutImageResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductWithoutImageResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithoutImageResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithoutImageResponse
     */
    brandName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductWithoutImageResponse
     */
    image?: string;
}

/**
 * Check if a given object implements the ProductWithoutImageResponse interface.
 */
export function instanceOfProductWithoutImageResponse(value: object): value is ProductWithoutImageResponse {
    return true;
}

export function ProductWithoutImageResponseFromJSON(json: any): ProductWithoutImageResponse {
    return ProductWithoutImageResponseFromJSONTyped(json, false);
}

export function ProductWithoutImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductWithoutImageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'brandName': json['brandName'] == null ? undefined : json['brandName'],
        'image': json['image'] == null ? undefined : json['image'],
    };
}

export function ProductWithoutImageResponseToJSON(json: any): ProductWithoutImageResponse {
    return ProductWithoutImageResponseToJSONTyped(json, false);
}

export function ProductWithoutImageResponseToJSONTyped(value?: ProductWithoutImageResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'brandName': value['brandName'],
        'image': value['image'],
    };
}

