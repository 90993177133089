/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SuborderResponse } from './SuborderResponse';
import {
    SuborderResponseFromJSON,
    SuborderResponseFromJSONTyped,
    SuborderResponseToJSON,
    SuborderResponseToJSONTyped,
} from './SuborderResponse';
import type { BrandResponse } from './BrandResponse';
import {
    BrandResponseFromJSON,
    BrandResponseFromJSONTyped,
    BrandResponseToJSON,
    BrandResponseToJSONTyped,
} from './BrandResponse';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface GetOrderResponse
 */
export interface GetOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    number?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderResponse
     */
    is_custom_assortment?: boolean;
    /**
     * 
     * @type {AddressResponse}
     * @memberof GetOrderResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {Date}
     * @memberof GetOrderResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    total_order_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    shipping_estimate_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    total_with_estimated_shipping?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    total_returns_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrderResponse
     */
    auth_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponse
     */
    payment_type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderResponse
     */
    financed?: boolean;
    /**
     * 
     * @type {Array<SuborderResponse>}
     * @memberof GetOrderResponse
     */
    suborders?: Array<SuborderResponse>;
    /**
     * 
     * @type {Array<BrandResponse>}
     * @memberof GetOrderResponse
     */
    brands?: Array<BrandResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrderResponse
     */
    is_first_order?: boolean;
}

/**
 * Check if a given object implements the GetOrderResponse interface.
 */
export function instanceOfGetOrderResponse(value: object): value is GetOrderResponse {
    return true;
}

export function GetOrderResponseFromJSON(json: any): GetOrderResponse {
    return GetOrderResponseFromJSONTyped(json, false);
}

export function GetOrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'number': json['number'] == null ? undefined : json['number'],
        'is_custom_assortment': json['is_custom_assortment'] == null ? undefined : json['is_custom_assortment'],
        'address': json['address'] == null ? undefined : AddressResponseFromJSON(json['address']),
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'total_order_price': json['total_order_price'] == null ? undefined : json['total_order_price'],
        'shipping_estimate_price': json['shipping_estimate_price'] == null ? undefined : json['shipping_estimate_price'],
        'total_with_estimated_shipping': json['total_with_estimated_shipping'] == null ? undefined : json['total_with_estimated_shipping'],
        'total_returns_price': json['total_returns_price'] == null ? undefined : json['total_returns_price'],
        'auth_amount': json['auth_amount'] == null ? undefined : json['auth_amount'],
        'payment_type': json['payment_type'] == null ? undefined : json['payment_type'],
        'financed': json['financed'] == null ? undefined : json['financed'],
        'suborders': json['suborders'] == null ? undefined : ((json['suborders'] as Array<any>).map(SuborderResponseFromJSON)),
        'brands': json['brands'] == null ? undefined : ((json['brands'] as Array<any>).map(BrandResponseFromJSON)),
        'is_first_order': json['is_first_order'] == null ? undefined : json['is_first_order'],
    };
}

export function GetOrderResponseToJSON(json: any): GetOrderResponse {
    return GetOrderResponseToJSONTyped(json, false);
}

export function GetOrderResponseToJSONTyped(value?: GetOrderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'retailer_name': value['retailer_name'],
        'number': value['number'],
        'is_custom_assortment': value['is_custom_assortment'],
        'address': AddressResponseToJSON(value['address']),
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'total_order_price': value['total_order_price'],
        'shipping_estimate_price': value['shipping_estimate_price'],
        'total_with_estimated_shipping': value['total_with_estimated_shipping'],
        'total_returns_price': value['total_returns_price'],
        'auth_amount': value['auth_amount'],
        'payment_type': value['payment_type'],
        'financed': value['financed'],
        'suborders': value['suborders'] == null ? undefined : ((value['suborders'] as Array<any>).map(SuborderResponseToJSON)),
        'brands': value['brands'] == null ? undefined : ((value['brands'] as Array<any>).map(BrandResponseToJSON)),
        'is_first_order': value['is_first_order'],
    };
}

