<script>
import { ContentManagementApiService } from '@/services/api/contentManagmentApi'
import { mapGetters } from 'vuex'

export default {
  props: {
    bannerData: {
      type: Object,
      default: null
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      banner: null,
      bannerHeight: 0,
      carouselActive: [],
      swiperReachStart: true,
      swiperReachEnd: false,
      position: 0,
      carouselInter: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState',
      isSiteWideBannerVisible: 'navigation/getSiteWideBannerVisible'
    }),
    showBanner() {
      if (!this.banner || !this.carouselActive) {
        return
      }
      if (this.preview) {
        return true
      }

      return this.isSiteWideBannerVisible
    }
  },
  watch: {
    user: {
      handler() {
        this.fetchBannerDataAndSetVisibility()
      },
      deep: true
    },
    banner: {
      async handler() {
        setTimeout(() => {
          this.calculateBannerHeight()
        })
      },
      deep: true
    }
  },
  mounted() {
    this.setCarousel()
  },
  async beforeMount() {
    await this.fetchBannerDataAndSetVisibility()
    this.calculateBannerHeight()
    window.addEventListener('resize', this.calculateBannerHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateBannerHeight)
  },
  methods: {
    calculateBannerHeight,
    setBannerVisibility,
    fetchBannerDataAndSetVisibility,
    slideToNext,
    slideToPrevious,
    setCarousel() {
      this.carouselInter = window.setInterval(() => {
        this.slideToNext()
      }, 5000)
    }
  }
}

async function fetchBannerDataAndSetVisibility() {
  const response = await ContentManagementApiService.getSiteWideBanner()
  this.toggled = response?.data[0].toggled
  this.banner = this.bannerData || response.data[0].view_components
  if (Array.isArray(this.banner)) {
    const activeArr = this.banner.filter((obj) => obj.info.active === 'true')
    if (activeArr.length <= 1) {
      this.carouselActive = this.banner.find((obj) => obj.info.active === 'true')
    } else {
      this.carouselActive = activeArr
    }
  } else {
    this.carouselActive = []
  }
  this.setBannerVisibility()
}

function setBannerVisibility() {
  const role = this.$session?.getRole()
  const showBannerBasedOnRole = !role || role === 'VISITOR' || role === 'RETAILER'
  if (Array.isArray(this.carouselActive)) {
    this.$store.dispatch(
      'navigation/setSiteWideBannerVisible',
      showBannerBasedOnRole ? this.carouselActive[0].info.active && this.toggled : false
    )
  } else {
    this.$store.dispatch(
      'navigation/setSiteWideBannerVisible',
      showBannerBasedOnRole ? (this.banner?.active || this.carouselActive?.info.active) && this.toggled : false
    )
  }
}

function calculateBannerHeight() {
  if (Array.isArray(this.carouselActive)) {
    this.$nextTick(() => {
      const siteWideBannerElements = this.$refs.siteWideBanner
      if (siteWideBannerElements && siteWideBannerElements.length > 0) {
        const bannerHeightArray = Array.from(siteWideBannerElements).map(
          (element) => element.getBoundingClientRect()?.height
        )
        this.bannerHeight = Math.max(...bannerHeightArray)
      } else {
        this.bannerHeight = 0
      }
      this.$store.dispatch('navigation/setSiteWideBannerHeight', this.bannerHeight)
    })
  } else {
    this.bannerHeight = this.$refs.siteWideBanner?.getBoundingClientRect()?.height || 0
  }
  this.$store.dispatch('navigation/setSiteWideBannerHeight', this.bannerHeight)
}

function slideToNext() {
  if (!this.carouselActive) {
    return
  }
  this.position = this.position === this.carouselActive.length - 1 ? 0 : this.position + 1
  clearInterval(this.carouselInter)
  this.setCarousel()
  this.calculateBannerHeight()
}
function slideToPrevious() {
  this.position = this.position === 0 ? this.carouselActive.length - 1 : this.position - 1
  clearInterval(this.carouselInter)
  this.setCarousel()
  this.calculateBannerHeight()
}
</script>
<template>
  <div v-if="Array.isArray(carouselActive) && !bannerData" class="carousel-collections-wrapper">
    <div class="carousel-container">
      <div
        v-for="(item, index) in carouselActive"
        :key="item.ordinal"
        class="slider-item"
        :class="index === position ? 'show' : 'hide'"
      >
        <div
          v-if="showBanner"
          id="site-wide-banner"
          ref="siteWideBanner"
          class="site-wide-banner"
          :style="{ backgroundColor: item.info.background_color, color: item.info.text_color }"
        >
          <span
            class="fadeIn"
            style="font-size: 25.5vw"
            v-html="
              `<a href='${item.info.link}' target='_blank' style='color: ${item.info.text_color}'>${item.info.title}</a>`
            "
          />
        </div>
      </div>
    </div>

    <Icon
      icon-key="chevron-left"
      hover-type="OPACITY"
      :size="36"
      :disabled="swiperReachStart"
      class="carousel-control left"
      :on-click="slideToPrevious"
    />
    <Icon
      icon-key="chevron-right"
      hover-type="OPACITY"
      :size="36"
      :disabled="swiperReachEnd"
      class="carousel-control right"
      :on-click="slideToPrevious"
    />
  </div>
  <div v-else-if="!bannerData && typeof carouselActive === 'object'">
    <div
      v-if="showBanner"
      id="site-wide-banner"
      ref="siteWideBanner"
      class="site-wide-banner"
      :style="{
        backgroundColor: carouselActive.info.background_color,
        color: carouselActive.info.text_color
      }"
    >
      <span
        v-html="
          `<a href='${carouselActive.info.link}' target='_blank' style='color: ${carouselActive.info.text_color}'>${carouselActive.info.title}</a>`
        "
      />
    </div>
  </div>
  <div v-else-if="bannerData">
    <div
      v-if="showBanner"
      id="site-wide-banner"
      ref="siteWideBanner"
      class="site-wide-banner"
      :style="{ backgroundColor: banner.background_color, color: banner.text_color }"
    >
      <span
        v-html="`<a href='${banner.link}' target='_blank' style='color: ${banner.text_color}'>${banner.title}</a>`"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/assets/sass/base/_vars.scss';
@import '~@/assets/sass/base/_helpers.scss';
.site-wide-banner {
  padding: 10px;
  @include respond-to(small) {
    padding: 10px 30px;
  }
  text-align: center;
  p,
  li,
  strong {
    font-family: NHaasGroteskDSPro-55Rg, serif !important;
    font-size: 12px;
    line-height: 1.4;
    color: inherit !important;
  }
  font-family: NHaasGroteskDSPro-55Rg, serif !important;
  font-size: 12px;
  line-height: 1.4;
}

.placeholder-slide {
  width: 16px;
}

.carousel-collections-wrapper {
  display: flex;

  .carousel-container {
    width: 100vw;
  }
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  &.left {
    left: 200px;
    @include respond-to(small) {
      left: 0;
    }
  }
  &.right {
    right: 200px;

    @include respond-to(small) {
      right: 0;
    }
  }
}

.mobile-preview {
  width: 375px !important;

  @include respond-to(small) {
    width: 100% !important;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
