<template>
  <div class="page-wrapper page-wrapper--white pb-130 pt-30-sm pb-80-sm">
    <div class="container">
      <h4 class="mb-50">Product Categories</h4>
      <div class="grid-wrapper grid-wrapper--col__4">
        <span
          v-for="collection in collections"
          :key="collection.id"
          data-test-id="category_tile_redirect"
          class="pointer"
          @click="goToCollection(collection.title, collection.id)"
        >
          <div class="product-box--image">
            <div class="overlay" />
            <!-- <img :src="collection.image_url">  -->
            <img
              :src="`https://bulletinassets.s3.amazonaws.com/catimg/${collection.title
                .toLowerCase()
                .replace(' - nav', '')}%402x.jpg`"
            />
          </div>
          <p class="text-center font-size-12-sm pt-30">
            {{ collection.title.replace(' - Nav', '').replace('- NAV', '') }}
          </p>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import CollectionApi from '../../../services/api/collectionApi'

const CollectionsApiService = new CollectionApi()

export default {
  data() {
    return {
      collections: []
    }
  },
  beforeMount() {
    CollectionsApiService.getHomeCollections()
      .then((response) => (this.collections = response.data))
      .catch((error) => {
        this.$handleError(this, error)
      })
  },
  methods: {
    goToCollection
  }
}

function goToCollection(title, collectionId) {
  const urlPath = `/collections/${title}/${collectionId}/products`
  this.$router.push(urlPath)
}
</script>
