/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BuyerResponse } from './BuyerResponse';
import {
    BuyerResponseFromJSON,
    BuyerResponseFromJSONTyped,
    BuyerResponseToJSON,
    BuyerResponseToJSONTyped,
} from './BuyerResponse';

/**
 * 
 * @export
 * @interface GetTradeShowBuyersResponse
 */
export interface GetTradeShowBuyersResponse {
    /**
     * 
     * @type {Array<BuyerResponse>}
     * @memberof GetTradeShowBuyersResponse
     */
    buyers?: Array<BuyerResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetTradeShowBuyersResponse
     */
    buyers_count?: number;
}

/**
 * Check if a given object implements the GetTradeShowBuyersResponse interface.
 */
export function instanceOfGetTradeShowBuyersResponse(value: object): value is GetTradeShowBuyersResponse {
    return true;
}

export function GetTradeShowBuyersResponseFromJSON(json: any): GetTradeShowBuyersResponse {
    return GetTradeShowBuyersResponseFromJSONTyped(json, false);
}

export function GetTradeShowBuyersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTradeShowBuyersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'buyers': json['buyers'] == null ? undefined : ((json['buyers'] as Array<any>).map(BuyerResponseFromJSON)),
        'buyers_count': json['buyers_count'] == null ? undefined : json['buyers_count'],
    };
}

export function GetTradeShowBuyersResponseToJSON(json: any): GetTradeShowBuyersResponse {
    return GetTradeShowBuyersResponseToJSONTyped(json, false);
}

export function GetTradeShowBuyersResponseToJSONTyped(value?: GetTradeShowBuyersResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'buyers': value['buyers'] == null ? undefined : ((value['buyers'] as Array<any>).map(BuyerResponseToJSON)),
        'buyers_count': value['buyers_count'],
    };
}

