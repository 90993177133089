/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create container
 * @export
 * @interface CreateViewContainerRequest
 */
export interface CreateViewContainerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerRequest
     */
    parent_container_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateViewContainerRequest
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateViewContainerRequest
     */
    ordinal: number;
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerRequest
     */
    container_direction: CreateViewContainerRequestContainerDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateViewContainerRequest
     */
    container_type: CreateViewContainerRequestContainerTypeEnum;
}


/**
 * @export
 */
export const CreateViewContainerRequestContainerDirectionEnum = {
    Row: 'ROW',
    Column: 'COLUMN',
    Grid: 'GRID'
} as const;
export type CreateViewContainerRequestContainerDirectionEnum = typeof CreateViewContainerRequestContainerDirectionEnum[keyof typeof CreateViewContainerRequestContainerDirectionEnum];

/**
 * @export
 */
export const CreateViewContainerRequestContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type CreateViewContainerRequestContainerTypeEnum = typeof CreateViewContainerRequestContainerTypeEnum[keyof typeof CreateViewContainerRequestContainerTypeEnum];


/**
 * Check if a given object implements the CreateViewContainerRequest interface.
 */
export function instanceOfCreateViewContainerRequest(value: object): value is CreateViewContainerRequest {
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('ordinal' in value) || value['ordinal'] === undefined) return false;
    if (!('container_direction' in value) || value['container_direction'] === undefined) return false;
    if (!('container_type' in value) || value['container_type'] === undefined) return false;
    return true;
}

export function CreateViewContainerRequestFromJSON(json: any): CreateViewContainerRequest {
    return CreateViewContainerRequestFromJSONTyped(json, false);
}

export function CreateViewContainerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateViewContainerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'parent_container_id': json['parent_container_id'] == null ? undefined : json['parent_container_id'],
        'active': json['active'],
        'ordinal': json['ordinal'],
        'container_direction': json['container_direction'],
        'container_type': json['container_type'],
    };
}

export function CreateViewContainerRequestToJSON(json: any): CreateViewContainerRequest {
    return CreateViewContainerRequestToJSONTyped(json, false);
}

export function CreateViewContainerRequestToJSONTyped(value?: CreateViewContainerRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'parent_container_id': value['parent_container_id'],
        'active': value['active'],
        'ordinal': value['ordinal'],
        'container_direction': value['container_direction'],
        'container_type': value['container_type'],
    };
}

