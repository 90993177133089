<template>
  <div
    class="text-form-group fleft"
    :class="{
      'one-third': width === 'one-third',
      half: width === 'half',
      'full-width': width === 'full',
      'no-spacing': noSpacing,
      'first-column': Number(column) === 1 && width !== 'full',
      'second-column': Number(column) === 2 && width !== 'full',
      'third-column': Number(column) === 3 && width !== 'full'
    }"
  >
    <div
      v-if="label && localValue && localValue.length !== 0"
      :class="{ invalid: $validator.errors.has(name) }"
      class="text-field-label b2 overflow-ellipsis text-light pl-10 inline-block"
    >
      <span>{{ label }}</span>
      <Icon v-if="showCopy" icon-key="copy" :size="20" class="ml-5 copy-container" :on-click="copyToClipboard" />
    </div>
    <div v-else-if="!noSpacing" style="height: 25px" />
    <input
      v-model="localValue"
      v-validate="validate"
      :data-vv-scope="scope"
      :name="name"
      :maxlength="maxlength"
      :placeholder="placeholder || label"
      :readonly="readonly"
      :disabled="disabled"
      :type="type"
      class="input-main b1"
      :class="{
        'invalid-border': $validator.errors.has(name),
        'with-copy text-overflow-ellipsis': showCopy && !label,
        readonly: readonly
      }"
      @focus="focus"
      @blur="blur"
    />

    <div v-if="showOptional" class="optional">Optional</div>

    <p v-if="hint" class="hint font-size-12">
      {{ hint }}
    </p>

    <error-message v-if="!noErrorMessage && $validator.errors.has(name)" class="invalid overflow-ellipsis">
      {{ getErrorMessageByRule($validator.errors.firstRule(name)) }}
    </error-message>
    <div v-else-if="!noSpacing" style="height: 25px" />
  </div>
</template>

<script>
import { formFieldHelpers } from '../formFieldHelper'

export default {
  mixins: [formFieldHelpers],
  inject: {
    parentValidator: {
      default: function () {
        return this.$validator
      }
    }
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    hint: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: undefined
    },
    showCopy: {
      type: Boolean,
      default: false
    },
    showOptional: {
      type: Boolean,
      default: false
    },
    scope: String
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.$validator = this.parentValidator
  },
  methods: {
    copyToClipboard: function () {
      this.$utils.copyToClipboard(this.localValue)
      this.toast({
        type: 'info',
        title: 'Information',
        message: `${this.label || this.placeholder} copied successfully!`
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./textField.scss"></style>
