/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilteredProductResponse
 */
export interface FilteredProductResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilteredProductResponse
     */
    product_ids?: Array<string>;
}

/**
 * Check if a given object implements the FilteredProductResponse interface.
 */
export function instanceOfFilteredProductResponse(value: object): value is FilteredProductResponse {
    return true;
}

export function FilteredProductResponseFromJSON(json: any): FilteredProductResponse {
    return FilteredProductResponseFromJSONTyped(json, false);
}

export function FilteredProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilteredProductResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
    };
}

export function FilteredProductResponseToJSON(json: any): FilteredProductResponse {
    return FilteredProductResponseToJSONTyped(json, false);
}

export function FilteredProductResponseToJSONTyped(value?: FilteredProductResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_ids': value['product_ids'],
    };
}

