/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCreditCardRequest
 */
export interface UpdateCreditCardRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCardRequest
     */
    stripe_card_token: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCardRequest
     */
    old_card_token?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCardRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreditCardRequest
     */
    last_name: string;
}

/**
 * Check if a given object implements the UpdateCreditCardRequest interface.
 */
export function instanceOfUpdateCreditCardRequest(value: object): value is UpdateCreditCardRequest {
    if (!('stripe_card_token' in value) || value['stripe_card_token'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    return true;
}

export function UpdateCreditCardRequestFromJSON(json: any): UpdateCreditCardRequest {
    return UpdateCreditCardRequestFromJSONTyped(json, false);
}

export function UpdateCreditCardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCreditCardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'stripe_card_token': json['stripe_card_token'],
        'old_card_token': json['old_card_token'] == null ? undefined : json['old_card_token'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
    };
}

export function UpdateCreditCardRequestToJSON(json: any): UpdateCreditCardRequest {
    return UpdateCreditCardRequestToJSONTyped(json, false);
}

export function UpdateCreditCardRequestToJSONTyped(value?: UpdateCreditCardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'stripe_card_token': value['stripe_card_token'],
        'old_card_token': value['old_card_token'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
    };
}

