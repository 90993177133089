<template>
  <div class="mb-10">
    <div class="flex items-center">
      <input
        :id="name"
        v-validate="'required'"
        :checked="value"
        class="acceptance-checkbox"
        :class="{ error: errors.has(name) }"
        :name="name"
        type="checkbox"
        @change="$emit('change')"
      />
      <label :for="name" class="acceptance-label">
        I agree to Emerald X’s
        <a class="underline" target="_blank" href="https://www.emeraldx.com/privacy-policy/">Privacy Policy</a>
        and
        <a class="underline" target="_blank" href="https://www.emeraldx.com/terms-of-use/"> Terms of Use</a>.
      </label>
    </div>
    <error-message :class="[errors.has(name) ? 'visible' : 'hidden']"> *Required for verification </error-message>
  </div>
</template>

<script>
export default {
  props: ['name', 'value']
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.acceptance-checkbox {
  cursor: pointer;
  width: 24px;
  height: 24px;
  accent-color: $color-black;
  flex-shrink: 0;

  &.error {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: 1px solid red;
    border-radius: 4px !important;
  }
}

.acceptance-label {
  cursor: pointer;
  font-family: NHaasGroteskDSPro-55Rg !important;
  font-size: 16px;
  letter-spacing: 0;
  margin-left: 10px;
}
</style>
