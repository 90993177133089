<template>
  <div class="flex items-center mb-10">
    <input
      :id="name"
      v-validate="'required'"
      :checked="value"
      class="acceptance-checkbox"
      :name="name"
      type="checkbox"
      @change="$emit('change')"
    />
    <label :for="name" class="acceptance-label">
      {{ label || 'Keep me updated with new arrivals, exclusive promotions, and recommendations from Bulletin.' }}
    </label>
  </div>
</template>

<script>
export default {
  props: ['name', 'value', 'label']
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.acceptance-checkbox {
  cursor: pointer;
  width: 24px;
  height: 24px;
  accent-color: $color-black;
  flex-shrink: 0;
}

.acceptance-label {
  cursor: pointer;
  font-family: NHaasGroteskDSPro-55Rg !important;
  font-size: 16px;
  letter-spacing: 0;
  margin-left: 10px;
}
</style>
