<template>
  <div class="flex py-20 bb-grey-3 justify-between items-start">
    <div class="flex-direction-column pointer" :class="{ 'no-events': disabled }" @click="onChange">
      <p class="b1">{{ label }}</p>
      <p class="secondary font-sans-regular mt-5">{{ description }}</p>
      <p v-if="linkConfig" class="underline b2 mt-10">
        <a :href="linkConfig.link" target="_blank">{{ linkConfig.label }}</a>
      </p>
    </div>
    <span v-tooltip="disabled && disabledTooltip ? disabledTooltip : null">
      <toggle
        :data-test-id="`${label}-toggle`"
        :value="value"
        :disabled="disabled"
        :has-lock="true"
        @click="onChange"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ToggleWithDescription',
  props: ['label', 'description', 'linkConfig', 'value', 'onChange', 'disabled', 'disabledTooltip']
}
</script>
