/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create Brand Board object
 * @export
 * @interface CreateBrandBoardRequest
 */
export interface CreateBrandBoardRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandBoardRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandBoardRequest
     */
    visibility?: CreateBrandBoardRequestVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandBoardRequest
     */
    show_on_profile?: boolean;
}


/**
 * @export
 */
export const CreateBrandBoardRequestVisibilityEnum = {
    Bulletin: 'BULLETIN',
    Public: 'PUBLIC'
} as const;
export type CreateBrandBoardRequestVisibilityEnum = typeof CreateBrandBoardRequestVisibilityEnum[keyof typeof CreateBrandBoardRequestVisibilityEnum];


/**
 * Check if a given object implements the CreateBrandBoardRequest interface.
 */
export function instanceOfCreateBrandBoardRequest(value: object): value is CreateBrandBoardRequest {
    return true;
}

export function CreateBrandBoardRequestFromJSON(json: any): CreateBrandBoardRequest {
    return CreateBrandBoardRequestFromJSONTyped(json, false);
}

export function CreateBrandBoardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBrandBoardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'visibility': json['visibility'] == null ? undefined : json['visibility'],
        'show_on_profile': json['show_on_profile'] == null ? undefined : json['show_on_profile'],
    };
}

export function CreateBrandBoardRequestToJSON(json: any): CreateBrandBoardRequest {
    return CreateBrandBoardRequestToJSONTyped(json, false);
}

export function CreateBrandBoardRequestToJSONTyped(value?: CreateBrandBoardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'visibility': value['visibility'],
        'show_on_profile': value['show_on_profile'],
    };
}

