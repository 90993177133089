/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomAssortmentItemRequest } from './CustomAssortmentItemRequest';
import {
    CustomAssortmentItemRequestFromJSON,
    CustomAssortmentItemRequestFromJSONTyped,
    CustomAssortmentItemRequestToJSON,
    CustomAssortmentItemRequestToJSONTyped,
} from './CustomAssortmentItemRequest';

/**
 * Update custom assortment object
 * @export
 * @interface UpdateCustomAssortmentRequest
 */
export interface UpdateCustomAssortmentRequest {
    /**
     * 
     * @type {Array<CustomAssortmentItemRequest>}
     * @memberof UpdateCustomAssortmentRequest
     */
    custom_assortment_items?: Array<CustomAssortmentItemRequest>;
}

/**
 * Check if a given object implements the UpdateCustomAssortmentRequest interface.
 */
export function instanceOfUpdateCustomAssortmentRequest(value: object): value is UpdateCustomAssortmentRequest {
    return true;
}

export function UpdateCustomAssortmentRequestFromJSON(json: any): UpdateCustomAssortmentRequest {
    return UpdateCustomAssortmentRequestFromJSONTyped(json, false);
}

export function UpdateCustomAssortmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCustomAssortmentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'custom_assortment_items': json['custom_assortment_items'] == null ? undefined : ((json['custom_assortment_items'] as Array<any>).map(CustomAssortmentItemRequestFromJSON)),
    };
}

export function UpdateCustomAssortmentRequestToJSON(json: any): UpdateCustomAssortmentRequest {
    return UpdateCustomAssortmentRequestToJSONTyped(json, false);
}

export function UpdateCustomAssortmentRequestToJSONTyped(value?: UpdateCustomAssortmentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'custom_assortment_items': value['custom_assortment_items'] == null ? undefined : ((value['custom_assortment_items'] as Array<any>).map(CustomAssortmentItemRequestToJSON)),
    };
}

