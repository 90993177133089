/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PromoCodeResponse } from './PromoCodeResponse';
import {
    PromoCodeResponseFromJSON,
    PromoCodeResponseFromJSONTyped,
    PromoCodeResponseToJSON,
    PromoCodeResponseToJSONTyped,
} from './PromoCodeResponse';

/**
 * 
 * @export
 * @interface PromoCodesWithPaginationResponse
 */
export interface PromoCodesWithPaginationResponse {
    /**
     * 
     * @type {Array<PromoCodeResponse>}
     * @memberof PromoCodesWithPaginationResponse
     */
    promo_codes?: Array<PromoCodeResponse>;
    /**
     * 
     * @type {number}
     * @memberof PromoCodesWithPaginationResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the PromoCodesWithPaginationResponse interface.
 */
export function instanceOfPromoCodesWithPaginationResponse(value: object): value is PromoCodesWithPaginationResponse {
    return true;
}

export function PromoCodesWithPaginationResponseFromJSON(json: any): PromoCodesWithPaginationResponse {
    return PromoCodesWithPaginationResponseFromJSONTyped(json, false);
}

export function PromoCodesWithPaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromoCodesWithPaginationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'promo_codes': json['promo_codes'] == null ? undefined : ((json['promo_codes'] as Array<any>).map(PromoCodeResponseFromJSON)),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function PromoCodesWithPaginationResponseToJSON(json: any): PromoCodesWithPaginationResponse {
    return PromoCodesWithPaginationResponseToJSONTyped(json, false);
}

export function PromoCodesWithPaginationResponseToJSONTyped(value?: PromoCodesWithPaginationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'promo_codes': value['promo_codes'] == null ? undefined : ((value['promo_codes'] as Array<any>).map(PromoCodeResponseToJSON)),
        'total': value['total'],
    };
}

