<template>
  <div class="access-container">
    <span class="subheading-3 mb-10">Access</span>
    <div v-click-outside="handleClose" class="access-dropdown" @click="handleOpen">
      <Icon :icon-key="selectedOption.icon" class="mr-5" :class="[effect ? 'animate-text' : '']" />
      <div class="flex-grow-1" :class="[effect ? 'animate-text' : '']">
        <p class="b1">{{ selectedOption.label }}</p>
        <p class="secondary font-sans-regular">{{ selectedOption.description }}</p>
      </div>
      <Icon :icon-key="open ? 'chevron-up' : 'chevron-down'" hover-type="OPACITY" />
    </div>
    <ol class="access-options" :class="[open ? 'access-options-open' : 'access-options-close']">
      <li v-for="option in options" :key="option.value" class="access-options-item" @click="handleChange(option.value)">
        <p class="b1">{{ option.label }}</p>
        <p class="secondary font-sans-regular">{{ option.description }}</p>
      </li>
    </ol>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  name: 'VisibilityDropdown',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: ['value', 'onChange'],
  data() {
    return {
      open: false,
      effect: false,
      options: [
        {
          value: 'PUBLIC',
          label: 'Public',
          description: 'Anyone can view this board.',
          icon: 'global'
        },
        {
          value: 'BULLETIN',
          label: 'Only Bulletin accounts',
          description: 'Anyone logged into a Bulletin account can view this board.',
          icon: 'user'
        }
      ]
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.value === this.value)
    }
  },
  methods: {
    handleOpen,
    handleClose,
    handleChange
  }
}

function handleOpen() {
  this.open = true
}

function handleClose() {
  this.open = false
}

function handleChange(value) {
  if (this.value !== value) {
    this.effect = true
    this.onChange(value)
    this.handleClose()
    setTimeout(() => (this.effect = false), 400)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
.access {
  &-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid $color-grey-3;
  }
  &-dropdown {
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  &-options {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: $color-white;
    border: 1px solid $color-grey-3;
    z-index: 22;
    transition: all 0.2s linear;

    &-open {
      opacity: 1;
      bottom: -100px;
    }
    &-close {
      bottom: -80px;
      opacity: 0;
      pointer-events: none;
    }

    &-item {
      padding: 4px 8px;
      cursor: pointer;
      &:hover {
        background-color: $color-light-green;
      }
    }
  }
}

@keyframes animation-text {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-text {
  animation-name: animation-text;
  animation-duration: 0.4s;
  animation-timing-function: linear;
}
</style>
