/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandPublishedPromoCodeResponse } from './BrandPublishedPromoCodeResponse';
import {
    BrandPublishedPromoCodeResponseFromJSON,
    BrandPublishedPromoCodeResponseFromJSONTyped,
    BrandPublishedPromoCodeResponseToJSON,
    BrandPublishedPromoCodeResponseToJSONTyped,
} from './BrandPublishedPromoCodeResponse';
import type { BrandTradeShowResponse } from './BrandTradeShowResponse';
import {
    BrandTradeShowResponseFromJSON,
    BrandTradeShowResponseFromJSONTyped,
    BrandTradeShowResponseToJSON,
    BrandTradeShowResponseToJSONTyped,
} from './BrandTradeShowResponse';
import type { GetBrandBoardResponse } from './GetBrandBoardResponse';
import {
    GetBrandBoardResponseFromJSON,
    GetBrandBoardResponseFromJSONTyped,
    GetBrandBoardResponseToJSON,
    GetBrandBoardResponseToJSONTyped,
} from './GetBrandBoardResponse';
import type { ShopifyBrandOrders } from './ShopifyBrandOrders';
import {
    ShopifyBrandOrdersFromJSON,
    ShopifyBrandOrdersFromJSONTyped,
    ShopifyBrandOrdersToJSON,
    ShopifyBrandOrdersToJSONTyped,
} from './ShopifyBrandOrders';
import type { DropdownItem } from './DropdownItem';
import {
    DropdownItemFromJSON,
    DropdownItemFromJSONTyped,
    DropdownItemToJSON,
    DropdownItemToJSONTyped,
} from './DropdownItem';

/**
 * 
 * @export
 * @interface GetBrandResponse
 */
export interface GetBrandResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    status?: GetBrandResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    brand_description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    brand_bio_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    instagram_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    instagram_followers?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    based_in?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    product_arrival?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    minimum_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    minimum_price?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBrandResponse
     */
    categories?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    is_published?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    increment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    instagram_growth_help?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof GetBrandResponse
     */
    values?: Array<DropdownItem>;
    /**
     * 
     * @type {number}
     * @memberof GetBrandResponse
     */
    case_pack_quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    user_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    bulletin_plus_access_level?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    onboarding_complete?: boolean;
    /**
     * 
     * @type {object}
     * @memberof GetBrandResponse
     */
    address?: object;
    /**
     * 
     * @type {object}
     * @memberof GetBrandResponse
     */
    fulfillment_address?: object;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    balance_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    phone_number?: string;
    /**
     * 
     * @type {ShopifyBrandOrders}
     * @memberof GetBrandResponse
     */
    shopify?: ShopifyBrandOrders;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof GetBrandResponse
     */
    special_services?: Array<DropdownItem>;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof GetBrandResponse
     */
    deals_and_promotions?: Array<DropdownItem>;
    /**
     * 
     * @type {Array<BrandPublishedPromoCodeResponse>}
     * @memberof GetBrandResponse
     */
    published_promo_codes?: Array<BrandPublishedPromoCodeResponse>;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    source?: GetBrandResponseSourceEnum;
    /**
     * 
     * @type {{ [key: string]: BrandTradeShowResponse; }}
     * @memberof GetBrandResponse
     */
    trade_shows?: { [key: string]: BrandTradeShowResponse; };
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    ships_from_usa_or_canada?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    bulletin_pick?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    buy_on_bulletin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandResponse
     */
    connect_with_shopify?: boolean;
    /**
     * 
     * @type {Array<GetBrandBoardResponse>}
     * @memberof GetBrandResponse
     */
    boards?: Array<GetBrandBoardResponse>;
    /**
     * 
     * @type {string}
     * @memberof GetBrandResponse
     */
    last_login_date?: string;
}


/**
 * @export
 */
export const GetBrandResponseStatusEnum = {
    Pending: 'PENDING',
    NeedsReview: 'NEEDS_REVIEW',
    Approved: 'APPROVED',
    Denied: 'DENIED',
    Registered: 'REGISTERED'
} as const;
export type GetBrandResponseStatusEnum = typeof GetBrandResponseStatusEnum[keyof typeof GetBrandResponseStatusEnum];

/**
 * @export
 */
export const GetBrandResponseSourceEnum = {
    Application: 'APPLICATION',
    Exhibitor: 'EXHIBITOR',
    Outreach: 'OUTREACH'
} as const;
export type GetBrandResponseSourceEnum = typeof GetBrandResponseSourceEnum[keyof typeof GetBrandResponseSourceEnum];


/**
 * Check if a given object implements the GetBrandResponse interface.
 */
export function instanceOfGetBrandResponse(value: object): value is GetBrandResponse {
    return true;
}

export function GetBrandResponseFromJSON(json: any): GetBrandResponse {
    return GetBrandResponseFromJSONTyped(json, false);
}

export function GetBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'status': json['status'] == null ? undefined : json['status'],
        'brand_description': json['brand_description'] == null ? undefined : json['brand_description'],
        'logo_url': json['logo_url'] == null ? undefined : json['logo_url'],
        'brand_bio_image_url': json['brand_bio_image_url'] == null ? undefined : json['brand_bio_image_url'],
        'website_url': json['website_url'] == null ? undefined : json['website_url'],
        'instagram_url': json['instagram_url'] == null ? undefined : json['instagram_url'],
        'instagram_followers': json['instagram_followers'] == null ? undefined : json['instagram_followers'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'product_arrival': json['product_arrival'] == null ? undefined : json['product_arrival'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'minimum_quantity': json['minimum_quantity'] == null ? undefined : json['minimum_quantity'],
        'minimum_price': json['minimum_price'] == null ? undefined : json['minimum_price'],
        'categories': json['categories'] == null ? undefined : json['categories'],
        'is_published': json['is_published'] == null ? undefined : json['is_published'],
        'increment': json['increment'] == null ? undefined : json['increment'],
        'instagram_growth_help': json['instagram_growth_help'] == null ? undefined : json['instagram_growth_help'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'values': json['values'] == null ? undefined : ((json['values'] as Array<any>).map(DropdownItemFromJSON)),
        'case_pack_quantity': json['case_pack_quantity'] == null ? undefined : json['case_pack_quantity'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
        'bulletin_plus_access_level': json['bulletin_plus_access_level'] == null ? undefined : json['bulletin_plus_access_level'],
        'onboarding_complete': json['onboarding_complete'] == null ? undefined : json['onboarding_complete'],
        'address': json['address'] == null ? undefined : json['address'],
        'fulfillment_address': json['fulfillment_address'] == null ? undefined : json['fulfillment_address'],
        'balance_id': json['balance_id'] == null ? undefined : json['balance_id'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'shopify': json['shopify'] == null ? undefined : ShopifyBrandOrdersFromJSON(json['shopify']),
        'special_services': json['special_services'] == null ? undefined : ((json['special_services'] as Array<any>).map(DropdownItemFromJSON)),
        'deals_and_promotions': json['deals_and_promotions'] == null ? undefined : ((json['deals_and_promotions'] as Array<any>).map(DropdownItemFromJSON)),
        'published_promo_codes': json['published_promo_codes'] == null ? undefined : ((json['published_promo_codes'] as Array<any>).map(BrandPublishedPromoCodeResponseFromJSON)),
        'source': json['source'] == null ? undefined : json['source'],
        'trade_shows': json['trade_shows'] == null ? undefined : (mapValues(json['trade_shows'], BrandTradeShowResponseFromJSON)),
        'ships_from_usa_or_canada': json['ships_from_usa_or_canada'] == null ? undefined : json['ships_from_usa_or_canada'],
        'bulletin_pick': json['bulletin_pick'] == null ? undefined : json['bulletin_pick'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
        'connect_with_shopify': json['connect_with_shopify'] == null ? undefined : json['connect_with_shopify'],
        'boards': json['boards'] == null ? undefined : ((json['boards'] as Array<any>).map(GetBrandBoardResponseFromJSON)),
        'last_login_date': json['last_login_date'] == null ? undefined : json['last_login_date'],
    };
}

export function GetBrandResponseToJSON(json: any): GetBrandResponse {
    return GetBrandResponseToJSONTyped(json, false);
}

export function GetBrandResponseToJSONTyped(value?: GetBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'brand_name': value['brand_name'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'status': value['status'],
        'brand_description': value['brand_description'],
        'logo_url': value['logo_url'],
        'brand_bio_image_url': value['brand_bio_image_url'],
        'website_url': value['website_url'],
        'instagram_url': value['instagram_url'],
        'instagram_followers': value['instagram_followers'],
        'based_in': value['based_in'],
        'product_arrival': value['product_arrival'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'minimum_quantity': value['minimum_quantity'],
        'minimum_price': value['minimum_price'],
        'categories': value['categories'],
        'is_published': value['is_published'],
        'increment': value['increment'],
        'instagram_growth_help': value['instagram_growth_help'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'vanity_url_name': value['vanity_url_name'],
        'values': value['values'] == null ? undefined : ((value['values'] as Array<any>).map(DropdownItemToJSON)),
        'case_pack_quantity': value['case_pack_quantity'],
        'user_id': value['user_id'],
        'bulletin_plus_access_level': value['bulletin_plus_access_level'],
        'onboarding_complete': value['onboarding_complete'],
        'address': value['address'],
        'fulfillment_address': value['fulfillment_address'],
        'balance_id': value['balance_id'],
        'phone_number': value['phone_number'],
        'shopify': ShopifyBrandOrdersToJSON(value['shopify']),
        'special_services': value['special_services'] == null ? undefined : ((value['special_services'] as Array<any>).map(DropdownItemToJSON)),
        'deals_and_promotions': value['deals_and_promotions'] == null ? undefined : ((value['deals_and_promotions'] as Array<any>).map(DropdownItemToJSON)),
        'published_promo_codes': value['published_promo_codes'] == null ? undefined : ((value['published_promo_codes'] as Array<any>).map(BrandPublishedPromoCodeResponseToJSON)),
        'source': value['source'],
        'trade_shows': value['trade_shows'] == null ? undefined : (mapValues(value['trade_shows'], BrandTradeShowResponseToJSON)),
        'ships_from_usa_or_canada': value['ships_from_usa_or_canada'],
        'bulletin_pick': value['bulletin_pick'],
        'buy_on_bulletin': value['buy_on_bulletin'],
        'connect_with_shopify': value['connect_with_shopify'],
        'boards': value['boards'] == null ? undefined : ((value['boards'] as Array<any>).map(GetBrandBoardResponseToJSON)),
        'last_login_date': value['last_login_date'],
    };
}

