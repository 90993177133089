/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BrandSignUpRequest,
  ChangeEmailResponse,
  ChangePasswordRequest,
  CreateBrandApplicantRequest,
  EmailWrapperDto,
  RefreshTokenResponse,
  ResetPasswordRequest,
  SetTradeShowAttendeePasswordRequest,
  SetTradeShowAttendeePasswordResponse,
  SignInRequest,
  SignInResponse,
  VerifyEmailRequest,
  ZendeskSignInRequest,
  ZendeskSingInResponse,
} from '../models/index';
import {
    BrandSignUpRequestFromJSON,
    BrandSignUpRequestToJSON,
    ChangeEmailResponseFromJSON,
    ChangeEmailResponseToJSON,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
    CreateBrandApplicantRequestFromJSON,
    CreateBrandApplicantRequestToJSON,
    EmailWrapperDtoFromJSON,
    EmailWrapperDtoToJSON,
    RefreshTokenResponseFromJSON,
    RefreshTokenResponseToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    SetTradeShowAttendeePasswordRequestFromJSON,
    SetTradeShowAttendeePasswordRequestToJSON,
    SetTradeShowAttendeePasswordResponseFromJSON,
    SetTradeShowAttendeePasswordResponseToJSON,
    SignInRequestFromJSON,
    SignInRequestToJSON,
    SignInResponseFromJSON,
    SignInResponseToJSON,
    VerifyEmailRequestFromJSON,
    VerifyEmailRequestToJSON,
    ZendeskSignInRequestFromJSON,
    ZendeskSignInRequestToJSON,
    ZendeskSingInResponseFromJSON,
    ZendeskSingInResponseToJSON,
} from '../models/index';

export interface ChangeEmailRequest {
    verifyEmailRequest: VerifyEmailRequest;
}

export interface ChangePasswordOperationRequest {
    changePasswordRequest: ChangePasswordRequest;
}

export interface ForgotPasswordRequest {
    emailWrapperDto: EmailWrapperDto;
}

export interface InviteAdminRequest {
    emailWrapperDto: EmailWrapperDto;
}

export interface RegisterBrandRequest {
    brandSignUpRequest: BrandSignUpRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest: ResetPasswordRequest;
}

export interface SendBrandInvitationMailRequest {
    id: string;
}

export interface SetTradeShowAttendeePasswordOperationRequest {
    setTradeShowAttendeePasswordRequest: SetTradeShowAttendeePasswordRequest;
}

export interface SignInOperationRequest {
    signInRequest: SignInRequest;
}

export interface VerifyBrandRequest {
    verifyEmailRequest: VerifyEmailRequest;
}

export interface VerifyRetailerRequest {
    verifyEmailRequest: VerifyEmailRequest;
}

export interface ZendeskSignInOperationRequest {
    zendeskSignInRequest: ZendeskSignInRequest;
}

/**
 * 
 */
export class AuthenticationControllerApi extends runtime.BaseAPI {

    /**
     * Change user email
     */
    async changeEmailRaw(requestParameters: ChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeEmailResponse>> {
        if (requestParameters['verifyEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'verifyEmailRequest',
                'Required parameter "verifyEmailRequest" was null or undefined when calling changeEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/change-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailRequestToJSON(requestParameters['verifyEmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeEmailResponseFromJSON(jsonValue));
    }

    /**
     * Change user email
     */
    async changeEmail(requestParameters: ChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeEmailResponse> {
        const response = await this.changeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change user password
     */
    async changePasswordRaw(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['changePasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'changePasswordRequest',
                'Required parameter "changePasswordRequest" was null or undefined when calling changePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters['changePasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change user password
     */
    async changePassword(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Extend reset password codes for all users
     */
    async extendAllResetPasswordCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/extend-all-reset-password-codes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Extend reset password codes for all users
     */
    async extendAllResetPasswordCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.extendAllResetPasswordCodesRaw(initOverrides);
    }

    /**
     * User forgot password
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailWrapperDto'] == null) {
            throw new runtime.RequiredError(
                'emailWrapperDto',
                'Required parameter "emailWrapperDto" was null or undefined when calling forgotPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailWrapperDtoToJSON(requestParameters['emailWrapperDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User forgot password
     */
    async forgotPassword(requestParameters: ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Invite admin
     */
    async inviteAdminRaw(requestParameters: InviteAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailWrapperDto'] == null) {
            throw new runtime.RequiredError(
                'emailWrapperDto',
                'Required parameter "emailWrapperDto" was null or undefined when calling inviteAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/admin/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailWrapperDtoToJSON(requestParameters['emailWrapperDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Invite admin
     */
    async inviteAdmin(requestParameters: InviteAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.inviteAdminRaw(requestParameters, initOverrides);
    }

    /**
     * Returns new access token using refresh token from cookie.
     */
    async refreshTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/refresh-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseFromJSON(jsonValue));
    }

    /**
     * Returns new access token using refresh token from cookie.
     */
    async refreshToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshTokenResponse> {
        const response = await this.refreshTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Brand registration. Returns registered brand info
     */
    async registerBrandRaw(requestParameters: RegisterBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        if (requestParameters['brandSignUpRequest'] == null) {
            throw new runtime.RequiredError(
                'brandSignUpRequest',
                'Required parameter "brandSignUpRequest" was null or undefined when calling registerBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/brand/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BrandSignUpRequestToJSON(requestParameters['brandSignUpRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     * Brand registration. Returns registered brand info
     */
    async registerBrand(requestParameters: RegisterBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.registerBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset user password
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        if (requestParameters['resetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'resetPasswordRequest',
                'Required parameter "resetPasswordRequest" was null or undefined when calling resetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters['resetPasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     * Reset user password
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send invitation email to brand
     */
    async sendBrandInvitationMailRaw(requestParameters: SendBrandInvitationMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendBrandInvitationMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/brand/{id}/invite`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send invitation email to brand
     */
    async sendBrandInvitationMail(requestParameters: SendBrandInvitationMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendBrandInvitationMailRaw(requestParameters, initOverrides);
    }

    /**
     * Set trade show attendee password
     */
    async setTradeShowAttendeePasswordRaw(requestParameters: SetTradeShowAttendeePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetTradeShowAttendeePasswordResponse>> {
        if (requestParameters['setTradeShowAttendeePasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'setTradeShowAttendeePasswordRequest',
                'Required parameter "setTradeShowAttendeePasswordRequest" was null or undefined when calling setTradeShowAttendeePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/retailer/set-attendee-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetTradeShowAttendeePasswordRequestToJSON(requestParameters['setTradeShowAttendeePasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetTradeShowAttendeePasswordResponseFromJSON(jsonValue));
    }

    /**
     * Set trade show attendee password
     */
    async setTradeShowAttendeePassword(requestParameters: SetTradeShowAttendeePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetTradeShowAttendeePasswordResponse> {
        const response = await this.setTradeShowAttendeePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User sign in. Returns info about user login
     */
    async signInRaw(requestParameters: SignInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        if (requestParameters['signInRequest'] == null) {
            throw new runtime.RequiredError(
                'signInRequest',
                'Required parameter "signInRequest" was null or undefined when calling signIn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestToJSON(requestParameters['signInRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     * User sign in. Returns info about user login
     */
    async signIn(requestParameters: SignInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.signInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify brand. Returns verified brand info
     */
    async verifyBrandRaw(requestParameters: VerifyBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBrandApplicantRequest>> {
        if (requestParameters['verifyEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'verifyEmailRequest',
                'Required parameter "verifyEmailRequest" was null or undefined when calling verifyBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/brand/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailRequestToJSON(requestParameters['verifyEmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBrandApplicantRequestFromJSON(jsonValue));
    }

    /**
     * Verify brand. Returns verified brand info
     */
    async verifyBrand(requestParameters: VerifyBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBrandApplicantRequest> {
        const response = await this.verifyBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify retailer. Returns registered retailer info
     */
    async verifyRetailerRaw(requestParameters: VerifyRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['verifyEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'verifyEmailRequest',
                'Required parameter "verifyEmailRequest" was null or undefined when calling verifyRetailer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/retailer/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailRequestToJSON(requestParameters['verifyEmailRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify retailer. Returns registered retailer info
     */
    async verifyRetailer(requestParameters: VerifyRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyRetailerRaw(requestParameters, initOverrides);
    }

    /**
     * Zendesk sign in. Returns JWT token for zendesk authorization
     */
    async zendeskSignInRaw(requestParameters: ZendeskSignInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ZendeskSingInResponse>> {
        if (requestParameters['zendeskSignInRequest'] == null) {
            throw new runtime.RequiredError(
                'zendeskSignInRequest',
                'Required parameter "zendeskSignInRequest" was null or undefined when calling zendeskSignIn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/zendesk-sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ZendeskSignInRequestToJSON(requestParameters['zendeskSignInRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZendeskSingInResponseFromJSON(jsonValue));
    }

    /**
     * Zendesk sign in. Returns JWT token for zendesk authorization
     */
    async zendeskSignIn(requestParameters: ZendeskSignInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ZendeskSingInResponse> {
        const response = await this.zendeskSignInRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
