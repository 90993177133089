/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeViewComponentActivityRequest,
  CreateViewComponentRequest,
  CreateViewComponentsRequest,
  CreateViewContainerElementsRequest,
  CreateViewContainerRequest,
  GetAbstractViewComponentResponse,
  IdWrapper,
  PaginatedFilteredProductResponse,
  RenameViewComponentRequest,
  UpdateBannerContainerRequest,
  UpdateViewComponentsRequest,
  UpdateViewContainerElementsRequest,
  UpdateViewContainerRequest,
  UploadComponentImageRequest,
  ViewContainerResponse,
} from '../models/index';
import {
    ChangeViewComponentActivityRequestFromJSON,
    ChangeViewComponentActivityRequestToJSON,
    CreateViewComponentRequestFromJSON,
    CreateViewComponentRequestToJSON,
    CreateViewComponentsRequestFromJSON,
    CreateViewComponentsRequestToJSON,
    CreateViewContainerElementsRequestFromJSON,
    CreateViewContainerElementsRequestToJSON,
    CreateViewContainerRequestFromJSON,
    CreateViewContainerRequestToJSON,
    GetAbstractViewComponentResponseFromJSON,
    GetAbstractViewComponentResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    PaginatedFilteredProductResponseFromJSON,
    PaginatedFilteredProductResponseToJSON,
    RenameViewComponentRequestFromJSON,
    RenameViewComponentRequestToJSON,
    UpdateBannerContainerRequestFromJSON,
    UpdateBannerContainerRequestToJSON,
    UpdateViewComponentsRequestFromJSON,
    UpdateViewComponentsRequestToJSON,
    UpdateViewContainerElementsRequestFromJSON,
    UpdateViewContainerElementsRequestToJSON,
    UpdateViewContainerRequestFromJSON,
    UpdateViewContainerRequestToJSON,
    UploadComponentImageRequestFromJSON,
    UploadComponentImageRequestToJSON,
    ViewContainerResponseFromJSON,
    ViewContainerResponseToJSON,
} from '../models/index';

export interface ChangeActivityRequest {
    changeViewComponentActivityRequest: ChangeViewComponentActivityRequest;
}

export interface CreateCollectionFromViewContainerRequest {
    id: string;
}

export interface CreateComponentRequest {
    id: string;
    createViewComponentRequest: CreateViewComponentRequest;
}

export interface CreateContainerRequest {
    createViewContainerRequest: CreateViewContainerRequest;
}

export interface CreateViewComponentsOperationRequest {
    id: string;
    createViewComponentsRequest: CreateViewComponentsRequest;
}

export interface CreateViewContainerElementsOperationRequest {
    id: string;
    createViewContainerElementsRequest: CreateViewContainerElementsRequest;
}

export interface DeleteComponentRequest {
    id: string;
}

export interface DeleteContainerRequest {
    id: string;
}

export interface DeleteViewContainerElementsRequest {
    parentContainerId: string;
}

export interface GetHomepageViewContainerRequest {
    type: GetHomepageViewContainerTypeEnum;
}

export interface GetNavigationRequest {
    ifNoneMatch?: string;
}

export interface GetProductsFromViewComponentRequest {
    id: string;
    page?: number;
    limit?: number;
}

export interface GetProductsFromViewContainerRequest {
    id: string;
    page?: number;
    limit?: number;
}

export interface GetViewContainerByIdRequest {
    id: string;
}

export interface GetViewContainersRequest {
    type: GetViewContainersTypeEnum;
}

export interface RenameViewComponentsRequest {
    renameViewComponentRequest: Array<RenameViewComponentRequest>;
}

export interface UpdateContainerRequest {
    type: UpdateContainerTypeEnum;
    updateViewContainerRequest: UpdateViewContainerRequest;
}

export interface UpdatePromoBannerContainerRequest {
    updateBannerContainerRequest: UpdateBannerContainerRequest;
}

export interface UpdateViewComponentsOperationRequest {
    updateViewComponentsRequest: Array<UpdateViewComponentsRequest>;
}

export interface UpdateViewContainerElementsOperationRequest {
    id: string;
    updateViewContainerElementsRequest: UpdateViewContainerElementsRequest;
}

export interface UploadComponentImageOperationRequest {
    componentId: string;
    uploadComponentImageRequest?: UploadComponentImageRequest;
}

export interface UploadImageToViewComponentRequest {
    id: string;
    image: Blob;
}

/**
 * 
 */
export class ViewControllerApi extends runtime.BaseAPI {

    /**
     * Change activity status for view component
     */
    async changeActivityRaw(requestParameters: ChangeActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['changeViewComponentActivityRequest'] == null) {
            throw new runtime.RequiredError(
                'changeViewComponentActivityRequest',
                'Required parameter "changeViewComponentActivityRequest" was null or undefined when calling changeActivity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/component/activity`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeViewComponentActivityRequestToJSON(requestParameters['changeViewComponentActivityRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change activity status for view component
     */
    async changeActivity(requestParameters: ChangeActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeActivityRaw(requestParameters, initOverrides);
    }

    /**
     * Construct navigation
     */
    async constructNavigationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/navigation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Construct navigation
     */
    async constructNavigation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.constructNavigationRaw(initOverrides);
    }

    /**
     * Create collection from view container
     */
    async createCollectionFromViewContainerRaw(requestParameters: CreateCollectionFromViewContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createCollectionFromViewContainer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}/create-collection`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create collection from view container
     */
    async createCollectionFromViewContainer(requestParameters: CreateCollectionFromViewContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createCollectionFromViewContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create component
     */
    async createComponentRaw(requestParameters: CreateComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createComponent().'
            );
        }

        if (requestParameters['createViewComponentRequest'] == null) {
            throw new runtime.RequiredError(
                'createViewComponentRequest',
                'Required parameter "createViewComponentRequest" was null or undefined when calling createComponent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}/component`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateViewComponentRequestToJSON(requestParameters['createViewComponentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create component
     */
    async createComponent(requestParameters: CreateComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create container
     */
    async createContainerRaw(requestParameters: CreateContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createViewContainerRequest'] == null) {
            throw new runtime.RequiredError(
                'createViewContainerRequest',
                'Required parameter "createViewContainerRequest" was null or undefined when calling createContainer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateViewContainerRequestToJSON(requestParameters['createViewContainerRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create container
     */
    async createContainer(requestParameters: CreateContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create view components
     */
    async createViewComponentsRaw(requestParameters: CreateViewComponentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createViewComponents().'
            );
        }

        if (requestParameters['createViewComponentsRequest'] == null) {
            throw new runtime.RequiredError(
                'createViewComponentsRequest',
                'Required parameter "createViewComponentsRequest" was null or undefined when calling createViewComponents().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}/components`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateViewComponentsRequestToJSON(requestParameters['createViewComponentsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create view components
     */
    async createViewComponents(requestParameters: CreateViewComponentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createViewComponentsRaw(requestParameters, initOverrides);
    }

    /**
     * Create view container elements
     */
    async createViewContainerElementsRaw(requestParameters: CreateViewContainerElementsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createViewContainerElements().'
            );
        }

        if (requestParameters['createViewContainerElementsRequest'] == null) {
            throw new runtime.RequiredError(
                'createViewContainerElementsRequest',
                'Required parameter "createViewContainerElementsRequest" was null or undefined when calling createViewContainerElements().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}/children-containers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateViewContainerElementsRequestToJSON(requestParameters['createViewContainerElementsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create view container elements
     */
    async createViewContainerElements(requestParameters: CreateViewContainerElementsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createViewContainerElementsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete component
     */
    async deleteComponentRaw(requestParameters: DeleteComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteComponent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete component
     */
    async deleteComponent(requestParameters: DeleteComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteComponentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete container
     */
    async deleteContainerRaw(requestParameters: DeleteContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteContainer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete container
     */
    async deleteContainer(requestParameters: DeleteContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContainerRaw(requestParameters, initOverrides);
    }

    /**
     * Delete view container elements
     */
    async deleteViewContainerElementsRaw(requestParameters: DeleteViewContainerElementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['parentContainerId'] == null) {
            throw new runtime.RequiredError(
                'parentContainerId',
                'Required parameter "parentContainerId" was null or undefined when calling deleteViewContainerElements().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{parentContainerId}/children-containers`.replace(`{${"parentContainerId"}}`, encodeURIComponent(String(requestParameters['parentContainerId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete view container elements
     */
    async deleteViewContainerElements(requestParameters: DeleteViewContainerElementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteViewContainerElementsRaw(requestParameters, initOverrides);
    }

    /**
     * Get homepage container and it\'s components by type. Allowed enum values for homepage containers: FEATURED_COLLECTIONS, FEATURED_BRANDS, PRODUCT_CATEGORIES, TRENDING_BRANDS, HERO, ABOUT.
     */
    async getHomepageViewContainerRaw(requestParameters: GetHomepageViewContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAbstractViewComponentResponse>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getHomepageViewContainer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/homepage/containers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAbstractViewComponentResponseFromJSON(jsonValue));
    }

    /**
     * Get homepage container and it\'s components by type. Allowed enum values for homepage containers: FEATURED_COLLECTIONS, FEATURED_BRANDS, PRODUCT_CATEGORIES, TRENDING_BRANDS, HERO, ABOUT.
     */
    async getHomepageViewContainer(requestParameters: GetHomepageViewContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAbstractViewComponentResponse> {
        const response = await this.getHomepageViewContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get navigation view container
     */
    async getNavigationRaw(requestParameters: GetNavigationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['ifNoneMatch'] != null) {
            headerParameters['If-None-Match'] = String(requestParameters['ifNoneMatch']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/navigation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get navigation view container
     */
    async getNavigation(requestParameters: GetNavigationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getNavigationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get products by view component Id
     */
    async getProductsFromViewComponentRaw(requestParameters: GetProductsFromViewComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFilteredProductResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getProductsFromViewComponent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/components/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFilteredProductResponseFromJSON(jsonValue));
    }

    /**
     * Get products by view component Id
     */
    async getProductsFromViewComponent(requestParameters: GetProductsFromViewComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFilteredProductResponse> {
        const response = await this.getProductsFromViewComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get products by view container Id
     */
    async getProductsFromViewContainerRaw(requestParameters: GetProductsFromViewContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFilteredProductResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getProductsFromViewContainer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFilteredProductResponseFromJSON(jsonValue));
    }

    /**
     * Get products by view container Id
     */
    async getProductsFromViewContainer(requestParameters: GetProductsFromViewContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFilteredProductResponse> {
        const response = await this.getProductsFromViewContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get view container by id
     */
    async getViewContainerByIdRaw(requestParameters: GetViewContainerByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewContainerResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getViewContainerById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewContainerResponseFromJSON(jsonValue));
    }

    /**
     * Get view container by id
     */
    async getViewContainerById(requestParameters: GetViewContainerByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewContainerResponse> {
        const response = await this.getViewContainerByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get view containers by type
     */
    async getViewContainersRaw(requestParameters: GetViewContainersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ViewContainerResponse>>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getViewContainers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViewContainerResponseFromJSON));
    }

    /**
     * Get view containers by type
     */
    async getViewContainers(requestParameters: GetViewContainersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ViewContainerResponse>> {
        const response = await this.getViewContainersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rename view components
     */
    async renameViewComponentsRaw(requestParameters: RenameViewComponentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['renameViewComponentRequest'] == null) {
            throw new runtime.RequiredError(
                'renameViewComponentRequest',
                'Required parameter "renameViewComponentRequest" was null or undefined when calling renameViewComponents().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/rename`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['renameViewComponentRequest']!.map(RenameViewComponentRequestToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rename view components
     */
    async renameViewComponents(requestParameters: RenameViewComponentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.renameViewComponentsRaw(requestParameters, initOverrides);
    }

    /**
     * Update homepage container with components. Allowed enum values for homepage containers: FEATURED_COLLECTIONS, FEATURED_BRANDS, PRODUCT_CATEGORIES, TRENDING_BRANDS, HERO, ABOUT.
     */
    async updateContainerRaw(requestParameters: UpdateContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateContainer().'
            );
        }

        if (requestParameters['updateViewContainerRequest'] == null) {
            throw new runtime.RequiredError(
                'updateViewContainerRequest',
                'Required parameter "updateViewContainerRequest" was null or undefined when calling updateContainer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/homepage/containers/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateViewContainerRequestToJSON(requestParameters['updateViewContainerRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update homepage container with components. Allowed enum values for homepage containers: FEATURED_COLLECTIONS, FEATURED_BRANDS, PRODUCT_CATEGORIES, TRENDING_BRANDS, HERO, ABOUT.
     */
    async updateContainer(requestParameters: UpdateContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateContainerRaw(requestParameters, initOverrides);
    }

    /**
     * Change activity status for view component
     */
    async updatePromoBannerContainerRaw(requestParameters: UpdatePromoBannerContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateBannerContainerRequest'] == null) {
            throw new runtime.RequiredError(
                'updateBannerContainerRequest',
                'Required parameter "updateBannerContainerRequest" was null or undefined when calling updatePromoBannerContainer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/components/promo-banner`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBannerContainerRequestToJSON(requestParameters['updateBannerContainerRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change activity status for view component
     */
    async updatePromoBannerContainer(requestParameters: UpdatePromoBannerContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePromoBannerContainerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateViewComponentsRaw(requestParameters: UpdateViewComponentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateViewComponentsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateViewComponentsRequest',
                'Required parameter "updateViewComponentsRequest" was null or undefined when calling updateViewComponents().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/homepage/components`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['updateViewComponentsRequest']!.map(UpdateViewComponentsRequestToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateViewComponents(requestParameters: UpdateViewComponentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateViewComponentsRaw(requestParameters, initOverrides);
    }

    /**
     * Update view container elements
     */
    async updateViewContainerElementsRaw(requestParameters: UpdateViewContainerElementsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateViewContainerElements().'
            );
        }

        if (requestParameters['updateViewContainerElementsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateViewContainerElementsRequest',
                'Required parameter "updateViewContainerElementsRequest" was null or undefined when calling updateViewContainerElements().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/containers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateViewContainerElementsRequestToJSON(requestParameters['updateViewContainerElementsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update view container elements
     */
    async updateViewContainerElements(requestParameters: UpdateViewContainerElementsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateViewContainerElementsRaw(requestParameters, initOverrides);
    }

    /**
     * Upload component image
     */
    async uploadComponentImageRaw(requestParameters: UploadComponentImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['componentId'] == null) {
            throw new runtime.RequiredError(
                'componentId',
                'Required parameter "componentId" was null or undefined when calling uploadComponentImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/images/{componentId}`.replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters['componentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadComponentImageRequestToJSON(requestParameters['uploadComponentImageRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload component image
     */
    async uploadComponentImage(requestParameters: UploadComponentImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadComponentImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update collection image
     */
    async uploadImageToViewComponentRaw(requestParameters: UploadImageToViewComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadImageToViewComponent().'
            );
        }

        if (requestParameters['image'] == null) {
            throw new runtime.RequiredError(
                'image',
                'Required parameter "image" was null or undefined when calling uploadImageToViewComponent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['image'] != null) {
            queryParameters['image'] = requestParameters['image'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/views/component/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update collection image
     */
    async uploadImageToViewComponent(requestParameters: UploadImageToViewComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadImageToViewComponentRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetHomepageViewContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type GetHomepageViewContainerTypeEnum = typeof GetHomepageViewContainerTypeEnum[keyof typeof GetHomepageViewContainerTypeEnum];
/**
 * @export
 */
export const GetViewContainersTypeEnum = {
    Navigation: 'NAVIGATION',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type GetViewContainersTypeEnum = typeof GetViewContainersTypeEnum[keyof typeof GetViewContainersTypeEnum];
/**
 * @export
 */
export const UpdateContainerTypeEnum = {
    Navigation: 'NAVIGATION',
    Landing: 'LANDING',
    FeaturedCollections: 'FEATURED_COLLECTIONS',
    FeaturedBrands: 'FEATURED_BRANDS',
    ProductCategories: 'PRODUCT_CATEGORIES',
    TrendingBrands: 'TRENDING_BRANDS',
    Hero: 'HERO',
    About: 'ABOUT',
    PromoBanner: 'PROMO_BANNER',
    PerfectForThe: 'PERFECT_FOR_THE',
    NyNow: 'NY_NOW',
    ExploreNewTrending: 'EXPLORE_NEW_TRENDING',
    TradeShow: 'TRADE_SHOW'
} as const;
export type UpdateContainerTypeEnum = typeof UpdateContainerTypeEnum[keyof typeof UpdateContainerTypeEnum];
