/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Estimate shipping object
 * @export
 * @interface EstimateShippingRequest
 */
export interface EstimateShippingRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimateShippingRequest
     */
    shipping_address_id: string;
}

/**
 * Check if a given object implements the EstimateShippingRequest interface.
 */
export function instanceOfEstimateShippingRequest(value: object): value is EstimateShippingRequest {
    if (!('shipping_address_id' in value) || value['shipping_address_id'] === undefined) return false;
    return true;
}

export function EstimateShippingRequestFromJSON(json: any): EstimateShippingRequest {
    return EstimateShippingRequestFromJSONTyped(json, false);
}

export function EstimateShippingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EstimateShippingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipping_address_id': json['shipping_address_id'],
    };
}

export function EstimateShippingRequestToJSON(json: any): EstimateShippingRequest {
    return EstimateShippingRequestToJSONTyped(json, false);
}

export function EstimateShippingRequestToJSONTyped(value?: EstimateShippingRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shipping_address_id': value['shipping_address_id'],
    };
}

