/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopifySuborderResponse
 */
export interface ShopifySuborderResponse {
    /**
     * 
     * @type {string}
     * @memberof ShopifySuborderResponse
     */
    shopify_status?: ShopifySuborderResponseShopifyStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifySuborderResponse
     */
    shopify_fulfill_complete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopifySuborderResponse
     */
    chargeable?: boolean;
}


/**
 * @export
 */
export const ShopifySuborderResponseShopifyStatusEnum = {
    Fulfilled: 'FULFILLED',
    Unfulfilled: 'UNFULFILLED',
    Partial: 'PARTIAL',
    Restocked: 'RESTOCKED',
    Null: 'NULL'
} as const;
export type ShopifySuborderResponseShopifyStatusEnum = typeof ShopifySuborderResponseShopifyStatusEnum[keyof typeof ShopifySuborderResponseShopifyStatusEnum];


/**
 * Check if a given object implements the ShopifySuborderResponse interface.
 */
export function instanceOfShopifySuborderResponse(value: object): value is ShopifySuborderResponse {
    return true;
}

export function ShopifySuborderResponseFromJSON(json: any): ShopifySuborderResponse {
    return ShopifySuborderResponseFromJSONTyped(json, false);
}

export function ShopifySuborderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopifySuborderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'shopify_status': json['shopify_status'] == null ? undefined : json['shopify_status'],
        'shopify_fulfill_complete': json['shopify_fulfill_complete'] == null ? undefined : json['shopify_fulfill_complete'],
        'chargeable': json['chargeable'] == null ? undefined : json['chargeable'],
    };
}

export function ShopifySuborderResponseToJSON(json: any): ShopifySuborderResponse {
    return ShopifySuborderResponseToJSONTyped(json, false);
}

export function ShopifySuborderResponseToJSONTyped(value?: ShopifySuborderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shopify_status': value['shopify_status'],
        'shopify_fulfill_complete': value['shopify_fulfill_complete'],
        'chargeable': value['chargeable'],
    };
}

