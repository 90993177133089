/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandsApplicationsPagination } from './BrandsApplicationsPagination';
import {
    BrandsApplicationsPaginationFromJSON,
    BrandsApplicationsPaginationFromJSONTyped,
    BrandsApplicationsPaginationToJSON,
    BrandsApplicationsPaginationToJSONTyped,
} from './BrandsApplicationsPagination';
import type { BrandsApplicationsResponse } from './BrandsApplicationsResponse';
import {
    BrandsApplicationsResponseFromJSON,
    BrandsApplicationsResponseFromJSONTyped,
    BrandsApplicationsResponseToJSON,
    BrandsApplicationsResponseToJSONTyped,
} from './BrandsApplicationsResponse';

/**
 * 
 * @export
 * @interface PaginatedBrandsApplicationsResponse
 */
export interface PaginatedBrandsApplicationsResponse {
    /**
     * 
     * @type {Array<BrandsApplicationsResponse>}
     * @memberof PaginatedBrandsApplicationsResponse
     */
    hits?: Array<BrandsApplicationsResponse>;
    /**
     * 
     * @type {BrandsApplicationsPagination}
     * @memberof PaginatedBrandsApplicationsResponse
     */
    pagination?: BrandsApplicationsPagination;
}

/**
 * Check if a given object implements the PaginatedBrandsApplicationsResponse interface.
 */
export function instanceOfPaginatedBrandsApplicationsResponse(value: object): value is PaginatedBrandsApplicationsResponse {
    return true;
}

export function PaginatedBrandsApplicationsResponseFromJSON(json: any): PaginatedBrandsApplicationsResponse {
    return PaginatedBrandsApplicationsResponseFromJSONTyped(json, false);
}

export function PaginatedBrandsApplicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedBrandsApplicationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(BrandsApplicationsResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : BrandsApplicationsPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedBrandsApplicationsResponseToJSON(json: any): PaginatedBrandsApplicationsResponse {
    return PaginatedBrandsApplicationsResponseToJSONTyped(json, false);
}

export function PaginatedBrandsApplicationsResponseToJSONTyped(value?: PaginatedBrandsApplicationsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(BrandsApplicationsResponseToJSON)),
        'pagination': BrandsApplicationsPaginationToJSON(value['pagination']),
    };
}

