/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update order return object
 * @export
 * @interface UpdateOrderReturnRequest
 */
export interface UpdateOrderReturnRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderReturnRequest
     */
    tracking_number?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderReturnRequest
     */
    shipping_cost?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderReturnRequest
     */
    shipping_carrier?: UpdateOrderReturnRequestShippingCarrierEnum;
}


/**
 * @export
 */
export const UpdateOrderReturnRequestShippingCarrierEnum = {
    Apc: 'APC',
    CanadaPost: 'CANADA_POST',
    Canpar: 'CANPAR',
    Dhl: 'DHL',
    FedEx: 'FedEx',
    Gls: 'GLS',
    NewGistics: 'NEW_GISTICS',
    Purolator: 'PUROLATOR',
    Sendle: 'SENDLE',
    SfExpress: 'SF_EXPRESS',
    Ups: 'UPS',
    Usps: 'USPS',
    Other: 'OTHER'
} as const;
export type UpdateOrderReturnRequestShippingCarrierEnum = typeof UpdateOrderReturnRequestShippingCarrierEnum[keyof typeof UpdateOrderReturnRequestShippingCarrierEnum];


/**
 * Check if a given object implements the UpdateOrderReturnRequest interface.
 */
export function instanceOfUpdateOrderReturnRequest(value: object): value is UpdateOrderReturnRequest {
    return true;
}

export function UpdateOrderReturnRequestFromJSON(json: any): UpdateOrderReturnRequest {
    return UpdateOrderReturnRequestFromJSONTyped(json, false);
}

export function UpdateOrderReturnRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrderReturnRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tracking_number': json['tracking_number'] == null ? undefined : json['tracking_number'],
        'shipping_cost': json['shipping_cost'] == null ? undefined : json['shipping_cost'],
        'shipping_carrier': json['shipping_carrier'] == null ? undefined : json['shipping_carrier'],
    };
}

export function UpdateOrderReturnRequestToJSON(json: any): UpdateOrderReturnRequest {
    return UpdateOrderReturnRequestToJSONTyped(json, false);
}

export function UpdateOrderReturnRequestToJSONTyped(value?: UpdateOrderReturnRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'tracking_number': value['tracking_number'],
        'shipping_cost': value['shipping_cost'],
        'shipping_carrier': value['shipping_carrier'],
    };
}

