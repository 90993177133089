/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PackingSlipItemDto } from './PackingSlipItemDto';
import {
    PackingSlipItemDtoFromJSON,
    PackingSlipItemDtoFromJSONTyped,
    PackingSlipItemDtoToJSON,
    PackingSlipItemDtoToJSONTyped,
} from './PackingSlipItemDto';

/**
 * Create packing slip object
 * @export
 * @interface CreatePackingSlipRequest
 */
export interface CreatePackingSlipRequest {
    /**
     * 
     * @type {Array<PackingSlipItemDto>}
     * @memberof CreatePackingSlipRequest
     */
    items?: Array<PackingSlipItemDto>;
}

/**
 * Check if a given object implements the CreatePackingSlipRequest interface.
 */
export function instanceOfCreatePackingSlipRequest(value: object): value is CreatePackingSlipRequest {
    return true;
}

export function CreatePackingSlipRequestFromJSON(json: any): CreatePackingSlipRequest {
    return CreatePackingSlipRequestFromJSONTyped(json, false);
}

export function CreatePackingSlipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePackingSlipRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(PackingSlipItemDtoFromJSON)),
    };
}

export function CreatePackingSlipRequestToJSON(json: any): CreatePackingSlipRequest {
    return CreatePackingSlipRequestToJSONTyped(json, false);
}

export function CreatePackingSlipRequestToJSONTyped(value?: CreatePackingSlipRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(PackingSlipItemDtoToJSON)),
    };
}

