/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandDropdownInfo } from './BrandDropdownInfo';
import {
    BrandDropdownInfoFromJSON,
    BrandDropdownInfoFromJSONTyped,
    BrandDropdownInfoToJSON,
    BrandDropdownInfoToJSONTyped,
} from './BrandDropdownInfo';

/**
 * 
 * @export
 * @interface BrandsDropdownResponse
 */
export interface BrandsDropdownResponse {
    /**
     * 
     * @type {Array<BrandDropdownInfo>}
     * @memberof BrandsDropdownResponse
     */
    brands_dropdown?: Array<BrandDropdownInfo>;
}

/**
 * Check if a given object implements the BrandsDropdownResponse interface.
 */
export function instanceOfBrandsDropdownResponse(value: object): value is BrandsDropdownResponse {
    return true;
}

export function BrandsDropdownResponseFromJSON(json: any): BrandsDropdownResponse {
    return BrandsDropdownResponseFromJSONTyped(json, false);
}

export function BrandsDropdownResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandsDropdownResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'brands_dropdown': json['brands_dropdown'] == null ? undefined : ((json['brands_dropdown'] as Array<any>).map(BrandDropdownInfoFromJSON)),
    };
}

export function BrandsDropdownResponseToJSON(json: any): BrandsDropdownResponse {
    return BrandsDropdownResponseToJSONTyped(json, false);
}

export function BrandsDropdownResponseToJSONTyped(value?: BrandsDropdownResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brands_dropdown': value['brands_dropdown'] == null ? undefined : ((value['brands_dropdown'] as Array<any>).map(BrandDropdownInfoToJSON)),
    };
}

