/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConditionOperatorResponse
 */
export interface ConditionOperatorResponse {
    /**
     * 
     * @type {string}
     * @memberof ConditionOperatorResponse
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionOperatorResponse
     */
    name?: string;
}

/**
 * Check if a given object implements the ConditionOperatorResponse interface.
 */
export function instanceOfConditionOperatorResponse(value: object): value is ConditionOperatorResponse {
    return true;
}

export function ConditionOperatorResponseFromJSON(json: any): ConditionOperatorResponse {
    return ConditionOperatorResponseFromJSONTyped(json, false);
}

export function ConditionOperatorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionOperatorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ConditionOperatorResponseToJSON(json: any): ConditionOperatorResponse {
    return ConditionOperatorResponseToJSONTyped(json, false);
}

export function ConditionOperatorResponseToJSONTyped(value?: ConditionOperatorResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'name': value['name'],
    };
}

