/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SaveDraftProductRequest } from './SaveDraftProductRequest';
import {
    SaveDraftProductRequestFromJSON,
    SaveDraftProductRequestFromJSONTyped,
    SaveDraftProductRequestToJSON,
    SaveDraftProductRequestToJSONTyped,
} from './SaveDraftProductRequest';

/**
 * Bulk save draft products request
 * @export
 * @interface BulkSaveDraftProductRequest
 */
export interface BulkSaveDraftProductRequest {
    /**
     * 
     * @type {Array<SaveDraftProductRequest>}
     * @memberof BulkSaveDraftProductRequest
     */
    products?: Array<SaveDraftProductRequest>;
}

/**
 * Check if a given object implements the BulkSaveDraftProductRequest interface.
 */
export function instanceOfBulkSaveDraftProductRequest(value: object): value is BulkSaveDraftProductRequest {
    return true;
}

export function BulkSaveDraftProductRequestFromJSON(json: any): BulkSaveDraftProductRequest {
    return BulkSaveDraftProductRequestFromJSONTyped(json, false);
}

export function BulkSaveDraftProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkSaveDraftProductRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(SaveDraftProductRequestFromJSON)),
    };
}

export function BulkSaveDraftProductRequestToJSON(json: any): BulkSaveDraftProductRequest {
    return BulkSaveDraftProductRequestToJSONTyped(json, false);
}

export function BulkSaveDraftProductRequestToJSONTyped(value?: BulkSaveDraftProductRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(SaveDraftProductRequestToJSON)),
    };
}

