/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStripeCustomerIdResponse
 */
export interface GetStripeCustomerIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStripeCustomerIdResponse
     */
    stripe_customer_id?: string;
}

/**
 * Check if a given object implements the GetStripeCustomerIdResponse interface.
 */
export function instanceOfGetStripeCustomerIdResponse(value: object): value is GetStripeCustomerIdResponse {
    return true;
}

export function GetStripeCustomerIdResponseFromJSON(json: any): GetStripeCustomerIdResponse {
    return GetStripeCustomerIdResponseFromJSONTyped(json, false);
}

export function GetStripeCustomerIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStripeCustomerIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'stripe_customer_id': json['stripe_customer_id'] == null ? undefined : json['stripe_customer_id'],
    };
}

export function GetStripeCustomerIdResponseToJSON(json: any): GetStripeCustomerIdResponse {
    return GetStripeCustomerIdResponseToJSONTyped(json, false);
}

export function GetStripeCustomerIdResponseToJSONTyped(value?: GetStripeCustomerIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'stripe_customer_id': value['stripe_customer_id'],
    };
}

