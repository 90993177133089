/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    original_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    type?: UploadImageResponseTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UploadImageResponse
     */
    is_custom_assortment_image?: boolean;
}


/**
 * @export
 */
export const UploadImageResponseTypeEnum = {
    Product: 'PRODUCT',
    Location: 'LOCATION'
} as const;
export type UploadImageResponseTypeEnum = typeof UploadImageResponseTypeEnum[keyof typeof UploadImageResponseTypeEnum];


/**
 * Check if a given object implements the UploadImageResponse interface.
 */
export function instanceOfUploadImageResponse(value: object): value is UploadImageResponse {
    return true;
}

export function UploadImageResponseFromJSON(json: any): UploadImageResponse {
    return UploadImageResponseFromJSONTyped(json, false);
}

export function UploadImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadImageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'url': json['url'] == null ? undefined : json['url'],
        'original_url': json['original_url'] == null ? undefined : json['original_url'],
        'type': json['type'] == null ? undefined : json['type'],
        'is_custom_assortment_image': json['is_custom_assortment_image'] == null ? undefined : json['is_custom_assortment_image'],
    };
}

export function UploadImageResponseToJSON(json: any): UploadImageResponse {
    return UploadImageResponseToJSONTyped(json, false);
}

export function UploadImageResponseToJSONTyped(value?: UploadImageResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'url': value['url'],
        'original_url': value['original_url'],
        'type': value['type'],
        'is_custom_assortment_image': value['is_custom_assortment_image'],
    };
}

