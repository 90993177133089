/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralResponse
 */
export interface ReferralResponse {
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    brand_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    store?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    status?: ReferralResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferralResponse
     */
    created_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralResponse
     */
    completed?: boolean;
}


/**
 * @export
 */
export const ReferralResponseStatusEnum = {
    InviteSent: 'INVITE_SENT',
    NotVerified: 'NOT_VERIFIED',
    SignedUp: 'SIGNED_UP',
    OrderPlaced: 'ORDER_PLACED'
} as const;
export type ReferralResponseStatusEnum = typeof ReferralResponseStatusEnum[keyof typeof ReferralResponseStatusEnum];


/**
 * Check if a given object implements the ReferralResponse interface.
 */
export function instanceOfReferralResponse(value: object): value is ReferralResponse {
    return true;
}

export function ReferralResponseFromJSON(json: any): ReferralResponse {
    return ReferralResponseFromJSONTyped(json, false);
}

export function ReferralResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'name': json['name'] == null ? undefined : json['name'],
        'store': json['store'] == null ? undefined : json['store'],
        'email': json['email'] == null ? undefined : json['email'],
        'status': json['status'] == null ? undefined : json['status'],
        'created_at': json['created_at'] == null ? undefined : json['created_at'],
        'completed': json['completed'] == null ? undefined : json['completed'],
    };
}

export function ReferralResponseToJSON(json: any): ReferralResponse {
    return ReferralResponseToJSONTyped(json, false);
}

export function ReferralResponseToJSONTyped(value?: ReferralResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'brand_id': value['brand_id'],
        'brand_name': value['brand_name'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'name': value['name'],
        'store': value['store'],
        'email': value['email'],
        'status': value['status'],
        'created_at': value['created_at'],
        'completed': value['completed'],
    };
}

