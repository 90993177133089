<template>
  <div v-if="cartItems && cartItems.length" class="cart-summary">
    <h4 class="font-size-12 mb-20">In your cart</h4>
    <div class="cart-items">
      <div v-for="item in cartItems" :key="item.name" class="font-size-14">
        <span class="mr-10"> {{ item.quantity }}</span>
        {{ item.name }}
      </div>
    </div>
    <div v-show="totalQuantity && totalPrice" class="flex justify-end">
      <h4 class="font-size-12 mt-20">{{ totalQuantity }} TOTAL - ${{ totalPrice }}</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    initialCart: {
      type: Object,
      required: true
    },
    productId: {
      type: String,
      required: true
    },
    variants: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => {
    return {
      cart: null,
      cartItems: [],
      totalPrice: null,
      totalQuantity: null
    }
  },
  computed: {
    ...mapGetters({
      count: 'cart/count'
    })
  },
  mounted() {
    this.initCartItemsAndTotalValues(this.initialCart)
    this.$eventBus.$on('refresh-product-cart-summary', this.fetchCart)
  },

  beforeDestroy() {
    this.$eventBus.$off('refresh-product-cart-summary', this.fetchCart)
  },
  methods: {
    fetchCart,
    initCartItemsAndTotalValues
  }
}

async function fetchCart() {
  try {
    const cart = (await this.$utils.getCart())?.data
    this.initCartItemsAndTotalValues(cart)
  } catch (error) {
    this.$handleError(this, error)
  }
}

function initCartItemsAndTotalValues(cart) {
  this.totalPrice = 0
  this.totalQuantity = 0
  this.cartItems = []
  cart?.sub_carts.forEach((subCart) => {
    subCart?.cart_items?.forEach(({ product_id, quantity, variant_id, price }) => {
      if (product_id === this.productId) {
        const variant = this.variants.find((item) => item.id === variant_id)
        const variantName = Object.keys(variant?.option)
          ?.filter((item) => item !== 'size')
          ?.map((key) => this.$utils.capitalize(variant.option[key]))
          ?.join(' - ')
        this.cartItems.push({ quantity, name: `${variantName} - Size ${variant?.option['size']}` })
        this.totalPrice += price * quantity
        this.totalQuantity += quantity
      }
    })
  })
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
.cart-summary {
  background: $color-grey-1;
  text-align: left;
  padding: 20px;
}
</style>
