export default {
  props: {
    active: {
      type: Number
    },
    limit: {
      type: Number
    },
    total: {
      type: Number
    },
    onClick: {
      type: Function
    },
    numberOfPages: 0
  },
  data() {
    return {
      offset: 5,
      pages: Number
    }
  },

  methods: {
    calculatePages,
    goToPage,
    handlePreviousClick,
    handleNextClick
  },

  watch: {
    total: function () {
      this.calculatePages(this.activePage, this.limit, this.total)
    },

    limit: function () {
      this.calculatePages(this.activePage, this.limit, this.total)
    }
  },
  computed: {
    hideSinglePage: function () {
      return this.pages > 1
    },
    preButtonDisabled: function () {
      return this.activePage === 1
    },
    nextButtonDisabled: function () {
      return this.activePage === this.pages
    },
    activePage: function () {
      const { page } = this.$route.query
      if (Number(page)) {
        return Number(page)
      }
      return this.active
    }
  },
  created() {
    const paginationOffsetDesktop = 5,
      paginationOffsetMobile = 3
    this.offset = this.$utils.constants.IS_MOBILE ? paginationOffsetMobile : paginationOffsetDesktop

    this.calculatePages(this.activePage, this.limit, this.total)
  }
}

function calculatePages(currentPage, limit, totalCount) {
  if (this.numberOfPages) {
    this.pages = this.numberOfPages
  } else {
    this.pages = Math.ceil(totalCount / limit)
  }
}

function handleNextClick() {
  window.scrollTo(0, 0)
  this.onClick(this.activePage + 1)
}
function handlePreviousClick() {
  window.scrollTo(0, 0)
  this.onClick(this.activePage - 1)
}

function goToPage(index) {
  window.scrollTo(0, 0)
  this.onClick(index)
}
