/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateViewComponentRequest
 */
export interface CreateViewComponentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateViewComponentRequest
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateViewComponentRequest
     */
    component_type: CreateViewComponentRequestComponentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateViewComponentRequest
     */
    ordinal: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateViewComponentRequest
     */
    info?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof CreateViewComponentRequest
     */
    active?: boolean;
}


/**
 * @export
 */
export const CreateViewComponentRequestComponentTypeEnum = {
    Category: 'CATEGORY',
    Collection: 'COLLECTION',
    Brand: 'BRAND',
    Hero: 'HERO',
    PromoBanner: 'PROMO_BANNER',
    About: 'ABOUT'
} as const;
export type CreateViewComponentRequestComponentTypeEnum = typeof CreateViewComponentRequestComponentTypeEnum[keyof typeof CreateViewComponentRequestComponentTypeEnum];


/**
 * Check if a given object implements the CreateViewComponentRequest interface.
 */
export function instanceOfCreateViewComponentRequest(value: object): value is CreateViewComponentRequest {
    if (!('entity_id' in value) || value['entity_id'] === undefined) return false;
    if (!('component_type' in value) || value['component_type'] === undefined) return false;
    if (!('ordinal' in value) || value['ordinal'] === undefined) return false;
    return true;
}

export function CreateViewComponentRequestFromJSON(json: any): CreateViewComponentRequest {
    return CreateViewComponentRequestFromJSONTyped(json, false);
}

export function CreateViewComponentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateViewComponentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'],
        'component_type': json['component_type'],
        'ordinal': json['ordinal'],
        'info': json['info'] == null ? undefined : json['info'],
        'active': json['active'] == null ? undefined : json['active'],
    };
}

export function CreateViewComponentRequestToJSON(json: any): CreateViewComponentRequest {
    return CreateViewComponentRequestToJSONTyped(json, false);
}

export function CreateViewComponentRequestToJSONTyped(value?: CreateViewComponentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'entity_id': value['entity_id'],
        'component_type': value['component_type'],
        'ordinal': value['ordinal'],
        'info': value['info'],
        'active': value['active'],
    };
}

