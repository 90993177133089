<template>
  <div class="flex justify-between items-center mb-15 pointer" @click="onClick">
    <div class="flex items-center">
      <img v-if="images[0]" class="board-item-card-image" :src="images[0]" />
      <div v-else class="board-item-card-image no-image">
        <span class="b2"> 0 saved </span>
      </div>
      <div class="flex flex-direction-column ml-10">
        <span class="body-copy-1">{{ name }}</span>
        <span class="secondary font-sans-serif">{{ count }} products</span>
      </div>
    </div>

    <checkbox-new :no-margin="true" :value="checked" />
  </div>
</template>

<script>
export default {
  props: ['id', 'name', 'count', 'images', 'onClick', 'checked']
}
</script>

<style scoped lang="scss">
@import '~@/assets/sass/base/_vars.scss';
.board-item-card {
  &-image {
    width: 91px;
    height: 91px;
    border-radius: 4px;
    object-fit: contain;

    &.no-image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-grey-2;
      color: $color-grey-4;
    }
  }
}
</style>
