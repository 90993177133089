/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeEmailResponse
 */
export interface ChangeEmailResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailResponse
     */
    role?: ChangeEmailResponseRoleEnum;
}


/**
 * @export
 */
export const ChangeEmailResponseRoleEnum = {
    Brand: 'BRAND',
    Retailer: 'RETAILER',
    Admin: 'ADMIN',
    ProductBuilder: 'PRODUCT_BUILDER'
} as const;
export type ChangeEmailResponseRoleEnum = typeof ChangeEmailResponseRoleEnum[keyof typeof ChangeEmailResponseRoleEnum];


/**
 * Check if a given object implements the ChangeEmailResponse interface.
 */
export function instanceOfChangeEmailResponse(value: object): value is ChangeEmailResponse {
    return true;
}

export function ChangeEmailResponseFromJSON(json: any): ChangeEmailResponse {
    return ChangeEmailResponseFromJSONTyped(json, false);
}

export function ChangeEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeEmailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function ChangeEmailResponseToJSON(json: any): ChangeEmailResponse {
    return ChangeEmailResponseToJSONTyped(json, false);
}

export function ChangeEmailResponseToJSONTyped(value?: ChangeEmailResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'role': value['role'],
    };
}

