<template>
  <div
    class="text-field-label b2 overflow-ellipsis text-light floatingLabel"
    :class="{ floatingLabelVisible: value, invalid: invalid ? invalid : false }"
  >
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ''
    },
    invalid: {
      default: ''
    },
    label: {
      default: ''
    }
  },
  methods: {}
}
</script>

<style lang="scss">
@import '@/assets/sass/base/vars.scss';
.floatingLabel {
  margin-left: 10px;
  visibility: hidden;
}

.floatingLabelVisible {
  visibility: visible;
}

.floatingLabel.invalid {
  color: $color-error;
}
</style>
