/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandBoardShareResponse
 */
export interface BrandBoardShareResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandBoardShareResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandBoardShareResponse
     */
    role?: BrandBoardShareResponseRoleEnum;
}


/**
 * @export
 */
export const BrandBoardShareResponseRoleEnum = {
    Creator: 'CREATOR',
    Viewer: 'VIEWER'
} as const;
export type BrandBoardShareResponseRoleEnum = typeof BrandBoardShareResponseRoleEnum[keyof typeof BrandBoardShareResponseRoleEnum];


/**
 * Check if a given object implements the BrandBoardShareResponse interface.
 */
export function instanceOfBrandBoardShareResponse(value: object): value is BrandBoardShareResponse {
    return true;
}

export function BrandBoardShareResponseFromJSON(json: any): BrandBoardShareResponse {
    return BrandBoardShareResponseFromJSONTyped(json, false);
}

export function BrandBoardShareResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandBoardShareResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function BrandBoardShareResponseToJSON(json: any): BrandBoardShareResponse {
    return BrandBoardShareResponseToJSONTyped(json, false);
}

export function BrandBoardShareResponseToJSONTyped(value?: BrandBoardShareResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'role': value['role'],
    };
}

