/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateReferralsByEmailsResponse
 */
export interface CreateReferralsByEmailsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateReferralsByEmailsResponse
     */
    emails_submitted_twice_by_brand?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateReferralsByEmailsResponse
     */
    undeliverable_emails?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateReferralsByEmailsResponse
     */
    referral_count?: number;
}

/**
 * Check if a given object implements the CreateReferralsByEmailsResponse interface.
 */
export function instanceOfCreateReferralsByEmailsResponse(value: object): value is CreateReferralsByEmailsResponse {
    return true;
}

export function CreateReferralsByEmailsResponseFromJSON(json: any): CreateReferralsByEmailsResponse {
    return CreateReferralsByEmailsResponseFromJSONTyped(json, false);
}

export function CreateReferralsByEmailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReferralsByEmailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'emails_submitted_twice_by_brand': json['emails_submitted_twice_by_brand'] == null ? undefined : json['emails_submitted_twice_by_brand'],
        'undeliverable_emails': json['undeliverable_emails'] == null ? undefined : json['undeliverable_emails'],
        'referral_count': json['referral_count'] == null ? undefined : json['referral_count'],
    };
}

export function CreateReferralsByEmailsResponseToJSON(json: any): CreateReferralsByEmailsResponse {
    return CreateReferralsByEmailsResponseToJSONTyped(json, false);
}

export function CreateReferralsByEmailsResponseToJSONTyped(value?: CreateReferralsByEmailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'emails_submitted_twice_by_brand': value['emails_submitted_twice_by_brand'],
        'undeliverable_emails': value['undeliverable_emails'],
        'referral_count': value['referral_count'],
    };
}

