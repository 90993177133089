<template>
  <dropdown
    :placeholder="placeholder"
    :class="{ invalid: errors.has('card') }"
    :name="'card'"
    :selected-name="selectedCard ? selectedCard.name : null"
    :on-select="handleSelectChange"
    :dropdown-items="cards"
    :is-required="true"
  />
</template>
<script>
import { mapGetters } from 'vuex'
import cardApi from '@/services/api/cardApi'

const cardApiService = new cardApi()
export default {
  props: {
    filterByCardId: {
      type: Array,
      default: () => []
    },
    retailerId: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select card'
    },
    selectedCardId: {
      type: String,
      default: null
    },
    nonSavedCard: {
      type: Object,
      default: () => {}
    },
    onSelect: {
      type: Function,
      default: () => {}
    },
    onCardsLoaded: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      cards: [],
      selectedCard: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  beforeMount() {
    this.getCreditCards()
  },
  methods: {
    getCreditCards,
    handleSelectChange,
    mapCardDataToDropdownItem
  }
}
async function getCreditCards() {
  try {
    const { data } =
      this.user.role === 'ADMIN' && this.retailerId
        ? await cardApiService.getRetailerCards(this.retailerId)
        : await cardApiService.getStoredCreditCardList()

    this.cards = data
      .filter((card) => !this.filterByCardId.includes(card.card_id))
      .map((card) => {
        const mappedCard = this.mapCardDataToDropdownItem(card)

        if (card.is_default && !this.selectedCardId) {
          this.selectedCard = mappedCard
        }

        return mappedCard
      })

    if (this.selectedCardId) {
      this.selectedCard = this.cards.find((item) => item.value === this.selectedCardId)
    }

    // If the selected card is not in the list, have to populate "manually"
    if (!this.selectedCard && this.nonSavedCard) {
      this.selectedCard = this.mapCardDataToDropdownItem({ ...this.nonSavedCard, is_default: false })
      this.cards = [...[this.selectedCard], ...this.cards]
    }
  } catch (error) {
    this.$handleError(this, this.$utils.formatErrorMessage('credit cards'))
  } finally {
    this.onCardsLoaded({ cards: this.cards, selectedCard: this.selectedCard })
  }
}

function mapCardDataToDropdownItem({ last_4, card_type, expiration_month, expiration_year, is_default, card_id }) {
  const month = expiration_month > 9 ? expiration_month : `0${expiration_month}`
  const year = expiration_year?.length === 4 ? expiration_year.slice(2, 4) : expiration_year

  return {
    name: `${card_type} ***${last_4}, Expires ${month}/${year} ${is_default ? '(default)' : ''}`,
    value: card_id,
    is_default: is_default
  }
}

function handleSelectChange(item) {
  this.onSelect(item)
}
</script>
