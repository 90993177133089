/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TradeShowFeatureResponse } from './TradeShowFeatureResponse';
import {
    TradeShowFeatureResponseFromJSON,
    TradeShowFeatureResponseFromJSONTyped,
    TradeShowFeatureResponseToJSON,
    TradeShowFeatureResponseToJSONTyped,
} from './TradeShowFeatureResponse';

/**
 * 
 * @export
 * @interface GetTradeShowByEventIdResponse
 */
export interface GetTradeShowByEventIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    slug?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetTradeShowByEventIdResponse
     */
    start_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetTradeShowByEventIdResponse
     */
    end_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetTradeShowByEventIdResponse
     */
    exhibitor_count?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradeShowByEventIdResponse
     */
    attendee_count?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    registration_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTradeShowByEventIdResponse
     */
    collections_visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowByEventIdResponse
     */
    view_container_id?: string;
    /**
     * 
     * @type {object}
     * @memberof GetTradeShowByEventIdResponse
     */
    exhibitor_directory_header?: object;
    /**
     * 
     * @type {object}
     * @memberof GetTradeShowByEventIdResponse
     */
    buyer_directory_header?: object;
    /**
     * 
     * @type {Array<TradeShowFeatureResponse>}
     * @memberof GetTradeShowByEventIdResponse
     */
    features?: Array<TradeShowFeatureResponse>;
}

/**
 * Check if a given object implements the GetTradeShowByEventIdResponse interface.
 */
export function instanceOfGetTradeShowByEventIdResponse(value: object): value is GetTradeShowByEventIdResponse {
    return true;
}

export function GetTradeShowByEventIdResponseFromJSON(json: any): GetTradeShowByEventIdResponse {
    return GetTradeShowByEventIdResponseFromJSONTyped(json, false);
}

export function GetTradeShowByEventIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTradeShowByEventIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'name': json['name'] == null ? undefined : json['name'],
        'location': json['location'] == null ? undefined : json['location'],
        'external_id': json['external_id'] == null ? undefined : json['external_id'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'exhibitor_count': json['exhibitor_count'] == null ? undefined : json['exhibitor_count'],
        'attendee_count': json['attendee_count'] == null ? undefined : json['attendee_count'],
        'registration_url': json['registration_url'] == null ? undefined : json['registration_url'],
        'collections_visible': json['collections_visible'] == null ? undefined : json['collections_visible'],
        'view_container_id': json['view_container_id'] == null ? undefined : json['view_container_id'],
        'exhibitor_directory_header': json['exhibitor_directory_header'] == null ? undefined : json['exhibitor_directory_header'],
        'buyer_directory_header': json['buyer_directory_header'] == null ? undefined : json['buyer_directory_header'],
        'features': json['features'] == null ? undefined : ((json['features'] as Array<any>).map(TradeShowFeatureResponseFromJSON)),
    };
}

export function GetTradeShowByEventIdResponseToJSON(json: any): GetTradeShowByEventIdResponse {
    return GetTradeShowByEventIdResponseToJSONTyped(json, false);
}

export function GetTradeShowByEventIdResponseToJSONTyped(value?: GetTradeShowByEventIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'brand': value['brand'],
        'name': value['name'],
        'location': value['location'],
        'external_id': value['external_id'],
        'slug': value['slug'],
        'start_date': value['start_date'] == null ? undefined : ((value['start_date']).toISOString()),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString()),
        'exhibitor_count': value['exhibitor_count'],
        'attendee_count': value['attendee_count'],
        'registration_url': value['registration_url'],
        'collections_visible': value['collections_visible'],
        'view_container_id': value['view_container_id'],
        'exhibitor_directory_header': value['exhibitor_directory_header'],
        'buyer_directory_header': value['buyer_directory_header'],
        'features': value['features'] == null ? undefined : ((value['features'] as Array<any>).map(TradeShowFeatureResponseToJSON)),
    };
}

