import { mapGetters } from 'vuex'

const ROLES = ['ADMIN', 'BRAND', 'RETAILER', 'PRODUCT_BUILDER']
export default {
  props: {
    roles: {
      type: Array,
      default: () => {
        return []
      },
      validator: (roleList) => {
        if (!roleList?.length) {
          return true
        }

        return roleList?.filter((item) => ROLES.includes(item)).length === roleList.length
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  methods: {
    hasAccess: function () {
      return !this.roles?.length || this.roles.includes(this.user?.role)
    }
  }
}
