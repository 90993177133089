/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HubSpotRetailerSignUpRequest
 */
export interface HubSpotRetailerSignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    store_website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    store_name?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    utm_medium?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    utm_source?: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpotRetailerSignUpRequest
     */
    utm_campaign?: string;
}

/**
 * Check if a given object implements the HubSpotRetailerSignUpRequest interface.
 */
export function instanceOfHubSpotRetailerSignUpRequest(value: object): value is HubSpotRetailerSignUpRequest {
    return true;
}

export function HubSpotRetailerSignUpRequestFromJSON(json: any): HubSpotRetailerSignUpRequest {
    return HubSpotRetailerSignUpRequestFromJSONTyped(json, false);
}

export function HubSpotRetailerSignUpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HubSpotRetailerSignUpRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'password': json['password'] == null ? undefined : json['password'],
        'store_website_url': json['store_website_url'] == null ? undefined : json['store_website_url'],
        'store_name': json['store_name'] == null ? undefined : json['store_name'],
        'utm_medium': json['utm_medium'] == null ? undefined : json['utm_medium'],
        'utm_source': json['utm_source'] == null ? undefined : json['utm_source'],
        'utm_campaign': json['utm_campaign'] == null ? undefined : json['utm_campaign'],
    };
}

export function HubSpotRetailerSignUpRequestToJSON(json: any): HubSpotRetailerSignUpRequest {
    return HubSpotRetailerSignUpRequestToJSONTyped(json, false);
}

export function HubSpotRetailerSignUpRequestToJSONTyped(value?: HubSpotRetailerSignUpRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'password': value['password'],
        'store_website_url': value['store_website_url'],
        'store_name': value['store_name'],
        'utm_medium': value['utm_medium'],
        'utm_source': value['utm_source'],
        'utm_campaign': value['utm_campaign'],
    };
}

