/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumOptionOrderStatus } from './EnumOptionOrderStatus';
import {
    EnumOptionOrderStatusFromJSON,
    EnumOptionOrderStatusFromJSONTyped,
    EnumOptionOrderStatusToJSON,
    EnumOptionOrderStatusToJSONTyped,
} from './EnumOptionOrderStatus';

/**
 * 
 * @export
 * @interface GetEnumOptionsResponseOrderStatus
 */
export interface GetEnumOptionsResponseOrderStatus {
    /**
     * 
     * @type {Array<EnumOptionOrderStatus>}
     * @memberof GetEnumOptionsResponseOrderStatus
     */
    options?: Array<EnumOptionOrderStatus>;
}

/**
 * Check if a given object implements the GetEnumOptionsResponseOrderStatus interface.
 */
export function instanceOfGetEnumOptionsResponseOrderStatus(value: object): value is GetEnumOptionsResponseOrderStatus {
    return true;
}

export function GetEnumOptionsResponseOrderStatusFromJSON(json: any): GetEnumOptionsResponseOrderStatus {
    return GetEnumOptionsResponseOrderStatusFromJSONTyped(json, false);
}

export function GetEnumOptionsResponseOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEnumOptionsResponseOrderStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'] == null ? undefined : ((json['options'] as Array<any>).map(EnumOptionOrderStatusFromJSON)),
    };
}

export function GetEnumOptionsResponseOrderStatusToJSON(json: any): GetEnumOptionsResponseOrderStatus {
    return GetEnumOptionsResponseOrderStatusToJSONTyped(json, false);
}

export function GetEnumOptionsResponseOrderStatusToJSONTyped(value?: GetEnumOptionsResponseOrderStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': value['options'] == null ? undefined : ((value['options'] as Array<any>).map(EnumOptionOrderStatusToJSON)),
    };
}

