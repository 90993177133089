/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Password and reset password code
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordRequest
     */
    bulletin_marketing_opt_in?: boolean;
}

/**
 * Check if a given object implements the ResetPasswordRequest interface.
 */
export function instanceOfResetPasswordRequest(value: object): value is ResetPasswordRequest {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function ResetPasswordRequestFromJSON(json: any): ResetPasswordRequest {
    return ResetPasswordRequestFromJSONTyped(json, false);
}

export function ResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'password': json['password'],
        'bulletin_marketing_opt_in': json['bulletin_marketing_opt_in'] == null ? undefined : json['bulletin_marketing_opt_in'],
    };
}

export function ResetPasswordRequestToJSON(json: any): ResetPasswordRequest {
    return ResetPasswordRequestToJSONTyped(json, false);
}

export function ResetPasswordRequestToJSONTyped(value?: ResetPasswordRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': value['code'],
        'password': value['password'],
        'bulletin_marketing_opt_in': value['bulletin_marketing_opt_in'],
    };
}

