/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkBypassOnboardingRequest
 */
export interface BulkBypassOnboardingRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkBypassOnboardingRequest
     */
    brand_ids?: Array<string>;
}

/**
 * Check if a given object implements the BulkBypassOnboardingRequest interface.
 */
export function instanceOfBulkBypassOnboardingRequest(value: object): value is BulkBypassOnboardingRequest {
    return true;
}

export function BulkBypassOnboardingRequestFromJSON(json: any): BulkBypassOnboardingRequest {
    return BulkBypassOnboardingRequestFromJSONTyped(json, false);
}

export function BulkBypassOnboardingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkBypassOnboardingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_ids': json['brand_ids'] == null ? undefined : json['brand_ids'],
    };
}

export function BulkBypassOnboardingRequestToJSON(json: any): BulkBypassOnboardingRequest {
    return BulkBypassOnboardingRequestToJSONTyped(json, false);
}

export function BulkBypassOnboardingRequestToJSONTyped(value?: BulkBypassOnboardingRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_ids': value['brand_ids'],
    };
}

