/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetDashboardInvoicesResponse } from './GetDashboardInvoicesResponse';
import {
    GetDashboardInvoicesResponseFromJSON,
    GetDashboardInvoicesResponseFromJSONTyped,
    GetDashboardInvoicesResponseToJSON,
    GetDashboardInvoicesResponseToJSONTyped,
} from './GetDashboardInvoicesResponse';
import type { InvoicesPaginationResponse } from './InvoicesPaginationResponse';
import {
    InvoicesPaginationResponseFromJSON,
    InvoicesPaginationResponseFromJSONTyped,
    InvoicesPaginationResponseToJSON,
    InvoicesPaginationResponseToJSONTyped,
} from './InvoicesPaginationResponse';

/**
 * 
 * @export
 * @interface PaginatedInvoicesResponse
 */
export interface PaginatedInvoicesResponse {
    /**
     * 
     * @type {Array<GetDashboardInvoicesResponse>}
     * @memberof PaginatedInvoicesResponse
     */
    hits?: Array<GetDashboardInvoicesResponse>;
    /**
     * 
     * @type {InvoicesPaginationResponse}
     * @memberof PaginatedInvoicesResponse
     */
    pagination?: InvoicesPaginationResponse;
}

/**
 * Check if a given object implements the PaginatedInvoicesResponse interface.
 */
export function instanceOfPaginatedInvoicesResponse(value: object): value is PaginatedInvoicesResponse {
    return true;
}

export function PaginatedInvoicesResponseFromJSON(json: any): PaginatedInvoicesResponse {
    return PaginatedInvoicesResponseFromJSONTyped(json, false);
}

export function PaginatedInvoicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedInvoicesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(GetDashboardInvoicesResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : InvoicesPaginationResponseFromJSON(json['pagination']),
    };
}

export function PaginatedInvoicesResponseToJSON(json: any): PaginatedInvoicesResponse {
    return PaginatedInvoicesResponseToJSONTyped(json, false);
}

export function PaginatedInvoicesResponseToJSONTyped(value?: PaginatedInvoicesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(GetDashboardInvoicesResponseToJSON)),
        'pagination': InvoicesPaginationResponseToJSON(value['pagination']),
    };
}

