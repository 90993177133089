/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InventoryLevelVariantResponse } from './InventoryLevelVariantResponse';
import {
    InventoryLevelVariantResponseFromJSON,
    InventoryLevelVariantResponseFromJSONTyped,
    InventoryLevelVariantResponseToJSON,
    InventoryLevelVariantResponseToJSONTyped,
} from './InventoryLevelVariantResponse';

/**
 * 
 * @export
 * @interface InventoryLevelProductResponse
 */
export interface InventoryLevelProductResponse {
    /**
     * 
     * @type {string}
     * @memberof InventoryLevelProductResponse
     */
    product_id?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryLevelProductResponse
     */
    product_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLevelProductResponse
     */
    track_inventory?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryLevelProductResponse
     */
    thumbnail_image?: string;
    /**
     * 
     * @type {Array<InventoryLevelVariantResponse>}
     * @memberof InventoryLevelProductResponse
     */
    variants?: Array<InventoryLevelVariantResponse>;
}

/**
 * Check if a given object implements the InventoryLevelProductResponse interface.
 */
export function instanceOfInventoryLevelProductResponse(value: object): value is InventoryLevelProductResponse {
    return true;
}

export function InventoryLevelProductResponseFromJSON(json: any): InventoryLevelProductResponse {
    return InventoryLevelProductResponseFromJSONTyped(json, false);
}

export function InventoryLevelProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryLevelProductResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'product_id': json['product_id'] == null ? undefined : json['product_id'],
        'product_name': json['product_name'] == null ? undefined : json['product_name'],
        'track_inventory': json['track_inventory'] == null ? undefined : json['track_inventory'],
        'thumbnail_image': json['thumbnail_image'] == null ? undefined : json['thumbnail_image'],
        'variants': json['variants'] == null ? undefined : ((json['variants'] as Array<any>).map(InventoryLevelVariantResponseFromJSON)),
    };
}

export function InventoryLevelProductResponseToJSON(json: any): InventoryLevelProductResponse {
    return InventoryLevelProductResponseToJSONTyped(json, false);
}

export function InventoryLevelProductResponseToJSONTyped(value?: InventoryLevelProductResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_id': value['product_id'],
        'product_name': value['product_name'],
        'track_inventory': value['track_inventory'],
        'thumbnail_image': value['thumbnail_image'],
        'variants': value['variants'] == null ? undefined : ((value['variants'] as Array<any>).map(InventoryLevelVariantResponseToJSON)),
    };
}

