/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddNewReferralsResponse
 */
export interface AddNewReferralsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddNewReferralsResponse
     */
    invalid_referrals?: Array<string>;
}

/**
 * Check if a given object implements the AddNewReferralsResponse interface.
 */
export function instanceOfAddNewReferralsResponse(value: object): value is AddNewReferralsResponse {
    return true;
}

export function AddNewReferralsResponseFromJSON(json: any): AddNewReferralsResponse {
    return AddNewReferralsResponseFromJSONTyped(json, false);
}

export function AddNewReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNewReferralsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'invalid_referrals': json['invalid_referrals'] == null ? undefined : json['invalid_referrals'],
    };
}

export function AddNewReferralsResponseToJSON(json: any): AddNewReferralsResponse {
    return AddNewReferralsResponseToJSONTyped(json, false);
}

export function AddNewReferralsResponseToJSONTyped(value?: AddNewReferralsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'invalid_referrals': value['invalid_referrals'],
    };
}

