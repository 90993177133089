/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuborderItemFromReorderResponse
 */
export interface SuborderItemFromReorderResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderItemFromReorderResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemFromReorderResponse
     */
    product_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderItemFromReorderResponse
     */
    is_product_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemFromReorderResponse
     */
    variant_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderItemFromReorderResponse
     */
    is_variant_deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderItemFromReorderResponse
     */
    is_tester?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemFromReorderResponse
     */
    original_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemFromReorderResponse
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemFromReorderResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemFromReorderResponse
     */
    product_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemFromReorderResponse
     */
    product_image?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SuborderItemFromReorderResponse
     */
    characteristic_option_map?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof SuborderItemFromReorderResponse
     */
    track_inventory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderItemFromReorderResponse
     */
    in_stock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemFromReorderResponse
     */
    inventory_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemFromReorderResponse
     */
    wholesale_increment?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemFromReorderResponse
     */
    wholesale_minimum?: number;
}

/**
 * Check if a given object implements the SuborderItemFromReorderResponse interface.
 */
export function instanceOfSuborderItemFromReorderResponse(value: object): value is SuborderItemFromReorderResponse {
    return true;
}

export function SuborderItemFromReorderResponseFromJSON(json: any): SuborderItemFromReorderResponse {
    return SuborderItemFromReorderResponseFromJSONTyped(json, false);
}

export function SuborderItemFromReorderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderItemFromReorderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'product_id': json['product_id'] == null ? undefined : json['product_id'],
        'is_product_active': json['is_product_active'] == null ? undefined : json['is_product_active'],
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
        'is_variant_deleted': json['is_variant_deleted'] == null ? undefined : json['is_variant_deleted'],
        'is_tester': json['is_tester'] == null ? undefined : json['is_tester'],
        'original_quantity': json['original_quantity'] == null ? undefined : json['original_quantity'],
        'price': json['price'] == null ? undefined : json['price'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'product_name': json['product_name'] == null ? undefined : json['product_name'],
        'product_image': json['product_image'] == null ? undefined : json['product_image'],
        'characteristic_option_map': json['characteristic_option_map'] == null ? undefined : json['characteristic_option_map'],
        'track_inventory': json['track_inventory'] == null ? undefined : json['track_inventory'],
        'in_stock': json['in_stock'] == null ? undefined : json['in_stock'],
        'inventory_quantity': json['inventory_quantity'] == null ? undefined : json['inventory_quantity'],
        'wholesale_increment': json['wholesale_increment'] == null ? undefined : json['wholesale_increment'],
        'wholesale_minimum': json['wholesale_minimum'] == null ? undefined : json['wholesale_minimum'],
    };
}

export function SuborderItemFromReorderResponseToJSON(json: any): SuborderItemFromReorderResponse {
    return SuborderItemFromReorderResponseToJSONTyped(json, false);
}

export function SuborderItemFromReorderResponseToJSONTyped(value?: SuborderItemFromReorderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'product_id': value['product_id'],
        'is_product_active': value['is_product_active'],
        'variant_id': value['variant_id'],
        'is_variant_deleted': value['is_variant_deleted'],
        'is_tester': value['is_tester'],
        'original_quantity': value['original_quantity'],
        'price': value['price'],
        'pos_price': value['pos_price'],
        'product_name': value['product_name'],
        'product_image': value['product_image'],
        'characteristic_option_map': value['characteristic_option_map'],
        'track_inventory': value['track_inventory'],
        'in_stock': value['in_stock'],
        'inventory_quantity': value['inventory_quantity'],
        'wholesale_increment': value['wholesale_increment'],
        'wholesale_minimum': value['wholesale_minimum'],
    };
}

