/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuborderRetailerResponse
 */
export interface SuborderRetailerResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderRetailerResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderRetailerResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderRetailerResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderRetailerResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderRetailerResponse
     */
    store_name?: string;
}

/**
 * Check if a given object implements the SuborderRetailerResponse interface.
 */
export function instanceOfSuborderRetailerResponse(value: object): value is SuborderRetailerResponse {
    return true;
}

export function SuborderRetailerResponseFromJSON(json: any): SuborderRetailerResponse {
    return SuborderRetailerResponseFromJSONTyped(json, false);
}

export function SuborderRetailerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderRetailerResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'store_name': json['store_name'] == null ? undefined : json['store_name'],
    };
}

export function SuborderRetailerResponseToJSON(json: any): SuborderRetailerResponse {
    return SuborderRetailerResponseToJSONTyped(json, false);
}

export function SuborderRetailerResponseToJSONTyped(value?: SuborderRetailerResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'store_name': value['store_name'],
    };
}

