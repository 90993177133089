import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'

export default {
  install(Vue) {
    const keyPrefix = '_'

    Vue.prototype.keyPrefix = keyPrefix

    let send = function (destination, body = '', invokeId, func, timeout = 3000, headers = {}) {
      // make sure that the second argument is a function
      if (typeof func !== 'function') {
        throw {
          name: 'Vue Stomp Error',
          message: 'The fourth argument must be a function.'
        }
      }
      // make sure that the second argument is a function
      if (invokeId == null) {
        throw {
          name: 'Vue Stomp Error',
          message: 'The third argument must not be null.'
        }
      }

      if (this.$stompClient == null || !this.$stompClient.connected) {
        throw {
          name: 'Vue Stomp Error',
          message: 'The connection is not established.'
        }
      }
      if (this.monitorEvents) {
        let key = this.keyPrefix + invokeId
        let monitorParm = {
          cmd: body,
          sendTime: Date.now(),
          timeout: timeout,
          func: func
        }
        this.monitorEvents[key] = monitorParm
      }
      this.$stompClient.send(destination, body, headers)
    }

    Vue.prototype.sendWS = send

    let removeStompMonitor = function (invokeId) {
      // make sure that the second argument is a function
      if (invokeId == null) {
        throw {
          name: 'Vue Stomp Error',
          message: 'The first argument must not be null.'
        }
      }
      let key = this.keyPrefix + invokeId
      if (this.monitorEvents[key] != null) {
        delete this.monitorEvents[key]
      }
    }
    Vue.prototype.removeStompMonitor = removeStompMonitor

    let reconnectErrorCallback = function (errorEvent) {
      if (errorEvent.type == 'close' && this.stompReconnect == true) {
        console.log('reconnErrorCallback reconnect required!')
        this.reconnecting = true
        this.connectWS(
          this.connectParams.serverEndPoint,
          this.connectParams.headers,
          this.connectParams.connectCallback,
          this.connectParams.errorCallback
        )
      }
      if (this.errorCallback) this.errorCallback(errorEvent)
    }
    Vue.prototype.reconnectErrorCallback = reconnectErrorCallback

    let connectWS = function (serverEndPoint, ...args) {
      if (this.$stompClient && this.$stompClient.connected) {
        return
      }
      let socket = new SockJS(serverEndPoint)

      let stompClient = Stomp.over(socket)
      //disable debug logs (set it to empty function
      stompClient.debug = () => {}
      Vue.prototype.$stompClient = stompClient

      if (this.stompReconnect == true && this.reconnecting != true) {
        switch (args.length) {
          case 3:
            if (args[1] instanceof Function) {
              let errorCallback = args[2]
              args[2] = this.reconnectErrorCallback.bind(this)
              this.errorCallback = errorCallback
            }
            break
          case 4:
          default:
            // eslint-disable-next-line no-case-declarations
            let errorCallback = args[3]
            args[3] = this.reconnectErrorCallback
            this.errorCallback = errorCallback
        }
        let [headers, connectCallback, errorCallback] = this.$stompClient._parseConnect(...args)
        let connectParams = {
          serverEndPoint: serverEndPoint,
          headers: headers,
          connectCallback: connectCallback,
          errorCallback: errorCallback
        }
        this.connectParams = connectParams
      }
      this.reconnecting = false

      this.$stompClient.connect(...args)
      this.monitorEvents = []
      if (this.responseMonitor == null) {
        this.responseMonitor = setInterval(() => {
          let now = Date.now()
          for (let mEventIndex in this.monitorEvents) {
            let monitorParm = this.monitorEvents[mEventIndex]
            if (monitorParm) {
              let delta = now - monitorParm.sendTime
              if (delta > monitorParm.timeout) {
                if (typeof this.timeoutCallback == 'function') {
                  this.timeoutCallback(monitorParm.cmd)
                }
                delete this.monitorEvents[mEventIndex]
              }
            }
          }
        }, this.monitorIntervalTime)
      }
    }
    Vue.prototype.connectWS = connectWS
    let addListeners = function () {
      if (this.$options['stompClient']) {
        let conf = this.$options.stompClient
        if (conf.timeout) {
          if (typeof conf.timeout !== 'function') {
            throw {
              name: 'Vue Stomp Error',
              message: 'The argument[timeout] must be a function.'
            }
          }
          this.timeoutCallback = conf.timeout
        }
        let monitorIntervalTime = 100
        if (
          conf.monitorIntervalTime &&
          typeof conf.monitorIntervalTime === 'number' &&
          !isNaN(conf.monitorIntervalTime)
        ) {
          monitorIntervalTime = conf.monitorIntervalTime
        }
        this.monitorIntervalTime = monitorIntervalTime
        if (conf.stompReconnect) {
          this.stompReconnect = conf.stompReconnect
        }
      }
    }
    let disconnectWS = function () {
      if (this.$stompClient && this.$stompClient.connected) {
        this.$stompClient.disconnect()
      }

      clearInterval(this.responseMonitor)
      this.responseMonitor = null
    }
    Vue.prototype.disconnectWS = disconnectWS

    let removeListeners = function () {
      if (this.$options['stompClient']) {
        this.disconnectWS()

        delete this.monitorEvents

        delete this.timeoutCallback
      }
    }

    Vue.mixin({
      // Vue v1.x
      beforeCompile: addListeners,

      // Vue v2.x
      beforeCreate: addListeners,

      beforeDestroy: removeListeners
    })
  }
}
