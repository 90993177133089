/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInventoryTrackingResponse
 */
export interface UpdateInventoryTrackingResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateInventoryTrackingResponse
     */
    invalidSkus?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateInventoryTrackingResponse
     */
    validSkusNumber?: number;
}

/**
 * Check if a given object implements the UpdateInventoryTrackingResponse interface.
 */
export function instanceOfUpdateInventoryTrackingResponse(value: object): value is UpdateInventoryTrackingResponse {
    return true;
}

export function UpdateInventoryTrackingResponseFromJSON(json: any): UpdateInventoryTrackingResponse {
    return UpdateInventoryTrackingResponseFromJSONTyped(json, false);
}

export function UpdateInventoryTrackingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInventoryTrackingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'invalidSkus': json['invalidSkus'] == null ? undefined : json['invalidSkus'],
        'validSkusNumber': json['validSkusNumber'] == null ? undefined : json['validSkusNumber'],
    };
}

export function UpdateInventoryTrackingResponseToJSON(json: any): UpdateInventoryTrackingResponse {
    return UpdateInventoryTrackingResponseToJSONTyped(json, false);
}

export function UpdateInventoryTrackingResponseToJSONTyped(value?: UpdateInventoryTrackingResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'invalidSkus': value['invalidSkus'],
        'validSkusNumber': value['validSkusNumber'],
    };
}

