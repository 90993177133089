/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandLocationResponse
 */
export interface BrandLocationResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandLocationResponse
     */
    location_name?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandLocationResponse
     */
    payout?: number;
}

/**
 * Check if a given object implements the BrandLocationResponse interface.
 */
export function instanceOfBrandLocationResponse(value: object): value is BrandLocationResponse {
    return true;
}

export function BrandLocationResponseFromJSON(json: any): BrandLocationResponse {
    return BrandLocationResponseFromJSONTyped(json, false);
}

export function BrandLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandLocationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'location_name': json['location_name'] == null ? undefined : json['location_name'],
        'payout': json['payout'] == null ? undefined : json['payout'],
    };
}

export function BrandLocationResponseToJSON(json: any): BrandLocationResponse {
    return BrandLocationResponseToJSONTyped(json, false);
}

export function BrandLocationResponseToJSONTyped(value?: BrandLocationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'location_name': value['location_name'],
        'payout': value['payout'],
    };
}

