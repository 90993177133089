export default {
  props: {
    removable: {
      type: Boolean,
      default: true
    },
    spacing: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    doNotInheritFontFamily: {
      type: Boolean,
      default: false
    }
  }
}
