/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RetailerSuborderResponse } from './RetailerSuborderResponse';
import {
    RetailerSuborderResponseFromJSON,
    RetailerSuborderResponseFromJSONTyped,
    RetailerSuborderResponseToJSON,
    RetailerSuborderResponseToJSONTyped,
} from './RetailerSuborderResponse';

/**
 * 
 * @export
 * @interface RetailerBalanceResponse
 */
export interface RetailerBalanceResponse {
    /**
     * 
     * @type {string}
     * @memberof RetailerBalanceResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerBalanceResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerBalanceResponse
     */
    store_name?: string;
    /**
     * 
     * @type {number}
     * @memberof RetailerBalanceResponse
     */
    balance?: number;
    /**
     * 
     * @type {Array<RetailerSuborderResponse>}
     * @memberof RetailerBalanceResponse
     */
    suborders?: Array<RetailerSuborderResponse>;
}

/**
 * Check if a given object implements the RetailerBalanceResponse interface.
 */
export function instanceOfRetailerBalanceResponse(value: object): value is RetailerBalanceResponse {
    return true;
}

export function RetailerBalanceResponseFromJSON(json: any): RetailerBalanceResponse {
    return RetailerBalanceResponseFromJSONTyped(json, false);
}

export function RetailerBalanceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerBalanceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'store_name': json['store_name'] == null ? undefined : json['store_name'],
        'balance': json['balance'] == null ? undefined : json['balance'],
        'suborders': json['suborders'] == null ? undefined : ((json['suborders'] as Array<any>).map(RetailerSuborderResponseFromJSON)),
    };
}

export function RetailerBalanceResponseToJSON(json: any): RetailerBalanceResponse {
    return RetailerBalanceResponseToJSONTyped(json, false);
}

export function RetailerBalanceResponseToJSONTyped(value?: RetailerBalanceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'store_name': value['store_name'],
        'balance': value['balance'],
        'suborders': value['suborders'] == null ? undefined : ((value['suborders'] as Array<any>).map(RetailerSuborderResponseToJSON)),
    };
}

