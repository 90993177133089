/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DropdownItem } from './DropdownItem';
import {
    DropdownItemFromJSON,
    DropdownItemFromJSONTyped,
    DropdownItemToJSON,
    DropdownItemToJSONTyped,
} from './DropdownItem';

/**
 * 
 * @export
 * @interface GetBrandProductResponse
 */
export interface GetBrandProductResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandProductResponse
     */
    published?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBrandProductResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandProductResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandProductResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandProductResponse
     */
    minimum_order_value?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandProductResponse
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandProductResponse
     */
    ships_from_usa_or_canada?: boolean;
    /**
     * 
     * @type {Array<DropdownItem>}
     * @memberof GetBrandProductResponse
     */
    values?: Array<DropdownItem>;
    /**
     * 
     * @type {number}
     * @memberof GetBrandProductResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandProductResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandProductResponse
     */
    buy_on_bulletin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBrandProductResponse
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandProductResponse
     */
    based_in?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandProductResponse
     */
    is_published?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBrandProductResponse
     */
    event_ids?: Array<string>;
}

/**
 * Check if a given object implements the GetBrandProductResponse interface.
 */
export function instanceOfGetBrandProductResponse(value: object): value is GetBrandProductResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function GetBrandProductResponseFromJSON(json: any): GetBrandProductResponse {
    return GetBrandProductResponseFromJSONTyped(json, false);
}

export function GetBrandProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandProductResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'published': json['published'] == null ? undefined : json['published'],
        'id': json['id'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'name': json['name'] == null ? undefined : json['name'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'ships_from_usa_or_canada': json['ships_from_usa_or_canada'] == null ? undefined : json['ships_from_usa_or_canada'],
        'values': json['values'] == null ? undefined : ((json['values'] as Array<any>).map(DropdownItemFromJSON)),
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
        'source': json['source'] == null ? undefined : json['source'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'is_published': json['is_published'] == null ? undefined : json['is_published'],
        'event_ids': json['event_ids'] == null ? undefined : json['event_ids'],
    };
}

export function GetBrandProductResponseToJSON(json: any): GetBrandProductResponse {
    return GetBrandProductResponseToJSONTyped(json, false);
}

export function GetBrandProductResponseToJSONTyped(value?: GetBrandProductResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'published': value['published'],
        'id': value['id'],
        'vanity_url_name': value['vanity_url_name'],
        'name': value['name'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'ships_from_usa_or_canada': value['ships_from_usa_or_canada'],
        'values': value['values'] == null ? undefined : ((value['values'] as Array<any>).map(DropdownItemToJSON)),
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'buy_on_bulletin': value['buy_on_bulletin'],
        'source': value['source'],
        'based_in': value['based_in'],
        'is_published': value['is_published'],
        'event_ids': value['event_ids'],
    };
}

