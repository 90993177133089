/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeShowFeatureRequest
 */
export interface TradeShowFeatureRequest {
    /**
     * 
     * @type {string}
     * @memberof TradeShowFeatureRequest
     */
    name: TradeShowFeatureRequestNameEnum;
    /**
     * 
     * @type {Date}
     * @memberof TradeShowFeatureRequest
     */
    start_date_time: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeShowFeatureRequest
     */
    end_date_time: Date;
}


/**
 * @export
 */
export const TradeShowFeatureRequestNameEnum = {
    ReducedCommission: 'REDUCED_COMMISSION',
    ZeroCommission: 'ZERO_COMMISSION',
    ExhibitorDirectory: 'EXHIBITOR_DIRECTORY',
    BuyerDirectory: 'BUYER_DIRECTORY',
    ExhibitorImport: 'EXHIBITOR_IMPORT',
    ExhibitorWelcomeEmails: 'EXHIBITOR_WELCOME_EMAILS',
    BuyerWelcomeEmails: 'BUYER_WELCOME_EMAILS',
    DailyReports: 'DAILY_REPORTS',
    Navigation: 'NAVIGATION',
    BrandProfileBadges: 'BRAND_PROFILE_BADGES',
    RestrictExhibitorVisibility: 'RESTRICT_EXHIBITOR_VISIBILITY'
} as const;
export type TradeShowFeatureRequestNameEnum = typeof TradeShowFeatureRequestNameEnum[keyof typeof TradeShowFeatureRequestNameEnum];


/**
 * Check if a given object implements the TradeShowFeatureRequest interface.
 */
export function instanceOfTradeShowFeatureRequest(value: object): value is TradeShowFeatureRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('start_date_time' in value) || value['start_date_time'] === undefined) return false;
    if (!('end_date_time' in value) || value['end_date_time'] === undefined) return false;
    return true;
}

export function TradeShowFeatureRequestFromJSON(json: any): TradeShowFeatureRequest {
    return TradeShowFeatureRequestFromJSONTyped(json, false);
}

export function TradeShowFeatureRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowFeatureRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'start_date_time': (new Date(json['start_date_time'])),
        'end_date_time': (new Date(json['end_date_time'])),
    };
}

export function TradeShowFeatureRequestToJSON(json: any): TradeShowFeatureRequest {
    return TradeShowFeatureRequestToJSONTyped(json, false);
}

export function TradeShowFeatureRequestToJSONTyped(value?: TradeShowFeatureRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'start_date_time': ((value['start_date_time']).toISOString()),
        'end_date_time': ((value['end_date_time']).toISOString()),
    };
}

