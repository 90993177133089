<template>
  <dropdown
    :dropdown-items="retailers"
    :searchable="true"
    :selected-name="selectedName"
    :placeholder="placeholder"
    :on-select="handleSelectChange"
  />
</template>
<script>
import { mapGetters } from 'vuex'
import MiscApi from '@/services/api/misc'

const MiscApiService = new MiscApi()
export default {
  props: {
    showAll: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    selectedName: {
      type: String
    },
    onSelect: {
      type: Function
    },
    onRetailersFetched: {
      type: Function
    }
  },
  data() {
    return {
      retailers: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  beforeMount() {
    this.getRetailers()
  },
  methods: {
    getRetailers,
    handleSelectChange
  }
}
async function getRetailers() {
  const showAll = this.showAll ? [{ name: 'ALL' }] : []
  try {
    if (this.user.role === 'ADMIN') {
      const { data } = await this.$utils.getAllRetailers()
      this.retailers = [...showAll, ...data.retailers_dropdown]
    } else if (this.user.role === 'BRAND') {
      const { data } = await MiscApiService.getBrandRetailers(this.user.brand_id)
      this.retailers = [...showAll, ...data.retailers_dropdown]
    }

    if (this.onRetailersFetched) {
      this.onRetailersFetched(this.retailers)
    }
  } catch (error) {
    this.$handleError(this, this.$utils.formatErrorMessage('retailers'))
  }
}

function handleSelectChange(item) {
  this.onSelect(item)
}
</script>
