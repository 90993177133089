/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductSearchCriteriaRequest
 */
export interface ProductSearchCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductSearchCriteriaRequest
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchCriteriaRequest
     */
    operator?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchCriteriaRequest
     */
    value?: string;
}

/**
 * Check if a given object implements the ProductSearchCriteriaRequest interface.
 */
export function instanceOfProductSearchCriteriaRequest(value: object): value is ProductSearchCriteriaRequest {
    return true;
}

export function ProductSearchCriteriaRequestFromJSON(json: any): ProductSearchCriteriaRequest {
    return ProductSearchCriteriaRequestFromJSONTyped(json, false);
}

export function ProductSearchCriteriaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSearchCriteriaRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'operator': json['operator'] == null ? undefined : json['operator'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ProductSearchCriteriaRequestToJSON(json: any): ProductSearchCriteriaRequest {
    return ProductSearchCriteriaRequestToJSONTyped(json, false);
}

export function ProductSearchCriteriaRequestToJSONTyped(value?: ProductSearchCriteriaRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'operator': value['operator'],
        'value': value['value'],
    };
}

