/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandsDirectoryPagination } from './BrandsDirectoryPagination';
import {
    BrandsDirectoryPaginationFromJSON,
    BrandsDirectoryPaginationFromJSONTyped,
    BrandsDirectoryPaginationToJSON,
    BrandsDirectoryPaginationToJSONTyped,
} from './BrandsDirectoryPagination';
import type { BrandsDirectoryResponse } from './BrandsDirectoryResponse';
import {
    BrandsDirectoryResponseFromJSON,
    BrandsDirectoryResponseFromJSONTyped,
    BrandsDirectoryResponseToJSON,
    BrandsDirectoryResponseToJSONTyped,
} from './BrandsDirectoryResponse';

/**
 * 
 * @export
 * @interface PaginatedBrandsDirectoryResponse
 */
export interface PaginatedBrandsDirectoryResponse {
    /**
     * 
     * @type {Array<BrandsDirectoryResponse>}
     * @memberof PaginatedBrandsDirectoryResponse
     */
    hits?: Array<BrandsDirectoryResponse>;
    /**
     * 
     * @type {BrandsDirectoryPagination}
     * @memberof PaginatedBrandsDirectoryResponse
     */
    pagination?: BrandsDirectoryPagination;
}

/**
 * Check if a given object implements the PaginatedBrandsDirectoryResponse interface.
 */
export function instanceOfPaginatedBrandsDirectoryResponse(value: object): value is PaginatedBrandsDirectoryResponse {
    return true;
}

export function PaginatedBrandsDirectoryResponseFromJSON(json: any): PaginatedBrandsDirectoryResponse {
    return PaginatedBrandsDirectoryResponseFromJSONTyped(json, false);
}

export function PaginatedBrandsDirectoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedBrandsDirectoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(BrandsDirectoryResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : BrandsDirectoryPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedBrandsDirectoryResponseToJSON(json: any): PaginatedBrandsDirectoryResponse {
    return PaginatedBrandsDirectoryResponseToJSONTyped(json, false);
}

export function PaginatedBrandsDirectoryResponseToJSONTyped(value?: PaginatedBrandsDirectoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(BrandsDirectoryResponseToJSON)),
        'pagination': BrandsDirectoryPaginationToJSON(value['pagination']),
    };
}

