/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuyerDirectoryHeaderResponse
 */
export interface BuyerDirectoryHeaderResponse {
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderResponse
     */
    trade_show_id?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderResponse
     */
    event_id?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderResponse
     */
    desktop_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderResponse
     */
    mobile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderResponse
     */
    directory_title?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderResponse
     */
    directory_description?: string;
}

/**
 * Check if a given object implements the BuyerDirectoryHeaderResponse interface.
 */
export function instanceOfBuyerDirectoryHeaderResponse(value: object): value is BuyerDirectoryHeaderResponse {
    return true;
}

export function BuyerDirectoryHeaderResponseFromJSON(json: any): BuyerDirectoryHeaderResponse {
    return BuyerDirectoryHeaderResponseFromJSONTyped(json, false);
}

export function BuyerDirectoryHeaderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerDirectoryHeaderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'trade_show_id': json['trade_show_id'] == null ? undefined : json['trade_show_id'],
        'event_id': json['event_id'] == null ? undefined : json['event_id'],
        'desktop_image_url': json['desktop_image_url'] == null ? undefined : json['desktop_image_url'],
        'mobile_image_url': json['mobile_image_url'] == null ? undefined : json['mobile_image_url'],
        'directory_title': json['directory_title'] == null ? undefined : json['directory_title'],
        'directory_description': json['directory_description'] == null ? undefined : json['directory_description'],
    };
}

export function BuyerDirectoryHeaderResponseToJSON(json: any): BuyerDirectoryHeaderResponse {
    return BuyerDirectoryHeaderResponseToJSONTyped(json, false);
}

export function BuyerDirectoryHeaderResponseToJSONTyped(value?: BuyerDirectoryHeaderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'trade_show_id': value['trade_show_id'],
        'event_id': value['event_id'],
        'desktop_image_url': value['desktop_image_url'],
        'mobile_image_url': value['mobile_image_url'],
        'directory_title': value['directory_title'],
        'directory_description': value['directory_description'],
    };
}

