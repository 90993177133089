/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateBrandApplicantRequest } from './CreateBrandApplicantRequest';
import {
    CreateBrandApplicantRequestFromJSON,
    CreateBrandApplicantRequestFromJSONTyped,
    CreateBrandApplicantRequestToJSON,
    CreateBrandApplicantRequestToJSONTyped,
} from './CreateBrandApplicantRequest';

/**
 * Brand info necessary for sign up
 * @export
 * @interface BrandSignUpRequest
 */
export interface BrandSignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof BrandSignUpRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof BrandSignUpRequest
     */
    company_description?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandSignUpRequest
     */
    products_arrival_duration?: string;
    /**
     * 
     * @type {CreateBrandApplicantRequest}
     * @memberof BrandSignUpRequest
     */
    info: CreateBrandApplicantRequest;
}

/**
 * Check if a given object implements the BrandSignUpRequest interface.
 */
export function instanceOfBrandSignUpRequest(value: object): value is BrandSignUpRequest {
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('info' in value) || value['info'] === undefined) return false;
    return true;
}

export function BrandSignUpRequestFromJSON(json: any): BrandSignUpRequest {
    return BrandSignUpRequestFromJSONTyped(json, false);
}

export function BrandSignUpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandSignUpRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'password': json['password'],
        'company_description': json['company_description'] == null ? undefined : json['company_description'],
        'products_arrival_duration': json['products_arrival_duration'] == null ? undefined : json['products_arrival_duration'],
        'info': CreateBrandApplicantRequestFromJSON(json['info']),
    };
}

export function BrandSignUpRequestToJSON(json: any): BrandSignUpRequest {
    return BrandSignUpRequestToJSONTyped(json, false);
}

export function BrandSignUpRequestToJSONTyped(value?: BrandSignUpRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'password': value['password'],
        'company_description': value['company_description'],
        'products_arrival_duration': value['products_arrival_duration'],
        'info': CreateBrandApplicantRequestToJSON(value['info']),
    };
}

