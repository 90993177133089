/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollectionInfo
 */
export interface CollectionInfo {
    /**
     * 
     * @type {string}
     * @memberof CollectionInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionInfo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionInfo
     */
    image_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionInfo
     */
    product_ids?: Array<string>;
}

/**
 * Check if a given object implements the CollectionInfo interface.
 */
export function instanceOfCollectionInfo(value: object): value is CollectionInfo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function CollectionInfoFromJSON(json: any): CollectionInfo {
    return CollectionInfoFromJSONTyped(json, false);
}

export function CollectionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'image_url': json['image_url'] == null ? undefined : json['image_url'],
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
    };
}

export function CollectionInfoToJSON(json: any): CollectionInfo {
    return CollectionInfoToJSONTyped(json, false);
}

export function CollectionInfoToJSONTyped(value?: CollectionInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
        'image_url': value['image_url'],
        'product_ids': value['product_ids'],
    };
}

