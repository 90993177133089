/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddNewReferralsRequest
 */
export interface AddNewReferralsRequest {
    /**
     * 
     * @type {string}
     * @memberof AddNewReferralsRequest
     */
    referred_retailer_email: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewReferralsRequest
     */
    brand_email: string;
}

/**
 * Check if a given object implements the AddNewReferralsRequest interface.
 */
export function instanceOfAddNewReferralsRequest(value: object): value is AddNewReferralsRequest {
    if (!('referred_retailer_email' in value) || value['referred_retailer_email'] === undefined) return false;
    if (!('brand_email' in value) || value['brand_email'] === undefined) return false;
    return true;
}

export function AddNewReferralsRequestFromJSON(json: any): AddNewReferralsRequest {
    return AddNewReferralsRequestFromJSONTyped(json, false);
}

export function AddNewReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNewReferralsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'referred_retailer_email': json['referred_retailer_email'],
        'brand_email': json['brand_email'],
    };
}

export function AddNewReferralsRequestToJSON(json: any): AddNewReferralsRequest {
    return AddNewReferralsRequestToJSONTyped(json, false);
}

export function AddNewReferralsRequestToJSONTyped(value?: AddNewReferralsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'referred_retailer_email': value['referred_retailer_email'],
        'brand_email': value['brand_email'],
    };
}

