<template>
  <Icon :icon-key="iconKey" hover-type="OPACITY" :size="24" :on-click="() => handleSort(accessor)" />
</template>

<script>
export default {
  name: 'TableSortIndicator',
  props: ['accessor', 'order', 'selectedAccessor', 'handleSort'],
  computed: {
    iconKey() {
      if (this.isSelected('desc')) return 'sort-down'
      if (this.isSelected('asc')) return 'sort-up'
      return 'sort'
    }
  },
  methods: {
    isSelected(order) {
      return this.accessor === this.selectedAccessor && this.order === order
    }
  }
}
</script>
