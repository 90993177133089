/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeShowRecommendedBrandsResponse
 */
export interface TradeShowRecommendedBrandsResponse {
    /**
     * 
     * @type {string}
     * @memberof TradeShowRecommendedBrandsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowRecommendedBrandsResponse
     */
    company_name?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowRecommendedBrandsResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowRecommendedBrandsResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeShowRecommendedBrandsResponse
     */
    minimum_order_value?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeShowRecommendedBrandsResponse
     */
    booth?: string;
}

/**
 * Check if a given object implements the TradeShowRecommendedBrandsResponse interface.
 */
export function instanceOfTradeShowRecommendedBrandsResponse(value: object): value is TradeShowRecommendedBrandsResponse {
    return true;
}

export function TradeShowRecommendedBrandsResponseFromJSON(json: any): TradeShowRecommendedBrandsResponse {
    return TradeShowRecommendedBrandsResponseFromJSONTyped(json, false);
}

export function TradeShowRecommendedBrandsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowRecommendedBrandsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'company_name': json['company_name'] == null ? undefined : json['company_name'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'minimum_order_value': json['minimum_order_value'] == null ? undefined : json['minimum_order_value'],
        'booth': json['booth'] == null ? undefined : json['booth'],
    };
}

export function TradeShowRecommendedBrandsResponseToJSON(json: any): TradeShowRecommendedBrandsResponse {
    return TradeShowRecommendedBrandsResponseToJSONTyped(json, false);
}

export function TradeShowRecommendedBrandsResponseToJSONTyped(value?: TradeShowRecommendedBrandsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'company_name': value['company_name'],
        'vanity_url_name': value['vanity_url_name'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'minimum_order_value': value['minimum_order_value'],
        'booth': value['booth'],
    };
}

