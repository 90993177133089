<template>
  <div class="toast-custom" :data-test-id="`toast_${type}`" :class="toastStyle()">
    <div class="grid-12">
      <div class="span-1">
        <Icon :icon-key="`alert-${type}`" />
      </div>
      <div class="span-11">
        <Icon
          icon-key="close"
          hover-type="OPACITY"
          class="fright"
          :data-test-id="`toast_${type}_close`"
          :on-click="closeToast"
        />
        <h4>{{ title }}</h4>
        <p v-html="message" />
        <p v-if="action" class="action-link underline mt-10" :data-test-id="`toast_${type}_action`" @click="onAction">
          {{ actionName }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    actionName: {
      type: String,
      default: 'Link'
    },
    action: {
      type: Function,
      default: undefined
    }
  },
  methods: {
    toastStyle() {
      return {
        error: this.type === 'error',
        success: this.type === 'success',
        info: this.type === 'info',
        warning: this.type === 'warning'
      }
    },
    closeToast() {
      this.$toasted.clear()
    },
    onAction() {
      this.action && this.action()
      this.closeToast()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/base/vars.scss';

.toasted-container {
  z-index: 24;
}
.toasted-container .toasted {
  background: $color-white !important;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 5px !important;
}

.toasted-container.top-center {
  top: 15px;
}

.toasted-primary {
  padding: 0 !important;
  box-shadow: none !important;
}

.toast-custom {
  width: 668px;
  border: 1px solid $color-grey-3;
  border-radius: 5px;
  padding: 20px 10px;
  &.error {
    border-left: 4px solid $color-error;
  }
  &.info {
    border-left: 4px solid $color-info;
  }
  &.success {
    border-left: 4px solid $color-success;
  }
  &.warning {
    border-left: 4px solid $color-salmon;
  }
}
</style>
