/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeShowFeaturesResponse
 */
export interface TradeShowFeaturesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowFeaturesResponse
     */
    exhibitor_directory_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowFeaturesResponse
     */
    buyer_directory_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowFeaturesResponse
     */
    navigation_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowFeaturesResponse
     */
    brand_profile_badges_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowFeaturesResponse
     */
    restrict_exhibitor_visibility?: boolean;
}

/**
 * Check if a given object implements the TradeShowFeaturesResponse interface.
 */
export function instanceOfTradeShowFeaturesResponse(value: object): value is TradeShowFeaturesResponse {
    return true;
}

export function TradeShowFeaturesResponseFromJSON(json: any): TradeShowFeaturesResponse {
    return TradeShowFeaturesResponseFromJSONTyped(json, false);
}

export function TradeShowFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowFeaturesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'exhibitor_directory_enabled': json['exhibitor_directory_enabled'] == null ? undefined : json['exhibitor_directory_enabled'],
        'buyer_directory_enabled': json['buyer_directory_enabled'] == null ? undefined : json['buyer_directory_enabled'],
        'navigation_enabled': json['navigation_enabled'] == null ? undefined : json['navigation_enabled'],
        'brand_profile_badges_enabled': json['brand_profile_badges_enabled'] == null ? undefined : json['brand_profile_badges_enabled'],
        'restrict_exhibitor_visibility': json['restrict_exhibitor_visibility'] == null ? undefined : json['restrict_exhibitor_visibility'],
    };
}

export function TradeShowFeaturesResponseToJSON(json: any): TradeShowFeaturesResponse {
    return TradeShowFeaturesResponseToJSONTyped(json, false);
}

export function TradeShowFeaturesResponseToJSONTyped(value?: TradeShowFeaturesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exhibitor_directory_enabled': value['exhibitor_directory_enabled'],
        'buyer_directory_enabled': value['buyer_directory_enabled'],
        'navigation_enabled': value['navigation_enabled'],
        'brand_profile_badges_enabled': value['brand_profile_badges_enabled'],
        'restrict_exhibitor_visibility': value['restrict_exhibitor_visibility'],
    };
}

