<template>
  <div class="navbar-expandable-menu">
    <div v-show="dropdown !== 'closed'" class="width-100 absolute z-8 bg-gray-100" @mouseleave="closeDropDown($event)">
      <div v-if="dropdown !== 'category'" class="container">
        <login-menu v-if="dropdown === 'sign-in'" @close-dropdown="closeDropDown" />
        <apply-menu v-if="dropdown === 'sign-up'" @close-dropdown="closeDropDown" />

        <refer v-if="dropdown === 'refer'" @close-dropdown="closeDropDown" />
        <NavNYNOW v-if="dropdown === 'nynow'" class="mb-50" @close-dropdown="closeDropDown" />
      </div>

      <div v-if="dropdown === 'category'">
        <sub-category-menu
          :categories="categories"
          :show-options-n-y-n-o-w="showOptionsNYNOW()"
          @close-dropdown="closeDropDown"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LoginMenu from '@/components/navigation/dropdownMenus/LoginMenu/LoginMenu.vue'
import ApplyMenu from '@/components/navigation/dropdownMenus/Apply'
import Refer from '@/components/navigation/refer/refer.vue'
import SubCategoryMenu from '@/components/navigation/SubCategoryMenu.vue'
import { mapGetters } from 'vuex'
import NavNYNOW from '../../views/TheNYNOWDirectoryPage/navNYNOW.vue'

export default {
  name: 'Dropdown',
  components: { LoginMenu, ApplyMenu, Refer, SubCategoryMenu, NavNYNOW },
  props: ['categories'],
  data: () => {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState',
      dropdown: 'navigation/dropdownState'
    })
  },

  methods: {
    closeDropDown(event) {
      if (event?.type === 'mouseleave' && this.dropdown === 'sign-in') {
        return
      }
      //prevent close if user hovers into navbar
      if (event?.relatedTarget && (event.relatedTarget.id === 'navbar' || event.relatedTarget.id === 'menu')) {
        return
      }
      this.$store.dispatch('navigation/setDropdownType', 'closed')
    },
    showOptionsNYNOW() {
      return this.user && this.user.role === 'ADMIN'
    }
  }
}
</script>

<style lang="scss" scoped></style>
