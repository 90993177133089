/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Admin email
 * @export
 * @interface EmailWrapperDto
 */
export interface EmailWrapperDto {
    /**
     * 
     * @type {string}
     * @memberof EmailWrapperDto
     */
    email: string;
}

/**
 * Check if a given object implements the EmailWrapperDto interface.
 */
export function instanceOfEmailWrapperDto(value: object): value is EmailWrapperDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function EmailWrapperDtoFromJSON(json: any): EmailWrapperDto {
    return EmailWrapperDtoFromJSONTyped(json, false);
}

export function EmailWrapperDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailWrapperDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function EmailWrapperDtoToJSON(json: any): EmailWrapperDto {
    return EmailWrapperDtoToJSONTyped(json, false);
}

export function EmailWrapperDtoToJSONTyped(value?: EmailWrapperDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
    };
}

