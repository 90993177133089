/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandLocationRequest } from './BrandLocationRequest';
import {
    BrandLocationRequestFromJSON,
    BrandLocationRequestFromJSONTyped,
    BrandLocationRequestToJSON,
    BrandLocationRequestToJSONTyped,
} from './BrandLocationRequest';

/**
 * Update brand object
 * @export
 * @interface UpdateBrandRequest
 */
export interface UpdateBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    brand_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    brand_description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    vanity_url_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    instagram_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    instagram_followers?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    based_in?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    minimum_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    minimum_price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    product_arrival?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandRequest
     */
    is_published: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    brand_bio_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    bulletin_reference?: string;
    /**
     * 
     * @type {Array<BrandLocationRequest>}
     * @memberof UpdateBrandRequest
     */
    brand_locations?: Array<BrandLocationRequest>;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    increment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandRequest
     */
    instagram_growth_help?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    minimum_order_value: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBrandRequest
     */
    values?: Array<UpdateBrandRequestValuesEnum>;
    /**
     * 
     * @type {object}
     * @memberof UpdateBrandRequest
     */
    address?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateBrandRequest
     */
    fulfillment_address?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    phone_number?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBrandRequest
     */
    special_services?: Array<UpdateBrandRequestSpecialServicesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBrandRequest
     */
    deals_and_promotions?: Array<UpdateBrandRequestDealsAndPromotionsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandRequest
     */
    bulletin_pick: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandRequest
     */
    buy_on_bulletin?: boolean;
}


/**
 * @export
 */
export const UpdateBrandRequestValuesEnum = {
    FemaleFounded: 'FEMALE_FOUNDED',
    EthicalProduction: 'ETHICAL_PRODUCTION',
    CleanIngredients: 'CLEAN_INGREDIENTS',
    Sustainable: 'SUSTAINABLE',
    Handmade: 'HANDMADE',
    MadeInTheUs: 'MADE_IN_THE_US',
    CharitableBrandGiveBack: 'CHARITABLE_BRAND_GIVE_BACK',
    Organic: 'ORGANIC',
    ZeroWaste: 'ZERO_WASTE',
    BlackOwned: 'BLACK_OWNED',
    LatinxOwned: 'LATINX_OWNED',
    AapiOwned: 'AAPI_OWNED',
    IndigenousOwned: 'INDIGENOUS_OWNED',
    LgbtqOwned: 'LGBTQ_OWNED',
    MinorityOwnedBusiness: 'MINORITY_OWNED_BUSINESS',
    DisabilityOwned: 'DISABILITY_OWNED'
} as const;
export type UpdateBrandRequestValuesEnum = typeof UpdateBrandRequestValuesEnum[keyof typeof UpdateBrandRequestValuesEnum];

/**
 * @export
 */
export const UpdateBrandRequestSpecialServicesEnum = {
    Collaboration: 'COLLABORATION',
    Customization: 'CUSTOMIZATION',
    DropShipping: 'DROP_SHIPPING',
    Personalization: 'PERSONALIZATION',
    PrivateLabel: 'PRIVATE_LABEL',
    WhiteLabel: 'WHITE_LABEL'
} as const;
export type UpdateBrandRequestSpecialServicesEnum = typeof UpdateBrandRequestSpecialServicesEnum[keyof typeof UpdateBrandRequestSpecialServicesEnum];

/**
 * @export
 */
export const UpdateBrandRequestDealsAndPromotionsEnum = {
    Discounts: 'DISCOUNTS',
    FreeGifts: 'FREE_GIFTS',
    FreeProductSamples: 'FREE_PRODUCT_SAMPLES',
    FreeShipping: 'FREE_SHIPPING',
    LowMinimumOrder: 'LOW_MINIMUM_ORDER',
    NewBuyerPromotion: 'NEW_BUYER_PROMOTION',
    NoMinimumOrder: 'NO_MINIMUM_ORDER',
    SpecialSale: 'SPECIAL_SALE'
} as const;
export type UpdateBrandRequestDealsAndPromotionsEnum = typeof UpdateBrandRequestDealsAndPromotionsEnum[keyof typeof UpdateBrandRequestDealsAndPromotionsEnum];


/**
 * Check if a given object implements the UpdateBrandRequest interface.
 */
export function instanceOfUpdateBrandRequest(value: object): value is UpdateBrandRequest {
    if (!('brand_name' in value) || value['brand_name'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('brand_description' in value) || value['brand_description'] === undefined) return false;
    if (!('vanity_url_name' in value) || value['vanity_url_name'] === undefined) return false;
    if (!('is_published' in value) || value['is_published'] === undefined) return false;
    if (!('minimum_order_value' in value) || value['minimum_order_value'] === undefined) return false;
    if (!('bulletin_pick' in value) || value['bulletin_pick'] === undefined) return false;
    return true;
}

export function UpdateBrandRequestFromJSON(json: any): UpdateBrandRequest {
    return UpdateBrandRequestFromJSONTyped(json, false);
}

export function UpdateBrandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBrandRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'brand_name': json['brand_name'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'brand_description': json['brand_description'],
        'vanity_url_name': json['vanity_url_name'],
        'website_url': json['website_url'] == null ? undefined : json['website_url'],
        'instagram_url': json['instagram_url'] == null ? undefined : json['instagram_url'],
        'instagram_followers': json['instagram_followers'] == null ? undefined : json['instagram_followers'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'minimum_quantity': json['minimum_quantity'] == null ? undefined : json['minimum_quantity'],
        'minimum_price': json['minimum_price'] == null ? undefined : json['minimum_price'],
        'product_arrival': json['product_arrival'] == null ? undefined : json['product_arrival'],
        'is_published': json['is_published'],
        'logo_url': json['logo_url'] == null ? undefined : json['logo_url'],
        'brand_bio_image_url': json['brand_bio_image_url'] == null ? undefined : json['brand_bio_image_url'],
        'bulletin_reference': json['bulletin_reference'] == null ? undefined : json['bulletin_reference'],
        'brand_locations': json['brand_locations'] == null ? undefined : ((json['brand_locations'] as Array<any>).map(BrandLocationRequestFromJSON)),
        'increment': json['increment'] == null ? undefined : json['increment'],
        'instagram_growth_help': json['instagram_growth_help'] == null ? undefined : json['instagram_growth_help'],
        'minimum_order_value': json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'values': json['values'] == null ? undefined : json['values'],
        'address': json['address'] == null ? undefined : json['address'],
        'fulfillment_address': json['fulfillment_address'] == null ? undefined : json['fulfillment_address'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'special_services': json['special_services'] == null ? undefined : json['special_services'],
        'deals_and_promotions': json['deals_and_promotions'] == null ? undefined : json['deals_and_promotions'],
        'bulletin_pick': json['bulletin_pick'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
    };
}

export function UpdateBrandRequestToJSON(json: any): UpdateBrandRequest {
    return UpdateBrandRequestToJSONTyped(json, false);
}

export function UpdateBrandRequestToJSONTyped(value?: UpdateBrandRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'brand_name': value['brand_name'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'brand_description': value['brand_description'],
        'vanity_url_name': value['vanity_url_name'],
        'website_url': value['website_url'],
        'instagram_url': value['instagram_url'],
        'instagram_followers': value['instagram_followers'],
        'based_in': value['based_in'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'minimum_quantity': value['minimum_quantity'],
        'minimum_price': value['minimum_price'],
        'product_arrival': value['product_arrival'],
        'is_published': value['is_published'],
        'logo_url': value['logo_url'],
        'brand_bio_image_url': value['brand_bio_image_url'],
        'bulletin_reference': value['bulletin_reference'],
        'brand_locations': value['brand_locations'] == null ? undefined : ((value['brand_locations'] as Array<any>).map(BrandLocationRequestToJSON)),
        'increment': value['increment'],
        'instagram_growth_help': value['instagram_growth_help'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'values': value['values'],
        'address': value['address'],
        'fulfillment_address': value['fulfillment_address'],
        'phone_number': value['phone_number'],
        'special_services': value['special_services'],
        'deals_and_promotions': value['deals_and_promotions'],
        'bulletin_pick': value['bulletin_pick'],
        'buy_on_bulletin': value['buy_on_bulletin'],
    };
}

