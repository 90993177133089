/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateViewComponentRequest } from './UpdateViewComponentRequest';
import {
    UpdateViewComponentRequestFromJSON,
    UpdateViewComponentRequestFromJSONTyped,
    UpdateViewComponentRequestToJSON,
    UpdateViewComponentRequestToJSONTyped,
} from './UpdateViewComponentRequest';

/**
 * Update container
 * @export
 * @interface UpdateViewContainerRequest
 */
export interface UpdateViewContainerRequest {
    /**
     * 
     * @type {Array<UpdateViewComponentRequest>}
     * @memberof UpdateViewContainerRequest
     */
    view_components?: Array<UpdateViewComponentRequest>;
    /**
     * 
     * @type {string}
     * @memberof UpdateViewContainerRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the UpdateViewContainerRequest interface.
 */
export function instanceOfUpdateViewContainerRequest(value: object): value is UpdateViewContainerRequest {
    return true;
}

export function UpdateViewContainerRequestFromJSON(json: any): UpdateViewContainerRequest {
    return UpdateViewContainerRequestFromJSONTyped(json, false);
}

export function UpdateViewContainerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateViewContainerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'view_components': json['view_components'] == null ? undefined : ((json['view_components'] as Array<any>).map(UpdateViewComponentRequestFromJSON)),
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function UpdateViewContainerRequestToJSON(json: any): UpdateViewContainerRequest {
    return UpdateViewContainerRequestToJSONTyped(json, false);
}

export function UpdateViewContainerRequestToJSONTyped(value?: UpdateViewContainerRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'view_components': value['view_components'] == null ? undefined : ((value['view_components'] as Array<any>).map(UpdateViewComponentRequestToJSON)),
        'name': value['name'],
    };
}

