/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumOptionRetailerInformedFrom } from './EnumOptionRetailerInformedFrom';
import {
    EnumOptionRetailerInformedFromFromJSON,
    EnumOptionRetailerInformedFromFromJSONTyped,
    EnumOptionRetailerInformedFromToJSON,
    EnumOptionRetailerInformedFromToJSONTyped,
} from './EnumOptionRetailerInformedFrom';

/**
 * 
 * @export
 * @interface GetEnumOptionsResponseRetailerInformedFrom
 */
export interface GetEnumOptionsResponseRetailerInformedFrom {
    /**
     * 
     * @type {Array<EnumOptionRetailerInformedFrom>}
     * @memberof GetEnumOptionsResponseRetailerInformedFrom
     */
    options?: Array<EnumOptionRetailerInformedFrom>;
}

/**
 * Check if a given object implements the GetEnumOptionsResponseRetailerInformedFrom interface.
 */
export function instanceOfGetEnumOptionsResponseRetailerInformedFrom(value: object): value is GetEnumOptionsResponseRetailerInformedFrom {
    return true;
}

export function GetEnumOptionsResponseRetailerInformedFromFromJSON(json: any): GetEnumOptionsResponseRetailerInformedFrom {
    return GetEnumOptionsResponseRetailerInformedFromFromJSONTyped(json, false);
}

export function GetEnumOptionsResponseRetailerInformedFromFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEnumOptionsResponseRetailerInformedFrom {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'] == null ? undefined : ((json['options'] as Array<any>).map(EnumOptionRetailerInformedFromFromJSON)),
    };
}

export function GetEnumOptionsResponseRetailerInformedFromToJSON(json: any): GetEnumOptionsResponseRetailerInformedFrom {
    return GetEnumOptionsResponseRetailerInformedFromToJSONTyped(json, false);
}

export function GetEnumOptionsResponseRetailerInformedFromToJSONTyped(value?: GetEnumOptionsResponseRetailerInformedFrom | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': value['options'] == null ? undefined : ((value['options'] as Array<any>).map(EnumOptionRetailerInformedFromToJSON)),
    };
}

