/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateSKURequest
 */
export interface ValidateSKURequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateSKURequest
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateSKURequest
     */
    brand_id?: string;
}

/**
 * Check if a given object implements the ValidateSKURequest interface.
 */
export function instanceOfValidateSKURequest(value: object): value is ValidateSKURequest {
    if (!('sku' in value) || value['sku'] === undefined) return false;
    return true;
}

export function ValidateSKURequestFromJSON(json: any): ValidateSKURequest {
    return ValidateSKURequestFromJSONTyped(json, false);
}

export function ValidateSKURequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateSKURequest {
    if (json == null) {
        return json;
    }
    return {
        
        'sku': json['sku'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
    };
}

export function ValidateSKURequestToJSON(json: any): ValidateSKURequest {
    return ValidateSKURequestToJSONTyped(json, false);
}

export function ValidateSKURequestToJSONTyped(value?: ValidateSKURequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sku': value['sku'],
        'brand_id': value['brand_id'],
    };
}

