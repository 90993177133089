export const searchTrackingHelper = {
  data() {
    return {
      productResult: '',
      brandResult: '',
      categoryResult: ''
    }
  },
  computed: {
    // don't track if the query is shorter than 3 chars
    dontTrack() {
      return !(this.query?.length > 2)
    }
  },
  methods: {
    getIds(items) {
      return items.map((item) => item.id).join()
    },
    trackProductResults(items) {
      const ids = this.getIds(items)
      if (this.dontTrack) {
        return items
      }
      if (this.productResult !== ids) {
        this.productResult = ids
        this.$trackEvent({
          action: 'Autocomplete: Products',
          properties: {
            'search results': items.map((item) => item.name),
            'search query': this.query
          }
        })
      }
      return items
    },
    trackBrandResults(items) {
      const ids = this.getIds(items)
      if (this.dontTrack) {
        return items
      }
      if (this.brandResult !== ids) {
        this.brandResult = ids
        this.$trackEvent({
          action: 'Autocomplete: Brands',
          properties: {
            'search results': items.map((item) => item.brand_name),
            'search query': this.query
          }
        })
      }
      return items
    },
    trackCategoryResults(items) {
      const ids = this.getIds(items)
      if (this.dontTrack) {
        return items
      }
      if (this.categoryResult !== ids) {
        this.categoryResult = ids
        this.$trackEvent({
          action: 'Autocomplete: Categories',
          properties: {
            'search results': items.map((item) => item.name),
            'search query': this.query
          }
        })
      }
      return items
    }
  }
}
