/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandLocationRequest } from './BrandLocationRequest';
import {
    BrandLocationRequestFromJSON,
    BrandLocationRequestFromJSONTyped,
    BrandLocationRequestToJSON,
    BrandLocationRequestToJSONTyped,
} from './BrandLocationRequest';

/**
 * 
 * @export
 * @interface CreateBrandApplicantRequest
 */
export interface CreateBrandApplicantRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandApplicantRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandApplicantRequest
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandApplicantRequest
     */
    website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandApplicantRequest
     */
    instagram_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandApplicantRequest
     */
    bulletin_reference?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandApplicantRequest
     */
    based_in?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBrandApplicantRequest
     */
    product_category: Array<string>;
    /**
     * 
     * @type {Array<BrandLocationRequest>}
     * @memberof CreateBrandApplicantRequest
     */
    brand_locations: Array<BrandLocationRequest>;
}

/**
 * Check if a given object implements the CreateBrandApplicantRequest interface.
 */
export function instanceOfCreateBrandApplicantRequest(value: object): value is CreateBrandApplicantRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('company_name' in value) || value['company_name'] === undefined) return false;
    if (!('product_category' in value) || value['product_category'] === undefined) return false;
    if (!('brand_locations' in value) || value['brand_locations'] === undefined) return false;
    return true;
}

export function CreateBrandApplicantRequestFromJSON(json: any): CreateBrandApplicantRequest {
    return CreateBrandApplicantRequestFromJSONTyped(json, false);
}

export function CreateBrandApplicantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBrandApplicantRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'company_name': json['company_name'],
        'website_url': json['website_url'] == null ? undefined : json['website_url'],
        'instagram_url': json['instagram_url'] == null ? undefined : json['instagram_url'],
        'bulletin_reference': json['bulletin_reference'] == null ? undefined : json['bulletin_reference'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'product_category': json['product_category'],
        'brand_locations': ((json['brand_locations'] as Array<any>).map(BrandLocationRequestFromJSON)),
    };
}

export function CreateBrandApplicantRequestToJSON(json: any): CreateBrandApplicantRequest {
    return CreateBrandApplicantRequestToJSONTyped(json, false);
}

export function CreateBrandApplicantRequestToJSONTyped(value?: CreateBrandApplicantRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'company_name': value['company_name'],
        'website_url': value['website_url'],
        'instagram_url': value['instagram_url'],
        'bulletin_reference': value['bulletin_reference'],
        'based_in': value['based_in'],
        'product_category': value['product_category'],
        'brand_locations': ((value['brand_locations'] as Array<any>).map(BrandLocationRequestToJSON)),
    };
}

