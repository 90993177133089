export const paramsHelper = {
  methods: {
    updateURLParams: function (key, value, forceReplace = false) {
      const { query } = this.$route,
        // eslint-disable-next-line no-unused-vars
        removeKey = (key, { [key]: value, ...rest }) => rest,
        queryToSet = {
          ...this.$route.query,
          [key]: value
        }

      if (query[key] && query[key] === value) {
        return
      }

      const location = {
        query: value ? queryToSet : removeKey(key, query)
      }

      if (key === 'page' && !forceReplace) {
        this.$router.push(location)
      } else {
        this.$router.replace(location)
      }
    }
  }
}
