<template>
  <div>
    <p v-if="increment > 1 && !hideIncrementMessage" class="multiples-text">Enter multiples of {{ increment }}:</p>
    <div class="flex align-center">
      <Icon
        icon-key="chevron-down-circled"
        hover-type="ICON"
        :disabled="disableDecreaseButton || disabled"
        data-test-id="quantity_button_decrement"
        :on-click="decreaseQuantity"
      />
      <input
        v-model="localValue"
        :step="increment"
        type="number"
        min="0"
        :class="{ disabled: disabled, 'green-bg': !disabled, 'no-events': disableInput }"
        class="quantity-input"
        @blur="quantityInputBlur()"
      />
      <Icon
        icon-key="chevron-up-circled"
        hover-type="ICON"
        :disabled="disableIncreaseButton || disabled"
        data-test-id="quantity_button_increment"
        :on-click="increaseQuantity"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    disableInput: {
      type: Boolean,
      default: false
    },
    increment: {
      type: Number,
      default: 0
    },
    disableIncreaseButton: {
      type: Boolean,
      default: false
    },
    disableDecreaseButton: {
      type: Boolean,
      default: false
    },
    decreaseQuantity: {
      type: Function,
      default: () => null
    },
    quantityInputBlur: {
      type: Function,
      default: () => null
    },
    increaseQuantity: {
      type: Function,
      default: () => null
    },
    hideIncrementMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/vars.scss';

.multiples-text {
  font-size: 12px;
  font-family: NHaasGroteskDSPro-55Rg;
}

.quantity-input {
  min-width: 40px;
  height: 40px;
  width: 40px;
  border: 1px solid $color-black;
  font-family: NHaasGroteskDSPro-55Rg;
  text-align: center;
  font-size: 1rem;

  &.disabled {
    border-color: $color-grey-3;
    color: $color-grey-3;
  }

  &.green-bg {
    background: $color-green;
  }
}
</style>
