<template>
  <div class="relative ml-10" @click="isActive ? null : $emit('toggle-cart')">
    <Icon data-cy="cart-button" :icon-key="isActive ? 'cart-active' : 'cart'" hover-type="ICON" />
    <h4 class="cart-count" :class="{ 'color-white': isActive }">
      {{ forceRetailerView ? '2' : count }}
    </h4>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartButton',
  props: {
    forceRetailerView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      count: 'cart/count'
    }),
    isActive() {
      return this.$route?.name?.includes('checkout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_mixins.scss';
.cart-count {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
</style>
