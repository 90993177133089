/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSuborderResponse
 */
export interface UpdateSuborderResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateSuborderResponse
     */
    message?: string;
}

/**
 * Check if a given object implements the UpdateSuborderResponse interface.
 */
export function instanceOfUpdateSuborderResponse(value: object): value is UpdateSuborderResponse {
    return true;
}

export function UpdateSuborderResponseFromJSON(json: any): UpdateSuborderResponse {
    return UpdateSuborderResponseFromJSONTyped(json, false);
}

export function UpdateSuborderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSuborderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function UpdateSuborderResponseToJSON(json: any): UpdateSuborderResponse {
    return UpdateSuborderResponseToJSONTyped(json, false);
}

export function UpdateSuborderResponseToJSONTyped(value?: UpdateSuborderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
    };
}

