/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Submit outbound-onboarding form object
 * @export
 * @interface SubmitOutboundOnboardingFormRequest
 */
export interface SubmitOutboundOnboardingFormRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    owner_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    owner_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    brand_name: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    website_url: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    years_in_business: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    based_in?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    shipping_city?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    categories: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    instagram_follower?: string;
    /**
     * 
     * @type {number}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    minimum_order_value: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    minimum_reorder_value: number;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    initial_skus?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    brand_bio?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    ship_to_canada: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    values: Array<SubmitOutboundOnboardingFormRequestValuesEnum>;
    /**
     * 
     * @type {number}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    case_pack_quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    phone_number: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    sell_with_other_shop: boolean;
    /**
     * 
     * @type {object}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    address: object;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    line_sheet_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    located_in_usa_canada: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    based_in_canada: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    editorial_link?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    product_photography?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormRequest
     */
    best_sellers?: string;
}


/**
 * @export
 */
export const SubmitOutboundOnboardingFormRequestValuesEnum = {
    FemaleFounded: 'FEMALE_FOUNDED',
    EthicalProduction: 'ETHICAL_PRODUCTION',
    CleanIngredients: 'CLEAN_INGREDIENTS',
    Sustainable: 'SUSTAINABLE',
    Handmade: 'HANDMADE',
    MadeInTheUs: 'MADE_IN_THE_US',
    CharitableBrandGiveBack: 'CHARITABLE_BRAND_GIVE_BACK',
    Organic: 'ORGANIC',
    ZeroWaste: 'ZERO_WASTE',
    BlackOwned: 'BLACK_OWNED',
    LatinxOwned: 'LATINX_OWNED',
    AapiOwned: 'AAPI_OWNED',
    IndigenousOwned: 'INDIGENOUS_OWNED',
    LgbtqOwned: 'LGBTQ_OWNED',
    MinorityOwnedBusiness: 'MINORITY_OWNED_BUSINESS',
    DisabilityOwned: 'DISABILITY_OWNED'
} as const;
export type SubmitOutboundOnboardingFormRequestValuesEnum = typeof SubmitOutboundOnboardingFormRequestValuesEnum[keyof typeof SubmitOutboundOnboardingFormRequestValuesEnum];


/**
 * Check if a given object implements the SubmitOutboundOnboardingFormRequest interface.
 */
export function instanceOfSubmitOutboundOnboardingFormRequest(value: object): value is SubmitOutboundOnboardingFormRequest {
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('brand_name' in value) || value['brand_name'] === undefined) return false;
    if (!('website_url' in value) || value['website_url'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('years_in_business' in value) || value['years_in_business'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('minimum_order_value' in value) || value['minimum_order_value'] === undefined) return false;
    if (!('minimum_reorder_value' in value) || value['minimum_reorder_value'] === undefined) return false;
    if (!('ship_to_canada' in value) || value['ship_to_canada'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    if (!('phone_number' in value) || value['phone_number'] === undefined) return false;
    if (!('sell_with_other_shop' in value) || value['sell_with_other_shop'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('located_in_usa_canada' in value) || value['located_in_usa_canada'] === undefined) return false;
    if (!('based_in_canada' in value) || value['based_in_canada'] === undefined) return false;
    return true;
}

export function SubmitOutboundOnboardingFormRequestFromJSON(json: any): SubmitOutboundOnboardingFormRequest {
    return SubmitOutboundOnboardingFormRequestFromJSONTyped(json, false);
}

export function SubmitOutboundOnboardingFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitOutboundOnboardingFormRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'owner_first_name': json['owner_first_name'] == null ? undefined : json['owner_first_name'],
        'owner_last_name': json['owner_last_name'] == null ? undefined : json['owner_last_name'],
        'email': json['email'],
        'brand_name': json['brand_name'],
        'website_url': json['website_url'],
        'location': json['location'],
        'years_in_business': json['years_in_business'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'shipping_city': json['shipping_city'] == null ? undefined : json['shipping_city'],
        'categories': json['categories'],
        'instagram': json['instagram'] == null ? undefined : json['instagram'],
        'instagram_follower': json['instagram_follower'] == null ? undefined : json['instagram_follower'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'minimum_order_value': json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'],
        'initial_skus': json['initial_skus'] == null ? undefined : json['initial_skus'],
        'brand_bio': json['brand_bio'] == null ? undefined : json['brand_bio'],
        'ship_to_canada': json['ship_to_canada'],
        'values': json['values'],
        'case_pack_quantity': json['case_pack_quantity'] == null ? undefined : json['case_pack_quantity'],
        'phone_number': json['phone_number'],
        'sell_with_other_shop': json['sell_with_other_shop'],
        'address': json['address'],
        'line_sheet_url': json['line_sheet_url'] == null ? undefined : json['line_sheet_url'],
        'located_in_usa_canada': json['located_in_usa_canada'],
        'based_in_canada': json['based_in_canada'],
        'editorial_link': json['editorial_link'] == null ? undefined : json['editorial_link'],
        'product_photography': json['product_photography'] == null ? undefined : json['product_photography'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'best_sellers': json['best_sellers'] == null ? undefined : json['best_sellers'],
    };
}

export function SubmitOutboundOnboardingFormRequestToJSON(json: any): SubmitOutboundOnboardingFormRequest {
    return SubmitOutboundOnboardingFormRequestToJSONTyped(json, false);
}

export function SubmitOutboundOnboardingFormRequestToJSONTyped(value?: SubmitOutboundOnboardingFormRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'owner_first_name': value['owner_first_name'],
        'owner_last_name': value['owner_last_name'],
        'email': value['email'],
        'brand_name': value['brand_name'],
        'website_url': value['website_url'],
        'location': value['location'],
        'years_in_business': value['years_in_business'],
        'based_in': value['based_in'],
        'shipping_city': value['shipping_city'],
        'categories': value['categories'],
        'instagram': value['instagram'],
        'instagram_follower': value['instagram_follower'],
        'min_lead_time': value['min_lead_time'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'initial_skus': value['initial_skus'],
        'brand_bio': value['brand_bio'],
        'ship_to_canada': value['ship_to_canada'],
        'values': value['values'],
        'case_pack_quantity': value['case_pack_quantity'],
        'phone_number': value['phone_number'],
        'sell_with_other_shop': value['sell_with_other_shop'],
        'address': value['address'],
        'line_sheet_url': value['line_sheet_url'],
        'located_in_usa_canada': value['located_in_usa_canada'],
        'based_in_canada': value['based_in_canada'],
        'editorial_link': value['editorial_link'],
        'product_photography': value['product_photography'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'best_sellers': value['best_sellers'],
    };
}

