/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SuborderPartialResponse } from './SuborderPartialResponse';
import {
    SuborderPartialResponseFromJSON,
    SuborderPartialResponseFromJSONTyped,
    SuborderPartialResponseToJSON,
    SuborderPartialResponseToJSONTyped,
} from './SuborderPartialResponse';
import type { AddressPartialResponse } from './AddressPartialResponse';
import {
    AddressPartialResponseFromJSON,
    AddressPartialResponseFromJSONTyped,
    AddressPartialResponseToJSON,
    AddressPartialResponseToJSONTyped,
} from './AddressPartialResponse';

/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    order_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    city?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderResponse
     */
    is_custom_assortment?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponse
     */
    timestamp?: Date;
    /**
     * 
     * @type {Array<SuborderPartialResponse>}
     * @memberof OrderResponse
     */
    suborders?: Array<SuborderPartialResponse>;
    /**
     * 
     * @type {AddressPartialResponse}
     * @memberof OrderResponse
     */
    address?: AddressPartialResponse;
}

/**
 * Check if a given object implements the OrderResponse interface.
 */
export function instanceOfOrderResponse(value: object): value is OrderResponse {
    return true;
}

export function OrderResponseFromJSON(json: any): OrderResponse {
    return OrderResponseFromJSONTyped(json, false);
}

export function OrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'order_id': json['order_id'] == null ? undefined : json['order_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'city': json['city'] == null ? undefined : json['city'],
        'is_custom_assortment': json['is_custom_assortment'] == null ? undefined : json['is_custom_assortment'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'suborders': json['suborders'] == null ? undefined : ((json['suborders'] as Array<any>).map(SuborderPartialResponseFromJSON)),
        'address': json['address'] == null ? undefined : AddressPartialResponseFromJSON(json['address']),
    };
}

export function OrderResponseToJSON(json: any): OrderResponse {
    return OrderResponseToJSONTyped(json, false);
}

export function OrderResponseToJSONTyped(value?: OrderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'order_id': value['order_id'],
        'retailer_name': value['retailer_name'],
        'city': value['city'],
        'is_custom_assortment': value['is_custom_assortment'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'suborders': value['suborders'] == null ? undefined : ((value['suborders'] as Array<any>).map(SuborderPartialResponseToJSON)),
        'address': AddressPartialResponseToJSON(value['address']),
    };
}

