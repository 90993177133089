/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VariantRequest } from './VariantRequest';
import {
    VariantRequestFromJSON,
    VariantRequestFromJSONTyped,
    VariantRequestToJSON,
    VariantRequestToJSONTyped,
} from './VariantRequest';

/**
 * Update Product object
 * @export
 * @interface ProductBuilderUpdateProductRequest
 */
export interface ProductBuilderUpdateProductRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderUpdateProductRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderUpdateProductRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderUpdateProductRequest
     */
    brand_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBuilderUpdateProductRequest
     */
    category_ids?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    sell_pos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    sell_wholesale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    pos_price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    compare_at_price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    wholesale_minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    wholesale_increment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    opted_into_consignment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    commission_split?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    visible: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBuilderUpdateProductRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    max_lead_time?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    is_test_product: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBuilderUpdateProductRequest
     */
    characteristics?: Array<string>;
    /**
     * 
     * @type {Array<VariantRequest>}
     * @memberof ProductBuilderUpdateProductRequest
     */
    variants?: Array<VariantRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBuilderUpdateProductRequest
     */
    images?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBuilderUpdateProductRequest
     */
    custom_assortment_images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderUpdateProductRequest
     */
    track_inventory?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderUpdateProductRequest
     */
    weight?: number;
}

/**
 * Check if a given object implements the ProductBuilderUpdateProductRequest interface.
 */
export function instanceOfProductBuilderUpdateProductRequest(value: object): value is ProductBuilderUpdateProductRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('visible' in value) || value['visible'] === undefined) return false;
    if (!('is_test_product' in value) || value['is_test_product'] === undefined) return false;
    return true;
}

export function ProductBuilderUpdateProductRequestFromJSON(json: any): ProductBuilderUpdateProductRequest {
    return ProductBuilderUpdateProductRequestFromJSONTyped(json, false);
}

export function ProductBuilderUpdateProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBuilderUpdateProductRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'category_ids': json['category_ids'] == null ? undefined : json['category_ids'],
        'sell_pos': json['sell_pos'] == null ? undefined : json['sell_pos'],
        'sell_wholesale': json['sell_wholesale'] == null ? undefined : json['sell_wholesale'],
        'active': json['active'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'compare_at_price': json['compare_at_price'] == null ? undefined : json['compare_at_price'],
        'wholesale_minimum': json['wholesale_minimum'] == null ? undefined : json['wholesale_minimum'],
        'wholesale_increment': json['wholesale_increment'] == null ? undefined : json['wholesale_increment'],
        'opted_into_consignment': json['opted_into_consignment'] == null ? undefined : json['opted_into_consignment'],
        'commission_split': json['commission_split'] == null ? undefined : json['commission_split'],
        'visible': json['visible'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'is_test_product': json['is_test_product'],
        'characteristics': json['characteristics'] == null ? undefined : json['characteristics'],
        'variants': json['variants'] == null ? undefined : ((json['variants'] as Array<any>).map(VariantRequestFromJSON)),
        'images': json['images'] == null ? undefined : json['images'],
        'custom_assortment_images': json['custom_assortment_images'] == null ? undefined : json['custom_assortment_images'],
        'track_inventory': json['track_inventory'] == null ? undefined : json['track_inventory'],
        'weight': json['weight'] == null ? undefined : json['weight'],
    };
}

export function ProductBuilderUpdateProductRequestToJSON(json: any): ProductBuilderUpdateProductRequest {
    return ProductBuilderUpdateProductRequestToJSONTyped(json, false);
}

export function ProductBuilderUpdateProductRequestToJSONTyped(value?: ProductBuilderUpdateProductRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'description': value['description'],
        'brand_id': value['brand_id'],
        'category_ids': value['category_ids'],
        'sell_pos': value['sell_pos'],
        'sell_wholesale': value['sell_wholesale'],
        'active': value['active'],
        'pos_price': value['pos_price'],
        'wholesale_price': value['wholesale_price'],
        'compare_at_price': value['compare_at_price'],
        'wholesale_minimum': value['wholesale_minimum'],
        'wholesale_increment': value['wholesale_increment'],
        'opted_into_consignment': value['opted_into_consignment'],
        'commission_split': value['commission_split'],
        'visible': value['visible'],
        'tags': value['tags'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'is_test_product': value['is_test_product'],
        'characteristics': value['characteristics'],
        'variants': value['variants'] == null ? undefined : ((value['variants'] as Array<any>).map(VariantRequestToJSON)),
        'images': value['images'],
        'custom_assortment_images': value['custom_assortment_images'],
        'track_inventory': value['track_inventory'],
        'weight': value['weight'],
    };
}

