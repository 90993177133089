/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PackingSlipItemRequest } from './PackingSlipItemRequest';
import {
    PackingSlipItemRequestFromJSON,
    PackingSlipItemRequestFromJSONTyped,
    PackingSlipItemRequestToJSON,
    PackingSlipItemRequestToJSONTyped,
} from './PackingSlipItemRequest';

/**
 * 
 * @export
 * @interface PackingSlipRequest
 */
export interface PackingSlipRequest {
    /**
     * 
     * @type {Array<PackingSlipItemRequest>}
     * @memberof PackingSlipRequest
     */
    items?: Array<PackingSlipItemRequest>;
    /**
     * 
     * @type {number}
     * @memberof PackingSlipRequest
     */
    ordinal?: number;
}

/**
 * Check if a given object implements the PackingSlipRequest interface.
 */
export function instanceOfPackingSlipRequest(value: object): value is PackingSlipRequest {
    return true;
}

export function PackingSlipRequestFromJSON(json: any): PackingSlipRequest {
    return PackingSlipRequestFromJSONTyped(json, false);
}

export function PackingSlipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackingSlipRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(PackingSlipItemRequestFromJSON)),
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
    };
}

export function PackingSlipRequestToJSON(json: any): PackingSlipRequest {
    return PackingSlipRequestToJSONTyped(json, false);
}

export function PackingSlipRequestToJSONTyped(value?: PackingSlipRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(PackingSlipItemRequestToJSON)),
        'ordinal': value['ordinal'],
    };
}

