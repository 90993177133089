/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update external service object
 * @export
 * @interface UpdateExternalServiceRequest
 */
export interface UpdateExternalServiceRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateExternalServiceRequest
     */
    numbers: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExternalServiceRequest
     */
    algolia?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExternalServiceRequest
     */
    salesforce?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExternalServiceRequest
     */
    retailer?: boolean;
}

/**
 * Check if a given object implements the UpdateExternalServiceRequest interface.
 */
export function instanceOfUpdateExternalServiceRequest(value: object): value is UpdateExternalServiceRequest {
    if (!('numbers' in value) || value['numbers'] === undefined) return false;
    return true;
}

export function UpdateExternalServiceRequestFromJSON(json: any): UpdateExternalServiceRequest {
    return UpdateExternalServiceRequestFromJSONTyped(json, false);
}

export function UpdateExternalServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateExternalServiceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'numbers': json['numbers'],
        'algolia': json['algolia'] == null ? undefined : json['algolia'],
        'salesforce': json['salesforce'] == null ? undefined : json['salesforce'],
        'retailer': json['retailer'] == null ? undefined : json['retailer'],
    };
}

export function UpdateExternalServiceRequestToJSON(json: any): UpdateExternalServiceRequest {
    return UpdateExternalServiceRequestToJSONTyped(json, false);
}

export function UpdateExternalServiceRequestToJSONTyped(value?: UpdateExternalServiceRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'numbers': value['numbers'],
        'algolia': value['algolia'],
        'salesforce': value['salesforce'],
        'retailer': value['retailer'],
    };
}

