<template>
  <div>
    <div v-if="loading" class="flex align-center justify-center">
      <loader-static />
    </div>
    <div v-else>
      <div v-if="products.length > 0" class="products-grid" :class="{ 'four-column': fourColumn }">
        <product-tile
          v-for="(product, i) in products"
          :key="'product_' + product.id + '_' + i"
          :i="i"
          :data="product"
          :quick-shop="showQuickShop()"
          :fit-height="true"
          :filter-or-sort-used="isFilterOrSortUser()"
          :list-of-filter-types="getActiveFilterKeys()"
          :event-id="eventId"
        />
      </div>
      <div v-else>
        <h4 class="big flex-grow mb-20">no results!</h4>
      </div>
      <pagination
        v-if="pagination"
        class="flex justify-center mt-50 mt-25-sm"
        :active="pagination.page"
        :number-of-pages="pagination.numberOfPages"
        :total="pagination.totalCount"
        :on-click="setPage"
        :limit="pagination.limit"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    fourColumn: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: null
    },

    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          limit: 36,
          totalCount: null,
          numberOfPages: 0
        }
      }
    },
    setPage: {
      type: Function,
      default: () => {}
    },
    eventId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      user: 'userState'
    })
  },
  methods: {
    getActiveFilterKeys: function () {
      const activeFilters = Object.keys(this.filters)?.filter((key) => {
        return this.filters[key]?.length
      })
      const activeSort = this.$route.query.sortBy ? ['Search result sort'] : []
      return [...activeFilters, ...activeSort]
    },
    isFilterOrSortUser: function () {
      return !!this.getActiveFilterKeys().length
    },
    showQuickShop: function () {
      return this.user?.role !== 'BRAND'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_mixins.scss';

.products-grid {
  display: grid;
  width: 100%;
  grid-row-gap: 40px;
  grid-column-gap: 24px !important;
  grid-template-columns: 1fr 1fr 1fr;

  &.four-column {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include respond-to('small') {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include respond-to('small') {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 40px;
  }
}
</style>
