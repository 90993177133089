/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductSearchCriteriaRequest } from './ProductSearchCriteriaRequest';
import {
    ProductSearchCriteriaRequestFromJSON,
    ProductSearchCriteriaRequestFromJSONTyped,
    ProductSearchCriteriaRequestToJSON,
    ProductSearchCriteriaRequestToJSONTyped,
} from './ProductSearchCriteriaRequest';

/**
 * Create collection object
 * @export
 * @interface CreateCollectionRequest
 */
export interface CreateCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionRequest
     */
    internal_title?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionRequest
     */
    collection_type: CreateCollectionRequestCollectionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCollectionRequest
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCollectionRequest
     */
    include_all_conditions?: boolean;
    /**
     * 
     * @type {Array<ProductSearchCriteriaRequest>}
     * @memberof CreateCollectionRequest
     */
    product_search_criteria?: Array<ProductSearchCriteriaRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateCollectionRequest
     */
    featured_category_id?: string;
}


/**
 * @export
 */
export const CreateCollectionRequestCollectionTypeEnum = {
    Manual: 'MANUAL',
    Automated: 'AUTOMATED',
    Parent: 'PARENT'
} as const;
export type CreateCollectionRequestCollectionTypeEnum = typeof CreateCollectionRequestCollectionTypeEnum[keyof typeof CreateCollectionRequestCollectionTypeEnum];


/**
 * Check if a given object implements the CreateCollectionRequest interface.
 */
export function instanceOfCreateCollectionRequest(value: object): value is CreateCollectionRequest {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('collection_type' in value) || value['collection_type'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function CreateCollectionRequestFromJSON(json: any): CreateCollectionRequest {
    return CreateCollectionRequestFromJSONTyped(json, false);
}

export function CreateCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'internal_title': json['internal_title'] == null ? undefined : json['internal_title'],
        'description': json['description'] == null ? undefined : json['description'],
        'collection_type': json['collection_type'],
        'active': json['active'],
        'include_all_conditions': json['include_all_conditions'] == null ? undefined : json['include_all_conditions'],
        'product_search_criteria': json['product_search_criteria'] == null ? undefined : ((json['product_search_criteria'] as Array<any>).map(ProductSearchCriteriaRequestFromJSON)),
        'featured_category_id': json['featured_category_id'] == null ? undefined : json['featured_category_id'],
    };
}

export function CreateCollectionRequestToJSON(json: any): CreateCollectionRequest {
    return CreateCollectionRequestToJSONTyped(json, false);
}

export function CreateCollectionRequestToJSONTyped(value?: CreateCollectionRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'internal_title': value['internal_title'],
        'description': value['description'],
        'collection_type': value['collection_type'],
        'active': value['active'],
        'include_all_conditions': value['include_all_conditions'],
        'product_search_criteria': value['product_search_criteria'] == null ? undefined : ((value['product_search_criteria'] as Array<any>).map(ProductSearchCriteriaRequestToJSON)),
        'featured_category_id': value['featured_category_id'],
    };
}

