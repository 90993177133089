<template>
  <dropdown
    v-if="hasAccess()"
    ref="brandDropdown"
    data-test-id="brand-dropdown"
    :class="{ 'opacity-05': !brands.length }"
    :dropdown-items="brands"
    :searchable="true"
    :is-required="required"
    :disabled="disabled || !brands.length"
    :dropdown-index="dropdownIndex"
    :selected-name="selectedName"
    :placeholder="loading ? 'Loading Brands...' : placeholder"
    :name="name"
    :on-select="handleSelectChange"
    :input-class="inputClass"
    :optionaly-available="excludeBy != ''"
  />
</template>

<script>
import { BrandApi } from '@/api'
import roleHelper from '../../../mixin/roleHelper'

export default {
  mixins: [roleHelper],
  props: {
    excludeBy: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select Brand'
    },
    inputClass: {
      type: String,
      default: ''
    },
    selectedName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    dropdownIndex: {
      type: Number,
      default: undefined
    },
    showAllAsOption: {
      type: Boolean,
      default: false
    },
    showResetAsOption: {
      type: Boolean,
      default: false
    },
    onSelect: {
      type: Function,
      default: () => {}
    },
    onReset: {
      type: Function,
      default: () => {}
    },
    onBrandsFetched: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      brands: [],
      loading: false
    }
  },
  mounted() {
    this.getBrands()
  },
  methods: {
    getBrands,
    handleSelectChange
  }
}

async function getBrands() {
  if (!this.hasAccess()) {
    return
  }

  try {
    this.loading = true
    this.excludeShopify = false
    if (this.excludeBy === 'connectedToShopify') {
      this.excludeShopify = true
    }
    const { brands_dropdown } = await BrandApi.getBrandsDropdown({
      isPublished: this.isPublished,
      excludeShopify: this.excludeShopify
    })
    this.brands = brands_dropdown

    if (this.showAllAsOption) {
      this.brands?.unshift({
        name: 'ALL'
      })
    }

    if (this.showResetAsOption) {
      this.brands?.unshift({
        name: 'Reset'
      })
    }

    this.onBrandsFetched()
  } catch (error) {
    this.$handleError(this, this.$utils.formatErrorMessage('brands'))
  } finally {
    this.loading = false
  }
}

function handleSelectChange(item) {
  if (item.name === 'Reset') {
    this.$refs.brandDropdown.selected.name = ''
    this.onReset(item)
  } else {
    this.onSelect(item)
  }
}
</script>
