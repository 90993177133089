/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetTradeShowDetailsResponse } from './GetTradeShowDetailsResponse';
import {
    GetTradeShowDetailsResponseFromJSON,
    GetTradeShowDetailsResponseFromJSONTyped,
    GetTradeShowDetailsResponseToJSON,
    GetTradeShowDetailsResponseToJSONTyped,
} from './GetTradeShowDetailsResponse';

/**
 * 
 * @export
 * @interface GetAllTradeShowsResponse
 */
export interface GetAllTradeShowsResponse {
    /**
     * 
     * @type {Array<GetTradeShowDetailsResponse>}
     * @memberof GetAllTradeShowsResponse
     */
    trade_shows?: Array<GetTradeShowDetailsResponse>;
}

/**
 * Check if a given object implements the GetAllTradeShowsResponse interface.
 */
export function instanceOfGetAllTradeShowsResponse(value: object): value is GetAllTradeShowsResponse {
    return true;
}

export function GetAllTradeShowsResponseFromJSON(json: any): GetAllTradeShowsResponse {
    return GetAllTradeShowsResponseFromJSONTyped(json, false);
}

export function GetAllTradeShowsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllTradeShowsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'trade_shows': json['trade_shows'] == null ? undefined : ((json['trade_shows'] as Array<any>).map(GetTradeShowDetailsResponseFromJSON)),
    };
}

export function GetAllTradeShowsResponseToJSON(json: any): GetAllTradeShowsResponse {
    return GetAllTradeShowsResponseToJSONTyped(json, false);
}

export function GetAllTradeShowsResponseToJSONTyped(value?: GetAllTradeShowsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'trade_shows': value['trade_shows'] == null ? undefined : ((value['trade_shows'] as Array<any>).map(GetTradeShowDetailsResponseToJSON)),
    };
}

