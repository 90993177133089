/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeViewComponentActivityRequest
 */
export interface ChangeViewComponentActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeViewComponentActivityRequest
     */
    view_component_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeViewComponentActivityRequest
     */
    active: boolean;
}

/**
 * Check if a given object implements the ChangeViewComponentActivityRequest interface.
 */
export function instanceOfChangeViewComponentActivityRequest(value: object): value is ChangeViewComponentActivityRequest {
    if (!('view_component_id' in value) || value['view_component_id'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function ChangeViewComponentActivityRequestFromJSON(json: any): ChangeViewComponentActivityRequest {
    return ChangeViewComponentActivityRequestFromJSONTyped(json, false);
}

export function ChangeViewComponentActivityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeViewComponentActivityRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'view_component_id': json['view_component_id'],
        'active': json['active'],
    };
}

export function ChangeViewComponentActivityRequestToJSON(json: any): ChangeViewComponentActivityRequest {
    return ChangeViewComponentActivityRequestToJSONTyped(json, false);
}

export function ChangeViewComponentActivityRequestToJSONTyped(value?: ChangeViewComponentActivityRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'view_component_id': value['view_component_id'],
        'active': value['active'],
    };
}

