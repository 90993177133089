/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExhibitorHeaderButtonRequest
 */
export interface ExhibitorHeaderButtonRequest {
    /**
     * 
     * @type {string}
     * @memberof ExhibitorHeaderButtonRequest
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorHeaderButtonRequest
     */
    link_url?: string;
}

/**
 * Check if a given object implements the ExhibitorHeaderButtonRequest interface.
 */
export function instanceOfExhibitorHeaderButtonRequest(value: object): value is ExhibitorHeaderButtonRequest {
    return true;
}

export function ExhibitorHeaderButtonRequestFromJSON(json: any): ExhibitorHeaderButtonRequest {
    return ExhibitorHeaderButtonRequestFromJSONTyped(json, false);
}

export function ExhibitorHeaderButtonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitorHeaderButtonRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'link_url': json['link_url'] == null ? undefined : json['link_url'],
    };
}

export function ExhibitorHeaderButtonRequestToJSON(json: any): ExhibitorHeaderButtonRequest {
    return ExhibitorHeaderButtonRequestToJSONTyped(json, false);
}

export function ExhibitorHeaderButtonRequestToJSONTyped(value?: ExhibitorHeaderButtonRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'link_url': value['link_url'],
    };
}

