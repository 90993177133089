/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Additional
 */
export interface Additional {
    /**
     * 
     * @type {boolean}
     * @memberof Additional
     */
    has_known_bounces: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Additional
     */
    has_suspected_bounces: boolean;
}

/**
 * Check if a given object implements the Additional interface.
 */
export function instanceOfAdditional(value: object): value is Additional {
    if (!('has_known_bounces' in value) || value['has_known_bounces'] === undefined) return false;
    if (!('has_suspected_bounces' in value) || value['has_suspected_bounces'] === undefined) return false;
    return true;
}

export function AdditionalFromJSON(json: any): Additional {
    return AdditionalFromJSONTyped(json, false);
}

export function AdditionalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Additional {
    if (json == null) {
        return json;
    }
    return {
        
        'has_known_bounces': json['has_known_bounces'],
        'has_suspected_bounces': json['has_suspected_bounces'],
    };
}

export function AdditionalToJSON(json: any): Additional {
    return AdditionalToJSONTyped(json, false);
}

export function AdditionalToJSONTyped(value?: Additional | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'has_known_bounces': value['has_known_bounces'],
        'has_suspected_bounces': value['has_suspected_bounces'],
    };
}

