/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignInRequest
 */
export interface SignInRequest {
    /**
     * 
     * @type {string}
     * @memberof SignInRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignInRequest
     */
    password: string;
}

/**
 * Check if a given object implements the SignInRequest interface.
 */
export function instanceOfSignInRequest(value: object): value is SignInRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function SignInRequestFromJSON(json: any): SignInRequest {
    return SignInRequestFromJSONTyped(json, false);
}

export function SignInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function SignInRequestToJSON(json: any): SignInRequest {
    return SignInRequestToJSONTyped(json, false);
}

export function SignInRequestToJSONTyped(value?: SignInRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

