<template>
  <div class="videoContainer">
    <video ref="videoElem" preload="none" autoplay />
  </div>
</template>

<script>
import Hls from 'hls.js'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      hls: null
    }
  },
  mounted() {
    if (!this.loaded) {
      this.loadVideo()
    }
  },
  methods: {
    loadVideo
  }
}

function loadVideo() {
  const video = this.$refs.videoElem,
    videoSrc = `https://stream.mux.com/${this.options.url}.m3u8`
  if (Hls.isSupported()) {
    this.hls = new Hls()
    this.hls.loadSource(videoSrc)
    this.hls.attachMedia(video)
    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      video.play()
      video.controls = true
    })
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = videoSrc
    video.addEventListener('loadedmetadata', () => {
      video.play()
      video.controls = true
    })
  }
}
</script>

<style lang="scss" scoped>
.videoContainer {
  position: relative;
  .playButton {
    cursor: pointer;
    position: absolute;
    border: none;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 55%;
    margin-left: -17.5%;
    margin-top: -17.5%;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    z-index: 4;
  }
  video {
    width: 100% !important;
    height: auto !important;
    min-height: 330px;
  }
}
</style>
