/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplyToBuyResponse
 */
export interface ApplyToBuyResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyResponse
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyResponse
     */
    brand_email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyResponse
     */
    balance_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplyToBuyResponse
     */
    use_balance?: boolean;
}

/**
 * Check if a given object implements the ApplyToBuyResponse interface.
 */
export function instanceOfApplyToBuyResponse(value: object): value is ApplyToBuyResponse {
    return true;
}

export function ApplyToBuyResponseFromJSON(json: any): ApplyToBuyResponse {
    return ApplyToBuyResponseFromJSONTyped(json, false);
}

export function ApplyToBuyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyToBuyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'user_id': json['user_id'] == null ? undefined : json['user_id'],
        'brand_email': json['brand_email'] == null ? undefined : json['brand_email'],
        'balance_id': json['balance_id'] == null ? undefined : json['balance_id'],
        'use_balance': json['use_balance'] == null ? undefined : json['use_balance'],
    };
}

export function ApplyToBuyResponseToJSON(json: any): ApplyToBuyResponse {
    return ApplyToBuyResponseToJSONTyped(json, false);
}

export function ApplyToBuyResponseToJSONTyped(value?: ApplyToBuyResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'user_id': value['user_id'],
        'brand_email': value['brand_email'],
        'balance_id': value['balance_id'],
        'use_balance': value['use_balance'],
    };
}

