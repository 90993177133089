/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Confirm packing slip object
 * @export
 * @interface ConfirmPackingSlipRequest
 */
export interface ConfirmPackingSlipRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ConfirmPackingSlipRequest
     */
    has_unfulfilled_items?: boolean;
}

/**
 * Check if a given object implements the ConfirmPackingSlipRequest interface.
 */
export function instanceOfConfirmPackingSlipRequest(value: object): value is ConfirmPackingSlipRequest {
    return true;
}

export function ConfirmPackingSlipRequestFromJSON(json: any): ConfirmPackingSlipRequest {
    return ConfirmPackingSlipRequestFromJSONTyped(json, false);
}

export function ConfirmPackingSlipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmPackingSlipRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'has_unfulfilled_items': json['has_unfulfilled_items'] == null ? undefined : json['has_unfulfilled_items'],
    };
}

export function ConfirmPackingSlipRequestToJSON(json: any): ConfirmPackingSlipRequest {
    return ConfirmPackingSlipRequestToJSONTyped(json, false);
}

export function ConfirmPackingSlipRequestToJSONTyped(value?: ConfirmPackingSlipRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'has_unfulfilled_items': value['has_unfulfilled_items'],
    };
}

