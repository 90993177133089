/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportProductsNavigationRequest
 */
export interface ImportProductsNavigationRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    product_name: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    subcategory?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    new_subcategory: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    parent_category?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    new_parent_category?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    collection?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProductsNavigationRequest
     */
    tags?: string;
}

/**
 * Check if a given object implements the ImportProductsNavigationRequest interface.
 */
export function instanceOfImportProductsNavigationRequest(value: object): value is ImportProductsNavigationRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('product_name' in value) || value['product_name'] === undefined) return false;
    if (!('new_subcategory' in value) || value['new_subcategory'] === undefined) return false;
    return true;
}

export function ImportProductsNavigationRequestFromJSON(json: any): ImportProductsNavigationRequest {
    return ImportProductsNavigationRequestFromJSONTyped(json, false);
}

export function ImportProductsNavigationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportProductsNavigationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'product_name': json['product_name'],
        'subcategory': json['subcategory'] == null ? undefined : json['subcategory'],
        'new_subcategory': json['new_subcategory'],
        'parent_category': json['parent_category'] == null ? undefined : json['parent_category'],
        'new_parent_category': json['new_parent_category'] == null ? undefined : json['new_parent_category'],
        'collection': json['collection'] == null ? undefined : json['collection'],
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function ImportProductsNavigationRequestToJSON(json: any): ImportProductsNavigationRequest {
    return ImportProductsNavigationRequestToJSONTyped(json, false);
}

export function ImportProductsNavigationRequestToJSONTyped(value?: ImportProductsNavigationRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'brand_name': value['brand_name'],
        'product_name': value['product_name'],
        'subcategory': value['subcategory'],
        'new_subcategory': value['new_subcategory'],
        'parent_category': value['parent_category'],
        'new_parent_category': value['new_parent_category'],
        'collection': value['collection'],
        'tags': value['tags'],
    };
}

