/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Merge Exhibitor Brand into Existing Brand
 * @export
 * @interface MergeExhibitorBrandRequest
 */
export interface MergeExhibitorBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof MergeExhibitorBrandRequest
     */
    exhibitor_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MergeExhibitorBrandRequest
     */
    brand_id?: string;
}

/**
 * Check if a given object implements the MergeExhibitorBrandRequest interface.
 */
export function instanceOfMergeExhibitorBrandRequest(value: object): value is MergeExhibitorBrandRequest {
    return true;
}

export function MergeExhibitorBrandRequestFromJSON(json: any): MergeExhibitorBrandRequest {
    return MergeExhibitorBrandRequestFromJSONTyped(json, false);
}

export function MergeExhibitorBrandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeExhibitorBrandRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'exhibitor_id': json['exhibitor_id'] == null ? undefined : json['exhibitor_id'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
    };
}

export function MergeExhibitorBrandRequestToJSON(json: any): MergeExhibitorBrandRequest {
    return MergeExhibitorBrandRequestToJSONTyped(json, false);
}

export function MergeExhibitorBrandRequestToJSONTyped(value?: MergeExhibitorBrandRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exhibitor_id': value['exhibitor_id'],
        'brand_id': value['brand_id'],
    };
}

