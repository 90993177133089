import { datadogRum } from '@datadog/browser-rum'
import Env from '@/env'

export default {
  addAction(action, context = undefined) {
    if (Env.getOrigin() === 'prod') {
      datadogRum.addAction(action, context)
    } else {
      console.warn(action, context)
    }
  },
  notify(e, context = undefined) {
    if (Env.getOrigin() === 'prod') {
      datadogRum.addError(e, context)
    } else {
      console.error(e, context)
    }
  },
  startView(view) {
    if (Env.getOrigin() === 'prod') {
      datadogRum.startView(view)
    }
  }
}
