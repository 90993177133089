/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InventoryLevelProductResponse } from './InventoryLevelProductResponse';
import {
    InventoryLevelProductResponseFromJSON,
    InventoryLevelProductResponseFromJSONTyped,
    InventoryLevelProductResponseToJSON,
    InventoryLevelProductResponseToJSONTyped,
} from './InventoryLevelProductResponse';

/**
 * 
 * @export
 * @interface GetInventoryLevelsResponse
 */
export interface GetInventoryLevelsResponse {
    /**
     * 
     * @type {Array<InventoryLevelProductResponse>}
     * @memberof GetInventoryLevelsResponse
     */
    products?: Array<InventoryLevelProductResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetInventoryLevelsResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the GetInventoryLevelsResponse interface.
 */
export function instanceOfGetInventoryLevelsResponse(value: object): value is GetInventoryLevelsResponse {
    return true;
}

export function GetInventoryLevelsResponseFromJSON(json: any): GetInventoryLevelsResponse {
    return GetInventoryLevelsResponseFromJSONTyped(json, false);
}

export function GetInventoryLevelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInventoryLevelsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(InventoryLevelProductResponseFromJSON)),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function GetInventoryLevelsResponseToJSON(json: any): GetInventoryLevelsResponse {
    return GetInventoryLevelsResponseToJSONTyped(json, false);
}

export function GetInventoryLevelsResponseToJSONTyped(value?: GetInventoryLevelsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(InventoryLevelProductResponseToJSON)),
        'total': value['total'],
    };
}

