/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariantRequest
 */
export interface VariantRequest {
    /**
     * 
     * @type {string}
     * @memberof VariantRequest
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    pos_price?: number;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    cost_per_item?: number;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    compare_at_price?: number;
    /**
     * 
     * @type {string}
     * @memberof VariantRequest
     */
    tester_variant_id?: string;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    tester_minimum_quantity?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VariantRequest
     */
    characteristic_option?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof VariantRequest
     */
    sku?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VariantRequest
     */
    in_stock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    inventory_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof VariantRequest
     */
    weight?: number;
}

/**
 * Check if a given object implements the VariantRequest interface.
 */
export function instanceOfVariantRequest(value: object): value is VariantRequest {
    return true;
}

export function VariantRequestFromJSON(json: any): VariantRequest {
    return VariantRequestFromJSONTyped(json, false);
}

export function VariantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'cost_per_item': json['cost_per_item'] == null ? undefined : json['cost_per_item'],
        'compare_at_price': json['compare_at_price'] == null ? undefined : json['compare_at_price'],
        'tester_variant_id': json['tester_variant_id'] == null ? undefined : json['tester_variant_id'],
        'tester_minimum_quantity': json['tester_minimum_quantity'] == null ? undefined : json['tester_minimum_quantity'],
        'characteristic_option': json['characteristic_option'] == null ? undefined : json['characteristic_option'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'in_stock': json['in_stock'] == null ? undefined : json['in_stock'],
        'inventory_quantity': json['inventory_quantity'] == null ? undefined : json['inventory_quantity'],
        'weight': json['weight'] == null ? undefined : json['weight'],
    };
}

export function VariantRequestToJSON(json: any): VariantRequest {
    return VariantRequestToJSONTyped(json, false);
}

export function VariantRequestToJSONTyped(value?: VariantRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'pos_price': value['pos_price'],
        'wholesale_price': value['wholesale_price'],
        'cost_per_item': value['cost_per_item'],
        'compare_at_price': value['compare_at_price'],
        'tester_variant_id': value['tester_variant_id'],
        'tester_minimum_quantity': value['tester_minimum_quantity'],
        'characteristic_option': value['characteristic_option'],
        'ordinal': value['ordinal'],
        'sku': value['sku'],
        'in_stock': value['in_stock'],
        'inventory_quantity': value['inventory_quantity'],
        'weight': value['weight'],
    };
}

