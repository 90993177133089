/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PromoCodeResponse
 */
export interface PromoCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof PromoCodeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeResponse
     */
    promo_code?: string;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeResponse
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeResponse
     */
    type?: PromoCodeResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeResponse
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof PromoCodeResponse
     */
    start_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PromoCodeResponse
     */
    end_date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PromoCodeResponse
     */
    first_order_only?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromoCodeResponse
     */
    one_time_use?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeResponse
     */
    status?: PromoCodeResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeResponse
     */
    minimum_cart_value?: number;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeResponse
     */
    promo_code_creator?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PromoCodeResponse
     */
    ongoing_indefinite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromoCodeResponse
     */
    published?: boolean;
}


/**
 * @export
 */
export const PromoCodeResponseTypeEnum = {
    Percent: 'PERCENT',
    Amount: 'AMOUNT',
    FreeShipping: 'FREE_SHIPPING'
} as const;
export type PromoCodeResponseTypeEnum = typeof PromoCodeResponseTypeEnum[keyof typeof PromoCodeResponseTypeEnum];

/**
 * @export
 */
export const PromoCodeResponseStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type PromoCodeResponseStatusEnum = typeof PromoCodeResponseStatusEnum[keyof typeof PromoCodeResponseStatusEnum];


/**
 * Check if a given object implements the PromoCodeResponse interface.
 */
export function instanceOfPromoCodeResponse(value: object): value is PromoCodeResponse {
    if (!('ongoing_indefinite' in value) || value['ongoing_indefinite'] === undefined) return false;
    return true;
}

export function PromoCodeResponseFromJSON(json: any): PromoCodeResponse {
    return PromoCodeResponseFromJSONTyped(json, false);
}

export function PromoCodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromoCodeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'promo_code': json['promo_code'] == null ? undefined : json['promo_code'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'type': json['type'] == null ? undefined : json['type'],
        'message': json['message'] == null ? undefined : json['message'],
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'first_order_only': json['first_order_only'] == null ? undefined : json['first_order_only'],
        'one_time_use': json['one_time_use'] == null ? undefined : json['one_time_use'],
        'status': json['status'] == null ? undefined : json['status'],
        'minimum_cart_value': json['minimum_cart_value'] == null ? undefined : json['minimum_cart_value'],
        'promo_code_creator': json['promo_code_creator'] == null ? undefined : json['promo_code_creator'],
        'ongoing_indefinite': json['ongoing_indefinite'],
        'published': json['published'] == null ? undefined : json['published'],
    };
}

export function PromoCodeResponseToJSON(json: any): PromoCodeResponse {
    return PromoCodeResponseToJSONTyped(json, false);
}

export function PromoCodeResponseToJSONTyped(value?: PromoCodeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'promo_code': value['promo_code'],
        'amount': value['amount'],
        'type': value['type'],
        'message': value['message'],
        'start_date': value['start_date'] == null ? undefined : ((value['start_date']).toISOString().substring(0,10)),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString().substring(0,10)),
        'first_order_only': value['first_order_only'],
        'one_time_use': value['one_time_use'],
        'status': value['status'],
        'minimum_cart_value': value['minimum_cart_value'],
        'promo_code_creator': value['promo_code_creator'],
        'ongoing_indefinite': value['ongoing_indefinite'],
        'published': value['published'],
    };
}

