import FormApi from '../../services/api/formApi.js'

const FormApiService = new FormApi()

export default {
  data() {
    return {
      email: '',
      isUserLogged: false,
      isSubmitted: false
    }
  },

  methods: {
    redirect,
    handleSubmit
  }
}

function redirect(link, type) {
  if (type === 'EXTERNAL') {
    window.open(link, '_blank')
  } else {
    this.$router.push(link)
  }
}

async function handleSubmit() {
  try {
    const valid = await this.$validator.validate()
    if (!valid) return

    await FormApiService.submitHubspotEmail({
      fields: [{ name: 'email', value: this.email }],
      pageName: [{ name: 'footer', value: 'footer' }],
      url: { name: 'url', value: window.location.href }
    })
    this.errors.clear()
    this.isSubmitted = true
    this.toast({
      type: 'success',
      title: 'Success',
      message: 'You have successfully subscribed!'
    })
  } catch (error) {
    this.$handleError(this, error)
  }
}
