/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Associate Product with Boards object
 * @export
 * @interface AssociateProductWithBoardsRequest
 */
export interface AssociateProductWithBoardsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssociateProductWithBoardsRequest
     */
    product_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssociateProductWithBoardsRequest
     */
    board_ids?: Array<string>;
}

/**
 * Check if a given object implements the AssociateProductWithBoardsRequest interface.
 */
export function instanceOfAssociateProductWithBoardsRequest(value: object): value is AssociateProductWithBoardsRequest {
    return true;
}

export function AssociateProductWithBoardsRequestFromJSON(json: any): AssociateProductWithBoardsRequest {
    return AssociateProductWithBoardsRequestFromJSONTyped(json, false);
}

export function AssociateProductWithBoardsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssociateProductWithBoardsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'product_id': json['product_id'] == null ? undefined : json['product_id'],
        'board_ids': json['board_ids'] == null ? undefined : json['board_ids'],
    };
}

export function AssociateProductWithBoardsRequestToJSON(json: any): AssociateProductWithBoardsRequest {
    return AssociateProductWithBoardsRequestToJSONTyped(json, false);
}

export function AssociateProductWithBoardsRequestToJSONTyped(value?: AssociateProductWithBoardsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_id': value['product_id'],
        'board_ids': value['board_ids'],
    };
}

