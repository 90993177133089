<template>
  <div class="flex align-center">
    <bulletin-button
      v-if="isRole('RETAILER') || forceRetailerView"
      class="re-order-button"
      variant="primary"
      to="/reorder?page=1"
      small-padding
      :mouse-over="() => hideNavBarPopup()"
      no-min-width
      @click.native="trackNavigationEvent('Reorder', 'Reorder')"
    >
      Reorder
    </bulletin-button>

    <div v-if="isRole('ADMIN')" class="flex align-center">
      <profile-icon />
      <Cart />
    </div>
    <div v-if="isRole('RETAILER') || forceRetailerView" class="flex align-center">
      <div class="relative">
        <profile-icon :mouse-over="() => showNavBarPopup('ACCOUNT')" />
        <the-account-popup v-if="visibleNavBarPopup === 'ACCOUNT'" :close-popup="hideNavBarPopup" />
      </div>

      <div @mouseover="hideNavBarPopup">
        <router-link :to="'/favorites'" data-test-id="all-favorites-link">
          <favorite-icon />
        </router-link>
      </div>

      <Cart :force-retailer-view="forceRetailerView" />
    </div>

    <div v-if="(isRole('BRAND') || isRole('PRODUCT_BUILDER')) && !forceRetailerView" class="flex align-center">
      <profile-icon />
    </div>

    <div v-if="user === null" class="hide-md">
      <bulletin-button
        class="inline-flex mx-10 auth-button"
        size="sm"
        variant="tertiary"
        @click="$store.dispatch('navigation/setDropdownType', 'sign-in')"
      >
        Login
      </bulletin-button>

      <bulletin-button
        class="inline-flex mx-10 auth-button"
        size="sm"
        variant="primary"
        @click="$store.dispatch('navigation/setDropdownType', 'sign-up')"
      >
        Join us
      </bulletin-button>
    </div>
    <Icon
      icon-key="hamburger"
      hover-type="OPACITY"
      :on-click="handleHamburgerMenuClick"
      class="show-md hide inline-flex"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Cart from '@/components/shared/cart/cart.vue'
import TheAccountPopup from './TheAccountPopup'
import ProfileIcon from './ProfileIcon.vue'
import { navigationHelper } from '../mixin/navigationHelper'
import FavoriteIcon from './FavoriteIcon'
import { recommendationsApi } from '@/services/api/recommendationsApi'

export default {
  name: 'UserControls',
  components: { FavoriteIcon, TheAccountPopup, Cart, ProfileIcon },
  mixins: [navigationHelper],
  props: {
    forceRetailerView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      role: this.$session.getRole(),
      visibleNavBarPopup: null,
      recommendedBrandIds: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState',
      sidebar: 'navigation/sidebar'
    })
  },
  watch: {
    user: function (user) {
      if (user?.retailer_id) {
        this.getRecommendedBrandIds(user.retailer_id)
      } else {
        this.recommendedBrandIds = []
      }
    }
  },
  methods: {
    handleHamburgerMenuClick() {
      this.$store.dispatch('navigation/toggleSideBar')
      if (this.$route.path.includes('dashboard')) {
        this.$eventBus.$emit('toggle-dashboard-sidebar')
      }
    },
    hideNavBarPopup() {
      this.visibleNavBarPopup = null
    },
    showNavBarPopup(popupKey) {
      if (this.user?.role === 'RETAILER') {
        this.visibleNavBarPopup = popupKey
      }
    },
    isRole(role) {
      return this.user?.role === role
    },
    async getRecommendedBrandIds(retailerId) {
      try {
        const { data } = await recommendationsApi.fetchRecommendedBrands(retailerId)
        const { brand_ids } = data || {}
        this.recommendedBrandIds = brand_ids || []
      } catch (error) {
        this.$logger?.notify(new Error(error))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/base/mixins.scss';

.auth-button {
  min-width: 100px !important;
  letter-spacing: 2.08px !important;
}

.re-order-button {
  margin-left: 16px;
  margin-right: 16px;

  @include respond-to(medium) {
    display: none !important;
  }
}
</style>
