/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Attach card request
 * @export
 * @interface UpdateSuborderCreditCardRequest
 */
export interface UpdateSuborderCreditCardRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSuborderCreditCardRequest
     */
    token_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSuborderCreditCardRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSuborderCreditCardRequest
     */
    last_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSuborderCreditCardRequest
     */
    make_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSuborderCreditCardRequest
     */
    save_card: boolean;
}

/**
 * Check if a given object implements the UpdateSuborderCreditCardRequest interface.
 */
export function instanceOfUpdateSuborderCreditCardRequest(value: object): value is UpdateSuborderCreditCardRequest {
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('make_default' in value) || value['make_default'] === undefined) return false;
    if (!('save_card' in value) || value['save_card'] === undefined) return false;
    return true;
}

export function UpdateSuborderCreditCardRequestFromJSON(json: any): UpdateSuborderCreditCardRequest {
    return UpdateSuborderCreditCardRequestFromJSONTyped(json, false);
}

export function UpdateSuborderCreditCardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSuborderCreditCardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'token_id': json['token_id'] == null ? undefined : json['token_id'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'make_default': json['make_default'],
        'save_card': json['save_card'],
    };
}

export function UpdateSuborderCreditCardRequestToJSON(json: any): UpdateSuborderCreditCardRequest {
    return UpdateSuborderCreditCardRequestToJSONTyped(json, false);
}

export function UpdateSuborderCreditCardRequestToJSONTyped(value?: UpdateSuborderCreditCardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token_id': value['token_id'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'make_default': value['make_default'],
        'save_card': value['save_card'],
    };
}

