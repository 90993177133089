/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuborderItemResponse
 */
export interface SuborderItemResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderItemResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemResponse
     */
    fulfilled_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemResponse
     */
    ordered_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemResponse
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemResponse
     */
    product_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemResponse
     */
    product_image?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderItemResponse
     */
    sku?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SuborderItemResponse
     */
    characteristic_option_map?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof SuborderItemResponse
     */
    shipped_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemResponse
     */
    returnable_quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderItemResponse
     */
    added_subsequently?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SuborderItemResponse
     */
    total_suborder_item_price?: number;
}

/**
 * Check if a given object implements the SuborderItemResponse interface.
 */
export function instanceOfSuborderItemResponse(value: object): value is SuborderItemResponse {
    return true;
}

export function SuborderItemResponseFromJSON(json: any): SuborderItemResponse {
    return SuborderItemResponseFromJSONTyped(json, false);
}

export function SuborderItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderItemResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'fulfilled_quantity': json['fulfilled_quantity'] == null ? undefined : json['fulfilled_quantity'],
        'ordered_quantity': json['ordered_quantity'] == null ? undefined : json['ordered_quantity'],
        'price': json['price'] == null ? undefined : json['price'],
        'product_name': json['product_name'] == null ? undefined : json['product_name'],
        'product_image': json['product_image'] == null ? undefined : json['product_image'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'characteristic_option_map': json['characteristic_option_map'] == null ? undefined : json['characteristic_option_map'],
        'shipped_quantity': json['shipped_quantity'] == null ? undefined : json['shipped_quantity'],
        'returnable_quantity': json['returnable_quantity'] == null ? undefined : json['returnable_quantity'],
        'added_subsequently': json['added_subsequently'] == null ? undefined : json['added_subsequently'],
        'total_suborder_item_price': json['total_suborder_item_price'] == null ? undefined : json['total_suborder_item_price'],
    };
}

export function SuborderItemResponseToJSON(json: any): SuborderItemResponse {
    return SuborderItemResponseToJSONTyped(json, false);
}

export function SuborderItemResponseToJSONTyped(value?: SuborderItemResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'fulfilled_quantity': value['fulfilled_quantity'],
        'ordered_quantity': value['ordered_quantity'],
        'price': value['price'],
        'product_name': value['product_name'],
        'product_image': value['product_image'],
        'sku': value['sku'],
        'characteristic_option_map': value['characteristic_option_map'],
        'shipped_quantity': value['shipped_quantity'],
        'returnable_quantity': value['returnable_quantity'],
        'added_subsequently': value['added_subsequently'],
        'total_suborder_item_price': value['total_suborder_item_price'],
    };
}

