/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Features } from './Features';
import {
    FeaturesFromJSON,
    FeaturesFromJSONTyped,
    FeaturesToJSON,
    FeaturesToJSONTyped,
} from './Features';

/**
 * 
 * @export
 * @interface TradeShowEnabledFeaturesResponse
 */
export interface TradeShowEnabledFeaturesResponse {
    /**
     * 
     * @type {Features}
     * @memberof TradeShowEnabledFeaturesResponse
     */
    features?: Features;
}

/**
 * Check if a given object implements the TradeShowEnabledFeaturesResponse interface.
 */
export function instanceOfTradeShowEnabledFeaturesResponse(value: object): value is TradeShowEnabledFeaturesResponse {
    return true;
}

export function TradeShowEnabledFeaturesResponseFromJSON(json: any): TradeShowEnabledFeaturesResponse {
    return TradeShowEnabledFeaturesResponseFromJSONTyped(json, false);
}

export function TradeShowEnabledFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowEnabledFeaturesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'features': json['features'] == null ? undefined : FeaturesFromJSON(json['features']),
    };
}

export function TradeShowEnabledFeaturesResponseToJSON(json: any): TradeShowEnabledFeaturesResponse {
    return TradeShowEnabledFeaturesResponseToJSONTyped(json, false);
}

export function TradeShowEnabledFeaturesResponseToJSONTyped(value?: TradeShowEnabledFeaturesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'features': FeaturesToJSON(value['features']),
    };
}

