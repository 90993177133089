/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductImageResponse
 */
export interface GetProductImageResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductImageResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductImageResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductImageResponse
     */
    original_url?: string;
    /**
     * 
     * @type {object}
     * @memberof GetProductImageResponse
     */
    crop_params?: object;
    /**
     * 
     * @type {number}
     * @memberof GetProductImageResponse
     */
    ordinal?: number;
}

/**
 * Check if a given object implements the GetProductImageResponse interface.
 */
export function instanceOfGetProductImageResponse(value: object): value is GetProductImageResponse {
    return true;
}

export function GetProductImageResponseFromJSON(json: any): GetProductImageResponse {
    return GetProductImageResponseFromJSONTyped(json, false);
}

export function GetProductImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductImageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'url': json['url'] == null ? undefined : json['url'],
        'original_url': json['original_url'] == null ? undefined : json['original_url'],
        'crop_params': json['crop_params'] == null ? undefined : json['crop_params'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
    };
}

export function GetProductImageResponseToJSON(json: any): GetProductImageResponse {
    return GetProductImageResponseToJSONTyped(json, false);
}

export function GetProductImageResponseToJSONTyped(value?: GetProductImageResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'url': value['url'],
        'original_url': value['original_url'],
        'crop_params': value['crop_params'],
        'ordinal': value['ordinal'],
    };
}

