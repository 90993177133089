/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeShowFeatureResponse
 */
export interface TradeShowFeatureResponse {
    /**
     * 
     * @type {string}
     * @memberof TradeShowFeatureResponse
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeShowFeatureResponse
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeShowFeatureResponse
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof TradeShowFeatureResponse
     */
    start_date_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeShowFeatureResponse
     */
    end_date_time?: Date;
}

/**
 * Check if a given object implements the TradeShowFeatureResponse interface.
 */
export function instanceOfTradeShowFeatureResponse(value: object): value is TradeShowFeatureResponse {
    return true;
}

export function TradeShowFeatureResponseFromJSON(json: any): TradeShowFeatureResponse {
    return TradeShowFeatureResponseFromJSONTyped(json, false);
}

export function TradeShowFeatureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowFeatureResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'active': json['active'] == null ? undefined : json['active'],
        'name': json['name'] == null ? undefined : json['name'],
        'start_date_time': json['start_date_time'] == null ? undefined : (new Date(json['start_date_time'])),
        'end_date_time': json['end_date_time'] == null ? undefined : (new Date(json['end_date_time'])),
    };
}

export function TradeShowFeatureResponseToJSON(json: any): TradeShowFeatureResponse {
    return TradeShowFeatureResponseToJSONTyped(json, false);
}

export function TradeShowFeatureResponseToJSONTyped(value?: TradeShowFeatureResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'active': value['active'],
        'name': value['name'],
        'start_date_time': value['start_date_time'] == null ? undefined : ((value['start_date_time']).toISOString()),
        'end_date_time': value['end_date_time'] == null ? undefined : ((value['end_date_time']).toISOString()),
    };
}

