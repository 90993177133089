/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadCollectionImageRequest
 */
export interface UploadCollectionImageRequest {
    /**
     * Image to upload
     * @type {Blob}
     * @memberof UploadCollectionImageRequest
     */
    image: Blob;
}

/**
 * Check if a given object implements the UploadCollectionImageRequest interface.
 */
export function instanceOfUploadCollectionImageRequest(value: object): value is UploadCollectionImageRequest {
    if (!('image' in value) || value['image'] === undefined) return false;
    return true;
}

export function UploadCollectionImageRequestFromJSON(json: any): UploadCollectionImageRequest {
    return UploadCollectionImageRequestFromJSONTyped(json, false);
}

export function UploadCollectionImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadCollectionImageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'],
    };
}

export function UploadCollectionImageRequestToJSON(json: any): UploadCollectionImageRequest {
    return UploadCollectionImageRequestToJSONTyped(json, false);
}

export function UploadCollectionImageRequestToJSONTyped(value?: UploadCollectionImageRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image': value['image'],
    };
}

