/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocalPart } from './LocalPart';
import {
    LocalPartFromJSON,
    LocalPartFromJSONTyped,
    LocalPartToJSON,
    LocalPartToJSONTyped,
} from './LocalPart';
import type { Additional } from './Additional';
import {
    AdditionalFromJSON,
    AdditionalFromJSONTyped,
    AdditionalToJSON,
    AdditionalToJSONTyped,
} from './Additional';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
    DomainToJSONTyped,
} from './Domain';

/**
 * 
 * @export
 * @interface Checks
 */
export interface Checks {
    /**
     * 
     * @type {Domain}
     * @memberof Checks
     */
    domain: Domain;
    /**
     * 
     * @type {LocalPart}
     * @memberof Checks
     */
    local_part: LocalPart;
    /**
     * 
     * @type {Additional}
     * @memberof Checks
     */
    additional: Additional;
}

/**
 * Check if a given object implements the Checks interface.
 */
export function instanceOfChecks(value: object): value is Checks {
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('local_part' in value) || value['local_part'] === undefined) return false;
    if (!('additional' in value) || value['additional'] === undefined) return false;
    return true;
}

export function ChecksFromJSON(json: any): Checks {
    return ChecksFromJSONTyped(json, false);
}

export function ChecksFromJSONTyped(json: any, ignoreDiscriminator: boolean): Checks {
    if (json == null) {
        return json;
    }
    return {
        
        'domain': DomainFromJSON(json['domain']),
        'local_part': LocalPartFromJSON(json['local_part']),
        'additional': AdditionalFromJSON(json['additional']),
    };
}

export function ChecksToJSON(json: any): Checks {
    return ChecksToJSONTyped(json, false);
}

export function ChecksToJSONTyped(value?: Checks | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'domain': DomainToJSON(value['domain']),
        'local_part': LocalPartToJSON(value['local_part']),
        'additional': AdditionalToJSON(value['additional']),
    };
}

