import restApi from '../../http.js'
import session from '../session.js'

export default class ProductApi {
  createProduct(request) {
    return restApi({
      method: 'POST',
      url: '/products',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  createProductAsProductBuilder(request) {
    return restApi({
      method: 'POST',
      url: '/products/product-builders',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getProduct(id) {
    return restApi({
      method: 'GET',
      url: '/products/' + id
    })
  }

  getSimilarProducts(request) {
    return restApi({
      method: 'GET',
      url: '/products/filter',
      params: {
        product_id: request.product_id,
        brand_id: request.brand_id,
        product_number: request.product_number
      }
    })
  }

  updateProduct(request) {
    return restApi({
      method: 'PUT',
      url: '/products/' + request.id,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateProductAsProductBuilder(request) {
    return restApi({
      method: 'PUT',
      url: `/products/${request.id}/product-builders`,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  addImageToProduct(id, imageFile) {
    return restApi({
      method: 'post',
      url: '/products/' + id + '/images',
      data: imageFile,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  editProductImage(productId, imageId, data) {
    return restApi({
      method: 'PATCH',
      url: `/products/${productId}/images/${imageId}`,
      data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removeImageFromProduct(id) {
    return restApi({
      method: 'delete',
      url: '/products/images/' + id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getProductsBySearchCriteria(request) {
    return restApi({
      method: 'post',
      url: '/products/search-criteria',
      data: {
        include_all_conditions: request.include_all_conditions,
        search_criteria: request.search_criteria
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  assignImageToVariant(request) {
    return restApi({
      method: 'post',
      url: '/products/variants/' + request.variantId + '/image/' + request.imageId,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removeVariantImage(request) {
    return restApi({
      method: 'delete',
      url: '/products/variants/' + request.variantId + '/image',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getCategoriesFromNavigation(active) {
    return restApi({
      method: 'get',
      url: '/categories/navigation',
      params: {
        active: active ? active : false
      }
    })
  }
  importFromCSVAdmin(request) {
    return restApi({
      method: 'POST',
      url: '/products/csv-import',
      data: request,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  importInventoryCSV(request) {
    return restApi({
      method: 'POST',
      url: '/products/import-inventory',
      data: request,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  importFromCSV(request, brandId) {
    let requestUrl = '/products/csv-import/v2?createDraft=true&csvType=bulletin-csv'
    if (brandId) {
      requestUrl = `${requestUrl}&brandId=${brandId}`
    }

    return restApi({
      method: 'POST',
      url: requestUrl,
      data: request,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getCSVTemplate() {
    return restApi({
      method: 'POST',
      url: '/products/download-inventory-csv',
      headers: {
        Accept: 'text/csv'
      }
    })
  }

  validateSku(sku, brandId) {
    const requestData = { sku }
    if (brandId) {
      requestData.brand_id = brandId
    }
    return restApi({
      method: 'POST',
      url: `/products/validate-sku`,
      data: requestData,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
