/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumOptionBalanceLevel } from './EnumOptionBalanceLevel';
import {
    EnumOptionBalanceLevelFromJSON,
    EnumOptionBalanceLevelFromJSONTyped,
    EnumOptionBalanceLevelToJSON,
    EnumOptionBalanceLevelToJSONTyped,
} from './EnumOptionBalanceLevel';

/**
 * 
 * @export
 * @interface GetEnumOptionsResponseBalanceLevel
 */
export interface GetEnumOptionsResponseBalanceLevel {
    /**
     * 
     * @type {Array<EnumOptionBalanceLevel>}
     * @memberof GetEnumOptionsResponseBalanceLevel
     */
    options?: Array<EnumOptionBalanceLevel>;
}

/**
 * Check if a given object implements the GetEnumOptionsResponseBalanceLevel interface.
 */
export function instanceOfGetEnumOptionsResponseBalanceLevel(value: object): value is GetEnumOptionsResponseBalanceLevel {
    return true;
}

export function GetEnumOptionsResponseBalanceLevelFromJSON(json: any): GetEnumOptionsResponseBalanceLevel {
    return GetEnumOptionsResponseBalanceLevelFromJSONTyped(json, false);
}

export function GetEnumOptionsResponseBalanceLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEnumOptionsResponseBalanceLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'] == null ? undefined : ((json['options'] as Array<any>).map(EnumOptionBalanceLevelFromJSON)),
    };
}

export function GetEnumOptionsResponseBalanceLevelToJSON(json: any): GetEnumOptionsResponseBalanceLevel {
    return GetEnumOptionsResponseBalanceLevelToJSONTyped(json, false);
}

export function GetEnumOptionsResponseBalanceLevelToJSONTyped(value?: GetEnumOptionsResponseBalanceLevel | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': value['options'] == null ? undefined : ((value['options'] as Array<any>).map(EnumOptionBalanceLevelToJSON)),
    };
}

