/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountBrandProductsResponse
 */
export interface CountBrandProductsResponse {
    /**
     * 
     * @type {number}
     * @memberof CountBrandProductsResponse
     */
    products_count?: number;
}

/**
 * Check if a given object implements the CountBrandProductsResponse interface.
 */
export function instanceOfCountBrandProductsResponse(value: object): value is CountBrandProductsResponse {
    return true;
}

export function CountBrandProductsResponseFromJSON(json: any): CountBrandProductsResponse {
    return CountBrandProductsResponseFromJSONTyped(json, false);
}

export function CountBrandProductsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountBrandProductsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'products_count': json['products_count'] == null ? undefined : json['products_count'],
    };
}

export function CountBrandProductsResponseToJSON(json: any): CountBrandProductsResponse {
    return CountBrandProductsResponseToJSONTyped(json, false);
}

export function CountBrandProductsResponseToJSONTyped(value?: CountBrandProductsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'products_count': value['products_count'],
    };
}

