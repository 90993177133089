/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomAssortmentItemResponse
 */
export interface CustomAssortmentItemResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentItemResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentItemResponse
     */
    variant_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomAssortmentItemResponse
     */
    quantity?: number;
}

/**
 * Check if a given object implements the CustomAssortmentItemResponse interface.
 */
export function instanceOfCustomAssortmentItemResponse(value: object): value is CustomAssortmentItemResponse {
    return true;
}

export function CustomAssortmentItemResponseFromJSON(json: any): CustomAssortmentItemResponse {
    return CustomAssortmentItemResponseFromJSONTyped(json, false);
}

export function CustomAssortmentItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAssortmentItemResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function CustomAssortmentItemResponseToJSON(json: any): CustomAssortmentItemResponse {
    return CustomAssortmentItemResponseToJSONTyped(json, false);
}

export function CustomAssortmentItemResponseToJSONTyped(value?: CustomAssortmentItemResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'variant_id': value['variant_id'],
        'quantity': value['quantity'],
    };
}

