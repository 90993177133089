<template>
  <div v-if="loading">
    <loader-static class="flex align-center justify-center min-height-300" />
  </div>
  <div v-else>
    <checkbox-new
      v-for="(brand, index) in getFilteredItems(brands, 'name', selectedBrand)"
      :key="index"
      v-model="brand.selected"
      :label="brand.name"
      @click="select(brand)"
    />
  </div>
</template>

<script>
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import { FilterKeys } from '../../../../services/utils'
import { mapGetters } from 'vuex'
import algoliaSearch from 'algoliasearch/lite'

export default {
  mixins: [paramsHelper, filterHelper],
  data() {
    return {
      loading: true,
      allBrands: [],
      selectedBrand: null,
      algoliaClient: algoliaSearch(this.$env.algolia().appId, this.$env.algolia().token, {
        timeouts: {
          connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
          read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
        }
      }),
      filteredBrands: []
    }
  },
  computed: {
    ...mapGetters({
      categoryBrandIds: 'filters/categoryBrandIds',
      searchTermBrandIds: 'filters/searchTermBrandIds'
    }),
    filterMode() {
      return this.$route.path.includes('/products') || this.$route.query.keyword
    },
    brands() {
      if (this.filterMode) {
        return this.filteredBrands
      }
      return this.allBrands || []
    }
  },
  watch: {
    '$route.query.brands': function () {
      this.parseSelectedValues()
      this.transformListEmitFilterSelection()
    },
    '$route.query.keyword': async function (keyword) {
      if (!keyword) {
        await this.getBrands()
      }
    },
    categoryBrandIds: function (categoryBrandIds) {
      if (categoryBrandIds && categoryBrandIds.length) {
        this.getBrandsFromAlgolia(categoryBrandIds)
      }
    },
    searchTermBrandIds: function (data) {
      this.getBrandsFromAlgolia(data?.brandIds || [])
    }
  },
  async beforeMount() {
    if (!this.filterMode) {
      await this.getBrands()
    }

    this.parseSelectedValues()
    this.transformListEmitFilterSelection()
  },
  beforeDestroy() {
    this.$store.commit('filters/setSearchTermBrandIds', null)
  },
  methods: {
    select,
    getBrands,
    clearSearch,
    setSelectedBrand,
    transformListEmitFilterSelection,
    parseSelectedValues,
    getBrandsFromAlgolia
  }
}

function setSelectedBrand() {
  this.selectedBrand = this.brands.find((item) => item.id === this.$route.query.brand)
  this.selectedBrand.selected = true
  if (this.selectedBrand) {
    this.setParentSearchValue(this.selectedBrand.name)
  }
  this.updateParentSelectionCount(this.brands)
}
function clearSearch() {
  this.updateURLParams('brand', false)
}

function select(brand) {
  brand.selected = !brand.selected
  this.updateFilterURLParams(
    FilterKeys.BRANDS,
    this.brands?.filter((item) => item.selected).map((item) => item.id)
  )
  this.updateParentSelectionCount(this.brands)
  this.transformListEmitFilterSelection()
  this.$forceUpdate()
  this.trackFilterUsedEvent('brand')
}

function transformListEmitFilterSelection() {
  this.emitFilterSelection({
    filterKey: FilterKeys.BRANDS,
    items: this.brands.map(({ name, id, selected }) => {
      return { label: name, value: id, selected }
    })
  })
}

function parseSelectedValues() {
  const selectedBrands = this.$utils.arrayify(this.$route.query.brands)
  this.brands.forEach((item) => (item.selected = false))
  this.brands.forEach((item) => {
    if (selectedBrands?.includes(item.id)) {
      item.selected = true
    }
  })
  this.$forceUpdate()
}

async function getBrands() {
  this.loading = true
  const { data } = await this.$utils.getAllBrands()
  this.allBrands = data?.brands_dropdown?.filter((item) => item.name).sort((a, b) => a.name.localeCompare(b.name)) || []
  this.loading = false
}

async function getBrandsFromAlgolia(brandIds) {
  const selectedBrands = this.$utils.arrayify(this.$route.query.brands)
  const alreadySelectedBrands = this.brands?.filter((item) => item.selected).map((item) => item.id) || []

  if (!brandIds || !brandIds.length) {
    this.filteredBrands = []
    this.loading = false
    return
  }

  try {
    this.loading = true
    const brandsIndex = this.$env.getOrigin() + '_brands'
    const { results } = await this.algoliaClient.initIndex(brandsIndex).getObjects(brandIds)
    this.filteredBrands =
      results
        .map((item) => {
          return {
            ...item,
            name: item.brand_name,
            selected: alreadySelectedBrands?.includes(item.id) || selectedBrands?.includes(item.id)
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    this.loading = false
  } catch (error) {
    this.loading = false
    this.$logger.notify(new Error(error))
  }
}
</script>
