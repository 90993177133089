/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentsPagination } from './PaymentsPagination';
import {
    PaymentsPaginationFromJSON,
    PaymentsPaginationFromJSONTyped,
    PaymentsPaginationToJSON,
    PaymentsPaginationToJSONTyped,
} from './PaymentsPagination';
import type { GetDashboardPaymentsResponse } from './GetDashboardPaymentsResponse';
import {
    GetDashboardPaymentsResponseFromJSON,
    GetDashboardPaymentsResponseFromJSONTyped,
    GetDashboardPaymentsResponseToJSON,
    GetDashboardPaymentsResponseToJSONTyped,
} from './GetDashboardPaymentsResponse';

/**
 * 
 * @export
 * @interface PaginatedPaymentsResponse
 */
export interface PaginatedPaymentsResponse {
    /**
     * 
     * @type {Array<GetDashboardPaymentsResponse>}
     * @memberof PaginatedPaymentsResponse
     */
    hits?: Array<GetDashboardPaymentsResponse>;
    /**
     * 
     * @type {PaymentsPagination}
     * @memberof PaginatedPaymentsResponse
     */
    pagination?: PaymentsPagination;
}

/**
 * Check if a given object implements the PaginatedPaymentsResponse interface.
 */
export function instanceOfPaginatedPaymentsResponse(value: object): value is PaginatedPaymentsResponse {
    return true;
}

export function PaginatedPaymentsResponseFromJSON(json: any): PaginatedPaymentsResponse {
    return PaginatedPaymentsResponseFromJSONTyped(json, false);
}

export function PaginatedPaymentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedPaymentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(GetDashboardPaymentsResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : PaymentsPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedPaymentsResponseToJSON(json: any): PaginatedPaymentsResponse {
    return PaginatedPaymentsResponseToJSONTyped(json, false);
}

export function PaginatedPaymentsResponseToJSONTyped(value?: PaginatedPaymentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(GetDashboardPaymentsResponseToJSON)),
        'pagination': PaymentsPaginationToJSON(value['pagination']),
    };
}

