import vClickOutside from 'v-click-outside'

export default {
  inject: ['$validator'],

  props: {
    searchable: {
      type: Boolean,
      required: false
    },
    showChips: {
      type: Boolean,
      default: false
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    isTableView: {
      type: Boolean,
      default: false
    },
    dropdownItems: {
      type: Array
    },
    subArrayName: {
      type: String
    },
    dropdownParentIndex: {
      type: Number
    },
    dropdownIndex: {
      type: Number
    },
    placeholder: {
      type: String
    },
    onSelect: {
      type: Function
    },
    unselect: {
      type: Function
    },
    selectedName: {
      type: String
    },
    isRequired: {
      type: Boolean
    },
    name: {
      type: String
    },
    modelName: {
      type: String
    },
    canReset: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    resetOnDestroy: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      query: '',
      selected: null,
      isOpen: false,
      items: [],
      multiSelected: [],
      limit: 3,
      selectionText: null
    }
  },

  watch: {
    dropdownItems: function (val) {
      // remove duplicates
      this.items = [...new Set(val)]
      if (this.isMultiSelect) {
        this.items.forEach((item) => {
          item.view_components.forEach((subItem) => {
            if (subItem.selected === true) {
              // if this item is not already in the list
              if (!this.multiSelected.find((selectedItem) => selectedItem.entity_id === subItem.entity_id)) {
                this.multiSelected.push(subItem)
              }
            }
          })
        })
      }
    },

    selectedName: function (val) {
      this.selected = val
    },
    multiSelected: function (val) {
      if (this.disabled) {
        return
      }

      const maxMessage = this.isTableView ? 'Max Added' : 'Max Categories Added. Remove to Add.'
      const select = val.length === 1 || val.length === 2 ? `${val.length} selected` : ''
      const selectMessage = this.isTableView ? select : 'Maximum of 3'
      const atLimit = val.length === this.limit
      this.selected = atLimit ? maxMessage : selectMessage
    }
  },

  methods: {
    toggleDropdown: toggleDropdown,
    closeDropdown: closeDropdown,
    pick: pick,
    reset,
    unselectCategory,
    setReadonlySelectionText
  },

  destroyed() {
    if (this.resetOnDestroy) {
      this.reset()
    }
  },

  mounted() {
    this.items = this.dropdownItems === undefined ? [] : this.dropdownItems
    if (this.isMultiSelect) {
      this.selected = !this.isTableView ? 'Maximum of 3' : ''
      this.items.forEach((parent) => {
        parent.view_components.forEach((child) => {
          if (child.selected === true) {
            this.multiSelected.push(child)
          }
        })
      })
      this.setReadonlySelectionText()
    } else {
      this.selected = this.selectedName ? this.selectedName : ''
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  computed: {
    filteredData,
    computedItems
  }
}

function filteredData() {
  const array = []

  this.computedItems.forEach((item) => {
    if (item.name.toLowerCase().match(this.query.toLowerCase())) {
      array.push(item)
    } else {
      item[this.subArrayName].forEach((subitem) => {
        if (subitem.info.text.toLowerCase().match(this.query.toLowerCase())) {
          array.push(item)
        }
      })
    }
  })

  return array
}

function pick(item, groupIndex, subitemIndex) {
  if (this.isMultiSelect) {
    const ids = this.multiSelected.map((item) => item.entity_id)
    if (ids.includes(item.entity_id)) {
      this.multiSelected = this.multiSelected.filter((id) => id.entity_id !== item.entity_id)
      // eslint-disable-next-line no-magic-numbers
    } else if (this.multiSelected.length >= 3) {
      return
    } else {
      this.multiSelected.push(item)
    }
  }
  this.onSelect(item, this.dropdownIndex, this.dropdownParentIndex, this.modelName)
  if (this.isMultiSelect) {
    this.filteredData[groupIndex][this.subArrayName][subitemIndex].selected =
      !this.filteredData[groupIndex][this.subArrayName][subitemIndex].selected
    this.$forceUpdate()
  } else {
    const selectedItem = {
      ...item
    }
    this.selected = selectedItem.info.text
    this.closeDropdown()
  }
}

function toggleDropdown() {
  if (this.items.length) {
    this.isOpen = !this.isOpen
  }
}

function closeDropdown() {
  this.isOpen = false
}

function computedItems() {
  return this.items.reduce((containers, container) => {
    return [
      ...containers,
      ...(container.container_direction === 'GRID'
        ? container.children_containers.map((_container) => {
            return {
              ..._container,
              name: `${container.name} (${_container.name})`
            }
          })
        : [container])
    ]
  }, [])
}

function reset() {
  this.selected = ''
  this.closeDropdown()
  this.onSelect(null)
  if (this.isMultiSelect) {
    this.multiSelected = []
    this.items = this.items.map((parent) => {
      parent.view_components.map((child) => ({
        ...child,
        ...(child.selected = false)
      }))
      return parent
    })
  }
}

function unselectCategory(id) {
  this.items = this.items.map((parent) => {
    parent.view_components.map((child) => ({
      ...child,
      ...(child.entity_id === id && (child.selected = false))
    }))
    return parent
  })
  this.multiSelected = this.multiSelected.filter((catId) => catId.entity_id !== id)
  this.unselect(id)
}

function setReadonlySelectionText() {
  if (this.disabled) {
    this.selected = `Products present in ${this.multiSelected.length} categories`
  }
}
