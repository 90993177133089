/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandReorderResponse
 */
export interface BrandReorderResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandReorderResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandReorderResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandReorderResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandReorderResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandReorderResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandReorderResponse
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandReorderResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandReorderResponse
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BrandReorderResponse
     */
    buy_on_bulletin?: boolean;
}

/**
 * Check if a given object implements the BrandReorderResponse interface.
 */
export function instanceOfBrandReorderResponse(value: object): value is BrandReorderResponse {
    return true;
}

export function BrandReorderResponseFromJSON(json: any): BrandReorderResponse {
    return BrandReorderResponseFromJSONTyped(json, false);
}

export function BrandReorderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandReorderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'name': json['name'] == null ? undefined : json['name'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'image': json['image'] == null ? undefined : json['image'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'buy_on_bulletin': json['buy_on_bulletin'] == null ? undefined : json['buy_on_bulletin'],
    };
}

export function BrandReorderResponseToJSON(json: any): BrandReorderResponse {
    return BrandReorderResponseToJSONTyped(json, false);
}

export function BrandReorderResponseToJSONTyped(value?: BrandReorderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'vanity_url_name': value['vanity_url_name'],
        'name': value['name'],
        'first_name': value['first_name'],
        'email': value['email'],
        'image': value['image'],
        'min_lead_time': value['min_lead_time'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'buy_on_bulletin': value['buy_on_bulletin'],
    };
}

