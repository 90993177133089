/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Set trade show attendee password object
 * @export
 * @interface SetTradeShowAttendeePasswordRequest
 */
export interface SetTradeShowAttendeePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof SetTradeShowAttendeePasswordRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SetTradeShowAttendeePasswordRequest
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetTradeShowAttendeePasswordRequest
     */
    bulletin_marketing_opt_in: boolean;
}

/**
 * Check if a given object implements the SetTradeShowAttendeePasswordRequest interface.
 */
export function instanceOfSetTradeShowAttendeePasswordRequest(value: object): value is SetTradeShowAttendeePasswordRequest {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('bulletin_marketing_opt_in' in value) || value['bulletin_marketing_opt_in'] === undefined) return false;
    return true;
}

export function SetTradeShowAttendeePasswordRequestFromJSON(json: any): SetTradeShowAttendeePasswordRequest {
    return SetTradeShowAttendeePasswordRequestFromJSONTyped(json, false);
}

export function SetTradeShowAttendeePasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetTradeShowAttendeePasswordRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'password': json['password'],
        'bulletin_marketing_opt_in': json['bulletin_marketing_opt_in'],
    };
}

export function SetTradeShowAttendeePasswordRequestToJSON(json: any): SetTradeShowAttendeePasswordRequest {
    return SetTradeShowAttendeePasswordRequestToJSONTyped(json, false);
}

export function SetTradeShowAttendeePasswordRequestToJSONTyped(value?: SetTradeShowAttendeePasswordRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': value['code'],
        'password': value['password'],
        'bulletin_marketing_opt_in': value['bulletin_marketing_opt_in'],
    };
}

