<template>
  <div>
    <brand-board-create-edit
      v-if="isCreateNew"
      :on-back="toggleCreateNew"
      :on-close="closeFavorite"
      :on-success-callback="getAllBoards"
    />
    <div v-else>
      <div
        class="favorite--overlay"
        :class="{
          'overlay-in-animation': animate === 'IN',
          'overlay-out-animation': animate === 'OUT'
        }"
        @click="delayClose"
      ></div>
      <div
        class="favorite-wrapper flex flex-direction-column z-22"
        :class="{
          'favorite-in-animation': animate === 'IN',
          'favorite-out-animation': animate === 'OUT'
        }"
      >
        <div class="favorite--header">
          <div class="flex items-center justify-end m-15">
            <Icon icon-key="close" hover-type="OPACITY" data-test-id="brand_board_close_icon" :on-click="delayClose" />
          </div>
          <div class="favorite--body-container">
            <!-- SCENARIO:: NO BOARDS -->
            <div v-if="displayNoContent" class="flex flex-direction-column">
              <heading4 class="mb-10">Bulletin Boards</heading4>
              <span class="body-copy-1 mb-10">
                Get started with your first board! Curate a selection of items that you want to highlight, and share it
                directly with your buyers or display it on your profile page.
              </span>
              <bulletin-button
                data-test-id="no_brand_board_create"
                class="half-width"
                variant="primary"
                @click="toggleCreateNew"
              >
                <span>Create Board</span>
              </bulletin-button>
            </div>
            <div v-else class="flex flex-direction-column">
              <div v-if="initialyLoaded">
                <div class="flex justify-between items-center mb-10">
                  <heading4>Edit Boards</heading4>
                  <div class="flex items-center">
                    <Icon icon-key="plus" :size="16" class="mr-3" :on-click="toggleCreateNew" />
                    <span
                      data-test-id="favorite_product_create"
                      class="body-copy-1 text-decoration-underline-grey pointer"
                      @click="toggleCreateNew"
                    >
                      Create New
                    </span>
                  </div>
                </div>
                <div class="flex">
                  <search-input-field v-model="searchValue" class="flex-grow-1 mb-20" placeholder="Search Boards" />
                  <sorting-options
                    style="width: 10%"
                    hide-label
                    :options="dateSortingOptions"
                    no-pagination
                    no-tracking
                    :local-sort="sortingValue"
                    :set-local-sort="setSortingValue"
                  />
                </div>
                <div v-if="isLoading" class="flex items-center justify-center mt-40">
                  <loader-static size="40px" />
                </div>
                <template v-else>
                  <brand-board-item-card
                    v-for="(board, index) in allBoards"
                    :id="board.id"
                    :key="index"
                    :data-test-id="`brand_board_card_${index}`"
                    :image="board.thumbnail_image_urls?.[0]"
                    :name="board.name"
                    :visibility="board.visibility"
                    :on-click="() => handleCheckBoard(board.id)"
                    :checked="isChecked(board.id)"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="favorite--footer">
          <bulletin-button
            :disabled="disableSaveButton"
            data-test-id="brand_boards_save"
            full-width
            variant="primary"
            :loading="isSaving"
            @click="handleSaveToBoard"
          >
            <span>Save</span>
          </bulletin-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as vClickOutsideDirective } from 'v-click-outside'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import BrandBoardCreateEdit from './brandBoardCreateEdit/BrandBoardCreateEdit'
import BrandBoardItemCard from './brandBoardItemCard/BrandBoardItemCard'
import { BrandBoardApi } from '@/api'
import _debounce from 'lodash/debounce'

export default {
  name: 'BrandBoardDrawer',
  components: { BrandBoardCreateEdit, BrandBoardItemCard },
  directives: {
    clickOutside: vClickOutsideDirective
  },
  mixins: [paramsHelper],
  props: ['productId', 'onClose', 'onSaveToBoard'],
  data() {
    return {
      isCreateNew: false,
      allBoards: [],
      originalySaved: [],
      isLoading: false,
      initialyLoaded: false,
      dateSortingOptions: this.$utils.constants.DATE_SORTING_OPTIONS,
      searchValue: '',
      sortingValue: 'DESC',
      animate: 'IN',
      displayNoContent: false,
      selectedIds: [],
      isSaving: false
    }
  },
  computed: {
    boardIdsByContainedProduct() {
      return this.allBoards.filter((board) => board.contains_product).map((item) => item.id)
    },
    totalBoardsToUpdate() {
      return this.selectedIds.length
    },
    disableSaveButton() {
      return JSON.stringify([...this.originalySaved].sort()) === JSON.stringify([...this.selectedIds].sort())
    }
  },
  watch: {
    sortingValue: function () {
      this.getAllBoards()
    },
    searchValue: _debounce(async function () {
      await this.getAllBoards()
    }, 250)
  },
  beforeMount() {
    this.getAllBoards()
  },
  methods: {
    getAllBoards,
    handleSaveToBoard,
    closeFavorite() {
      this.onClose()
      this.isCreateNew = false
    },
    toggleCreateNew() {
      this.animate = ''
      this.isCreateNew = !this.isCreateNew
    },
    handleCheckBoard(boardId) {
      if (this.selectedIds.includes(boardId)) {
        this.selectedIds = this.selectedIds.filter((item) => item !== boardId)
      } else {
        this.selectedIds = [...this.selectedIds, boardId]
      }
    },
    isChecked(boardId) {
      return this.selectedIds.includes(boardId)
    },
    setSortingValue,
    delayClose
  }
}

async function getAllBoards(newlyCreatedId) {
  try {
    this.isLoading = true
    this.allBoards = []
    const brandId = this.$session.getUserInfo().brand_id
    const { brand_boards } = await BrandBoardApi.getBrandBoards({
      brandId: brandId,
      page: 0,
      size: 100,
      sortBy: 'creationDate',
      productId: this.productId,
      sortOrder: this.sortingValue,
      search: this.searchValue
    })
    if (!this.searchValue) {
      this.displayNoContent = brand_boards.length === 0
    }
    this.allBoards = brand_boards
    this.originalySaved = this.boardIdsByContainedProduct

    if (this.selectedIds.length === 0) {
      this.selectedIds = this.boardIdsByContainedProduct
    }
    if (newlyCreatedId) {
      this.selectedIds = [...this.selectedIds, newlyCreatedId]
    }
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    if (!this.initialyLoaded) this.initialyLoaded = true
    this.isLoading = false
  }
}

async function handleSaveToBoard() {
  try {
    this.isSaving = true
    await BrandBoardApi.addProductToBrandBoards({
      id: this.productId,
      addProductToBrandBoardsRequest: {
        brand_board_ids: this.selectedIds
      }
    })
    this.toast({
      type: 'success',
      title: 'Success',
      message:
        this.totalBoardsToUpdate > 0
          ? `Saved to ${this.totalBoardsToUpdate} board${this.totalBoardsToUpdate > 1 ? 's' : ''}`
          : 'Removed from all boards',
      actionName: 'Go to Boards',
      action: () => this.$router.push('/dashboard/brand-boards')
    })
    if (this.onSaveToBoard) {
      await this.onSaveToBoard()
    }
    this.onClose()
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    this.isSaving = false
  }
}

function setSortingValue(value) {
  this.sortingValue = value
}

function delayClose() {
  this.animate = 'OUT'
  setTimeout(() => {
    this.closeFavorite()
  }, 400)
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
.border-b-gray {
  border-bottom: 1px solid $color-grey-3 !important;
}
</style>
