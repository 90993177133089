/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddressResponse,
  ApplyToBuyRequest,
  ApplyToBuyResponse,
  DropdownItem,
  GetEnumOptionsResponseBalanceLevel,
  GetEnumOptionsResponseRetailerInformedFrom,
  GetEnumOptionsResponseRetailerStatus,
  GetEnumOptionsResponseRetailerStoreType,
  GetStripeCustomerIdResponse,
  HubSpotRetailerSignUpRequest,
  IdWrapper,
  OrderCountResponse,
  PaginatedRetailersApplicationsResponse,
  PaginatedRetailersDirectoryResponse,
  PaymentSourceResponse,
  PurchasedBrandResponse,
  RetailerBalanceResponse,
  RetailerCreditResponse,
  RetailerResponse,
  SendVerificationEmailsRequest,
  UpdateRetailerAccountRequest,
  UpdateRetailerStatusRequest,
  UploadFileResponse,
  UploadRetailerThumbnailImageRequest,
} from '../models/index';
import {
    AddressResponseFromJSON,
    AddressResponseToJSON,
    ApplyToBuyRequestFromJSON,
    ApplyToBuyRequestToJSON,
    ApplyToBuyResponseFromJSON,
    ApplyToBuyResponseToJSON,
    DropdownItemFromJSON,
    DropdownItemToJSON,
    GetEnumOptionsResponseBalanceLevelFromJSON,
    GetEnumOptionsResponseBalanceLevelToJSON,
    GetEnumOptionsResponseRetailerInformedFromFromJSON,
    GetEnumOptionsResponseRetailerInformedFromToJSON,
    GetEnumOptionsResponseRetailerStatusFromJSON,
    GetEnumOptionsResponseRetailerStatusToJSON,
    GetEnumOptionsResponseRetailerStoreTypeFromJSON,
    GetEnumOptionsResponseRetailerStoreTypeToJSON,
    GetStripeCustomerIdResponseFromJSON,
    GetStripeCustomerIdResponseToJSON,
    HubSpotRetailerSignUpRequestFromJSON,
    HubSpotRetailerSignUpRequestToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    OrderCountResponseFromJSON,
    OrderCountResponseToJSON,
    PaginatedRetailersApplicationsResponseFromJSON,
    PaginatedRetailersApplicationsResponseToJSON,
    PaginatedRetailersDirectoryResponseFromJSON,
    PaginatedRetailersDirectoryResponseToJSON,
    PaymentSourceResponseFromJSON,
    PaymentSourceResponseToJSON,
    PurchasedBrandResponseFromJSON,
    PurchasedBrandResponseToJSON,
    RetailerBalanceResponseFromJSON,
    RetailerBalanceResponseToJSON,
    RetailerCreditResponseFromJSON,
    RetailerCreditResponseToJSON,
    RetailerResponseFromJSON,
    RetailerResponseToJSON,
    SendVerificationEmailsRequestFromJSON,
    SendVerificationEmailsRequestToJSON,
    UpdateRetailerAccountRequestFromJSON,
    UpdateRetailerAccountRequestToJSON,
    UpdateRetailerStatusRequestFromJSON,
    UpdateRetailerStatusRequestToJSON,
    UploadFileResponseFromJSON,
    UploadFileResponseToJSON,
    UploadRetailerThumbnailImageRequestFromJSON,
    UploadRetailerThumbnailImageRequestToJSON,
} from '../models/index';

export interface AdminApproveRetailerRequest {
    retailerId: string;
}

export interface AdminDeclineRetailerRequest {
    retailerId: string;
}

export interface ApplyToBuyOperationRequest {
    applyToBuyRequest: ApplyToBuyRequest;
}

export interface CreateNewHubSpotRetailerRequest {
    hubSpotRetailerSignUpRequest: HubSpotRetailerSignUpRequest;
}

export interface DeleteRetailerRequest {
    id: string;
}

export interface GetAddressesRequest {
    shipping?: boolean;
    billing?: boolean;
}

export interface GetPurchasedBrandsRequest {
    id: string;
}

export interface GetRetailer1Request {
    id: string;
}

export interface GetRetailerCreditCardsByIdRequest {
    id: string;
}

export interface GetRetailerUsedBalanceRequest {
    id: string;
}

export interface GetRetailersApplicationsRequest {
    searchBy?: string;
    page?: number;
    size?: number;
}

export interface GetRetailersDirectoryRequest {
    searchBy?: string;
    page?: number;
    size?: number;
}

export interface GetRetailersDropdownRequest {
    ifNoneMatch?: string;
}

export interface ResendVerificationMailRequest {
    id: string;
}

export interface SendVerificationEmailsToRetailersByCountriesRequest {
    sendVerificationEmailsRequest: SendVerificationEmailsRequest;
}

export interface UpdateMyAccountRequest {
    updateRetailerAccountRequest: UpdateRetailerAccountRequest;
}

export interface UpdateRetailerAccountOperationRequest {
    id: string;
    updateRetailerAccountRequest: UpdateRetailerAccountRequest;
}

export interface UpdateRetailerStatusOperationRequest {
    retailerId: string;
    updateRetailerStatusRequest: UpdateRetailerStatusRequest;
}

export interface UploadRetailerThumbnailImageOperationRequest {
    uploadRetailerThumbnailImageRequest?: UploadRetailerThumbnailImageRequest;
}

/**
 * 
 */
export class RetailerControllerApi extends runtime.BaseAPI {

    /**
     * Approve retailer by admin
     */
    async adminApproveRetailerRaw(requestParameters: AdminApproveRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['retailerId'] == null) {
            throw new runtime.RequiredError(
                'retailerId',
                'Required parameter "retailerId" was null or undefined when calling adminApproveRetailer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{retailerId}/approve`.replace(`{${"retailerId"}}`, encodeURIComponent(String(requestParameters['retailerId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve retailer by admin
     */
    async adminApproveRetailer(requestParameters: AdminApproveRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.adminApproveRetailerRaw(requestParameters, initOverrides);
    }

    /**
     * Decline retailer by admin
     */
    async adminDeclineRetailerRaw(requestParameters: AdminDeclineRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['retailerId'] == null) {
            throw new runtime.RequiredError(
                'retailerId',
                'Required parameter "retailerId" was null or undefined when calling adminDeclineRetailer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{retailerId}/decline`.replace(`{${"retailerId"}}`, encodeURIComponent(String(requestParameters['retailerId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decline retailer by admin
     */
    async adminDeclineRetailer(requestParameters: AdminDeclineRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.adminDeclineRetailerRaw(requestParameters, initOverrides);
    }

    /**
     * Apply to buy
     */
    async applyToBuyRaw(requestParameters: ApplyToBuyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplyToBuyResponse>> {
        if (requestParameters['applyToBuyRequest'] == null) {
            throw new runtime.RequiredError(
                'applyToBuyRequest',
                'Required parameter "applyToBuyRequest" was null or undefined when calling applyToBuy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/apply-to-buy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyToBuyRequestToJSON(requestParameters['applyToBuyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplyToBuyResponseFromJSON(jsonValue));
    }

    /**
     * Apply to buy
     */
    async applyToBuy(requestParameters: ApplyToBuyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplyToBuyResponse> {
        const response = await this.applyToBuyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Retailer using HubSpot form
     */
    async createNewHubSpotRetailerRaw(requestParameters: CreateNewHubSpotRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['hubSpotRetailerSignUpRequest'] == null) {
            throw new runtime.RequiredError(
                'hubSpotRetailerSignUpRequest',
                'Required parameter "hubSpotRetailerSignUpRequest" was null or undefined when calling createNewHubSpotRetailer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/hub-spot/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HubSpotRetailerSignUpRequestToJSON(requestParameters['hubSpotRetailerSignUpRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create a new Retailer using HubSpot form
     */
    async createNewHubSpotRetailer(requestParameters: CreateNewHubSpotRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createNewHubSpotRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete retailer
     */
    async deleteRetailerRaw(requestParameters: DeleteRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteRetailer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete retailer
     */
    async deleteRetailer(requestParameters: DeleteRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRetailerRaw(requestParameters, initOverrides);
    }

    /**
     * Find retailer address
     */
    async getAddressesRaw(requestParameters: GetAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddressResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['shipping'] != null) {
            queryParameters['shipping'] = requestParameters['shipping'];
        }

        if (requestParameters['billing'] != null) {
            queryParameters['billing'] = requestParameters['billing'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressResponseFromJSON));
    }

    /**
     * Find retailer address
     */
    async getAddresses(requestParameters: GetAddressesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddressResponse>> {
        const response = await this.getAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all balance levels
     */
    async getBalanceLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEnumOptionsResponseBalanceLevel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/balance-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnumOptionsResponseBalanceLevelFromJSON(jsonValue));
    }

    /**
     * Get all balance levels
     */
    async getBalanceLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEnumOptionsResponseBalanceLevel> {
        const response = await this.getBalanceLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get retailer\'s billing addresses
     */
    async getBillingAddressesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddressResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/addresses/billing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressResponseFromJSON));
    }

    /**
     * Get retailer\'s billing addresses
     */
    async getBillingAddresses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddressResponse>> {
        const response = await this.getBillingAddressesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all options for how did you hear for us on retailer sign up
     */
    async getInformedFromOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEnumOptionsResponseRetailerInformedFrom>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/informed-from-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnumOptionsResponseRetailerInformedFromFromJSON(jsonValue));
    }

    /**
     * Get all options for how did you hear for us on retailer sign up
     */
    async getInformedFromOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEnumOptionsResponseRetailerInformedFrom> {
        const response = await this.getInformedFromOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get retailer
     */
    async getMe1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetailerResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetailerResponseFromJSON(jsonValue));
    }

    /**
     * Get retailer
     */
    async getMe1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetailerResponse> {
        const response = await this.getMe1Raw(initOverrides);
        return await response.value();
    }

    /**
     * Find retailer Id
     */
    async getMyIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/my-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Find retailer Id
     */
    async getMyId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.getMyIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get retailer\'s purchased brands
     */
    async getPurchasedBrandsRaw(requestParameters: GetPurchasedBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PurchasedBrandResponse>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPurchasedBrands().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{id}/purchased-brands`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PurchasedBrandResponseFromJSON));
    }

    /**
     * Get retailer\'s purchased brands
     */
    async getPurchasedBrands(requestParameters: GetPurchasedBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PurchasedBrandResponse>> {
        const response = await this.getPurchasedBrandsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get retailer
     */
    async getRetailer1Raw(requestParameters: GetRetailer1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetailerResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRetailer1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetailerResponseFromJSON(jsonValue));
    }

    /**
     * Get retailer
     */
    async getRetailer1(requestParameters: GetRetailer1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetailerResponse> {
        const response = await this.getRetailer1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get count of retailer\'s orders
     */
    async getRetailerCountOfOrdersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderCountResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/orders-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderCountResponseFromJSON(jsonValue));
    }

    /**
     * Get count of retailer\'s orders
     */
    async getRetailerCountOfOrders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderCountResponse> {
        const response = await this.getRetailerCountOfOrdersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Obtain Retailer\'s saved credit cards
     */
    async getRetailerCreditCardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentSourceResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/credit-cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentSourceResponseFromJSON));
    }

    /**
     * Obtain Retailer\'s saved credit cards
     */
    async getRetailerCreditCards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentSourceResponse>> {
        const response = await this.getRetailerCreditCardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Obtain Retailer\'s saved credit cards by Id
     */
    async getRetailerCreditCardsByIdRaw(requestParameters: GetRetailerCreditCardsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentSourceResponse>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRetailerCreditCardsById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{id}/credit-cards`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentSourceResponseFromJSON));
    }

    /**
     * Obtain Retailer\'s saved credit cards by Id
     */
    async getRetailerCreditCardsById(requestParameters: GetRetailerCreditCardsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentSourceResponse>> {
        const response = await this.getRetailerCreditCardsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all retailer statuses
     */
    async getRetailerStatusesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEnumOptionsResponseRetailerStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnumOptionsResponseRetailerStatusFromJSON(jsonValue));
    }

    /**
     * Get all retailer statuses
     */
    async getRetailerStatuses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEnumOptionsResponseRetailerStatus> {
        const response = await this.getRetailerStatusesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all balance levels
     */
    async getRetailerStoreTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEnumOptionsResponseRetailerStoreType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/store-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnumOptionsResponseRetailerStoreTypeFromJSON(jsonValue));
    }

    /**
     * Get all balance levels
     */
    async getRetailerStoreTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEnumOptionsResponseRetailerStoreType> {
        const response = await this.getRetailerStoreTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get retailer balance
     */
    async getRetailerUsedBalanceRaw(requestParameters: GetRetailerUsedBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetailerCreditResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRetailerUsedBalance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/balance/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetailerCreditResponseFromJSON(jsonValue));
    }

    /**
     * Get retailer balance
     */
    async getRetailerUsedBalance(requestParameters: GetRetailerUsedBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetailerCreditResponse> {
        const response = await this.getRetailerUsedBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all retailer values
     */
    async getRetailerValuesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DropdownItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/values/dropdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DropdownItemFromJSON));
    }

    /**
     * Returns all retailer values
     */
    async getRetailerValues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DropdownItem>> {
        const response = await this.getRetailerValuesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all Retailer applicants per multiple/custom criteria
     */
    async getRetailersApplicationsRaw(requestParameters: GetRetailersApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRetailersApplicationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRetailersApplicationsResponseFromJSON(jsonValue));
    }

    /**
     * Find all Retailer applicants per multiple/custom criteria
     */
    async getRetailersApplications(requestParameters: GetRetailersApplicationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRetailersApplicationsResponse> {
        const response = await this.getRetailersApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all Retailer applicants per multiple/custom criteria
     */
    async getRetailersDirectoryRaw(requestParameters: GetRetailersDirectoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRetailersDirectoryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/directory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRetailersDirectoryResponseFromJSON(jsonValue));
    }

    /**
     * Find all Retailer applicants per multiple/custom criteria
     */
    async getRetailersDirectory(requestParameters: GetRetailersDirectoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRetailersDirectoryResponse> {
        const response = await this.getRetailersDirectoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get retailers for dropdown
     */
    async getRetailersDropdownRaw(requestParameters: GetRetailersDropdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['ifNoneMatch'] != null) {
            headerParameters['If-None-Match'] = String(requestParameters['ifNoneMatch']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/dropdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get retailers for dropdown
     */
    async getRetailersDropdown(requestParameters: GetRetailersDropdownRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getRetailersDropdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get retailers with a balance greater than zero.
     */
    async getRetailersWithBalanceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RetailerBalanceResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/balance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RetailerBalanceResponseFromJSON));
    }

    /**
     * Get retailers with a balance greater than zero.
     */
    async getRetailersWithBalance(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RetailerBalanceResponse>> {
        const response = await this.getRetailersWithBalanceRaw(initOverrides);
        return await response.value();
    }

    /**
     * Obtain Retailer\'s Stripe Customer Id token. This api will return null if token is not yet created.
     */
    async getStripeCustomerIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStripeCustomerIdResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/stripe-customer-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStripeCustomerIdResponseFromJSON(jsonValue));
    }

    /**
     * Obtain Retailer\'s Stripe Customer Id token. This api will return null if token is not yet created.
     */
    async getStripeCustomerId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStripeCustomerIdResponse> {
        const response = await this.getStripeCustomerIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * Resends verification mail to given retailer
     */
    async resendVerificationMailRaw(requestParameters: ResendVerificationMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling resendVerificationMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{id}/resend-verification-mail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resends verification mail to given retailer
     */
    async resendVerificationMail(requestParameters: ResendVerificationMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendVerificationMailRaw(requestParameters, initOverrides);
    }

    /**
     * Send verification emails to retailers from given countries
     */
    async sendVerificationEmailsToRetailersByCountriesRaw(requestParameters: SendVerificationEmailsToRetailersByCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sendVerificationEmailsRequest'] == null) {
            throw new runtime.RequiredError(
                'sendVerificationEmailsRequest',
                'Required parameter "sendVerificationEmailsRequest" was null or undefined when calling sendVerificationEmailsToRetailersByCountries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/send-verification-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendVerificationEmailsRequestToJSON(requestParameters['sendVerificationEmailsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send verification emails to retailers from given countries
     */
    async sendVerificationEmailsToRetailersByCountries(requestParameters: SendVerificationEmailsToRetailersByCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendVerificationEmailsToRetailersByCountriesRaw(requestParameters, initOverrides);
    }

    /**
     * Update retailer account by user id
     */
    async updateMyAccountRaw(requestParameters: UpdateMyAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateRetailerAccountRequest'] == null) {
            throw new runtime.RequiredError(
                'updateRetailerAccountRequest',
                'Required parameter "updateRetailerAccountRequest" was null or undefined when calling updateMyAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/me/account`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRetailerAccountRequestToJSON(requestParameters['updateRetailerAccountRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update retailer account by user id
     */
    async updateMyAccount(requestParameters: UpdateMyAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMyAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Update retailer account
     */
    async updateRetailerAccountRaw(requestParameters: UpdateRetailerAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateRetailerAccount().'
            );
        }

        if (requestParameters['updateRetailerAccountRequest'] == null) {
            throw new runtime.RequiredError(
                'updateRetailerAccountRequest',
                'Required parameter "updateRetailerAccountRequest" was null or undefined when calling updateRetailerAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{id}/account`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRetailerAccountRequestToJSON(requestParameters['updateRetailerAccountRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update retailer account
     */
    async updateRetailerAccount(requestParameters: UpdateRetailerAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRetailerAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Update retailer status - ACTIVE, SUSPENDED, BANNED
     */
    async updateRetailerStatusRaw(requestParameters: UpdateRetailerStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['retailerId'] == null) {
            throw new runtime.RequiredError(
                'retailerId',
                'Required parameter "retailerId" was null or undefined when calling updateRetailerStatus().'
            );
        }

        if (requestParameters['updateRetailerStatusRequest'] == null) {
            throw new runtime.RequiredError(
                'updateRetailerStatusRequest',
                'Required parameter "updateRetailerStatusRequest" was null or undefined when calling updateRetailerStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/{retailerId}/status`.replace(`{${"retailerId"}}`, encodeURIComponent(String(requestParameters['retailerId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRetailerStatusRequestToJSON(requestParameters['updateRetailerStatusRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update retailer status - ACTIVE, SUSPENDED, BANNED
     */
    async updateRetailerStatus(requestParameters: UpdateRetailerStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRetailerStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Upload retailer\'s thumbnail image
     */
    async uploadRetailerThumbnailImageRaw(requestParameters: UploadRetailerThumbnailImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadFileResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/retailers/thumbnail-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadRetailerThumbnailImageRequestToJSON(requestParameters['uploadRetailerThumbnailImageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadFileResponseFromJSON(jsonValue));
    }

    /**
     * Upload retailer\'s thumbnail image
     */
    async uploadRetailerThumbnailImage(requestParameters: UploadRetailerThumbnailImageOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadFileResponse> {
        const response = await this.uploadRetailerThumbnailImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
