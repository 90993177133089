/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeShowAttendeeProvince
 */
export interface TradeShowAttendeeProvince {
    /**
     * 
     * @type {string}
     * @memberof TradeShowAttendeeProvince
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeShowAttendeeProvince
     */
    code?: string;
}

/**
 * Check if a given object implements the TradeShowAttendeeProvince interface.
 */
export function instanceOfTradeShowAttendeeProvince(value: object): value is TradeShowAttendeeProvince {
    return true;
}

export function TradeShowAttendeeProvinceFromJSON(json: any): TradeShowAttendeeProvince {
    return TradeShowAttendeeProvinceFromJSONTyped(json, false);
}

export function TradeShowAttendeeProvinceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeShowAttendeeProvince {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'code': json['code'] == null ? undefined : json['code'],
    };
}

export function TradeShowAttendeeProvinceToJSON(json: any): TradeShowAttendeeProvince {
    return TradeShowAttendeeProvinceToJSONTyped(json, false);
}

export function TradeShowAttendeeProvinceToJSONTyped(value?: TradeShowAttendeeProvince | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'code': value['code'],
    };
}

