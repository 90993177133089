/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandFromBrandBoardResponse } from './BrandFromBrandBoardResponse';
import {
    BrandFromBrandBoardResponseFromJSON,
    BrandFromBrandBoardResponseFromJSONTyped,
    BrandFromBrandBoardResponseToJSON,
    BrandFromBrandBoardResponseToJSONTyped,
} from './BrandFromBrandBoardResponse';
import type { ProductFromBrandBoardResponse } from './ProductFromBrandBoardResponse';
import {
    ProductFromBrandBoardResponseFromJSON,
    ProductFromBrandBoardResponseFromJSONTyped,
    ProductFromBrandBoardResponseToJSON,
    ProductFromBrandBoardResponseToJSONTyped,
} from './ProductFromBrandBoardResponse';

/**
 * 
 * @export
 * @interface GetBrandBoardDetailsResponse
 */
export interface GetBrandBoardDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardDetailsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardDetailsResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardDetailsResponse
     */
    visibility?: GetBrandBoardDetailsResponseVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetBrandBoardDetailsResponse
     */
    show_on_profile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardDetailsResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandBoardDetailsResponse
     */
    product_count?: number;
    /**
     * 
     * @type {BrandFromBrandBoardResponse}
     * @memberof GetBrandBoardDetailsResponse
     */
    brand?: BrandFromBrandBoardResponse;
    /**
     * 
     * @type {Array<ProductFromBrandBoardResponse>}
     * @memberof GetBrandBoardDetailsResponse
     */
    products?: Array<ProductFromBrandBoardResponse>;
}


/**
 * @export
 */
export const GetBrandBoardDetailsResponseVisibilityEnum = {
    Bulletin: 'BULLETIN',
    Public: 'PUBLIC'
} as const;
export type GetBrandBoardDetailsResponseVisibilityEnum = typeof GetBrandBoardDetailsResponseVisibilityEnum[keyof typeof GetBrandBoardDetailsResponseVisibilityEnum];


/**
 * Check if a given object implements the GetBrandBoardDetailsResponse interface.
 */
export function instanceOfGetBrandBoardDetailsResponse(value: object): value is GetBrandBoardDetailsResponse {
    return true;
}

export function GetBrandBoardDetailsResponseFromJSON(json: any): GetBrandBoardDetailsResponse {
    return GetBrandBoardDetailsResponseFromJSONTyped(json, false);
}

export function GetBrandBoardDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandBoardDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'visibility': json['visibility'] == null ? undefined : json['visibility'],
        'show_on_profile': json['show_on_profile'] == null ? undefined : json['show_on_profile'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'product_count': json['product_count'] == null ? undefined : json['product_count'],
        'brand': json['brand'] == null ? undefined : BrandFromBrandBoardResponseFromJSON(json['brand']),
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductFromBrandBoardResponseFromJSON)),
    };
}

export function GetBrandBoardDetailsResponseToJSON(json: any): GetBrandBoardDetailsResponse {
    return GetBrandBoardDetailsResponseToJSONTyped(json, false);
}

export function GetBrandBoardDetailsResponseToJSONTyped(value?: GetBrandBoardDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'visibility': value['visibility'],
        'show_on_profile': value['show_on_profile'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'product_count': value['product_count'],
        'brand': BrandFromBrandBoardResponseToJSON(value['brand']),
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductFromBrandBoardResponseToJSON)),
    };
}

