<template>
  <div>
    <div v-for="brandValue in getFilteredItems(brandValues, 'label')" :key="brandValue.value" class="mb-5">
      <checkbox-new v-model="brandValue.selected" :label="brandValue.label" @click="handleClick(brandValue)" />
    </div>
  </div>
</template>

<script>
import BrandApi from '@/services/api/brandApi'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import { FilterKeys } from '../../../../services/utils'

const BrandApiService = new BrandApi()

export default {
  mixins: [paramsHelper, filterHelper],
  data() {
    return {
      brandValues: [],
      selected: []
    }
  },
  watch: {
    '$route.query.special_services': function (query) {
      this.parseSelection(query)
      this.emitFilterSelection({
        filterKey: FilterKeys.SPECIAL_SERVICES,
        items: this.brandValues
      })
    }
  },
  beforeMount() {
    this.getBrandValues()
  },
  methods: {
    async getBrandValues() {
      let { data } = await BrandApiService.getSpecialServices()

      this.brandValues = data
      this.parseSelection(this.$route.query.special_services)

      this.emitFilterSelection({
        filterKey: FilterKeys.SPECIAL_SERVICES,
        items: this.brandValues
      })
    },
    handleClick(data) {
      data.selected = !data.selected
      const selectedBrandValues = this.brandValues.filter((obj) => obj.selected).map((obj) => obj.value)
      this.trackFilterUsedEvent('special services')
      this.updateFilterURLParams(FilterKeys.SPECIAL_SERVICES, selectedBrandValues)
      this.emitFilterSelection({
        filterKey: FilterKeys.SPECIAL_SERVICES.toString(),
        items: this.brandValues
      })
    },
    parseSelection(brandValuesQuery) {
      this.brandValues = this.brandValues.map((brandValue) => {
        const isSelected = brandValuesQuery === undefined ? false : brandValuesQuery?.includes(brandValue.value)
        return {
          ...brandValue,
          ...{ selected: isSelected }
        }
      })
    }
  }
}
</script>
