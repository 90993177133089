<template>
  <div class="variant-picker pt-40">
    <div v-if="variants.length > 1" class="characteristic-list">
      <div v-if="showMainOutOfStockList()" class="main-out-of-stock-list flex flex-direction-column mb-25">
        <p>
          {{
            `${getFullyOutOfStockOptions().join(', ')} ${
              getFullyOutOfStockOptions().length === 1 ? 'is' : 'are'
            } completely out of stock.`
          }}
        </p>
      </div>

      <div v-for="groupKey in getFilteredCharacteristicsList()" :key="groupKey" class="characteristic-list-item mb-25">
        <h4 class="font-size-12 mb-10">
          {{ groupKey }}
        </h4>
        <div class="characteristic-options">
          <div
            v-for="option in optionsByCharacteristic[groupKey]"
            :key="option"
            class="characteristic-option inline-block"
            :class="{
              selected: isOptionSelected(groupKey, option),
              'out-of-stock': isFullyOutOfStock(groupKey, option) || isOutOfStockOnSelection(groupKey, option),
              fully: isFullyOutOfStock(groupKey, option)
            }"
            @click="setSelectionByGroup(groupKey, option)"
          >
            {{ option }}
          </div>
        </div>
        <div class="mb-15 mt-15">
          <div v-for="item in getOutOfStockFromGroup(groupKey)" :key="item.id" class="font-size-14">
            {{ item }}
          </div>
        </div>
      </div>

      <!--    Render single size option as preselected-->
      <div v-if="hasOnlyOneSize" class="characteristic-list">
        <div class="characteristic-list-item mb-25">
          <h4 class="font-size-12 mb-10">Size</h4>
          <div class="characteristic-options">
            <div class="characteristic-option inline-block selected disabled">
              {{ getOriginalOptionTextFromQuantityGroup(optionsByCharacteristic['size'][0]) }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="quantityGroupKey" class="quantities-by-group">
        <h4 class="font-size-12">
          {{ getQuantityInputGroupSectionTitle() }}
        </h4>
        <p v-if="increment > 1" class="font-size-14 mt-10">Enter multiples of {{ increment }}:</p>
        <div class="grid-12 mt-10">
          <div
            v-for="option in optionsByCharacteristic[quantityGroupKey]"
            :key="option"
            class="quantity-by-group span-6"
          >
            <div class="flex align-center">
              <div
                v-if="isFullyOutOfStock(quantityGroupKey, option) || isOutOfStockOnSelection(quantityGroupKey, option)"
                class="quantity-input out-of-stock"
                :class="{ fully: isFullyOutOfStock(quantityGroupKey, option) }"
              />
              <input
                v-else
                v-model="selectionByGroup[quantityGroupKey][option]"
                type="number"
                class="quantity-input"
                min="0"
                :step="increment"
                :class="{ 'green-bg': selectionByGroup[quantityGroupKey][option] > 0 }"
                @focus="() => onQuantityInputFocus(option)"
                @blur="() => onQuantityInputBlur(option)"
              />
              <div
                class="option-label"
                :class="{
                  'out-of-stock fully': isFullyOutOfStock(quantityGroupKey, option)
                }"
              >
                {{ hasOnlyOneSize ? '' : getOriginalOptionTextFromQuantityGroup(option) }}
              </div>
            </div>
          </div>
        </div>

        <div class="mb-15 mt-15">
          <div v-for="item in getOutOfStockFromGroup(quantityGroupKey)" :key="item.id" class="font-size-14">
            {{ item }}
          </div>
          <div v-if="showMinimumQuantityWarning" class="font-size-14 quantity-warning">
            You need to add at least {{ minimum }} of this item.
          </div>
        </div>
      </div>
    </div>

    <!-- Listing pre-selected (unmodifiable) option if only one variant exists -->
    <div v-if="variants.length === 1" class="characteristic-list">
      <template v-for="groupKey in getFilteredCharacteristicsList()">
        <div :key="groupKey" class="characteristic-list-item mb-25">
          <h4 class="font-size-12 mb-10">
            {{ groupKey }}
          </h4>
          <div class="characteristic-options">
            <div
              v-for="option in optionsByCharacteristic[groupKey]"
              :key="option"
              class="characteristic-option inline-block selected disabled"
            >
              {{ groupKey === 'size' ? getOriginalOptionTextFromQuantityGroup(option) : option }}
            </div>
          </div>
        </div>
      </template>
    </div>

    <!--    Single "quantity" mode with input stepper arrows-->
    <div v-if="!quantityGroupKey" class="single-option-picker">
      <h4 class="font-size-12">QUANTITY</h4>

      <single-variant-picker
        v-model="singleQuantityInputValue"
        class="mt-10"
        :decrease-quantity="decreaseSingleQuantity"
        :disable-decrease-button="disableDecreaseButton(singleQuantityInputValue, minimum)"
        :increase-quantity="increaseSingleQuantity"
        :increment="Number(increment)"
        :quantity-input-blur="onSingleQuantityInputBlur"
      />
    </div>

    <div v-if="inventoryWarnings.length" class="mb-15 mt-15">
      <div v-for="(variant, index) in inventoryWarnings" :key="index" class="font-size-14 quantity-warning">
        {{ getInventoryWarning(variant) }}
      </div>
    </div>
  </div>
</template>

<script>
import { variantPickerHelper } from './variantPickerHelper'

export default {
  mixins: [variantPickerHelper],
  props: {
    variants: {
      type: Array,
      default: () => {
        return []
      }
    },
    characteristics: {
      type: Array,
      default: () => {
        return []
      }
    },
    characteristicOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    minimum: {
      type: Number,
      default: 1
    },
    increment: {
      type: Number,
      default: 1
    },
    trackInventory: {
      type: Boolean,
      default: false
    },
    changeSelection: {
      type: Function,
      default: () => {}
    },
    pickVariantByPartialSelection: {
      type: Function,
      default: () => {}
    }
  },
  data: () => {
    return {
      singleQuantityInputValue: null,
      singleQuantityMode: true,
      hasSizeCharacteristic: false,
      selectionByGroup: null,
      quantityGroupKey: 'size',
      testWatch: null,
      fullyOutOfStockOptionsByGroup: null,
      outOfStockItems: [],
      showMinimumQuantityWarning: false,
      hasOnlyOneSize: false,
      inventoryWarnings: []
    }
  },
  computed: {
    optionsByCharacteristic: function () {
      const groups = {}
      Object.keys(this.characteristicOptions).forEach((optionKey) => {
        groups[optionKey] = this.characteristicOptions[optionKey].map((item) => item?.toLowerCase())
      })
      return groups
    },
    outOfStockVariants: function () {
      return this.variants
        .filter((item) => !item.in_stock)
        .map((variant) => {
          Object.keys(variant.option).forEach((key) => {
            variant.option[key] = variant.option[key]?.toLowerCase()
          })
          return variant
        })
    }
  },
  watch: {
    selectionByGroup: {
      handler: watchSelectionChange,
      deep: true
    },
    singleQuantityInputValue: {
      handler: watchSingleQuantityInputValue
    }
  },
  beforeMount() {
    this.initPickerMode()
    this.initSelection()
    this.initUnavailableOptions()
  },
  methods: {
    watchSingleQuantityInputValue,
    watchSelectionChange,
    initPickerMode,
    initSelection,
    getFilteredCharacteristicsList,
    increaseSingleQuantity,
    decreaseSingleQuantity,
    setSelectionByGroup,
    isOptionSelected,
    isSelectionValid,
    filterVariantsBySelection,
    findSelectedVariants,
    initUnavailableOptions,
    isFullyOutOfStock,
    setOutOfStockOnSelection,
    hasAnySelection,
    getFullyOutOfStockOptions,
    showMainOutOfStockList,
    checkIfVariantIsOutOfStockOnSelection,
    isVariantFullyOutOfStockOnQuantityGroup,
    updateOutOfStockList,
    getOutOfStockFromGroup,
    clearSelection,
    isOutOfStockOnSelection,
    setMinimumQuantityWarningVisibility,
    getOriginalOptionTextFromQuantityGroup,
    onQuantityInputFocus,
    onQuantityInputBlur,
    emitSelection,
    emitPartialSelection,
    validateIncrement,
    getQuantityInputGroupSectionTitle,
    onSingleQuantityInputBlur,
    getInventoryWarning
  }
}

function initPickerMode() {
  this.singleQuantityInputValue = this.minimum || 1
  this.singleQuantityMode = this.variants?.length === 1

  if (this.singleQuantityMode) {
    this.emitSelection([{ variant: this.variants[0], quantity: this.singleQuantityInputValue }])
  }

  this.hasSizeCharacteristic = Boolean(this.characteristics.find((item) => item === 'size')) && !this.singleQuantityMode

  this.hasOnlyOneSize =
    Boolean(this.characteristics.find((item) => item === 'size')) && this.characteristicOptions['size']?.length === 1

  if (!this.hasSizeCharacteristic) {
    this.quantityGroupKey = null
  }
}

function initSelection() {
  const selection = { ...this.selectionByGroup }
  this.characteristics.forEach((characteristicKey) => {
    if (characteristicKey === this.quantityGroupKey) {
      const quantityOptions = {}
      this.optionsByCharacteristic[characteristicKey].forEach((option) => {
        if (characteristicKey === 'size' && this.hasOnlyOneSize) {
          quantityOptions[option] = this.minimum
        } else {
          quantityOptions[option] = 0
        }
      })
      selection[characteristicKey] = quantityOptions
    } else {
      selection[characteristicKey] = null
    }
  })
  this.selectionByGroup = selection

  this.$forceUpdate()
}

function clearSelection() {
  if (!this.quantityGroupKey) {
    this.singleQuantityInputValue = this.minimum || 1

    if (this.variants?.length === 1) {
      this.emitSelection([{ variant: this.variants[0], quantity: this.singleQuantityInputValue }])
    }
  }

  this.selectionByGroup = null
  this.initSelection()
}

function initUnavailableOptions() {
  this.fullyOutOfStockOptionsByGroup = {}
  Object.keys(this.optionsByCharacteristic).forEach((groupKey) => {
    this.optionsByCharacteristic[groupKey].forEach((option) => {
      const found = !!this.variants.find((variant) => {
        return variant.option[groupKey]?.toLowerCase() === option && variant.in_stock
      })

      if (!found) {
        this.fullyOutOfStockOptionsByGroup[groupKey] = [...(this.fullyOutOfStockOptionsByGroup[groupKey] || []), option]
      }
    })
  })
}

function isFullyOutOfStock(groupKey, option) {
  return this.fullyOutOfStockOptionsByGroup[groupKey]?.includes(option)
}

function hasAnySelection() {
  return Boolean(
    Object.keys(this.selectionByGroup).filter((selectionGroupKey) => {
      if (selectionGroupKey !== this.quantityGroupKey) {
        return this.selectionByGroup[selectionGroupKey]
      }
      return Object.keys(this.selectionByGroup[selectionGroupKey])?.filter((optionKey) =>
        Number(this.selectionByGroup[selectionGroupKey][optionKey])
      ).length
    })?.length
  )
}

function updateOutOfStockList() {
  const outOfStockItems = []
  Object.keys(this.optionsByCharacteristic).forEach((groupKey) => {
    this.optionsByCharacteristic[groupKey].forEach((option) => {
      const variants = this.setOutOfStockOnSelection(groupKey, option, true)
      if (this.setOutOfStockOnSelection(groupKey, option)) {
        outOfStockItems.push({ variants, option, groupKey })
      }
    })
  })
  this.outOfStockItems = outOfStockItems
}

function getOutOfStockFromGroup(groupKey, noMiddleText) {
  return this.outOfStockItems
    .filter((item) => item.groupKey === groupKey)
    .map((item) => {
      let activeSelectionKeyInQuantityGroup = null
      const activeSelectionKeys = Object.keys(this.selectionByGroup).filter((key) => {
        if (key === this.quantityGroupKey) {
          Object.keys(this.selectionByGroup[key]).forEach((option) => {
            const hasValue = Number(this.selectionByGroup[key][option])
            const optionMatchOnVariant = item.variants.find((variant) => variant.option[key] === option)
            if (hasValue && optionMatchOnVariant) {
              activeSelectionKeyInQuantityGroup = option
            }
          })
        } else {
          return this.selectionByGroup[key] && key !== groupKey
        }
      })

      let selectedOptions = []
      activeSelectionKeys.forEach((selectionKey) => {
        if (this.selectionByGroup[selectionKey]) {
          selectedOptions.push(this.$utils.capitalize(this.selectionByGroup[selectionKey]))
        }
      })

      if (activeSelectionKeyInQuantityGroup) {
        selectedOptions.push(this.getOriginalOptionTextFromQuantityGroup(activeSelectionKeyInQuantityGroup))
      }

      let selectedOptionsText = null
      if (selectedOptions.length) {
        selectedOptionsText = selectedOptions.join(' - ')
      }

      const outOfStockIn =
        groupKey === this.quantityGroupKey
          ? this.getOriginalOptionTextFromQuantityGroup(item.variants[0].option[groupKey])
          : this.$utils.capitalize(item.variants[0].option[groupKey])

      const middleText = noMiddleText ? ' - ' : ' is out of stock in '
      return `${selectedOptionsText}${middleText}${outOfStockIn}`
    })
}

function isOutOfStockOnSelection(groupKey, option) {
  return this.outOfStockItems.find((item) => item.groupKey === groupKey && item.option === option)
}

function setOutOfStockOnSelection(groupKey, option, returnWithVariants) {
  if (!this.hasAnySelection()) {
    return
  }

  // 1.) filter by selection, 2. )filter by fully out of stock variants
  let filteredVariants = this.outOfStockVariants
    ?.filter((variant) => {
      return Boolean(
        Object.keys(this.selectionByGroup).filter((selectionGroupKey) => {
          if (selectionGroupKey === this.quantityGroupKey) {
            return Object.keys(this.selectionByGroup[selectionGroupKey])
              .filter((key) => Number(this.selectionByGroup[selectionGroupKey][key]))
              .includes(variant.option[selectionGroupKey])
          }
          return variant.option[selectionGroupKey] === this.selectionByGroup[selectionGroupKey]
        })?.length
      )
    })
    .filter((variant) => {
      const found = Object.keys(variant.option).find((optionGroupKey) => {
        return this.fullyOutOfStockOptionsByGroup[optionGroupKey]?.includes(variant.option[optionGroupKey])
      })
      return !found
    })

  const isVariantFullyOutOfStockOnQuantityGroup = this.isVariantFullyOutOfStockOnQuantityGroup(
    filteredVariants,
    groupKey,
    option
  )

  const variants = filteredVariants?.filter((variant) => {
    if (groupKey === this.quantityGroupKey) {
      return (
        variant.option[groupKey]?.toLowerCase() === option &&
        !Number(this.selectionByGroup[groupKey][option]) &&
        this.checkIfVariantIsOutOfStockOnSelection(this.quantityGroupKey, variant)
      )
    }

    return (
      variant.option[groupKey]?.toLowerCase() === option &&
      this.selectionByGroup[groupKey] !== option &&
      (this.checkIfVariantIsOutOfStockOnSelection(groupKey, variant) || isVariantFullyOutOfStockOnQuantityGroup)
    )
  })

  return returnWithVariants ? variants : variants?.length
}

function isVariantFullyOutOfStockOnQuantityGroup(filteredVariants, groupKey, option) {
  if (!this.quantityGroupKey || !Object.keys(this.fullyOutOfStockOptionsByGroup)?.length) {
    return false
  }

  if (this.selectionByGroup[groupKey] !== option) {
    const outOfStockOptionFromQuantityGroupWithCurrent = [
      ...(this.fullyOutOfStockOptionsByGroup[this.quantityGroupKey] || []),
      ...filteredVariants
        .filter((item) => item.option[groupKey] === option)
        .map((item) => item.option[this.quantityGroupKey])
    ]

    return (
      this.characteristicOptions[this.quantityGroupKey]?.length === outOfStockOptionFromQuantityGroupWithCurrent?.length
    )
  }
  return false
}

function checkIfVariantIsOutOfStockOnSelection(groupKey, variant) {
  let matchBasedOnSelection = true
  Object.keys(variant.option)
    .filter((key) => key !== groupKey)
    .forEach((optionKey) => {
      if (optionKey === this.quantityGroupKey) {
        const hasValueOnOptions = Object.keys(this.selectionByGroup[optionKey]).filter((key) =>
          Number(this.selectionByGroup[optionKey][key])
        )

        if (!hasValueOnOptions?.includes(variant.option[optionKey])) {
          matchBasedOnSelection = false
        }
      } else {
        if (this.selectionByGroup && this.selectionByGroup[optionKey] !== variant.option[optionKey]) {
          matchBasedOnSelection = false
        }
      }
    })

  return matchBasedOnSelection
}

function getFilteredCharacteristicsList() {
  return this.characteristics.filter((item) => item !== this.quantityGroupKey)
}

function onSingleQuantityInputBlur() {
  if (this.singleQuantityInputValue < this.minimum) {
    this.singleQuantityInputValue = this.minimum
    this.emitSelection([{ variant: this.variants[0], quantity: this.singleQuantityInputValue }])
    return
  }

  const currentValue = this.singleQuantityInputValue
  const newValue = this.roundsUpValueBasedOnIncrement(currentValue, this.increment)

  if (newValue !== currentValue) {
    this.singleQuantityInputValue = newValue
  }

  this.emitSelection([{ variant: this.variants[0], quantity: this.singleQuantityInputValue }])
}

function watchSingleQuantityInputValue(inputValue) {
  if (inputValue < this.minimum) {
    this.emitSelection([])
    return
  }

  if (inputValue % this.increment !== 0) {
    this.emitSelection([])
    return
  }

  let selectedVariant = null
  if (this.variants?.length === 1) {
    selectedVariant = this.variants[0]
  } else {
    const selectedVariants = this.findSelectedVariants()
    if (selectedVariants.length) {
      selectedVariant = selectedVariants[0].variant
    }
  }
  if (selectedVariant) {
    this.emitSelection([{ variant: selectedVariant, quantity: this.singleQuantityInputValue }])
  }
}

function increaseSingleQuantity() {
  this.singleQuantityInputValue = this.increaseVariantQuantity(this.singleQuantityInputValue, this.increment)
}

function decreaseSingleQuantity() {
  this.singleQuantityInputValue = this.decreaseVariantQuantity(
    this.singleQuantityInputValue,
    this.increment,
    this.minimum
  )
}

function setSelectionByGroup(groupKey, option) {
  if (this.isFullyOutOfStock(groupKey, option)) {
    return
  }

  if (this.setOutOfStockOnSelection(groupKey, option)) {
    return
  }

  let newSelection = { ...this.selectionByGroup }
  newSelection[groupKey] = newSelection[groupKey] === option ? null : option

  // Clear / set 0 quantities on quantity group if necessary
  if (this.quantityGroupKey) {
    Object.keys(newSelection[this.quantityGroupKey]).forEach((optionKey) => {
      let shouldClear = false
      this.outOfStockVariants.find((variant) => {
        shouldClear = variant.option[groupKey] === option && Number(variant.option[this.quantityGroupKey] === optionKey)
      })

      if (shouldClear) {
        newSelection[this.quantityGroupKey][optionKey] = 0
      }
    })
  }

  this.selectionByGroup = newSelection
  this.$forceUpdate()
}

function isOptionSelected(groupKey, option) {
  return this.selectionByGroup[groupKey] === option
}

function isSelectionValid(selection) {
  let isSelectionValid = true
  this.characteristics.forEach((characteristic) => {
    if (characteristic === this.quantityGroupKey) {
      isSelectionValid = Boolean(
        Object.keys(selection[characteristic]).filter((key) => Number(selection[characteristic][key]) > 0)?.length
      )
    } else {
      if (!selection[characteristic]) {
        isSelectionValid = false
      }
    }
  })

  return isSelectionValid
}

function filterVariantsBySelection() {
  return this.variants.filter((variant) => {
    let match = true
    Object.keys(this.selectionByGroup)
      .filter((groupKey) => groupKey !== this.quantityGroupKey)
      .forEach((groupKey) => {
        if (variant.option[groupKey]?.toLowerCase() !== this.selectionByGroup[groupKey]?.toLowerCase()) {
          match = false
        }
      })
    return match
  })
}

function findSelectedVariants() {
  const variantsByQuantity = []
  const filteredVariants = this.filterVariantsBySelection()

  if (this.quantityGroupKey) {
    Object.keys(this.selectionByGroup[this.quantityGroupKey])
      .filter((key) => this.selectionByGroup[this.quantityGroupKey][key])
      .forEach((option) => {
        const variantFound = filteredVariants.find((variant) => {
          return variant.option[this.quantityGroupKey].toLowerCase() === option
        })
        const quantity = Number(this.selectionByGroup[this.quantityGroupKey][option])
        if (variantFound && quantity && variantFound.in_stock) {
          variantsByQuantity.push({ variant: variantFound, quantity })
        }
      })
  } else if (filteredVariants?.length === 1) {
    variantsByQuantity.push({ variant: filteredVariants[0], quantity: this.singleQuantityInputValue })
  }

  return variantsByQuantity
}

function watchSelectionChange(selection) {
  if (this.singleQuantityMode) {
    return
  }

  this.updateOutOfStockList()

  const isSelectionValid = this.isSelectionValid(selection)
  if (!isSelectionValid) {
    this.emitSelection([])
    this.setMinimumQuantityWarningVisibility([])
    return
  }

  const selectedVariants = this.findSelectedVariants()
  this.setMinimumQuantityWarningVisibility(selectedVariants)
  this.emitSelection(selectedVariants)
}

function getFullyOutOfStockOptions() {
  let fullyOutOfStockOptions = []
  Object.keys(this.fullyOutOfStockOptionsByGroup).forEach((key) => {
    const list = this.fullyOutOfStockOptionsByGroup[key].map((item) => {
      if (key === this.quantityGroupKey) {
        return this.getOriginalOptionTextFromQuantityGroup(item)
      }
      return this.$utils.capitalize(item)
    })
    fullyOutOfStockOptions = [...fullyOutOfStockOptions, ...list]
  })
  return fullyOutOfStockOptions?.reverse() || []
}

function showMainOutOfStockList() {
  return Boolean(Object.keys(this.fullyOutOfStockOptionsByGroup)?.length)
}
function setMinimumQuantityWarningVisibility(selectedVariants) {
  if (!selectedVariants?.length) {
    this.showMinimumQuantityWarning = false
    return
  }

  this.showMinimumQuantityWarning =
    selectedVariants
      .map((item) => item.quantity)
      ?.reduce((previousValue, currentValue) => previousValue + currentValue) < this.minimum
}

function getOriginalOptionTextFromQuantityGroup(option) {
  return this.characteristicOptions[this.quantityGroupKey || 'size'].find((item) => item?.toLowerCase() === option)
}

function onQuantityInputFocus(option) {
  if (this.selectionByGroup[this.quantityGroupKey][option] === 0) {
    this.selectionByGroup[this.quantityGroupKey][option] = null
  }
}

function onQuantityInputBlur(option) {
  if (
    this.selectionByGroup[this.quantityGroupKey][option] === null ||
    this.selectionByGroup[this.quantityGroupKey][option] === '' ||
    this.selectionByGroup[this.quantityGroupKey][option] < 0
  ) {
    this.selectionByGroup[this.quantityGroupKey][option] = 0
  }

  const currentValue = this.selectionByGroup[this.quantityGroupKey][option]
  const newValue = this.roundsUpValueBasedOnIncrement(currentValue, this.increment)

  if (newValue !== currentValue) {
    this.selectionByGroup[this.quantityGroupKey][option] = newValue
  }
}

function getInventoryWarning(variant) {
  const variantName = this.quantityGroupKey ? `${variant.characteristic_option[this.quantityGroupKey]}: ` : ''
  return `${variantName}Only ${variant.inventory_quantity} left in stock.`
}

function emitSelection(selectedVariants) {
  const invalidIncrementFound = this.validateIncrement(selectedVariants)

  this.inventoryWarnings = []
  let inventoryMisMatch = false
  if (this.trackInventory) {
    selectedVariants.forEach(({ variant, quantity }) => {
      if (variant.inventory_quantity < quantity) {
        inventoryMisMatch = true
        this.inventoryWarnings.push(variant)
      }
    })
  }

  if (invalidIncrementFound || inventoryMisMatch) {
    this.changeSelection([])
  } else {
    this.changeSelection(selectedVariants)
  }

  if (!selectedVariants?.length && this.selectionByGroup) {
    this.emitPartialSelection()
  }
}

function emitPartialSelection() {
  const partialSelection = Object.keys(this.selectionByGroup)
    ?.filter((groupKey) => groupKey !== this.quantityGroupKey || !this.selectionByGroup[groupKey])
    ?.reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue]: this.selectionByGroup[currentValue] }
    }, {})

  let unselectedOptionFound = false
  const optionKeyList = Object.keys(this.characteristicOptions)?.filter((key) => key !== this.quantityGroupKey)
  optionKeyList?.forEach((groupKey) => {
    if (!Object.keys(partialSelection)?.find((key) => groupKey === key)) {
      unselectedOptionFound = true
    }
  })

  const hasSelection = Boolean(
    Object.keys(this.selectionByGroup)?.filter(
      (groupKey) => groupKey !== this.quantityGroupKey && this.selectionByGroup[groupKey]
    )?.length
  )

  if (!unselectedOptionFound && hasSelection) {
    this.pickVariantByPartialSelection(partialSelection)
  }
}

function validateIncrement(selectedVariants) {
  let invalidIncrementFound = false

  selectedVariants.forEach((item) => {
    const remainder = item.quantity % this.increment
    if (remainder !== 0) {
      invalidIncrementFound = true
    }
  })

  return invalidIncrementFound
}

function getQuantityInputGroupSectionTitle() {
  if (this.hasOnlyOneSize) {
    return 'Quantity'
  }
  return `Quantity by ${this.quantityGroupKey}`
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/vars.scss';
@import '@/assets/sass/base/mixins.scss';
@import '@/assets/sass/base/typo.scss';

.variant-picker {
  width: 100%;
  text-align: left;
}
.single-option-picker {
  user-select: none !important;
  .quantity-button {
    height: 30px;
    width: 30px;
    border-radius: 15px;

    &:not(.disabled) {
      &:hover {
        cursor: pointer;
        background-color: $color-green;
      }
    }

    svg {
      height: 30px;
      width: 30px;
    }

    &.disabled {
      svg {
        fill: $color-grey-4;
      }
    }
  }
}

.quantity-input {
  min-width: 40px;
  height: 40px;
  width: 40px;
  border: 1px solid $color-black;
  margin: 0 4px;
  font-family: NHaasGroteskDSPro-55Rg;
  text-align: center;
  font-size: 1rem;

  &.green-bg {
    background: $color-green;
  }
}

.characteristic-option {
  text-transform: capitalize;
  display: inline-block;
  border: 1px solid black;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  padding: 0 8px;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Teodor;

  &:not(.out-of-stock) {
    &:hover {
      cursor: pointer;
    }
    &.selected {
      background: $color-green;
    }
  }
}

.characteristic-option,
.quantity-input {
  &.out-of-stock {
    border: 1px dashed black;

    &.fully {
      opacity: 0.25;
    }
  }
}

.quantities-by-group {
  .quantity-by-group {
    margin-bottom: 8px;

    .option-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.out-of-stock {
        &.fully {
          opacity: 0.25;
        }
      }
    }
  }
}

.main-out-of-stock-list {
  ol {
    list-style: disc;
    margin-left: 25px;
  }
}

.quantity-warning {
  color: $color-warning;
}
</style>
