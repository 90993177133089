/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailerBoardResponse
 */
export interface RetailerBoardResponse {
    /**
     * 
     * @type {string}
     * @memberof RetailerBoardResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerBoardResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RetailerBoardResponse
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RetailerBoardResponse
     */
    products_count?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerBoardResponse
     */
    contains_product?: boolean;
}

/**
 * Check if a given object implements the RetailerBoardResponse interface.
 */
export function instanceOfRetailerBoardResponse(value: object): value is RetailerBoardResponse {
    return true;
}

export function RetailerBoardResponseFromJSON(json: any): RetailerBoardResponse {
    return RetailerBoardResponseFromJSONTyped(json, false);
}

export function RetailerBoardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerBoardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'images': json['images'] == null ? undefined : json['images'],
        'products_count': json['products_count'] == null ? undefined : json['products_count'],
        'contains_product': json['contains_product'] == null ? undefined : json['contains_product'],
    };
}

export function RetailerBoardResponseToJSON(json: any): RetailerBoardResponse {
    return RetailerBoardResponseToJSONTyped(json, false);
}

export function RetailerBoardResponseToJSONTyped(value?: RetailerBoardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'images': value['images'],
        'products_count': value['products_count'],
        'contains_product': value['contains_product'],
    };
}

