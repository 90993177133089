/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SuborderDashboardResponse } from './SuborderDashboardResponse';
import {
    SuborderDashboardResponseFromJSON,
    SuborderDashboardResponseFromJSONTyped,
    SuborderDashboardResponseToJSON,
    SuborderDashboardResponseToJSONTyped,
} from './SuborderDashboardResponse';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface GetOrdersDashboardResponse
 */
export interface GetOrdersDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardResponse
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetOrdersDashboardResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardResponse
     */
    shipped_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardResponse
     */
    total_quantity?: number;
    /**
     * 
     * @type {Array<SuborderDashboardResponse>}
     * @memberof GetOrdersDashboardResponse
     */
    suborders?: Array<SuborderDashboardResponse>;
    /**
     * 
     * @type {AddressResponse}
     * @memberof GetOrdersDashboardResponse
     */
    address?: AddressResponse;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardResponse
     */
    number_of_returns?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardResponse
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOrdersDashboardResponse
     */
    total_order_price?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersDashboardResponse
     */
    retailer_email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetOrdersDashboardResponse
     */
    brand_email?: Array<string>;
}

/**
 * Check if a given object implements the GetOrdersDashboardResponse interface.
 */
export function instanceOfGetOrdersDashboardResponse(value: object): value is GetOrdersDashboardResponse {
    return true;
}

export function GetOrdersDashboardResponseFromJSON(json: any): GetOrdersDashboardResponse {
    return GetOrdersDashboardResponseFromJSONTyped(json, false);
}

export function GetOrdersDashboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrdersDashboardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'shipped_quantity': json['shipped_quantity'] == null ? undefined : json['shipped_quantity'],
        'total_quantity': json['total_quantity'] == null ? undefined : json['total_quantity'],
        'suborders': json['suborders'] == null ? undefined : ((json['suborders'] as Array<any>).map(SuborderDashboardResponseFromJSON)),
        'address': json['address'] == null ? undefined : AddressResponseFromJSON(json['address']),
        'number_of_returns': json['number_of_returns'] == null ? undefined : json['number_of_returns'],
        'status': json['status'] == null ? undefined : json['status'],
        'total_order_price': json['total_order_price'] == null ? undefined : json['total_order_price'],
        'retailer_email': json['retailer_email'] == null ? undefined : json['retailer_email'],
        'brand_email': json['brand_email'] == null ? undefined : json['brand_email'],
    };
}

export function GetOrdersDashboardResponseToJSON(json: any): GetOrdersDashboardResponse {
    return GetOrdersDashboardResponseToJSONTyped(json, false);
}

export function GetOrdersDashboardResponseToJSONTyped(value?: GetOrdersDashboardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'shipped_quantity': value['shipped_quantity'],
        'total_quantity': value['total_quantity'],
        'suborders': value['suborders'] == null ? undefined : ((value['suborders'] as Array<any>).map(SuborderDashboardResponseToJSON)),
        'address': AddressResponseToJSON(value['address']),
        'number_of_returns': value['number_of_returns'],
        'status': value['status'],
        'total_order_price': value['total_order_price'],
        'retailer_email': value['retailer_email'],
        'brand_email': value['brand_email'],
    };
}

