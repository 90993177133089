/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZendeskSingInResponse
 */
export interface ZendeskSingInResponse {
    /**
     * 
     * @type {string}
     * @memberof ZendeskSingInResponse
     */
    zendesk_jwt?: string;
}

/**
 * Check if a given object implements the ZendeskSingInResponse interface.
 */
export function instanceOfZendeskSingInResponse(value: object): value is ZendeskSingInResponse {
    return true;
}

export function ZendeskSingInResponseFromJSON(json: any): ZendeskSingInResponse {
    return ZendeskSingInResponseFromJSONTyped(json, false);
}

export function ZendeskSingInResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskSingInResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'zendesk_jwt': json['zendesk_jwt'] == null ? undefined : json['zendesk_jwt'],
    };
}

export function ZendeskSingInResponseToJSON(json: any): ZendeskSingInResponse {
    return ZendeskSingInResponseToJSONTyped(json, false);
}

export function ZendeskSingInResponseToJSONTyped(value?: ZendeskSingInResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'zendesk_jwt': value['zendesk_jwt'],
    };
}

