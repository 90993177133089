/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetVideoResponse
 */
export interface GetVideoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetVideoResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVideoResponse
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVideoResponse
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVideoResponse
     */
    entity_type?: GetVideoResponseEntityTypeEnum;
}


/**
 * @export
 */
export const GetVideoResponseEntityTypeEnum = {
    Product: 'PRODUCT'
} as const;
export type GetVideoResponseEntityTypeEnum = typeof GetVideoResponseEntityTypeEnum[keyof typeof GetVideoResponseEntityTypeEnum];


/**
 * Check if a given object implements the GetVideoResponse interface.
 */
export function instanceOfGetVideoResponse(value: object): value is GetVideoResponse {
    return true;
}

export function GetVideoResponseFromJSON(json: any): GetVideoResponse {
    return GetVideoResponseFromJSONTyped(json, false);
}

export function GetVideoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVideoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'entity_id': json['entity_id'] == null ? undefined : json['entity_id'],
        'url': json['url'] == null ? undefined : json['url'],
        'entity_type': json['entity_type'] == null ? undefined : json['entity_type'],
    };
}

export function GetVideoResponseToJSON(json: any): GetVideoResponse {
    return GetVideoResponseToJSONTyped(json, false);
}

export function GetVideoResponseToJSONTyped(value?: GetVideoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'entity_id': value['entity_id'],
        'url': value['url'],
        'entity_type': value['entity_type'],
    };
}

