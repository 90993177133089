/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBrandCategoriesForDropdownResponse
 */
export interface GetBrandCategoriesForDropdownResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBrandCategoriesForDropdownResponse
     */
    parent_category?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBrandCategoriesForDropdownResponse
     */
    child_categories?: Array<string>;
}

/**
 * Check if a given object implements the GetBrandCategoriesForDropdownResponse interface.
 */
export function instanceOfGetBrandCategoriesForDropdownResponse(value: object): value is GetBrandCategoriesForDropdownResponse {
    return true;
}

export function GetBrandCategoriesForDropdownResponseFromJSON(json: any): GetBrandCategoriesForDropdownResponse {
    return GetBrandCategoriesForDropdownResponseFromJSONTyped(json, false);
}

export function GetBrandCategoriesForDropdownResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandCategoriesForDropdownResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'parent_category': json['parent_category'] == null ? undefined : json['parent_category'],
        'child_categories': json['child_categories'] == null ? undefined : json['child_categories'],
    };
}

export function GetBrandCategoriesForDropdownResponseToJSON(json: any): GetBrandCategoriesForDropdownResponse {
    return GetBrandCategoriesForDropdownResponseToJSONTyped(json, false);
}

export function GetBrandCategoriesForDropdownResponseToJSONTyped(value?: GetBrandCategoriesForDropdownResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'parent_category': value['parent_category'],
        'child_categories': value['child_categories'],
    };
}

