/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmitOutboundOnboardingFormResponse
 */
export interface SubmitOutboundOnboardingFormResponse {
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormResponse
     */
    brand_id: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOutboundOnboardingFormResponse
     */
    brand_bio_image_url: string;
}

/**
 * Check if a given object implements the SubmitOutboundOnboardingFormResponse interface.
 */
export function instanceOfSubmitOutboundOnboardingFormResponse(value: object): value is SubmitOutboundOnboardingFormResponse {
    if (!('brand_id' in value) || value['brand_id'] === undefined) return false;
    if (!('brand_bio_image_url' in value) || value['brand_bio_image_url'] === undefined) return false;
    return true;
}

export function SubmitOutboundOnboardingFormResponseFromJSON(json: any): SubmitOutboundOnboardingFormResponse {
    return SubmitOutboundOnboardingFormResponseFromJSONTyped(json, false);
}

export function SubmitOutboundOnboardingFormResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitOutboundOnboardingFormResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_id': json['brand_id'],
        'brand_bio_image_url': json['brand_bio_image_url'],
    };
}

export function SubmitOutboundOnboardingFormResponseToJSON(json: any): SubmitOutboundOnboardingFormResponse {
    return SubmitOutboundOnboardingFormResponseToJSONTyped(json, false);
}

export function SubmitOutboundOnboardingFormResponseToJSONTyped(value?: SubmitOutboundOnboardingFormResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_id': value['brand_id'],
        'brand_bio_image_url': value['brand_bio_image_url'],
    };
}

