<template>
  <div
    class="text-form-group fleft"
    :class="{
      'one-third': width === 'one-third',
      half: width === 'half',
      'full-width': width === 'full',
      'no-spacing': noSpacing,
      'first-column': Number(column) === 1 && width !== 'full',
      'second-column': Number(column) === 2 && width !== 'full',
      'third-column': Number(column) === 3 && width !== 'full'
    }"
  >
    <p
      v-if="label && localValue.length !== 0"
      class="text-field-label b2 overflow-ellipsis text-light pl-10 inline-block"
      :class="{ invalid: $validator.errors.has(name) }"
    >
      {{ label }}
    </p>
    <div v-else style="height: 25px" />

    <vue-google-autocomplete
      v-if="googleMapsInitialized"
      :id="_uid.toString()"
      ref="addressAutocomplete"
      :name="name"
      :class="{ invalid: $validator.errors.has(name) }"
      :types="null"
      :country="countryList"
      :placeholder="placeholder"
      classname="input-main input-font-regular"
      @placechanged="placeChanged"
      @inputChange="addressInputChanged"
    />

    <!--    Hidden input binded to vue-autocomplete for make validation work-->
    <input v-model="localValue" v-validate="validate" type="hidden" :name="name" />

    <error-message v-if="!noErrorMessage && $validator.errors.has(name)" class="text-error-message overflow-ellipsis">
      {{ getErrorMessageByRule($validator.errors.firstRule(name)) }}
    </error-message>
  </div>
</template>

<script>
// this list needs to be extended whit the new validation
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { formFieldHelpers } from '../formFieldHelper'

export default {
  components: {
    VueGoogleAutocomplete
  },
  mixins: [formFieldHelpers],
  inject: ['parentValidator'],
  props: {
    withAddressForm: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'autocomplete'
    },
    addressSelected: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      localValue: this.value,
      googleMapsInitialized: false,
      countryList:
        // BRANDS AND ADMINS get ALL COUNTRIES, RETAILERS only get US and CA
        this.$session.getRole() === 'BRAND' || this.$session.getRole() === 'ADMIN'
          ? []
          : this.$utils.constants.RETAILER_AUTOCOMPLETE_COUNTRY_LIT
    }
  },
  computed: {},

  created() {
    this.$validator = this.parentValidator
  },
  mounted() {
    // TODO find better solution instead of this hack one
    // this prevents an issue, when you directly arrive to page and there is an address (currently on production), google will be undefined
    setTimeout(() => {
      this.googleMapsInitialized = true

      this.$nextTick(() => {
        if (this.localValue) {
          this.$refs.addressAutocomplete.autocompleteText = this.localValue
        }
      })
    }, 250)
  },
  methods: {
    async addressInputChanged(values) {
      if (!values.newVal.length) {
        this.localValue = ''
        this.$refs.addressAutocomplete.clear()
        this.$validator.validate(this.name, this.localValue)
        return
      }
      this.localValue = values.newVal
      setTimeout(() => {
        this.$emit('input', values.newVal)
      })

      this.$validator.validate(this.name, this.localValue)
    },
    placeChanged(data) {
      const { street_number, route, postal_code, locality, administrative_area_level_1, country } = data || {}
      if (route && street_number) {
        const address = {
          street: `${street_number} ${route}`,
          zip_code: postal_code,
          city: locality,
          state: administrative_area_level_1,
          country: country
        }

        this.localValue = address.street
        this.$validator.validate(this.name, this.localValue)
        this.$emit('input', address.street)
        this.addressSelected(address)

        if (this.withAddressForm) {
          this.$nextTick(() => {
            this.$refs.addressAutocomplete.update(address.street)
          })
        }
      } else {
        this.$emit('input', this.$refs.addressAutocomplete.autocompleteText)
      }
    },
    clear() {
      this.localValue = ''
      this.$refs.addressAutocomplete.clear()
    }
  }
}
</script>

<style lang="scss" scoped src="../textField/textField.scss"></style>
