/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCustomAssortmentRequest,
  CustomAssortmentResponse,
  IdWrapper,
  PaginatedCustomAssortmentsResponse,
  UpdateCustomAssortmentRequest,
} from '../models/index';
import {
    CreateCustomAssortmentRequestFromJSON,
    CreateCustomAssortmentRequestToJSON,
    CustomAssortmentResponseFromJSON,
    CustomAssortmentResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    PaginatedCustomAssortmentsResponseFromJSON,
    PaginatedCustomAssortmentsResponseToJSON,
    UpdateCustomAssortmentRequestFromJSON,
    UpdateCustomAssortmentRequestToJSON,
} from '../models/index';

export interface CreateCustomAssortmentOperationRequest {
    createCustomAssortmentRequest: CreateCustomAssortmentRequest;
}

export interface DeleteCustomAssortmentRequest {
    id: string;
}

export interface GetCustomAssortmentRequest {
    id: string;
}

export interface GetCustomAssortmentsRequest {
    retailerId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
}

export interface GetCustomAssortmentsByRetailerRequest {
    retailerId: string;
    page?: number;
    size?: number;
    searchBy?: string;
}

export interface UpdateCustomAssortmentOperationRequest {
    id: string;
    updateCustomAssortmentRequest: UpdateCustomAssortmentRequest;
}

/**
 * 
 */
export class CustomAssortmentControllerApi extends runtime.BaseAPI {

    /**
     * Create custom assortment. Returns Id of created custom assortment.
     */
    async createCustomAssortmentRaw(requestParameters: CreateCustomAssortmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createCustomAssortmentRequest'] == null) {
            throw new runtime.RequiredError(
                'createCustomAssortmentRequest',
                'Required parameter "createCustomAssortmentRequest" was null or undefined when calling createCustomAssortment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom-assortments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCustomAssortmentRequestToJSON(requestParameters['createCustomAssortmentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create custom assortment. Returns Id of created custom assortment.
     */
    async createCustomAssortment(requestParameters: CreateCustomAssortmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createCustomAssortmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete custom assortment
     */
    async deleteCustomAssortmentRaw(requestParameters: DeleteCustomAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCustomAssortment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete custom assortment
     */
    async deleteCustomAssortment(requestParameters: DeleteCustomAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomAssortmentRaw(requestParameters, initOverrides);
    }

    /**
     * Get custom assortment by Id
     */
    async getCustomAssortmentRaw(requestParameters: GetCustomAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomAssortmentResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCustomAssortment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAssortmentResponseFromJSON(jsonValue));
    }

    /**
     * Get custom assortment by Id
     */
    async getCustomAssortment(requestParameters: GetCustomAssortmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomAssortmentResponse> {
        const response = await this.getCustomAssortmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all custom-assortments per multiple/custom criteria
     */
    async getCustomAssortmentsRaw(requestParameters: GetCustomAssortmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCustomAssortmentsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom-assortments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCustomAssortmentsResponseFromJSON(jsonValue));
    }

    /**
     * Find all custom-assortments per multiple/custom criteria
     */
    async getCustomAssortments(requestParameters: GetCustomAssortmentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCustomAssortmentsResponse> {
        const response = await this.getCustomAssortmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all custom-assortments for provided retailer
     */
    async getCustomAssortmentsByRetailerRaw(requestParameters: GetCustomAssortmentsByRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCustomAssortmentsResponse>> {
        if (requestParameters['retailerId'] == null) {
            throw new runtime.RequiredError(
                'retailerId',
                'Required parameter "retailerId" was null or undefined when calling getCustomAssortmentsByRetailer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom-assortments/retailer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCustomAssortmentsResponseFromJSON(jsonValue));
    }

    /**
     * Find all custom-assortments for provided retailer
     */
    async getCustomAssortmentsByRetailer(requestParameters: GetCustomAssortmentsByRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCustomAssortmentsResponse> {
        const response = await this.getCustomAssortmentsByRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update custom assortment
     */
    async updateCustomAssortmentRaw(requestParameters: UpdateCustomAssortmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCustomAssortment().'
            );
        }

        if (requestParameters['updateCustomAssortmentRequest'] == null) {
            throw new runtime.RequiredError(
                'updateCustomAssortmentRequest',
                'Required parameter "updateCustomAssortmentRequest" was null or undefined when calling updateCustomAssortment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom-assortments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCustomAssortmentRequestToJSON(requestParameters['updateCustomAssortmentRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update custom assortment
     */
    async updateCustomAssortment(requestParameters: UpdateCustomAssortmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomAssortmentRaw(requestParameters, initOverrides);
    }

}
