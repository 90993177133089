/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariantWithoutSkuResponse
 */
export interface VariantWithoutSkuResponse {
    /**
     * 
     * @type {string}
     * @memberof VariantWithoutSkuResponse
     */
    variant_id?: string;
}

/**
 * Check if a given object implements the VariantWithoutSkuResponse interface.
 */
export function instanceOfVariantWithoutSkuResponse(value: object): value is VariantWithoutSkuResponse {
    return true;
}

export function VariantWithoutSkuResponseFromJSON(json: any): VariantWithoutSkuResponse {
    return VariantWithoutSkuResponseFromJSONTyped(json, false);
}

export function VariantWithoutSkuResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantWithoutSkuResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
    };
}

export function VariantWithoutSkuResponseToJSON(json: any): VariantWithoutSkuResponse {
    return VariantWithoutSkuResponseToJSONTyped(json, false);
}

export function VariantWithoutSkuResponseToJSONTyped(value?: VariantWithoutSkuResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'variant_id': value['variant_id'],
    };
}

