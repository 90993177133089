import env from '../env.js'

/**
 * Top level function to do event tracking.
 *
 * @param {string} name
 * @param {*} params
 * @param events
 */
export function trackEvent(name, params = null, events = null) {
  _fbTrackEvent(name, params, events)
}

/* eslint-disable */

/**
 * Tracking for facebook tracking pixel / fbq
 *
 * @param {string} name
 * @param {*} params
 * @param events
 */
function _fbTrackEvent(name, params, events) {
  const trackParams = params || undefined

  if (env.getOrigin() !== 'prod') {
    return
  }

  if (window.fbq === undefined) {
    return
  }
  switch (name) {
    case 'Retailer Button Click':
    case 'ViewContent':
    case 'PageView':
      window.fbq('track', name)
      break
    case 'CompleteRegistration':
      window.fbq('track', 'CompleteRegistration', trackParams, { eventID: trackParams.external_id })
      break
    case 'Purchase':
      window.fbq('track', name, trackParams, events)
      break
  }
}

export function track(vue, payload) {
  if (payload === undefined) {
    return
  }
  vue.$ma.trackEvent(payload)
  if (payload.action === undefined) {
    console.log('Skipping Rudderstack track because event action is missing')
    return
  }

  rudderanalytics.track(payload.action, payload.properties, () => {
    // console.log("track call");
  })
}
