<template>
  <div>
    <div v-for="item in getFilteredItems(dropdownData, 'label')" :key="item.label" class="mb-5">
      <checkbox-new v-model="item.selected" :label="item.label" @click="handleClick(item)" />
    </div>
  </div>
</template>

<script>
import BrandApi from '@/services/api/brandApi'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import { FilterKeys } from '../../../../services/utils'

const BrandApiService = new BrandApi()

export default {
  mixins: [paramsHelper, filterHelper],
  data() {
    return {
      dropdownData: [],
      selected: []
    }
  },
  watch: {
    '$route.query.deals_promotions': function (query) {
      this.parseSelection(query)
      this.emitFilterSelection({
        filterKey: FilterKeys.DEALS_PROMOTIONS,
        items: this.dropdownData
      })
    }
  },
  beforeMount() {
    this.getDropdownData()
  },
  methods: {
    async getDropdownData() {
      let { data } = await BrandApiService.getDealsPromotions()
      this.dropdownData = data
      this.parseSelection(this.$route.query.deals_promotions)
      this.emitFilterSelection({
        filterKey: FilterKeys.DEALS_PROMOTIONS,
        items: this.dropdownData
      })
    },
    handleClick(data) {
      data.selected = !data.selected
      const selectedDropdownData = this.dropdownData.filter((obj) => obj.selected).map((obj) => obj.value)
      this.trackFilterUsedEvent('deals and promotions')
      this.updateFilterURLParams(FilterKeys.DEALS_PROMOTIONS, selectedDropdownData)
      this.emitFilterSelection({
        filterKey: FilterKeys.DEALS_PROMOTIONS.toString(),
        items: this.dropdownData
      })
    },
    parseSelection(dropdownDataQuery) {
      this.dropdownData = this.dropdownData.map((brandValue) => {
        const isSelected = dropdownDataQuery === undefined ? false : dropdownDataQuery?.includes(brandValue.value)
        return {
          ...brandValue,
          ...{ selected: isSelected }
        }
      })
    }
  }
}
</script>
