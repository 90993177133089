/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuyerResponse
 */
export interface BuyerResponse {
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    company_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    job_function?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    primary_buying_category?: string;
}

/**
 * Check if a given object implements the BuyerResponse interface.
 */
export function instanceOfBuyerResponse(value: object): value is BuyerResponse {
    return true;
}

export function BuyerResponseFromJSON(json: any): BuyerResponse {
    return BuyerResponseFromJSONTyped(json, false);
}

export function BuyerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'company_name': json['company_name'] == null ? undefined : json['company_name'],
        'website': json['website'] == null ? undefined : json['website'],
        'job_function': json['job_function'] == null ? undefined : json['job_function'],
        'country': json['country'] == null ? undefined : json['country'],
        'province': json['province'] == null ? undefined : json['province'],
        'primary_buying_category': json['primary_buying_category'] == null ? undefined : json['primary_buying_category'],
    };
}

export function BuyerResponseToJSON(json: any): BuyerResponse {
    return BuyerResponseToJSONTyped(json, false);
}

export function BuyerResponseToJSONTyped(value?: BuyerResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'company_name': value['company_name'],
        'website': value['website'],
        'job_function': value['job_function'],
        'country': value['country'],
        'province': value['province'],
        'primary_buying_category': value['primary_buying_category'],
    };
}

