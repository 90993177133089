/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExhibitorHeaderButtonResponse } from './ExhibitorHeaderButtonResponse';
import {
    ExhibitorHeaderButtonResponseFromJSON,
    ExhibitorHeaderButtonResponseFromJSONTyped,
    ExhibitorHeaderButtonResponseToJSON,
    ExhibitorHeaderButtonResponseToJSONTyped,
} from './ExhibitorHeaderButtonResponse';

/**
 * 
 * @export
 * @interface ExhibitorDirectoryHeaderResponse
 */
export interface ExhibitorDirectoryHeaderResponse {
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    trade_show_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    event_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    desktop_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    mobile_image_url?: string;
    /**
     * 
     * @type {ExhibitorHeaderButtonResponse}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    button_1?: ExhibitorHeaderButtonResponse;
    /**
     * 
     * @type {ExhibitorHeaderButtonResponse}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    button_2?: ExhibitorHeaderButtonResponse;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    view_container_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    directory_title?: string;
    /**
     * 
     * @type {string}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    booth_map_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExhibitorDirectoryHeaderResponse
     */
    collections_visible?: boolean;
}

/**
 * Check if a given object implements the ExhibitorDirectoryHeaderResponse interface.
 */
export function instanceOfExhibitorDirectoryHeaderResponse(value: object): value is ExhibitorDirectoryHeaderResponse {
    return true;
}

export function ExhibitorDirectoryHeaderResponseFromJSON(json: any): ExhibitorDirectoryHeaderResponse {
    return ExhibitorDirectoryHeaderResponseFromJSONTyped(json, false);
}

export function ExhibitorDirectoryHeaderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExhibitorDirectoryHeaderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'trade_show_id': json['trade_show_id'] == null ? undefined : json['trade_show_id'],
        'event_id': json['event_id'] == null ? undefined : json['event_id'],
        'desktop_image_url': json['desktop_image_url'] == null ? undefined : json['desktop_image_url'],
        'mobile_image_url': json['mobile_image_url'] == null ? undefined : json['mobile_image_url'],
        'button_1': json['button_1'] == null ? undefined : ExhibitorHeaderButtonResponseFromJSON(json['button_1']),
        'button_2': json['button_2'] == null ? undefined : ExhibitorHeaderButtonResponseFromJSON(json['button_2']),
        'view_container_id': json['view_container_id'] == null ? undefined : json['view_container_id'],
        'directory_title': json['directory_title'] == null ? undefined : json['directory_title'],
        'booth_map_url': json['booth_map_url'] == null ? undefined : json['booth_map_url'],
        'collections_visible': json['collections_visible'] == null ? undefined : json['collections_visible'],
    };
}

export function ExhibitorDirectoryHeaderResponseToJSON(json: any): ExhibitorDirectoryHeaderResponse {
    return ExhibitorDirectoryHeaderResponseToJSONTyped(json, false);
}

export function ExhibitorDirectoryHeaderResponseToJSONTyped(value?: ExhibitorDirectoryHeaderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'trade_show_id': value['trade_show_id'],
        'event_id': value['event_id'],
        'desktop_image_url': value['desktop_image_url'],
        'mobile_image_url': value['mobile_image_url'],
        'button_1': ExhibitorHeaderButtonResponseToJSON(value['button_1']),
        'button_2': ExhibitorHeaderButtonResponseToJSON(value['button_2']),
        'view_container_id': value['view_container_id'],
        'directory_title': value['directory_title'],
        'booth_map_url': value['booth_map_url'],
        'collections_visible': value['collections_visible'],
    };
}

