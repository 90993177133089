/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOrderReturnItemRequest
 */
export interface UpdateOrderReturnItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderReturnItemRequest
     */
    order_return_item_id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderReturnItemRequest
     */
    accepted_quantity: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderReturnItemRequest
     */
    declined_quantity: number;
}

/**
 * Check if a given object implements the UpdateOrderReturnItemRequest interface.
 */
export function instanceOfUpdateOrderReturnItemRequest(value: object): value is UpdateOrderReturnItemRequest {
    if (!('accepted_quantity' in value) || value['accepted_quantity'] === undefined) return false;
    if (!('declined_quantity' in value) || value['declined_quantity'] === undefined) return false;
    return true;
}

export function UpdateOrderReturnItemRequestFromJSON(json: any): UpdateOrderReturnItemRequest {
    return UpdateOrderReturnItemRequestFromJSONTyped(json, false);
}

export function UpdateOrderReturnItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrderReturnItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'order_return_item_id': json['order_return_item_id'] == null ? undefined : json['order_return_item_id'],
        'accepted_quantity': json['accepted_quantity'],
        'declined_quantity': json['declined_quantity'],
    };
}

export function UpdateOrderReturnItemRequestToJSON(json: any): UpdateOrderReturnItemRequest {
    return UpdateOrderReturnItemRequestToJSONTyped(json, false);
}

export function UpdateOrderReturnItemRequestToJSONTyped(value?: UpdateOrderReturnItemRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'order_return_item_id': value['order_return_item_id'],
        'accepted_quantity': value['accepted_quantity'],
        'declined_quantity': value['declined_quantity'],
    };
}

