/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandPublishedPromoCodeResponse
 */
export interface BrandPublishedPromoCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandPublishedPromoCodeResponse
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandPublishedPromoCodeResponse
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandPublishedPromoCodeResponse
     */
    type?: string;
}

/**
 * Check if a given object implements the BrandPublishedPromoCodeResponse interface.
 */
export function instanceOfBrandPublishedPromoCodeResponse(value: object): value is BrandPublishedPromoCodeResponse {
    return true;
}

export function BrandPublishedPromoCodeResponseFromJSON(json: any): BrandPublishedPromoCodeResponse {
    return BrandPublishedPromoCodeResponseFromJSONTyped(json, false);
}

export function BrandPublishedPromoCodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandPublishedPromoCodeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function BrandPublishedPromoCodeResponseToJSON(json: any): BrandPublishedPromoCodeResponse {
    return BrandPublishedPromoCodeResponseToJSONTyped(json, false);
}

export function BrandPublishedPromoCodeResponseToJSONTyped(value?: BrandPublishedPromoCodeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': value['code'],
        'amount': value['amount'],
        'type': value['type'],
    };
}

