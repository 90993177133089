/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBrandBoardResponse
 */
export interface GetBrandBoardResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandBoardResponse
     */
    product_count?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandBoardResponse
     */
    thumbnail_image_url?: string;
}

/**
 * Check if a given object implements the GetBrandBoardResponse interface.
 */
export function instanceOfGetBrandBoardResponse(value: object): value is GetBrandBoardResponse {
    return true;
}

export function GetBrandBoardResponseFromJSON(json: any): GetBrandBoardResponse {
    return GetBrandBoardResponseFromJSONTyped(json, false);
}

export function GetBrandBoardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandBoardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'product_count': json['product_count'] == null ? undefined : json['product_count'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
    };
}

export function GetBrandBoardResponseToJSON(json: any): GetBrandBoardResponse {
    return GetBrandBoardResponseToJSONTyped(json, false);
}

export function GetBrandBoardResponseToJSONTyped(value?: GetBrandBoardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'product_count': value['product_count'],
        'thumbnail_image_url': value['thumbnail_image_url'],
    };
}

