/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDashboardPaymentsResponse
 */
export interface GetDashboardPaymentsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    suborder_id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetDashboardPaymentsResponse
     */
    suborder_number?: number;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    invoice_status?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    brand_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetDashboardPaymentsResponse
     */
    brand_payout?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetDashboardPaymentsResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetDashboardPaymentsResponse
     */
    balance_invoice_url?: string;
}

/**
 * Check if a given object implements the GetDashboardPaymentsResponse interface.
 */
export function instanceOfGetDashboardPaymentsResponse(value: object): value is GetDashboardPaymentsResponse {
    return true;
}

export function GetDashboardPaymentsResponseFromJSON(json: any): GetDashboardPaymentsResponse {
    return GetDashboardPaymentsResponseFromJSONTyped(json, false);
}

export function GetDashboardPaymentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDashboardPaymentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'suborder_id': json['suborder_id'] == null ? undefined : json['suborder_id'],
        'suborder_number': json['suborder_number'] == null ? undefined : json['suborder_number'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'invoice_status': json['invoice_status'] == null ? undefined : json['invoice_status'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'brand_payout': json['brand_payout'] == null ? undefined : json['brand_payout'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'balance_invoice_url': json['balance_invoice_url'] == null ? undefined : json['balance_invoice_url'],
    };
}

export function GetDashboardPaymentsResponseToJSON(json: any): GetDashboardPaymentsResponse {
    return GetDashboardPaymentsResponseToJSONTyped(json, false);
}

export function GetDashboardPaymentsResponseToJSONTyped(value?: GetDashboardPaymentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suborder_id': value['suborder_id'],
        'suborder_number': value['suborder_number'],
        'retailer_id': value['retailer_id'],
        'retailer_name': value['retailer_name'],
        'invoice_status': value['invoice_status'],
        'brand_id': value['brand_id'],
        'brand_name': value['brand_name'],
        'brand_payout': value['brand_payout'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'balance_invoice_url': value['balance_invoice_url'],
    };
}

