<template>
  <div class="sorting-options" :class="{ mobile: isMobile, 'hide-label': hideLabel }">
    <div class="indicator" @click="handleOpen">
      <Icon icon-key="sort" />
      <div v-if="!hideLabel" class="selected-option text-overflow-ellipsis">
        {{ selectedSort ? selectedSort.name : 'Sort By' }}
      </div>
    </div>
    <div v-if="showPopup && !isMobile" class="cover" @click="handleClose" />
    <div v-if="showPopup" class="sorting-popup">
      <template v-if="isMobile">
        <Icon icon-key="close" hover-type="OPACITY" class="close-container" :on-click="handleClose" />
        <h2 class="popup-title">Sort By</h2>
      </template>
      <div v-for="(item, index) in options" :key="`${item.value}-${index}`" class="sort-items" @click="sortBy(item)">
        {{ item.name }}
        <Icon
          v-if="selectedSort && selectedSort.value === item.value && selectedSort.distinct === item.distinct"
          icon-key="checkmark"
          class="checkmark-container"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { paramsHelper } from '../../mixin/paramsHelper'

export default {
  mixins: [paramsHelper],

  props: {
    options: {
      type: Array,
      default: function () {
        return this.$utils.constants.DEFAULT_PRODUCT_SORTING_OPTIONS
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    noPagination: {
      type: Boolean,
      default: false
    },
    noTracking: {
      type: Boolean,
      default: false
    },
    firstPage: {
      type: Number,
      default: 1
    },
    // NOTE:: If localSort and setLocalSort is passed, sortingOptions component won't use route params
    localSort: {
      type: String
    },
    setLocalSort: {
      type: Function
    }
  },
  data() {
    return {
      selectedSort: null,
      showPopup: false
    }
  },
  computed: {
    shouldSortLocally() {
      return !!(this.localSort && this.setLocalSort)
    }
  },
  watch: {
    '$route.query.sortBy': function (sortBy) {
      this.setSelectedSort(sortBy, Boolean(this.$route.query?.distinct))
    },
    '$route.query.distinct': function (distinct) {
      this.setSelectedSort(this.$route.query?.sortBy, Boolean(distinct))
    },
    showPopup: function (value) {
      if (this.isMobile) {
        this.$store.dispatch('navigation/setMobilePopupOpen', value)
      }
    },
    selectedSort: function (sortBy) {
      if (this.shouldSortLocally && sortBy) {
        this.setLocalSort(sortBy.value)
      }
    }
  },
  beforeMount() {
    if (this.shouldSortLocally) {
      this.setSelectedSort(this.localSort)
    } else {
      this.setSelectedSort(this.$route.query?.sortBy, this.$route.query?.distinct)
    }
  },
  methods: {
    handleOpen: function () {
      this.showPopup = !this.showPopup
    },
    handleClose: function () {
      this.showPopup = false
    },
    sortBy: function (filterType) {
      this.showPopup = false
      this.selectedSort = filterType

      if (!this.noPagination) {
        this.updateURLParams('page', this.firstPage)
      }

      !this.shouldSortLocally && this.updateURLParams('distinct', this.selectedSort.distinct)
      !this.shouldSortLocally && this.updateURLParams('sortBy', this.selectedSort.value)

      if (!this.noTracking) {
        this.$trackEvent({
          action: 'Bulletin Board Sort',
          properties: {
            'Search Sort Criteria': this.selectedSort?.name
          }
        })

        if (this.selectedSort?.value?.startsWith('brands_name')) {
          this.$trackEvent({
            action: 'Alphabetical Sort on Tradeshow Directory'
          })
        }
      }
    },
    setSelectedSort: function (sortBy, distinct) {
      this.selectedSort = this.options?.find((item) => {
        if (!distinct) {
          return item.value === sortBy && !item.distinct
        }
        return item.value === sortBy && item.distinct
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';

.sorting-options {
  position: relative;
  width: 205px;
  justify-content: right;

  .indicator {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity $defaultTransitionTime $defaultTransitionType;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    .selected-option {
      font-size: 16px;
      font-family: 'NHaasGroteskDSPro-55Rg';
    }
  }

  .sorting-popup {
    position: absolute;
    top: 50px;
    min-width: 322px;
    right: 0;
    border: 1px solid $color-black;
    background: $color-white;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 19;

    .sort-items {
      font-size: 16px;
      font-family: 'NHaasGroteskDSPro-55Rg';
      padding: 8px 16px;
      transition: opacity $defaultTransitionTime $defaultTransitionType;
      position: relative;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      .checkmark-container {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.mobile {
    &:not(.hide-label) {
      width: 100%;
    }

    &.hide-label {
      width: 72px;
    }

    .indicator {
      justify-content: center;
    }

    .sorting-popup {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      border: unset;

      .sorting-items {
        padding-left: 16px;
      }

      .popup-title {
        margin-top: 48px;
        margin-left: 16px;
        margin-bottom: 16px;
        font-size: 32px;
      }

      .close-container {
        position: fixed;
        top: 16px;
        right: 16px;
      }
    }
  }
}
</style>
