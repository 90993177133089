import axios from 'axios'
import session from '../services/session'
import utils from '@/services/utils'

export default {
  createRestApi(baseUrl) {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }

    const restApi = axios.create({
      baseURL: baseUrl,
      headers
    })

    restApi.interceptors.request.use(
      function (config) {
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    restApi.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const responsePath = error.request.responseURL ? new URL(error.request.responseURL).pathname : 'none'
        if (
          (error.response.status === utils.constants.HTTP_STATUS.UNAUTHORIZED ||
            error.response.status === utils.constants.HTTP_STATUS.FORBIDDEN) &&
          responsePath !== '/auth/sign-in'
        ) {
          session.destroy()
          delete axios.defaults.headers.common.authorization
          window.location.href = `/sign-in${responsePath === '/carts' ? '?showSuspendedWarning=true' : ''}`

          // eslint-disable-next-line no-empty-function
          return new Promise(() => {})
        }
        return Promise.reject(error)
      }
    )

    return restApi
  }
}
