/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnumOptionOrderStatus
 */
export interface EnumOptionOrderStatus {
    /**
     * 
     * @type {string}
     * @memberof EnumOptionOrderStatus
     */
    value?: EnumOptionOrderStatusValueEnum;
    /**
     * 
     * @type {string}
     * @memberof EnumOptionOrderStatus
     */
    label?: string;
}


/**
 * @export
 */
export const EnumOptionOrderStatusValueEnum = {
    Hold: 'HOLD',
    Void: 'VOID',
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    Expired: 'EXPIRED',
    PartiallyFulfilled: 'PARTIALLY_FULFILLED',
    FulfillmentInProgress: 'FULFILLMENT_IN_PROGRESS',
    Shipped: 'SHIPPED',
    Paid: 'PAID',
    Failed: 'FAILED',
    NotCollectable: 'NOT_COLLECTABLE',
    Cancelled: 'CANCELLED'
} as const;
export type EnumOptionOrderStatusValueEnum = typeof EnumOptionOrderStatusValueEnum[keyof typeof EnumOptionOrderStatusValueEnum];


/**
 * Check if a given object implements the EnumOptionOrderStatus interface.
 */
export function instanceOfEnumOptionOrderStatus(value: object): value is EnumOptionOrderStatus {
    return true;
}

export function EnumOptionOrderStatusFromJSON(json: any): EnumOptionOrderStatus {
    return EnumOptionOrderStatusFromJSONTyped(json, false);
}

export function EnumOptionOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumOptionOrderStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function EnumOptionOrderStatusToJSON(json: any): EnumOptionOrderStatus {
    return EnumOptionOrderStatusToJSONTyped(json, false);
}

export function EnumOptionOrderStatusToJSONTyped(value?: EnumOptionOrderStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'label': value['label'],
    };
}

