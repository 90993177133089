/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Add Product To Brand Boards object
 * @export
 * @interface AddProductToBrandBoardsRequest
 */
export interface AddProductToBrandBoardsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddProductToBrandBoardsRequest
     */
    brand_board_ids?: Array<string>;
}

/**
 * Check if a given object implements the AddProductToBrandBoardsRequest interface.
 */
export function instanceOfAddProductToBrandBoardsRequest(value: object): value is AddProductToBrandBoardsRequest {
    return true;
}

export function AddProductToBrandBoardsRequestFromJSON(json: any): AddProductToBrandBoardsRequest {
    return AddProductToBrandBoardsRequestFromJSONTyped(json, false);
}

export function AddProductToBrandBoardsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProductToBrandBoardsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_board_ids': json['brand_board_ids'] == null ? undefined : json['brand_board_ids'],
    };
}

export function AddProductToBrandBoardsRequestToJSON(json: any): AddProductToBrandBoardsRequest {
    return AddProductToBrandBoardsRequestToJSONTyped(json, false);
}

export function AddProductToBrandBoardsRequestToJSONTyped(value?: AddProductToBrandBoardsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_board_ids': value['brand_board_ids'],
    };
}

