/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentSourceResponse } from './PaymentSourceResponse';
import {
    PaymentSourceResponseFromJSON,
    PaymentSourceResponseFromJSONTyped,
    PaymentSourceResponseToJSON,
    PaymentSourceResponseToJSONTyped,
} from './PaymentSourceResponse';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface GetPayInvoiceInformationResponse
 */
export interface GetPayInvoiceInformationResponse {
    /**
     * 
     * @type {AddressResponse}
     * @memberof GetPayInvoiceInformationResponse
     */
    billing_address?: AddressResponse;
    /**
     * 
     * @type {PaymentSourceResponse}
     * @memberof GetPayInvoiceInformationResponse
     */
    stripe_payment_source?: PaymentSourceResponse;
}

/**
 * Check if a given object implements the GetPayInvoiceInformationResponse interface.
 */
export function instanceOfGetPayInvoiceInformationResponse(value: object): value is GetPayInvoiceInformationResponse {
    return true;
}

export function GetPayInvoiceInformationResponseFromJSON(json: any): GetPayInvoiceInformationResponse {
    return GetPayInvoiceInformationResponseFromJSONTyped(json, false);
}

export function GetPayInvoiceInformationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPayInvoiceInformationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'billing_address': json['billing_address'] == null ? undefined : AddressResponseFromJSON(json['billing_address']),
        'stripe_payment_source': json['stripe_payment_source'] == null ? undefined : PaymentSourceResponseFromJSON(json['stripe_payment_source']),
    };
}

export function GetPayInvoiceInformationResponseToJSON(json: any): GetPayInvoiceInformationResponse {
    return GetPayInvoiceInformationResponseToJSONTyped(json, false);
}

export function GetPayInvoiceInformationResponseToJSONTyped(value?: GetPayInvoiceInformationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'billing_address': AddressResponseToJSON(value['billing_address']),
        'stripe_payment_source': PaymentSourceResponseToJSON(value['stripe_payment_source']),
    };
}

