<template>
  <div :class="{ 'is-mobile': isMobile }" class="filter-panel-wrapper">
    <filter-bulletin-picks v-if="!isBuyer" />

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.SECTIONS)"
      :active-filter-key="openedFilter"
      :filter-key="$utils.constants.FilterKeys.SECTIONS"
      :label="'NY NOW Sections'"
      :on-change="handleAccordionStateChange"
    >
      <filter-sections />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.CATEGORIES)"
      :active-filter-key="openedFilter"
      :filter-key="$utils.constants.FilterKeys.CATEGORIES"
      :label="getParentCategoryFilter() ? 'Subcategories' : 'Product Categories'"
      :on-change="handleAccordionStateChange"
    >
      <filter-categories-new
        :categories-filter="getBrandCategoryFilter()"
        :parent-category-filter="getParentCategoryFilter()"
        :pre-loaded-categories="getCategories()"
      />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.BRANDS)"
      :active-filter-key="openedFilter"
      :filter-key="$utils.constants.FilterKeys.BRANDS"
      :on-change="handleAccordionStateChange"
      label="Brands"
    >
      <filter-brand />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.BRAND_VALUES)"
      :active-filter-key="openedFilter"
      :filter-key="$utils.constants.FilterKeys.BRAND_VALUES"
      :on-change="handleAccordionStateChange"
      label="Brand Values"
    >
      <filter-brand-values-new />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.PRICE_FROM)"
      :active-filter-key="openedFilter"
      :content-height="330"
      :filter-key="$utils.constants.FilterKeys.PRICE_FROM"
      :on-change="handleAccordionStateChange"
      label="Retail Price"
      no-search
    >
      <filter-price-new />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.SHIP_BY)"
      :active-filter-key="openedFilter"
      :content-height="330"
      :filter-key="$utils.constants.FilterKeys.SHIP_BY"
      :on-change="handleAccordionStateChange"
      label="Ship by"
      no-search
    >
      <filter-date-new />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.LOCATION)"
      :active-filter-key="openedFilter"
      :filter-key="$utils.constants.FilterKeys.LOCATION"
      :on-change="handleAccordionStateChange"
      label="Location"
    >
      <filter-locations :is-buyer="isBuyer" />
    </filter-accordion>

    <filter-accordion
      v-if="filterPanelConfig?.showPrimaryBuyingCategoriesFilter"
      :active-filter-key="openedFilter"
      :filter-key="$utils.constants.FilterKeys.SECTIONS"
      :label="'Primary Buying Category'"
      :on-change="handleAccordionStateChange"
      no-search
    >
      <filter-sections />
    </filter-accordion>

    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.MOV)"
      :active-filter-key="openedFilter"
      :content-height="350"
      :filter-key="$utils.constants.FilterKeys.MOV"
      :on-change="handleAccordionStateChange"
      label="Minimum Order"
      no-search
    >
      <filter-minimum-order-value-new />
    </filter-accordion>
    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.SPECIAL_SERVICES)"
      :active-filter-key="openedFilter"
      :content-height="350"
      :filter-key="$utils.constants.FilterKeys.SPECIAL_SERVICES"
      :on-change="handleAccordionStateChange"
      label="Special Services"
      no-search
    >
      <filter-special-services />
    </filter-accordion>
    <filter-accordion
      v-if="!hideFilter($utils.constants.FilterKeys.DEALS_PROMOTIONS)"
      :active-filter-key="openedFilter"
      :content-height="350"
      :filter-key="$utils.constants.FilterKeys.DEALS_PROMOTIONS"
      :on-change="handleAccordionStateChange"
      label="Deals & Promotions"
      no-search
    >
      <filter-deals-promotions />
    </filter-accordion>
  </div>
</template>
<script>
import FilterBrandValuesNew from './filterTypes/filterBrandValues'
import { FilterKeys } from '@/services/utils'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from '@/components/shared/filters/filterTypes/filterHelper'
import FilterBulletinPicks from '@/components/shared/filters/FilterBulletinPicks.vue'

export default {
  components: { FilterBulletinPicks, FilterBrandValuesNew },
  mixins: [paramsHelper, filterHelper],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: Number,
      default: 0
    },
    filterPanelConfig: {
      type: Object,
      default: function () {
        // Shape of filter panel config
        return {
          hiddenFilters: [],
          showPrimaryBuyingCategoriesFilter: false,
          filterSettings: {
            [FilterKeys.CATEGORIES]: {
              parentCategoryId: '',
              brandCategories: [
                {
                  parent_category: '', // name of the parent category
                  child_categories: [] // names of the sub categories
                }
              ]
            }
          }
        }
      }
    },
    isBuyer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { openedFilter: null }
  },
  methods: {
    handleAccordionStateChange: function ({ open, filterKey }) {
      if (!open) {
        this.openedFilter = null
        return
      }

      this.openedFilter = filterKey
    },
    hideFilter: function (filterKey) {
      return this.filterPanelConfig?.hiddenFilters?.includes(filterKey.toString())
    },
    getParentCategoryFilter: function () {
      const { filterSettings } = this.filterPanelConfig
      return filterSettings ? filterSettings[FilterKeys.CATEGORIES]?.parentCategoryId : undefined
    },
    getBrandCategoryFilter: function () {
      const { filterSettings } = this.filterPanelConfig
      return filterSettings ? filterSettings[FilterKeys.CATEGORIES]?.categoriesFilter : []
    },
    getCategories: function () {
      const { filterSettings } = this.filterPanelConfig || {}
      return filterSettings ? filterSettings[FilterKeys.CATEGORIES]?.categories : undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-panel-wrapper {
  width: calc(25% - 10px);

  &.is-mobile {
    max-width: 100%;
    width: 100%;
  }
}
</style>
