import restApi from '../../http.js'
import session from '../session'

export default class contentManagment {
  async getFeaturedCollections() {
    return contentManagment.mapContainerResponse(
      await restApi({
        method: 'GET',
        url: '/views/homepage/containers?type=FEATURED_COLLECTIONS'
      })
    )
  }
  updateFeaturedCollections(data) {
    return restApi({
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      },
      method: 'PUT',
      url: '/views/homepage/containers/FEATURED_COLLECTIONS',
      data: data
    })
  }
  async getHero() {
    return contentManagment.mapContainerResponse(
      await restApi({
        method: 'GET',
        url: '/views/homepage/containers?type=HERO'
      })
    )
  }
  updateHero(data) {
    return restApi({
      method: 'PUT',
      url: '/views/homepage/containers/HERO',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      },
      data: data
    })
  }
  async getFeaturedBrands() {
    return contentManagment.mapContainerResponse(
      await restApi({
        method: 'GET',
        url: '/views/homepage/containers?type=FEATURED_BRANDS'
      })
    )
  }
  updateFeaturedBrands(data) {
    return restApi({
      headers: { Authorization: 'Bearer ' + session.getAccessToken() },
      method: 'PUT',
      url: '/views/homepage/containers/FEATURED_BRANDS',
      data: data
    })
  }
  async getTrendingBrands() {
    return contentManagment.mapContainerResponse(
      await restApi({
        method: 'GET',
        url: '/views/homepage/containers?type=TRENDING_BRANDS'
      })
    )
  }
  updateTrendingBrands(data) {
    return restApi({
      headers: { Authorization: 'Bearer ' + session.getAccessToken() },
      method: 'PUT',
      url: '/views/homepage/containers/TRENDING_BRANDS',
      data: data
    })
  }
  async getAbout() {
    return contentManagment.mapContainerResponse(
      await restApi({
        method: 'GET',
        url: 'views/homepage/containers?type=ABOUT'
      })
    )
  }
  updateAbout(data) {
    return restApi({
      headers: { Authorization: 'Bearer ' + session.getAccessToken() },
      method: 'PUT',
      url: '/views/homepage/containers/ABOUT',
      data: data
    })
  }

  async getCollectionsCarousel(returnOnlyWithList) {
    return contentManagment.mapContainerResponse(
      await restApi({
        method: 'GET',
        url: '/views/homepage/containers?type=PERFECT_FOR_THE'
      }),
      returnOnlyWithList
    )
  }

  updateCollectionsCarousel(request) {
    return restApi({
      method: 'PUT',
      url: '/views/homepage/containers/PERFECT_FOR_THE',
      data: request,
      headers: { Authorization: 'Bearer ' + session.getAccessToken() }
    })
  }

  uploadFile(file) {
    return restApi({
      method: 'POST',
      url: '/files',
      data: file,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  async getSiteWideBanner() {
    return restApi({
      method: 'GET',
      url: '/views/containers?type=PROMO_BANNER'
    })
  }

  async saveSiteWideBanner(data) {
    return restApi({
      method: 'PUT',
      url: '/views/components/promo-banner',
      data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  static mapContainerResponse(response, returnOnlyWithList = true) {
    const { view_components } = response?.data ?? {}
    return Promise.resolve(returnOnlyWithList ? { data: view_components || [] } : response)
  }
}

export const ContentManagementApiService = new contentManagment()
