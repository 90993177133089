import restApi from '../../http.js'
import session from '../session.js'

export default class Api {
  getAllCountries() {
    return restApi({
      method: 'GET',
      url: '/countries'
    })
  }
  getNumberOfFollowers(request) {
    return restApi({
      method: 'GET',
      url: 'https://www.instagram.com/' + request.username + '/?__a=1'
    })
  }

  getReferrerByCode(param) {
    return restApi({
      method: 'GET',
      url: '/brands/brand-referral',
      params: {
        referralCode: param
      }
    })
  }

  getReferrerById(param) {
    return restApi({
      method: 'GET',
      url: '/brands/brand-referral',
      params: {
        referralId: param
      }
    })
  }

  getMeRetailer() {
    return restApi({
      method: 'GET',
      url: '/retailers/me',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  uploadRetailerThumb(image) {
    return restApi({
      method: 'POST',
      url: `/retailers/thumbnail-image`,
      data: image,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updatePassword(request) {
    return restApi({
      method: 'POST',
      url: '/auth/change-password',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getAllRetailers() {
    return restApi({
      method: 'GET',
      url: '/retailers/dropdown'
    })
  }

  getBrandRetailers(id) {
    return restApi({
      method: 'GET',
      url: `/brands/${id}/retailers`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getAllBrands(param) {
    return restApi({
      method: 'GET',
      url: '/brands/dropdown',
      params: {
        is_published: param
      }
    })
  }

  getMinimumOrderValues() {
    return restApi({
      method: 'GET',
      url: '/orders/minimum-order-values'
    })
  }

  async getLocations() {
    try {
      const { data } = await restApi({
        method: 'GET',
        url: '/countries/states'
      })
      return (data?.countries || [])
        .map(({ code, name, state }) => {
          return { code, name, states: state }
        })
        .sort((country1) => {
          return country1.code === 'USA' ? 1 : -1
        })
    } catch (e) {
      return []
    }
  }
}
