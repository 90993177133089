/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TradeShowRecommendedBrandsResponse } from './TradeShowRecommendedBrandsResponse';
import {
    TradeShowRecommendedBrandsResponseFromJSON,
    TradeShowRecommendedBrandsResponseFromJSONTyped,
    TradeShowRecommendedBrandsResponseToJSON,
    TradeShowRecommendedBrandsResponseToJSONTyped,
} from './TradeShowRecommendedBrandsResponse';

/**
 * 
 * @export
 * @interface GetTradeShowRecommendedBrandsResponse
 */
export interface GetTradeShowRecommendedBrandsResponse {
    /**
     * 
     * @type {Array<TradeShowRecommendedBrandsResponse>}
     * @memberof GetTradeShowRecommendedBrandsResponse
     */
    recommendations?: Array<TradeShowRecommendedBrandsResponse>;
}

/**
 * Check if a given object implements the GetTradeShowRecommendedBrandsResponse interface.
 */
export function instanceOfGetTradeShowRecommendedBrandsResponse(value: object): value is GetTradeShowRecommendedBrandsResponse {
    return true;
}

export function GetTradeShowRecommendedBrandsResponseFromJSON(json: any): GetTradeShowRecommendedBrandsResponse {
    return GetTradeShowRecommendedBrandsResponseFromJSONTyped(json, false);
}

export function GetTradeShowRecommendedBrandsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTradeShowRecommendedBrandsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'recommendations': json['recommendations'] == null ? undefined : ((json['recommendations'] as Array<any>).map(TradeShowRecommendedBrandsResponseFromJSON)),
    };
}

export function GetTradeShowRecommendedBrandsResponseToJSON(json: any): GetTradeShowRecommendedBrandsResponse {
    return GetTradeShowRecommendedBrandsResponseToJSONTyped(json, false);
}

export function GetTradeShowRecommendedBrandsResponseToJSONTyped(value?: GetTradeShowRecommendedBrandsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'recommendations': value['recommendations'] == null ? undefined : ((value['recommendations'] as Array<any>).map(TradeShowRecommendedBrandsResponseToJSON)),
    };
}

