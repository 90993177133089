export default {
  data: () => ({
    model: {
      email: null,
      password: null
    },
    loginRequestLoading: false,
    errorMessage: null
  }),
  mounted() {
    this.$refs.emailInput.focus()
  },
  methods: {
    // eslint-disable-next-line complexity
    async signIn() {
      const request = {
          email: this.model.email,
          password: this.model.password
        },
        valid = await this.$validator.validate()

      if (!valid) {
        return
      }

      this.loginRequestLoading = true

      try {
        const response = await this.$utils.handleLogin(request)
        this.$emit('close-dropdown')

        this.$utils.handlePostLogin.call(this, response)
      } catch (error) {
        this.$trackEvent({
          action: 'Log In',
          properties: {
            Success: false,
            Error: error.response ? error.response.data.error_message : error
          }
        })

        if (error.response) {
          this.errors.add({
            field: 'email',
            msg: error.response.data.error_message
          })
        } else {
          this.$logger.notify(new Error(error))
        }
      } finally {
        this.loginRequestLoading = false
      }
    }
  }
}
