/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailerCreditResponse
 */
export interface RetailerCreditResponse {
    /**
     * 
     * @type {number}
     * @memberof RetailerCreditResponse
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailerCreditResponse
     */
    credit_used?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailerCreditResponse
     */
    credit_left?: number;
    /**
     * 
     * @type {number}
     * @memberof RetailerCreditResponse
     */
    credit_level?: number;
}

/**
 * Check if a given object implements the RetailerCreditResponse interface.
 */
export function instanceOfRetailerCreditResponse(value: object): value is RetailerCreditResponse {
    return true;
}

export function RetailerCreditResponseFromJSON(json: any): RetailerCreditResponse {
    return RetailerCreditResponseFromJSONTyped(json, false);
}

export function RetailerCreditResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerCreditResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'balance': json['balance'] == null ? undefined : json['balance'],
        'credit_used': json['credit_used'] == null ? undefined : json['credit_used'],
        'credit_left': json['credit_left'] == null ? undefined : json['credit_left'],
        'credit_level': json['credit_level'] == null ? undefined : json['credit_level'],
    };
}

export function RetailerCreditResponseToJSON(json: any): RetailerCreditResponse {
    return RetailerCreditResponseToJSONTyped(json, false);
}

export function RetailerCreditResponseToJSONTyped(value?: RetailerCreditResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'balance': value['balance'],
        'credit_used': value['credit_used'],
        'credit_left': value['credit_left'],
        'credit_level': value['credit_level'],
    };
}

