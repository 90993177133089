/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConditionKeyResponse
 */
export interface ConditionKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof ConditionKeyResponse
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionKeyResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConditionKeyResponse
     */
    available_operators?: Array<string>;
}

/**
 * Check if a given object implements the ConditionKeyResponse interface.
 */
export function instanceOfConditionKeyResponse(value: object): value is ConditionKeyResponse {
    return true;
}

export function ConditionKeyResponseFromJSON(json: any): ConditionKeyResponse {
    return ConditionKeyResponseFromJSONTyped(json, false);
}

export function ConditionKeyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionKeyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'name': json['name'] == null ? undefined : json['name'],
        'available_operators': json['available_operators'] == null ? undefined : json['available_operators'],
    };
}

export function ConditionKeyResponseToJSON(json: any): ConditionKeyResponse {
    return ConditionKeyResponseToJSONTyped(json, false);
}

export function ConditionKeyResponseToJSONTyped(value?: ConditionKeyResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'name': value['name'],
        'available_operators': value['available_operators'],
    };
}

