/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    zip_code?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressResponse
     */
    shipping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressResponse
     */
    billing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressResponse
     */
    default_shipping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressResponse
     */
    default_billing?: boolean;
}

/**
 * Check if a given object implements the AddressResponse interface.
 */
export function instanceOfAddressResponse(value: object): value is AddressResponse {
    return true;
}

export function AddressResponseFromJSON(json: any): AddressResponse {
    return AddressResponseFromJSONTyped(json, false);
}

export function AddressResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'zip_code': json['zip_code'] == null ? undefined : json['zip_code'],
        'first_name': json['first_name'] == null ? undefined : json['first_name'],
        'last_name': json['last_name'] == null ? undefined : json['last_name'],
        'street': json['street'] == null ? undefined : json['street'],
        'company': json['company'] == null ? undefined : json['company'],
        'city': json['city'] == null ? undefined : json['city'],
        'apartment': json['apartment'] == null ? undefined : json['apartment'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'state': json['state'] == null ? undefined : json['state'],
        'country': json['country'] == null ? undefined : json['country'],
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'shipping': json['shipping'] == null ? undefined : json['shipping'],
        'billing': json['billing'] == null ? undefined : json['billing'],
        'default_shipping': json['default_shipping'] == null ? undefined : json['default_shipping'],
        'default_billing': json['default_billing'] == null ? undefined : json['default_billing'],
    };
}

export function AddressResponseToJSON(json: any): AddressResponse {
    return AddressResponseToJSONTyped(json, false);
}

export function AddressResponseToJSONTyped(value?: AddressResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'zip_code': value['zip_code'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'street': value['street'],
        'company': value['company'],
        'city': value['city'],
        'apartment': value['apartment'],
        'phone': value['phone'],
        'state': value['state'],
        'country': value['country'],
        'retailer_id': value['retailer_id'],
        'shipping': value['shipping'],
        'billing': value['billing'],
        'default_shipping': value['default_shipping'],
        'default_billing': value['default_billing'],
    };
}

