/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateEmailRequest
 */
export interface ValidateEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateEmailRequest
     */
    email: string;
}

/**
 * Check if a given object implements the ValidateEmailRequest interface.
 */
export function instanceOfValidateEmailRequest(value: object): value is ValidateEmailRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function ValidateEmailRequestFromJSON(json: any): ValidateEmailRequest {
    return ValidateEmailRequestFromJSONTyped(json, false);
}

export function ValidateEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateEmailRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function ValidateEmailRequestToJSON(json: any): ValidateEmailRequest {
    return ValidateEmailRequestToJSONTyped(json, false);
}

export function ValidateEmailRequestToJSONTyped(value?: ValidateEmailRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
    };
}

