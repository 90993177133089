import { mapGetters } from 'vuex'
import { ValidationApi } from '@/api'

export const dialogHelper = {
  computed: {
    ...mapGetters({
      disablingMessageReason: 'disablingMessageReason'
    })
  },
  methods: {
    showMessageWarningToSuspendedOrDeclinedRetailers: async function () {
      await this.$swal('Attention', this.disablingMessageReason)
    },
    showMessageWarningToUnverifiedRetailer: async function () {
      await this.$swal(
        'Attention',
        'Hold Up! Please reach out to our Support Team for clarification regarding your platform access at <a class=\'underline\' href="mailto: support@bulletin.co">support@bulletin.co</a>. Thank you!'
      )
    },

    async hasFailedEmailChecks() {
      try {
        const { validation } = await ValidationApi.validateEmail({ validateEmailRequest: { email: this.form.email } })
        const checks = validation.checks
        if (
          checks.domain.has_valid_address_syntax &&
          checks.domain.has_mx_or_a_record &&
          !checks.domain.is_suspected_disposable_address &&
          !checks.additional.has_known_bounces
          // disalbed checks
          // && !checks.additional.has_suspected_bounces
          // !checks.local_part.is_suspected_role_address &&
        ) {
          return false
        } else {
          this.$swal({
            title: 'Attention',
            html: 'The email address entered is invalid. Please double-check and try again.'
          })
          return true
        }
      } catch (e) {
        // if the validation check fails don't stop the user flow
        return false
      }
    }
  }
}
