export default {
  getUserInfo() {
    try {
      let user = JSON.parse(localStorage.getItem('USER_INFO'))

      return user
    } catch (e) {
      console.error(e)
    }
  },

  getRole() {
    const user = this.getUserInfo()
    return user ? user.role : 'VISITOR'
  },

  getUserId() {
    let user = this.getUserInfo()

    return user.id
  },

  setUserInfo(user) {
    localStorage.setItem('USER_INFO', JSON.stringify(user))
  },

  getAccessToken() {
    try {
      const token = localStorage.getItem('ACCESS_TOKEN')
      return token
    } catch (e) {
      console.error(e)
    }
  },

  setAccessToken(token) {
    localStorage.setItem('ACCESS_TOKEN', token)
  },

  destroy() {
    localStorage.removeItem('ACCESS_TOKEN')
    localStorage.removeItem('USER_INFO')
  }
}
