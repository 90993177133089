/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update Brand Board object
 * @export
 * @interface UpdateBrandBoardRequest
 */
export interface UpdateBrandBoardRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBoardRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandBoardRequest
     */
    visibility?: UpdateBrandBoardRequestVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandBoardRequest
     */
    show_on_profile?: boolean;
}


/**
 * @export
 */
export const UpdateBrandBoardRequestVisibilityEnum = {
    Bulletin: 'BULLETIN',
    Public: 'PUBLIC'
} as const;
export type UpdateBrandBoardRequestVisibilityEnum = typeof UpdateBrandBoardRequestVisibilityEnum[keyof typeof UpdateBrandBoardRequestVisibilityEnum];


/**
 * Check if a given object implements the UpdateBrandBoardRequest interface.
 */
export function instanceOfUpdateBrandBoardRequest(value: object): value is UpdateBrandBoardRequest {
    return true;
}

export function UpdateBrandBoardRequestFromJSON(json: any): UpdateBrandBoardRequest {
    return UpdateBrandBoardRequestFromJSONTyped(json, false);
}

export function UpdateBrandBoardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBrandBoardRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'visibility': json['visibility'] == null ? undefined : json['visibility'],
        'show_on_profile': json['show_on_profile'] == null ? undefined : json['show_on_profile'],
    };
}

export function UpdateBrandBoardRequestToJSON(json: any): UpdateBrandBoardRequest {
    return UpdateBrandBoardRequestToJSONTyped(json, false);
}

export function UpdateBrandBoardRequestToJSONTyped(value?: UpdateBrandBoardRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'visibility': value['visibility'],
        'show_on_profile': value['show_on_profile'],
    };
}

