/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BuyerDirectory } from './BuyerDirectory';
import {
    BuyerDirectoryFromJSON,
    BuyerDirectoryFromJSONTyped,
    BuyerDirectoryToJSON,
    BuyerDirectoryToJSONTyped,
} from './BuyerDirectory';
import type { Navigation } from './Navigation';
import {
    NavigationFromJSON,
    NavigationFromJSONTyped,
    NavigationToJSON,
    NavigationToJSONTyped,
} from './Navigation';
import type { ExhibitorDirectory } from './ExhibitorDirectory';
import {
    ExhibitorDirectoryFromJSON,
    ExhibitorDirectoryFromJSONTyped,
    ExhibitorDirectoryToJSON,
    ExhibitorDirectoryToJSONTyped,
} from './ExhibitorDirectory';
import type { RestrictExhibitorVisibility } from './RestrictExhibitorVisibility';
import {
    RestrictExhibitorVisibilityFromJSON,
    RestrictExhibitorVisibilityFromJSONTyped,
    RestrictExhibitorVisibilityToJSON,
    RestrictExhibitorVisibilityToJSONTyped,
} from './RestrictExhibitorVisibility';
import type { ProfileBadges } from './ProfileBadges';
import {
    ProfileBadgesFromJSON,
    ProfileBadgesFromJSONTyped,
    ProfileBadgesToJSON,
    ProfileBadgesToJSONTyped,
} from './ProfileBadges';

/**
 * 
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * 
     * @type {ExhibitorDirectory}
     * @memberof Features
     */
    exhibitor_directory?: ExhibitorDirectory;
    /**
     * 
     * @type {BuyerDirectory}
     * @memberof Features
     */
    buyer_directory?: BuyerDirectory;
    /**
     * 
     * @type {Navigation}
     * @memberof Features
     */
    navigation?: Navigation;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof Features
     */
    profile_badges?: ProfileBadges;
    /**
     * 
     * @type {RestrictExhibitorVisibility}
     * @memberof Features
     */
    restrict_exhibitor_visibility?: RestrictExhibitorVisibility;
}

/**
 * Check if a given object implements the Features interface.
 */
export function instanceOfFeatures(value: object): value is Features {
    return true;
}

export function FeaturesFromJSON(json: any): Features {
    return FeaturesFromJSONTyped(json, false);
}

export function FeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Features {
    if (json == null) {
        return json;
    }
    return {
        
        'exhibitor_directory': json['exhibitor_directory'] == null ? undefined : ExhibitorDirectoryFromJSON(json['exhibitor_directory']),
        'buyer_directory': json['buyer_directory'] == null ? undefined : BuyerDirectoryFromJSON(json['buyer_directory']),
        'navigation': json['navigation'] == null ? undefined : NavigationFromJSON(json['navigation']),
        'profile_badges': json['profile_badges'] == null ? undefined : ProfileBadgesFromJSON(json['profile_badges']),
        'restrict_exhibitor_visibility': json['restrict_exhibitor_visibility'] == null ? undefined : RestrictExhibitorVisibilityFromJSON(json['restrict_exhibitor_visibility']),
    };
}

export function FeaturesToJSON(json: any): Features {
    return FeaturesToJSONTyped(json, false);
}

export function FeaturesToJSONTyped(value?: Features | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exhibitor_directory': ExhibitorDirectoryToJSON(value['exhibitor_directory']),
        'buyer_directory': BuyerDirectoryToJSON(value['buyer_directory']),
        'navigation': NavigationToJSON(value['navigation']),
        'profile_badges': ProfileBadgesToJSON(value['profile_badges']),
        'restrict_exhibitor_visibility': RestrictExhibitorVisibilityToJSON(value['restrict_exhibitor_visibility']),
    };
}

