import Talk from 'talkjs'
import { mapGetters } from 'vuex'
import { dialogHelper } from '@/components/mixin/dialogHelper'
import userStatusHelper from '../../mixin/userStatusHelper'
import ChatIcon from '@/components/shared/chatIcon/chatIcon.vue'

export default {
  components: { ChatIcon },
  mixins: [dialogHelper, userStatusHelper],
  props: ['me', 'other', 'btnClass', 'btnVariant', 'hideName', 'hideIcon', 'asPlainText', 'showAsIcon', 'trackingInfo'],
  data() {
    return {
      modal: false,
      message: '',
      messageLoading: false,
      messageSent: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  methods: {
    handleBtnClick,
    closePopUp,
    submitMessage,
    createTalkJSConversation
  }
}

function handleBtnClick() {
  if (!this.canSendMessage && this.user?.role === 'RETAILER') {
    this.showMessageWarningToSuspendedOrDeclinedRetailers()
    return
  }

  this.modal = !this.modal
}

function closePopUp() {
  if (this.messageLoading) {
    return
  }
  this.modal = !this.modal
  this.message = ''
  this.messageLoading = false
  this.messageSent = false
  this.$validator.reset()
}

async function submitMessage() {
  if (!(await this.$validator.validate())) {
    return
  }
  this.messageLoading = true
  await Talk.ready
  this.createTalkJSConversation()
  const properties = {
    ...this.trackingInfo.properties,
    'user ID': this.me.userId,
    Email: this.me.email,
    role: this.me.role
  }
  if (this.trackingInfo.action) {
    let action = this.trackingInfo.action
    this.$trackEvent({
      action,
      properties
    })
  }
}

async function createTalkJSConversation() {
  const me = new Talk.User({
      id: this.me.userId,
      name: this.me.fullName,
      email: this.me.email,
      role: this.me.role
    }),
    other = new Talk.User({
      id: this.other.userId,
      name: `${this.other.firstName} from ${this.other.storeName ? this.other.storeName : this.other.firstName}`,
      email: this.other.email ? this.other.email : 'NA',
      role: this.other.role
    }),
    popUptalkSession = new Talk.Session({
      appId: this.$utils.constants.TALKJS_APP_ID,
      me: me
    }),
    conversation = talkSession.getOrCreateConversation(Talk.oneOnOneId(me, other))
  conversation.setParticipant(me)
  conversation.setParticipant(other)
  // eslint-disable-next-line one-var
  const popup = await talkSession.createPopup(conversation, {
    keepOpen: false
  })
  await popup.mount({
    show: false
  })
  await conversation.sendMessage(this.message)
  this.messageLoading = false
  this.messageSent = true
  popUptalkSession.destroy()

  this.closePopUp()

  this.toast({
    type: 'success',
    title: 'Success',
    message: 'Message successfully sent',
    actionName: 'Go to Messages',
    action: () => this.$router.push('/dashboard/messages')
  })
}
