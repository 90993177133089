export default {
  props: {
    title: {
      type: String,
      default: 'Qty'
    },
    disabled: {
      type: Boolean
    },
    onChange: {
      type: Function
    },
    minimum: {
      type: Number
    },
    increment: {
      type: Number
    },
    val: {
      type: Number
    },
    productIndex: {
      type: Number
    },
    brandIndex: {
      type: Number
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      value: null,
      inputValue: null,
      inputFocused: false
    }
  },
  methods: {
    add: add,
    remove: remove,
    openInput,
    closeInput,
    shouldOpen,
    handleChange
  },
  watch: {
    val: function () {
      this.value = this.val
    },
    value() {
      if (this.value % 1 !== 0) {
        this.value = Math.floor(this.value)
      }

      this.inputValue = this.value
    }
  },

  mounted() {
    if (this.val) {
      this.value = this.val
    } else {
      this.value = this.minimum
    }
  }
}

function add() {
  this.value = Number(this.value) + Number(this.increment)

  this.handleChange()
}

function remove() {
  if (this.value - this.increment < this.minimum) {
    return false
  }
  this.value = Number(this.value) - Number(this.increment)

  this.handleChange()
}

function shouldOpen() {
  if (this.disabled) {
    return false
  }

  return true
}

async function openInput() {
  if (!this.shouldOpen()) {
    return
  }

  this.inputFocused = true
  await this.$nextTick()
  this.$refs.quantityInput.focus()
  this.$refs.quantityInput.select()
}

function closeInput() {
  const prevValue = this.value

  if (!this.inputFocused) {
    return
  }

  if (this.$utils.isValidNumericValue(this.inputValue)) {
    this.value = this.$utils.validateQuantityNumber(this.inputValue, this.minimum, this.increment)
  }

  if (this.inputValue !== prevValue) {
    this.inputValue = this.value
    this.handleChange()
  }

  this.inputFocused = false
}

function handleChange() {
  if (this.brandIndex === null) {
    this.onChange(this.value, null, this.productIndex)
  } else {
    this.onChange(this.value, this.brandIndex, this.productIndex)
  }
}
