/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BoardBrandResponse
 */
export interface BoardBrandResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardBrandResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardBrandResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardBrandResponse
     */
    vanity_url_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardBrandResponse
     */
    thumbnail_image_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BoardBrandResponse
     */
    retailer_is_following?: boolean;
}

/**
 * Check if a given object implements the BoardBrandResponse interface.
 */
export function instanceOfBoardBrandResponse(value: object): value is BoardBrandResponse {
    return true;
}

export function BoardBrandResponseFromJSON(json: any): BoardBrandResponse {
    return BoardBrandResponseFromJSONTyped(json, false);
}

export function BoardBrandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoardBrandResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'vanity_url_name': json['vanity_url_name'] == null ? undefined : json['vanity_url_name'],
        'thumbnail_image_url': json['thumbnail_image_url'] == null ? undefined : json['thumbnail_image_url'],
        'retailer_is_following': json['retailer_is_following'] == null ? undefined : json['retailer_is_following'],
    };
}

export function BoardBrandResponseToJSON(json: any): BoardBrandResponse {
    return BoardBrandResponseToJSONTyped(json, false);
}

export function BoardBrandResponseToJSONTyped(value?: BoardBrandResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'vanity_url_name': value['vanity_url_name'],
        'thumbnail_image_url': value['thumbnail_image_url'],
        'retailer_is_following': value['retailer_is_following'],
    };
}

