import restApi from '../../http.js'
import session from '../session.js'

export default class Api {
  getMyRetailerInfo() {
    return restApi({
      method: 'GET',
      url: '/retailers/me',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getRetailerMessagingCapability() {
    return restApi({
      method: 'GET',
      url: '/user/me/can-send-message',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getRetailerByResetPasswordCode(resetPasswordCode) {
    return restApi({
      method: 'GET',
      url: `/user/code/${resetPasswordCode}`
    })
  }

  setRetailerPassword(request) {
    return restApi({
      method: 'POST',
      url: `/auth/retailer/set-attendee-password`,
      data: request
    })
  }

  getRetailerById(id) {
    return restApi({
      method: 'GET',
      url: `/retailers/${id}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getRetailerProfileById(id) {
    return restApi({
      method: 'GET',
      url: `/retailers/${id}`
    })
  }
  getRetailerOrderList(id) {
    return restApi({
      method: 'GET',
      url: `/retailers/${id}/purchased-brands`
    })
  }

  updateRetailerAccountById(id, request) {
    return restApi({
      method: 'PATCH',
      url: `/retailers/${id}/account`,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  updateMyRetailerAccount(request) {
    return restApi({
      method: 'PATCH',
      url: '/retailers/me/account ',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  uploadRetailerThumb(image) {
    return restApi({
      method: 'POST',
      url: '/retailers/thumbnail-image',
      data: image,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  updateRetailerStatus(id, request) {
    return restApi({
      method: 'PATCH',
      url: '/retailers/' + id + '/status',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  getRetailerStatuses() {
    return restApi({
      method: 'GET',
      url: '/retailers/statuses',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  fetchRetailerBalanceLevels() {
    return restApi({
      method: 'GET',
      url: '/retailers/balance-levels',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getStoreValues() {
    return restApi({
      method: 'GET',
      url: '/retailers/values/dropdown',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getStoreTypes() {
    return restApi({
      method: 'GET',
      url: '/retailers/store-types',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  approvedRetailer(id) {
    return restApi({
      method: 'PATCH',
      url: `/retailers/${id}/approve`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  declineRetailer(id) {
    return restApi({
      method: 'PATCH',
      url: `/retailers/${id}/decline`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getRetailerBalance(retailerId) {
    return restApi({
      method: 'GET',
      url: `/retailers/balance/${retailerId}`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
