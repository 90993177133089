<template>
  <div v-if="brandValues && brandValues.length">
    <span v-for="brandValue in brandValues" :key="brandValue.value" class="inline-block mr-10 mb-10">
      <btn class="button-green no-events" button-type="tag" @click="select(index)">
        <template #icon>
          <brand-value-icon class="mr-10" :name="brandValue.value" />
        </template>
        {{ brandValue.label }}
      </btn>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    brandValues: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
@import '@/assets/sass/base/_typo.scss';
</style>
