/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeletedVariantResponse
 */
export interface DeletedVariantResponse {
    /**
     * 
     * @type {string}
     * @memberof DeletedVariantResponse
     */
    product_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedVariantResponse
     */
    variant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedVariantResponse
     */
    product_name?: string;
}

/**
 * Check if a given object implements the DeletedVariantResponse interface.
 */
export function instanceOfDeletedVariantResponse(value: object): value is DeletedVariantResponse {
    return true;
}

export function DeletedVariantResponseFromJSON(json: any): DeletedVariantResponse {
    return DeletedVariantResponseFromJSONTyped(json, false);
}

export function DeletedVariantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletedVariantResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'product_id': json['product_id'] == null ? undefined : json['product_id'],
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
        'product_name': json['product_name'] == null ? undefined : json['product_name'],
    };
}

export function DeletedVariantResponseToJSON(json: any): DeletedVariantResponse {
    return DeletedVariantResponseToJSONTyped(json, false);
}

export function DeletedVariantResponseToJSONTyped(value?: DeletedVariantResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_id': value['product_id'],
        'variant_id': value['variant_id'],
        'product_name': value['product_name'],
    };
}

