/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterProductRequest
 */
export interface FilterProductRequest {
    /**
     * 
     * @type {boolean}
     * @memberof FilterProductRequest
     */
    include_all_conditions: boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterProductRequest
     */
    search_criteria: string;
}

/**
 * Check if a given object implements the FilterProductRequest interface.
 */
export function instanceOfFilterProductRequest(value: object): value is FilterProductRequest {
    if (!('include_all_conditions' in value) || value['include_all_conditions'] === undefined) return false;
    if (!('search_criteria' in value) || value['search_criteria'] === undefined) return false;
    return true;
}

export function FilterProductRequestFromJSON(json: any): FilterProductRequest {
    return FilterProductRequestFromJSONTyped(json, false);
}

export function FilterProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterProductRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'include_all_conditions': json['include_all_conditions'],
        'search_criteria': json['search_criteria'],
    };
}

export function FilterProductRequestToJSON(json: any): FilterProductRequest {
    return FilterProductRequestToJSONTyped(json, false);
}

export function FilterProductRequestToJSONTyped(value?: FilterProductRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'include_all_conditions': value['include_all_conditions'],
        'search_criteria': value['search_criteria'],
    };
}

