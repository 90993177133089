/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentSourceResponse
 */
export interface PaymentSourceResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentSourceResponse
     */
    card_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSourceResponse
     */
    card_type?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSourceResponse
     */
    last_4?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSourceResponse
     */
    is_default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentSourceResponse
     */
    expiration_year?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSourceResponse
     */
    expiration_month?: string;
}

/**
 * Check if a given object implements the PaymentSourceResponse interface.
 */
export function instanceOfPaymentSourceResponse(value: object): value is PaymentSourceResponse {
    return true;
}

export function PaymentSourceResponseFromJSON(json: any): PaymentSourceResponse {
    return PaymentSourceResponseFromJSONTyped(json, false);
}

export function PaymentSourceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSourceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'card_id': json['card_id'] == null ? undefined : json['card_id'],
        'card_type': json['card_type'] == null ? undefined : json['card_type'],
        'last_4': json['last_4'] == null ? undefined : json['last_4'],
        'is_default': json['is_default'] == null ? undefined : json['is_default'],
        'expiration_year': json['expiration_year'] == null ? undefined : json['expiration_year'],
        'expiration_month': json['expiration_month'] == null ? undefined : json['expiration_month'],
    };
}

export function PaymentSourceResponseToJSON(json: any): PaymentSourceResponse {
    return PaymentSourceResponseToJSONTyped(json, false);
}

export function PaymentSourceResponseToJSONTyped(value?: PaymentSourceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'card_id': value['card_id'],
        'card_type': value['card_type'],
        'last_4': value['last_4'],
        'is_default': value['is_default'],
        'expiration_year': value['expiration_year'],
        'expiration_month': value['expiration_month'],
    };
}

