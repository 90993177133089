/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BuyerDirectoryHeaderRequest,
  BuyerDirectoryHeaderResponse,
  CreateTradeShowRequest,
  ExhibitorDirectoryHeaderRequest,
  ExhibitorDirectoryHeaderResponse,
  ExportTradeShowExhibitorsToCSVRequest,
  GetAllTradeShowsResponse,
  GetTradeShowBuyersResponse,
  GetTradeShowByEventIdResponse,
  GetTradeShowRecommendedBrandsResponse,
  IdWrapper,
  ImportBulletinIncubatorBrandsRequest,
  LinkRetailersWithTradeShowRequest,
  MarketingCategoriesResponse,
  PreventEndDateOverlapRequest,
  ReindexTradeShowBrandRequest,
  SendTradeShowWelcomeEmailsRequest,
  SendWelcomeEmailToTradeShowAttendeesRequest,
  TradeShowCountryResponse,
  TradeShowEnabledFeaturesResponse,
  TradeShowFeatureResponse,
  TradeShowResponse,
  UpdateTradeShowLinksRequest,
  UpdateTradeShowRequest,
} from '../models/index';
import {
    BuyerDirectoryHeaderRequestFromJSON,
    BuyerDirectoryHeaderRequestToJSON,
    BuyerDirectoryHeaderResponseFromJSON,
    BuyerDirectoryHeaderResponseToJSON,
    CreateTradeShowRequestFromJSON,
    CreateTradeShowRequestToJSON,
    ExhibitorDirectoryHeaderRequestFromJSON,
    ExhibitorDirectoryHeaderRequestToJSON,
    ExhibitorDirectoryHeaderResponseFromJSON,
    ExhibitorDirectoryHeaderResponseToJSON,
    ExportTradeShowExhibitorsToCSVRequestFromJSON,
    ExportTradeShowExhibitorsToCSVRequestToJSON,
    GetAllTradeShowsResponseFromJSON,
    GetAllTradeShowsResponseToJSON,
    GetTradeShowBuyersResponseFromJSON,
    GetTradeShowBuyersResponseToJSON,
    GetTradeShowByEventIdResponseFromJSON,
    GetTradeShowByEventIdResponseToJSON,
    GetTradeShowRecommendedBrandsResponseFromJSON,
    GetTradeShowRecommendedBrandsResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    ImportBulletinIncubatorBrandsRequestFromJSON,
    ImportBulletinIncubatorBrandsRequestToJSON,
    LinkRetailersWithTradeShowRequestFromJSON,
    LinkRetailersWithTradeShowRequestToJSON,
    MarketingCategoriesResponseFromJSON,
    MarketingCategoriesResponseToJSON,
    PreventEndDateOverlapRequestFromJSON,
    PreventEndDateOverlapRequestToJSON,
    ReindexTradeShowBrandRequestFromJSON,
    ReindexTradeShowBrandRequestToJSON,
    SendTradeShowWelcomeEmailsRequestFromJSON,
    SendTradeShowWelcomeEmailsRequestToJSON,
    SendWelcomeEmailToTradeShowAttendeesRequestFromJSON,
    SendWelcomeEmailToTradeShowAttendeesRequestToJSON,
    TradeShowCountryResponseFromJSON,
    TradeShowCountryResponseToJSON,
    TradeShowEnabledFeaturesResponseFromJSON,
    TradeShowEnabledFeaturesResponseToJSON,
    TradeShowFeatureResponseFromJSON,
    TradeShowFeatureResponseToJSON,
    TradeShowResponseFromJSON,
    TradeShowResponseToJSON,
    UpdateTradeShowLinksRequestFromJSON,
    UpdateTradeShowLinksRequestToJSON,
    UpdateTradeShowRequestFromJSON,
    UpdateTradeShowRequestToJSON,
} from '../models/index';

export interface AddBuyerDirectoryHeaderDetailsRequest {
    eventId: string;
    buyerDirectoryHeaderRequest: BuyerDirectoryHeaderRequest;
}

export interface AddExhibitorDirectoryHeaderDetailsRequest {
    eventId: string;
    exhibitorDirectoryHeaderRequest: ExhibitorDirectoryHeaderRequest;
}

export interface CreateTradeShowOperationRequest {
    createTradeShowRequest: CreateTradeShowRequest;
}

export interface DeleteTradeShowRequest {
    id: string;
}

export interface ExportTradeShowExhibitorsToCSVOperationRequest {
    eventId: string;
    exportTradeShowExhibitorsToCSVRequest: ExportTradeShowExhibitorsToCSVRequest;
}

export interface GetActiveTradeShowSectionsRequest {
    eventId: string;
}

export interface GetBuyerDirectoryHeaderDetailsRequest {
    eventId: string;
}

export interface GetExhibitorDirectoryHeaderDetailsRequest {
    eventId: string;
}

export interface GetExhibitorsRequest {
    eventId: string;
}

export interface GetExhibitorsPosthookRequest {
    eventId: string;
    body: string;
}

export interface GetMarketingCategoryValuesByTradeShowRequest {
    eventId: string;
}

export interface GetTradeShowBuyersRequest {
    eventId: string;
    page: number;
    size: number;
    sortOrder: string;
    state?: string;
    country?: string;
    sections?: string;
    search?: string;
}

export interface GetTradeShowByEventIdRequest {
    eventId: string;
}

export interface GetTradeShowCountriesRequest {
    eventId: string;
}

export interface GetTradeShowDetailsByEventIdRequest {
    eventId: string;
}

export interface GetTradeShowRecommendedBrandsRequest {
    eventId: string;
    brandId: string;
}

export interface ImportBulletinIncubatorBrandsOperationRequest {
    importBulletinIncubatorBrandsRequest: ImportBulletinIncubatorBrandsRequest;
}

export interface ImportTradeShowBrandByCompanyIdRequest {
    eventId: string;
    companyId: string;
}

export interface LinkRetailersWithTradeShowOperationRequest {
    eventId: string;
    linkRetailersWithTradeShowRequest?: LinkRetailersWithTradeShowRequest;
}

export interface PreventEndDateOverlapOperationRequest {
    preventEndDateOverlapRequest: PreventEndDateOverlapRequest;
}

export interface ReindexTradeShowBrandsRequest {
    reindexTradeShowBrandRequest: ReindexTradeShowBrandRequest;
}

export interface ResendTradeShowAttendeesWelcomeEmailRequest {
    eventId: string;
}

export interface SendTradeShowAttendeesWelcomeEmailRequest {
    eventId: string;
    sendWelcomeEmailToTradeShowAttendeesRequest: SendWelcomeEmailToTradeShowAttendeesRequest;
}

export interface SendTradeShowWelcomeEmailsToBrandsRequest {
    eventId: string;
    sendTradeShowWelcomeEmailsRequest: SendTradeShowWelcomeEmailsRequest;
}

export interface UpdateTradeShowOperationRequest {
    id: string;
    updateTradeShowRequest: UpdateTradeShowRequest;
}

export interface UpdateTradeShowLinksOperationRequest {
    id: string;
    updateTradeShowLinksRequest: UpdateTradeShowLinksRequest;
}

/**
 * 
 */
export class TradeShowControllerApi extends runtime.BaseAPI {

    /**
     * Add buyer directory header for the specific trade show
     */
    async addBuyerDirectoryHeaderDetailsRaw(requestParameters: AddBuyerDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerDirectoryHeaderResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling addBuyerDirectoryHeaderDetails().'
            );
        }

        if (requestParameters['buyerDirectoryHeaderRequest'] == null) {
            throw new runtime.RequiredError(
                'buyerDirectoryHeaderRequest',
                'Required parameter "buyerDirectoryHeaderRequest" was null or undefined when calling addBuyerDirectoryHeaderDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/buyer-directory-header`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BuyerDirectoryHeaderRequestToJSON(requestParameters['buyerDirectoryHeaderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerDirectoryHeaderResponseFromJSON(jsonValue));
    }

    /**
     * Add buyer directory header for the specific trade show
     */
    async addBuyerDirectoryHeaderDetails(requestParameters: AddBuyerDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerDirectoryHeaderResponse> {
        const response = await this.addBuyerDirectoryHeaderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add exhibitor directory header for the specific trade show
     */
    async addExhibitorDirectoryHeaderDetailsRaw(requestParameters: AddExhibitorDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExhibitorDirectoryHeaderResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling addExhibitorDirectoryHeaderDetails().'
            );
        }

        if (requestParameters['exhibitorDirectoryHeaderRequest'] == null) {
            throw new runtime.RequiredError(
                'exhibitorDirectoryHeaderRequest',
                'Required parameter "exhibitorDirectoryHeaderRequest" was null or undefined when calling addExhibitorDirectoryHeaderDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/exhibitor-directory-header`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExhibitorDirectoryHeaderRequestToJSON(requestParameters['exhibitorDirectoryHeaderRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitorDirectoryHeaderResponseFromJSON(jsonValue));
    }

    /**
     * Add exhibitor directory header for the specific trade show
     */
    async addExhibitorDirectoryHeaderDetails(requestParameters: AddExhibitorDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExhibitorDirectoryHeaderResponse> {
        const response = await this.addExhibitorDirectoryHeaderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new trade show
     */
    async createTradeShowRaw(requestParameters: CreateTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createTradeShowRequest'] == null) {
            throw new runtime.RequiredError(
                'createTradeShowRequest',
                'Required parameter "createTradeShowRequest" was null or undefined when calling createTradeShow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTradeShowRequestToJSON(requestParameters['createTradeShowRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create a new trade show
     */
    async createTradeShow(requestParameters: CreateTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createTradeShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete trade show
     */
    async deleteTradeShowRaw(requestParameters: DeleteTradeShowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTradeShow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete trade show
     */
    async deleteTradeShow(requestParameters: DeleteTradeShowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTradeShowRaw(requestParameters, initOverrides);
    }

    /**
     * Export trade show exhibitors to the CSV file.
     */
    async exportTradeShowExhibitorsToCSVRaw(requestParameters: ExportTradeShowExhibitorsToCSVOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling exportTradeShowExhibitorsToCSV().'
            );
        }

        if (requestParameters['exportTradeShowExhibitorsToCSVRequest'] == null) {
            throw new runtime.RequiredError(
                'exportTradeShowExhibitorsToCSVRequest',
                'Required parameter "exportTradeShowExhibitorsToCSVRequest" was null or undefined when calling exportTradeShowExhibitorsToCSV().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/exhibitors-export`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportTradeShowExhibitorsToCSVRequestToJSON(requestParameters['exportTradeShowExhibitorsToCSVRequest']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export trade show exhibitors to the CSV file.
     */
    async exportTradeShowExhibitorsToCSV(requestParameters: ExportTradeShowExhibitorsToCSVOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportTradeShowExhibitorsToCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of sections for a given Trade Show ID
     */
    async getActiveTradeShowSectionsRaw(requestParameters: GetActiveTradeShowSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getActiveTradeShowSections().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/sections`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get list of sections for a given Trade Show ID
     */
    async getActiveTradeShowSections(requestParameters: GetActiveTradeShowSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getActiveTradeShowSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get active trade shows
     * @deprecated
     */
    async getActiveTradeShowsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TradeShowResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TradeShowResponseFromJSON));
    }

    /**
     * Get active trade shows
     * @deprecated
     */
    async getActiveTradeShows(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TradeShowResponse>> {
        const response = await this.getActiveTradeShowsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Admin view of all Trade Shows
     */
    async getAllTradeShowsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllTradeShowsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/admin-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllTradeShowsResponseFromJSON(jsonValue));
    }

    /**
     * Get Admin view of all Trade Shows
     */
    async getAllTradeShows(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllTradeShowsResponse> {
        const response = await this.getAllTradeShowsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get exhibitor directory header by trade show id
     */
    async getBuyerDirectoryHeaderDetailsRaw(requestParameters: GetBuyerDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyerDirectoryHeaderResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getBuyerDirectoryHeaderDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/buyer-directory-header`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyerDirectoryHeaderResponseFromJSON(jsonValue));
    }

    /**
     * Get exhibitor directory header by trade show id
     */
    async getBuyerDirectoryHeaderDetails(requestParameters: GetBuyerDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyerDirectoryHeaderResponse> {
        const response = await this.getBuyerDirectoryHeaderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get exhibitor directory header by trade show id
     */
    async getExhibitorDirectoryHeaderDetailsRaw(requestParameters: GetExhibitorDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExhibitorDirectoryHeaderResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getExhibitorDirectoryHeaderDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/exhibitor-directory-header`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExhibitorDirectoryHeaderResponseFromJSON(jsonValue));
    }

    /**
     * Get exhibitor directory header by trade show id
     */
    async getExhibitorDirectoryHeaderDetails(requestParameters: GetExhibitorDirectoryHeaderDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExhibitorDirectoryHeaderResponse> {
        const response = await this.getExhibitorDirectoryHeaderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch a list of exhibitors for the current trade show from A2z. This API is available in case a manual trigger is needed. 
     */
    async getExhibitorsRaw(requestParameters: GetExhibitorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getExhibitors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/exhibitors`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fetch a list of exhibitors for the current trade show from A2z. This API is available in case a manual trigger is needed. 
     */
    async getExhibitors(requestParameters: GetExhibitorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getExhibitorsRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch a list of exhibitors for the current trade show from A2z. This API is triggered by a Posthook sequence.
     */
    async getExhibitorsPosthookRaw(requestParameters: GetExhibitorsPosthookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getExhibitorsPosthook().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling getExhibitorsPosthook().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/posthook/{eventId}/exhibitors`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fetch a list of exhibitors for the current trade show from A2z. This API is triggered by a Posthook sequence.
     */
    async getExhibitorsPosthook(requestParameters: GetExhibitorsPosthookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getExhibitorsPosthookRaw(requestParameters, initOverrides);
    }

    /**
     * Get marketing category values by trade show ID
     */
    async getMarketingCategoryValuesByTradeShowRaw(requestParameters: GetMarketingCategoryValuesByTradeShowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingCategoriesResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getMarketingCategoryValuesByTradeShow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/marketing-category-values`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingCategoriesResponseFromJSON(jsonValue));
    }

    /**
     * Get marketing category values by trade show ID
     */
    async getMarketingCategoryValuesByTradeShow(requestParameters: GetMarketingCategoryValuesByTradeShowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingCategoriesResponse> {
        const response = await this.getMarketingCategoryValuesByTradeShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Trade Show Buyers by eventId
     */
    async getTradeShowBuyersRaw(requestParameters: GetTradeShowBuyersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTradeShowBuyersResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getTradeShowBuyers().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getTradeShowBuyers().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getTradeShowBuyers().'
            );
        }

        if (requestParameters['sortOrder'] == null) {
            throw new runtime.RequiredError(
                'sortOrder',
                'Required parameter "sortOrder" was null or undefined when calling getTradeShowBuyers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortOrder'] != null) {
            queryParameters['sort_order'] = requestParameters['sortOrder'];
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['sections'] != null) {
            queryParameters['sections'] = requestParameters['sections'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/buyer-directory`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTradeShowBuyersResponseFromJSON(jsonValue));
    }

    /**
     * Get Trade Show Buyers by eventId
     */
    async getTradeShowBuyers(requestParameters: GetTradeShowBuyersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTradeShowBuyersResponse> {
        const response = await this.getTradeShowBuyersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get trade show by event id
     */
    async getTradeShowByEventIdRaw(requestParameters: GetTradeShowByEventIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TradeShowResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getTradeShowByEventId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeShowResponseFromJSON(jsonValue));
    }

    /**
     * Get trade show by event id
     */
    async getTradeShowByEventId(requestParameters: GetTradeShowByEventIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TradeShowResponse> {
        const response = await this.getTradeShowByEventIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Trade Show Countries by eventId
     */
    async getTradeShowCountriesRaw(requestParameters: GetTradeShowCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TradeShowCountryResponse>>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getTradeShowCountries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/countries`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TradeShowCountryResponseFromJSON));
    }

    /**
     * Get Trade Show Countries by eventId
     */
    async getTradeShowCountries(requestParameters: GetTradeShowCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TradeShowCountryResponse>> {
        const response = await this.getTradeShowCountriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Admin view of the specific Trade Show (by eventId)
     */
    async getTradeShowDetailsByEventIdRaw(requestParameters: GetTradeShowDetailsByEventIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTradeShowByEventIdResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getTradeShowDetailsByEventId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/admin-view`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTradeShowByEventIdResponseFromJSON(jsonValue));
    }

    /**
     * Get Admin view of the specific Trade Show (by eventId)
     */
    async getTradeShowDetailsByEventId(requestParameters: GetTradeShowDetailsByEventIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTradeShowByEventIdResponse> {
        const response = await this.getTradeShowDetailsByEventIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTradeShowEnabledFeaturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TradeShowEnabledFeaturesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/enabled-features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeShowEnabledFeaturesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTradeShowEnabledFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TradeShowEnabledFeaturesResponse> {
        const response = await this.getTradeShowEnabledFeaturesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of recommended brands by trade show ID
     */
    async getTradeShowRecommendedBrandsRaw(requestParameters: GetTradeShowRecommendedBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTradeShowRecommendedBrandsResponse>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getTradeShowRecommendedBrands().'
            );
        }

        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getTradeShowRecommendedBrands().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/brand/{brandId}/recommended-brands`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))).replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTradeShowRecommendedBrandsResponseFromJSON(jsonValue));
    }

    /**
     * Get list of recommended brands by trade show ID
     */
    async getTradeShowRecommendedBrands(requestParameters: GetTradeShowRecommendedBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTradeShowRecommendedBrandsResponse> {
        const response = await this.getTradeShowRecommendedBrandsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import exhibitors from Bulletin Incubator.
     */
    async importBulletinIncubatorBrandsRaw(requestParameters: ImportBulletinIncubatorBrandsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['importBulletinIncubatorBrandsRequest'] == null) {
            throw new runtime.RequiredError(
                'importBulletinIncubatorBrandsRequest',
                'Required parameter "importBulletinIncubatorBrandsRequest" was null or undefined when calling importBulletinIncubatorBrands().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/bulletin-incubator/exhibitors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportBulletinIncubatorBrandsRequestToJSON(requestParameters['importBulletinIncubatorBrandsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import exhibitors from Bulletin Incubator.
     */
    async importBulletinIncubatorBrands(requestParameters: ImportBulletinIncubatorBrandsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importBulletinIncubatorBrandsRaw(requestParameters, initOverrides);
    }

    /**
     * Import trade show brand from A2z with specific company id
     */
    async importTradeShowBrandByCompanyIdRaw(requestParameters: ImportTradeShowBrandByCompanyIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling importTradeShowBrandByCompanyId().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling importTradeShowBrandByCompanyId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/coid/{companyId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))).replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import trade show brand from A2z with specific company id
     */
    async importTradeShowBrandByCompanyId(requestParameters: ImportTradeShowBrandByCompanyIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importTradeShowBrandByCompanyIdRaw(requestParameters, initOverrides);
    }

    /**
     * Link retailers with trade show and change their status to be approved.
     */
    async linkRetailersWithTradeShowRaw(requestParameters: LinkRetailersWithTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling linkRetailersWithTradeShow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/link-retailers`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRetailersWithTradeShowRequestToJSON(requestParameters['linkRetailersWithTradeShowRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Link retailers with trade show and change their status to be approved.
     */
    async linkRetailersWithTradeShow(requestParameters: LinkRetailersWithTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.linkRetailersWithTradeShowRaw(requestParameters, initOverrides);
    }

    /**
     * The end date of existing levers should not overlap with the start of these levers.
     */
    async preventEndDateOverlapRaw(requestParameters: PreventEndDateOverlapOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TradeShowFeatureResponse>>> {
        if (requestParameters['preventEndDateOverlapRequest'] == null) {
            throw new runtime.RequiredError(
                'preventEndDateOverlapRequest',
                'Required parameter "preventEndDateOverlapRequest" was null or undefined when calling preventEndDateOverlap().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/levers/prevent-end-date-overlap`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PreventEndDateOverlapRequestToJSON(requestParameters['preventEndDateOverlapRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TradeShowFeatureResponseFromJSON));
    }

    /**
     * The end date of existing levers should not overlap with the start of these levers.
     */
    async preventEndDateOverlap(requestParameters: PreventEndDateOverlapOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TradeShowFeatureResponse>> {
        const response = await this.preventEndDateOverlapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reindexTradeShowBrandsRaw(requestParameters: ReindexTradeShowBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['reindexTradeShowBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'reindexTradeShowBrandRequest',
                'Required parameter "reindexTradeShowBrandRequest" was null or undefined when calling reindexTradeShowBrands().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/brands/reindex`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReindexTradeShowBrandRequestToJSON(requestParameters['reindexTradeShowBrandRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reindexTradeShowBrands(requestParameters: ReindexTradeShowBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reindexTradeShowBrandsRaw(requestParameters, initOverrides);
    }

    /**
     * Resend trade show attendees welcome email for the retailers that have not changed their password
     */
    async resendTradeShowAttendeesWelcomeEmailRaw(requestParameters: ResendTradeShowAttendeesWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling resendTradeShowAttendeesWelcomeEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/resend-retailer-welcome-email`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend trade show attendees welcome email for the retailers that have not changed their password
     */
    async resendTradeShowAttendeesWelcomeEmail(requestParameters: ResendTradeShowAttendeesWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendTradeShowAttendeesWelcomeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Send trade show attendees welcome email fron the specific event
     */
    async sendTradeShowAttendeesWelcomeEmailRaw(requestParameters: SendTradeShowAttendeesWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling sendTradeShowAttendeesWelcomeEmail().'
            );
        }

        if (requestParameters['sendWelcomeEmailToTradeShowAttendeesRequest'] == null) {
            throw new runtime.RequiredError(
                'sendWelcomeEmailToTradeShowAttendeesRequest',
                'Required parameter "sendWelcomeEmailToTradeShowAttendeesRequest" was null or undefined when calling sendTradeShowAttendeesWelcomeEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/send-retailer-welcome-email`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendWelcomeEmailToTradeShowAttendeesRequestToJSON(requestParameters['sendWelcomeEmailToTradeShowAttendeesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send trade show attendees welcome email fron the specific event
     */
    async sendTradeShowAttendeesWelcomeEmail(requestParameters: SendTradeShowAttendeesWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendTradeShowAttendeesWelcomeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * This is a utility endpoint to manually send trade show welcome emails in case of need.
     */
    async sendTradeShowWelcomeEmailsToBrandsRaw(requestParameters: SendTradeShowWelcomeEmailsToBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling sendTradeShowWelcomeEmailsToBrands().'
            );
        }

        if (requestParameters['sendTradeShowWelcomeEmailsRequest'] == null) {
            throw new runtime.RequiredError(
                'sendTradeShowWelcomeEmailsRequest',
                'Required parameter "sendTradeShowWelcomeEmailsRequest" was null or undefined when calling sendTradeShowWelcomeEmailsToBrands().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{eventId}/send-welcome-emails`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendTradeShowWelcomeEmailsRequestToJSON(requestParameters['sendTradeShowWelcomeEmailsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This is a utility endpoint to manually send trade show welcome emails in case of need.
     */
    async sendTradeShowWelcomeEmailsToBrands(requestParameters: SendTradeShowWelcomeEmailsToBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendTradeShowWelcomeEmailsToBrandsRaw(requestParameters, initOverrides);
    }

    /**
     * Update trade show
     */
    async updateTradeShowRaw(requestParameters: UpdateTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTradeShow().'
            );
        }

        if (requestParameters['updateTradeShowRequest'] == null) {
            throw new runtime.RequiredError(
                'updateTradeShowRequest',
                'Required parameter "updateTradeShowRequest" was null or undefined when calling updateTradeShow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTradeShowRequestToJSON(requestParameters['updateTradeShowRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update trade show
     */
    async updateTradeShow(requestParameters: UpdateTradeShowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTradeShowRaw(requestParameters, initOverrides);
    }

    /**
     * Update links related to the selected trade show.
     */
    async updateTradeShowLinksRaw(requestParameters: UpdateTradeShowLinksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTradeShowLinks().'
            );
        }

        if (requestParameters['updateTradeShowLinksRequest'] == null) {
            throw new runtime.RequiredError(
                'updateTradeShowLinksRequest',
                'Required parameter "updateTradeShowLinksRequest" was null or undefined when calling updateTradeShowLinks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/trade-shows/{id}/links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTradeShowLinksRequestToJSON(requestParameters['updateTradeShowLinksRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update links related to the selected trade show.
     */
    async updateTradeShowLinks(requestParameters: UpdateTradeShowLinksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTradeShowLinksRaw(requestParameters, initOverrides);
    }

}
