/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateSuborderItemRequest } from './UpdateSuborderItemRequest';
import {
    UpdateSuborderItemRequestFromJSON,
    UpdateSuborderItemRequestFromJSONTyped,
    UpdateSuborderItemRequestToJSON,
    UpdateSuborderItemRequestToJSONTyped,
} from './UpdateSuborderItemRequest';

/**
 * Update suborder object
 * @export
 * @interface UpdateSuborderRequest
 */
export interface UpdateSuborderRequest {
    /**
     * 
     * @type {Array<UpdateSuborderItemRequest>}
     * @memberof UpdateSuborderRequest
     */
    items?: Array<UpdateSuborderItemRequest>;
}

/**
 * Check if a given object implements the UpdateSuborderRequest interface.
 */
export function instanceOfUpdateSuborderRequest(value: object): value is UpdateSuborderRequest {
    return true;
}

export function UpdateSuborderRequestFromJSON(json: any): UpdateSuborderRequest {
    return UpdateSuborderRequestFromJSONTyped(json, false);
}

export function UpdateSuborderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSuborderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(UpdateSuborderItemRequestFromJSON)),
    };
}

export function UpdateSuborderRequestToJSON(json: any): UpdateSuborderRequest {
    return UpdateSuborderRequestToJSONTyped(json, false);
}

export function UpdateSuborderRequestToJSONTyped(value?: UpdateSuborderRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(UpdateSuborderItemRequestToJSON)),
    };
}

