/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create brand object
 * @export
 * @interface CreateBrandRequest
 */
export interface CreateBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    brand_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    brand_description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    brand_bio_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    based_in?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBrandRequest
     */
    product_categories?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    max_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    minimum_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    increment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandRequest
     */
    instagram_growth_help?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    website_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    instagram_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    instagram_followers?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    minimum_order_value: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    minimum_reorder_value?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBrandRequest
     */
    values?: Array<CreateBrandRequestValuesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandRequest
     */
    is_published: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateBrandRequest
     */
    address?: object;
}


/**
 * @export
 */
export const CreateBrandRequestValuesEnum = {
    FemaleFounded: 'FEMALE_FOUNDED',
    EthicalProduction: 'ETHICAL_PRODUCTION',
    CleanIngredients: 'CLEAN_INGREDIENTS',
    Sustainable: 'SUSTAINABLE',
    Handmade: 'HANDMADE',
    MadeInTheUs: 'MADE_IN_THE_US',
    CharitableBrandGiveBack: 'CHARITABLE_BRAND_GIVE_BACK',
    Organic: 'ORGANIC',
    ZeroWaste: 'ZERO_WASTE',
    BlackOwned: 'BLACK_OWNED',
    LatinxOwned: 'LATINX_OWNED',
    AapiOwned: 'AAPI_OWNED',
    IndigenousOwned: 'INDIGENOUS_OWNED',
    LgbtqOwned: 'LGBTQ_OWNED',
    MinorityOwnedBusiness: 'MINORITY_OWNED_BUSINESS',
    DisabilityOwned: 'DISABILITY_OWNED'
} as const;
export type CreateBrandRequestValuesEnum = typeof CreateBrandRequestValuesEnum[keyof typeof CreateBrandRequestValuesEnum];


/**
 * Check if a given object implements the CreateBrandRequest interface.
 */
export function instanceOfCreateBrandRequest(value: object): value is CreateBrandRequest {
    if (!('brand_name' in value) || value['brand_name'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('brand_description' in value) || value['brand_description'] === undefined) return false;
    if (!('minimum_order_value' in value) || value['minimum_order_value'] === undefined) return false;
    if (!('is_published' in value) || value['is_published'] === undefined) return false;
    return true;
}

export function CreateBrandRequestFromJSON(json: any): CreateBrandRequest {
    return CreateBrandRequestFromJSONTyped(json, false);
}

export function CreateBrandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBrandRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_name': json['brand_name'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'brand_description': json['brand_description'],
        'brand_bio_image_url': json['brand_bio_image_url'] == null ? undefined : json['brand_bio_image_url'],
        'based_in': json['based_in'] == null ? undefined : json['based_in'],
        'product_categories': json['product_categories'] == null ? undefined : json['product_categories'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'minimum_quantity': json['minimum_quantity'] == null ? undefined : json['minimum_quantity'],
        'increment': json['increment'] == null ? undefined : json['increment'],
        'instagram_growth_help': json['instagram_growth_help'] == null ? undefined : json['instagram_growth_help'],
        'website_url': json['website_url'] == null ? undefined : json['website_url'],
        'instagram_url': json['instagram_url'] == null ? undefined : json['instagram_url'],
        'instagram_followers': json['instagram_followers'] == null ? undefined : json['instagram_followers'],
        'minimum_order_value': json['minimum_order_value'],
        'minimum_reorder_value': json['minimum_reorder_value'] == null ? undefined : json['minimum_reorder_value'],
        'values': json['values'] == null ? undefined : json['values'],
        'is_published': json['is_published'],
        'address': json['address'] == null ? undefined : json['address'],
    };
}

export function CreateBrandRequestToJSON(json: any): CreateBrandRequest {
    return CreateBrandRequestToJSONTyped(json, false);
}

export function CreateBrandRequestToJSONTyped(value?: CreateBrandRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_name': value['brand_name'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'brand_description': value['brand_description'],
        'brand_bio_image_url': value['brand_bio_image_url'],
        'based_in': value['based_in'],
        'product_categories': value['product_categories'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'minimum_quantity': value['minimum_quantity'],
        'increment': value['increment'],
        'instagram_growth_help': value['instagram_growth_help'],
        'website_url': value['website_url'],
        'instagram_url': value['instagram_url'],
        'instagram_followers': value['instagram_followers'],
        'minimum_order_value': value['minimum_order_value'],
        'minimum_reorder_value': value['minimum_reorder_value'],
        'values': value['values'],
        'is_published': value['is_published'],
        'address': value['address'],
    };
}

