/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedInvoicesResponse,
} from '../models/index';
import {
    PaginatedInvoicesResponseFromJSON,
    PaginatedInvoicesResponseToJSON,
} from '../models/index';

export interface GetInvoicesRequest {
    retailerId?: string;
    brandId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
    status?: string;
    sortName?: string;
    sortValue?: string;
}

export interface GetInvoicesByRetailerRequest {
    retailerId: string;
    brandId?: string;
    page?: number;
    size?: number;
    searchBy?: string;
    status?: string;
    sortName?: string;
    sortValue?: string;
}

/**
 * 
 */
export class InvoiceControllerApi extends runtime.BaseAPI {

    /**
     * Find all invoices per multiple/custom criteria
     */
    async getInvoicesRaw(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInvoicesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['sortName'] != null) {
            queryParameters['sortName'] = requestParameters['sortName'];
        }

        if (requestParameters['sortValue'] != null) {
            queryParameters['sortValue'] = requestParameters['sortValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInvoicesResponseFromJSON(jsonValue));
    }

    /**
     * Find all invoices per multiple/custom criteria
     */
    async getInvoices(requestParameters: GetInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInvoicesResponse> {
        const response = await this.getInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all invoices for provided brand
     */
    async getInvoicesByRetailerRaw(requestParameters: GetInvoicesByRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInvoicesResponse>> {
        if (requestParameters['retailerId'] == null) {
            throw new runtime.RequiredError(
                'retailerId',
                'Required parameter "retailerId" was null or undefined when calling getInvoicesByRetailer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['retailerId'] != null) {
            queryParameters['retailerId'] = requestParameters['retailerId'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['sortName'] != null) {
            queryParameters['sortName'] = requestParameters['sortName'];
        }

        if (requestParameters['sortValue'] != null) {
            queryParameters['sortValue'] = requestParameters['sortValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoices/retailer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInvoicesResponseFromJSON(jsonValue));
    }

    /**
     * Find all invoices for provided brand
     */
    async getInvoicesByRetailer(requestParameters: GetInvoicesByRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInvoicesResponse> {
        const response = await this.getInvoicesByRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
