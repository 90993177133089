/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandReferralResponse
 */
export interface BrandReferralResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandReferralResponse
     */
    brand_name?: string;
}

/**
 * Check if a given object implements the BrandReferralResponse interface.
 */
export function instanceOfBrandReferralResponse(value: object): value is BrandReferralResponse {
    return true;
}

export function BrandReferralResponseFromJSON(json: any): BrandReferralResponse {
    return BrandReferralResponseFromJSONTyped(json, false);
}

export function BrandReferralResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandReferralResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
    };
}

export function BrandReferralResponseToJSON(json: any): BrandReferralResponse {
    return BrandReferralResponseToJSONTyped(json, false);
}

export function BrandReferralResponseToJSONTyped(value?: BrandReferralResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand_name': value['brand_name'],
    };
}

