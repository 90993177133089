<template>
  <div class="section-headline">
    <div class="flex">
      <h4 class="pr-15">
        <a :href="url" target="_blank">{{ title }}</a>
      </h4>
      <hr class="flex-grow" />
    </div>
    <p v-if="label" class="mt-10 mt-0-sm font-size-12-sm pt-5-sm line-height-16-sm">
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['title', 'label', 'url']
}
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/base/_vars.scss';
@import '~@/assets/sass/base/_helpers.scss';
.section-headline {
  position: relative;
  margin-bottom: 40px;

  h4 {
    position: relative;
    z-index: 8;
    display: inline-block;
    padding-right: 15px;
    line-height: 14px;
    text-transform: uppercase;

    @include respond-to(small) {
      a {
        font-size: 9px;
      }
    }
  }

  a {
    white-space: nowrap;
  }

  hr {
    transform: translateY(7px);
  }
}
.section-headline.pad-top {
  padding-top: 30px;
}
</style>
