/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductOrdinalDto } from './ProductOrdinalDto';
import {
    ProductOrdinalDtoFromJSON,
    ProductOrdinalDtoFromJSONTyped,
    ProductOrdinalDtoToJSON,
    ProductOrdinalDtoToJSONTyped,
} from './ProductOrdinalDto';
import type { ProductSearchCriteriaRequest } from './ProductSearchCriteriaRequest';
import {
    ProductSearchCriteriaRequestFromJSON,
    ProductSearchCriteriaRequestFromJSONTyped,
    ProductSearchCriteriaRequestToJSON,
    ProductSearchCriteriaRequestToJSONTyped,
} from './ProductSearchCriteriaRequest';

/**
 * 
 * @export
 * @interface UpdateCollectionRequest
 */
export interface UpdateCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCollectionRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCollectionRequest
     */
    internal_title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCollectionRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCollectionRequest
     */
    active: boolean;
    /**
     * 
     * @type {Array<ProductOrdinalDto>}
     * @memberof UpdateCollectionRequest
     */
    product_ids?: Array<ProductOrdinalDto>;
    /**
     * 
     * @type {Array<ProductOrdinalDto>}
     * @memberof UpdateCollectionRequest
     */
    removed_product_ids?: Array<ProductOrdinalDto>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCollectionRequest
     */
    include_all_conditions?: boolean;
    /**
     * 
     * @type {Array<ProductSearchCriteriaRequest>}
     * @memberof UpdateCollectionRequest
     */
    product_search_criteria?: Array<ProductSearchCriteriaRequest>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCollectionRequest
     */
    featured_category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCollectionRequest
     */
    collection_type?: UpdateCollectionRequestCollectionTypeEnum;
}


/**
 * @export
 */
export const UpdateCollectionRequestCollectionTypeEnum = {
    Manual: 'MANUAL',
    Automated: 'AUTOMATED',
    Parent: 'PARENT'
} as const;
export type UpdateCollectionRequestCollectionTypeEnum = typeof UpdateCollectionRequestCollectionTypeEnum[keyof typeof UpdateCollectionRequestCollectionTypeEnum];


/**
 * Check if a given object implements the UpdateCollectionRequest interface.
 */
export function instanceOfUpdateCollectionRequest(value: object): value is UpdateCollectionRequest {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function UpdateCollectionRequestFromJSON(json: any): UpdateCollectionRequest {
    return UpdateCollectionRequestFromJSONTyped(json, false);
}

export function UpdateCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'internal_title': json['internal_title'] == null ? undefined : json['internal_title'],
        'description': json['description'] == null ? undefined : json['description'],
        'active': json['active'],
        'product_ids': json['product_ids'] == null ? undefined : ((json['product_ids'] as Array<any>).map(ProductOrdinalDtoFromJSON)),
        'removed_product_ids': json['removed_product_ids'] == null ? undefined : ((json['removed_product_ids'] as Array<any>).map(ProductOrdinalDtoFromJSON)),
        'include_all_conditions': json['include_all_conditions'] == null ? undefined : json['include_all_conditions'],
        'product_search_criteria': json['product_search_criteria'] == null ? undefined : ((json['product_search_criteria'] as Array<any>).map(ProductSearchCriteriaRequestFromJSON)),
        'featured_category_id': json['featured_category_id'] == null ? undefined : json['featured_category_id'],
        'collection_type': json['collection_type'] == null ? undefined : json['collection_type'],
    };
}

export function UpdateCollectionRequestToJSON(json: any): UpdateCollectionRequest {
    return UpdateCollectionRequestToJSONTyped(json, false);
}

export function UpdateCollectionRequestToJSONTyped(value?: UpdateCollectionRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'internal_title': value['internal_title'],
        'description': value['description'],
        'active': value['active'],
        'product_ids': value['product_ids'] == null ? undefined : ((value['product_ids'] as Array<any>).map(ProductOrdinalDtoToJSON)),
        'removed_product_ids': value['removed_product_ids'] == null ? undefined : ((value['removed_product_ids'] as Array<any>).map(ProductOrdinalDtoToJSON)),
        'include_all_conditions': value['include_all_conditions'],
        'product_search_criteria': value['product_search_criteria'] == null ? undefined : ((value['product_search_criteria'] as Array<any>).map(ProductSearchCriteriaRequestToJSON)),
        'featured_category_id': value['featured_category_id'],
        'collection_type': value['collection_type'],
    };
}

