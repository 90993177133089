/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinimumOrderValuesResponse
 */
export interface MinimumOrderValuesResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof MinimumOrderValuesResponse
     */
    minimum_order_values?: Array<number>;
}

/**
 * Check if a given object implements the MinimumOrderValuesResponse interface.
 */
export function instanceOfMinimumOrderValuesResponse(value: object): value is MinimumOrderValuesResponse {
    return true;
}

export function MinimumOrderValuesResponseFromJSON(json: any): MinimumOrderValuesResponse {
    return MinimumOrderValuesResponseFromJSONTyped(json, false);
}

export function MinimumOrderValuesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinimumOrderValuesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'minimum_order_values': json['minimum_order_values'] == null ? undefined : json['minimum_order_values'],
    };
}

export function MinimumOrderValuesResponseToJSON(json: any): MinimumOrderValuesResponse {
    return MinimumOrderValuesResponseToJSONTyped(json, false);
}

export function MinimumOrderValuesResponseToJSONTyped(value?: MinimumOrderValuesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'minimum_order_values': value['minimum_order_values'],
    };
}

