/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddNewReferralsRequest,
  AddNewReferralsResponse,
  CreateReferralsByEmailsResponse,
  CreateReferralsRequest,
  ImportReferralsFromCSVRequest,
  PaginatedRefferalsResponse,
  ReferralLinkResponse,
  ReferralResponse,
} from '../models/index';
import {
    AddNewReferralsRequestFromJSON,
    AddNewReferralsRequestToJSON,
    AddNewReferralsResponseFromJSON,
    AddNewReferralsResponseToJSON,
    CreateReferralsByEmailsResponseFromJSON,
    CreateReferralsByEmailsResponseToJSON,
    CreateReferralsRequestFromJSON,
    CreateReferralsRequestToJSON,
    ImportReferralsFromCSVRequestFromJSON,
    ImportReferralsFromCSVRequestToJSON,
    PaginatedRefferalsResponseFromJSON,
    PaginatedRefferalsResponseToJSON,
    ReferralLinkResponseFromJSON,
    ReferralLinkResponseToJSON,
    ReferralResponseFromJSON,
    ReferralResponseToJSON,
} from '../models/index';

export interface AddNewReferralsOperationRequest {
    addNewReferralsRequest: Array<AddNewReferralsRequest>;
}

export interface CreateReferralsOperationRequest {
    createReferralsRequest: CreateReferralsRequest;
}

export interface GetReferralRequest {
    id: string;
}

export interface GetReferralsRequest {
    brandId?: string;
    page?: number;
    size?: number;
    search?: string;
}

export interface GetReferralsByBrandIdRequest {
    brandId: string;
    page?: number;
    size?: number;
    search?: string;
}

export interface ImportReferralsFromCSVOperationRequest {
    importReferralsFromCSVRequest?: ImportReferralsFromCSVRequest;
}

export interface SendReferralCompletenessEmailRequest {
    id: string;
    body: string;
}

export interface ToggleReferralCompletenessRequest {
    id: string;
}

/**
 * 
 */
export class ReferralControllerApi extends runtime.BaseAPI {

    /**
     * Add new referrals to database
     */
    async addNewReferralsRaw(requestParameters: AddNewReferralsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddNewReferralsResponse>> {
        if (requestParameters['addNewReferralsRequest'] == null) {
            throw new runtime.RequiredError(
                'addNewReferralsRequest',
                'Required parameter "addNewReferralsRequest" was null or undefined when calling addNewReferrals().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['addNewReferralsRequest']!.map(AddNewReferralsRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddNewReferralsResponseFromJSON(jsonValue));
    }

    /**
     * Add new referrals to database
     */
    async addNewReferrals(requestParameters: AddNewReferralsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddNewReferralsResponse> {
        const response = await this.addNewReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create referrals
     */
    async createReferralsRaw(requestParameters: CreateReferralsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateReferralsByEmailsResponse>> {
        if (requestParameters['createReferralsRequest'] == null) {
            throw new runtime.RequiredError(
                'createReferralsRequest',
                'Required parameter "createReferralsRequest" was null or undefined when calling createReferrals().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReferralsRequestToJSON(requestParameters['createReferralsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateReferralsByEmailsResponseFromJSON(jsonValue));
    }

    /**
     * Create referrals
     */
    async createReferrals(requestParameters: CreateReferralsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateReferralsByEmailsResponse> {
        const response = await this.createReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download csv template which should be filled with referral emails
     */
    async downloadCsvFileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/download-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download csv template which should be filled with referral emails
     */
    async downloadCsvFile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadCsvFileRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find Referral by Id
     */
    async getReferralRaw(requestParameters: GetReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getReferral().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralResponseFromJSON(jsonValue));
    }

    /**
     * Find Referral by Id
     */
    async getReferral(requestParameters: GetReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralResponse> {
        const response = await this.getReferralRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get referral link
     */
    async getReferralLinkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralLinkResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralLinkResponseFromJSON(jsonValue));
    }

    /**
     * Get referral link
     */
    async getReferralLink(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralLinkResponse> {
        const response = await this.getReferralLinkRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all referrals per multiple/custom criteria
     */
    async getReferralsRaw(requestParameters: GetReferralsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRefferalsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRefferalsResponseFromJSON(jsonValue));
    }

    /**
     * Find all referrals per multiple/custom criteria
     */
    async getReferrals(requestParameters: GetReferralsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRefferalsResponse> {
        const response = await this.getReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all referrals by brand
     */
    async getReferralsByBrandIdRaw(requestParameters: GetReferralsByBrandIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRefferalsResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling getReferralsByBrandId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/brands/{brandId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRefferalsResponseFromJSON(jsonValue));
    }

    /**
     * Find all referrals by brand
     */
    async getReferralsByBrandId(requestParameters: GetReferralsByBrandIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRefferalsResponse> {
        const response = await this.getReferralsByBrandIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import referrals from csv to database.Receives html file and csv file. Returns array of invalid referral emails.
     */
    async importReferralsFromCSVRaw(requestParameters: ImportReferralsFromCSVOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateReferralsByEmailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/import-from-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportReferralsFromCSVRequestToJSON(requestParameters['importReferralsFromCSVRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateReferralsByEmailsResponseFromJSON(jsonValue));
    }

    /**
     * Import referrals from csv to database.Receives html file and csv file. Returns array of invalid referral emails.
     */
    async importReferralsFromCSV(requestParameters: ImportReferralsFromCSVOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateReferralsByEmailsResponse> {
        const response = await this.importReferralsFromCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send referral completeness email
     */
    async sendReferralCompletenessEmailRaw(requestParameters: SendReferralCompletenessEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendReferralCompletenessEmail().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling sendReferralCompletenessEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/{id}/send-referral-completeness-email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send referral completeness email
     */
    async sendReferralCompletenessEmail(requestParameters: SendReferralCompletenessEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendReferralCompletenessEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Change referral completeness
     */
    async toggleReferralCompletenessRaw(requestParameters: ToggleReferralCompletenessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling toggleReferralCompleteness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/{id}/toggle-complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change referral completeness
     */
    async toggleReferralCompleteness(requestParameters: ToggleReferralCompletenessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleReferralCompletenessRaw(requestParameters, initOverrides);
    }

}
