<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Heading4'
}
</script>

<style scoped>
div {
  font-family: Teodor, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
}
</style>
