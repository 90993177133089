/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportReferralsFromCSVRequest
 */
export interface ImportReferralsFromCSVRequest {
    /**
     * CSV file which contains referrals email addresses.
     * @type {Blob}
     * @memberof ImportReferralsFromCSVRequest
     */
    file: Blob;
    /**
     * Html file which will be included inside referral email
     * @type {Blob}
     * @memberof ImportReferralsFromCSVRequest
     */
    mail_info: Blob;
}

/**
 * Check if a given object implements the ImportReferralsFromCSVRequest interface.
 */
export function instanceOfImportReferralsFromCSVRequest(value: object): value is ImportReferralsFromCSVRequest {
    if (!('file' in value) || value['file'] === undefined) return false;
    if (!('mail_info' in value) || value['mail_info'] === undefined) return false;
    return true;
}

export function ImportReferralsFromCSVRequestFromJSON(json: any): ImportReferralsFromCSVRequest {
    return ImportReferralsFromCSVRequestFromJSONTyped(json, false);
}

export function ImportReferralsFromCSVRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportReferralsFromCSVRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
        'mail_info': json['mail_info'],
    };
}

export function ImportReferralsFromCSVRequestToJSON(json: any): ImportReferralsFromCSVRequest {
    return ImportReferralsFromCSVRequestToJSONTyped(json, false);
}

export function ImportReferralsFromCSVRequestToJSONTyped(value?: ImportReferralsFromCSVRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'file': value['file'],
        'mail_info': value['mail_info'],
    };
}

