<template>
  <div :class="sidebar ? 'nav-opened' : 'relative'">
    <header id="navbar" :class="{ bb: !isMobile }">
      <div v-if="showNynowTopbanner" class="nyNow-site-wide-banner">
        <img src="../../assets/img/Union.svg" class="nyNow-site-wide-bannerImage" />
      </div>
      <nav
        class="primary-row bt"
        :style="{ top: primaryHeaderTop }"
        :class="{
          'mobile-sticky-mode': mobileStickyMode,
          'bordered-header': showPrimaryHeaderBorder,
          bb: !mobileStickyMode && isMobile,
          'mobile-popup-open': mobilePopupOpen
        }"
        @mouseover="onPrimaryHeaderMouseOver"
      >
        <section class="logo-cell" :class="{ 'mobile-sticky-mode': mobileStickyMode }">
          <img
            class="logo show-md pointer"
            src="../../assets/img/img-logo.svg"
            data-test-id="header_logo_link"
            data-cy="bulletin-logo"
            @click="handleLogoClick"
          />
        </section>
        <section class="search-cell" :class="{ 'mobile-sticky-mode': mobileStickyMode }">
          <the-search-field />
        </section>
        <section class="controls-cell">
          <UserControls :force-retailer-view="forceRetailerView" />
        </section>
      </nav>

      <the-secondary-header :categories="categories" :force-retailer-view="forceRetailerView" />
      <the-main-mobile-menu v-if="sidebar" :categories="categories" />
    </header>
    <dropdown :categories="categories" />

    <the-left-side-category-menu v-if="leftSideCategoryMenuOpen" :categories="categories" />
  </div>
</template>

<script>
import TheSearchField from '@/components/navigation/TheSearchField'
import UserControls from '@/components/navigation/UserControls'
import TheMainMobileMenu from '@/components/navigation/TheMainMobileMenu/TheMainMobileMenu.vue'

import Dropdown from '@/components/navigation/Dropdown'

import { mapGetters } from 'vuex'
import TheSecondaryHeader from './TheSecondaryHeader'
import { navigationHelper } from '../mixin/navigationHelper'
import TheLeftSideCategoryMenu from './TheLeftSideCategoryMenu'

const SECONDARY_HEADER_HEIGHT = 48
const MOBILE_STICKY_MODEL_BREAK_POINT = 71

export default {
  name: 'TheNavigationBar',
  components: {
    TheLeftSideCategoryMenu,
    TheSecondaryHeader,
    Dropdown,
    UserControls,
    TheSearchField,
    TheMainMobileMenu
  },
  mixins: [navigationHelper],
  data() {
    return {
      mobileStickyMode: false,
      showPrimaryHeaderBorder: false,
      isMobile: false,
      scrollPosition: window.scrollY,
      forceRetailerView: false
    }
  },
  computed: {
    ...mapGetters({
      sidebar: 'navigation/sidebar',
      dropdownState: 'navigation/dropdownState',
      leftSideCategoryMenuOpen: 'navigation/leftSideCategoryMenuOpen',
      mobilePopupOpen: 'navigation/mobilePopupOpen',
      siteWideBannerHeight: 'navigation/getSiteWideBannerHeight',
      isSiteWideBannerVisible: 'navigation/getSiteWideBannerVisible',
      user: 'userState',
      categories: 'getNav'
    }),
    showNynowTopbanner() {
      return (
        !['ADMIN', 'BRAND'].includes(this.user?.role) && ['/nynow-exhibitor', '/nynow-buyer'].includes(this.$route.path)
      )
    },
    primaryHeaderTop() {
      if (this.showNynowTopbanner) {
        return '40px'
      }

      if (!this.isSiteWideBannerVisible) {
        return 0
      }

      let topWithScrollCorrection = this.siteWideBannerHeight - this.scrollPosition
      if (topWithScrollCorrection < 0) {
        topWithScrollCorrection = 0
      }
      return `${topWithScrollCorrection}px`
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeMount() {
    this.setMobileState()
    if (window !== window.parent) {
      this.forceRetailerView = true
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > this.$utils.constants.BREAKPOINT_MEDIUM) {
        if (this.sidebar === true) {
          this.$store.dispatch('navigation/hideSideBar')
        }
      }
      this.setMobileState()
    })
  },
  methods: {
    handleLogoClick() {
      this.trackNavigationEvent('logo', 'logo')
      this.$router.push('/')
      this.$store.dispatch('navigation/setDropdownType', 'closed')
      this.$store.dispatch('navigation/hideSideBar')
    },
    handleScroll() {
      this.showPrimaryHeaderBorder = window.scrollY > SECONDARY_HEADER_HEIGHT
      this.mobileStickyMode = window.scrollY > MOBILE_STICKY_MODEL_BREAK_POINT
      this.scrollPosition = window.scrollY
    },
    setMobileState() {
      this.isMobile = window.innerWidth <= this.$utils.constants.BREAKPOINT_MEDIUM
    },
    onPrimaryHeaderMouseOver() {
      if (this.dropdownState === 'sign-in' || this.dropdownState === 'sign-up') {
        return
      }
      this.$store.dispatch('navigation/setDropdownType', 'closed')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/vars.scss';

.nav-opened {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 22;
  bottom: 0;
}

.nyNow-site-wide-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 25;
  bottom: 0;
  background-color: $color-black;
  height: 40px !important;
}
.nyNow-site-wide-bannerImage {
  height: auto;
  padding-bottom: 4px;
  padding: 10px !important;
}
</style>
