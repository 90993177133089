import Talk from 'talkjs'
import BrandAPI from './services/api/brandApi.js'
import utils from './services/utils'

const BrandService = new BrandAPI()

async function TalkSession(vueInstance, userInfo) {
  if (userInfo.role !== 'BRAND' && userInfo.role !== 'RETAILER') {
    return
  }

  let data
  if (userInfo.role === 'RETAILER') {
    data = vueInstance.$store.getters.retailerState
  } else {
    const brandResponse = await BrandService.getBrandMe()
    data = brandResponse.data
  }

  await Talk.ready
  const me = new Talk.User({
    id: userInfo.role === 'BRAND' ? userInfo.brand_id : userInfo.retailer_id,
    name: `${data?.first_name} from ${userInfo.brand_id ? data?.brand_name : data?.store_name}`,
    email: data?.email,
    role: userInfo.role
  })
  window.talkSession = new Talk.Session({
    appId: utils.constants.TALKJS_APP_ID,
    me: me
  })
  vueInstance.$store.commit('setNotificationSessionLoaded', true)
  window.talkSession.unreads.on('change', (unreadConversations) => {
    const amountOfUnreads = unreadConversations.length
    vueInstance.$store.commit('setNotificationCount', amountOfUnreads)
  })
}

export default TalkSession
