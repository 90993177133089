/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Unlink brands from trade show request
 * @export
 * @interface UnlinkBrandsFromTradeShowRequest
 */
export interface UnlinkBrandsFromTradeShowRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnlinkBrandsFromTradeShowRequest
     */
    emails?: Array<string>;
}

/**
 * Check if a given object implements the UnlinkBrandsFromTradeShowRequest interface.
 */
export function instanceOfUnlinkBrandsFromTradeShowRequest(value: object): value is UnlinkBrandsFromTradeShowRequest {
    return true;
}

export function UnlinkBrandsFromTradeShowRequestFromJSON(json: any): UnlinkBrandsFromTradeShowRequest {
    return UnlinkBrandsFromTradeShowRequestFromJSONTyped(json, false);
}

export function UnlinkBrandsFromTradeShowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnlinkBrandsFromTradeShowRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'emails': json['emails'] == null ? undefined : json['emails'],
    };
}

export function UnlinkBrandsFromTradeShowRequestToJSON(json: any): UnlinkBrandsFromTradeShowRequest {
    return UnlinkBrandsFromTradeShowRequestToJSONTyped(json, false);
}

export function UnlinkBrandsFromTradeShowRequestToJSONTyped(value?: UnlinkBrandsFromTradeShowRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'emails': value['emails'],
    };
}

