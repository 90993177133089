/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnumOptionRetailerStatus
 */
export interface EnumOptionRetailerStatus {
    /**
     * 
     * @type {string}
     * @memberof EnumOptionRetailerStatus
     */
    value?: EnumOptionRetailerStatusValueEnum;
    /**
     * 
     * @type {string}
     * @memberof EnumOptionRetailerStatus
     */
    label?: string;
}


/**
 * @export
 */
export const EnumOptionRetailerStatusValueEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Banned: 'BANNED'
} as const;
export type EnumOptionRetailerStatusValueEnum = typeof EnumOptionRetailerStatusValueEnum[keyof typeof EnumOptionRetailerStatusValueEnum];


/**
 * Check if a given object implements the EnumOptionRetailerStatus interface.
 */
export function instanceOfEnumOptionRetailerStatus(value: object): value is EnumOptionRetailerStatus {
    return true;
}

export function EnumOptionRetailerStatusFromJSON(json: any): EnumOptionRetailerStatus {
    return EnumOptionRetailerStatusFromJSONTyped(json, false);
}

export function EnumOptionRetailerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumOptionRetailerStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function EnumOptionRetailerStatusToJSON(json: any): EnumOptionRetailerStatus {
    return EnumOptionRetailerStatusToJSONTyped(json, false);
}

export function EnumOptionRetailerStatusToJSONTyped(value?: EnumOptionRetailerStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'label': value['label'],
    };
}

