/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandDropdownInfo
 */
export interface BrandDropdownInfo {
    /**
     * 
     * @type {string}
     * @memberof BrandDropdownInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandDropdownInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandDropdownInfo
     */
    vanityUrlName?: string;
    /**
     * 
     * @type {Date}
     * @memberof BrandDropdownInfo
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the BrandDropdownInfo interface.
 */
export function instanceOfBrandDropdownInfo(value: object): value is BrandDropdownInfo {
    return true;
}

export function BrandDropdownInfoFromJSON(json: any): BrandDropdownInfo {
    return BrandDropdownInfoFromJSONTyped(json, false);
}

export function BrandDropdownInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandDropdownInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'vanityUrlName': json['vanityUrlName'] == null ? undefined : json['vanityUrlName'],
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function BrandDropdownInfoToJSON(json: any): BrandDropdownInfo {
    return BrandDropdownInfoToJSONTyped(json, false);
}

export function BrandDropdownInfoToJSONTyped(value?: BrandDropdownInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'vanityUrlName': value['vanityUrlName'],
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

