/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssociateProductWithBoardsRequest,
  CreateBoardRequest,
  CreateBoardResponse,
  GetAllRetailerBoardsDetailsResponse,
  GetBoardDetailsResponse,
  GetRetailerBoardsResponse,
  RenameBoardRequest,
  RenameBoardResponse,
} from '../models/index';
import {
    AssociateProductWithBoardsRequestFromJSON,
    AssociateProductWithBoardsRequestToJSON,
    CreateBoardRequestFromJSON,
    CreateBoardRequestToJSON,
    CreateBoardResponseFromJSON,
    CreateBoardResponseToJSON,
    GetAllRetailerBoardsDetailsResponseFromJSON,
    GetAllRetailerBoardsDetailsResponseToJSON,
    GetBoardDetailsResponseFromJSON,
    GetBoardDetailsResponseToJSON,
    GetRetailerBoardsResponseFromJSON,
    GetRetailerBoardsResponseToJSON,
    RenameBoardRequestFromJSON,
    RenameBoardRequestToJSON,
    RenameBoardResponseFromJSON,
    RenameBoardResponseToJSON,
} from '../models/index';

export interface AssociateProductWithBoardsOperationRequest {
    associateProductWithBoardsRequest: AssociateProductWithBoardsRequest;
}

export interface CreateBoardOperationRequest {
    createBoardRequest: CreateBoardRequest;
}

export interface DeleteBoardRequest {
    id: string;
}

export interface ExportBoardToCsvRequest {
    id: string;
    order: string;
}

export interface GetBoardByIdRequest {
    id: string;
    order: string;
    search?: string;
}

export interface GetRetailerBoards1Request {
    productId: string;
}

export interface RenameBoardOperationRequest {
    id: string;
    renameBoardRequest: RenameBoardRequest;
}

/**
 * 
 */
export class BoardControllerApi extends runtime.BaseAPI {

    /**
     * Associate Product with Boards
     */
    async associateProductWithBoardsRaw(requestParameters: AssociateProductWithBoardsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['associateProductWithBoardsRequest'] == null) {
            throw new runtime.RequiredError(
                'associateProductWithBoardsRequest',
                'Required parameter "associateProductWithBoardsRequest" was null or undefined when calling associateProductWithBoards().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards/associate-product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssociateProductWithBoardsRequestToJSON(requestParameters['associateProductWithBoardsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Associate Product with Boards
     */
    async associateProductWithBoards(requestParameters: AssociateProductWithBoardsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.associateProductWithBoardsRaw(requestParameters, initOverrides);
    }

    /**
     * Create board
     */
    async createBoardRaw(requestParameters: CreateBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBoardResponse>> {
        if (requestParameters['createBoardRequest'] == null) {
            throw new runtime.RequiredError(
                'createBoardRequest',
                'Required parameter "createBoardRequest" was null or undefined when calling createBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBoardRequestToJSON(requestParameters['createBoardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBoardResponseFromJSON(jsonValue));
    }

    /**
     * Create board
     */
    async createBoard(requestParameters: CreateBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBoardResponse> {
        const response = await this.createBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete board
     */
    async deleteBoardRaw(requestParameters: DeleteBoardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete board
     */
    async deleteBoard(requestParameters: DeleteBoardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBoardRaw(requestParameters, initOverrides);
    }

    /**
     * Export board to CSV file
     */
    async exportBoardToCsvRaw(requestParameters: ExportBoardToCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling exportBoardToCsv().'
            );
        }

        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling exportBoardToCsv().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export board to CSV file
     */
    async exportBoardToCsv(requestParameters: ExportBoardToCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportBoardToCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get board details
     */
    async getBoardByIdRaw(requestParameters: GetBoardByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBoardDetailsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getBoardById().'
            );
        }

        if (requestParameters['order'] == null) {
            throw new runtime.RequiredError(
                'order',
                'Required parameter "order" was null or undefined when calling getBoardById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBoardDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Get board details
     */
    async getBoardById(requestParameters: GetBoardByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBoardDetailsResponse> {
        const response = await this.getBoardByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get retailer all boards details
     */
    async getRetailerBoardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllRetailerBoardsDetailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllRetailerBoardsDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Get retailer all boards details
     */
    async getRetailerBoards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllRetailerBoardsDetailsResponse> {
        const response = await this.getRetailerBoardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get retailer boards details
     */
    async getRetailerBoards1Raw(requestParameters: GetRetailerBoards1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRetailerBoardsResponse>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getRetailerBoards1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards/with-product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRetailerBoardsResponseFromJSON(jsonValue));
    }

    /**
     * Get retailer boards details
     */
    async getRetailerBoards1(requestParameters: GetRetailerBoards1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRetailerBoardsResponse> {
        const response = await this.getRetailerBoards1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update board name
     */
    async renameBoardRaw(requestParameters: RenameBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenameBoardResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling renameBoard().'
            );
        }

        if (requestParameters['renameBoardRequest'] == null) {
            throw new runtime.RequiredError(
                'renameBoardRequest',
                'Required parameter "renameBoardRequest" was null or undefined when calling renameBoard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/boards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RenameBoardRequestToJSON(requestParameters['renameBoardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenameBoardResponseFromJSON(jsonValue));
    }

    /**
     * Update board name
     */
    async renameBoard(requestParameters: RenameBoardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenameBoardResponse> {
        const response = await this.renameBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
