/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderPreviewAddressResponse } from './OrderPreviewAddressResponse';
import {
    OrderPreviewAddressResponseFromJSON,
    OrderPreviewAddressResponseFromJSONTyped,
    OrderPreviewAddressResponseToJSON,
    OrderPreviewAddressResponseToJSONTyped,
} from './OrderPreviewAddressResponse';

/**
 * 
 * @export
 * @interface OrderPreviewResponse
 */
export interface OrderPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewResponse
     */
    number?: string;
    /**
     * 
     * @type {OrderPreviewAddressResponse}
     * @memberof OrderPreviewResponse
     */
    delivery_address?: OrderPreviewAddressResponse;
    /**
     * 
     * @type {string}
     * @memberof OrderPreviewResponse
     */
    promo_code_type?: OrderPreviewResponsePromoCodeTypeEnum;
}


/**
 * @export
 */
export const OrderPreviewResponsePromoCodeTypeEnum = {
    Percent: 'PERCENT',
    Amount: 'AMOUNT',
    FreeShipping: 'FREE_SHIPPING'
} as const;
export type OrderPreviewResponsePromoCodeTypeEnum = typeof OrderPreviewResponsePromoCodeTypeEnum[keyof typeof OrderPreviewResponsePromoCodeTypeEnum];


/**
 * Check if a given object implements the OrderPreviewResponse interface.
 */
export function instanceOfOrderPreviewResponse(value: object): value is OrderPreviewResponse {
    return true;
}

export function OrderPreviewResponseFromJSON(json: any): OrderPreviewResponse {
    return OrderPreviewResponseFromJSONTyped(json, false);
}

export function OrderPreviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPreviewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'delivery_address': json['delivery_address'] == null ? undefined : OrderPreviewAddressResponseFromJSON(json['delivery_address']),
        'promo_code_type': json['promo_code_type'] == null ? undefined : json['promo_code_type'],
    };
}

export function OrderPreviewResponseToJSON(json: any): OrderPreviewResponse {
    return OrderPreviewResponseToJSONTyped(json, false);
}

export function OrderPreviewResponseToJSONTyped(value?: OrderPreviewResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'delivery_address': OrderPreviewAddressResponseToJSON(value['delivery_address']),
        'promo_code_type': value['promo_code_type'],
    };
}

