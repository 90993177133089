/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBannerComponentRequest
 */
export interface UpdateBannerComponentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBannerComponentRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBannerComponentRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBannerComponentRequest
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBannerComponentRequest
     */
    text_color?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBannerComponentRequest
     */
    background_color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBannerComponentRequest
     */
    active?: boolean;
}

/**
 * Check if a given object implements the UpdateBannerComponentRequest interface.
 */
export function instanceOfUpdateBannerComponentRequest(value: object): value is UpdateBannerComponentRequest {
    return true;
}

export function UpdateBannerComponentRequestFromJSON(json: any): UpdateBannerComponentRequest {
    return UpdateBannerComponentRequestFromJSONTyped(json, false);
}

export function UpdateBannerComponentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBannerComponentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'link': json['link'] == null ? undefined : json['link'],
        'text_color': json['text_color'] == null ? undefined : json['text_color'],
        'background_color': json['background_color'] == null ? undefined : json['background_color'],
        'active': json['active'] == null ? undefined : json['active'],
    };
}

export function UpdateBannerComponentRequestToJSON(json: any): UpdateBannerComponentRequest {
    return UpdateBannerComponentRequestToJSONTyped(json, false);
}

export function UpdateBannerComponentRequestToJSONTyped(value?: UpdateBannerComponentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
        'link': value['link'],
        'text_color': value['text_color'],
        'background_color': value['background_color'],
        'active': value['active'],
    };
}

