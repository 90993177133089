/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRetailerStatusRequest
 */
export interface UpdateRetailerStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRetailerStatusRequest
     */
    status: UpdateRetailerStatusRequestStatusEnum;
}


/**
 * @export
 */
export const UpdateRetailerStatusRequestStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Banned: 'BANNED'
} as const;
export type UpdateRetailerStatusRequestStatusEnum = typeof UpdateRetailerStatusRequestStatusEnum[keyof typeof UpdateRetailerStatusRequestStatusEnum];


/**
 * Check if a given object implements the UpdateRetailerStatusRequest interface.
 */
export function instanceOfUpdateRetailerStatusRequest(value: object): value is UpdateRetailerStatusRequest {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function UpdateRetailerStatusRequestFromJSON(json: any): UpdateRetailerStatusRequest {
    return UpdateRetailerStatusRequestFromJSONTyped(json, false);
}

export function UpdateRetailerStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRetailerStatusRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function UpdateRetailerStatusRequestToJSON(json: any): UpdateRetailerStatusRequest {
    return UpdateRetailerStatusRequestToJSONTyped(json, false);
}

export function UpdateRetailerStatusRequestToJSONTyped(value?: UpdateRetailerStatusRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': value['status'],
    };
}

