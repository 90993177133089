<template>
  <div class="left-side-category-menu-wrapper" :class="{ visible: show }">
    <div class="left-side-category-menu" :class="{ visible: show }">
      <div class="menu-header align-center justify-between" :class="{ 'justify-end': menuLevel === 0 }">
        <div v-if="menuLevel === 1" class="back-link flex align-center" @click="onBack">
          <Icon icon-key="chevron-left" hover-type="OPACITY" />
          <h4>{{ backButtonLabel }}</h4>
        </div>
        <Icon icon-key="close" hover-type="OPACITY" :on-click="closeMenu" />
      </div>

      <div class="menu-content">
        <div v-if="menuLevel === 0">
          <h2 class="font-size-32 mb-30">All Categories</h2>

          <div class="category-list">
            <div
              v-for="(parentCategory, index) in parentCategories"
              :key="index"
              :class="[
                'category-list-item',
                'flex',
                'justify-between',
                'align-center',
                { 'border-bottom-menu': index === 1 }
              ]"
              @click="setActiveCategory(parentCategory)"
            >
              {{ parentCategory.name }}
              <Icon v-if="index !== 1" icon-key="chevron-right" hover-type="OPACITY" />
            </div>
          </div>
        </div>

        <div v-if="menuLevel === 1">
          <!-- SUB CATEGORY-->
          <sub-category-menu
            class="sub-category-menu"
            :categories="categories"
            :left-side-menu="true"
            :close-left-side-menu="closeMenu"
            :select-category-container="setSelectedCategoryContainer"
          />
        </div>
      </div>
    </div>
    <div class="backdrop" @click="closeMenu" />
  </div>
</template>

<script>
import { navigationHelper } from '../mixin/navigationHelper'
import { bodyScrollHelper } from '../mixin/bodyScrollHelper'
import SubCategoryMenu from './SubCategoryMenu'
import { mapGetters } from 'vuex'

export default {
  components: {
    SubCategoryMenu
  },
  mixins: [navigationHelper, bodyScrollHelper],
  props: {
    categories: {
      type: [Array, Object],
      required: true
    }
  },
  data: function () {
    return {
      activeCategoryContainer: null,
      show: false,
      menuLevel: 0,
      selectedCategoryContainer: null
    }
  },
  computed: {
    ...mapGetters({
      activeCategoryId: 'navigation/activeCategory'
    }),
    activeCategory() {
      return this.parentCategories?.find((item) => item.id.toString() === this.activeCategoryId)
    },
    parentCategories() {
      if (!this.categories?.children_containers.some((container) => container.name === 'NY NOW')) {
        // eslint-disable-next-line vue/no-mutating-props, vue/no-side-effects-in-computed-properties
        this.categories?.children_containers.splice(1, 0, { name: 'NY NOW', id: 'nynow' })
      }
      return this.categories?.children_containers
    },
    backButtonLabel() {
      if (this.selectedCategoryContainer) {
        return `ALL ${this.activeCategory.name}`
      }

      return 'ALL CATEGORIES'
    }
  },
  watch: {},
  async mounted() {
    await this.$nextTick()
    this.show = true
  },
  beforeDestroy() {},
  methods: {
    closeMenu,
    setActiveCategory,
    setSelectedCategoryContainer,
    onBack
  }
}

function closeMenu() {
  this.show = false
  setTimeout(() => {
    this.$store.dispatch('navigation/setLeftSideCategoryMenuOpen', false)
  }, 250)
}

function setActiveCategory(parentCategory) {
  this.menuLevel = 1
  if (parentCategory.id === 'nynow') {
    const nynowLink = {
      name: 'nynow',
      query: {
        sortBy: 'brands_made_active_desc',
        page: 1
      }
    }
    this.$router.push(nynowLink)
    this.closeMenu()
  } else this.$store.dispatch('navigation/setActiveCategory', parentCategory.id)
}

function setSelectedCategoryContainer(container) {
  this.selectedCategoryContainer = container
  this.$forceUpdate()
}

function onBack() {
  if (this.selectedCategoryContainer) {
    this.$eventBus.$emit('clear-selected-category-container')
    return
  }

  this.menuLevel = 0
}
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/base/_vars.scss';
.menu-header {
  display: flex;
  padding: 24px;

  .back-link {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  h4 {
    color: $color-grey-4;
  }
}

.left-side-category-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0.75);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  z-index: 98;
  &.visible {
    opacity: 1;
  }
}

.backdrop {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: calc(100vw - 501px);
  z-index: 98;
}

.left-side-category-menu {
  width: 501px;
  background: $color-white;
  height: 100vh;
  transform: translate3d(-500px, 0, 0);
  transition: transform 250ms ease-in-out;

  &.visible {
    transform: translate3d(0, 0, 0);
  }
}
.menu-content {
  padding: 0 24px 40px 40px;
  max-height: calc(100vh - 88px);
  overflow: auto;

  .category-list {
    .category-list-item {
      margin-top: 8px;
      font-family: NHaasGroteskDSPro-55Rg;
      font-size: 16px;

      &:hover {
        cursor: pointer;
        color: $color-grey-4;

        svg {
          opacity: 0.75;
        }
      }
    }
  }
}

.category-list-item.border-bottom-menu {
  border-bottom: 1px solid $color-white !important;
  padding-top: 10px;
  padding-bottom: 28px;
  margin-bottom: 16px;

  a:hover {
    cursor: pointer;
    color: $color-grey-4;
  }
}
</style>
