import utils, { RouterPath } from './services/utils'
import session from './services/session'

const MainView = () => import('./views/mainView.vue')
const Home = () => import('./views/home/home.vue')

const CategoryProductsPage = () => import('./views/productSearchPages/categoryProductsPage.vue')
const DefaultSearchProductsPage = () => import('./views/productSearchPages/defaultSearchProductsPage.vue')
const CollectionProductsPage = () => import('./views/productSearchPages/collectionProductsPage.vue')
const AboutUs = () => import('./views/about/aboutUs.vue')
const Login = () => import('./views/auth/login/login.vue')
const NotFound = () => import('./views/notFound.vue')
const Unavailable = () => import('./views/unavailable.vue')
const ProductDetails = () => import('./views/TheProductDetailsPage/TheProductDetailsPage.vue')
const Checkout = () => import('./views/checkout/checkout.vue')
const CheckoutSteps = () => import('./views/checkout/checkoutSteps/checkoutSteps.vue')
const OrderConfirmation = () => import('./views/checkout/checkoutSteps/orderConfirmation/orderConfirmation.vue')

const BuyerPage = () => import('./views/valueProps/BuyerPage.vue')
const BrandPage = () => import('./views/valueProps/BrandPage.vue')
const ExhibitorSurfExpoPage = () => import('./views/valueProps/SurfExpoExhibitor.vue')
const ThankYouPage = () => import('./views/valueProps/ThankYouPage.vue')
const Dashboard = () => import('./views/dashboard/dashboard.vue')
const Products = () => import('./views/dashboard/products/products.vue')
const Drafts = () => import('./views/dashboard/drafts/drafts.vue')
const DraftEdit = () => import('./views/dashboard/drafts/editDraft/EditDraft.vue')
const Inventory = () => import('./views/dashboard/inventory/inventoryPanel.vue')
const Brands = () => import('./views/dashboard/brand/brands.vue')
const Retailers = () => import('./views/dashboard/retailers/retailers.vue')
const RetailerDetails = () => import('./views/dashboard/retailers/retailerDetails/retailerDetails.vue')
const BrandApplications = () => import('./views/dashboard/applications/brandApplications/brandApplications.vue')
const RetailerApplications = () =>
  import('./views/dashboard/applications/retailerApplications/retailerApplications.vue')
const CreateEditProduct = () => import('./views/dashboard/products/createEditProduct/createEditProduct.vue')
const CreateEditBrand = () => import('./views/dashboard/brand/createEditBrand/createEditBrand.vue')
const Collections = () => import('./views/dashboard/collections/collections.vue')
const DashboardHomepage = () => import('./views/dashboard/contentManagment/contentManagment.vue')
const TradeShowDirectoriesEditor = () =>
  import('./views/dashboard/contentManagment/tradeShowDirectories/tradeShowDirectoriesEditor.vue')
const NavCollectionEditorPage = () =>
  import('./views/dashboard/contentManagment/navCollectionEditorPage/navCollectionEditorPage.vue')
const GlobalComponentsCMSPage = () =>
  import('./views/dashboard/contentManagment/globalComponents/GlobalComponentsCMSPage.vue')
const BrandDirectoryPage = () => import('./views/brandDirectoryPage/brandDirectoryPage.vue')
const NavNYNOW = () => import('./views/TheNYNOWDirectoryPage/navNYNOW.vue')
const BuyerDirectoryNYNOW = () => import('./views/TheNYNOWDirectoryPage/buyerDirectoryNYNOW.vue')
const TheNYNOWDirectoryPage = () => import('./views/TheNYNOWDirectoryPage/TheNYNOWDirectoryPage.vue')
const BrandBio = () => import('./views/brandBio/brandBio.vue')
const RetailerProfile = () => import('./views/brandBio/retailerProfilePage/retailerProfilePage.vue')
const CreateEditCollection = () => import('./views/dashboard/collections/createEditCollection/createEditCollection.vue')
const Orders = () => import('./views/dashboard/orders/orders.vue')
const OrdersRetailer = () => import('./views/dashboard/orders/ordersRetailer/ordersRetailer.vue')
const Invoices = () => import('./views/dashboard/invoices/invoices.vue')
const Payments = () => import('./views/dashboard/payments/payments.vue')
const PaymentDetails = () => import('./views/dashboard/payments/paymentDetails/paymentDetails.vue')
const Credit = () => import('./views/dashboard/paymentInfo/credit.vue')
const InvoiceDetails = () => import('./views/dashboard/invoices/invoiceDetails.vue')
const BrandBoards = () => import('./views/dashboard/brandBoards/BrandBoards.vue')
const BrandBoardsDetails = () => import('./views/dashboard/brandBoardsDetails/BrandBoardsDetails.vue')
const Messages = () => import('./views/dashboard/messages/messages.vue')
const OrderDetailsOld = () => import('./views/dashboard/orders/orderDetails/orderDetailsOld.vue')
const OrderDetails = () => import('./views/dashboard/orders/orderDetails/orderDetails.vue')
const OrderDetailsRetailer = () => import('./views/dashboard/orders/orderDetails/orderDetailsRetailer.vue')
const createReviewPackingSlip = () =>
  import('./views/dashboard/orders/createReviewPackingSlip/createReviewPackingSlip.vue')
const CustomAssortments = () => import('./views/dashboard/customAssortments/customAssortments.vue')
const ConnectToStripe = () => import('./views/dashboard/brand/connectToStripe/connectToStripe.vue')

const SetPassword = () => import('./views/auth/setPassword/setPassword.vue')
const ForgotPassword = () => import('./views/auth/forgotPassword/forgotPassword.vue')
const Refer = () => import('./views/refer/refer.vue')
const Referrals = () => import('./views/dashboard/referrals/referrals.vue')
const ReorderPage = () => import('./views/dashboard/reorderPage/reorderPage.vue')
const FavoritesPage = () => import('./views/favoritesPage/favoritesPage.vue')

const FavoriteBoardDetailsPage = () =>
  import('./views/favoritesPage/subPages/favoriteBoardDetailsPage/favoriteBoardDetailsPage.vue')
const RecommendedBrandsPage = () =>
  import('./views/favoritesPage/subPages/recommendedBrandsPage/recommendedBrandsPage.vue')
const FavoriteBrandsPage = () => import('./views/favoritesPage/subPages/favoriteBrandsPage/favoriteBrandsPage.vue')
const Password = () => import('./views/dashboard/password/password.vue')
const Profile = () => import('./views/dashboard/profile/profile.vue')
const AdminPromoCodes = () => import('./views/dashboard/promoCodes/admin/adminPromoCodes.vue')
const BrandPromoCodes = () => import('./views/dashboard/promoCodes/brand/brandPromoCodes.vue')
const Addresses = () => import('./views/dashboard/addresses/addresses.vue')
const CreateEditAddress = () => import('./views/dashboard/addresses/createEditAddress/createEditAddress.vue')
const PaymentInfo = () => import('./views/dashboard/paymentInfo/paymentInfo.vue')

const ConnectShopify = () => import('./views/dashboard/connectShopify/connectShopify.vue')
const ShopifyConnectStart = () => import('./views/shopifyConnectStart/shopifyConnectStart.vue')
const Sandbox = () => import('./views/sandbox/sandbox.vue')
const SandboxTextField = () => import('./views/sandbox/sandboxTextField.vue')
const SandboxButton = () => import('./views/sandbox/sandboxButton.vue')
const SandboxAddress = () => import('./views/sandbox/sandboxAddress.vue')
const SandboxFilters = () => import('./views/sandbox/sandboxFilters.vue')
const SandboxSelectors = () => import('./views/sandbox/sandboxSelectors.vue')
const SitemapPage = () => import('./views/sitemapPage/sitemapPage.vue')
const BrandPageSetup = () => import('./views/dashboard/brandPageSetup/brandPageSetup.vue')
const BrandPreviewForRetailer = () => import('./views/dashboard/brandPageSetup/brandPreviewForRetailer.vue')

const ReviewBrandPage = () => import('./views/dashboard/reviewBrandPage/reviewBrandPage.vue')
const BrandCreate = () => import('./views/brand/brandCreate/BrandCreate.vue')
const BrandVerifyEmail = () => import('./views/brand/brandVerifyEmail.vue')
const BrandAccountConfirmed = () => import('./views/brand/brandAccountConfirmed.vue')
const BrandLogin = () => import('./views/brand/brandLogin.vue')
const BrandForgotPassword = () => import('./views/brand/brandForgotPassword.vue')
const BrandResetPassword = () => import('./views/brand/brandResetPassword.vue')

const RetailerSetPassword = () => import('./views/retailer/retailerSetPassword.vue')
const BulletinBucksPromoTerms = () => import('./views/bulletinBucksPromo/bulletinBucksPromoTerms.vue')

const routes = [
  {
    path: '/brand/create',
    name: 'create',
    component: BrandCreate,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/brand/verifyEmail',
    name: 'verify-email',
    component: BrandVerifyEmail,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/brand/login',
    name: 'login',
    component: BrandLogin,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/brand/forgotPassword',
    name: 'forgot-password',
    component: BrandForgotPassword,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/brand/resetPassword',
    name: 'reset-password',
    component: BrandResetPassword,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/',
    component: MainView,
    redirect: '/',
    children: [
      {
        path: '/dashboard',
        component: Dashboard,
        redirect: '/dashboard/products',
        meta: {
          title: 'Dashboard',
          isPublic: false,
          roles: ['ADMIN']
        },
        children: [
          {
            path: '/dashboard/brand-page-setup',
            component: BrandPageSetup,
            name: 'dashboardBrandPageSetup',
            meta: {
              title: 'BrandPageSetup',
              isPublic: false,
              roles: ['BRAND']
            }
          },
          {
            path: '/dashboard/referrals',
            component: Referrals,
            name: 'dashboardReferrals',
            meta: {
              title: 'Referrals',
              isPublic: false,
              roles: ['ADMIN', 'BRAND']
            }
          },
          {
            path: '/dashboard/review-brand/:brandId/',
            component: ReviewBrandPage,
            name: 'dashboardReviewBrandPage',
            meta: {
              title: 'ReviewBrandPage',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/referrals/new',
            component: Refer,
            name: 'dashboardCreateReferral',
            meta: {
              title: 'Refer a Retailer',
              isPublic: false,
              roles: ['BRAND']
            }
          },
          {
            path: '/dashboard/products',
            component: Products,
            name: 'dashboardProducts',
            meta: {
              title: 'Dashboard Products',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'PRODUCT_BUILDER']
            }
          },
          {
            path: '/dashboard/products/new',
            component: CreateEditProduct,
            name: 'createProduct',
            meta: {
              title: 'New Product',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'PRODUCT_BUILDER']
            }
          },
          {
            path: '/dashboard/products/:productId/edit',
            component: CreateEditProduct,
            name: 'editProduct',
            meta: {
              title: 'Edit Product',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'PRODUCT_BUILDER']
            }
          },
          {
            path: '/dashboard/drafts',
            component: Drafts,
            name: 'dashboardDrafts',
            meta: {
              title: 'Dashboard Drafts',
              isPublic: false,
              roles: ['BRAND', 'PRODUCT_BUILDER', 'ADMIN']
            }
          },
          {
            path: '/dashboard/drafts/:productId',
            component: DraftEdit,
            name: 'dashboardDraftEdit',
            meta: {
              title: 'Edit Draft',
              isPublic: false,
              roles: ['BRAND', 'ADMIN', 'PRODUCT_BUILDER']
            }
          },
          {
            path: '/dashboard/inventory',
            component: Inventory,
            name: 'dashboardInventory',
            meta: {
              title: 'Dashboard Inventory',
              isPublic: false,
              roles: ['BRAND']
            }
          },
          {
            path: '/dashboard/collections',
            component: Collections,
            name: 'dashboardCollections',
            meta: {
              title: 'Collections',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/collections/:id/edit',
            component: CreateEditCollection,
            name: 'editCollection',
            meta: {
              title: 'Edit Collection',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/collections/new',
            component: CreateEditCollection,
            name: 'createCollection',
            meta: {
              title: 'New Collection',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/global-components',
            component: GlobalComponentsCMSPage,
            name: 'globalComponents',
            meta: {
              title: 'Global components',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/content-management',
            component: DashboardHomepage,
            name: 'dashboardHomepage',
            meta: {
              title: 'Content Management',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/edit-nav-collections',
            component: NavCollectionEditorPage,
            name: 'editNavCollectionsPage',
            meta: {
              title: 'Edit Nav Collections Page',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/edit-trade-show-directories',
            component: TradeShowDirectoriesEditor,
            name: 'editTradeShowDirectories',
            meta: {
              title: 'Edit Trade Show Directories',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/brands',
            component: Brands,
            name: 'dashboardBrands',
            meta: {
              title: 'Dashboard Brands',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/retailers',
            component: Retailers,
            name: 'dashboardRetailers',
            meta: {
              title: 'Dashboard Retailers',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/retailers/:id',
            component: RetailerDetails,
            name: 'Retailer Details',
            meta: {
              title: 'Account Settings - Profile',
              isPublic: false,
              roles: ['ADMIN', 'RETAILER']
            }
          },
          {
            path: '/dashboard/messages',
            component: Messages,
            name: 'dashboardMessages',
            meta: {
              title: 'Messages',
              isPublic: false,
              roles: ['RETAILER', 'BRAND']
            }
          },
          {
            path: '/dashboard/applications/brands',
            component: BrandApplications,
            name: 'dashboardBrandApplications',
            meta: {
              title: 'Dashboard Brand Applications',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/applications/retailers',
            component: RetailerApplications,
            name: 'dashboardRetailerApplications',
            meta: {
              title: 'Dashboard Retailer Applications',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/brands/:brandId/edit',
            component: CreateEditBrand,
            name: 'editBrand',
            meta: {
              title: 'Edit Brand',
              isPublic: false,
              roles: ['ADMIN', 'BRAND']
            }
          },
          {
            path: '/dashboard/brands/my-brand-page',
            component: CreateEditBrand,
            name: 'my-brand-page',
            meta: {
              title: 'Edit Brand Profile',
              isPublic: false,
              roles: ['BRAND']
            }
          },
          {
            path: '/dashboard/orders',
            component: Orders,
            name: 'orders',
            meta: {
              title: 'Dashboard Orders',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'RETAILER']
            }
          },
          {
            path: '/dashboard/retailer/orders',
            component: OrdersRetailer,
            name: 'ordersRetailer',
            meta: {
              title: 'Dashboard Orders',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/orders/:orderId/details/old',
            component: OrderDetailsOld,
            name: 'orderDetailsOld',
            meta: {
              title: 'Dashboard Order Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'RETAILER']
            },
            beforeEnter: (to, from, next) => {
              if (session.getUserInfo().role === 'RETAILER') {
                return next({
                  name: 'orderDetailsRetailer',
                  params: {
                    orderId: to.params.orderId
                  }
                })
              }

              next()
            }
          },
          {
            path: '/dashboard/orders/:orderId/details',
            component: OrderDetails,
            name: 'orderDetails',
            meta: {
              title: 'Dashboard Order Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'RETAILER']
            },
            beforeEnter: (to, from, next) => {
              if (session.getUserInfo().role === 'RETAILER') {
                return next({
                  name: 'orderDetailsRetailer',
                  params: {
                    orderId: to.params.orderId
                  }
                })
              }

              next()
            }
          },
          {
            path: '/dashboard/retailer/orders/:orderId/details',
            component: OrderDetailsRetailer,
            name: 'orderDetailsRetailer',
            meta: {
              title: 'Dashboard Order Details',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/orders/:suborderId',
            component: OrderDetails,
            name: 'suborderDetails',
            meta: {
              title: 'Dashboard Suborder Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'RETAILER']
            }
          },
          {
            path: '/dashboard/orders/old/:suborderId',
            component: OrderDetailsOld,
            name: 'suborderDetailsOld',
            meta: {
              title: 'Dashboard Suborder Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND', 'RETAILER']
            }
          },
          {
            path: '/dashboard/orders/:id/create-packing-slip',
            component: createReviewPackingSlip,
            name: 'createPackingSlip',
            meta: {
              title: 'Dashboard Suborder Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND']
            }
          },
          {
            path: '/dashboard/orders/:id/packingSlips/:packingSlipId/review-packing-slip',
            component: createReviewPackingSlip,
            name: 'reviewPackingSlip',
            meta: {
              title: 'Dashboard Suborder Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND']
            }
          },
          {
            path: '/dashboard/brand-boards',
            component: BrandBoards,
            name: 'dashboardBrandBoards',
            meta: {
              title: 'Dashboard Brand Boards',
              isPublic: false,
              roles: ['BRAND']
            }
          },
          {
            path: '/dashboard/invoices',
            component: Invoices,
            name: 'invoices',
            meta: {
              title: 'Dashboard Invoices',
              isPublic: false,
              roles: ['RETAILER', 'ADMIN']
            }
          },
          {
            path: '/dashboard/invoices/:invoiceId/details/:retailerId',
            component: InvoiceDetails,
            name: 'invoiceDetails',
            meta: {
              title: 'Dashboard Invoice Details',
              isPublic: false,
              roles: ['RETAILER', 'ADMIN']
            }
          },
          {
            path: '/dashboard/payments',
            component: Payments,
            name: 'payments',
            meta: {
              title: 'Dashboard Payments',
              isPublic: false,
              roles: ['ADMIN', 'BRAND']
            }
          },
          {
            path: '/dashboard/payments/:suborderId/details',
            component: PaymentDetails,
            name: 'paymentDetails',
            meta: {
              title: 'Dashboard Payment Details',
              isPublic: false,
              roles: ['ADMIN', 'BRAND']
            }
          },
          {
            path: '/dashboard/custom-assortments',
            component: CustomAssortments,
            name: 'customAssortments',
            meta: {
              title: 'Dashboard Custom Assortments',
              isPublic: false,
              roles: ['ADMIN', 'RETAILER']
            }
          },
          {
            path: '/dashboard/profile/:id?',
            component: Profile,
            name: 'dashboardProfile',
            meta: {
              title: 'Account Settings - Profile',
              isPublic: false,
              roles: ['ADMIN', 'RETAILER']
            }
          },
          {
            path: '/dashboard/password',
            component: Password,
            name: 'dashboardPassword',
            meta: {
              title: 'Account Settings - Password',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/admin-promo-codes',
            component: AdminPromoCodes,
            name: 'dashboardAdminPromoCodes',
            meta: {
              title: 'Promo Codes',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/dashboard/brand-promo-codes',
            component: BrandPromoCodes,
            name: 'dashboardBrandPromoCodes',
            meta: {
              title: 'Promo Codes',
              isPublic: false,
              roles: ['BRAND']
            }
          },
          {
            path: '/dashboard/addresses',
            component: Addresses,
            name: 'dashboardAddresses',
            meta: {
              title: 'Account Settings - Addresses',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/addresses/new',
            component: CreateEditAddress,
            name: 'dashboardCreateAddress',
            meta: {
              title: 'Account Settings - Create Address',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/addresses/:id/edit',
            component: CreateEditAddress,
            name: 'dashboardEditAddress',
            meta: {
              title: 'Account Settings - Edit Address',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/payment',
            component: PaymentInfo,
            name: 'dashboardPaymentInfo',
            meta: {
              title: 'Account Settings - Payment Info',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/credit',
            component: Credit,
            name: 'dashboardCredit',
            meta: {
              title: 'Account Settings - Credit',
              isPublic: false,
              roles: ['RETAILER']
            }
          },
          {
            path: '/dashboard/connect-shopify',
            component: ConnectShopify,
            name: 'connectShopify',
            meta: {
              title: 'Connect Shopify',
              isPublic: false,
              roles: ['BRAND']
            }
          }
        ]
      },
      {
        path: '/accountConfirmed',
        name: 'account-confirmed',
        component: BrandAccountConfirmed,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/retailer-set-password',
        name: 'retailer-set-password',
        component: RetailerSetPassword,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/',
        component: Home,
        name: 'home',
        meta: {
          isPublic: true
        }
      },
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/products/:parentCategory?/:category?/:productName/:productId',
        component: ProductDetails,
        name: 'productDetails',
        meta: {
          isPublic: true
        }
      },
      {
        path: '/brands',
        name: 'brands',
        component: BrandDirectoryPage,
        meta: {
          title: 'brands',
          isPublic: true
        }
      },
      {
        path: '/nynow',
        name: 'nynow',
        component: NavNYNOW,
        meta: {
          title: 'NY NOW Directory Nav',
          isPublic: true,
          keepScrollPosition: true
        }
      },
      {
        path: '/nynow-buyer',
        name: 'nynow',
        component: BuyerDirectoryNYNOW,
        meta: {
          title: 'NY NOW Buyer Directory',
          isPublic: true,
          keepScrollPosition: true
        }
      },
      {
        path: '/nynow-exhibitor',
        name: 'nynow',
        component: TheNYNOWDirectoryPage,
        meta: {
          title: 'New york Now Buyer Directory',
          isPublic: true,
          keepScrollPosition: true
        }
      },
      {
        path: `/${RouterPath.DEFAULT_SEARCH_PRODUCT_PAGE}`,
        name: 'search',
        component: DefaultSearchProductsPage,
        meta: {
          title: 'Search',
          isPublic: true
        }
      },
      {
        path: `/${RouterPath.COLLECTION_PRODUCTS_PAGE}/:collectionName/:collectionId`,
        name: 'Collection',
        component: CollectionProductsPage,
        meta: {
          isPublic: true
        },
        props: {
          fromNavBar: false
        }
      },
      {
        path: `/:navItemParentName?/:navItemName/:navItemId/${RouterPath.CATEGORY_PRODUCTS_PAGE}`,
        name: 'productsFromNavigation',
        component: CategoryProductsPage,
        meta: {
          isPublic: true
        }
      },
      {
        path: `/:categoryName/:collectionName/:collectionId/${RouterPath.COLLECTION_PRODUCTS_PAGE}`,
        name: 'CollectionWithCategory',
        component: CollectionProductsPage,
        meta: {
          isPublic: true
        },
        props: {
          fromNavBar: true,
          isParentCollection: false
        }
      },
      {
        path: `/:collectionName/:collectionId/${RouterPath.COLLECTION_PRODUCTS_PAGE}`,
        name: 'ParentCollection',
        component: CollectionProductsPage,
        meta: {
          isPublic: true
        },
        props: {
          isParentCollection: true,
          fromNavBar: true
        }
      },
      {
        path: `/${RouterPath.COLLECTION_PRODUCTS_PAGE}/:collectionName/:collectionId`,
        name: 'Collection',
        component: CollectionProductsPage,
        meta: {
          isPublic: true
        },
        props: {
          fromNavBar: false,
          isParentCollection: false
        }
      },
      {
        path: `/category/:categoryId/:categoryHandle/products/:productId/:productHandle`,
        component: ProductDetails,
        name: 'categoryProductDetails',
        meta: {
          isPublic: true
        }
      },
      {
        path: '/about',
        name: 'about',
        component: AboutUs,
        meta: {
          title: 'About',
          isPublic: true
        }
      },
      {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
        meta: {
          title: 'Checkout',
          isPublic: true
        }
      },
      {
        path: '/checkout/shipping',
        name: 'checkoutShipping',
        component: CheckoutSteps,
        meta: {
          title: 'Shipping',
          isPublic: false,
          roles: ['ADMIN', 'RETAILER']
        }
      },
      {
        path: '/checkout/order-review',
        name: 'checkoutOrderReview',
        component: CheckoutSteps,
        meta: {
          title: 'Order Details',
          isPublic: false,
          roles: ['ADMIN', 'RETAILER']
        }
      },
      {
        path: '/checkout/order-confirmation/:id',
        name: 'orderConfirmation',
        component: OrderConfirmation,
        meta: {
          title: 'Order Confirmation',
          isPublic: false,
          roles: ['ADMIN', 'RETAILER']
        }
      },
      {
        path: '/sign-in',
        name: 'signIn',
        component: Login,
        meta: {
          title: 'Sign In',
          isPublic: true
        }
      },
      {
        path: '/set-password',
        name: 'setPassword',
        component: SetPassword,
        meta: {
          title: 'Set Password',
          isPublic: true
        }
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
          title: 'Forgot Password',
          isPublic: true
        }
      },
      {
        path: '/faq/:page?',
        name: 'faq',
        component: undefined,
        meta: {
          title: 'FAQ',
          isPublic: true
        },
        beforeEnter() {
          window.location.replace(utils.constants.ZENDESK_URL)
        }
      },
      {
        path: '/wholesale-retail/buyer',
        name: 'buyer',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/exhibitor/surfexpo',
        name: 'exhibitor-surfexpo',
        component: ExhibitorSurfExpoPage,
        meta: {
          title: 'Exhibitor Surf Expo Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/buyer-adwords',
        name: 'buyer-adwords',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/buyer-linkedin',
        name: 'buyer-linkedin',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/buyer-instagram',
        name: 'buyer-instagram',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/buyer-facebook',
        name: 'buyer-facebook',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/buyer-email',
        name: 'buyer-email',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/buyer-partner',
        name: 'buyer-partner',
        component: BuyerPage,
        meta: {
          title: 'Buyer Page',
          isPublic: true
        }
      },
      {
        path: '/wholesale-retail/brand',
        name: 'Brand',
        component: BrandPage,
        meta: {
          title: 'Brand Page',
          isPublic: true
        }
      },
      {
        path: '/thank-you',
        name: 'thank you',
        component: ThankYouPage,
        meta: {
          title: 'Thank You!',
          isPublic: true
        }
      },
      {
        path: '/brands/:brandUrl',
        name: 'brand-bio',
        component: BrandBio,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/brands/preview-for-retailer/:brandUrl',
        name: 'brand-preview-for-retailer',
        component: BrandPreviewForRetailer,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/retailer/:id',
        name: 'edit-retailer-profile',
        component: RetailerProfile,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/retailer',
        name: 'retailer-profile',
        component: RetailerProfile,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/connect-to-stripe',
        component: ConnectToStripe,
        name: 'connectToStripe',
        meta: {
          title: 'Connect to Stripe',
          isPublic: true
        }
      },
      {
        path: '/refer',
        component: Refer,
        name: 'refer',
        meta: {
          title: 'Refer a Retailer',
          isPublic: false,
          roles: ['BRAND']
        }
      },
      {
        path: '/shopify-connect',
        component: ShopifyConnectStart,
        name: 'ShopifyConnectStart',
        meta: {
          title: 'Bulletin Shopify Connection',
          isPublic: true
        }
      },
      {
        path: '/sandbox',
        component: Sandbox,
        name: 'sandbox',
        redirect: '/sandbox/text-field',
        meta: {
          title: 'Component Sandbox',
          isPublic: false,
          roles: ['ADMIN']
        },
        children: [
          {
            path: '/sandbox/text-field',
            component: SandboxTextField,
            name: 'sandboxTextField',
            meta: {
              title: 'TextField',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/sandbox/button',
            component: SandboxButton,
            name: 'sandboxButton',
            meta: {
              title: 'Button',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/sandbox/address',
            component: SandboxAddress,
            name: 'sandboxAddress',
            meta: {
              title: 'Address',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/sandbox/filters',
            component: SandboxFilters,
            name: 'sandboxFilters',
            meta: {
              title: 'Filters',
              isPublic: false,
              roles: ['ADMIN']
            }
          },
          {
            path: '/sandbox/selectors',
            component: SandboxSelectors,
            name: 'sandboxSelectors',
            meta: {
              title: 'Selectors',
              isPublic: false,
              roles: ['ADMIN']
            }
          }
        ]
      },
      {
        path: '/reorder',
        component: ReorderPage,
        name: 'reorder',
        meta: {
          title: 'Reorder',
          isPublic: false,
          roles: ['RETAILER']
        }
      },
      {
        path: '/favorites',
        component: FavoritesPage,
        name: 'favorites',
        meta: {
          title: 'Favorites',
          isPublic: false,
          roles: ['RETAILER']
        }
      },
      {
        path: '/all-favorites/:boardId',
        component: FavoriteBoardDetailsPage,
        name: 'allFavorites',
        meta: {
          title: 'All Favorites',
          isPublic: false,
          roles: ['RETAILER']
        }
      },
      {
        path: '/favorite-board/:boardId',
        component: FavoriteBoardDetailsPage,
        name: 'favoriteBoards',
        meta: {
          title: 'Favorite Board',
          isPublic: false,
          roles: ['RETAILER']
        }
      },
      {
        path: '/favorites/recommended-brands',
        component: RecommendedBrandsPage,
        name: 'favorites-recommendations',
        meta: {
          title: 'Recommendations',
          isPublic: false,
          roles: ['RETAILER']
        }
      },
      {
        path: '/favorites/favorite-brands',
        component: FavoriteBrandsPage,
        name: 'favorite-brands',
        meta: {
          title: 'FavoriteBrands',
          isPublic: false,
          roles: ['RETAILER']
        }
      },
      {
        path: '/brand-boards/:boardId',
        component: BrandBoardsDetails,
        name: 'BrandBoardsDetails',
        meta: {
          title: 'Brand Board',
          isPublic: true,
          roles: ['ADMIN', 'BRAND', 'RETAILER']
        }
      },
      {
        path: '/site-map',
        component: SitemapPage,
        name: 'sitemap',
        meta: {
          title: 'Sitemap',
          isPublic: true
        }
      },
      {
        path: '/unavailable',
        name: 'unavailable',
        component: Unavailable,
        meta: {
          isPublic: true
        }
      },
      {
        path: '/bulletin-bucks-terms-and-conditions',
        name: 'BulletinBucksTerms',
        component: BulletinBucksPromoTerms,
        meta: {
          isPublic: true
        }
      },
      {
        path: '*',
        name: 'notFound',
        component: NotFound,
        meta: {
          isPublic: true
        }
      }
    ]
  }
]

export default routes
