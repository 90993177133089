import restApi from '../../http.js'
import session from '../session.js'

export default class CollectionApi {
  getConditions() {
    return restApi({
      method: 'GET',
      url: '/collections/automated/conditions',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  create(model) {
    return restApi({
      method: 'POST',
      url: '/collections',
      data: {
        active: model.active,
        collection_type: model.collection_type,
        description: model.description,
        include_all_conditions: model.include_all_conditions,
        product_search_criteria: model.product_search_criteria,
        title: model.title,
        featured_collection_id: model.featured_collection_id
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  deleteCollection(id) {
    return restApi({
      method: 'DELETE',
      url: '/collections/' + id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  attachImageToCollection(data, id) {
    return restApi({
      method: 'POST',
      url: '/collections/' + id + '/image',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  removeCollectionHeaderImage(id) {
    return restApi({
      method: 'DELETE',
      url: `/collections/${id}/image`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getCollection(id) {
    return restApi({
      method: 'GET',
      url: '/collections/' + id
    })
  }

  getProductsByCollectionId(id, page) {
    return restApi({
      method: 'GET',
      url: 'products/collections/' + id,
      params: {
        limit: 36,
        page: page
      }
    })
  }
  getAllProductsByCollectionId(id) {
    return restApi({
      method: 'GET',
      url: 'products/collections/' + id
    })
  }

  getFeaturedCollection() {
    return restApi({
      method: 'GET',
      url: '/collections/featured/home'
    })
  }

  getLandingCollection() {
    return restApi({
      method: 'GET',
      url: '/collections/featured/landing'
    })
  }

  save(request) {
    return restApi({
      method: 'PATCH',
      url: `/collections/${request.id}`,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getHomeCollections() {
    return restApi({
      method: 'GET',
      url: '/collections/home'
    })
  }
}
