<template>
  <div>
    <div
      class="favorite--overlay"
      :class="{
        'overlay-in-animation': !onBack && animate === 'IN',
        'overlay-out-animation': animate === 'OUT'
      }"
      @click="delayClose"
    ></div>
    <div
      class="favorite-wrapper flex flex-direction-column z-22"
      :class="{
        'favorite-in-animation': !onBack && animate === 'IN',
        'favorite-out-animation': animate === 'OUT'
      }"
    >
      <div class="favorite--header">
        <div class="flex items-center justify-between m-15" :class="{ 'justify-end': !onBack }">
          <Icon
            v-if="onBack"
            data-test-id="favorite_product_back_icon"
            icon-key="chevron-left"
            hover-type="OPACITY"
            :on-click="onBack"
          />
          <Icon
            icon-key="close"
            hover-type="OPACITY"
            data-test-id="favorite_product_close_icon"
            :on-click="delayClose"
          />
        </div>
      </div>
      <div class="favorite--body-container">
        <heading4 class="mb-10">{{ isCreate ? 'Create New Board' : 'Edit name' }}</heading4>
        <text-field
          v-model="name"
          data-test-id="favorite_product_board_name_input"
          class="width-100"
          name="name"
          label="Name"
          :maxlength="100"
          :no-spacing="true"
        />
      </div>
      <div class="favorite--footer">
        <bulletin-button
          data-test-id="favorite_product_save"
          full-width
          variant="primary"
          :disabled="!name"
          :loading="isLoading"
          @click.prevent="handleSubmit"
        >
          <span>Save</span>
        </bulletin-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BoardApi } from '@/api'
export default {
  props: {
    board: {
      type: Object
    },
    onBack: {
      type: Function
    },
    onClose: {
      type: Function
    },
    onSuccessCallback: {
      type: Function
    }
  },
  data() {
    return {
      name: '',
      isLoading: false,
      animate: 'IN'
    }
  },
  computed: {
    isCreate() {
      return !this.board
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.name = this.board.name
    }
  },
  methods: {
    handleSubmit,
    handleCreateNewBoard,
    handleEditBoard,
    delayClose
  }
}

async function handleSubmit() {
  if (this.isCreate) {
    await this.handleCreateNewBoard()
  } else {
    await this.handleEditBoard()
  }
}

async function handleCreateNewBoard() {
  try {
    this.isLoading = true
    const { id } = await BoardApi.createBoard({ createBoardRequest: { name: this.name } })
    await this.onSuccessCallback(id)
    this.$trackEvent({
      action: 'Bulletin Board Created',
      properties: {
        'User Role': 'RETAILER'
      }
    })
    this.onBack ? this.onBack() : this.delayClose()
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    this.isLoading = false
  }
}

async function handleEditBoard() {
  try {
    this.isLoading = true
    await BoardApi.renameBoard({
      id: this.board.id,
      renameBoardRequest: { name: this.name }
    })
    await this.onSuccessCallback(this.board.id)
    this.$trackEvent({ action: 'Bulletin Board Name Update' })
    this.onClose()
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    this.isLoading = false
  }
}

function delayClose() {
  this.animate = 'OUT'
  setTimeout(() => {
    this.onClose()
  }, 400)
}
</script>
