/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomAssortmentItemRequest } from './CustomAssortmentItemRequest';
import {
    CustomAssortmentItemRequestFromJSON,
    CustomAssortmentItemRequestFromJSONTyped,
    CustomAssortmentItemRequestToJSON,
    CustomAssortmentItemRequestToJSONTyped,
} from './CustomAssortmentItemRequest';

/**
 * Create custom assortment object
 * @export
 * @interface CreateCustomAssortmentRequest
 */
export interface CreateCustomAssortmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomAssortmentRequest
     */
    retailer_id?: string;
    /**
     * 
     * @type {Array<CustomAssortmentItemRequest>}
     * @memberof CreateCustomAssortmentRequest
     */
    custom_assortment_items?: Array<CustomAssortmentItemRequest>;
}

/**
 * Check if a given object implements the CreateCustomAssortmentRequest interface.
 */
export function instanceOfCreateCustomAssortmentRequest(value: object): value is CreateCustomAssortmentRequest {
    return true;
}

export function CreateCustomAssortmentRequestFromJSON(json: any): CreateCustomAssortmentRequest {
    return CreateCustomAssortmentRequestFromJSONTyped(json, false);
}

export function CreateCustomAssortmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomAssortmentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'custom_assortment_items': json['custom_assortment_items'] == null ? undefined : ((json['custom_assortment_items'] as Array<any>).map(CustomAssortmentItemRequestFromJSON)),
    };
}

export function CreateCustomAssortmentRequestToJSON(json: any): CreateCustomAssortmentRequest {
    return CreateCustomAssortmentRequestToJSONTyped(json, false);
}

export function CreateCustomAssortmentRequestToJSONTyped(value?: CreateCustomAssortmentRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'retailer_id': value['retailer_id'],
        'custom_assortment_items': value['custom_assortment_items'] == null ? undefined : ((value['custom_assortment_items'] as Array<any>).map(CustomAssortmentItemRequestToJSON)),
    };
}

