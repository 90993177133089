<template>
  <div>
    <div
      class="favorite--overlay"
      :class="{
        'overlay-in-animation': !onBack && animate === 'IN',
        'overlay-out-animation': animate === 'OUT'
      }"
      @click="delayClose"
    ></div>
    <div
      class="favorite-wrapper flex flex-direction-column z-22"
      :class="{
        'favorite-in-animation': !onBack && animate === 'IN',
        'favorite-out-animation': animate === 'OUT'
      }"
    >
      <div class="favorite--header">
        <div class="flex items-center justify-between m-15" :class="{ 'justify-end': !onBack }">
          <Icon
            v-if="onBack"
            data-test-id="favorite_product_back_icon"
            icon-key="chevron-left"
            hover-type="OPACITY"
            :on-click="onBack"
          />
          <Icon
            data-test-id="favorite_product_close_icon"
            icon-key="close"
            hover-type="OPACITY"
            :on-click="delayClose"
          />
        </div>
      </div>
      <div class="favorite--body-container">
        <heading4 class="mb-10">{{ isCreate ? 'Create New Board' : 'Edit Board' }}</heading4>
        <text-field
          v-model="name"
          data-test-id="favorite_product_board_name_input"
          class="width-100"
          type="text"
          name="name"
          label="Name"
          :maxlength="100"
          :no-spacing="true"
        />
        <visibility-dropdown :value="visibility" :on-change="handleChangeVisibility" />
        <toggle-with-description
          :value="show_on_profile"
          :on-change="toggleShowOnProfile"
          label="Publish Board"
          description="Show this board as a collection on your brand profile page."
        />
      </div>
      <div class="favorite--footer">
        <bulletin-button
          data-test-id="favorite_product_save"
          full-width
          variant="primary"
          :disabled="disableButton"
          :loading="isLoading"
          @click.prevent="handleSubmit"
        >
          <span>Save</span>
        </bulletin-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BrandBoardApi } from '@/api'
import VisibilityDropdown from './formFields/VisibilityDropdown'

export default {
  name: 'BrandBoardCreate',
  components: { VisibilityDropdown },
  props: {
    brandBoard: {
      type: Object
    },
    onBack: {
      type: Function
    },
    onClose: {
      type: Function
    },
    onSuccessCallback: {
      type: Function
    }
  },
  data() {
    return {
      name: '',
      visibility: 'PUBLIC',
      show_on_profile: false,
      isLoading: false,
      animate: 'IN'
    }
  },
  computed: {
    isCreate() {
      return !this.brandBoard
    },
    disableButton() {
      if (this.isCreate) return !this.name
      return (
        this.name === this.brandBoard.name &&
        this.visibility === this.brandBoard.visibility &&
        this.show_on_profile === this.brandBoard.show_on_profile
      )
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.name = this.brandBoard.name
      this.visibility = this.brandBoard.visibility
      this.show_on_profile = this.brandBoard.show_on_profile
    }
  },
  methods: {
    handleSubmit,
    handleCreateNewBoard,
    handleEditBoard,
    handleChangeVisibility,
    toggleShowOnProfile,
    delayClose
  }
}

async function handleSubmit() {
  if (this.isCreate) {
    await this.handleCreateNewBoard()
  } else {
    await this.handleEditBoard()
  }
}

async function handleCreateNewBoard() {
  try {
    this.isLoading = true
    const brandId = this.$session.getUserInfo().brand_id
    const { id } = await BrandBoardApi.createBrandBoard({
      brandId,
      createBrandBoardRequest: {
        name: this.name,
        visibility: this.visibility,
        show_on_profile: this.show_on_profile
      }
    })
    await this.onSuccessCallback(id)
    this.$trackEvent({
      action: 'Bulletin Board Created',
      properties: {
        'User Role': 'BRAND'
      }
    })
    this.onBack ? this.onBack() : this.delayClose()
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    this.isLoading = false
  }
}

async function handleEditBoard() {
  try {
    this.isLoading = true
    await BrandBoardApi.updateBrandBoard({
      id: this.brandBoard.id,
      updateBrandBoardRequest: {
        name: this.name,
        visibility: this.visibility,
        show_on_profile: this.show_on_profile
      }
    })
    await this.onSuccessCallback(this.brandBoard.id)
    this.onClose()
  } catch (error) {
    this.$handleError(this, error)
  } finally {
    this.isLoading = false
  }
}

function handleChangeVisibility(visibility) {
  this.visibility = visibility
}

function toggleShowOnProfile() {
  this.show_on_profile = !this.show_on_profile
}

function delayClose() {
  this.animate = 'OUT'
  setTimeout(() => {
    this.onClose()
  }, 400)
}
</script>
