/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RetailersApplicationsPagination } from './RetailersApplicationsPagination';
import {
    RetailersApplicationsPaginationFromJSON,
    RetailersApplicationsPaginationFromJSONTyped,
    RetailersApplicationsPaginationToJSON,
    RetailersApplicationsPaginationToJSONTyped,
} from './RetailersApplicationsPagination';
import type { RetailersApplicationsResponse } from './RetailersApplicationsResponse';
import {
    RetailersApplicationsResponseFromJSON,
    RetailersApplicationsResponseFromJSONTyped,
    RetailersApplicationsResponseToJSON,
    RetailersApplicationsResponseToJSONTyped,
} from './RetailersApplicationsResponse';

/**
 * 
 * @export
 * @interface PaginatedRetailersApplicationsResponse
 */
export interface PaginatedRetailersApplicationsResponse {
    /**
     * 
     * @type {Array<RetailersApplicationsResponse>}
     * @memberof PaginatedRetailersApplicationsResponse
     */
    hits?: Array<RetailersApplicationsResponse>;
    /**
     * 
     * @type {RetailersApplicationsPagination}
     * @memberof PaginatedRetailersApplicationsResponse
     */
    pagination?: RetailersApplicationsPagination;
}

/**
 * Check if a given object implements the PaginatedRetailersApplicationsResponse interface.
 */
export function instanceOfPaginatedRetailersApplicationsResponse(value: object): value is PaginatedRetailersApplicationsResponse {
    return true;
}

export function PaginatedRetailersApplicationsResponseFromJSON(json: any): PaginatedRetailersApplicationsResponse {
    return PaginatedRetailersApplicationsResponseFromJSONTyped(json, false);
}

export function PaginatedRetailersApplicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedRetailersApplicationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(RetailersApplicationsResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : RetailersApplicationsPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedRetailersApplicationsResponseToJSON(json: any): PaginatedRetailersApplicationsResponse {
    return PaginatedRetailersApplicationsResponseToJSONTyped(json, false);
}

export function PaginatedRetailersApplicationsResponseToJSONTyped(value?: PaginatedRetailersApplicationsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(RetailersApplicationsResponseToJSON)),
        'pagination': RetailersApplicationsPaginationToJSON(value['pagination']),
    };
}

