/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ManualCollectionResponse } from './ManualCollectionResponse';
import {
    ManualCollectionResponseFromJSON,
    ManualCollectionResponseFromJSONTyped,
    ManualCollectionResponseToJSON,
    ManualCollectionResponseToJSONTyped,
} from './ManualCollectionResponse';

/**
 * 
 * @export
 * @interface GetAllManualCollectionsResponse
 */
export interface GetAllManualCollectionsResponse {
    /**
     * 
     * @type {Array<ManualCollectionResponse>}
     * @memberof GetAllManualCollectionsResponse
     */
    collections?: Array<ManualCollectionResponse>;
}

/**
 * Check if a given object implements the GetAllManualCollectionsResponse interface.
 */
export function instanceOfGetAllManualCollectionsResponse(value: object): value is GetAllManualCollectionsResponse {
    return true;
}

export function GetAllManualCollectionsResponseFromJSON(json: any): GetAllManualCollectionsResponse {
    return GetAllManualCollectionsResponseFromJSONTyped(json, false);
}

export function GetAllManualCollectionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllManualCollectionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'collections': json['collections'] == null ? undefined : ((json['collections'] as Array<any>).map(ManualCollectionResponseFromJSON)),
    };
}

export function GetAllManualCollectionsResponseToJSON(json: any): GetAllManualCollectionsResponse {
    return GetAllManualCollectionsResponseToJSONTyped(json, false);
}

export function GetAllManualCollectionsResponseToJSONTyped(value?: GetAllManualCollectionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'collections': value['collections'] == null ? undefined : ((value['collections'] as Array<any>).map(ManualCollectionResponseToJSON)),
    };
}

