/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStockistsResponse
 */
export interface GetStockistsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStockistsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStockistsResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStockistsResponse
     */
    city?: string;
}

/**
 * Check if a given object implements the GetStockistsResponse interface.
 */
export function instanceOfGetStockistsResponse(value: object): value is GetStockistsResponse {
    return true;
}

export function GetStockistsResponseFromJSON(json: any): GetStockistsResponse {
    return GetStockistsResponseFromJSONTyped(json, false);
}

export function GetStockistsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStockistsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'city': json['city'] == null ? undefined : json['city'],
    };
}

export function GetStockistsResponseToJSON(json: any): GetStockistsResponse {
    return GetStockistsResponseToJSONTyped(json, false);
}

export function GetStockistsResponseToJSONTyped(value?: GetStockistsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'city': value['city'],
    };
}

