/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Add Products to manual collection object
 * @export
 * @interface AddProductsToManualCollectionRequest
 */
export interface AddProductsToManualCollectionRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddProductsToManualCollectionRequest
     */
    collection_ids: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddProductsToManualCollectionRequest
     */
    product_ids: Array<string>;
}

/**
 * Check if a given object implements the AddProductsToManualCollectionRequest interface.
 */
export function instanceOfAddProductsToManualCollectionRequest(value: object): value is AddProductsToManualCollectionRequest {
    if (!('collection_ids' in value) || value['collection_ids'] === undefined) return false;
    if (!('product_ids' in value) || value['product_ids'] === undefined) return false;
    return true;
}

export function AddProductsToManualCollectionRequestFromJSON(json: any): AddProductsToManualCollectionRequest {
    return AddProductsToManualCollectionRequestFromJSONTyped(json, false);
}

export function AddProductsToManualCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProductsToManualCollectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'collection_ids': json['collection_ids'],
        'product_ids': json['product_ids'],
    };
}

export function AddProductsToManualCollectionRequestToJSON(json: any): AddProductsToManualCollectionRequest {
    return AddProductsToManualCollectionRequestToJSONTyped(json, false);
}

export function AddProductsToManualCollectionRequestToJSONTyped(value?: AddProductsToManualCollectionRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'collection_ids': value['collection_ids'],
        'product_ids': value['product_ids'],
    };
}

