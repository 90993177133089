import ProductApi from '../../../../services/api/productApi.js'
import FavoritesApi from '../../../../services/api/favoritesApi.js'

import ProductDescriptionHeader from '../productDescriptionHeader/productDescriptionHeader.vue'
import { mapGetters } from 'vuex'
import algoliasearch from 'algoliasearch/lite'
import { cartHelper } from '@/components/mixin/cartHelper'
import { productDetailsHelper } from '@/components/mixin/product/productDetailsHelper'
import VisitorView from '@/views/TheProductDetailsPage/VisitorView'
import ShippingTime from '@/views/TheProductDetailsPage/ShippingTime'

const ProductApiService = new ProductApi(),
  FavoritesApiService = new FavoritesApi()

export default {
  name: 'quickshopmodal',
  mixins: [cartHelper, productDetailsHelper],
  components: {
    ProductDescriptionHeader,
    VisitorView,
    ShippingTime
  },
  data() {
    return {
      numberOfOptions: null,
      selectedOption: {},
      selectedVariant: null,
      show: false,
      product: null,
      totalPrice: 0,
      quantity: null,
      fulfilledQuantity: null,
      shippedQuantity: null,
      faveData: null,
      config: {}
    }
  },

  computed: {
    suborderStatus,
    ...mapGetters({
      user: 'userState'
    }),
    displayNotSelling() {
      const { ships_from_usa_or_canada, buy_on_bulletin, source } = this.product.brand
      return (
        !this.isAddToCardEnabled() &&
        (!ships_from_usa_or_canada || (ships_from_usa_or_canada && !buy_on_bulletin && source === 'EXHIBITOR'))
      )
    }
  },

  methods: {
    selectVariant,
    close,
    showQuickShopModalCallback,
    showQuickShopModalByIdCallback,
    updateProductWithMovData,
    updateTotalPrice,
    handleAddToCart
  },
  async beforeMount() {},

  mounted() {
    this.$eventBus.$on('show-quick-shop-modal', this.showQuickShopModalCallback)
    this.$eventBus.$on('show-quick-shop-modal-by-id', this.showQuickShopModalByIdCallback)
    this.$eventBus.$on('close-quick-shop-modal', this.close)
    this.$eventBus.$on('refresh-product-cart-summary', this.fetchCartAndUpdateVariants)
  },

  beforeDestroy() {
    this.$eventBus.$off('refresh-product-cart-summary', this.fetchCartAndUpdateVariants)
    this.$eventBus.$off('show-quick-shop-modal', this.showQuickShopModalCallback)
    this.$eventBus.$off('show-quick-shop-modal-by-id', this.showQuickShopModalByIdCallback)
    this.$eventBus.$on('close-quick-shop-modal', this.close)
  }
}

function selectVariant(val) {
  if (val) {
    this.selectedVariant = val
    this.totalPrice = this.quantity * this.selectedVariant.price
  } else {
    this.$handleError(
      this,
      'Sorry, this variant is not available at the moment. Please select another combination of options'
    )
  }
}

function showQuickShopModalByIdCallback(id) {
  const objectArray = []
  objectArray.push(id)
  algoliasearch(this.$env.algolia().appId, this.$env.algolia().token, {
    timeouts: {
      connect: this.$utils.constants.ALGOLIA_CONNECT_TIMEOUT,
      read: this.$utils.constants.ALGOLIA_READ_TIMEOUT
    }
  })
    .initIndex(this.$env.getOrigin() + '_products')
    .getObjects(objectArray)
    .then((data) => this.showQuickShopModalCallback(data.results[0]))
}
function showQuickShopModalCallback(product, config) {
  const _self = this
  _self.config = config || {}

  ProductApiService.getProduct(product.id)
    // eslint-disable-next-line complexity
    .then(async (response) => {
      _self.product = response.data
      _self.product.description_stripped = _self.$utils.stripHTML(_self.product.description)

      _self.product.brand = {
        company_name: response.data.brand.name,
        id: response.data.brand.id,
        minimum_order_value: response.data.brand.minimum_order_value,
        vanity_url_name: response.data.brand.vanity_url_name,
        ships_from_usa_or_canada: response.data.brand.ships_from_usa_or_canada,
        buy_on_bulletin: response.data.brand.buy_on_bulletin,
        source: response.data.brand.source
      }

      _self.product.variants = this.mapVariants(response.data?.variants, _self.product, this.cart)
      _self.hasInStockVariant = _self.product.variants?.filter((variant) => variant.in_stock).length
      _self.quantity = _self.product.wholesale_minimum

      if (_self.product.variants.length === 1) {
        _self.selectVariant(_self.product.variants[0])
      }

      _self.show = true
      _self.$trackEvent({
        action: 'View PDP',
        properties: {
          'Pdp Type': 'quickshop',
          'Entry Point': window.location.href,
          'Product Name': _self.product.name,
          Brand: _self.product.brand.company_name,
          'Product Id': _self.product.id
        }
      })

      if (_self.user && _self.user.role === 'RETAILER') {
        const orderCount = (await _self.$utils.getOrderCount(_self.product.brand.id)).data.order_count

        if (orderCount > 0) {
          _self.product.brand.minimum_order_value = response.data.brand.minimum_reorder_value
        }
      }

      _self.updateProductWithMovData(_self.product)

      if (_self.user && _self.user.role === 'RETAILER') {
        FavoritesApiService.getFavoriteProducts()
          .then((response) => {
            let ids = response.data.product_ids
            if (ids === undefined) {
              ids = []
            }
            _self.faveData = {
              is_fav: ids.includes(_self.product.id),
              id: _self.product.id
            }
          })
          .catch((error) => {
            _self.$handleError(_self, error)
          })
        await this.fetchCart()
      }
    })
    .catch((error) => {
      _self.$handleError(_self, error)
    })
}

function getBrandMinimumData(productID) {
  const cartData = JSON.parse(localStorage.getItem('CHECKOUT_BULLETIN'))
  if (cartData) {
    const brandData = cartData.brands
      .filter((brand) => brand.info.id === productID)
      .map((brand) => {
        return {
          subtotal: brand.subtotal,
          balance: brand.info.minimum_order_value - brand.subtotal
        }
      })
    return brandData.length === 0 ? false : brandData[0]
  }
  return false
}

function updateProductWithMovData(product) {
  const data = getBrandMinimumData(product.brand.id)
  if (data) {
    product.showMinimum = false
    product.showMinReached = data.balance <= 0
    product.amountToGo = data.balance.toFixed(this.$utils.constants.PRICE_FORMAT_DECIMAL_PLACES)
  } else {
    product.showMinimum = true
  }
}

function updateTotalPrice(quantity, price) {
  this.totalPrice = quantity * price
}

function close() {
  this.totalPrice = 0
  this.selectedVariant = null
  this.selectedOption = {}
  this.show = false
  this.product = null
}

function suborderStatus() {
  return this.config.suborder ? this.config.suborder.status : null
}

function handleAddToCart() {
  if (this.config.isOrderAdjustmentModal) {
    this.$eventBus.$emit('order-adjustment-product-added', this.selectedVariants)
  } else {
    this.addToCart()
  }
}
