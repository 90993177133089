import restApi from '../../http.js'
import session from '../session'
export default class BrandApi {
  getPublicBrand(id) {
    return restApi({
      method: 'GET',
      url: '/brands/' + id + '/public'
    })
  }

  getBrand(id) {
    return restApi({
      method: 'GET',
      url: '/brands/' + id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getBrandEmailVerification(id) {
    return restApi({
      method: 'GET',
      url: `/user/${id}/email-verification`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateBrandEmailVerification(id, request) {
    return restApi({
      method: 'PATCH',
      url: `/user/${id}/verify-email`,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  generatePasswordRestLink(id) {
    return restApi({
      method: 'PATCH',
      url: `/user/${id}/generate-password-reset-code`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getBrandBySlug(slug) {
    return restApi({
      method: 'GET',
      url: '/brands/brand-bio/' + slug,
      headers: {
        Authorization: session.getAccessToken() ? 'Bearer ' + session.getAccessToken() : ''
      }
    })
  }

  updateBrand(request) {
    return restApi({
      method: 'PATCH',
      url: '/brands/' + request.id,
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  updateBrandMe(request) {
    return restApi({
      method: 'PATCH',
      url: '/brands/me',
      data: request,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getBrandMe() {
    return restApi({
      method: 'GET',
      url: '/brands/me',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  uploadBrandImage(data, id) {
    return restApi({
      method: 'POST',
      url: '/brands/' + id + '/image',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  uploadBrandThumb(data, id) {
    return restApi({
      method: 'POST',
      url: '/brands/' + id + '/thumbnail',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  uploadBrandImageMe(data) {
    return restApi({
      method: 'POST',
      url: '/brands/my-image',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
  uploadBrandThumbMe(data) {
    return restApi({
      method: 'POST',
      url: '/brands/my-thumbnail',
      data: data,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  onboardingFormSubmit(request) {
    return restApi({
      method: 'POST',
      url: '/brands/onboarding-form',
      data: request
    })
  }

  outboundOnboardingFormSubmit(request) {
    return restApi({
      method: 'POST',
      url: '/brands/outbound-onboarding-form',
      data: request
    })
  }

  uploadDocs(request, code = '') {
    return restApi({
      method: 'POST',
      url: '/brands/docs?code=' + code,
      data: request
    })
  }

  uploadDocs2(request) {
    return restApi({
      method: 'POST',
      url: '/brands/docs?code=',
      data: request
    })
  }

  getInfoFromCode(code) {
    return restApi({
      method: 'GET',
      url: '/brands/apply-to-sell-details',
      params: {
        code: code
      }
    })
  }

  approveOnboarding(id) {
    return restApi({
      method: 'PATCH',
      url: `onboarding/brand/${id}/approve`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  deny(id) {
    return restApi({
      method: 'PATCH',
      url: `/brands/${id}/deny`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getBrandDetails(id) {
    return restApi({
      method: 'GET',
      url: `/brands/${id}/details`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  async getBrandValues(onlyActives) {
    const response = await restApi({
      method: 'GET',
      url: '/brands/values/dropdown'
    })

    if (onlyActives && response?.data) {
      response.data = response?.data?.filter((item) => item.active)
    }
    return response
  }

  async getBrandSpecialServices(onlyActives) {
    const response = await restApi({
      method: 'GET',
      url: '/brands/special-services'
    })

    if (onlyActives && response?.data) {
      response.data = response?.data?.filter((item) => item.active)
    }
    return response
  }

  async getDealsAndPromotions(onlyActives) {
    const response = await restApi({
      method: 'GET',
      url: '/brands/deals-and-promotions'
    })

    if (onlyActives && response?.data) {
      response.data = response?.data?.filter((item) => item.active)
    }
    return response
  }

  getBrandStockist(id) {
    return restApi({
      method: 'GET',
      url: `/brands/${id}/stockists`
    })
  }

  getMyBrandPromoCodes() {
    return restApi({
      method: 'GET',
      url: `/brands/promo-codes/active`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getBrandsFromCategoryContainerId(containerId) {
    return restApi({
      method: 'GET',
      url: `/brands/container/${containerId}/dropdown`
    })
  }
  getBrandsFromCategoryComponentId(componentId) {
    return restApi({
      method: 'GET',
      url: `brands/component/${componentId}/dropdown`
    })
  }

  getSpecialServices() {
    return restApi({
      method: 'GET',
      url: '/brands/special-services'
    })
  }
  getDealsPromotions() {
    return restApi({
      method: 'GET',
      url: '/brands/deals-and-promotions'
    })
  }
  resendVerificationForOutreachBrand(request) {
    return restApi({
      method: 'POST',
      url: '/brands/verify',
      data: request
    })
  }
  getDraftBrand(id) {
    if (!id) {
      return window.location.reload()
    }
    return restApi({
      method: 'GET',
      url: '/onboarding/brand/' + id,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getMarketingCategoryValues() {
    return restApi({
      method: 'GET',
      url: '/onboarding/marketing-categories'
    })
  }

  updateDraftBrand(id, payload) {
    return restApi({
      method: 'PATCH',
      url: '/onboarding/brand/' + id,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  completeBrandOnboarding(id) {
    return restApi({
      method: 'PATCH',
      url: `onboarding/brand/${id}/complete`,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  brandCsvUpload(payload) {
    return restApi({
      method: 'POST',
      url: '/products/csv-import/v2',
      data: payload,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  brandLineSheetUpload(id, payload) {
    return restApi({
      method: 'PATCH',
      url: `onboarding/brand/${id}/line-sheet`,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  getBrandCategories() {
    return restApi({
      method: 'GET',
      url: '/trade-shows/marketing-category-values'
    })
  }

  moveDataFromDraftBrandTable(id, payload) {
    return restApi({
      method: 'PATCH',
      url: `onboarding/brand/${id}/move-data`,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
