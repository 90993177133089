/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConditionKeyResponse } from './ConditionKeyResponse';
import {
    ConditionKeyResponseFromJSON,
    ConditionKeyResponseFromJSONTyped,
    ConditionKeyResponseToJSON,
    ConditionKeyResponseToJSONTyped,
} from './ConditionKeyResponse';
import type { ConditionOperatorResponse } from './ConditionOperatorResponse';
import {
    ConditionOperatorResponseFromJSON,
    ConditionOperatorResponseFromJSONTyped,
    ConditionOperatorResponseToJSON,
    ConditionOperatorResponseToJSONTyped,
} from './ConditionOperatorResponse';

/**
 * 
 * @export
 * @interface AutomatedCollectionConditionsResponse
 */
export interface AutomatedCollectionConditionsResponse {
    /**
     * 
     * @type {Array<ConditionKeyResponse>}
     * @memberof AutomatedCollectionConditionsResponse
     */
    condition_keys?: Array<ConditionKeyResponse>;
    /**
     * 
     * @type {Array<ConditionOperatorResponse>}
     * @memberof AutomatedCollectionConditionsResponse
     */
    condition_operators?: Array<ConditionOperatorResponse>;
}

/**
 * Check if a given object implements the AutomatedCollectionConditionsResponse interface.
 */
export function instanceOfAutomatedCollectionConditionsResponse(value: object): value is AutomatedCollectionConditionsResponse {
    return true;
}

export function AutomatedCollectionConditionsResponseFromJSON(json: any): AutomatedCollectionConditionsResponse {
    return AutomatedCollectionConditionsResponseFromJSONTyped(json, false);
}

export function AutomatedCollectionConditionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomatedCollectionConditionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'condition_keys': json['condition_keys'] == null ? undefined : ((json['condition_keys'] as Array<any>).map(ConditionKeyResponseFromJSON)),
        'condition_operators': json['condition_operators'] == null ? undefined : ((json['condition_operators'] as Array<any>).map(ConditionOperatorResponseFromJSON)),
    };
}

export function AutomatedCollectionConditionsResponseToJSON(json: any): AutomatedCollectionConditionsResponse {
    return AutomatedCollectionConditionsResponseToJSONTyped(json, false);
}

export function AutomatedCollectionConditionsResponseToJSONTyped(value?: AutomatedCollectionConditionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'condition_keys': value['condition_keys'] == null ? undefined : ((value['condition_keys'] as Array<any>).map(ConditionKeyResponseToJSON)),
        'condition_operators': value['condition_operators'] == null ? undefined : ((value['condition_operators'] as Array<any>).map(ConditionOperatorResponseToJSON)),
    };
}

