/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RestrictExhibitorVisibility
 */
export interface RestrictExhibitorVisibility {
    /**
     * 
     * @type {Array<string>}
     * @memberof RestrictExhibitorVisibility
     */
    event_ids?: Array<string>;
}

/**
 * Check if a given object implements the RestrictExhibitorVisibility interface.
 */
export function instanceOfRestrictExhibitorVisibility(value: object): value is RestrictExhibitorVisibility {
    return true;
}

export function RestrictExhibitorVisibilityFromJSON(json: any): RestrictExhibitorVisibility {
    return RestrictExhibitorVisibilityFromJSONTyped(json, false);
}

export function RestrictExhibitorVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestrictExhibitorVisibility {
    if (json == null) {
        return json;
    }
    return {
        
        'event_ids': json['event_ids'] == null ? undefined : json['event_ids'],
    };
}

export function RestrictExhibitorVisibilityToJSON(json: any): RestrictExhibitorVisibility {
    return RestrictExhibitorVisibilityToJSONTyped(json, false);
}

export function RestrictExhibitorVisibilityToJSONTyped(value?: RestrictExhibitorVisibility | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'event_ids': value['event_ids'],
    };
}

