/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailerSuborderResponse
 */
export interface RetailerSuborderResponse {
    /**
     * 
     * @type {string}
     * @memberof RetailerSuborderResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RetailerSuborderResponse
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof RetailerSuborderResponse
     */
    status?: RetailerSuborderResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof RetailerSuborderResponse
     */
    amount?: number;
}


/**
 * @export
 */
export const RetailerSuborderResponseStatusEnum = {
    Hold: 'HOLD',
    Void: 'VOID',
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    Expired: 'EXPIRED',
    PartiallyFulfilled: 'PARTIALLY_FULFILLED',
    FulfillmentInProgress: 'FULFILLMENT_IN_PROGRESS',
    Shipped: 'SHIPPED',
    Paid: 'PAID',
    Failed: 'FAILED',
    NotCollectable: 'NOT_COLLECTABLE',
    Cancelled: 'CANCELLED'
} as const;
export type RetailerSuborderResponseStatusEnum = typeof RetailerSuborderResponseStatusEnum[keyof typeof RetailerSuborderResponseStatusEnum];


/**
 * Check if a given object implements the RetailerSuborderResponse interface.
 */
export function instanceOfRetailerSuborderResponse(value: object): value is RetailerSuborderResponse {
    return true;
}

export function RetailerSuborderResponseFromJSON(json: any): RetailerSuborderResponse {
    return RetailerSuborderResponseFromJSONTyped(json, false);
}

export function RetailerSuborderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerSuborderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'status': json['status'] == null ? undefined : json['status'],
        'amount': json['amount'] == null ? undefined : json['amount'],
    };
}

export function RetailerSuborderResponseToJSON(json: any): RetailerSuborderResponse {
    return RetailerSuborderResponseToJSONTyped(json, false);
}

export function RetailerSuborderResponseToJSONTyped(value?: RetailerSuborderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'status': value['status'],
        'amount': value['amount'],
    };
}

