/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductFromBrandBoardResponse
 */
export interface ProductFromBrandBoardResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductFromBrandBoardResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFromBrandBoardResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductFromBrandBoardResponse
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFromBrandBoardResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductFromBrandBoardResponse
     */
    image?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductFromBrandBoardResponse
     */
    added_at?: Date;
}

/**
 * Check if a given object implements the ProductFromBrandBoardResponse interface.
 */
export function instanceOfProductFromBrandBoardResponse(value: object): value is ProductFromBrandBoardResponse {
    return true;
}

export function ProductFromBrandBoardResponseFromJSON(json: any): ProductFromBrandBoardResponse {
    return ProductFromBrandBoardResponseFromJSONTyped(json, false);
}

export function ProductFromBrandBoardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductFromBrandBoardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'image': json['image'] == null ? undefined : json['image'],
        'added_at': json['added_at'] == null ? undefined : (new Date(json['added_at'])),
    };
}

export function ProductFromBrandBoardResponseToJSON(json: any): ProductFromBrandBoardResponse {
    return ProductFromBrandBoardResponseToJSONTyped(json, false);
}

export function ProductFromBrandBoardResponseToJSONTyped(value?: ProductFromBrandBoardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'wholesale_price': value['wholesale_price'],
        'pos_price': value['pos_price'],
        'image': value['image'],
        'added_at': value['added_at'] == null ? undefined : ((value['added_at']).toISOString()),
    };
}

