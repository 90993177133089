/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TradeShowFeatureRequest } from './TradeShowFeatureRequest';
import {
    TradeShowFeatureRequestFromJSON,
    TradeShowFeatureRequestFromJSONTyped,
    TradeShowFeatureRequestToJSON,
    TradeShowFeatureRequestToJSONTyped,
} from './TradeShowFeatureRequest';

/**
 * Update TradeShow object
 * @export
 * @interface UpdateTradeShowRequest
 */
export interface UpdateTradeShowRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    location?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTradeShowRequest
     */
    start_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTradeShowRequest
     */
    end_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTradeShowRequest
     */
    commission_start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTradeShowRequest
     */
    commission_end?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTradeShowRequest
     */
    zero_commission_start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTradeShowRequest
     */
    zero_commission_end?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    registration_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTradeShowRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTradeShowRequest
     */
    booth_map_url?: string;
    /**
     * 
     * @type {Array<TradeShowFeatureRequest>}
     * @memberof UpdateTradeShowRequest
     */
    features?: Array<TradeShowFeatureRequest>;
}

/**
 * Check if a given object implements the UpdateTradeShowRequest interface.
 */
export function instanceOfUpdateTradeShowRequest(value: object): value is UpdateTradeShowRequest {
    return true;
}

export function UpdateTradeShowRequestFromJSON(json: any): UpdateTradeShowRequest {
    return UpdateTradeShowRequestFromJSONTyped(json, false);
}

export function UpdateTradeShowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTradeShowRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'brand': json['brand'] == null ? undefined : json['brand'],
        'name': json['name'] == null ? undefined : json['name'],
        'location': json['location'] == null ? undefined : json['location'],
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'commission_start': json['commission_start'] == null ? undefined : (new Date(json['commission_start'])),
        'commission_end': json['commission_end'] == null ? undefined : (new Date(json['commission_end'])),
        'zero_commission_start': json['zero_commission_start'] == null ? undefined : (new Date(json['zero_commission_start'])),
        'zero_commission_end': json['zero_commission_end'] == null ? undefined : (new Date(json['zero_commission_end'])),
        'registration_url': json['registration_url'] == null ? undefined : json['registration_url'],
        'active': json['active'] == null ? undefined : json['active'],
        'external_id': json['external_id'] == null ? undefined : json['external_id'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'booth_map_url': json['booth_map_url'] == null ? undefined : json['booth_map_url'],
        'features': json['features'] == null ? undefined : ((json['features'] as Array<any>).map(TradeShowFeatureRequestFromJSON)),
    };
}

export function UpdateTradeShowRequestToJSON(json: any): UpdateTradeShowRequest {
    return UpdateTradeShowRequestToJSONTyped(json, false);
}

export function UpdateTradeShowRequestToJSONTyped(value?: UpdateTradeShowRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'brand': value['brand'],
        'name': value['name'],
        'location': value['location'],
        'start_date': value['start_date'] == null ? undefined : ((value['start_date']).toISOString()),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString()),
        'commission_start': value['commission_start'] == null ? undefined : ((value['commission_start']).toISOString()),
        'commission_end': value['commission_end'] == null ? undefined : ((value['commission_end']).toISOString()),
        'zero_commission_start': value['zero_commission_start'] == null ? undefined : ((value['zero_commission_start']).toISOString()),
        'zero_commission_end': value['zero_commission_end'] == null ? undefined : ((value['zero_commission_end']).toISOString()),
        'registration_url': value['registration_url'],
        'active': value['active'],
        'external_id': value['external_id'],
        'slug': value['slug'],
        'booth_map_url': value['booth_map_url'],
        'features': value['features'] == null ? undefined : ((value['features'] as Array<any>).map(TradeShowFeatureRequestToJSON)),
    };
}

