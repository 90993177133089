/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReorderResponse } from './ReorderResponse';
import {
    ReorderResponseFromJSON,
    ReorderResponseFromJSONTyped,
    ReorderResponseToJSON,
    ReorderResponseToJSONTyped,
} from './ReorderResponse';

/**
 * 
 * @export
 * @interface GetReorderResponse
 */
export interface GetReorderResponse {
    /**
     * 
     * @type {Array<ReorderResponse>}
     * @memberof GetReorderResponse
     */
    reorders?: Array<ReorderResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetReorderResponse
     */
    total?: number;
}

/**
 * Check if a given object implements the GetReorderResponse interface.
 */
export function instanceOfGetReorderResponse(value: object): value is GetReorderResponse {
    return true;
}

export function GetReorderResponseFromJSON(json: any): GetReorderResponse {
    return GetReorderResponseFromJSONTyped(json, false);
}

export function GetReorderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReorderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'reorders': json['reorders'] == null ? undefined : ((json['reorders'] as Array<any>).map(ReorderResponseFromJSON)),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function GetReorderResponseToJSON(json: any): GetReorderResponse {
    return GetReorderResponseToJSONTyped(json, false);
}

export function GetReorderResponseToJSONTyped(value?: GetReorderResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reorders': value['reorders'] == null ? undefined : ((value['reorders'] as Array<any>).map(ReorderResponseToJSON)),
        'total': value['total'],
    };
}

