import restApi from '../../http.js'
import session from '../session'

export default class AuthApi {
  signIn(request) {
    return restApi({
      method: 'POST',
      url: '/auth/sign-in',
      data: {
        email: request.email,
        password: request.password
      }
    })
  }

  resetPassword(request) {
    return restApi({
      method: 'POST',
      url: '/auth/reset-password',
      data: request
    })
  }

  forgotPassword(request) {
    return restApi({
      method: 'POST',
      url: '/auth/forgot-password',
      data: request
    })
  }

  getLoggedBrandInfo() {
    return restApi({
      method: 'GET',
      url: '/brands/my-id',
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }

  signUpAsBrand(request) {
    return restApi({
      method: 'POST',
      url: '/brands/apply-to-sell',
      data: request
    })
  }

  signUpAsRetailer(request) {
    return restApi({
      method: 'POST',
      url: '/retailers/apply-to-buy',
      data: request
    })
  }

  verifyRetailer(request) {
    return restApi({
      method: 'POST',
      url: '/auth/retailer/verify',
      data: request
    })
  }

  verifyBrand(request) {
    return restApi({
      method: 'POST',
      url: '/auth/brand/verify',
      data: request
    })
  }

  verifyNewEmail(request) {
    return restApi({
      method: 'POST',
      url: '/auth/change-email',
      data: request
    })
  }
  getZendDeskJWTToken(request) {
    return restApi({
      method: 'POST',
      url: '/auth/zendesk-sign-in',
      data: {
        email: request.email,
        password: request.password
      },
      headers: {
        Authorization: 'Bearer ' + session.getAccessToken()
      }
    })
  }
}
