/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RetailerDropdownInfo } from './RetailerDropdownInfo';
import {
    RetailerDropdownInfoFromJSON,
    RetailerDropdownInfoFromJSONTyped,
    RetailerDropdownInfoToJSON,
    RetailerDropdownInfoToJSONTyped,
} from './RetailerDropdownInfo';

/**
 * 
 * @export
 * @interface RetailerDropdownResponse
 */
export interface RetailerDropdownResponse {
    /**
     * 
     * @type {Array<RetailerDropdownInfo>}
     * @memberof RetailerDropdownResponse
     */
    retailers_dropdown?: Array<RetailerDropdownInfo>;
}

/**
 * Check if a given object implements the RetailerDropdownResponse interface.
 */
export function instanceOfRetailerDropdownResponse(value: object): value is RetailerDropdownResponse {
    return true;
}

export function RetailerDropdownResponseFromJSON(json: any): RetailerDropdownResponse {
    return RetailerDropdownResponseFromJSONTyped(json, false);
}

export function RetailerDropdownResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerDropdownResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'retailers_dropdown': json['retailers_dropdown'] == null ? undefined : ((json['retailers_dropdown'] as Array<any>).map(RetailerDropdownInfoFromJSON)),
    };
}

export function RetailerDropdownResponseToJSON(json: any): RetailerDropdownResponse {
    return RetailerDropdownResponseToJSONTyped(json, false);
}

export function RetailerDropdownResponseToJSONTyped(value?: RetailerDropdownResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'retailers_dropdown': value['retailers_dropdown'] == null ? undefined : ((value['retailers_dropdown'] as Array<any>).map(RetailerDropdownInfoToJSON)),
    };
}

