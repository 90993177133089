<template>
  <div>
    <filter-radio-button
      v-for="item in minimumOrderValues"
      :key="item.value"
      v-model="item.selected"
      :value="item.selected"
      :label="item.label"
      @click="() => handleChange(item)"
    />
  </div>
</template>

<script>
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import MiscApi from '@/services/api/misc'
import { FilterKeys } from '../../../../services/utils'

const MiscApiService = new MiscApi()

export default {
  mixins: [paramsHelper, filterHelper],
  data() {
    return {
      minimumOrderValues: []
    }
  },
  watch: {
    '$route.query.mov': function (query) {
      this.parseSelectedValue(query)
      this.mapSelectedItemEmitFilterSelection()
    }
  },
  async beforeMount() {
    await this.getMinimumOrderValues()
    this.parseSelectedValue(this.$route.query.mov)
    this.mapSelectedItemEmitFilterSelection()
  },
  methods: {
    getMinimumOrderValues,
    parseSelectedValue,
    handleChange,
    mapSelectedItemEmitFilterSelection
  }
}

async function getMinimumOrderValues() {
  try {
    const { data } = await MiscApiService.getMinimumOrderValues()
    const { minimum_order_values } = data
    this.minimumOrderValues = minimum_order_values?.map((value) => {
      return {
        value: value,
        label: value ? `$${value} or less` : 'No minimum'
      }
    })
  } catch (e) {
    this.minimumOrderValues = []
  }
}

function handleChange(minOrderValue) {
  this.clearSelectionOnSingleSelect(this.minimumOrderValues.filter((item) => item.value !== minOrderValue.value))
  minOrderValue.selected = !minOrderValue.selected

  this.updateFilterURLParams(FilterKeys.MOV, minOrderValue.selected ? minOrderValue.value : null)
  this.mapSelectedItemEmitFilterSelection()
  this.trackFilterUsedEvent('mov')
}

function mapSelectedItemEmitFilterSelection() {
  const items = this.minimumOrderValues.filter((item) => item.selected === true)
  this.emitFilterSelection({
    filterKey: FilterKeys.MOV,
    items
  })

  this.$forceUpdate()
}

function parseSelectedValue(selectedValue) {
  this.clearSelection(this.minimumOrderValues)
  const match = this.minimumOrderValues?.find((item) => item.value?.toString() === selectedValue?.toString())
  if (match) {
    match.selected = true
  }
  this.$forceUpdate()
}
</script>
