<template>
  <div
    class="filter-checkbox-wrapper"
    :class="{ disabled }"
    @click="
      $emit('click', $event)
      $emit('input', !value)
    "
  >
    <div class="filter-radio-indicator" :class="{ checked: value }">
      <div class="inside-circle" :class="{ checked: value }" />
    </div>
    <div class="checkbox-label font-sans">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'label', 'disabled']
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
.filter-checkbox-wrapper {
  margin: 0 5px 15px 5px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.filter-radio-indicator {
  border: 2px solid $color-grey-4;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color $defaultTransitionTime $defaultTransitionType;

  &.checked {
    border-color: $color-black;
  }
}

.inside-circle {
  position: absolute;
  height: 20px;
  width: 20px;
  background: $color-black;
  border-radius: 50%;
  opacity: 0;
  transition: opacity $defaultTransitionTime $defaultTransitionType;
  &.checked {
    opacity: 1;
  }
}

.checkbox-label {
  margin-left: 10px;
}
</style>
