import { trackEvent } from '@/services/tracking'
import { mapGetters } from 'vuex'

export const productDetailsHelper = {
  computed: {
    ...mapGetters({
      user: 'userState'
    })
  },
  data() {
    return {
      selectedVariant: null,
      product: null,
      totalPrice: 0,
      totalQuantity: 0,
      activeImage: null,
      activeImageIndex: 0,
      quantity: null,
      selectedOption: {},
      faveData: null,
      showVideo: false,
      selectedVariants: [],
      multipleQuantityMode: false,
      hasInStockVariant: false,
      cart: null,
      variantsWithInitialInventoryValues: [],
      hideVariantPicker: false
    }
  },
  methods: {
    addToCart,
    onChangeSelection,
    onPartialVariantSelect,
    isAddToCardEnabled,
    onAddMultipleVariantsToCart,
    onAddMultipleVariantsToCartAndContinue,
    addMultipleVariantsToCart,
    mapVariants,
    trackAddToCart,
    setActiveImage,
    setInStockBasedOnInventory,
    processInventoryState,
    fetchCart,
    reduceInventoryQuantityWithCartValue,
    fetchCartAndUpdateVariants
  }
}

function addToCart(keepAdding) {
  if (keepAdding) {
    this.onAddMultipleVariantsToCartAndContinue()
  } else {
    this.onAddMultipleVariantsToCart()
  }
  this.trackAddToCart()
}

function setActiveImage(variant) {
  const index = this.product?.images?.findIndex((image) => image.url === variant.image_url)
  this.activeImage = index ? this.product?.images[index] : null
  this.activeImageIndex = index || 0
}

function onPartialVariantSelect(selectedOptionsGroup) {
  const firstMatchVariant = this.product.variants?.find((variant) => {
    return (
      Object.keys(selectedOptionsGroup)?.filter(
        (groupKey) =>
          variant.characteristic_option[groupKey]?.toLowerCase() === selectedOptionsGroup[groupKey]?.toLowerCase()
      ).length === Object.keys(selectedOptionsGroup).length
    )
  })

  if (firstMatchVariant) {
    this.setActiveImage(firstMatchVariant)
  }
}

function onChangeSelection(selection) {
  this.selectedVariants = selection

  if (this.selectedVariants?.length) {
    this.totalPrice = this.selectedVariants
      .map((item) => item.variant.price * item.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue)
      .toFixed(2)

    this.totalQuantity = this.selectedVariants
      .map((item) => item.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue)

    this.setActiveImage(this.selectedVariants[0].variant)
  } else {
    this.totalPrice = 0
  }
}

function isAddToCardEnabled() {
  const isBrand = this.user?.role === 'BRAND'
  if (this.product.brand.ships_from_usa_or_canada === false) return false
  if (this.product.brand.buy_on_bulletin === false) return false
  return !isBrand && this.selectedVariants.length && this.totalQuantity >= (this.product.wholesale_minimum || 1)
}

function onAddMultipleVariantsToCart() {
  this.addMultipleVariantsToCart()
}

function onAddMultipleVariantsToCartAndContinue() {
  this.addMultipleVariantsToCart(true)
}

function addMultipleVariantsToCart(keepAdding) {
  const { category, parentCategory } = this.$route.query
  const obj = {
    product: this.product,
    variants: this.selectedVariants,
    entryPoint: this.$route.name,
    category_id: this.findProductCategoryIdByName(this.product, category, parentCategory),
    keepAdding
  }

  this.$eventBus.$emit('add-multiple-variants', obj)
  this.$refs.variantPicker.clearSelection()
}

function mapVariants(variants, product, cart) {
  const mappedVariants = variants.map((variant) => {
    this.variantsWithInitialInventoryValues.push({
      id: variant.id,
      inventory_quantity: variant.inventory_quantity
    })

    const { wholesale_price, characteristic_option } = variant
    return {
      ...variant,
      price: wholesale_price,
      name: Object.values(characteristic_option).join(' '),
      option: characteristic_option
    }
  })

  return this.processInventoryState(mappedVariants, product, cart)
}

function reduceInventoryQuantityWithCartValue(variant, cart) {
  const { inventory_quantity } = variant

  let reservedQuantity = 0
  cart?.sub_carts?.find((sub_cart) =>
    sub_cart?.cart_items?.find(({ variant_id, quantity }) => {
      if (variant_id === variant.id) {
        reservedQuantity = quantity
      }
    })
  )

  if (reservedQuantity) {
    const reducedQuantity = inventory_quantity - reservedQuantity
    return reducedQuantity > 0 ? reducedQuantity : 0
  }

  return inventory_quantity
}

function processInventoryState(variants, product, cart) {
  const { track_inventory } = product

  if (!track_inventory) {
    return variants
  }

  variants = variants.map((variant) => {
    return { ...variant, inventory_quantity: this.reduceInventoryQuantityWithCartValue(variant, cart) }
  })

  const maxPurchasableQuantity = variants.reduce((quantity, item) => {
    if (item.inventory_quantity >= product.wholesale_increment) {
      quantity = quantity + item.inventory_quantity
    }
    return quantity
  }, 0)

  return variants?.map((variant) => {
    const in_stock = this.setInStockBasedOnInventory(variant, product, maxPurchasableQuantity)
    return { ...variant, in_stock }
  })
}

function setInStockBasedOnInventory(variant, product, maxPurchasableQuantity) {
  const { inventory_quantity } = variant
  const { wholesale_minimum } = product

  if (maxPurchasableQuantity < wholesale_minimum) {
    return false
  }

  return inventory_quantity && inventory_quantity >= product.wholesale_increment
}

function trackAddToCart() {
  if (this.$route.name === 'favoriteBoards' || this.$route.name === 'BrandBoardsDetails') {
    trackEvent('Add product to Bulletin Board', {
      objectID: this.product.id,
      'Board Creator': this.$route.name === 'favoriteBoards' ? 'RETAILER' : 'BRAND'
    })
  }
}

async function fetchCart() {
  if (!this.user || (this.user?.role !== 'RETAILER' && this.user?.role !== 'ADMIN')) {
    return
  }

  try {
    this.cart = (await this.$utils.getCart())?.data
  } catch (error) {
    this.$handleError(this, error)
  }
}

async function fetchCartAndUpdateVariants() {
  await this.fetchCart()

  if (!this.product) {
    return
  }

  this.product.variants = this.processInventoryState(
    this.product.variants.map((variant) => {
      return {
        ...variant,
        inventory_quantity: this.variantsWithInitialInventoryValues.find((item) => item.id === variant.id)
          ?.inventory_quantity
      }
    }),
    this.product,
    this.cart
  )

  this.hideVariantPicker = true
  await this.$nextTick()
  this.hideVariantPicker = false
}
