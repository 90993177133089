<template>
  <button
    v-if="hasAccess()"
    class="bulletin-button"
    :data-test-id="getDataTestId()"
    :class="{
      'primary-button': variant === 'primary',
      'primary-white-button': variant === 'primary-white',
      'secondary-button': variant === 'secondary',
      'green-button': variant === 'green',
      'tertiary-button': variant === 'tertiary',
      'selected-action-chip': variant === 'selectedActionChip',
      'nynow-style-button': variant === 'nynow',
      'sm-button': size === 'sm',
      'xsm-button': size === 'xsm',
      'xxsm-button': size === 'xxsm',
      'no-min-width': noMinWidth,
      'full-width': fullWidth,
      'small-padding': smallPadding,
      loading: loading,
      disabled: disabled || loading
    }"
    @mouseover="mouseOver()"
    @click="handleClick($event)"
  >
    <div class="slot-wrapper">
      <span v-if="loading" class="button-loader mr-15">
        <loader-static :dark="variant === 'secondary'" />
      </span>
      <slot></slot>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import roleHelper from '../../mixin/roleHelper'

const BUTTON_VARIANTS = ['primary','primary-white-button','secondary', 'tertiary', 'green', 'selectedActionChip', 'nynow']
const BUTTON_SIZES = ['md', 'sm', 'xsm', 'xxsm']

export default defineComponent({
  mixins: [roleHelper],
  props: {
    variant: {
      type: String,
      default: 'secondary'
    },
    size: {
      type: String,
      default: 'md'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noMinWidth: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    smallPadding: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object]
    },
    location: {
      type: String
    },
    mouseOver: {
      type: Function,
      default: () => null
    }
  },
  methods: {
    handleClick: function (event) {
      if (this.to) {
        this.$router.push(this.to)
      } else if (this.location) {
        window.open(this.location, '_blank')
      } else {
        event.stopPropagation()
        event.preventDefault()
        this.$emit('click', event)
      }
    },
    getDataTestId: function () {
      const label = this.$slots?.default?.length ? this.$slots?.default[0].text : null
      let generatedName = ''
      if (label) {
        generatedName = label.trim()?.replace(/\s/g, '-')?.toLowerCase()
      }
      const baseDataTestId = `${this.variant}-button`
      return this.name ? `${this.name}-${baseDataTestId}` : `${generatedName}-${baseDataTestId}`
    }
  }
})
</script>

<style lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
@import '@/assets/sass/base/_typo.scss';

.bulletin-button {
  display: inline-flex;
  text-align: center;
  @include transition(all, 0.15s, ease);
  text-transform: capitalize;
  @extend %subhead-1;
  cursor: pointer;
  padding: 0 35px;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 19px;
  position: relative;
  min-width: 10.625rem;

  &.small-padding {
    padding-inline: 24px !important;
  }

  // Sizes
  &.sm-button {
    height: 31px;
    font-size: 13px;
    padding: 0 15px;
    min-width: unset;
  }

  &.xsm-button {
    height: 28px;
    padding: 7px 20px;
    font-weight: bold;
    font-size: 12px;
    min-width: unset;
  }

  &.xxsm-button {
    height: 22px;
    padding: 5px 7px;
    font-style: normal;
    font-size: 8px;
    line-height: 10px;
    min-width: unset;
  }

  // Variants
  &.primary-button {
    color: $color-white;
    background-color: $color-black;
    border: 1px solid $color-black;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $color-black;
        background-color: $color-white;
      }
    }
  }

  &.primary-white-button {
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-black;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $color-white;
        background-color: $color-black;
      }
    }
  }

  &.secondary-button {
    background-color: transparent;
    border: 1px solid $color-black !important;

    &:hover {
      background-color: $color-black;
      color: $color-white;
    }
  }
  &.nynow-style-button {
    margin: 0 8px;
    height: 100%;
    padding: 0 32px;
    border-radius: 100px;
    background-color: $color-black;
    border: 1px solid $color-black !important;
    font-family: 'Futura', serif;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -0.8px;

    text-transform: uppercase;

    color: $color-white;

    @include respond-to('small') {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: $color-black;
      color: $color-white;
    }
  }

  &.selected-action-chip {
    background-color: transparent;
    border: 1px solid $color-grey-3 !important;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $color-black;
        border-color: $color-black !important;
        color: $color-white;
      }
    }
  }

  &.green-button {
    border: 1px solid $color-green !important;
    background-color: $color-green;
    &:hover {
      background-color: mix($color-black, $color-green, 5%);
      border-color: mix($color-black, $color-green, 5%);
      color: $color-black;
    }
  }

  &.tertiary-button {
    background-color: transparent;
    border: 1px solid transparent !important;

    &:hover {
      background-color: $color-black;
      color: $color-white;
    }
  }

  &.selected-action-chip {
    background-color: transparent;
    border: 1px solid $color-grey-3 !important;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $color-black;
        border-color: $color-black !important;
        color: $color-white;
      }
    }
  }

  // custom widths
  &.full-width {
    width: 100% !important;
  }
  &.half-width {
    width: calc(50% - 15px);
  }
  &.third-width {
    width: calc(33.33% - 15px);
  }
  &.width-260 {
    width: 260px !important;
  }
  &.width-280 {
    width: 280px !important;
  }

  &.no-min-width {
    min-width: 0 !important;

    &:not(.small-padding) {
      padding-inline: 10px !important;
    }

    .slot-wrapper {
      .button-loader {
        left: -22px;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.loading {

    &.sm-button {
      .slot-wrapper {
        .button-loader {
          margin-right: 0 !important;
          left: -13px;
          top: 2px;
          .loader {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .slot-wrapper {
    position: relative;
    .button-loader {
      position: absolute;
      left: -30px;
      top: 0;
    }
  }
}
</style>
