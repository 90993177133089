/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplyToSellRequest,
  ApplyToSellResponse,
  BrandActivePromoCodesResponse,
  BrandCategoriesForDropdownResponse,
  BrandInfoResponse,
  BrandReferralResponse,
  BrandsDropdownResponse,
  CreateBrandRequest,
  CreateOutreachBrandRequest,
  DropdownItem,
  FavoriteBrandCountResponse,
  FavoriteBrandResponse,
  GetBrandDetailsResponse,
  GetBrandResponse,
  GetStockistsResponse,
  IdWrapper,
  MergeExhibitorBrandRequest,
  PaginatedBrandsApplicationsResponse,
  PaginatedBrandsDirectoryResponse,
  PublicBrandResponse,
  ResendBrandWelcomeEmailRequest,
  RetailerDropdownResponse,
  SubmitOutboundOnboardingFormRequest,
  SubmitOutboundOnboardingFormResponse,
  ToggleBrandsBulletinPickRequest,
  UpdateBrandRequest,
  UploadComponentImageRequest,
} from '../models/index';
import {
    ApplyToSellRequestFromJSON,
    ApplyToSellRequestToJSON,
    ApplyToSellResponseFromJSON,
    ApplyToSellResponseToJSON,
    BrandActivePromoCodesResponseFromJSON,
    BrandActivePromoCodesResponseToJSON,
    BrandCategoriesForDropdownResponseFromJSON,
    BrandCategoriesForDropdownResponseToJSON,
    BrandInfoResponseFromJSON,
    BrandInfoResponseToJSON,
    BrandReferralResponseFromJSON,
    BrandReferralResponseToJSON,
    BrandsDropdownResponseFromJSON,
    BrandsDropdownResponseToJSON,
    CreateBrandRequestFromJSON,
    CreateBrandRequestToJSON,
    CreateOutreachBrandRequestFromJSON,
    CreateOutreachBrandRequestToJSON,
    DropdownItemFromJSON,
    DropdownItemToJSON,
    FavoriteBrandCountResponseFromJSON,
    FavoriteBrandCountResponseToJSON,
    FavoriteBrandResponseFromJSON,
    FavoriteBrandResponseToJSON,
    GetBrandDetailsResponseFromJSON,
    GetBrandDetailsResponseToJSON,
    GetBrandResponseFromJSON,
    GetBrandResponseToJSON,
    GetStockistsResponseFromJSON,
    GetStockistsResponseToJSON,
    IdWrapperFromJSON,
    IdWrapperToJSON,
    MergeExhibitorBrandRequestFromJSON,
    MergeExhibitorBrandRequestToJSON,
    PaginatedBrandsApplicationsResponseFromJSON,
    PaginatedBrandsApplicationsResponseToJSON,
    PaginatedBrandsDirectoryResponseFromJSON,
    PaginatedBrandsDirectoryResponseToJSON,
    PublicBrandResponseFromJSON,
    PublicBrandResponseToJSON,
    ResendBrandWelcomeEmailRequestFromJSON,
    ResendBrandWelcomeEmailRequestToJSON,
    RetailerDropdownResponseFromJSON,
    RetailerDropdownResponseToJSON,
    SubmitOutboundOnboardingFormRequestFromJSON,
    SubmitOutboundOnboardingFormRequestToJSON,
    SubmitOutboundOnboardingFormResponseFromJSON,
    SubmitOutboundOnboardingFormResponseToJSON,
    ToggleBrandsBulletinPickRequestFromJSON,
    ToggleBrandsBulletinPickRequestToJSON,
    UpdateBrandRequestFromJSON,
    UpdateBrandRequestToJSON,
    UploadComponentImageRequestFromJSON,
    UploadComponentImageRequestToJSON,
} from '../models/index';

export interface ApplyToSellOperationRequest {
    applyToSellRequest: ApplyToSellRequest;
}

export interface ApproveRequest {
    id: string;
}

export interface BrandBioRequest {
    vanityUrlName: string;
}

export interface CreateBrandOperationRequest {
    createBrandRequest: CreateBrandRequest;
}

export interface CreateOutreachBrandOperationRequest {
    createOutreachBrandRequest: CreateOutreachBrandRequest;
}

export interface CreateRoutableRequest {
    brandId: string;
}

export interface DeleteBrandRequest {
    id: string;
}

export interface DenyBrandApplicantRequest {
    id: string;
}

export interface FavoritizeBrandRequest {
    id: string;
}

export interface GetBrandRequest {
    id: string;
}

export interface GetBrandByReferralCodeRequest {
    referralCode: string;
    referralId: string;
}

export interface GetBrandCategoriesRequest {
    id: string;
}

export interface GetBrandDetailsRequest {
    id: string;
}

export interface GetBrandsApplicationsRequest {
    searchBy?: string;
    source?: string;
    status?: string;
    page?: number;
    size?: number;
}

export interface GetBrandsDirectoryRequest {
    searchBy?: string;
    source?: string;
    bulletinPick?: boolean;
    page?: number;
    size?: number;
}

export interface GetBrandsDropdownRequest {
    ifNoneMatch?: string;
    isPublished?: boolean;
    excludeShopify?: boolean;
}

export interface GetBrandsFromComponentForDropdownRequest {
    componentId: string;
}

export interface GetBrandsFromContainerForDropdownRequest {
    containerId: string;
}

export interface GetPublicBrandRequest {
    id: string;
}

export interface GetRetailersDropdown1Request {
    id: string;
}

export interface GetRetailersStockistsRequest {
    id: string;
}

export interface MergeExhibitorBrandOperationRequest {
    mergeExhibitorBrandRequest: MergeExhibitorBrandRequest;
}

export interface ResendWelcomeEmailRequest {
    id: string;
    resendBrandWelcomeEmailRequest: ResendBrandWelcomeEmailRequest;
}

export interface SubmitOutboundOnboardingFormOperationRequest {
    submitOutboundOnboardingFormRequest: SubmitOutboundOnboardingFormRequest;
}

export interface ToggleBrandsBulletinPickOperationRequest {
    toggleBrandsBulletinPickRequest: ToggleBrandsBulletinPickRequest;
}

export interface ToggleBulletinPickRequest {
    brandId: string;
}

export interface ToggleBuyOnBulletinRequest {
    brandId: string;
}

export interface UpdateBrandOperationRequest {
    id: string;
    updateBrandRequest: UpdateBrandRequest;
}

export interface UpdateMeRequest {
    updateBrandRequest: UpdateBrandRequest;
}

export interface UploadBrandBioImageRequest {
    id: string;
    uploadComponentImageRequest?: UploadComponentImageRequest;
}

export interface UploadMyBrandBioImageRequest {
    uploadComponentImageRequest?: UploadComponentImageRequest;
}

export interface UploadMyThumbnailImageRequest {
    uploadComponentImageRequest?: UploadComponentImageRequest;
}

export interface UploadThumbnailImageRequest {
    id: string;
    uploadComponentImageRequest?: UploadComponentImageRequest;
}

/**
 * 
 */
export class BrandControllerApi extends runtime.BaseAPI {

    /**
     * Apply to Sell
     */
    async applyToSellRaw(requestParameters: ApplyToSellOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplyToSellResponse>> {
        if (requestParameters['applyToSellRequest'] == null) {
            throw new runtime.RequiredError(
                'applyToSellRequest',
                'Required parameter "applyToSellRequest" was null or undefined when calling applyToSell().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/apply-to-sell`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyToSellRequestToJSON(requestParameters['applyToSellRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplyToSellResponseFromJSON(jsonValue));
    }

    /**
     * Apply to Sell
     */
    async applyToSell(requestParameters: ApplyToSellOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplyToSellResponse> {
        const response = await this.applyToSellRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve brand
     */
    async approveRaw(requestParameters: ApproveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve brand
     */
    async approve(requestParameters: ApproveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveRaw(requestParameters, initOverrides);
    }

    /**
     * Returns brand info by his vanity url name
     */
    async brandBioRaw(requestParameters: BrandBioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicBrandResponse>> {
        if (requestParameters['vanityUrlName'] == null) {
            throw new runtime.RequiredError(
                'vanityUrlName',
                'Required parameter "vanityUrlName" was null or undefined when calling brandBio().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/brand-bio/{vanityUrlName}`.replace(`{${"vanityUrlName"}}`, encodeURIComponent(String(requestParameters['vanityUrlName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicBrandResponseFromJSON(jsonValue));
    }

    /**
     * Returns brand info by his vanity url name
     */
    async brandBio(requestParameters: BrandBioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicBrandResponse> {
        const response = await this.brandBioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create brand
     */
    async createBrandRaw(requestParameters: CreateBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'createBrandRequest',
                'Required parameter "createBrandRequest" was null or undefined when calling createBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBrandRequestToJSON(requestParameters['createBrandRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create brand
     */
    async createBrand(requestParameters: CreateBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create outreach brand
     */
    async createOutreachBrandRaw(requestParameters: CreateOutreachBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdWrapper>> {
        if (requestParameters['createOutreachBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'createOutreachBrandRequest',
                'Required parameter "createOutreachBrandRequest" was null or undefined when calling createOutreachBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/outreach`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOutreachBrandRequestToJSON(requestParameters['createOutreachBrandRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdWrapperFromJSON(jsonValue));
    }

    /**
     * Create outreach brand
     */
    async createOutreachBrand(requestParameters: CreateOutreachBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdWrapper> {
        const response = await this.createOutreachBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create brand profile in routable
     */
    async createRoutableRaw(requestParameters: CreateRoutableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling createRoutable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{brandId}/create-routable-profile`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create brand profile in routable
     */
    async createRoutable(requestParameters: CreateRoutableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRoutableRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Brand
     */
    async deleteBrandRaw(requestParameters: DeleteBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Brand
     */
    async deleteBrand(requestParameters: DeleteBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Deny brand
     */
    async denyBrandApplicantRaw(requestParameters: DenyBrandApplicantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling denyBrandApplicant().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/deny`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deny brand
     */
    async denyBrandApplicant(requestParameters: DenyBrandApplicantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.denyBrandApplicantRaw(requestParameters, initOverrides);
    }

    /**
     * Favoritize brand
     */
    async favoritizeBrandRaw(requestParameters: FavoritizeBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling favoritizeBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/favoritize`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Favoritize brand
     */
    async favoritizeBrand(requestParameters: FavoritizeBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.favoritizeBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Generate routable to csv
     */
    async generateRoutableCSVRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/generate-routable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate routable to csv
     */
    async generateRoutableCSV(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.generateRoutableCSVRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async generateVanityUrlNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/generate-vanity-url-names`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async generateVanityUrlNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateVanityUrlNamesRaw(initOverrides);
    }

    /**
     * Get brand by Id
     */
    async getBrandRaw(requestParameters: GetBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandResponseFromJSON(jsonValue));
    }

    /**
     * Get brand by Id
     */
    async getBrand(requestParameters: GetBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandResponse> {
        const response = await this.getBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brand\'s active promo codes
     */
    async getBrandActivePromoCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandActivePromoCodesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/promo-codes/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandActivePromoCodesResponseFromJSON(jsonValue));
    }

    /**
     * Get brand\'s active promo codes
     */
    async getBrandActivePromoCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandActivePromoCodesResponse> {
        const response = await this.getBrandActivePromoCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find brand by referral code
     */
    async getBrandByReferralCodeRaw(requestParameters: GetBrandByReferralCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandReferralResponse>> {
        if (requestParameters['referralCode'] == null) {
            throw new runtime.RequiredError(
                'referralCode',
                'Required parameter "referralCode" was null or undefined when calling getBrandByReferralCode().'
            );
        }

        if (requestParameters['referralId'] == null) {
            throw new runtime.RequiredError(
                'referralId',
                'Required parameter "referralId" was null or undefined when calling getBrandByReferralCode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['referralCode'] != null) {
            queryParameters['referralCode'] = requestParameters['referralCode'];
        }

        if (requestParameters['referralId'] != null) {
            queryParameters['referralId'] = requestParameters['referralId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/brand-referral`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandReferralResponseFromJSON(jsonValue));
    }

    /**
     * Find brand by referral code
     */
    async getBrandByReferralCode(requestParameters: GetBrandByReferralCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandReferralResponse> {
        const response = await this.getBrandByReferralCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brand\'s categories
     */
    async getBrandCategoriesRaw(requestParameters: GetBrandCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandCategoriesForDropdownResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getBrandCategories().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/categories`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandCategoriesForDropdownResponseFromJSON(jsonValue));
    }

    /**
     * Get brand\'s categories
     */
    async getBrandCategories(requestParameters: GetBrandCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandCategoriesForDropdownResponse> {
        const response = await this.getBrandCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brand details
     */
    async getBrandDetailsRaw(requestParameters: GetBrandDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandDetailsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getBrandDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Get brand details
     */
    async getBrandDetails(requestParameters: GetBrandDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandDetailsResponse> {
        const response = await this.getBrandDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all brand values
     */
    async getBrandValuesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DropdownItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/values/dropdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DropdownItemFromJSON));
    }

    /**
     * Returns all brand values
     */
    async getBrandValues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DropdownItem>> {
        const response = await this.getBrandValuesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find all Brand applicants per multiple/custom criteria
     */
    async getBrandsApplicationsRaw(requestParameters: GetBrandsApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedBrandsApplicationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['source'] != null) {
            queryParameters['source'] = requestParameters['source'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedBrandsApplicationsResponseFromJSON(jsonValue));
    }

    /**
     * Find all Brand applicants per multiple/custom criteria
     */
    async getBrandsApplications(requestParameters: GetBrandsApplicationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedBrandsApplicationsResponse> {
        const response = await this.getBrandsApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all Brand directory per multiple/custom criteria
     */
    async getBrandsDirectoryRaw(requestParameters: GetBrandsDirectoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedBrandsDirectoryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['searchBy'] != null) {
            queryParameters['searchBy'] = requestParameters['searchBy'];
        }

        if (requestParameters['source'] != null) {
            queryParameters['source'] = requestParameters['source'];
        }

        if (requestParameters['bulletinPick'] != null) {
            queryParameters['bulletinPick'] = requestParameters['bulletinPick'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/directory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedBrandsDirectoryResponseFromJSON(jsonValue));
    }

    /**
     * Find all Brand directory per multiple/custom criteria
     */
    async getBrandsDirectory(requestParameters: GetBrandsDirectoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedBrandsDirectoryResponse> {
        const response = await this.getBrandsDirectoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brands for dropdown
     */
    async getBrandsDropdownRaw(requestParameters: GetBrandsDropdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        if (requestParameters['isPublished'] != null) {
            queryParameters['is_published'] = requestParameters['isPublished'];
        }

        if (requestParameters['excludeShopify'] != null) {
            queryParameters['exclude_shopify'] = requestParameters['excludeShopify'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['ifNoneMatch'] != null) {
            headerParameters['If-None-Match'] = String(requestParameters['ifNoneMatch']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/dropdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get brands for dropdown
     */
    async getBrandsDropdown(requestParameters: GetBrandsDropdownRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getBrandsDropdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brands from component for dropdown
     */
    async getBrandsFromComponentForDropdownRaw(requestParameters: GetBrandsFromComponentForDropdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandsDropdownResponse>> {
        if (requestParameters['componentId'] == null) {
            throw new runtime.RequiredError(
                'componentId',
                'Required parameter "componentId" was null or undefined when calling getBrandsFromComponentForDropdown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/component/{componentId}/dropdown`.replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters['componentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandsDropdownResponseFromJSON(jsonValue));
    }

    /**
     * Get brands from component for dropdown
     */
    async getBrandsFromComponentForDropdown(requestParameters: GetBrandsFromComponentForDropdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandsDropdownResponse> {
        const response = await this.getBrandsFromComponentForDropdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get brands from container for dropdown
     */
    async getBrandsFromContainerForDropdownRaw(requestParameters: GetBrandsFromContainerForDropdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandsDropdownResponse>> {
        if (requestParameters['containerId'] == null) {
            throw new runtime.RequiredError(
                'containerId',
                'Required parameter "containerId" was null or undefined when calling getBrandsFromContainerForDropdown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/container/{containerId}/dropdown`.replace(`{${"containerId"}}`, encodeURIComponent(String(requestParameters['containerId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandsDropdownResponseFromJSON(jsonValue));
    }

    /**
     * Get brands from container for dropdown
     */
    async getBrandsFromContainerForDropdown(requestParameters: GetBrandsFromContainerForDropdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandsDropdownResponse> {
        const response = await this.getBrandsFromContainerForDropdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all brand deals and promotions
     */
    async getDealsAndPromotionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DropdownItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/deals-and-promotions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DropdownItemFromJSON));
    }

    /**
     * Returns all brand deals and promotions
     */
    async getDealsAndPromotions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DropdownItem>> {
        const response = await this.getDealsAndPromotionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find retailer favorite brands
     */
    async getFavoriteBrandsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavoriteBrandResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/favorite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteBrandResponseFromJSON(jsonValue));
    }

    /**
     * Find retailer favorite brands
     */
    async getFavoriteBrands(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavoriteBrandResponse> {
        const response = await this.getFavoriteBrandsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find retailer\'s last five favorite brands
     */
    async getFilteredFavoriteBrandsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavoriteBrandResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/favorite-brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteBrandResponseFromJSON(jsonValue));
    }

    /**
     * Find retailer\'s last five favorite brands
     */
    async getFilteredFavoriteBrands(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavoriteBrandResponse> {
        const response = await this.getFilteredFavoriteBrandsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get brand
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandResponseFromJSON(jsonValue));
    }

    /**
     * Get brand
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandResponse> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Find brand by user Id
     */
    async getMyId1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/my-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandInfoResponseFromJSON(jsonValue));
    }

    /**
     * Find brand by user Id
     */
    async getMyId1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandInfoResponse> {
        const response = await this.getMyId1Raw(initOverrides);
        return await response.value();
    }

    /**
     * Get number of favorite brands
     */
    async getNumberOfFavoriteBrandsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavoriteBrandCountResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/number-of-favorite-brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteBrandCountResponseFromJSON(jsonValue));
    }

    /**
     * Get number of favorite brands
     */
    async getNumberOfFavoriteBrands(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavoriteBrandCountResponse> {
        const response = await this.getNumberOfFavoriteBrandsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get public brand
     */
    async getPublicBrandRaw(requestParameters: GetPublicBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicBrandResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPublicBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/public`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicBrandResponseFromJSON(jsonValue));
    }

    /**
     * Get public brand
     */
    async getPublicBrand(requestParameters: GetPublicBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicBrandResponse> {
        const response = await this.getPublicBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all retailers related to the brand
     */
    async getRetailersDropdown1Raw(requestParameters: GetRetailersDropdown1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetailerDropdownResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRetailersDropdown1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/retailers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetailerDropdownResponseFromJSON(jsonValue));
    }

    /**
     * Get all retailers related to the brand
     */
    async getRetailersDropdown1(requestParameters: GetRetailersDropdown1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetailerDropdownResponse> {
        const response = await this.getRetailersDropdown1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get active retailers stockists for the brand
     */
    async getRetailersStockistsRaw(requestParameters: GetRetailersStockistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetStockistsResponse>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRetailersStockists().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/stockists`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetStockistsResponseFromJSON));
    }

    /**
     * Get active retailers stockists for the brand
     */
    async getRetailersStockists(requestParameters: GetRetailersStockistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetStockistsResponse>> {
        const response = await this.getRetailersStockistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of possible values for special services
     */
    async getSpecialServicesValuesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DropdownItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/special-services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DropdownItemFromJSON));
    }

    /**
     * Get a list of possible values for special services
     */
    async getSpecialServicesValues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DropdownItem>> {
        const response = await this.getSpecialServicesValuesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Merge EXHIBITOR Brand into existing Brand
     */
    async mergeExhibitorBrandRaw(requestParameters: MergeExhibitorBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mergeExhibitorBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'mergeExhibitorBrandRequest',
                'Required parameter "mergeExhibitorBrandRequest" was null or undefined when calling mergeExhibitorBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/merge-exhibitor-brand`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MergeExhibitorBrandRequestToJSON(requestParameters['mergeExhibitorBrandRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Merge EXHIBITOR Brand into existing Brand
     */
    async mergeExhibitorBrand(requestParameters: MergeExhibitorBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.mergeExhibitorBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Resends verification email to given brand
     */
    async resendVerificationMail1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/resend-verification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resends verification email to given brand
     */
    async resendVerificationMail1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendVerificationMail1Raw(initOverrides);
    }

    /**
     * Resend appropriate welcome email to a brand, depending on the brand source and state.
     */
    async resendWelcomeEmailRaw(requestParameters: ResendWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling resendWelcomeEmail().'
            );
        }

        if (requestParameters['resendBrandWelcomeEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'resendBrandWelcomeEmailRequest',
                'Required parameter "resendBrandWelcomeEmailRequest" was null or undefined when calling resendWelcomeEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/resend-welcome-email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendBrandWelcomeEmailRequestToJSON(requestParameters['resendBrandWelcomeEmailRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend appropriate welcome email to a brand, depending on the brand source and state.
     */
    async resendWelcomeEmail(requestParameters: ResendWelcomeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendWelcomeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Submit outbound onboarding form
     */
    async submitOutboundOnboardingFormRaw(requestParameters: SubmitOutboundOnboardingFormOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubmitOutboundOnboardingFormResponse>> {
        if (requestParameters['submitOutboundOnboardingFormRequest'] == null) {
            throw new runtime.RequiredError(
                'submitOutboundOnboardingFormRequest',
                'Required parameter "submitOutboundOnboardingFormRequest" was null or undefined when calling submitOutboundOnboardingForm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/outbound-onboarding-form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitOutboundOnboardingFormRequestToJSON(requestParameters['submitOutboundOnboardingFormRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmitOutboundOnboardingFormResponseFromJSON(jsonValue));
    }

    /**
     * Submit outbound onboarding form
     */
    async submitOutboundOnboardingForm(requestParameters: SubmitOutboundOnboardingFormOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubmitOutboundOnboardingFormResponse> {
        const response = await this.submitOutboundOnboardingFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle brands bulletin pick flag
     */
    async toggleBrandsBulletinPickRaw(requestParameters: ToggleBrandsBulletinPickOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['toggleBrandsBulletinPickRequest'] == null) {
            throw new runtime.RequiredError(
                'toggleBrandsBulletinPickRequest',
                'Required parameter "toggleBrandsBulletinPickRequest" was null or undefined when calling toggleBrandsBulletinPick().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/toggle-bulletin-pick`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ToggleBrandsBulletinPickRequestToJSON(requestParameters['toggleBrandsBulletinPickRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggle brands bulletin pick flag
     */
    async toggleBrandsBulletinPick(requestParameters: ToggleBrandsBulletinPickOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleBrandsBulletinPickRaw(requestParameters, initOverrides);
    }

    /**
     * Toggle brand bulletin pick flag
     */
    async toggleBulletinPickRaw(requestParameters: ToggleBulletinPickRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling toggleBulletinPick().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{brandId}/toggle-bulletin-pick`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggle brand bulletin pick flag
     */
    async toggleBulletinPick(requestParameters: ToggleBulletinPickRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleBulletinPickRaw(requestParameters, initOverrides);
    }

    /**
     * Toggle brand buy on bulletin flag
     */
    async toggleBuyOnBulletinRaw(requestParameters: ToggleBuyOnBulletinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling toggleBuyOnBulletin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{brandId}/toggle-buy-on-bulletin`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggle brand buy on bulletin flag
     */
    async toggleBuyOnBulletin(requestParameters: ToggleBuyOnBulletinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleBuyOnBulletinRaw(requestParameters, initOverrides);
    }

    /**
     * Update brand
     */
    async updateBrandRaw(requestParameters: UpdateBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateBrand().'
            );
        }

        if (requestParameters['updateBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'updateBrandRequest',
                'Required parameter "updateBrandRequest" was null or undefined when calling updateBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandRequestToJSON(requestParameters['updateBrandRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update brand
     */
    async updateBrand(requestParameters: UpdateBrandOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBrandRaw(requestParameters, initOverrides);
    }

    /**
     * Update brand by user Id
     */
    async updateMeRaw(requestParameters: UpdateMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateBrandRequest'] == null) {
            throw new runtime.RequiredError(
                'updateBrandRequest',
                'Required parameter "updateBrandRequest" was null or undefined when calling updateMe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/me`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandRequestToJSON(requestParameters['updateBrandRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update brand by user Id
     */
    async updateMe(requestParameters: UpdateMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMeRaw(requestParameters, initOverrides);
    }

    /**
     * Upload Brand Bio image
     */
    async uploadBrandBioImageRaw(requestParameters: UploadBrandBioImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadBrandBioImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadComponentImageRequestToJSON(requestParameters['uploadComponentImageRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload Brand Bio image
     */
    async uploadBrandBioImage(requestParameters: UploadBrandBioImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadBrandBioImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload user Brand Bio image
     */
    async uploadMyBrandBioImageRaw(requestParameters: UploadMyBrandBioImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/my-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadComponentImageRequestToJSON(requestParameters['uploadComponentImageRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload user Brand Bio image
     */
    async uploadMyBrandBioImage(requestParameters: UploadMyBrandBioImageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadMyBrandBioImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload user thumbnail image
     */
    async uploadMyThumbnailImageRaw(requestParameters: UploadMyThumbnailImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/my-thumbnail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadComponentImageRequestToJSON(requestParameters['uploadComponentImageRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload user thumbnail image
     */
    async uploadMyThumbnailImage(requestParameters: UploadMyThumbnailImageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadMyThumbnailImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload thumbnail image
     */
    async uploadThumbnailImageRaw(requestParameters: UploadThumbnailImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadThumbnailImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/thumbnail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadComponentImageRequestToJSON(requestParameters['uploadComponentImageRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload thumbnail image
     */
    async uploadThumbnailImage(requestParameters: UploadThumbnailImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadThumbnailImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
