/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create Referral object
 * @export
 * @interface CreateReferralsRequest
 */
export interface CreateReferralsRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateReferralsRequest
     */
    mail_info: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateReferralsRequest
     */
    emails?: Array<string>;
}

/**
 * Check if a given object implements the CreateReferralsRequest interface.
 */
export function instanceOfCreateReferralsRequest(value: object): value is CreateReferralsRequest {
    if (!('mail_info' in value) || value['mail_info'] === undefined) return false;
    return true;
}

export function CreateReferralsRequestFromJSON(json: any): CreateReferralsRequest {
    return CreateReferralsRequestFromJSONTyped(json, false);
}

export function CreateReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReferralsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'mail_info': json['mail_info'],
        'emails': json['emails'] == null ? undefined : json['emails'],
    };
}

export function CreateReferralsRequestToJSON(json: any): CreateReferralsRequest {
    return CreateReferralsRequestToJSONTyped(json, false);
}

export function CreateReferralsRequestToJSONTyped(value?: CreateReferralsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'mail_info': value['mail_info'],
        'emails': value['emails'],
    };
}

