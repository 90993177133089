<template>
  <div class="grid-12">
    <div
      :class="{ 'show hide-sm hide-xs': !(savedSearches && savedSearches.length) }"
      class="span-3 span-12-sm mt-25-sm"
    >
      <h4 class="mb-15">recent</h4>
      <ul v-if="savedSearches && savedSearches.length">
        <li v-for="(search, index) in savedSearches" :key="index" class="search-result mb-8">
          <span
            class="pointer tag b1 inline-flex align-center justify-center"
            @click="
              handleSearchClick(search)
              $emit('close')
            "
          >
            <span>{{ search }}</span>
            <Icon icon-key="chevron-right" hover-type="OPACITY" :size="24" />
          </span>

          <span
            class="close-button ml-15 mb-5 text-decoration-underline-grey"
            @click="$emit('remove-search-item', search)"
          >
            Remove
          </span>
        </li>
      </ul>
      <div v-else class="b1 text-light">No recent searches</div>
    </div>
    <div class="span-9 mb-15 span-12-sm mt-25-sm">
      <h4 class="mb-15">best sellers</h4>
      <div class="grid-12 span-12-sm mb-15">
        <router-link
          v-for="(bestSeller, index) in bestSellers"
          :key="index"
          :to="{
            name: 'brand-bio',
            params: {
              brandUrl: bestSeller.vanity_url_name
            }
          }"
          class="span-4 span-12-sm flex mb-25 mb-15-sm"
          @click.native="
            $emit('close')
            $trackEvent({
              action: 'Best Sellers in Search click',
              properties: {
                'brand name': bestSeller.name
              }
            })
          "
        >
          <img
            v-if="bestSeller.image_url"
            :src="$utils.getCDNURL({ w: 91, h: 91, src: bestSeller.image_url, q: 100 })"
            class="object-fit-cover border-black square-thumbnail"
          />
          <div class="flex align-center justify-center">
            <div class="pl-15">
              <div class="a2">
                {{ bestSeller.name }}
              </div>
              <div class="b2 secondary-grey">${{ bestSeller.mov }} minimum</div>
            </div>
          </div>
        </router-link>
      </div>
      <h4>Bulletin Edits</h4>
      <div class="grid-12 grid-column-gap-24 mt-15 mb-60">
        <router-link
          v-for="(collection, index) in collections"
          :key="index"
          class="span-4 span-12-sm mb-20-sm"
          :to="{
            name: 'Collection',
            params: {
              collectionId: collection.entity_id,
              collectionName: collection.homepage_title
            }
          }"
          @click.native="
            $emit('close')
            $trackEvent({
              action: 'Collections in Search Click',
              properties: {
                collection: collection.homepage_title
              }
            })
          "
        >
          <img
            :src="$utils.getCDNURL({ w: 460, src: collection.image_url })"
            class="width-100 img-entity bg-green"
            style="aspect-ratio: 322 / 91"
          />
          <div class="mt-15 mt-5-sm">
            {{ collection.homepage_title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import ContentManagementApi from '@/services/api/contentManagmentApi.js'
import { RouterPath } from '@/services/utils'
import localForage from 'localforage'

const { getTrendingBrands, getCollectionsCarousel } = new ContentManagementApi()
export default {
  props: ['savedSearches'],
  data() {
    return {
      bestSellers: null,
      collections: null
    }
  },
  async beforeMount() {
    this.bestSellers = (await getTrendingBrands())['data']
    this.bestSellers.length = 6
    this.collections = (await getCollectionsCarousel())['data']
    this.collections.length = 3
  },
  methods: {
    handleSearchClick(query) {
      this.$router.push(`/${RouterPath.DEFAULT_SEARCH_PRODUCT_PAGE}?keyword=${encodeURIComponent(query)}`)
      this.$trackEvent({
        action: 'Recent Searches',
        properties: {
          'saved search term': query
        }
      })
    },
    async handleDeleteSavedSearch(query) {
      await localForage.setItem(
        'SAVED_SEARCH',
        (await localForage.getItem('SAVED_SEARCH')).filter((i) => i !== query)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';

.close-button {
  display: none;
  font-family: NHaasGroteskDSPro-55Rg;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $color-grey-4;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.search-result:hover .close-button {
  display: inline-flex;
}

.tag {
  padding: 4px 8px 4px 16px;
  background: $color-white;
  border: 1px solid $color-grey-3;
  box-sizing: border-box;
  border-radius: 100px;
  display: inline-block;
}

.square-thumbnail {
  width: 91px;
  height: 91px;
  @include respond-to('xsmall') {
    width: 74px;
    height: 74px;
  }
}
</style>
