// this list needs to be extended whit the new validation
export const DEFAULT_ERROR_MESSAGES = {
  required: 'Please fill out',
  numeric: 'Please use numbers only'
}

// TODO this should be refactored later and it should not be responsibility of the text field component
const FORM_FIELD_WIDTH_OPTIONS = ['half', 'one-third', 'full']

export const formFieldHelpers = {
  inject: ['parentValidator'],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    noSpacing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validate: {
      type: [Object, String],
      default: undefined
    },
    errorMessages: {
      type: Object,
      default: undefined
    },
    noErrorMessage: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'half',
      validator: (val) => {
        return FORM_FIELD_WIDTH_OPTIONS.includes(val)
      }
    },
    column: {
      type: [Number, String],
      default: 1,
      validator: (val) => {
        return [1, 2, 3].includes(Number(val))
      }
    },
    focus: {
      type: Function,
      default: () => {}
    },
    blur: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    getErrorMessageByRule(rule) {
      if (this.errorMessages && this.errorMessages[rule]) {
        return this.errorMessages[rule]
      }

      if (DEFAULT_ERROR_MESSAGES[rule]) {
        return DEFAULT_ERROR_MESSAGES[rule]
      }

      return this.errors.items.find((item) => item.field === this.name)?.msg
    }
  }
}
