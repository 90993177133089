/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateViewComponentRequest } from './CreateViewComponentRequest';
import {
    CreateViewComponentRequestFromJSON,
    CreateViewComponentRequestFromJSONTyped,
    CreateViewComponentRequestToJSON,
    CreateViewComponentRequestToJSONTyped,
} from './CreateViewComponentRequest';

/**
 * 
 * @export
 * @interface CreateViewComponentsRequest
 */
export interface CreateViewComponentsRequest {
    /**
     * 
     * @type {Array<CreateViewComponentRequest>}
     * @memberof CreateViewComponentsRequest
     */
    view_components?: Array<CreateViewComponentRequest>;
}

/**
 * Check if a given object implements the CreateViewComponentsRequest interface.
 */
export function instanceOfCreateViewComponentsRequest(value: object): value is CreateViewComponentsRequest {
    return true;
}

export function CreateViewComponentsRequestFromJSON(json: any): CreateViewComponentsRequest {
    return CreateViewComponentsRequestFromJSONTyped(json, false);
}

export function CreateViewComponentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateViewComponentsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'view_components': json['view_components'] == null ? undefined : ((json['view_components'] as Array<any>).map(CreateViewComponentRequestFromJSON)),
    };
}

export function CreateViewComponentsRequestToJSON(json: any): CreateViewComponentsRequest {
    return CreateViewComponentsRequestToJSONTyped(json, false);
}

export function CreateViewComponentsRequestToJSONTyped(value?: CreateViewComponentsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'view_components': value['view_components'] == null ? undefined : ((value['view_components'] as Array<any>).map(CreateViewComponentRequestToJSON)),
    };
}

