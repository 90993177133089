/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetSuborderItemsResponse } from './GetSuborderItemsResponse';
import {
    GetSuborderItemsResponseFromJSON,
    GetSuborderItemsResponseFromJSONTyped,
    GetSuborderItemsResponseToJSON,
    GetSuborderItemsResponseToJSONTyped,
} from './GetSuborderItemsResponse';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface GetPaymentResponse
 */
export interface GetPaymentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    number?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetPaymentResponse
     */
    shipped_on?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentResponse
     */
    retailer_name?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof GetPaymentResponse
     */
    retailer_address?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentResponse
     */
    retailer_id?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof GetPaymentResponse
     */
    bulletin_address?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentResponse
     */
    promo_code?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    promo_code_discount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    shipping_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    commission?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    commission_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    total_suborder_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentResponse
     */
    total_payout_price?: number;
    /**
     * 
     * @type {Array<GetSuborderItemsResponse>}
     * @memberof GetPaymentResponse
     */
    items?: Array<GetSuborderItemsResponse>;
}

/**
 * Check if a given object implements the GetPaymentResponse interface.
 */
export function instanceOfGetPaymentResponse(value: object): value is GetPaymentResponse {
    return true;
}

export function GetPaymentResponseFromJSON(json: any): GetPaymentResponse {
    return GetPaymentResponseFromJSONTyped(json, false);
}

export function GetPaymentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'shipped_on': json['shipped_on'] == null ? undefined : (new Date(json['shipped_on'])),
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'retailer_name': json['retailer_name'] == null ? undefined : json['retailer_name'],
        'retailer_address': json['retailer_address'] == null ? undefined : AddressResponseFromJSON(json['retailer_address']),
        'retailer_id': json['retailer_id'] == null ? undefined : json['retailer_id'],
        'bulletin_address': json['bulletin_address'] == null ? undefined : AddressResponseFromJSON(json['bulletin_address']),
        'promo_code': json['promo_code'] == null ? undefined : json['promo_code'],
        'promo_code_discount': json['promo_code_discount'] == null ? undefined : json['promo_code_discount'],
        'shipping_cost': json['shipping_cost'] == null ? undefined : json['shipping_cost'],
        'commission': json['commission'] == null ? undefined : json['commission'],
        'commission_percent': json['commission_percent'] == null ? undefined : json['commission_percent'],
        'total_suborder_price': json['total_suborder_price'] == null ? undefined : json['total_suborder_price'],
        'total_payout_price': json['total_payout_price'] == null ? undefined : json['total_payout_price'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(GetSuborderItemsResponseFromJSON)),
    };
}

export function GetPaymentResponseToJSON(json: any): GetPaymentResponse {
    return GetPaymentResponseToJSONTyped(json, false);
}

export function GetPaymentResponseToJSONTyped(value?: GetPaymentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'shipped_on': value['shipped_on'] == null ? undefined : ((value['shipped_on']).toISOString().substring(0,10)),
        'brand_name': value['brand_name'],
        'retailer_name': value['retailer_name'],
        'retailer_address': AddressResponseToJSON(value['retailer_address']),
        'retailer_id': value['retailer_id'],
        'bulletin_address': AddressResponseToJSON(value['bulletin_address']),
        'promo_code': value['promo_code'],
        'promo_code_discount': value['promo_code_discount'],
        'shipping_cost': value['shipping_cost'],
        'commission': value['commission'],
        'commission_percent': value['commission_percent'],
        'total_suborder_price': value['total_suborder_price'],
        'total_payout_price': value['total_payout_price'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(GetSuborderItemsResponseToJSON)),
    };
}

