<template>
  <div class="half-column-layout">
    <address-autocomplete
      ref="addressAutoComplete"
      v-model="formData.street"
      :address-selected="onAddressSelected"
      :validate="'required'"
      :label="withLabels ? 'Address' : ''"
      with-address-form
      width="full"
      :name="`${fieldsNamePrefix}street`"
      :placeholder="addressPlaceholder"
    />

    <text-field
      v-model="formData.apartment"
      :name="`${fieldsNamePrefix}apartment`"
      placeholder="Unit Number, Loading Dock #, etc"
      column="2"
      show-optional
      width="full"
      :label="withLabels ? 'Unit Number, Loading Dock #, etc' : ''"
    />
    <text-field
      v-model="formData.city"
      :validate="'required'"
      :name="`${fieldsNamePrefix}city`"
      placeholder="City"
      :label="withLabels ? 'City' : ''"
      width="full"
    />
    <template v-if="isInternational">
      <div>
        <text-field
          v-model="formData.state"
          :label="withLabels ? 'State' : ''"
          :name="`${fieldsNamePrefix}state`"
          :validate="'required'"
          :width="'full'"
          column="2"
          placeholder="State"
        />
      </div>
      <text-field
        v-model="formData.zip_code"
        :label="withLabels ? 'Zip Code' : ''"
        :name="`${fieldsNamePrefix}zip_code`"
        :validate="'required'"
        placeholder="Zip Code"
        width="full"
      />
    </template>
    <template v-else>
      <div v-if="stateDropdown">
        <FloatingLabel :value="selectedStateName" label="State" />
        <dropdown
          v-if="hasStateData"
          :class="{ invalid: $validator.errors.has(`${fieldsNamePrefix}state`) }"
          :dropdown-items="stateList"
          :is-required="true"
          :name="`${fieldsNamePrefix}state`"
          :on-select="selectState"
          :placeholder="'State'"
          :selected-name="selectedStateName"
          class="pt-10 address-dropdown"
          optionaly-available
          width="full"
        />
        <text-field
          v-else
          v-model="formData.state"
          :label="withLabels ? 'State' : ''"
          :name="`${fieldsNamePrefix}state`"
          :validate="'required'"
          column="2"
          placeholder="State"
        />
      </div>
      <text-field
        v-model="formData.zip_code"
        :label="withLabels ? 'Zip Code' : ''"
        :name="`${fieldsNamePrefix}zip_code`"
        :validate="'required'"
        placeholder="Zip Code"
        width="full"
      />
    </template>

    <div v-if="!isInternational">
      <FloatingLabel :value="formData.country" label="Country" />
      <div v-if="countryDropdown">
        <dropdown
          :selected-name="formData.country"
          :class="{ invalid: $validator.errors.has(`${fieldsNamePrefix}country`) }"
          :placeholder="'Country/Region'"
          class="pt-10 address-dropdown"
          :dropdown-items="countries"
          :on-select="selectCountry"
          :name="`${fieldsNamePrefix}country`"
          :is-required="true"
        />
      </div>
      <text-field
        v-else
        v-model="formData.country"
        :validate="'required'"
        :name="`${fieldsNamePrefix}country`"
        column="2"
        placeholder="Country/Region"
        :label="withLabels ? 'Country/Region' : ''"
      />
    </div>
    <div v-else>
      <text-field
        v-model="formData.country"
        :label="withLabels ? 'Country/Region' : ''"
        :name="`${fieldsNamePrefix}country`"
        :validate="'required'"
        column="2"
        placeholder="Country/Region"
        width="full"
      />
    </div>
  </div>
</template>

<script>
import MiscApi from '@/services/api/misc'

const MiscApiService = new MiscApi()

export default {
  inject: ['parentValidator'],
  props: {
    addressChanged: {
      type: Function,
      default: () => {}
    },
    withLabels: {
      type: Boolean,
      default: false
    },
    stateDropdown: {
      type: Boolean,
      default: false
    },
    countryDropdown: {
      type: Boolean,
      default: true
    },
    addressPlaceholder: {
      type: String,
      default: 'Address'
    },
    initialData: {
      type: Object
    },
    fieldsNamePrefix: {
      type: String,
      default: ''
    }
  },
  data: function () {
    const { street, zip_code, city, state, country, apartment } = this.initialData || {}
    return {
      formData: {
        street: street || '',
        zip_code: zip_code || '',
        city: city || '',
        state: state || '',
        country: country || '',
        apartment: apartment || ''
      },
      stateData: [],
      stateList: [],
      countries: [
        { name: 'United States', value: 'United States' },
        { name: 'Canada', value: 'Canada' }
      ]
    }
  },
  computed: {
    selectedStateName: function () {
      const countryIndex = this.formData.country === this.countries[0].name ? 0 : 1

      const found = this.stateData[countryIndex]?.states?.find(
        (item) => item.name === this.formData.state || item.code === this.formData.state
      )?.name

      if (!found) {
        return ''
      }

      const selectedStateName = this.stateData[0].states
        .concat(this.stateData[1].states)
        .filter((state) => this.formData.state === state.code || this.formData.state === state.name)
        .map((state) => state.name)

      return selectedStateName[0]
    },
    hasStateData: function () {
      return this.stateData.length !== 0
    },
    isInternational: function () {
      if (this.formData.country === '') {
        return false
      }
      return this.formData.country !== 'United States' && this.formData.country !== 'Canada'
    }
  },
  watch: {
    formData: {
      handler() {
        this.addressChanged(this.formData)
        if (this.stateDropdown) {
          this.setStateList(this.formData.country === 'Canada' ? 'CA' : 'US')
        }
      },
      deep: true
    }
  },
  async created() {
    this.$validator = this.parentValidator
    if (this.stateDropdown) {
      this.getLocations()
    }
  },
  methods: {
    onAddressSelected: async function (addressObject) {
      this.formData = addressObject
      this.$validator.errors.remove(`${this.fieldsNamePrefix}state`)
      this.$validator.errors.remove(`${this.fieldsNamePrefix}country`)
    },
    getLocations: async function () {
      this.stateData = await MiscApiService.getLocations()
      if (this.formData) {
        this.setStateList(this.formData.country === 'Canada' ? 'CA' : 'US')
      } else {
        this.setStateList('US')
      }
    },
    setStateList: function (country) {
      this.stateList = this.stateData[country === 'US' ? 0 : 1].states.map((state) => ({
        value: state.code,
        name: state.name,
        available: true
      }))
    },
    clearForm: function () {
      this.formData = {
        street: '',
        zip_code: '',
        city: '',
        state: '',
        country: '',
        apartment: ''
      }

      this.$refs.addressAutoComplete?.clear()
    },
    selectState: function (item) {
      this.formData.state = item.value
    },
    selectCountry: function (item) {
      this.formData.country = item.name
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';
.address-dropdown {
  margin-top: -3px;
  @include respond-to('small') {
    width: 100%;
    margin-bottom: 56px;
  }
}
</style>
