import restApi from '../../http.js'

export default class FormApi {
  submitHubspotEmail(data) {
    return restApi({
      method: 'POST',
      url: `https://api.hsforms.com/submissions/v3/integration/submit/6694100/aee4cdca-0666-412b-b1b1-469b8883e866`,
      data: data
    })
  }

  hubspotCreateUser(data) {
    return restApi({
      method: 'POST',
      url: `https://api.hsforms.com/submissions/v3/integration/submit/6694100/421f1b5e-63a6-411b-9f14-d0d5147ea901`,
      data: data
    })
  }
}
