/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrderReturnItemRequest
 */
export interface CreateOrderReturnItemRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnItemRequest
     */
    suborder_item_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderReturnItemRequest
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnItemRequest
     */
    reason?: CreateOrderReturnItemRequestReasonEnum;
}


/**
 * @export
 */
export const CreateOrderReturnItemRequestReasonEnum = {
    ItemsNotDescribed: 'ITEMS_NOT_DESCRIBED',
    ProductQualityIsLacking: 'PRODUCT_QUALITY_IS_LACKING',
    ProductIsNotGoodFit: 'PRODUCT_IS_NOT_GOOD_FIT',
    ProductNotSell: 'PRODUCT_NOT_SELL',
    PriceNotWork: 'PRICE_NOT_WORK',
    ProductNotMeetExpectations: 'PRODUCT_NOT_MEET_EXPECTATIONS',
    SizingAndFitWasSmallOrTooBig: 'SIZING_AND_FIT_WAS_SMALL_OR_TOO_BIG',
    ItemIsDamaged: 'ITEM_IS_DAMAGED',
    ConsumablesExpiresOrOutOfDate: 'CONSUMABLES_EXPIRES_OR_OUT_OF_DATE'
} as const;
export type CreateOrderReturnItemRequestReasonEnum = typeof CreateOrderReturnItemRequestReasonEnum[keyof typeof CreateOrderReturnItemRequestReasonEnum];


/**
 * Check if a given object implements the CreateOrderReturnItemRequest interface.
 */
export function instanceOfCreateOrderReturnItemRequest(value: object): value is CreateOrderReturnItemRequest {
    return true;
}

export function CreateOrderReturnItemRequestFromJSON(json: any): CreateOrderReturnItemRequest {
    return CreateOrderReturnItemRequestFromJSONTyped(json, false);
}

export function CreateOrderReturnItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderReturnItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'suborder_item_id': json['suborder_item_id'] == null ? undefined : json['suborder_item_id'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function CreateOrderReturnItemRequestToJSON(json: any): CreateOrderReturnItemRequest {
    return CreateOrderReturnItemRequestToJSONTyped(json, false);
}

export function CreateOrderReturnItemRequestToJSONTyped(value?: CreateOrderReturnItemRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suborder_item_id': value['suborder_item_id'],
        'quantity': value['quantity'],
        'reason': value['reason'],
    };
}

