export const variantPickerHelper = {
  methods: {
    decreaseVariantQuantity,
    increaseVariantQuantity,
    roundsUpValueBasedOnIncrement,
    disableDecreaseButton
  }
}

function decreaseVariantQuantity(currentValue, increment, minimum) {
  const newValue = (currentValue -= increment || 1)

  if (currentValue === 1 || currentValue === minimum) {
    return currentValue
  }

  if (newValue < minimum) {
    return minimum
  } else {
    return newValue
  }
}

function increaseVariantQuantity(currentValue, increment) {
  const newValue = Number(currentValue) + increment || 1
  const reminder = newValue % increment
  if (reminder) {
    return newValue - reminder
  } else {
    return newValue
  }
}

function roundsUpValueBasedOnIncrement(value, increment) {
  const remainder = value % increment

  if (remainder !== 0) {
    return value - remainder + increment
  }
  return value
}

function disableDecreaseButton(currentValue, minimum) {
  return currentValue === 1 || currentValue === minimum
}
