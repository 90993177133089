/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductVariantResponse
 */
export interface GetProductVariantResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductVariantResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    cost_per_item?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    compare_at_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    ordinal?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductVariantResponse
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductVariantResponse
     */
    tester_variant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductVariantResponse
     */
    tester_product_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductVariantResponse
     */
    tester_product_name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    tester_minimum_quantity?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetProductVariantResponse
     */
    characteristic_option?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof GetProductVariantResponse
     */
    sku?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductVariantResponse
     */
    in_stock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    inventory_quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductVariantResponse
     */
    weight?: number;
}

/**
 * Check if a given object implements the GetProductVariantResponse interface.
 */
export function instanceOfGetProductVariantResponse(value: object): value is GetProductVariantResponse {
    return true;
}

export function GetProductVariantResponseFromJSON(json: any): GetProductVariantResponse {
    return GetProductVariantResponseFromJSONTyped(json, false);
}

export function GetProductVariantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductVariantResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'cost_per_item': json['cost_per_item'] == null ? undefined : json['cost_per_item'],
        'compare_at_price': json['compare_at_price'] == null ? undefined : json['compare_at_price'],
        'ordinal': json['ordinal'] == null ? undefined : json['ordinal'],
        'image_url': json['image_url'] == null ? undefined : json['image_url'],
        'tester_variant_id': json['tester_variant_id'] == null ? undefined : json['tester_variant_id'],
        'tester_product_id': json['tester_product_id'] == null ? undefined : json['tester_product_id'],
        'tester_product_name': json['tester_product_name'] == null ? undefined : json['tester_product_name'],
        'tester_minimum_quantity': json['tester_minimum_quantity'] == null ? undefined : json['tester_minimum_quantity'],
        'characteristic_option': json['characteristic_option'] == null ? undefined : json['characteristic_option'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'in_stock': json['in_stock'] == null ? undefined : json['in_stock'],
        'inventory_quantity': json['inventory_quantity'] == null ? undefined : json['inventory_quantity'],
        'weight': json['weight'] == null ? undefined : json['weight'],
    };
}

export function GetProductVariantResponseToJSON(json: any): GetProductVariantResponse {
    return GetProductVariantResponseToJSONTyped(json, false);
}

export function GetProductVariantResponseToJSONTyped(value?: GetProductVariantResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'pos_price': value['pos_price'],
        'wholesale_price': value['wholesale_price'],
        'cost_per_item': value['cost_per_item'],
        'compare_at_price': value['compare_at_price'],
        'ordinal': value['ordinal'],
        'image_url': value['image_url'],
        'tester_variant_id': value['tester_variant_id'],
        'tester_product_id': value['tester_product_id'],
        'tester_product_name': value['tester_product_name'],
        'tester_minimum_quantity': value['tester_minimum_quantity'],
        'characteristic_option': value['characteristic_option'],
        'sku': value['sku'],
        'in_stock': value['in_stock'],
        'inventory_quantity': value['inventory_quantity'],
        'weight': value['weight'],
    };
}

