/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadRetailerThumbnailImageRequest
 */
export interface UploadRetailerThumbnailImageRequest {
    /**
     * Retailer thumbnail image
     * @type {Blob}
     * @memberof UploadRetailerThumbnailImageRequest
     */
    file: Blob;
}

/**
 * Check if a given object implements the UploadRetailerThumbnailImageRequest interface.
 */
export function instanceOfUploadRetailerThumbnailImageRequest(value: object): value is UploadRetailerThumbnailImageRequest {
    if (!('file' in value) || value['file'] === undefined) return false;
    return true;
}

export function UploadRetailerThumbnailImageRequestFromJSON(json: any): UploadRetailerThumbnailImageRequest {
    return UploadRetailerThumbnailImageRequestFromJSONTyped(json, false);
}

export function UploadRetailerThumbnailImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadRetailerThumbnailImageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function UploadRetailerThumbnailImageRequestToJSON(json: any): UploadRetailerThumbnailImageRequest {
    return UploadRetailerThumbnailImageRequestToJSONTyped(json, false);
}

export function UploadRetailerThumbnailImageRequestToJSONTyped(value?: UploadRetailerThumbnailImageRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'file': value['file'],
    };
}

