/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTradeShowDetailsResponse
 */
export interface GetTradeShowDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowDetailsResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowDetailsResponse
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowDetailsResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowDetailsResponse
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowDetailsResponse
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTradeShowDetailsResponse
     */
    slug?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetTradeShowDetailsResponse
     */
    start_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetTradeShowDetailsResponse
     */
    end_date?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetTradeShowDetailsResponse
     */
    exhibitor_count?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradeShowDetailsResponse
     */
    attendee_count?: number;
}

/**
 * Check if a given object implements the GetTradeShowDetailsResponse interface.
 */
export function instanceOfGetTradeShowDetailsResponse(value: object): value is GetTradeShowDetailsResponse {
    return true;
}

export function GetTradeShowDetailsResponseFromJSON(json: any): GetTradeShowDetailsResponse {
    return GetTradeShowDetailsResponseFromJSONTyped(json, false);
}

export function GetTradeShowDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTradeShowDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'name': json['name'] == null ? undefined : json['name'],
        'location': json['location'] == null ? undefined : json['location'],
        'external_id': json['external_id'] == null ? undefined : json['external_id'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'exhibitor_count': json['exhibitor_count'] == null ? undefined : json['exhibitor_count'],
        'attendee_count': json['attendee_count'] == null ? undefined : json['attendee_count'],
    };
}

export function GetTradeShowDetailsResponseToJSON(json: any): GetTradeShowDetailsResponse {
    return GetTradeShowDetailsResponseToJSONTyped(json, false);
}

export function GetTradeShowDetailsResponseToJSONTyped(value?: GetTradeShowDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'brand': value['brand'],
        'name': value['name'],
        'location': value['location'],
        'external_id': value['external_id'],
        'slug': value['slug'],
        'start_date': value['start_date'] == null ? undefined : ((value['start_date']).toISOString()),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString()),
        'exhibitor_count': value['exhibitor_count'],
        'attendee_count': value['attendee_count'],
    };
}

