/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Buyer directory header object
 * @export
 * @interface BuyerDirectoryHeaderRequest
 */
export interface BuyerDirectoryHeaderRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderRequest
     */
    desktop_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderRequest
     */
    mobile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderRequest
     */
    directory_title?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDirectoryHeaderRequest
     */
    directory_description?: string;
}

/**
 * Check if a given object implements the BuyerDirectoryHeaderRequest interface.
 */
export function instanceOfBuyerDirectoryHeaderRequest(value: object): value is BuyerDirectoryHeaderRequest {
    return true;
}

export function BuyerDirectoryHeaderRequestFromJSON(json: any): BuyerDirectoryHeaderRequest {
    return BuyerDirectoryHeaderRequestFromJSONTyped(json, false);
}

export function BuyerDirectoryHeaderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerDirectoryHeaderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'desktop_image_url': json['desktop_image_url'] == null ? undefined : json['desktop_image_url'],
        'mobile_image_url': json['mobile_image_url'] == null ? undefined : json['mobile_image_url'],
        'directory_title': json['directory_title'] == null ? undefined : json['directory_title'],
        'directory_description': json['directory_description'] == null ? undefined : json['directory_description'],
    };
}

export function BuyerDirectoryHeaderRequestToJSON(json: any): BuyerDirectoryHeaderRequest {
    return BuyerDirectoryHeaderRequestToJSONTyped(json, false);
}

export function BuyerDirectoryHeaderRequestToJSONTyped(value?: BuyerDirectoryHeaderRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'desktop_image_url': value['desktop_image_url'],
        'mobile_image_url': value['mobile_image_url'],
        'directory_title': value['directory_title'],
        'directory_description': value['directory_description'],
    };
}

