/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetSuborderItemsResponse } from './GetSuborderItemsResponse';
import {
    GetSuborderItemsResponseFromJSON,
    GetSuborderItemsResponseFromJSONTyped,
    GetSuborderItemsResponseToJSON,
    GetSuborderItemsResponseToJSONTyped,
} from './GetSuborderItemsResponse';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
    AddressResponseToJSONTyped,
} from './AddressResponse';

/**
 * 
 * @export
 * @interface InvoiceResponse
 */
export interface InvoiceResponse {
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    number?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceResponse
     */
    created_at?: Date;
    /**
     * 
     * @type {AddressResponse}
     * @memberof InvoiceResponse
     */
    retailer_address?: AddressResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof InvoiceResponse
     */
    bulletin_address?: AddressResponse;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceResponse
     */
    scheduled_charge_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceResponse
     */
    manually_charge_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    promo_code?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    promo_code_discount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    promo_code_type?: InvoiceResponsePromoCodeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    shipping_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    total_suborder_price?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    total_suborder_items_price?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    status?: InvoiceResponseStatusEnum;
    /**
     * 
     * @type {Array<GetSuborderItemsResponse>}
     * @memberof InvoiceResponse
     */
    items?: Array<GetSuborderItemsResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    manually_charged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    charge_status?: InvoiceResponseChargeStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    paid_via_balance?: boolean;
}


/**
 * @export
 */
export const InvoiceResponsePromoCodeTypeEnum = {
    Percent: 'PERCENT',
    Amount: 'AMOUNT',
    FreeShipping: 'FREE_SHIPPING'
} as const;
export type InvoiceResponsePromoCodeTypeEnum = typeof InvoiceResponsePromoCodeTypeEnum[keyof typeof InvoiceResponsePromoCodeTypeEnum];

/**
 * @export
 */
export const InvoiceResponseStatusEnum = {
    Paid: 'PAID',
    NotPaid: 'NOT_PAID',
    PaymentFailed: 'PAYMENT_FAILED',
    UnprocessableCharge: 'UNPROCESSABLE_CHARGE'
} as const;
export type InvoiceResponseStatusEnum = typeof InvoiceResponseStatusEnum[keyof typeof InvoiceResponseStatusEnum];

/**
 * @export
 */
export const InvoiceResponseChargeStatusEnum = {
    Pending: 'PENDING',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    OrderCanceled: 'ORDER_CANCELED'
} as const;
export type InvoiceResponseChargeStatusEnum = typeof InvoiceResponseChargeStatusEnum[keyof typeof InvoiceResponseChargeStatusEnum];


/**
 * Check if a given object implements the InvoiceResponse interface.
 */
export function instanceOfInvoiceResponse(value: object): value is InvoiceResponse {
    return true;
}

export function InvoiceResponseFromJSON(json: any): InvoiceResponse {
    return InvoiceResponseFromJSONTyped(json, false);
}

export function InvoiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'retailer_address': json['retailer_address'] == null ? undefined : AddressResponseFromJSON(json['retailer_address']),
        'bulletin_address': json['bulletin_address'] == null ? undefined : AddressResponseFromJSON(json['bulletin_address']),
        'scheduled_charge_date': json['scheduled_charge_date'] == null ? undefined : (new Date(json['scheduled_charge_date'])),
        'manually_charge_date': json['manually_charge_date'] == null ? undefined : (new Date(json['manually_charge_date'])),
        'promo_code': json['promo_code'] == null ? undefined : json['promo_code'],
        'promo_code_discount': json['promo_code_discount'] == null ? undefined : json['promo_code_discount'],
        'promo_code_type': json['promo_code_type'] == null ? undefined : json['promo_code_type'],
        'shipping_cost': json['shipping_cost'] == null ? undefined : json['shipping_cost'],
        'total_suborder_price': json['total_suborder_price'] == null ? undefined : json['total_suborder_price'],
        'total_suborder_items_price': json['total_suborder_items_price'] == null ? undefined : json['total_suborder_items_price'],
        'status': json['status'] == null ? undefined : json['status'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(GetSuborderItemsResponseFromJSON)),
        'manually_charged': json['manually_charged'] == null ? undefined : json['manually_charged'],
        'charge_status': json['charge_status'] == null ? undefined : json['charge_status'],
        'paid_via_balance': json['paid_via_balance'] == null ? undefined : json['paid_via_balance'],
    };
}

export function InvoiceResponseToJSON(json: any): InvoiceResponse {
    return InvoiceResponseToJSONTyped(json, false);
}

export function InvoiceResponseToJSONTyped(value?: InvoiceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'created_at': value['created_at'] == null ? undefined : ((value['created_at']).toISOString()),
        'retailer_address': AddressResponseToJSON(value['retailer_address']),
        'bulletin_address': AddressResponseToJSON(value['bulletin_address']),
        'scheduled_charge_date': value['scheduled_charge_date'] == null ? undefined : ((value['scheduled_charge_date']).toISOString().substring(0,10)),
        'manually_charge_date': value['manually_charge_date'] == null ? undefined : ((value['manually_charge_date']).toISOString().substring(0,10)),
        'promo_code': value['promo_code'],
        'promo_code_discount': value['promo_code_discount'],
        'promo_code_type': value['promo_code_type'],
        'shipping_cost': value['shipping_cost'],
        'total_suborder_price': value['total_suborder_price'],
        'total_suborder_items_price': value['total_suborder_items_price'],
        'status': value['status'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(GetSuborderItemsResponseToJSON)),
        'manually_charged': value['manually_charged'],
        'charge_status': value['charge_status'],
        'paid_via_balance': value['paid_via_balance'],
    };
}

