/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuborderDashboardResponse
 */
export interface SuborderDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderDashboardResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SuborderDashboardResponse
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof SuborderDashboardResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderDashboardResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderDashboardResponse
     */
    payout_date?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderDashboardResponse
     */
    original_charge_date?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderDashboardResponse
     */
    scheduled_charge_date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderDashboardResponse
     */
    manually_charged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuborderDashboardResponse
     */
    charge_date_adjusted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SuborderDashboardResponse
     */
    returned_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderDashboardResponse
     */
    accepted_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SuborderDashboardResponse
     */
    declined_amount?: number;
}

/**
 * Check if a given object implements the SuborderDashboardResponse interface.
 */
export function instanceOfSuborderDashboardResponse(value: object): value is SuborderDashboardResponse {
    return true;
}

export function SuborderDashboardResponseFromJSON(json: any): SuborderDashboardResponse {
    return SuborderDashboardResponseFromJSONTyped(json, false);
}

export function SuborderDashboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderDashboardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'number': json['number'] == null ? undefined : json['number'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'status': json['status'] == null ? undefined : json['status'],
        'payout_date': json['payout_date'] == null ? undefined : json['payout_date'],
        'original_charge_date': json['original_charge_date'] == null ? undefined : json['original_charge_date'],
        'scheduled_charge_date': json['scheduled_charge_date'] == null ? undefined : json['scheduled_charge_date'],
        'manually_charged': json['manually_charged'] == null ? undefined : json['manually_charged'],
        'charge_date_adjusted': json['charge_date_adjusted'] == null ? undefined : json['charge_date_adjusted'],
        'returned_amount': json['returned_amount'] == null ? undefined : json['returned_amount'],
        'accepted_amount': json['accepted_amount'] == null ? undefined : json['accepted_amount'],
        'declined_amount': json['declined_amount'] == null ? undefined : json['declined_amount'],
    };
}

export function SuborderDashboardResponseToJSON(json: any): SuborderDashboardResponse {
    return SuborderDashboardResponseToJSONTyped(json, false);
}

export function SuborderDashboardResponseToJSONTyped(value?: SuborderDashboardResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'number': value['number'],
        'brand_name': value['brand_name'],
        'status': value['status'],
        'payout_date': value['payout_date'],
        'original_charge_date': value['original_charge_date'],
        'scheduled_charge_date': value['scheduled_charge_date'],
        'manually_charged': value['manually_charged'],
        'charge_date_adjusted': value['charge_date_adjusted'],
        'returned_amount': value['returned_amount'],
        'accepted_amount': value['accepted_amount'],
        'declined_amount': value['declined_amount'],
    };
}

