/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ValidateEmailRequest,
  ValidateEmailResponse,
} from '../models/index';
import {
    ValidateEmailRequestFromJSON,
    ValidateEmailRequestToJSON,
    ValidateEmailResponseFromJSON,
    ValidateEmailResponseToJSON,
} from '../models/index';

export interface ValidateEmailOperationRequest {
    validateEmailRequest: ValidateEmailRequest;
}

/**
 * 
 */
export class ValidationControllerApi extends runtime.BaseAPI {

    /**
     * Validate an email address using SendGrid - third party service.
     */
    async validateEmailRaw(requestParameters: ValidateEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateEmailResponse>> {
        if (requestParameters['validateEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'validateEmailRequest',
                'Required parameter "validateEmailRequest" was null or undefined when calling validateEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/validations/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateEmailRequestToJSON(requestParameters['validateEmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateEmailResponseFromJSON(jsonValue));
    }

    /**
     * Validate an email address using SendGrid - third party service.
     */
    async validateEmail(requestParameters: ValidateEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateEmailResponse> {
        const response = await this.validateEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
