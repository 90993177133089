/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomAssortmentItemRequest
 */
export interface CustomAssortmentItemRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentItemRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentItemRequest
     */
    variant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomAssortmentItemRequest
     */
    category_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomAssortmentItemRequest
     */
    quantity?: number;
}

/**
 * Check if a given object implements the CustomAssortmentItemRequest interface.
 */
export function instanceOfCustomAssortmentItemRequest(value: object): value is CustomAssortmentItemRequest {
    return true;
}

export function CustomAssortmentItemRequestFromJSON(json: any): CustomAssortmentItemRequest {
    return CustomAssortmentItemRequestFromJSONTyped(json, false);
}

export function CustomAssortmentItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAssortmentItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'variant_id': json['variant_id'] == null ? undefined : json['variant_id'],
        'category_id': json['category_id'] == null ? undefined : json['category_id'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function CustomAssortmentItemRequestToJSON(json: any): CustomAssortmentItemRequest {
    return CustomAssortmentItemRequestToJSONTyped(json, false);
}

export function CustomAssortmentItemRequestToJSONTyped(value?: CustomAssortmentItemRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'variant_id': value['variant_id'],
        'category_id': value['category_id'],
        'quantity': value['quantity'],
    };
}

