/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BrandBoardShareResponse } from './BrandBoardShareResponse';
import {
    BrandBoardShareResponseFromJSON,
    BrandBoardShareResponseFromJSONTyped,
    BrandBoardShareResponseToJSON,
    BrandBoardShareResponseToJSONTyped,
} from './BrandBoardShareResponse';

/**
 * 
 * @export
 * @interface GetBrandBoardSharesResponse
 */
export interface GetBrandBoardSharesResponse {
    /**
     * 
     * @type {Array<BrandBoardShareResponse>}
     * @memberof GetBrandBoardSharesResponse
     */
    shares?: Array<BrandBoardShareResponse>;
}

/**
 * Check if a given object implements the GetBrandBoardSharesResponse interface.
 */
export function instanceOfGetBrandBoardSharesResponse(value: object): value is GetBrandBoardSharesResponse {
    return true;
}

export function GetBrandBoardSharesResponseFromJSON(json: any): GetBrandBoardSharesResponse {
    return GetBrandBoardSharesResponseFromJSONTyped(json, false);
}

export function GetBrandBoardSharesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBrandBoardSharesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'shares': json['shares'] == null ? undefined : ((json['shares'] as Array<any>).map(BrandBoardShareResponseFromJSON)),
    };
}

export function GetBrandBoardSharesResponseToJSON(json: any): GetBrandBoardSharesResponse {
    return GetBrandBoardSharesResponseToJSONTyped(json, false);
}

export function GetBrandBoardSharesResponseToJSONTyped(value?: GetBrandBoardSharesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shares': value['shares'] == null ? undefined : ((value['shares'] as Array<any>).map(BrandBoardShareResponseToJSON)),
    };
}

