/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulletinIncubatorBrandRequest
 */
export interface BulletinIncubatorBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    userid?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    exhibitingas?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    a2zcontractemail?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    subexpocode?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    booth?: string;
    /**
     * 
     * @type {string}
     * @memberof BulletinIncubatorBrandRequest
     */
    companyid?: string;
}

/**
 * Check if a given object implements the BulletinIncubatorBrandRequest interface.
 */
export function instanceOfBulletinIncubatorBrandRequest(value: object): value is BulletinIncubatorBrandRequest {
    return true;
}

export function BulletinIncubatorBrandRequestFromJSON(json: any): BulletinIncubatorBrandRequest {
    return BulletinIncubatorBrandRequestFromJSONTyped(json, false);
}

export function BulletinIncubatorBrandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulletinIncubatorBrandRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'event': json['event'] == null ? undefined : json['event'],
        'userid': json['userid'] == null ? undefined : json['userid'],
        'exhibitingas': json['exhibitingas'] == null ? undefined : json['exhibitingas'],
        'email': json['email'] == null ? undefined : json['email'],
        'a2zcontractemail': json['a2zcontractemail'] == null ? undefined : json['a2zcontractemail'],
        'subexpocode': json['subexpocode'] == null ? undefined : json['subexpocode'],
        'booth': json['booth'] == null ? undefined : json['booth'],
        'companyid': json['companyid'] == null ? undefined : json['companyid'],
    };
}

export function BulletinIncubatorBrandRequestToJSON(json: any): BulletinIncubatorBrandRequest {
    return BulletinIncubatorBrandRequestToJSONTyped(json, false);
}

export function BulletinIncubatorBrandRequestToJSONTyped(value?: BulletinIncubatorBrandRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'event': value['event'],
        'userid': value['userid'],
        'exhibitingas': value['exhibitingas'],
        'email': value['email'],
        'a2zcontractemail': value['a2zcontractemail'],
        'subexpocode': value['subexpocode'],
        'booth': value['booth'],
        'companyid': value['companyid'],
    };
}

