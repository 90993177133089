/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeletedVariantResponse } from './DeletedVariantResponse';
import {
    DeletedVariantResponseFromJSON,
    DeletedVariantResponseFromJSONTyped,
    DeletedVariantResponseToJSON,
    DeletedVariantResponseToJSONTyped,
} from './DeletedVariantResponse';

/**
 * 
 * @export
 * @interface GetDeletedVariantsResponse
 */
export interface GetDeletedVariantsResponse {
    /**
     * 
     * @type {Array<DeletedVariantResponse>}
     * @memberof GetDeletedVariantsResponse
     */
    deleted_variants?: Array<DeletedVariantResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDeletedVariantsResponse
     */
    product_names?: Array<string>;
}

/**
 * Check if a given object implements the GetDeletedVariantsResponse interface.
 */
export function instanceOfGetDeletedVariantsResponse(value: object): value is GetDeletedVariantsResponse {
    return true;
}

export function GetDeletedVariantsResponseFromJSON(json: any): GetDeletedVariantsResponse {
    return GetDeletedVariantsResponseFromJSONTyped(json, false);
}

export function GetDeletedVariantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeletedVariantsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'deleted_variants': json['deleted_variants'] == null ? undefined : ((json['deleted_variants'] as Array<any>).map(DeletedVariantResponseFromJSON)),
        'product_names': json['product_names'] == null ? undefined : json['product_names'],
    };
}

export function GetDeletedVariantsResponseToJSON(json: any): GetDeletedVariantsResponse {
    return GetDeletedVariantsResponseToJSONTyped(json, false);
}

export function GetDeletedVariantsResponseToJSONTyped(value?: GetDeletedVariantsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'deleted_variants': value['deleted_variants'] == null ? undefined : ((value['deleted_variants'] as Array<any>).map(DeletedVariantResponseToJSON)),
        'product_names': value['product_names'],
    };
}

