<template>
  <div class="filter-price">
    <div class="price-input-wrapper">
      <text-field
        v-model="retailPriceFrom"
        no-spacing
        width="full"
        type="number"
        name="retailPriceFrom"
        label="Minimum"
        :validate="{ regex: $root.alphaNumericPattern, min_value: 0 }"
        :no-error-message="true"
        :focus="() => onMinValueFocused()"
        :blur="() => onMinValueBlurred()"
      />
    </div>
    <span class="input-divider">to</span>
    <div class="price-input-wrapper">
      <text-field
        v-model="retailPriceTo"
        name="retailPriceTo"
        type="number"
        no-spacing
        width="full"
        label="Maximum"
        :focus="() => onMaxValueFocused()"
        :blur="() => onMaxValueBlurred()"
        :validate="{ regex: $root.alphaNumericPattern, min_value: 0 }"
        :no-error-message="true"
      />
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import { paramsHelper } from '@/components/mixin/paramsHelper'
import { filterHelper } from './filterHelper'
import { FilterKeys } from '../../../../services/utils'
export default {
  mixins: [paramsHelper, filterHelper],
  provide() {
    return { parentValidator: this.$validator }
  },
  data() {
    return {
      retailPriceFrom: null,
      retailPriceTo: null,
      isFromValid: true,
      isToValid: true,
      loaded: false
    }
  },
  watch: {
    retailPriceFrom: _debounce(function () {
      this.handleValueChange()
    }, 500),
    retailPriceTo: _debounce(function () {
      this.handleValueChange()
    }, 500),
    '$route.query.price_from': function (price_from) {
      this.parseSelectedValue({ ...this.$route.query, price_from })
      // this.transformValuesEmitFilterSelection();
      this.handleValueChange()
    },
    '$route.query.price_to': function (price_to) {
      this.parseSelectedValue({ ...this.$route.query, price_to })
      this.handleValueChange()
    }
  },
  beforeMount() {
    this.parseSelectedValue(this.$route.query)
    this.handleValueChange()
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    handleValueChange: async function () {
      const from = Number(this.retailPriceFrom)
      const to = Number(this.retailPriceTo)

      if (!this.$validator.errors.has('retailPriceFrom') && from) {
        this.updateURLParams('price_from', from)
        this.isFromValid = true
      } else {
        this.updateURLParams('price_from', null)
        this.isFromValid = false
      }

      if (!this.$validator.errors.has('retailPriceTo') && to) {
        if (to > from) {
          this.updateURLParams('price_to', to)
          this.isToValid = true
        } else {
          this.isToValid = false
          this.$validator.errors.add({ field: 'retailPriceTo', msg: 'Invalid range' })
          this.updateURLParams('price_to', null)
        }
      } else {
        this.updateURLParams('price_to', null)
        this.isToValid = false
      }

      this.transformValuesEmitFilterSelection()
      this.setParentSelectionCountValue([this.isFromValid, this.isToValid].filter(Boolean).length)

      if (this.loaded && (this.isFromValid || this.isToValid)) {
        this.trackFilterUsedEvent('price')
      }
    },
    parseSelectedValue: function (query) {
      const { price_from, price_to } = query
      this.retailPriceFrom = price_from ? Number(price_from) : null
      this.retailPriceTo = price_to ? Number(price_to) : null
    },
    transformValuesEmitFilterSelection() {
      this.emitFilterSelection({
        filterKey: FilterKeys.PRICE_FROM,
        items:
          this.retailPriceFrom && this.isFromValid
            ? [{ value: this.retailPriceFrom, label: `Retail Min: $${this.retailPriceFrom}`, selected: true }]
            : []
      })
      this.emitFilterSelection({
        filterKey: FilterKeys.PRICE_TO,
        items:
          this.retailPriceTo && this.isToValid
            ? [{ value: this.retailPriceTo, label: `Retail Max: $${this.retailPriceTo}`, selected: true }]
            : []
      })
    },
    onMinValueFocused: function () {
      if (this.retailPriceFrom === 0) {
        this.retailPriceFrom = null
      }
    },
    onMinValueBlurred: function () {
      if (this.retailPriceFrom === null || this.retailPriceFrom === '' || this.retailPriceFrom < 0) {
        this.retailPriceFrom = null
      }
    },
    onMaxValueFocused: function () {
      if (this.retailPriceTo === 0) {
        this.retailPriceTo = null
      }
    },
    onMaxValueBlurred: function () {
      if (this.retailPriceTo === null || this.retailPriceTo === '' || this.retailPriceTo < 0) {
        this.retailPriceTo = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
.filter-price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
  .input-divider {
    margin-left: 10px;
    margin-right: 10px;
  }
  .price-label {
    font-size: 12px;
    font-family: NHaasGroteskDSPro-55Rg;
    color: $color-grey-4;
  }

  .price-input-wrapper {
    flex: 1;
  }
}
</style>
