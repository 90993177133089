/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RetailerBoardResponse } from './RetailerBoardResponse';
import {
    RetailerBoardResponseFromJSON,
    RetailerBoardResponseFromJSONTyped,
    RetailerBoardResponseToJSON,
    RetailerBoardResponseToJSONTyped,
} from './RetailerBoardResponse';

/**
 * 
 * @export
 * @interface GetRetailerBoardsResponse
 */
export interface GetRetailerBoardsResponse {
    /**
     * 
     * @type {Array<RetailerBoardResponse>}
     * @memberof GetRetailerBoardsResponse
     */
    boards?: Array<RetailerBoardResponse>;
}

/**
 * Check if a given object implements the GetRetailerBoardsResponse interface.
 */
export function instanceOfGetRetailerBoardsResponse(value: object): value is GetRetailerBoardsResponse {
    return true;
}

export function GetRetailerBoardsResponseFromJSON(json: any): GetRetailerBoardsResponse {
    return GetRetailerBoardsResponseFromJSONTyped(json, false);
}

export function GetRetailerBoardsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRetailerBoardsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'boards': json['boards'] == null ? undefined : ((json['boards'] as Array<any>).map(RetailerBoardResponseFromJSON)),
    };
}

export function GetRetailerBoardsResponseToJSON(json: any): GetRetailerBoardsResponse {
    return GetRetailerBoardsResponseToJSONTyped(json, false);
}

export function GetRetailerBoardsResponseToJSONTyped(value?: GetRetailerBoardsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'boards': value['boards'] == null ? undefined : ((value['boards'] as Array<any>).map(RetailerBoardResponseToJSON)),
    };
}

