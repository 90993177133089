/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Add suborder shipping info object
 * @export
 * @interface AddSuborderShippingInfoRequest
 */
export interface AddSuborderShippingInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof AddSuborderShippingInfoRequest
     */
    shipping_info?: string;
}

/**
 * Check if a given object implements the AddSuborderShippingInfoRequest interface.
 */
export function instanceOfAddSuborderShippingInfoRequest(value: object): value is AddSuborderShippingInfoRequest {
    return true;
}

export function AddSuborderShippingInfoRequestFromJSON(json: any): AddSuborderShippingInfoRequest {
    return AddSuborderShippingInfoRequestFromJSONTyped(json, false);
}

export function AddSuborderShippingInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSuborderShippingInfoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipping_info': json['shipping_info'] == null ? undefined : json['shipping_info'],
    };
}

export function AddSuborderShippingInfoRequestToJSON(json: any): AddSuborderShippingInfoRequest {
    return AddSuborderShippingInfoRequestToJSONTyped(json, false);
}

export function AddSuborderShippingInfoRequestToJSONTyped(value?: AddSuborderShippingInfoRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shipping_info': value['shipping_info'],
    };
}

