export default {
  props: {
    buttonType: {
      type: String,
      validator: (val) => ['tag'].includes(val)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
