/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VariantWithoutSkuResponse } from './VariantWithoutSkuResponse';
import {
    VariantWithoutSkuResponseFromJSON,
    VariantWithoutSkuResponseFromJSONTyped,
    VariantWithoutSkuResponseToJSON,
    VariantWithoutSkuResponseToJSONTyped,
} from './VariantWithoutSkuResponse';

/**
 * 
 * @export
 * @interface UpdateInventoryLevelResponse
 */
export interface UpdateInventoryLevelResponse {
    /**
     * 
     * @type {Array<VariantWithoutSkuResponse>}
     * @memberof UpdateInventoryLevelResponse
     */
    variants?: Array<VariantWithoutSkuResponse>;
    /**
     * 
     * @type {string}
     * @memberof UpdateInventoryLevelResponse
     */
    message?: string;
}

/**
 * Check if a given object implements the UpdateInventoryLevelResponse interface.
 */
export function instanceOfUpdateInventoryLevelResponse(value: object): value is UpdateInventoryLevelResponse {
    return true;
}

export function UpdateInventoryLevelResponseFromJSON(json: any): UpdateInventoryLevelResponse {
    return UpdateInventoryLevelResponseFromJSONTyped(json, false);
}

export function UpdateInventoryLevelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInventoryLevelResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'variants': json['variants'] == null ? undefined : ((json['variants'] as Array<any>).map(VariantWithoutSkuResponseFromJSON)),
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function UpdateInventoryLevelResponseToJSON(json: any): UpdateInventoryLevelResponse {
    return UpdateInventoryLevelResponseToJSONTyped(json, false);
}

export function UpdateInventoryLevelResponseToJSONTyped(value?: UpdateInventoryLevelResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'variants': value['variants'] == null ? undefined : ((value['variants'] as Array<any>).map(VariantWithoutSkuResponseToJSON)),
        'message': value['message'],
    };
}

