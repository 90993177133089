/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Validation } from './Validation';
import {
    ValidationFromJSON,
    ValidationFromJSONTyped,
    ValidationToJSON,
    ValidationToJSONTyped,
} from './Validation';

/**
 * 
 * @export
 * @interface ValidateEmailResponse
 */
export interface ValidateEmailResponse {
    /**
     * 
     * @type {Validation}
     * @memberof ValidateEmailResponse
     */
    validation: Validation;
}

/**
 * Check if a given object implements the ValidateEmailResponse interface.
 */
export function instanceOfValidateEmailResponse(value: object): value is ValidateEmailResponse {
    if (!('validation' in value) || value['validation'] === undefined) return false;
    return true;
}

export function ValidateEmailResponseFromJSON(json: any): ValidateEmailResponse {
    return ValidateEmailResponseFromJSONTyped(json, false);
}

export function ValidateEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateEmailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'validation': ValidationFromJSON(json['validation']),
    };
}

export function ValidateEmailResponseToJSON(json: any): ValidateEmailResponse {
    return ValidateEmailResponseToJSONTyped(json, false);
}

export function ValidateEmailResponseToJSONTyped(value?: ValidateEmailResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'validation': ValidationToJSON(value['validation']),
    };
}

