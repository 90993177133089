/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetOrdersDashboardForSubordersResponse } from './GetOrdersDashboardForSubordersResponse';
import {
    GetOrdersDashboardForSubordersResponseFromJSON,
    GetOrdersDashboardForSubordersResponseFromJSONTyped,
    GetOrdersDashboardForSubordersResponseToJSON,
    GetOrdersDashboardForSubordersResponseToJSONTyped,
} from './GetOrdersDashboardForSubordersResponse';
import type { OrdersPagination } from './OrdersPagination';
import {
    OrdersPaginationFromJSON,
    OrdersPaginationFromJSONTyped,
    OrdersPaginationToJSON,
    OrdersPaginationToJSONTyped,
} from './OrdersPagination';

/**
 * 
 * @export
 * @interface PaginatedOrdersForSubordersResponse
 */
export interface PaginatedOrdersForSubordersResponse {
    /**
     * 
     * @type {Array<GetOrdersDashboardForSubordersResponse>}
     * @memberof PaginatedOrdersForSubordersResponse
     */
    hits?: Array<GetOrdersDashboardForSubordersResponse>;
    /**
     * 
     * @type {OrdersPagination}
     * @memberof PaginatedOrdersForSubordersResponse
     */
    pagination?: OrdersPagination;
}

/**
 * Check if a given object implements the PaginatedOrdersForSubordersResponse interface.
 */
export function instanceOfPaginatedOrdersForSubordersResponse(value: object): value is PaginatedOrdersForSubordersResponse {
    return true;
}

export function PaginatedOrdersForSubordersResponseFromJSON(json: any): PaginatedOrdersForSubordersResponse {
    return PaginatedOrdersForSubordersResponseFromJSONTyped(json, false);
}

export function PaginatedOrdersForSubordersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedOrdersForSubordersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hits': json['hits'] == null ? undefined : ((json['hits'] as Array<any>).map(GetOrdersDashboardForSubordersResponseFromJSON)),
        'pagination': json['pagination'] == null ? undefined : OrdersPaginationFromJSON(json['pagination']),
    };
}

export function PaginatedOrdersForSubordersResponseToJSON(json: any): PaginatedOrdersForSubordersResponse {
    return PaginatedOrdersForSubordersResponseToJSONTyped(json, false);
}

export function PaginatedOrdersForSubordersResponseToJSONTyped(value?: PaginatedOrdersForSubordersResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hits': value['hits'] == null ? undefined : ((value['hits'] as Array<any>).map(GetOrdersDashboardForSubordersResponseToJSON)),
        'pagination': OrdersPaginationToJSON(value['pagination']),
    };
}

