/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulletinIncubatorBrandRequest } from './BulletinIncubatorBrandRequest';
import {
    BulletinIncubatorBrandRequestFromJSON,
    BulletinIncubatorBrandRequestFromJSONTyped,
    BulletinIncubatorBrandRequestToJSON,
    BulletinIncubatorBrandRequestToJSONTyped,
} from './BulletinIncubatorBrandRequest';

/**
 * 
 * @export
 * @interface ImportBulletinIncubatorBrandsRequest
 */
export interface ImportBulletinIncubatorBrandsRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportBulletinIncubatorBrandsRequest
     */
    event_id: string;
    /**
     * 
     * @type {Array<BulletinIncubatorBrandRequest>}
     * @memberof ImportBulletinIncubatorBrandsRequest
     */
    brands?: Array<BulletinIncubatorBrandRequest>;
}

/**
 * Check if a given object implements the ImportBulletinIncubatorBrandsRequest interface.
 */
export function instanceOfImportBulletinIncubatorBrandsRequest(value: object): value is ImportBulletinIncubatorBrandsRequest {
    if (!('event_id' in value) || value['event_id'] === undefined) return false;
    return true;
}

export function ImportBulletinIncubatorBrandsRequestFromJSON(json: any): ImportBulletinIncubatorBrandsRequest {
    return ImportBulletinIncubatorBrandsRequestFromJSONTyped(json, false);
}

export function ImportBulletinIncubatorBrandsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportBulletinIncubatorBrandsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'event_id': json['event_id'],
        'brands': json['brands'] == null ? undefined : ((json['brands'] as Array<any>).map(BulletinIncubatorBrandRequestFromJSON)),
    };
}

export function ImportBulletinIncubatorBrandsRequestToJSON(json: any): ImportBulletinIncubatorBrandsRequest {
    return ImportBulletinIncubatorBrandsRequestToJSONTyped(json, false);
}

export function ImportBulletinIncubatorBrandsRequestToJSONTyped(value?: ImportBulletinIncubatorBrandsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'event_id': value['event_id'],
        'brands': value['brands'] == null ? undefined : ((value['brands'] as Array<any>).map(BulletinIncubatorBrandRequestToJSON)),
    };
}

