/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DraftProductRequest } from './DraftProductRequest';
import {
    DraftProductRequestFromJSON,
    DraftProductRequestFromJSONTyped,
    DraftProductRequestToJSON,
    DraftProductRequestToJSONTyped,
} from './DraftProductRequest';

/**
 * Add all draft products request
 * @export
 * @interface AddAllDraftProductsRequest
 */
export interface AddAllDraftProductsRequest {
    /**
     * 
     * @type {Array<DraftProductRequest>}
     * @memberof AddAllDraftProductsRequest
     */
    draft_products?: Array<DraftProductRequest>;
}

/**
 * Check if a given object implements the AddAllDraftProductsRequest interface.
 */
export function instanceOfAddAllDraftProductsRequest(value: object): value is AddAllDraftProductsRequest {
    return true;
}

export function AddAllDraftProductsRequestFromJSON(json: any): AddAllDraftProductsRequest {
    return AddAllDraftProductsRequestFromJSONTyped(json, false);
}

export function AddAllDraftProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAllDraftProductsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'draft_products': json['draft_products'] == null ? undefined : ((json['draft_products'] as Array<any>).map(DraftProductRequestFromJSON)),
    };
}

export function AddAllDraftProductsRequestToJSON(json: any): AddAllDraftProductsRequest {
    return AddAllDraftProductsRequestToJSONTyped(json, false);
}

export function AddAllDraftProductsRequestToJSONTyped(value?: AddAllDraftProductsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'draft_products': value['draft_products'] == null ? undefined : ((value['draft_products'] as Array<any>).map(DraftProductRequestToJSON)),
    };
}

