<template>
  <div
    v-if="!isLoading"
    class="toggle--wrapper relative flex items-center"
    :class="{
      'disabled opacity-05': disabled,
      'toggle--wrapper--lock--on': hasLock && disabled && value,
      'toggle--wrapper--lock--off': hasLock && disabled && !value
    }"
    @click="
      $emit('click', $event)
      $emit('input', !value)
    "
  >
    <div class="toggle--indicator" :class="{ checked: value, 'mr-15': !!label }" />
    <span>{{ label }}</span>
  </div>
  <div v-else class="toggle--wrapper--loading">
    <loader-static :dark="true" />
  </div>
</template>

<script>
export default {
  props: ['value', 'label', 'isLoading', 'disabled', 'hasLock']
}
</script>
