/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetVideoResponse } from './GetVideoResponse';
import {
    GetVideoResponseFromJSON,
    GetVideoResponseFromJSONTyped,
    GetVideoResponseToJSON,
    GetVideoResponseToJSONTyped,
} from './GetVideoResponse';
import type { ProductCategoryResponse } from './ProductCategoryResponse';
import {
    ProductCategoryResponseFromJSON,
    ProductCategoryResponseFromJSONTyped,
    ProductCategoryResponseToJSON,
    ProductCategoryResponseToJSONTyped,
} from './ProductCategoryResponse';
import type { GetProductImageResponse } from './GetProductImageResponse';
import {
    GetProductImageResponseFromJSON,
    GetProductImageResponseFromJSONTyped,
    GetProductImageResponseToJSON,
    GetProductImageResponseToJSONTyped,
} from './GetProductImageResponse';
import type { GetBrandProductResponse } from './GetBrandProductResponse';
import {
    GetBrandProductResponseFromJSON,
    GetBrandProductResponseFromJSONTyped,
    GetBrandProductResponseToJSON,
    GetBrandProductResponseToJSONTyped,
} from './GetBrandProductResponse';
import type { GetProductVariantResponse } from './GetProductVariantResponse';
import {
    GetProductVariantResponseFromJSON,
    GetProductVariantResponseFromJSONTyped,
    GetProductVariantResponseToJSON,
    GetProductVariantResponseToJSONTyped,
} from './GetProductVariantResponse';

/**
 * 
 * @export
 * @interface GetProductResponse
 */
export interface GetProductResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponse
     */
    description?: string;
    /**
     * 
     * @type {GetBrandProductResponse}
     * @memberof GetProductResponse
     */
    brand?: GetBrandProductResponse;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponse
     */
    status?: GetProductResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    sell_pos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    sell_wholesale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    pos_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    wholesale_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    compare_at_price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    wholesale_minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    wholesale_increment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    opted_into_consignment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    commission_split?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    min_lead_time?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    max_lead_time?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    is_test_product?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductResponse
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductResponse
     */
    characteristics?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof GetProductResponse
     */
    characteristic_options?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<GetProductVariantResponse>}
     * @memberof GetProductResponse
     */
    variants?: Array<GetProductVariantResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductResponse
     */
    collections?: Array<string>;
    /**
     * 
     * @type {Array<GetProductImageResponse>}
     * @memberof GetProductResponse
     */
    images?: Array<GetProductImageResponse>;
    /**
     * 
     * @type {GetVideoResponse}
     * @memberof GetProductResponse
     */
    video?: GetVideoResponse;
    /**
     * 
     * @type {Array<GetProductImageResponse>}
     * @memberof GetProductResponse
     */
    custom_assortment_images?: Array<GetProductImageResponse>;
    /**
     * 
     * @type {Array<ProductCategoryResponse>}
     * @memberof GetProductResponse
     */
    categories?: Array<ProductCategoryResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    track_inventory?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetProductResponse
     */
    shopify?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof GetProductResponse
     */
    weight?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductResponse
     */
    created_by_admin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponse
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponse
     */
    updated_at?: string;
}


/**
 * @export
 */
export const GetProductResponseStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Any: 'ANY',
    Draft: 'DRAFT'
} as const;
export type GetProductResponseStatusEnum = typeof GetProductResponseStatusEnum[keyof typeof GetProductResponseStatusEnum];


/**
 * Check if a given object implements the GetProductResponse interface.
 */
export function instanceOfGetProductResponse(value: object): value is GetProductResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function GetProductResponseFromJSON(json: any): GetProductResponse {
    return GetProductResponseFromJSONTyped(json, false);
}

export function GetProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'brand': json['brand'] == null ? undefined : GetBrandProductResponseFromJSON(json['brand']),
        'status': json['status'] == null ? undefined : json['status'],
        'sell_pos': json['sell_pos'] == null ? undefined : json['sell_pos'],
        'sell_wholesale': json['sell_wholesale'] == null ? undefined : json['sell_wholesale'],
        'active': json['active'] == null ? undefined : json['active'],
        'pos_price': json['pos_price'] == null ? undefined : json['pos_price'],
        'wholesale_price': json['wholesale_price'] == null ? undefined : json['wholesale_price'],
        'compare_at_price': json['compare_at_price'] == null ? undefined : json['compare_at_price'],
        'wholesale_minimum': json['wholesale_minimum'] == null ? undefined : json['wholesale_minimum'],
        'wholesale_increment': json['wholesale_increment'] == null ? undefined : json['wholesale_increment'],
        'opted_into_consignment': json['opted_into_consignment'] == null ? undefined : json['opted_into_consignment'],
        'commission_split': json['commission_split'] == null ? undefined : json['commission_split'],
        'visible': json['visible'] == null ? undefined : json['visible'],
        'min_lead_time': json['min_lead_time'] == null ? undefined : json['min_lead_time'],
        'max_lead_time': json['max_lead_time'] == null ? undefined : json['max_lead_time'],
        'is_test_product': json['is_test_product'] == null ? undefined : json['is_test_product'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'characteristics': json['characteristics'] == null ? undefined : json['characteristics'],
        'characteristic_options': json['characteristic_options'] == null ? undefined : json['characteristic_options'],
        'variants': json['variants'] == null ? undefined : ((json['variants'] as Array<any>).map(GetProductVariantResponseFromJSON)),
        'collections': json['collections'] == null ? undefined : json['collections'],
        'images': json['images'] == null ? undefined : ((json['images'] as Array<any>).map(GetProductImageResponseFromJSON)),
        'video': json['video'] == null ? undefined : GetVideoResponseFromJSON(json['video']),
        'custom_assortment_images': json['custom_assortment_images'] == null ? undefined : ((json['custom_assortment_images'] as Array<any>).map(GetProductImageResponseFromJSON)),
        'categories': json['categories'] == null ? undefined : ((json['categories'] as Array<any>).map(ProductCategoryResponseFromJSON)),
        'track_inventory': json['track_inventory'] == null ? undefined : json['track_inventory'],
        'shopify': json['shopify'] == null ? undefined : json['shopify'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'created_by_admin': json['created_by_admin'] == null ? undefined : json['created_by_admin'],
        'created_at': json['created_at'] == null ? undefined : json['created_at'],
        'updated_at': json['updated_at'] == null ? undefined : json['updated_at'],
    };
}

export function GetProductResponseToJSON(json: any): GetProductResponse {
    return GetProductResponseToJSONTyped(json, false);
}

export function GetProductResponseToJSONTyped(value?: GetProductResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'brand': GetBrandProductResponseToJSON(value['brand']),
        'status': value['status'],
        'sell_pos': value['sell_pos'],
        'sell_wholesale': value['sell_wholesale'],
        'active': value['active'],
        'pos_price': value['pos_price'],
        'wholesale_price': value['wholesale_price'],
        'compare_at_price': value['compare_at_price'],
        'wholesale_minimum': value['wholesale_minimum'],
        'wholesale_increment': value['wholesale_increment'],
        'opted_into_consignment': value['opted_into_consignment'],
        'commission_split': value['commission_split'],
        'visible': value['visible'],
        'min_lead_time': value['min_lead_time'],
        'max_lead_time': value['max_lead_time'],
        'is_test_product': value['is_test_product'],
        'tags': value['tags'],
        'characteristics': value['characteristics'],
        'characteristic_options': value['characteristic_options'],
        'variants': value['variants'] == null ? undefined : ((value['variants'] as Array<any>).map(GetProductVariantResponseToJSON)),
        'collections': value['collections'],
        'images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(GetProductImageResponseToJSON)),
        'video': GetVideoResponseToJSON(value['video']),
        'custom_assortment_images': value['custom_assortment_images'] == null ? undefined : ((value['custom_assortment_images'] as Array<any>).map(GetProductImageResponseToJSON)),
        'categories': value['categories'] == null ? undefined : ((value['categories'] as Array<any>).map(ProductCategoryResponseToJSON)),
        'track_inventory': value['track_inventory'],
        'shopify': value['shopify'],
        'weight': value['weight'],
        'created_by_admin': value['created_by_admin'],
        'created_at': value['created_at'],
        'updated_at': value['updated_at'],
    };
}

