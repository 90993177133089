export const sessionHelper = {
  methods: {
    logout() {
      localStorage.removeItem('ONBOARD_DATA')
      this.$ma.reset()
      this.$eventBus.$emit('destroy-cart')
      this.$eventBus.$emit('unsubscribe-all')
      this.$store.commit('removeCheckoutState')
      if (window.talkSession) {
        window.talkSession.destroy()
      }

      this.$session.destroy()
      this.$store.commit('setUser', null)

      if (this.$route.name === undefined || this.$route.name === 'home') {
        window.location.reload()
      } else {
        this.$router.push('/home')
        this.$forceUpdate()
      }
    }
  }
}
