import { FilterKeys } from '../services/utils'

export default {
  namespaced: true,
  state: () => ({
    filters: {
      [FilterKeys.CATEGORIES]: [],
      [FilterKeys.BRANDS]: [],
      [FilterKeys.BRAND_VALUES]: [],
      [FilterKeys.SHIP_BY]: [],
      [FilterKeys.COUNTRY]: [],
      [FilterKeys.LOCATION]: [],
      [FilterKeys.PRICE_FROM]: [],
      [FilterKeys.PRICE_TO]: [],
      [FilterKeys.MOV]: [],
      [FilterKeys.KEYWORD]: [],
      [FilterKeys.SECTIONS]: [],
      [FilterKeys.SPECIAL_SERVICES]: [],
      [FilterKeys.DEALS_PROMOTIONS]: []
    },
    categoryBrandIds: [],
    searchTermBrandIds: { keyword: '', brandsIds: [] }
  }),
  mutations: {
    setFilters(state, { filterKey, activeFilters }) {
      state.filters[filterKey] = activeFilters
    },
    clearFilters(state) {
      const clearFilters = { ...state.filters }
      Object.keys(clearFilters).forEach((key) => (clearFilters[key] = []))
      state.filters = clearFilters
    },
    setCategoryBrandsIds(state, brandIds) {
      state.categoryBrandIds = brandIds
    },
    setSearchTermBrandIds(state, brandIds) {
      state.searchTermBrandIds = brandIds
    }
  },
  getters: {
    filters(state) {
      return state.filters
    },
    categoryBrandIds(state) {
      return state.categoryBrandIds
    },
    searchTermBrandIds(state) {
      return state.searchTermBrandIds
    }
  }
}
