/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductsByCategoryResponse
 */
export interface GetProductsByCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductsByCategoryResponse
     */
    category_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductsByCategoryResponse
     */
    product_ids?: Array<string>;
}

/**
 * Check if a given object implements the GetProductsByCategoryResponse interface.
 */
export function instanceOfGetProductsByCategoryResponse(value: object): value is GetProductsByCategoryResponse {
    return true;
}

export function GetProductsByCategoryResponseFromJSON(json: any): GetProductsByCategoryResponse {
    return GetProductsByCategoryResponseFromJSONTyped(json, false);
}

export function GetProductsByCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductsByCategoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'category_name': json['category_name'] == null ? undefined : json['category_name'],
        'product_ids': json['product_ids'] == null ? undefined : json['product_ids'],
    };
}

export function GetProductsByCategoryResponseToJSON(json: any): GetProductsByCategoryResponse {
    return GetProductsByCategoryResponseToJSONTyped(json, false);
}

export function GetProductsByCategoryResponseToJSONTyped(value?: GetProductsByCategoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'category_name': value['category_name'],
        'product_ids': value['product_ids'],
    };
}

