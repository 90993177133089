<template>
  <div class="clearfix grid-12 pt-40 pb-40">
    <ul class="span-2">
      <li><h4>About Us</h4></li>
      <li><a href="/about">Our Story</a></li>
      <li><a href="/faq">FAQ</a></li>
    </ul>
    <div class="span-6">
      <router-link :to="{ name: 'buyer' }" class="nav-apply-button mr-30" @click.native="handleClick('Retailer')">
        <entity-image
          type="retailer"
          size="lg"
          class="width-auto"
          :src="require('../../../assets/img/value-props/retailers-2.png')"
        />
        <h4 class="mb-5 mt-20">I'm a</h4>
        <h1 class="font-size-36">Retailer</h1>
      </router-link>
      <router-link :to="{ name: 'Brand' }" class="nav-apply-button" @click.native="handleClick('Brand')">
        <entity-image
          type="brand"
          size="lg"
          class="width-auto"
          :src="require('../../../assets/img/value-props/retailers-1.png')"
        />
        <h4 class="mb-5 mt-20">I'm a</h4>
        <h1 class="font-size-36">Brand</h1>
      </router-link>
    </div>
  </div>
</template>
<script>
import { trackEvent } from '@/services/tracking'
export default {
  methods: {
    closeDropDown() {
      this.$emit('close-dropdown')
    },
    handleClick(target) {
      trackEvent(`${target} Button Click`)
      this.$store.dispatch('navigation/setDropdownType', 'closed')
    }
  }
}
</script>
