/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralLinkResponse
 */
export interface ReferralLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof ReferralLinkResponse
     */
    link?: string;
}

/**
 * Check if a given object implements the ReferralLinkResponse interface.
 */
export function instanceOfReferralLinkResponse(value: object): value is ReferralLinkResponse {
    return true;
}

export function ReferralLinkResponseFromJSON(json: any): ReferralLinkResponse {
    return ReferralLinkResponseFromJSONTyped(json, false);
}

export function ReferralLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralLinkResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'link': json['link'] == null ? undefined : json['link'],
    };
}

export function ReferralLinkResponseToJSON(json: any): ReferralLinkResponse {
    return ReferralLinkResponseToJSONTyped(json, false);
}

export function ReferralLinkResponseToJSONTyped(value?: ReferralLinkResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'link': value['link'],
    };
}

