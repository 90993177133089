<template>
  <div class="filter-accordion">
    <div class="accordion-header font-sans font-size-16" @click="handleHeaderClick">
      <span class="flex">
        {{ label }}
        <div v-if="selectionCount" class="selection-counter">
          {{ selectionCount }}
        </div>
      </span>
      <Icon :icon-key="open ? 'chevron-up' : 'chevron-down'" hover-type="OPACITY" class="mr-10" />
    </div>

    <div
      class="accordion-content"
      :class="{ open: open, 'transition-running': transitionRunning }"
      :style="{ maxHeight: open ? `${contentHeight + 60}px` : 0 }"
    >
      <search-input-field v-if="!noSearch" v-model="searchValue" class="mb-20" />
      <div
        class="filter-options-wrapper"
        :class="{ open: open, 'transition-running': transitionRunning }"
        :style="{ maxHeight: open ? `${contentHeight}px` : 0 }"
      >
        <slot :search-value="searchValue" />
      </div>
    </div>
  </div>
</template>

<script>
const OPEN_TRANSITION_TIME = 200
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    contentHeight: {
      type: Number,
      default: 200
    },
    onChange: {
      type: Function,
      default: () => undefined
    },
    noSearch: {
      type: Boolean,
      default: false
    },
    filterKey: {
      type: [String, Object],
      default: () => {
        return ''
      }
    },
    activeFilterKey: {
      type: [String, Object],
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-computed-properties-in-data
      open: this.isOpen,
      transitionRunning: false,
      searchValue: '',
      selectionCount: 0
    }
  },
  computed: {
    isOpen() {
      return this.activeFilterKey === this.filterKey
    }
  },
  watch: {
    isOpen: function (value) {
      this.open = value
      this.handleTransition()
    }
  },
  methods: {
    handleHeaderClick: function () {
      this.open = !this.open
      this.onChange({ open: this.open, filterKey: this.filterKey })
      this.handleTransition()
    },
    handleTransition: function () {
      this.transitionRunning = true
      setTimeout(() => {
        this.transitionRunning = false
      }, OPEN_TRANSITION_TIME)
    },
    setSearchValue: function (value) {
      this.searchValue = value
    },
    setSelectionCount: function (value) {
      this.selectionCount = value
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/base/_vars.scss';
@import '@/assets/sass/base/_mixins.scss';

$transitionTime: 200ms;
$transitionType: ease-in-out;

.filter-accordion {
  border-bottom: 1px solid $color-grey-3;
}
.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
.accordion-content {
  @include transition(max-height, $transitionTime, $transitionType);

  max-height: 0;
  overflow: hidden;

  .filter-options-wrapper {
    max-height: 0;
    overflow: auto;
    @include transition(max-height, $transitionTime, $transitionType);

    &.transition-running {
      overflow: hidden !important;
    }
  }

  &.transition-running {
    overflow: hidden !important;
  }
}
</style>
