/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductCategoryResponse
 */
export interface ProductCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    parent_category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    parent_category_name?: string;
}

/**
 * Check if a given object implements the ProductCategoryResponse interface.
 */
export function instanceOfProductCategoryResponse(value: object): value is ProductCategoryResponse {
    return true;
}

export function ProductCategoryResponseFromJSON(json: any): ProductCategoryResponse {
    return ProductCategoryResponseFromJSONTyped(json, false);
}

export function ProductCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'parent_category_id': json['parent_category_id'] == null ? undefined : json['parent_category_id'],
        'parent_category_name': json['parent_category_name'] == null ? undefined : json['parent_category_name'],
    };
}

export function ProductCategoryResponseToJSON(json: any): ProductCategoryResponse {
    return ProductCategoryResponseToJSONTyped(json, false);
}

export function ProductCategoryResponseToJSONTyped(value?: ProductCategoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'parent_category_id': value['parent_category_id'],
        'parent_category_name': value['parent_category_name'],
    };
}

