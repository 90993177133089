/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuborderPartialResponse
 */
export interface SuborderPartialResponse {
    /**
     * 
     * @type {string}
     * @memberof SuborderPartialResponse
     */
    suborder_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderPartialResponse
     */
    brand_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SuborderPartialResponse
     */
    order_status?: SuborderPartialResponseOrderStatusEnum;
}


/**
 * @export
 */
export const SuborderPartialResponseOrderStatusEnum = {
    Hold: 'HOLD',
    Void: 'VOID',
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    Expired: 'EXPIRED',
    PartiallyFulfilled: 'PARTIALLY_FULFILLED',
    FulfillmentInProgress: 'FULFILLMENT_IN_PROGRESS',
    Shipped: 'SHIPPED',
    Paid: 'PAID',
    Failed: 'FAILED',
    NotCollectable: 'NOT_COLLECTABLE',
    Cancelled: 'CANCELLED'
} as const;
export type SuborderPartialResponseOrderStatusEnum = typeof SuborderPartialResponseOrderStatusEnum[keyof typeof SuborderPartialResponseOrderStatusEnum];


/**
 * Check if a given object implements the SuborderPartialResponse interface.
 */
export function instanceOfSuborderPartialResponse(value: object): value is SuborderPartialResponse {
    return true;
}

export function SuborderPartialResponseFromJSON(json: any): SuborderPartialResponse {
    return SuborderPartialResponseFromJSONTyped(json, false);
}

export function SuborderPartialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuborderPartialResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'suborder_id': json['suborder_id'] == null ? undefined : json['suborder_id'],
        'brand_name': json['brand_name'] == null ? undefined : json['brand_name'],
        'order_status': json['order_status'] == null ? undefined : json['order_status'],
    };
}

export function SuborderPartialResponseToJSON(json: any): SuborderPartialResponse {
    return SuborderPartialResponseToJSONTyped(json, false);
}

export function SuborderPartialResponseToJSONTyped(value?: SuborderPartialResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suborder_id': value['suborder_id'],
        'brand_name': value['brand_name'],
        'order_status': value['order_status'],
    };
}

