/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateBannerComponentRequest } from './UpdateBannerComponentRequest';
import {
    UpdateBannerComponentRequestFromJSON,
    UpdateBannerComponentRequestFromJSONTyped,
    UpdateBannerComponentRequestToJSON,
    UpdateBannerComponentRequestToJSONTyped,
} from './UpdateBannerComponentRequest';

/**
 * 
 * @export
 * @interface UpdateBannerContainerRequest
 */
export interface UpdateBannerContainerRequest {
    /**
     * 
     * @type {Array<UpdateBannerComponentRequest>}
     * @memberof UpdateBannerContainerRequest
     */
    components?: Array<UpdateBannerComponentRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBannerContainerRequest
     */
    toggled?: boolean;
}

/**
 * Check if a given object implements the UpdateBannerContainerRequest interface.
 */
export function instanceOfUpdateBannerContainerRequest(value: object): value is UpdateBannerContainerRequest {
    return true;
}

export function UpdateBannerContainerRequestFromJSON(json: any): UpdateBannerContainerRequest {
    return UpdateBannerContainerRequestFromJSONTyped(json, false);
}

export function UpdateBannerContainerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBannerContainerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'components': json['components'] == null ? undefined : ((json['components'] as Array<any>).map(UpdateBannerComponentRequestFromJSON)),
        'toggled': json['toggled'] == null ? undefined : json['toggled'],
    };
}

export function UpdateBannerContainerRequestToJSON(json: any): UpdateBannerContainerRequest {
    return UpdateBannerContainerRequestToJSONTyped(json, false);
}

export function UpdateBannerContainerRequestToJSONTyped(value?: UpdateBannerContainerRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'components': value['components'] == null ? undefined : ((value['components'] as Array<any>).map(UpdateBannerComponentRequestToJSON)),
        'toggled': value['toggled'],
    };
}

