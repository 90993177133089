/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnumOptionBalanceLevel
 */
export interface EnumOptionBalanceLevel {
    /**
     * 
     * @type {string}
     * @memberof EnumOptionBalanceLevel
     */
    value?: EnumOptionBalanceLevelValueEnum;
    /**
     * 
     * @type {string}
     * @memberof EnumOptionBalanceLevel
     */
    label?: string;
}


/**
 * @export
 */
export const EnumOptionBalanceLevelValueEnum = {
    _500: '500',
    _2000: '2000',
    _2500: '2500',
    _5000: '5000',
    _7500: '7500',
    _10000: '10000',
    _15000: '15000',
    _20000: '20000',
    _25000: '25000',
    _100000: '100000'
} as const;
export type EnumOptionBalanceLevelValueEnum = typeof EnumOptionBalanceLevelValueEnum[keyof typeof EnumOptionBalanceLevelValueEnum];


/**
 * Check if a given object implements the EnumOptionBalanceLevel interface.
 */
export function instanceOfEnumOptionBalanceLevel(value: object): value is EnumOptionBalanceLevel {
    return true;
}

export function EnumOptionBalanceLevelFromJSON(json: any): EnumOptionBalanceLevel {
    return EnumOptionBalanceLevelFromJSONTyped(json, false);
}

export function EnumOptionBalanceLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumOptionBalanceLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function EnumOptionBalanceLevelToJSON(json: any): EnumOptionBalanceLevel {
    return EnumOptionBalanceLevelToJSONTyped(json, false);
}

export function EnumOptionBalanceLevelToJSONTyped(value?: EnumOptionBalanceLevel | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'label': value['label'],
    };
}

