export const navigationHelper = {
  computed: {
    isOnADashboardPage() {
      return (
        Boolean(this.$route.matched.filter((route) => route.path === '/dashboard')?.length) &&
        this.$route.path !== '/dashboard/reorder'
      )
    }
  },
  methods: {
    trackNavigationEvent,
    navigateToParentCategory,
    getParentCategoryLink
  }
}

function trackNavigationEvent(name, type) {
  this.$trackEvent({
    action: 'Navigation Click',
    properties: {
      'Page Title': name,
      'Page Type': type?.toLowerCase()
    }
  })

  this.$trackEvent({
    action: `Navigation Click ${name}`,
    properties: {
      'Page Type': type?.toLowerCase()
    }
  })
}

function getParentCategoryLink({ name, id }) {
  if (name === 'Gifts') {
    return {
      name: 'ParentCollection',
      params: {
        collectionName: name,
        collectionId: id
      }
    }
  } else if (name === 'NY NOW') {
    return {
      name: 'nynow',
      params: {
        navItemName: name,
        navItemId: id
      }
    }
  } else {
    return {
      name: 'productsFromNavigation',
      params: {
        navItemName: name,
        navItemId: id
      },
      query: {
        sortBy: 'created_at_asc',
        page: 1,
        distinct: true
      }
    }
  }
}

function navigateToParentCategory({ name, id, type }) {
  if (name === 'Collections') {
    return
  }

  // if (parentCategory.name === "NYNOW") {
  //   this.$router.push(this.getParentCategoryLink({name:"nynow", id:"nynow"}));
  // } else {
  this.$router.push(this.getParentCategoryLink({ name, id }))
  this.trackNavigationEvent(name, type)
  this.$store.dispatch('navigation/setDropdownType', 'closed')
  this.$store.dispatch('navigation/hideSideBar')
  // }
  if (this.leftSideMenu) {
    this.closeLeftSideMenu()
  }
}
