/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Create Promo code object
 * @export
 * @interface CreatePromoCodeRequest
 */
export interface CreatePromoCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePromoCodeRequest
     */
    promo_code: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePromoCodeRequest
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof CreatePromoCodeRequest
     */
    start_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreatePromoCodeRequest
     */
    end_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreatePromoCodeRequest
     */
    type: CreatePromoCodeRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePromoCodeRequest
     */
    first_order_only: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePromoCodeRequest
     */
    one_time_use: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatePromoCodeRequest
     */
    minimum_cart_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePromoCodeRequest
     */
    ongoing_indefinite: boolean;
}


/**
 * @export
 */
export const CreatePromoCodeRequestTypeEnum = {
    Percent: 'PERCENT',
    Amount: 'AMOUNT',
    FreeShipping: 'FREE_SHIPPING'
} as const;
export type CreatePromoCodeRequestTypeEnum = typeof CreatePromoCodeRequestTypeEnum[keyof typeof CreatePromoCodeRequestTypeEnum];


/**
 * Check if a given object implements the CreatePromoCodeRequest interface.
 */
export function instanceOfCreatePromoCodeRequest(value: object): value is CreatePromoCodeRequest {
    if (!('promo_code' in value) || value['promo_code'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('start_date' in value) || value['start_date'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('first_order_only' in value) || value['first_order_only'] === undefined) return false;
    if (!('one_time_use' in value) || value['one_time_use'] === undefined) return false;
    if (!('ongoing_indefinite' in value) || value['ongoing_indefinite'] === undefined) return false;
    return true;
}

export function CreatePromoCodeRequestFromJSON(json: any): CreatePromoCodeRequest {
    return CreatePromoCodeRequestFromJSONTyped(json, false);
}

export function CreatePromoCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePromoCodeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'promo_code': json['promo_code'],
        'amount': json['amount'],
        'start_date': (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'type': json['type'],
        'first_order_only': json['first_order_only'],
        'one_time_use': json['one_time_use'],
        'minimum_cart_value': json['minimum_cart_value'] == null ? undefined : json['minimum_cart_value'],
        'ongoing_indefinite': json['ongoing_indefinite'],
    };
}

export function CreatePromoCodeRequestToJSON(json: any): CreatePromoCodeRequest {
    return CreatePromoCodeRequestToJSONTyped(json, false);
}

export function CreatePromoCodeRequestToJSONTyped(value?: CreatePromoCodeRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'promo_code': value['promo_code'],
        'amount': value['amount'],
        'start_date': ((value['start_date']).toISOString().substring(0,10)),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString().substring(0,10)),
        'type': value['type'],
        'first_order_only': value['first_order_only'],
        'one_time_use': value['one_time_use'],
        'minimum_cart_value': value['minimum_cart_value'],
        'ongoing_indefinite': value['ongoing_indefinite'],
    };
}

