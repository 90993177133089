/* tslint:disable */
/* eslint-disable */
/**
 * Bulletin API
 * REST API for Bulletin platform
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Apply to buy object
 * @export
 * @interface ApplyToBuyRequest
 */
export interface ApplyToBuyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    store_name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    store_url: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    informed_from?: ApplyToBuyRequestInformedFromEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplyToBuyRequest
     */
    assortment_requested: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    store_location_id: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    referral_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    referral_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    brand_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    utm_medium?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    utm_source?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    utm_campaign?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    utm_content?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    utm_term?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    gclid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToBuyRequest
     */
    phone_number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplyToBuyRequest
     */
    marketing_opt_in?: boolean;
}


/**
 * @export
 */
export const ApplyToBuyRequestInformedFromEnum = {
    BulletinEmailOutreach: 'Bulletin Email Outreach',
    BulletinInstagram: 'Bulletin Instagram',
    PressOrPublication: 'Press or Publication',
    WordOfMouth: 'Word of mouth',
    ReferredByABrand: 'Referred by a Brand',
    ReferredByARetailer: 'Referred by a Retailer',
    ReferredByAFriend: 'Referred by a Friend',
    FamiliarWithBulletinStores: 'Familiar with Bulletin Stores',
    ExternalFacebookGroup: 'External Facebook Group',
    NyNowDiscoveryShow: 'NY Now Discovery Show',
    ReassembledTradeShow: 'Reassembled Trade Show',
    SupportTheStoresCampaign: 'Support the Stores Campaign',
    Other: 'Other'
} as const;
export type ApplyToBuyRequestInformedFromEnum = typeof ApplyToBuyRequestInformedFromEnum[keyof typeof ApplyToBuyRequestInformedFromEnum];


/**
 * Check if a given object implements the ApplyToBuyRequest interface.
 */
export function instanceOfApplyToBuyRequest(value: object): value is ApplyToBuyRequest {
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('store_name' in value) || value['store_name'] === undefined) return false;
    if (!('store_url' in value) || value['store_url'] === undefined) return false;
    if (!('assortment_requested' in value) || value['assortment_requested'] === undefined) return false;
    if (!('store_location_id' in value) || value['store_location_id'] === undefined) return false;
    return true;
}

export function ApplyToBuyRequestFromJSON(json: any): ApplyToBuyRequest {
    return ApplyToBuyRequestFromJSONTyped(json, false);
}

export function ApplyToBuyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyToBuyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': json['email'],
        'password': json['password'],
        'store_name': json['store_name'],
        'store_url': json['store_url'],
        'informed_from': json['informed_from'] == null ? undefined : json['informed_from'],
        'assortment_requested': json['assortment_requested'],
        'store_location_id': json['store_location_id'],
        'referral_code': json['referral_code'] == null ? undefined : json['referral_code'],
        'referral_id': json['referral_id'] == null ? undefined : json['referral_id'],
        'brand_id': json['brand_id'] == null ? undefined : json['brand_id'],
        'utm_medium': json['utm_medium'] == null ? undefined : json['utm_medium'],
        'utm_source': json['utm_source'] == null ? undefined : json['utm_source'],
        'utm_campaign': json['utm_campaign'] == null ? undefined : json['utm_campaign'],
        'utm_content': json['utm_content'] == null ? undefined : json['utm_content'],
        'utm_term': json['utm_term'] == null ? undefined : json['utm_term'],
        'gclid': json['gclid'] == null ? undefined : json['gclid'],
        'phone_number': json['phone_number'] == null ? undefined : json['phone_number'],
        'marketing_opt_in': json['marketing_opt_in'] == null ? undefined : json['marketing_opt_in'],
    };
}

export function ApplyToBuyRequestToJSON(json: any): ApplyToBuyRequest {
    return ApplyToBuyRequestToJSONTyped(json, false);
}

export function ApplyToBuyRequestToJSONTyped(value?: ApplyToBuyRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'email': value['email'],
        'password': value['password'],
        'store_name': value['store_name'],
        'store_url': value['store_url'],
        'informed_from': value['informed_from'],
        'assortment_requested': value['assortment_requested'],
        'store_location_id': value['store_location_id'],
        'referral_code': value['referral_code'],
        'referral_id': value['referral_id'],
        'brand_id': value['brand_id'],
        'utm_medium': value['utm_medium'],
        'utm_source': value['utm_source'],
        'utm_campaign': value['utm_campaign'],
        'utm_content': value['utm_content'],
        'utm_term': value['utm_term'],
        'gclid': value['gclid'],
        'phone_number': value['phone_number'],
        'marketing_opt_in': value['marketing_opt_in'],
    };
}

