export default {
  namespaced: true,
  state: () => ({
    dropdown: 'closed',
    activeCategory: null,
    sidebar: false,
    leftSideCategoryMenuOpen: false,
    mobilePopupOpen: false,
    navigationData: [],
    isSiteWideBannerVisible: false,
    siteWideBannerHeight: 0
  }),
  mutations: {
    SET_STATE(state, input) {
      state.dropdown = input
    },
    SET_ACTIVE_CATEGORY(state, input) {
      state.activeCategory = input
    },
    SET_SIDEBAR(state, input) {
      state.sidebar = input
    },
    SET_NAVIGATION_DATA(state, categories) {
      state.navigationData = categories
    },
    SET_LEFT_SIDE_CATEGORY_MENU_OPEN(state, input) {
      state.leftSideCategoryMenuOpen = input
    },
    SET_MOBILE_POPUP_OPEN(state, value) {
      state.mobilePopupOpen = value
    },
    SET_SITE_WIDE_BANNER_VISIBLE(state, value) {
      state.isSiteWideBannerVisible = value
    },
    SET_SITE_WIDE_BANNER_HEIGHT(state, value) {
      state.siteWideBannerHeight = value
    }
  },
  getters: {
    dropdownState(state) {
      return state.dropdown
    },
    activeCategory(state) {
      return state.activeCategory
    },
    sidebar(state) {
      return state.sidebar
    },
    leftSideCategoryMenuOpen(state) {
      return state.leftSideCategoryMenuOpen
    },
    navigationData(state) {
      return state.navigationData
    },
    mobilePopupOpen(state) {
      return state.mobilePopupOpen
    },
    getSiteWideBannerVisible(state) {
      return state.isSiteWideBannerVisible
    },
    getSiteWideBannerHeight(state) {
      return state.siteWideBannerHeight
    }
  },
  actions: {
    setDropdownType({ commit }, input) {
      commit('SET_STATE', input)
    },
    setActiveCategory({ commit }, input) {
      commit('SET_ACTIVE_CATEGORY', input)
    },
    toggleSideBar({ state, commit }) {
      commit('SET_SIDEBAR', !state.sidebar)
    },
    hideSideBar({ commit }) {
      commit('SET_SIDEBAR', false)
    },
    setLeftSideCategoryMenuOpen({ commit }, state) {
      commit('SET_LEFT_SIDE_CATEGORY_MENU_OPEN', state)
    },
    setNavigationData({ commit }, data) {
      commit('SET_NAVIGATION_DATA', data)
    },
    setMobilePopupOpen({ commit }, data) {
      commit('SET_MOBILE_POPUP_OPEN', data)
    },
    setSiteWideBannerVisible({ commit }, data) {
      commit('SET_SITE_WIDE_BANNER_VISIBLE', data)
    },
    setSiteWideBannerHeight({ commit }, data) {
      commit('SET_SITE_WIDE_BANNER_HEIGHT', data)
    }
  }
}
